var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "errPage-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "pan-back-btn",
          attrs: { icon: "arrow-left" },
          on: { click: _vm.back },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("common.Return")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h1", { staticClass: "text-jumbo text-ginormous" }, [
              _vm._v("Oops!"),
            ]),
            _vm._v(" "),
            _c("h2", [_vm._v(_vm._s(_vm.$t("common.hint_706")))]),
            _vm._v(" "),
            _c("h6", [_vm._v(_vm._s(_vm.$t("common.hint_309")))]),
            _vm._v(" "),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", [_vm._v(_vm._s(_vm.$t("common.Or_you_can_go")) + ":")]),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "link-type" },
                [
                  _c("router-link", { attrs: { to: "/dashboard" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.Go_back_to_the_homepage")) +
                        "\n          "
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("img", {
              attrs: {
                src: _vm.errGif,
                width: "313",
                height: "428",
                alt: "Girl has dropped her ice cream.",
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }