export default {
title: '管理控台',
merchant: '商戶ID',
logIn: '登錄',
username: '帳號',
password: '密碼',
merchantName: '商戶ID',
usernameTips: '請輸入正確的用戶名',
passwordTips: '密碼長度不能少於6位元',
merchantNameTips: '請輸入正確的商戶ID',
ForgotPasswordPrompt: '如忘記密碼，請聯繫管理員重置密碼。'
}
