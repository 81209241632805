export default {
  dashboard: 'Trang đầu',
  directivePermission: 'Quyền chỉ thị',
  icons: 'biểu tượng',
  components: 'các thành phần',
  componentMixin: 'thành phần nhỏ',
  backToTop: 'Quay lại đầu trang',
  errorPages: 'trang lỗi',
  page401: '401',
  page404: '404',
  errorLog: 'Nhật ký lỗi',
  clipboardDemo: 'Clipboard',
  i18n: 'Trần Quốc Tuấn',
  profile: "Trung tâm cá nhân"
}
