export default {
  Edit: "Edit",
  User_Configuration: "User Configuration",
  Please_enter_a_numerical_level: "Please enter a numerical level",
  hint_4:
    "Setting nicknames containing English letters is not allowed below this level",
  Nickname_English_letter_level_limit: "Nickname English letter level limit",
  Live_broadcast_room_configuration: "Live broadcast room configuration",
  hint_6: "If it is lower than this level, live streaming chat is not allowed",
  hint_5:
    "The English letters for chatting in live rooms below this level have been replaced with *",
  Open: "Open",
  Close: "Close",
  Circle_Speech_Configuration: "Circle Speech Configuration",
  hint_7:
    "If it is lower than this level, private message anchors within the circle are not allowed.",
  Speech_level_limit: "Speech level limit",
  hint_8_:
    "If it is lower than this level, chatting and speaking within the circle are not allowed.",
  Posting_updates_submission_levels: "Posting updates/submission levels",
  hint_9: "Posting updates/submissions below this level is not allowed",
  hint_911: "Posting updates/submissions",
  Cancel_1: "Cancel",
  Save_1: "Save",
  Please_enter_level: "Please enter level",
  APP_bottom_label: "APP bottom label",
  Initial_icon: "Initial icon",
  Activity_icon: "Activity icon",
  World_Cup_icon: "World Cup icon",
  The_bottom_marketing_position_of_the_APP:
    "The bottom marketing position of the APP",
  Please_enter_the_redirect_address: "Please enter the redirect address",
  Event_start_time: "Event start time",
  APP_homepage_my_background: "APP homepage, my background",
  hint_10: "Size: 1125 * 624, can only upload jpg/png files",
  Operation: "Operation",
  Upload: "Upload",
  Ordinary_user_currency_icon: "Ordinary user currency icon",
  Size_1: "Size",
  Type: "Type",
  Preview_image: "Preview image",
  Live_settlement_currency_icon: "Live settlement currency icon",
  Brocade_Bag_Settlement_Currency_icon: "Brocade Bag Settlement Currency icon",
  hint_11: "Supported formats: PNG, size: up to 500K",
  Android_Triple_Image: "Android Triple Image",
  Top_of_homepage_top_of_activity_page: "Top of homepage, top of activity page",
  At_the_top_of_the_competition_page_my:
    "At the top of the competition page, my",
  Signal_source_top: "Signal source top",
  Please_select: "Please select",
  Please_upload: "Please upload",
  Operation_successful: "Operation successful",
  Can_only_upload_can_only_upload_PNG_files:
    "Can only upload, can only upload PNG files",
  hint_800: "The upload size cannot exceed 500K!",
  Login_address_configuration: "Login address configuration",
  Please_enter_your_login_address: "Please enter your login address",
  hint_12:
    "This configuration only applies to the merchant side user system used by the streaming end. After configuration, the streaming end supports identifying the merchant side's own username and password.",
  Recommended_by_the_editorial_circle: "Recommended by the editorial circle",
  Please_enter_the_resource_name: "Please enter the resource name",
  Circle_List_Recommendation: "Circle List Recommendation",
  Recommended_by_Square_Circle: "Recommended by Square Circle",
  Please_enter_userid: "Please enter userid",
  Please_enter_the_IP_address: "Please enter the IP address",
  Search: "Search",
  Refresh_IP_blacklist_cache: "Refresh IP blacklist cache",
  Reset: "Reset",
  Loading: "Loading...",
  Merchant_ID_1: "Merchant ID",
  Prohibition_time_1: "Prohibition time",
  Remove: "Remove",
  hint_743: "Are you sure you want to remove this IP?",
  APP_brand_name: "APP brand name",
  Default_username_prefix: "Default username prefix",
  Common_user_currency_name: "Common user currency name",
  Live_settlement_currency_name: "Live settlement currency name",
  The_name_of_the_settlement_currency_for_the_brocade_bag:
    "The name of the settlement currency for the brocade bag",
  Contact_customer_service: "Contact customer service",
  Assistant_default_icon: "Assistant default icon",
  Click_to_select_resources: "Click to select resources",
  hint_13:
    "Only SVGA files can be uploaded, and the image size cannot exceed 800k",
  Assistant_unread_message_icon: "Assistant unread message icon",
  Assistant_displays_icon_size: "Assistant displays icon size",
  Small: "Small",
  Medium: "Medium",
  Big: "Big",
  Assistant_display_time: "Assistant display time",
  Online_customer_service_address: "Online customer service address",
  Initialize_account: "Initialize account",
  Anchor_tool_download_address: "Anchor tool download address",
  ICP_Filing_Information: "ICP Filing Information",
  Live_broadcast_room_default_message: "Live broadcast room default message",
  Conversation_background: "Conversation background",
  Default_official_anchor_profile_picture:
    "Default official anchor profile picture",
  Default_official_message_avatar: "Default official message avatar",
  Login_registration_page_background: "Login registration page background",
  hint_14: "Supported formats: JPG, PNG, GIF, MP4, size: up to 3M",
  Delete: "Delete",
  Triple_plot: "Triple plot",
  Double_plot: "Double plot",
  Please_fill_in_the_brand_name: "Please fill in the brand name",
  Please_fill_in_the_prefix: "Please fill in the prefix",
  Please_fill_in_the_currency_name: "Please fill in the currency name",
  Please_fill_in_customer_service_WeChat:
    "Please fill in customer service WeChat",
  Please_fill_in_the_download_address_for_the_anchor_tool:
    "Please fill in the download address for the anchor tool",
  Please_fill_in_the_ICP_information: "Please fill in the ICP information",
  Please_fill_in_the_default_message_for_the_live_broadcast_room:
    "Please fill in the default message for the live broadcast room",
  Please_fill_in_the_assistant_display_time:
    "Please fill in the assistant display time",
  hint_15:
    "There is an error in uploading the size, please upload according to the format requirements!",
  hint_16: "Can only upload, can only upload PNG files!",
  The_upload_size_cannot_exceed: "The upload size cannot exceed",
  hint_17: "The transfer size cannot exceed 800K!",
  Select: "Select",
  Please_upload_the_edited_Excel_template_file:
    "Please upload the edited Excel template file",
  Confirm: "Confirm",
  Importing: "Importing",
  Please_upload_the_file: "Please upload the file",
  Only_xlsx_format_files_can_be_uploaded:
    "Only. xlsx format files can be uploaded!",
  hint_742: "The size of the uploaded image cannot exceed 500MB!",
  Successfully_added: "Successfully added",
  Import_member_failure_list: "Import member failure list",
  OK: "OK",
  Select_file: "Select file",
  Please_enter_the_ID: "Please enter the ID",
  User_nickname: "User nickname",
  hint_740: "Please enter the user's nickname",
  User_phone_number: "User's phone number",
  hint_739: "Please enter the user's phone number",
  User_level: "User level",
  Please_enter_user_level: "Please enter user level",
  Membership_level: "Membership level",
  Game_users: "Game users",
  Status: "Status",
  Registration_time: "Registration time",
  Identity: "Identity",
  User_type: "User type",
  Inviter_userid: "Inviter userid",
  hint_738: "Please enter the inviter's userid",
  Registration_Platform: "Registration Platform",
  hint_741: "Please enter the registration platform website address",
  Batch_import_of_members: "Batch import of members",
  Download_batch_import_templates: "Download batch import templates",
  Export_data: "Export data",
  Total_number_of_registered_users: "Total number of registered users",
  Number_of_registered_users_today: "Number of registered users today",
  nickname: "nickname",
  Avatar: "Avatar",
  Mobile_area_code: "Mobile area code",
  Mobile_phone_number: "Mobile phone number",
  Third_party_binding: "Third party binding",
  Recent_login_time: "Recent login time",
  Recently_logged_in_IP: "Recently logged in IP",
  Last_visited_time: "Last visited time",
  Anchor: "Anchor",
  Experts: "Experts",
  Anchor_level: "Anchor level",
  Ban: "Ban",
  Enable: "Enable",
  Detailed: "Detailed",
  Prohibition: "Prohibition",
  Reset_password: "Reset password",
  Personal_information_1: "Personal information",
  Mobile_phone_number_and_area_code: "Mobile phone number and area code",
  Settings: "Settings",
  Phone_number: "Phone number",
  Please_enter_your_phone_number: "Please enter your phone number",
  User_status: "User status",
  Normal_1: "Normal",
  hint_737: "Is it a broadcaster?",
  hint_736: "Is it a brocade expert?",
  Recommendation_code: "Recommendation code",
  Personal_signature: "Personal signature",
  User_UID: "User UID",
  Prohibition_period_: "Prohibition period:",
  Release_the_ban: "Release the ban",
  Reasons_for_Prohibition: "Reasons for Prohibition",
  Please_select_the_reason_for_the_ban: "Please select the reason for the ban",
  Delete_chat_messages: "Delete chat messages",
  Delete_all_chat_messages_in_the_live_broadcast_room:
    "Delete all chat messages in the live broadcast room",
  hint_735: "Are you sure you want to ban this account?",
  Is_IP_banned_: "Is IP banned?",
  Current_level: "Current level",
  Operation_time: "Operation time",
  Operator: "Operator",
  No: "No",
  Yes: "Yes",
  C_end_user_data: "C-end user data",
  Batch_import_of_member_templates: "Batch import of member templates",
  Account_has_been_banned: "Account has been banned",
  hint_734: "Are you sure you want to enable this account?",
  Prompt: "Prompt",
  Account_enabled: "Account enabled",
  hint_733:
    "Are you sure to reset the user's password? The initial password is: Aa123456",
  Password_reset_successful: "Password reset successful",
  Password_reset_failed: "Password reset failed",
  Download_the_homepage_logo_: "Download the homepage logo:",
  Click_to_select_image: "Click to select image",
  hint_20:
    "Only jpg/png files can be uploaded, and the image size needs to be 344X120, with a maximum of 1 image.",
  hint_21:
    "Only jpg/png files can be uploaded, and the image size needs to be 288X100, with a maximum of 1 image.",
  Download_the_top_screen_background_image:
    "Download the top screen background image",
  hint_22:
    "Only jpg/png files can be uploaded, and the image size needs to be 375X732, with a maximum of 1 image.",
  Download_the_top_screen_copy_image: "Download the top screen copy image",
  hint_23:
    "Only jpg/png files can be uploaded, and the image size needs to be 1980x1650, with a maximum of 1 image.",
  Download_page_application_introduction_image:
    "Download page application introduction image",
  hint_24:
    "Only jpg/png files can be uploaded, and the image size needs to be 360X842, with a maximum of 4 images.",
  Download_page_application_introduction:
    "Download page application introduction",
  Download_page_features: "Download page features",
  PWA_configuration: "PWA configuration",
  Only_json_files_can_be_uploaded: "Only. json files can be uploaded",
  Download_floating_bar_description: "Download floating bar description",
  New_H5_logo: "New H5 logo",
  hint_732: "The recommended image size is 40x40",
  New_H5_Download_floating_bar: "New H5- Download floating bar",
  hint_731: "The recommended image size is 400x60",
  Youmeng_Statistics: "Youmeng Statistics",
  Please_enter_the_URL_corresponding_to_src:
    "Please enter the URL corresponding to src",
  hint_25:
    "If you need to count multiple domain names, please use | | to distinguish",
  Baidu_Statistics: "Baidu Statistics",
  Version_download_address: "Version download address",
  Quick_Version: "Quick Version",
  Please_enter_the_iOS_shortcut_address:
    "Please enter the iOS shortcut address",
  Please_enter_Android_Scheme: "Please enter Android Scheme",
  H5_Station_Download_Jump_Status: "H5 Station Download Jump Status",
  APP_H5_Album_Page_Name: "APP/H5 Album Page Name",
  Please_enter_a_name: "Please enter a name",
  APP_H5_Album_Page_Link: "APP/H5 Album Page Link",
  Please_upload_pictures: "Please upload pictures",
  Please_upload_the_PWA_configuration_file:
    "Please upload the PWA configuration file",
  Circle_: "Circle {num}",
  Please_enter_the_circle_ID: "Please enter the circle ID",
  Please_fill_in_the_Android_app_download_address:
    "Please fill in the Android app download address",
  Please_fill_in_the_iOS_app_download_address:
    "Please fill in the iOS app download address",
  Please_fill_in_the_application_introduction:
    "Please fill in the application introduction",
  Please_fill_in_the_featured_features: "Please fill in the featured features",
  Please_fill_in_the_description_1: "Please fill in the description",
  hint_730: "Please fill in the download address for iOS stable version",
  Please_fill_in_the_Android_Scheme: "Please fill in the Android Scheme",
  Please_fill_in_the_iOS_Scheme: "Please fill in the iOS Scheme",
  hint_18: "Can only upload. Only jpg, jpeg, or png files can be uploaded.",
  Upload_up_to_1_image: "Upload up to 1 image",
  hint_19: "Only uploadable. Only jpg, jpeg, or png files can be uploaded!",
  The_upload_size_cannot_exceed_1_5M_: "The upload size cannot exceed 1.5M!",
  Only_json_format_files_can_be_uploaded_:
    "Only. json format files can be uploaded!",
  The_size_cannot_exceed_1MB_: "The size cannot exceed 1MB!",
  IOS_double_plot: "IOS double plot",
  Protocol_identifier_ID: "Protocol identifier ID",
  Protocol_identifier: "Protocol identifier",
  Protocol_Name: "Protocol Name",
  Last_editing_date: "Last editing date",
  Release_status: "Release status",
  H5_download_page_address: "H5 download page address",
  Application_Introduction: "Application Introduction",
  Image_and_Text_Configuration: "Image and Text Configuration",
  Page_: "Page {num}",
  Only_jpg_png_files_can_be_uploaded: "Only jpg/png files can be uploaded",
  The_size_is_394_X798: "The size is 394X798",
  Please_enter_the_application_introduction:
    "Please enter the application introduction",
  Please_enter_the_address: "Please enter the address",
  The_title_of_the_th_page_cannot_be_empty:
    "The title of the ${num} th page cannot be empty",
  Logo_icon: "Logo icon",
  Logo_Title: "Logo Title",
  The_recommended_image_size_is_140_x70: "The recommended image size is 140x70",
  Navigation_bar_activity_map: "Navigation bar activity map",
  hint_307: "Size: 312 * 126, can only upload jpg/png files",
  PC_download_copy: "PC download copy",
  hint_26: "Please enter the first line of text, no more than 10 characters",
  hint_27: "Please enter the second line of text, no more than 10 characters",
  Page_title_: "Page title:",
  Please_enter_a_title: "Please enter a title",
  Page_keywords: "Page keywords",
  Page_Description: "Page Description",
  Please_enter_a_description: "Please enter a description",
  Please_enter_the_download_address_for_the_anchor_tool:
    "Please enter the download address for the anchor tool",
  H5_download_page_address_1: "H5 download page address",
  Please_enter_the_h5_download_page_address:
    "Please enter the h5 download page address",
  Anchor_Recruitment_Description: "Anchor Recruitment Description",
  Please_enter_the_anchor_recruitment_description:
    "Please enter the anchor recruitment description",
  Anchor_Recruitment_QR_Code: "Anchor Recruitment QR Code",
  The_recommended_image_size_is_256x256:
    "The recommended image size is 256x256",
  Contact_email_1: "Contact email",
  Please_enter_your_contact_email: "Please enter your contact email",
  Bottom_copyright_information: "Bottom copyright information",
  Please_enter_copyright_information: "Please enter copyright information",
  PC_Link_Configuration: "PC Link Configuration",
  Please_enter_the_PC_domain_name_link_configuration:
    "Please enter the PC domain name link configuration",
  Site_icon: "Site icon",
  Click_to_select: "Click to select",
  hint_28:
    "Only ICO files can be uploaded, and the image size needs to be 32x32, with a maximum size of 20kb.",
  Please_fill_in_the_statistics_link: "Please fill in the statistics link",
  Customer_service_code_: "Customer service code",
  Please_fill_in_the_customer_service_code:
    "Please fill in the customer service code",
  PC_album_page_status: "PC album page status",
  PC_album_page_link: "PC album page link",
  Please_fill_in_the_title: "Please fill in the title",
  Please_fill_in_the_description: "Please fill in the description",
  Please_fill_in_the_keywords: "Please fill in the keywords",
  Please_fill_in_the_record_number_document:
    "Please fill in the record number document",
  Please_fill_in_your_email_address: "Please fill in your email address",
  Please_fill_in_the_h5_download_page_address:
    "Please fill in the h5 download page address",
  Please_select_the_Feixin_QR_code_image:
    "Please select the Feixin QR code image",
  Please_upload_the_logo_icon: "Please upload the logo icon",
  Please_upload_the_logo_title: "Please upload the logo title",
  Please_enter: "Please enter",
  Only_ICO_files_can_be_uploaded_: "Only ICO files can be uploaded!",
  hint_729: "The size of the uploaded image cannot exceed 20KB!",
  Please_enter_the_theme_name: "Please enter the theme name",
  Add_a_new_theme: "Add a new theme",
  There_is_currently_no_content: "There is currently no content",
  Last_modification_time: "Last modification time",
  Please_enter_sorting: "Please enter sorting",
  Are_you_sure_you_want_to_delete_it_: "Are you sure you want to delete it?",
  Delete_successful: "Delete successful",
  Theme: "Theme",
  Publish: "Publish",
  Please_select_a_theme: "Please select a theme",
  Please_enter_the_content_of_the_agreement:
    "Please enter the content of the agreement",
  Please_enter_the_protocol_name: "Please enter the protocol name",
  hint_728: "Are you sure you want to publish the agreement information?",
  hint_727: "Are you sure to save the protocol information?",
  User_Registration_Agreement: "User Registration Agreement",
  User_Privacy_Agreement: "User Privacy Agreement",
  Live_streaming_protocol: "Live streaming protocol",
  Payment_Agreement: "Payment Agreement",
  Settlement_rules: "Settlement rules",
  Recharge_Service_Agreement: "Recharge Service Agreement",
  About: "About",
  Please_select_the_protocol_type: "Please select the protocol type",
  Login_Page_Logo: "Login Page Logo",
  hint_29:
    "Only jpg/png files can be uploaded, and the image size needs to be 109X140, with a maximum of 1 image.",
  Main_interface_logo: "Main interface logo",
  hint_30:
    "Only jpg/png files can be uploaded, and the image size needs to be 69X67, with a maximum of 1 image.",
  About_Page_Logo: "About Page Logo",
  hint_31:
    "Only jpg/png files can be uploaded, and the image size needs to be 109X109, with a maximum of 1 image.",
  hint_32:
    "The size of the uploaded image is incorrect, please upload according to the format requirements!",
  Add_TF_package: "Add TF package",
  Package_name: "Package name",
  Download_link: "Download link",
  Developer_account: "Developer account",
  Effective_date_1: "Effective date",
  Expiration_date: "Expiration date",
  Remaining_valid_days: "Remaining valid days",
  Please_enter_valid_days: "Please enter valid days",
  Effective_days: "Effective days",
  Please_enter_the_package_name: "Please enter the package name",
  Edit_TF_package: "Edit TF package",
  Please_fill_in_the_download_link: "Please fill in the download link",
  Please_select_the_effective_date: "Please select the effective date",
  hint_726: "The number of valid days must be greater than or equal to 90",
  Created_successfully: "Created successfully",
  Update_method: "Update method",
  System: "System",
  Add_1: "Add",
  Update_type: "Update type",
  Version_number: "Version number",
  Prompt_package: "Prompt package",
  Update_package: "Update package",
  Reminder_date: "Reminder date",
  Recently_edited_by: "Recently edited by",
  Last_edited_time: "Last edited time",
  Version_update: "Version update",
  TF_Package_Update: "TF Package Update",
  Download_address: "Download address",
  Select_prompt_package: "Select prompt package",
  Please_fill_in_the_download_address: "Please fill in the download address",
  Select_Update_Package: "Select Update Package",
  Remaining: "Remaining",
  Heaven: "Heaven",
  Start_reminder_date: "Start reminder date",
  Update_content: "Update content",
  Please_enter_the_updated_content: "Please enter the updated content",
  hint_33: "Supported formats: PNG, JPG, size requirement: 750 * 1624",
  Edit_version_update: "Edit version update",
  Add_version_update: "Add version update",
  Please_set_an_updated_version: "Please set an updated version",
  Please_set_the_prompt_version: "Please set the prompt version",
  Please_select_the_system: "Please select the system",
  Please_choose_a_method: "Please choose the method",
  Please_enter_the_content: "Please enter the content",
  Please_set_the_reminder_date: "Please set the reminder date",
  hint_725: "Uploading images can only be in JPG or PNG format!",
  hint_724: "The size of the uploaded image cannot exceed 1MB!",
  Modified_successfully: "Modified successfully",
  User_Management: "User Management",
  IP_blacklist: "IP blacklist",
  Application_ID: "Application ID",
  Application_Name: "Application Name",
  Last_modified_date: "Last modified date",
  Create_an_application: "Create an application",
  View_Key: "View Key",
  Module: "Module",
  Supplier: "Supplier",
  Add_Module: "Add Module",
  Reset_Key: "Reset Key",
  New_Module: "New Module",
  Please_enter_the_application_name: "Please enter the application name",
  Please_select_a_module: "Please select a module",
  Please_select_a_supplier: "Please select a supplier",
  Please_enter_supplier_configuration: "Please enter supplier configuration",
  Edit_application: "Edit application",
  hint_34:
    "If the application has the function of using this module, deleting records may cause the function to be unavailable. Are you sure you want to delete it?",
  Start_page: "Start page",
  Change: "Change",
  Only_JPG_or_PNG_format_images_are_allowed_to_be_uploaded:
    "Only JPG or PNG format images are allowed to be uploaded",
  Are_you_sure_you_want_to_save_the_changes_:
    "Are you sure you want to save the changes?",
  hint_320: "Password modification successful, please log in again",
  Review_version: "Review version",
  Please_enter_version: "Please enter version",
  Tag_number: "Tag number",
  List_Fields: "List Fields",
  Form_Description: "Form Description",
  Remarks: "Remarks",
  Pre_explanation: "Pre explanation",
  hint_35:
    "1. Hidden version control function for iOS submission environment; 2. Obvious code level avoidance with comments or code for review environment control; 3. No need for Android control",
  Version_control: "Version control",
  hint_36:
    "1. Display the current version number that needs to be controlled. For example, if the current version that needs to be reviewed is V1.1, enter 1.1 in the input box, and check the corresponding module that needs to be hidden below. If the corresponding module needs to be hidden is checked below, then all module entries in V1.1 version will be hidden. 2. Enter a version number without an actual version to save, which does not affect the display of any version of the module.",
  Control_module: "Control module",
  hint_317:
    "Display the function names that need to be hidden in the re approval environment, with a default check status. If the check is removed and saved, this module will not be hidden in this version;",
  Please_fill_in_version_information: "Please fill in version information",
  Select_language: "Select language",
  General_configuration: "General configuration",
  IOS_configuration: "IOS configuration",
  H5_configuration: "H5 configuration",
  New_download_page_configuration: "New download page configuration",
  Streaming_tool_configuration: "Streaming tool configuration",
  PC_configuration: "PC configuration",
  Mobile_streaming_configuration: "Mobile streaming configuration",
  Advertising_restriction_configuration:
    "Advertising restriction configuration",
  Virtual_Number_Restrictions: "Virtual Number Restrictions",
  Reporting_restrictions_for_third_party_data_apps:
    "Reporting restrictions for third-party data apps",
  Report_domain_name_configuration: "Report domain name configuration",
  Please_enter_the_domain_name: "Please enter the domain name",
  hint_723: "Download the APP and report the first buried point",
  Channel_code_parameter_acquisition: "Channel code parameter acquisition",
  PC_data_reporting: "PC data reporting",
  H5_data_reporting: "H5 data reporting",
  Domain_Name_Address: "Domain Name Address",
  Please_enter_the_domain_name_address: "Please enter the domain name address",
  Deactivate: "Deactivate",
  Please_select_the_type: "Please select the type",
  Are_you_sure_to_enable_the_domain_name_:
    "Are you sure to enable the domain name?",
  hint_722: "Are you sure to deactivate the domain name?",
  Update_successful: "Update successful",
  Recommended: "Recommended",
  Paused: "Paused",
  Picture_matching: "Picture matching",
  Source: "Source",
  Feedback_type: "Feedback type",
  Number: "Number",
  Feedback_details: "Feedback details",
  Contact_information: "Contact information",
  Feedback_time: "Feedback time",
  Navigation_Station_Switch: "Navigation Station Switch",
  Page_Configuration: "Page Configuration",
  Weight: "Weight",
  hint_37: "The larger the number, the higher the ranking",
  Button_Name: "Button Name",
  Domain_name: "Domain name",
  Please_enter_the_weight: "Please enter the weight",
  Please_enter_the_button_name: "Please enter the button name",
  Mobile_related: "Mobile related",
  PC_related: "PC related",
  Personal_information: "Personal information",
  Login_account: "Login account",
  User_Name: "User Name",
  Security_Settings: "Security Settings",
  Change_password: "Change password",
  Original_password: "Original password",
  New_password: "New password",
  Confirm_new_password: "Confirm new password",
  hint_721: "The password length cannot be less than 6 digits",
  The_passwords_entered_twice_do_not_match:
    "The passwords entered twice do not match",
  Please_enter_your_password_again: "Please enter your password again",
  Merchant_Basic_Information: "Merchant Basic Information",
  Merchant_ID: "Merchant ID",
  Enterprise_Name: "Enterprise Name",
  Contact_person: "Contact person",
  Contact_email: "Contact email",
  Contact_phone_number: "Contact phone number",
  SMS_verification_code: "SMS verification code",
  Please_enter_the_merchant_ID: "Please enter the merchant ID",
  Please_enter_the_company_name: "Please enter the company name",
  Please_enter_the_contact_person: "Please enter the contact person",
  Please_enter_the_contact_person_phone_number:
    "Please enter the contact person's phone number",
  Please_enter_the_contact_email: "Please enter the contact email",
  Please_enter_the_verification_code: "Please enter the verification code",
  Get_verification_code: "Get verification code",
  Please_enter_sensitive_words: "Please enter sensitive words",
  Batch_Add: "Batch Add",
  Query_results: "Query results",
  hint_720: "Sorry, we couldn't find the sensitive word",
  Activity_Name: "Activity Name",
  hint_718:
    "Please enter sensitive words that cannot be less than two characters in length",
  hint_308: "Are you sure you want to delete this keyword?",
  continue_to_be_sent_on_the_platform:
    "After lifting the ban, the word can continue to be sent on the platform",
  Are_you_sure_to_lift_the_ban_: "Are you sure to lift the ban?",
  The_image_size_needs_to_be_40_x40: "The image size needs to be 40x40",
  Brand_name: "Brand name",
  Main_site_name: "Main site name",
  Please_enter_the_name_of_the_main_site:
    "Please enter the name of the main site",
  Main_site_redirect_URL: "Main site redirect URL",
  Please_enter_the_main_site_redirect_URL:
    "Please enter the main site redirect URL",
  H5_Main_Station_Jump_URL: "H5 Main Station Jump URL",
  Please_enter_the_H5_main_site_redirect_URL:
    "Please enter the H5 main site redirect URL",
  Homepage_banner: "Homepage banner",
  hint_717: "The image size needs to be 1200x150",
  Homepage_banner_URL: "Homepage banner URL",
  Please_enter_the_banner_URL_on_the_homepage:
    "Please enter the banner URL on the homepage",
  Live_room_banner: "Live room banner",
  hint_716: "Please enter the banner URL for the live broadcast room",
  Homepage_title: "Homepage title",
  Please_enter_the_title: "Please enter the title",
  Home_page_description_1: "Home page - description",
  Homepage_keywords_1: "Homepage keywords",
  Please_enter_keywords_1: "Please enter keywords",
  Live_room_title: "Live room title",
  Live_broadcast_room_keywords_1: "Live broadcast room keywords",
  Bottom_copyright_text: "Bottom copyright text",
  Please_enter_the_copyright_text_at_the_bottom:
    "Please enter the copyright text at the bottom",
  APP_download_URL: "APP download URL",
  Please_enter_the_APP_download_URL: "Please enter the APP download URL",
  hint_715: "The image size needs to be 750x94",
  H5_Live_Banner: "H5 Live Banner",
  Please_enter_h5RoomBannerRedirectUrl: "Please enter h5RoomBannerRedirectUrl",
  Please_enter_the_brand_name: "Please enter the brand name",
  Please_enter_the_main_site_redirect: "Please enter the main site redirect",
  Please_enter_H5_main_site_redirect: "Please enter H5 main site redirect",
  Please_enter_the_homepage: "Please enter the homepage",
  Please_enter_the_homepage_banner_to_jump_to:
    "Please enter the homepage banner to jump to",
  Please_enter_the_homepage_description:
    "Please enter the homepage description",
  Please_enter_homepage_keywords: "Please enter homepage keywords",
  Please_enter_the_live_streaming_page: "Please enter the live streaming page",
  hint_714: "Please enter a description for the live streaming page",
  Please_enter_keywords_for_the_live_streaming_page:
    "Please enter keywords for the live streaming page",
  Please_enter_the_website_copyright_statement:
    "Please enter the website copyright statement",
  Please_enter_the_app_download_URL: "Please enter the app download URL",
  Please_enter_the_site_h5BannerRedirectUrl:
    "Please enter the site h5BannerRedirectUrl",
  Add_Site: "Add Site",
  Edit_Site: "Edit Site",
  Upload_up_to_1_photo_: "Upload up to 1 photo!",
  Home_page_description: "Home page description",
  Homepage_keywords: "Homepage keywords",
  Live_broadcast_room_title: "Live broadcast room title",
  Live_broadcast_room_description: "Live room - description",
  Live_broadcast_room_keywords: "Live broadcast room keywords",
  App_download_URL: "App download URL",
  H5_homepage_banner_URL: "H5 homepage banner URL",
  H5_homepage_banner_redirect_URL: "H5 homepage banner redirect URL",
  H5_live_broadcast_room_banner_URL: "H5 live broadcast room banner URL",
  H5_Live_Room_Banner_Jump_URL: "H5 Live Room Banner Jump URL",
  Live_streaming_services_1: "Live streaming services",
  SMS_service: "SMS service",
  Certificate_file_name: "Certificate file name",
  Certificate_type: "Certificate type",
  General_Supplier_Configuration: "General Supplier Configuration",
  Whether_to_configure_TF_package_name: "Whether to configure TF package name",
  Package_Name_Configuration: "Package Name Configuration",
  Supplier_Configuration: "Supplier Configuration",
  Edit_Supplier_Configuration: "Edit Supplier Configuration",
  Add_supplier_configuration: "Add supplier configuration",
  Please_fill_in_the_configuration_information:
    "Please fill in the configuration information",
  hint_713: "Are you sure you want to delete the configuration?",
  Please_configure_TF_configuration_correctly:
    "Please configure TF configuration correctly",
  Version_update_reminder: "Version update reminder",
  TF_Package_Management: "TF Package Management",
  Batch_upload: "Batch upload",
  Posting_updates: "Posting updates",
  Account: "Account",
  Content: "Content",
  Upload_images: "Upload images",
  Only_jpg_jpeg_png_files_can_be_uploaded:
    "Only jpg/jpeg/png files can be uploaded",
  Up_to_9_images_can_be_uploaded: "Up to 9 images can be uploaded",
  Click_to_select_file: "Click to select file",
  Only_supports_video_format_files: "Only supports video format files",
  Related_circles: "Related circles",
  Please_select_a_circle: "Please select a circle",
  Related_topics: "Related topics",
  Please_select_a_topic: "Please select a topic",
  Please_upload_the_video_file: "Please upload the video file",
  Please_select_an_account: "Please select an account",
  hint_712: "Uploading images can only be in JPG or PNG format!",
  hint_707: "The size of the uploaded image cannot exceed 10MB!",
  hint_710: "Only MP4 format files can be uploaded!",
  hint_711: "The size cannot exceed 200MB!",
  Please_enter_your_nickname: "Please enter your nickname",
  Joined_the_circle: "Joined the circle",
  Import_time: "Import time",
  Disabled: "Disabled",
  Add_Circle: "Add Circle",
  Cannot_be_greater_than_80: "Cannot be greater than 80",
  hint_38:
    "Are you sure you want to disable this user? After disabling, you won't be able to use this account to post updates?",
  hint_39:
    "Are you sure you want to enable this user? Once enabled, you will be able to use this account again to post updates?",
  Dynamic_configuration: "Dynamic configuration",
  Publisher_nickname: "Publisher's nickname",
  Accumulated_likes: "Accumulated likes",
  Accumulated_number_of_comments: "Accumulated number of comments",
  Topic: "Topic",
  Circle: "Circle",
  Release_time: "Release time",
  Remove_this_comment: "Remove this comment",
  Dynamic_permission_configuration: "Dynamic permission configuration",
  Publish_dynamic_user_level_configuration:
    "Publish dynamic user level configuration",
  Name: "Name",
  Level: "Level",
  hint_703:
    "This configuration is the level that ordinary users need to reach when publishing dynamic content.",
  Cannot_be_less_than_1: "Cannot be less than 1",
  Please_fill_in_the_user_level: "Please fill in the user level",
  There_are_currently_no_related_posts: "There are currently no related posts",
  hint_702: "Are you sure you want to remove this post comment?",
  Comment_removed_successfully: "Comment removed successfully",
  hint_704: "Are you sure you want to remove this topic?",
  hint_705: "Are you sure you want to list this topic?",
  Return: "Return",
  hint_706: "You do not have permission to go to this page",
  hint_309: "If you are dissatisfied, please contact your leader",
  Or_you_can_go: "Or you can go",
  Go_back_to_the_homepage: "Go back to the homepage",
  hint_40:
    "Please check if the website address you entered is correct. Click the following button to return to the homepage or send an error report.",
  Return_to_homepage: "Return to homepage",
  hint_41:
    "Hint: 1. The weight is a positive integer, and the larger the value, the higher it comes to the front; 2. The default front end is displayed, and if hidden, the front end is not displayed.",
  Anchor_nickname: "Anchor nickname",
  Please_enter_sorting_weight: "Please enter sorting weight",
  Display: "Display",
  Hidden: "Hidden",
  Associated_channels_categories: "Associated channels/categories",
  Anchor_Name_1: "Anchor Name",
  Please_enter_the_anchor_name_1: "Please enter the anchor name",
  Room_number: "Room number",
  Please_enter_the_room_number: "Please enter the room number",
  Source_of_flow: "Source of flow",
  Anchor_profile_picture: "Anchor profile picture",
  Source_room_number: "Source room number",
  Live_streaming_categories: "Live streaming categories",
  Live_streaming_title: "Live streaming title",
  Stream_address: "Stream Address",
  Forbidden_broadcasting: "Forbidden broadcasting",
  Live_streaming_1: "Live streaming",
  Ended: "Ended",
  Unlocking: "Unlocking",
  All: "All",
  Douyu: "Douyu",
  Tiger_Fang: "Tiger Fang",
  hint_701: "Are you sure you want to unban this anchor?",
  hint_700: "Are you sure you want to ban this anchor?",
  Competition_time: "Competition time",
  Competition_status: "Competition status",
  Please_enter_keywords_2: "Please enter keywords",
  League_Cup_Tournament: "League/Cup Tournament",
  The_home_team: "The home team",
  The_visiting_team: "The visiting team",
  Event_ID: "Event ID",
  Anchor_Qingliu_Live_Room: "Anchor Qingliu Live Room",
  Associated_Channel: "Associated Channel",
  Unknown_status: "Unknown status",
  Not_started: "Not started",
  In_progress: "In progress",
  The_competition_has_been_postponed: "The competition has been postponed",
  Competition_cancelled: "Competition cancelled",
  Direct_room_number: "Direct room number",
  Progress_time: "Progress time",
  Live_streaming_status: "Live streaming status",
  Next_broadcast: "Next broadcast",
  Binding_successful: "Binding successful",
  hint_699: "Are you sure to remove the live broadcast?",
  Anchor_sorting: "Anchor sorting",
  hint_42:
    "Hint: 1. The weight is a positive integer, and the larger the value, the higher the front end; 2. The default front end is displayed, and if hidden, the front end is not displayed;",
  Update: "Update",
  Channel_sorting: "Channel sorting",
  Channel_nickname: "Channel nickname",
  Secondary_classification: "Secondary classification",
  Live_streaming_type: "Live streaming type",
  hint_698:
    "Multiple associated items, please use English commas to separate them",
  Streaming_settings: "Streaming settings",
  End_all_live_broadcasts: "End all live broadcasts",
  Signal_source: "Signal source",
  Push_flow_room: "Push flow room",
  Streaming_Title: "Streaming Title",
  Push_Streaming_Channel: "Push Streaming Channel",
  There_are_currently_no_channels_available:
    "There are currently no channels available",
  Streaming_address: "Streaming address",
  Streaming_address_server_address_: "Streaming address (server address)",
  Copy: "Copy",
  Push_stream_code_stream_key_: "Push stream code (stream key)",
  End_Live: "End Live",
  hint_321: "If there are no available channels, please first:",
  Create_channel: "Create channel",
  You_are_about_to_use_it: "You are about to use it",
  Start_broadcasting: "Start broadcasting",
  Start_live_streaming: "Start live streaming",
  Copy_successful: "Copy successful",
  hint_697: "Please fill in the complete information before submitting",
  hint_696: "Are you sure you want to end the live broadcast?",
  hint_695: "Are you sure you want to end all live broadcasts?",
  Choose_a_live_broadcast_room: "Choose a live broadcast room",
  Live_streaming_room: "Live streaming room",
  League_name: "League name",
  Please_enter_the_league_name: "Please enter the league name",
  Popular: "Popular",
  Normal: "Normal",
  The_continent_where_it_is_located: "The continent where it is located",
  Football: "Football",
  Basketball: "Basketball",
  Date: "Date",
  Start_date: "Start date",
  To: "To",
  End_date: "End date",
  In_the_competition: "In the competition",
  Delay: "Delay",
  Popular_events: "Popular events",
  Keywords_1: "Keywords",
  Please_enter_keywords: "Please enter keywords",
  Batch_editing_of_popular_content: "Batch editing of popular content",
  Batch_creation_of_reserved_events: "Batch creation of reserved events",
  Country_Region: "Country/Region",
  Current_round: "Current round",
  Edit_Appointment_Anchor: "Edit Appointment Anchor",
  Please_choose_to_create_a_schedule: "Please choose to create a schedule",
  Please_select_the_associated_channel: "Please select the associated channel",
  Basketball_schedule_data: "Basketball schedule data",
  No_selected_items: "No selected items",
  Main_Station_Event_ID: "Main Station Event ID",
  Please_enter_the_main_event_ID: "Please enter the main event ID",
  Third_party_in_game_event_ID: "Third party in-game event ID",
  hint_694: "Please enter the event ID for the third-party competition",
  There_are_streaming_anchors: "There are streaming anchors",
  There_is_a_push_flow_room: "There is a push flow room",
  Click_on_the_nickname_modification_announcement:
    "Click on the nickname modification announcement",
  Please_select_a_popular_event: "Please select a popular event",
  Edit_successful: "Edit successful",
  E_sports_streaming: "E-sports streaming",
  Entertainment_stream: "Entertainment stream",
  Create_Popular: "Create Popular",
  Official_Clearflow: "Official Clearflow",
  Anchor_Qingliu: "Anchor Qingliu",
  Score: "Score",
  The_competition_team: "The competition team",
  Create_circles: "Create circles",
  Limit_1M: "Limit 1M",
  hint_900: "Size 790 * 215px",
  Associate_Creator: "Associate Creator",
  Operation_label: "Operation label",
  Add_tags: "Add tags",
  Confirm_creation: "Confirm creation",
  Label_: "Label {num}",
  Please_select_an_image: "Please select an image",
  Please_select_the_associated_creator: "Please select the associated creator",
  Publisher_userid: "Publisher userid",
  Message_content: "Message content",
  Chat_Room: "Chat Room",
  hint_45:
    "After deletion, this message will not be displayed in this circle on the App. Are you sure to delete it?",
  Circle_Introduction: "Circle Introduction",
  Circle_name: "Circle name",
  Circle_avatar: "Circle avatar",
  Creator_1: "Creator",
  Number_of_people_in_the_circle: "Number of people in the circle",
  The_maximum_number_of_people_in_the_circle:
    "The maximum number of people in the circle",
  Circle_Cover: "Circle Cover",
  Circle_dynamics_1: "Circle dynamics",
  Circle_Announcement: "Circle Announcement",
  Creation_time: "Creation time",
  Dissolve_the_circle: "Dissolve the circle",
  Dissolved: "Dissolved",
  Submission_dynamics: "Submission dynamics",
  User_userid: "User userid",
  Number_of_comments: "Number of comments",
  Like_count: "Like count",
  Preview: "Preview",
  Setting_up_circle_announcements: "Setting up circle announcements",
  Add_Announcement: "Add Announcement",
  Please_enter_the_link_name: "Please enter the link name",
  Display_link: "Display link",
  Jump_link: "Jump link",
  Please_enter_the_jump_link: "Please enter the jump link",
  To_be_reviewed: "To be reviewed",
  Listed: "Listed",
  Offline_1: "Offline",
  Rejected: "Rejected",
  Deleted: "Deleted",
  hint_692:
    "Please fill in the complete announcement link information before submitting",
  The_circle_has_disbanded: "The circle has disbanded",
  Are_you_sure_you_want_to_dissolve_this_circle_:
    "Are you sure you want to dissolve this circle?",
  Dissolution: "Dissolution",
  hint_44: "Are you sure you want to '{status}' this dynamic?",
  hint_693: "Are you sure you want to reject this dynamic?",
  Successfully: "Successfully",
  Remove_from_shelves: "Remove from shelves",
  Listing: "Listing",
  Circle_dynamics: "Circle dynamics",
  Creator_nickname: "Creator nickname",
  Creator_userid: "Creator userid",
  In_operation: "In operation",
  View_details: "View details",
  Chat_messages: "Chat messages",
  Edit_anchor_information: "Edit anchor information",
  Review: "Review",
  Anchor_Name: "Anchor Name",
  Guild: "Guild",
  Please_select_a_guild: "Please select a guild",
  Split_ratio: "Split ratio",
  Please_select_the_sharing_ratio: "Please select the sharing ratio",
  Basic_salary: "Basic salary",
  Please_enter_the_base_salary: "Please enter the base salary",
  Review_details: "Review details",
  Review_details_1: "Details",
  Please_enter_the_ID_number: "Please enter the ID number",
  Please_enter_the_host_nickname: "Please enter the host's nickname",
  hint_43:
    "Are you sure you want to remove this user from the group owner's authorization list?",
  Add_channel: "Add channel",
  Default_sorting: "Default sorting",
  Channel_ID: "Channel ID",
  Icon: "Icon",
  Page_template: "Page template",
  Tab_name: "Tab name",
  Channel_Type: "Channel Type",
  hint_46:
    "Only jpg/png files can be uploaded, and the image size needs to be 120X120.",
  Please_enter_a_valid_tab_name_: "Please enter a valid tab name!",
  Enter_tab_name: "Enter tab name",
  Content_ID: "Content ID",
  Please_enter_ID: "Please enter ID",
  Content_type: "Content type",
  Topped: "Topped",
  Top: "Top",
  Cancel_Topping: "Cancel Topping",
  Restore_Display: "Restore Display",
  Expiration_time: "Expiration time",
  Select_date_and_time: "Select date and time",
  Sealing_the_picture: "Sealing the picture",
  real_time_info: "real-time info",
  Short_video: "Short video",
  Dynamic: "Dynamic",
  Brocade_Bag: "Brocade Bag",
  The_expiration_time_has_not_been_set_yet:
    "The expiration time has not been set yet",
  Please_select_a_future_time: "Please select a future time",
  Homepage_Content_Management: "Homepage Content Management",
  Top_management_on_homepage: "Top management on homepage",
  Please_enter_information_keywords: "Please enter information keywords",
  Keywords: "Keywords",
  Batch_publishing: "Batch publishing",
  Batch_scheduled_release: "Batch scheduled release",
  Thumbnails: "Thumbnails",
  Title_1: "Title",
  Crawling_time: "Crawling time",
  hint_47:
    "You can choose to schedule the release at any time within the next week after 5 minutes, and you can re edit and cancel it before the set time.",
  Timed_release: "Timed release",
  hint_48:
    "After publishing, modifications and revocations cannot be made. Do you want to confirm publishing?",
  hint_691:
    "No information has been selected. Please select the information before publishing!",
  Please_choose_a_reasonable_publishing_time:
    "Please choose a reasonable publishing time",
  Edit_information: "Edit information",
  New_information: "New information",
  Please_fill_in_the_necessary_title_and_author:
    "Please fill in the necessary title and author",
  Information_Title: "Information Title",
  Comment_content: "Comment content",
  hint_49:
    "After deletion, this comment will not be displayed on the app or PC, are you sure to delete it?",
  hint_50:
    "Are you sure you want to cancel publishing? After cancellation, this message will enter the draft box",
  hint_51:
    "Are you sure you want to remove it? After removal, this message will not appear on the mobile end!",
  Cancel_Top_Time: "Cancel Top Time",
  Publishing_Library: "Publishing Library",
  Top_Library: "Top Library",
  Crawling_website_titles: "Crawling website titles",
  Crawling_website_links: "Crawling website links",
  Crawling_time_period: "Crawling time period",
  Set_crawling_time_period: "Set crawling time period",
  End_time: "End time",
  Select_a_time_range: "Select a time range",
  hint_52:
    "If disabled, the website data will not be crawled. Are you sure you want to disable it?",
  hint_690: "Are you sure you want to enable this configuration?",
  hint_689: "Are you sure you want to delete this message?",
  Prize_Name: "Prize Name",
  Please_enter_the_name_of_the_prize: "Please enter the name of the prize",
  Prize_type: "Prize type",
  Query: "Query",
  Add_prizes_1: "Add prizes",
  Prize_icon: "Prize icon",
  Please_enter_the_number_of_prizes: "Please enter the number of prizes",
  quantity: "quantity",
  Only_jpg_png_gif_files_can_be_uploaded:
    "Only jpg/png/gif files can be uploaded",
  Modify: "Modify",
  New: "New",
  Virtual_currency: "Virtual currency",
  Experience_value: "Experience value",
  hint_53: "Uploading images can only be in JPG, PNG, or GIF format!",
  Winning_number: "Winning number",
  Please_enter_the_winning_number: "Please enter the winning number",
  UID: "UID",
  Please_enter_UID: "Please enter UID",
  Obtain_time: "Obtain time",
  Export: "Export",
  hint_688: "Live broadcast room smashing golden eggs - prize record",
  Basic_Settings: "Basic Settings",
  Red_envelope_rain_name: "Red envelope rain name",
  Edit_Red_Envelope_Rain_Library: "Edit Red Envelope Rain Library",
  New_Red_Envelope_Rain_Warehouse: "New Red Envelope Rain Warehouse",
  Please_enter_the_name_of_the_red_envelope_rain:
    "Please enter the name of the red envelope rain",
  Trigger_type: "Trigger type",
  Goal_scoring_events: "Goal scoring events",
  Event_timeline: "Event timeline",
  Specify_a_time_point: "Specify a time point",
  Maximum_number_of_red_envelope_rain_wheels:
    "Maximum number of red envelope rain wheels",
  Please_enter_the_number_of_rounds: "Please enter the number of rounds",
  hint_54:
    "0 means no red envelope rain, which is the upper limit of the maximum number of red envelope rain rounds in a game.",
  hint_687:
    "The maximum number of red envelopes in a single round for a single user",
  hint_55:
    "0 means no red envelopes can be obtained. Once the number of red envelopes is reached, no more red envelopes will be obtained.",
  Fixed_point_in_time: "Fixed point in time",
  Select_date: "Select date",
  Probability_of_winning_a_prize_red_envelope:
    "Probability of winning a prize red envelope",
  Please_enter_the_probability: "Please enter the probability",
  hint_56:
    "0 means it is impossible to win a red envelope, and this is the probability that the user will win a prize red envelope.",
  hint_686: "Please enter the maximum number of red envelope rain wheels",
  Award_Pool_Settings: "Award Pool Settings",
  Add_prizes: "Add prizes",
  Number_of_individual_red_envelope_rewards:
    "Number of individual red envelope rewards",
  Please_enter_the_number: "Please enter the number",
  Number_of_red_envelopes: "Number of red envelopes",
  Please_enter_the_quantity: "Please enter the quantity",
  Prize_weight: "Prize weight",
  Please_fill_in_the_fixed_time_point: "Please fill in the fixed time point",
  Please_select_a_time_point_for_the_competition:
    "Please select a time point for the competition",
  hint_685: "Only positive integer numbers from 0 to 100 can be configured",
  Please_add_at_least_one_prize: "Please add at least one prize",
  hint_684: "Please fill in the complete prize information before submitting",
  hint_57:
    "Input error, the number of individual red envelope rewards and the number of red envelopes can only be configured as positive integers ranging from 1 to 99999.",
  Add_has_reached_the_limit: "Add has reached the limit",
  Golden_Egg_Switch: "Golden Egg Switch",
  Basic_Information_Configuration: "Basic Information Configuration",
  APP_background_image: "APP background image",
  APP_Title_Image: "APP Title Image",
  The_total_probability_must_be_10000: "The total probability must be 10000",
  Red_envelope_rain_storage_details: "Red envelope rain storage details",
  hint_58:
    "0 means no red envelope rain, which is the upper limit of the maximum number of red envelope rain rounds in a game.",
  Wheel: "Wheel",
  Pieces: "Pieces",
  Task_type: "Task type",
  Reward_Name: "Reward Name",
  Task_Title: "Task Title",
  Task_icon: "Task icon",
  Task_count: "Task count",
  Application_channels: "Application channels",
  Edit_task: "Edit task",
  Reward_type: "Reward type",
  Reward_quantity: "Reward quantity",
  Please_enter_the_number_of_rewards: "Please enter the number of rewards",
  Please_select_the_reward_type_icon: "Please select the reward type icon",
  Please_select_the_application_channel:
    "Please select the application channel",
  Task_title_cannot_be_empty: "Task title cannot be empty",
  Task_count_cannot_be_empty: "Task count cannot be empty",
  To_be_effective: "To be effective",
  In_effect: "In effect",
  Daily_tasks: "Daily tasks",
  Novice_Task: "Novice Task",
  Sign_in_task: "Sign in task",
  Attendance_Record: "Attendance Record",
  Please_enter_the_task_title: "Please enter the task title",
  Check_in_switch: "Check in switch",
  No_1: "No",
  Number_of_check_in_days: "Number of check-in days",
  Reward_details: "Reward details",
  Reward_icon: "Reward icon",
  Edit_check_in: "Edit check-in",
  Lottery: "Lottery",
  Physical_object: "Physical object",
  Please_upload_the_reward_type_icon: "Please upload the reward type icon",
  Sign_in_has_been_closed: "Sign in has been closed",
  Check_in_has_been_opened: "Check in has been opened",
  Please_fill_in_the_quantity: "Please fill in the quantity",
  Please_enter_the_room_number_for_sending:
    "Please enter the room number for sending",
  Send_room_number: "Send room number",
  Sending_time: "Sending time",
  Details_of_receiving_red_envelopes: "Details of receiving red envelopes",
  hint_683: "There are currently no details of receiving red envelopes",
  Anchor_room_number: "Anchor room number",
  Please_enter_the_host_room_number: "Please enter the host room number",
  Remove_permissions: "Remove permissions",
  Add_anchor: "Add anchor",
  Time: "Time",
  Anchor_userID: "Anchor userID",
  Please_enter_the_anchor_userID: "Please enter the anchor userID",
  Betting_amount: "Betting amount",
  Prophecy_betting_list: "Prophecy betting list",
  Please_enter_the_name_of_the_main_team:
    "Please enter the name of the main team",
  Please_enter_the_name_of_the_visiting_team:
    "Please enter the name of the visiting team",
  Please_enter_the_status: "Please enter the status",
  Team_PK_ID: "Team PK ID",
  The_winner: "The winner",
  Number_of_gift_givers_1: "Number of gift givers",
  Total_value_in_gold_coins: "Total value (in gold coins)",
  The_losing_side: "The losing side",
  Binding_events: "Binding events",
  Please_enter_the_binding_event: "Please enter the binding event",
  Bind_anchor: "Bind anchor",
  Please_enter_the_bound_anchor: "Please enter the bound anchor",
  Activity_Strategy_Name: "Activity Strategy Name",
  hint_682: "Please enter the name of the activity strategy",
  Red_envelope_rain_claim_details: "Red envelope rain claim details",
  Effective_anchor_room_details: "Effective anchor room details",
  To_be_published: "To be published",
  Published: "Published",
  Canceled: "Canceled",
  hint_681: "Are you sure you want to cancel publishing?",
  Main_switch: "Main switch",
  Policy_Name: "Policy Name",
  Please_enter_a_policy_name: "Please enter a policy name",
  Add_Policy: "Add Policy",
  Red_envelope_rain_storage: "Red envelope rain storage",
  Red_envelope_rain_type: "Red envelope rain type",
  Event_red_envelope_rain: "Event red envelope rain",
  Live_broadcast_room_chat_room: "Live broadcast room/chat room",
  Event_type: "Event type",
  Distribute_users: "Distribute users",
  hint_60:
    "Please enter the user UID, multiple UIDs separated by English commas",
  Please_select_the_type_of_red_envelope_rain:
    "Please select the type of red envelope rain",
  Please_select_an_event: "Please select an event",
  Please_select_the_red_envelope_rain_storage:
    "Please select the red envelope rain storage",
  Edit_Policy: "Edit Policy",
  Strategy_details: "Strategy details",
  hint_61:
    "Offline this activity strategy will result in the activity being taken offline from the front-end. Are you sure to take it offline?",
  Offline_2: "Offline",
  Live_room_room_number: "Live room room number",
  hint_680: "Please enter the room number for the live broadcast room",
  Configuration_Management: "Configuration Management",
  Prize_configuration: "Prize configuration",
  Prize_Record: "Prize Record",
  Anchor_cumulative_share: "Anchor cumulative share",
  Option_A_cumulative_betting: "Option A cumulative betting",
  Option_A: "Option A",
  Option_B: "Option B",
  Option_B_cumulative_betting: "Option B cumulative betting",
  Results: "Results",
  Number_of_bets: "Number of bets",
  Anchor_sharing_3: "Anchor sharing (3%)",
  Platform_share_2: "Platform share (2%)",
  The_anchor_prophecy: "The anchor's prophecy",
  The_prophecy_of_the_whole_nation: "The prophecy of the whole nation",
  Login_time: "Login time",
  Online_duration: "Online duration",
  Inviters_nickname: "Inviting person's nickname",
  Invitee_UID_: "Invitee UID:",
  Invitation_time: "Invitation time",
  Inviter_payment_method: "Inviter's payment method",
  Alipay: "Alipay",
  WeChat_account: "WeChat account",
  QQ_account: "QQ account",
  Inviting_person_rewards: "Inviting person rewards",
  Distributed: "Distributed",
  Not_passed: "Not passed",
  Accumulated: "Accumulated",
  Invitee_nickname: "Invitee's nickname",
  Invitee_rewards: "Invitee rewards",
  Select_distribution_type: "Select distribution type",
  Please_enter_a_rule_description: "Please enter a rule description",
  Are_you_sure_to_cancel_the_distribution_:
    "Are you sure to cancel the distribution?",
  APP_user_login_statistics: "APP user login statistics",
  hint_676: "The inviter's reward cannot be less than 0",
  hint_677: "The inviter's reward cannot exceed 99999",
  hint_675: "The reward for invitees cannot be less than 0",
  hint_678: "The invitee's reward cannot exceed 99999",
  Room_type: "Room type",
  The_ban_was_successful: "The ban was successful",
  hint_62:
    "After deletion, this message will not be displayed in the live broadcast/chat room on the App, PC, or H5 end. Are you sure to hide it?",
  Please_enter_the_user_UID: "Please enter the user UID",
  Prohibition_time: "Prohibition time",
  Forbidden_live_broadcast_room: "Forbidden live broadcast room",
  Prohibition_type: "Prohibition type",
  Prohibition_reasons: "Prohibition reasons",
  Operator_UID: "Operator UID",
  Successfully_unblocked: "Successfully unblocked",
  Opening_duration: "Opening duration",
  The_duration_of_the_current_live_broadcast:
    "The duration of the current live broadcast",
  Current_number_of_views: "Current number of views",
  hint_674: "The number of views in the current live broadcast (deduplication)",
  Current_number_of_online_users: "Current number of online users",
  hint_673:
    "The number of online viewers of the current live broadcast (de duplication)",
  The_highest_number_of_online_users: "The highest number of online users",
  hint_672:
    "The highest number of online viewers for the current live broadcast (de duplication)",
  hint_63:
    "Statistics include users who have not logged in, and users who have not logged in have not been deduplicated.",
  Field_Description: "Field Description",
  Anchor_UID: "Anchor UID",
  Live_Channel: "Live Channel",
  Start_time_1: "Start time",
  Downtime: "Downtime",
  Opening_duration_minutes_: "Opening duration (minutes)",
  Accumulated_views: "Accumulated views",
  Accumulated_number_of_online_users: "Accumulated number of online users",
  Average_viewing_time_minutes_: "Average viewing time (minutes)",
  The_entire_live_broadcast_data: "The entire live broadcast data",
  Edit_data: "Edit data",
  Add_announcement_link: "Add announcement link",
  Announcement_link: "Announcement link",
  Please_enter_the_display_link: "Please enter the display link",
  Recommended_display: "Recommended display",
  Browser_Open: "Browser Open",
  Display_end: "Display end",
  Mobile_end: "Mobile end",
  PC_end: "PC end",
  Application_time: "Application time",
  Global_announcement_adjustment: "Global announcement adjustment",
  Sports_type: "Sports type",
  On_the_front_of_the_ID_card: "On the front of the ID card",
  On_the_back_of_the_ID_card: "On the back of the ID card",
  Holding_an_ID_card: "Holding an ID card",
  Reason_for_rejection: "Reason for rejection",
  Lifting_the_ban: "Lifting the ban",
  Refused_to_pass: "Refused to pass",
  Please_enter_the_anchor_name: "Please enter the anchor's name",
  ID_number: "ID number",
  Front_photo_of_ID_card: "Front photo of ID card",
  Back_photo_of_ID_card: "Back photo of ID card",
  Housing_Management_Uid: "Housing Management Uid",
  Please_enter_the_housing_management_Uid:
    "Please enter the housing management Uid",
  Housing_management_nickname: "Housing management nickname",
  hint_671: "Please enter the housing management nickname",
  Add_property_management: "Add property management",
  Cancel_housing_management: "Cancel housing management",
  Please_enter_your_name: "Please enter your name",
  hint_670: "Please enter Chinese characters for your name",
  Approved: "Approved",
  Rejected_for_review: "Rejected for review",
  Prohibited: "Prohibited",
  The_ID_photo_is_blurry: "The ID photo is blurry",
  The_ID_number_does_not_match_the_ID_photo:
    "The ID number does not match the ID photo",
  My_ID_card_does_not_match_me: "My ID card does not match me",
  ID_card_number_is_blocked: "ID card number is blocked",
  Facial_occlusion: "Facial occlusion",
  Room: "Room",
  Please_select_the_reason: "Please select the reason",
  hint_669: "Please enter the announcement name or link to replace",
  Are_you_sure_you_want_to_pass_the_review_:
    "Are you sure you want to pass the review?",
  hint_301:
    "Are you sure you want to restore the live streaming permission of this anchor?",
  Are_you_sure_you_want_to_ban_this_anchor_:
    "Are you sure you want to ban this anchor?",
  Main_account_UID: "Main account UID",
  Please_enter_the_main_account_UID: "Please enter the main account UID",
  Merge_account_UID: "Merge account UID",
  Please_enter_the_merged_account_UID: "Please enter the merged account UID",
  Add_Merge: "Add Merge",
  Master_account_nickname: "Master account nickname",
  Merge_account_nicknames: "Merge account nicknames",
  Merge_time: "Merge time",
  hint_64:
    "After merging accounts, the merged anchor account will be used for live streaming display.",
  Merge_accounts: "Merge accounts",
  Refresh: "Refresh",
  hint_668: "Note: There is a slight delay in online data",
  Note: "Note",
  Basic_information_1: "Basic information",
  Anchor_Information: "Anchor Information",
  Real_time_live_streaming_data: "Real time live streaming data",
  hint_667: "The current flow information is not available!",
  Anchor_Management: "Anchor Management",
  Merge_anchor_accounts: "Merge anchor accounts",
  Identify_the_main_switch: "Identify the main switch",
  The_current_status_is: "The current status is",
  There_is_currently_no_data_available: "There is currently no data available",
  Jian_Huang: "Jian Huang",
  Fear_prevention: "Fear prevention",
  Jianzheng: "Jianzheng",
  hint_303:
    "Are you sure you want to cut off the flow of this live broadcast room?",
  Thermal_value_coefficient: "Thermal value coefficient",
  Heat_value_base: "Heat value base",
  Modified_by: "Modified by",
  Latest_modification_time: "Latest modification time",
  Heat_value_setting: "Heat value setting",
  Role_Name: "Role Name",
  Whether_to_enable: "Whether to enable",
  Live_broadcast_room_type: "Live broadcast room type",
  Initial_heat_value: "Initial heat value",
  Last_editing_time: "Last editing time",
  Edit_heat_value: "Edit heat value",
  Initial_heating_value_: "Initial heating value:",
  Please_enter_the_heat_value: "Please enter the heat value",
  Please_fill_in_the_heat_value: "Please fill in the heat value",
  Please_enter_the_anchor_UID: "Please enter the anchor UID",
  Live_streaming_services: "Live streaming services",
  Batch_editing: "Batch editing",
  All_anchors: "All anchors",
  Platform_anchor_stream: "Platform anchor stream",
  Event_clearance: "Event clearance",
  Anchor_event_flow: "Anchor event flow",
  Esports_Entertainment_Stream: "Esports Entertainment Stream",
  Live_streaming_2: "Live streaming",
  Field: "Field",
  hint_665: "Are you sure you want to remove this live broadcast room?",
  hint_664: "Are you sure you want to ban this live broadcast room?",
  This_PV: "This PV",
  UV_in_this_field: "UV in this field",
  Peak_value: "Peak value",
  Accumulated_viewing_time_minutes_: "Accumulated viewing time (minutes)",
  Total_number_of_followers: "Total number of followers",
  Number_of_followers_in_this_session: "Number of followers in this session",
  The_number_of_levels_taken_in_this_game:
    "The number of levels taken in this game",
  Net_increase_in_levels_in_this_game: "Net increase in levels in this game",
  Live_broadcast_session_data_of_anchors:
    "Live broadcast session data of anchors",
  Forbidden_List: "Forbidden List",
  Team: "Team",
  Please_enter_the_team: "Please enter the team",
  Push_order_status: "Push order status",
  hint_306:
    "Are you sure you want to hide this tweet? The front end will not be visible after hiding",
  hint_305:
    "Are you sure you want to display this push order? The front end will be visible after it is displayed",
  Please_select_the_correction_result: "Please select the correction result",
  Reporting_time: "Reporting time",
  Whistleblower_userid: "Whistleblower userid",
  Enter_the_whistleblower_userid: "Enter the whistleblower's userid",
  Enter_room_number: "Enter room number",
  Reply_or_not: "Reply or not",
  Reason_for_reporting: "Reason for reporting",
  Reply_content: "Reply content",
  hint_663: "Please enter the reply content (within 100 words)",
  The_reply_content_cannot_be_empty: "The reply content cannot be empty",
  Homepage_Configuration: "Homepage Configuration",
  Level_animation_switch: "Level animation switch",
  Cat_coin_animation_switch: "Cat coin animation switch",
  Enter_animation_switch_: "Enter animation switch:",
  APP_membership_background: "APP membership background",
  Membership_Benefits_Background: "Membership Benefits Background",
  APP_H5_Membership_Benefits_Background:
    "APP/H5 Membership Benefits Background",
  Background_of_PC_Membership_Benefits: "Background of PC Membership Benefits",
  hint_409: "Note: The size is 926x126",
  VIP_discount_configuration: "VIP discount configuration",
  Please_enter_VIP: "Please enter VIP",
  hint_402: "Please enter VIP discount (e.g. 0.9)",
  hint_403: "Please enter the VIP discount name (e.g. 10% off)",
  Edit_Channel: "Edit Channel",
  Add_a_new_channel: "Add a new channel",
  Channel_avatar: "Channel avatar",
  hint_401: "Size: 114 * 114, can only upload jpg/png files",
  Channel_Cover: "Channel Cover",
  hint_405: "Size: 522 * 294, can only upload jpg/png files",
  hint_406:
    "Tip: Automatically capture the streaming screen, use default cover after failure",
  hint_407:
    "Are you sure you want to restore the live streaming permission for this channel?",
  hint_408: "Are you sure you want to ban this channel?",
  Schedule_data: "Schedule data",
  Edit_Announcement: "Edit Announcement",
  Create_Announcement: "Create Announcement",
  Announcement_type: "Announcement type",
  Image_and_text_links: "Image and text links",
  Picture_and_text_messages: "Picture and text messages",
  Activity_link: "Activity link",
  Announcement_Title: "Announcement Title",
  Cover_image: "Cover image",
  Whether_to_push: "Whether to push",
  Select_content: "Select content",
  Activity_Title: "Activity Title",
  Event_Introduction: "Event Introduction",
  Please_select_the_announcement_type: "Please select the announcement type",
  Please_select_the_publishing_time: "Please select the publishing time",
  Please_enter_the_announcement_title: "Please enter the announcement title",
  Please_enter_the_activity_title: "Please enter the activity title",
  Please_enter_the_announcement_content:
    "Please enter the announcement content",
  Please_fill_in_the_activity_introduction:
    "Please fill in the activity introduction",
  Please_enter_the_text_message_content:
    "Please enter the text message content",
  Send_to: "Send to",
  Upload_data: "Upload data",
  Download_List_Excel_Template: "Download List Excel Template",
  Receive_user_list: "Receive user list",
  All_platform_users: "All platform users",
  Not_sent: "Not sent",
  Sent: "Sent",
  Cancel_sending: "Cancel sending",
  Specify_user: "Specify user",
  Please_enter_the_theme: "Please enter the theme",
  Multiple_separated_by_commas: "Multiple separated by commas",
  Template_ID: "Template ID",
  Instant_sending: "Instant sending",
  Please_select_template_ID: "Please select template ID",
  Are_you_sure_to_send_to_all_users_: "Are you sure to send to all users?",
  Sending_successful: "Sending successful",
  Channel_template_ID: "Channel template ID",
  Please_enter_the_channel_template_ID: "Please enter the channel template ID",
  Template_content: "Template content",
  Please_enter_template_content: "Please enter template content",
  Please_select_a_channel: "Please select a channel",
  Please_enter_your_signature: "Please enter your signature",
  Alibaba_Cloud: "Alibaba Cloud",
  Notification: "Notification",
  Marketing: "Marketing",
  Verification_code: "Verification code",
  First_select_the_SMS_type: "First select the SMS type",
  Please_fill_in_the_channel_template_ID:
    "Please fill in the channel template ID",
  Message_type: "Message type",
  New_announcement: "New announcement",
  Official_announcement_title: "Official announcement title",
  Unpublish: "Unpublish",
  Jump_type: "Jump type",
  Push_time: "Push time",
  Please_enter_the_bag_ID: "Please enter the bag ID",
  Decimal_cannot_be_entered: "Decimal cannot be entered",
  Please_enter_the_H5_link: "Please enter the H5 link",
  Push_details: "Push details",
  Edit_push: "Edit push",
  Add_push: "Add push",
  Push_order_page: "Push order page",
  Please_select_the_push_time: "Please select the push time",
  Please_select_the_effective_time: "Please select the effective time",
  hint_660: "Are you sure you want to cancel this push task?",
  Add_menu: "Add menu",
  catalogue: "catalogue",
  Menu: "Menu",
  Menu_icons: "Menu icons",
  Menu_Title: "Menu Title",
  Routing_address: "Routing address",
  Component_Name: "Component Name",
  Component_path: "Component path",
  Whether_to_hide: "Whether to hide",
  Superior_categories: "Superior categories",
  Select_superior_category: "Select superior category",
  Menu_Name: "Menu Name",
  Permission_identification: "Permission identification",
  Edit_menu: "Edit menu",
  Please_enter_the_menu_title: "Please enter the menu title",
  Please_enter_the_routing_address: "Please enter the routing address",
  Please_enter_the_component_name: "Please enter the component name",
  Please_enter_the_component_path: "Please enter the component path",
  Please_select_the_superior_category: "Please select the superior category",
  Top_level_categories: "Top level categories",
  hint_661: "Are you sure you want to delete this menu?",
  Please_enter_the_role_name: "Please enter the role name",
  Add_new_role: "Add new role",
  Role_ID: "Role ID",
  Creator: "Creator",
  Authorization: "Authorization",
  Edit_Role: "Edit Role",
  Audit_scenario: "Audit scenario",
  Please_enter_the_review_scenario: "Please enter the review scenario",
  Review_page: "Review page",
  Edit_audit_scenario_account: "Edit audit scenario account",
  Operations_Manager_Account: "Operations Manager Account",
  Please_enter_the_review_account: "Please enter the review account",
  Please_enter_your_account: "Please enter your account",
  New_users: "New users",
  Bind_characters: "Bind characters",
  Latest_reset_time: "Latest reset time",
  Please_select_a_role: "Please select a role",
  Input_cannot_be_empty: "Input cannot be empty",
  hint_6601: "Special characters and numbers cannot be entered",
  Edit_Users: "Edit Users",
  hint_659: "Are you sure you want to deactivate this account?",
  Account_deactivated: "Account deactivated",
  hint_658: "Are you sure you want to reset this account password?",
  Reset_successful: "Reset successful",
  The_new_password_is: "The new password is",
  Gift_Name: "Gift Name",
  Please_enter_the_gift_name: "Please enter the gift name",
  Gift_category: "Gift category",
  VIP_gift: "VIP gift",
  Add_gift: "Add gift",
  Gift_unit_price_in_gold_coins_: "Gift unit price (in gold coins)",
  Banner_gift: "Banner gift",
  Full_screen_gift: "Full screen gift",
  Gift_icon: "Gift icon",
  Size_does_not_exceed_1MB_: "Size does not exceed 1MB!",
  Enable_floating_screen: "Enable floating screen",
  Floating_screen_category: "Floating screen category",
  hint_657: "Please select the floating screen category",
  Please_select_VIP_level: "Please select VIP level",
  hint_656: "VIP users at or above this level can use it",
  hint_601:
    "You can input up to 4 Chinese characters, 8 English letters, or 8 numbers.",
  Please_enter_the_price: "Please enter the price",
  hint_602:
    "The unit price of the gift cannot be greater than 9999999 or less than 0",
  Please_upload_the_gift_icon: "Please upload the gift icon",
  Please_upload_the_playback_effect: "Please upload the playback effect",
  Please_upload_the_preview_effect: "Please upload the preview effect",
  Edit_Gift: "Edit Gift",
  Please_choose_a_gift_type: "Please choose a gift type",
  Please_select_the_floating_screen_type:
    "Please select the floating screen type",
  Please_select_whether_combo_is_possible:
    "Please select whether combo is possible",
  Only_SVGA_files_can_be_uploaded_: "Only SVGA files can be uploaded!",
  Only_GIF_files_can_be_uploaded: "Only GIF files can be uploaded",
  hint_66:
    "If the gift is taken offline, it will be taken offline from the gift warehouse, that is, the gift will not appear in the configuration gift strategy. Are you sure to take it offline?",
  Successfully_taken_offline: "Successfully taken offline",
  hint_67:
    "If the gift is launched, it will be re launched to the gift warehouse, that is, the gift will reappear in the configuration gift strategy. Are you sure to launch it?",
  Gift_data_statistics: "Gift data statistics",
  User_gift_details: "User gift details",
  Gift_List: "Gift List",
  Distribution_scope: "Distribution scope",
  Effective_time: "Effective time",
  Last_modified_time: "Last modified time",
  Last_Editor: "Last Editor",
  hint_68:
    "Please enter the gift ID, multiple gifts should be separated by English commas, and the input order will be the front-end gift display order.",
  Sending_gifts: "Sending gifts",
  hint_72:
    "Deleting this gift policy will result in its deletion. Are you sure you want to delete it?",
  hint_70:
    "Offline: This gift strategy will cause the gifts in the strategy room to be taken offline from the front end. Are you sure to take them offline?",
  hint_71:
    "If the gift strategy is launched, the gifts in the room will be launched from the front-end. Are you sure to launch it?",
  Expert_type: "Expert type",
  Import_experts: "Import experts",
  Introduction: "Introduction",
  Hit_rate: "Hit rate",
  Recent_status: "Recent status",
  The_highest_consecutive_red: "The highest consecutive red",
  hint_604: "Recently, it has been continuously popular.",
  Number_of_proposals: "Number of proposals",
  Number_of_buyers: "Number of buyers",
  Accumulated_total_revenue: "Accumulated total revenue",
  Settlable_income: "Settlable income",
  Add_Expert: "Add Expert",
  User_userid_cannot_be_empty: "User userid cannot be empty",
  Please_enter_the_league_and_team_name:
    "Please enter the league and team name",
  Home_team_vs_away_team: "Home team vs away team",
  League: "League",
  Choose_a_competition: "Choose a competition",
  Event_information: "Event information",
  Select_recommendation: "Select recommendation",
  Yazhi: "Yazhi",
  The_main_winner: "The main winner",
  Customer_win: "Customer win",
  Small_and_large_balls: "Small and large balls",
  Greater_than: "Greater than",
  ball: "ball",
  European_Compensation: "European Compensation",
  A_draw: "A draw",
  Wanbo: "Wanbo",
  Le_Dong: "Le Dong",
  Macau: "Macau",
  crown: "crown",
  hint_655: "Please choose the gameplay first",
  Add_recommendation_group: "Add recommendation group",
  Recommended_group_name: "Recommended group name",
  Circle_details: "Circle details",
  Number_of_recommended_circles: "Number of recommended circles",
  Resource_bit_name: "Resource bit name",
  Expert_details: "Expert details",
  Number_of_recommended_experts: "Number of recommended experts",
  Pause: "Pause",
  Add_anchors: "Add anchors",
  X_connected_red: "X connected red",
  Near_X_in_X: "Near X in X",
  Return_rate: "Return rate",
  hint_653: "Please fill in at least one complete anchor information",
  hint_654: "Are you sure you recommend this group?",
  hint_652: "Are you sure to pause this recommendation group?",
  Up_to_20_can_be_configured: "Up to 20 can be configured",
  Edit_selling_price: "Edit selling price",
  First_gear: "First gear",
  Second_gear: "Second gear",
  Third_gear: "Third gear",
  Release_Brocade_Bag: "Release Brocade Bag",
  Brocade_Bag_Type_: "Brocade Bag Type:",
  Football_recommendations: "Football recommendations",
  Basketball_recommendations: "Basketball recommendations",
  hint_651: "Please select the recommended option for event settings",
  Please_enter_the_publisher_account: "Please enter the publisher account",
  Please_enter_the_recommendation_reason:
    "Please enter the recommendation reason",
  Please_set_the_publishing_time: "Please set the publishing time",
  hint_650: "The game has ended and a release time needs to be selected",
  Expert_management: "Expert management",
  Popular_experts: "Popular experts",
  Hit: "Hit",
  Returned: "Returned",
  To_be_returned: "To be returned",
  On_sale: "On sale",
  Cut_off_orders: "Cut off orders",
  Order_status: "Order status",
  Purchase_time: "Purchase time",
  Please_enter_the_competition: "Please enter the competition",
  Competition: "Competition",
  Publisher: "Publisher",
  Please_enter_the_publisher: "Please enter the publisher",
  Purchase_successful: "Purchase successful",
  hint_73:
    "Are you sure you want to remove it? After removal, this short video will not appear on the mobile end?",
  Bullet_Screen_Library: "Bullet Screen Library",
  Add_bullet_library: "Add bullet library",
  Batch_Import: "Batch Import",
  Add_bullet_comments: "Add bullet comments",
  Bullet_screen_content: "Bullet screen content",
  Recent_Editors: "Recent Editors",
  Import_data: "Import data",
  Edit_bullet_screen: "Edit bullet screen",
  hint_649:
    "Support adding multiple bullet comments, separated by English commas, with a maximum of 1000 bullet comments added at a time.",
  Label_One: "Label One",
  Label_2: "Label 2",
  Label_Three: "Label Three",
  Label_Four: "Label Four",
  Label_Five: "Label Five",
  Please_enter_the_bullet_content: "Please enter the bullet content",
  hint_648: "The upload size cannot exceed 10MB!",
  Successfully_added_barrage: "Successfully added barrage",
  Update_barrage_successful: "Update barrage successful",
  hint_647: "Uploading files can only be in xls format!",
  Import_failed: "Import failed",
  Import_successful: "Import successful",
  hint_646: "Are you sure you want to delete this barrage?",
  Number_of_fans: "Number of fans",
  Live_broadcast_time: "Live broadcast time",
  Duration_minutes_: "Duration (minutes)",
  Number_of_gift_givers: "Number of gift givers",
  Number_of_gifts_given: "Number of gifts given",
  Live_room_revenue_feather_: "Live room revenue (feather)",
  Guilds: "Guilds",
  Settlable_income_yuan_: "Settlable income (yuan)",
  Reward_and_punishment_income_yuan: "Reward and punishment income (yuan)",
  Total_revenue_yuan_: "Total revenue (yuan)",
  Adjustment_type_: "Adjustment type:",
  hint_645: "Reasons for rewards and punishments:",
  Settlement_bill_review: "Settlement bill review",
  Originating_Reviewer: "Originating Reviewer",
  Operations_Manager_: "Operations Manager",
  Reissue: "Reissue",
  Through: "Through",
  Please_enter_the_amount: "Please enter the amount",
  Please_enter_the_reason: "Please enter the reason",
  Guild_name: "Guild name",
  Please_enter_the_guild_name: "Please enter the guild name",
  Guild_ID: "Guild ID",
  Receiving_account_name: "Receiving account name",
  Opening_Bank: "Opening Bank",
  Bank_card_number: "Bank card number",
  Account_opening_address: "Account opening address",
  Number_of_anchors: "Number of anchors",
  Please_fill_in_your_phone_number: "Please fill in your phone number",
  Please_fill_in_your_bank_card_number: "Please fill in your bank card number",
  hint_75:
    "After activation, the guild can be assigned to the anchor. Are you sure you want to enable this guild?",
  Resource_location: "Resource location",
  Is_it_effective_: "Is it effective?",
  Jump_position: "Jump position",
  Please_enter_the_jump_location: "Please enter the jump location",
  Please_select_a_cover: "Please select a cover",
  Please_select_a_jump_type: "Please select a jump type",
  Please_select_a_resource_location: "Please select a resource location",
  hint_76:
    "Note: The greater the weight, the higher the current advertising position.",
  Is_the_browser_open_: "Is the browser open?",
  hint_644: "Are you sure you want to enable this advertisement?",
  hint_643: "Are you sure you want to deactivate this advertisement?",
  hint_642: "Are you sure you want to remove this advertising space?",
  Add_an_open_screen_advertisement: "Add an open screen advertisement",
  hint_641:
    "Please ensure that the video format is MP4 format and does not exceed 5M",
  hint_77:
    "Only jpg/png/gif files can be uploaded, and the image size needs to be 750X1624.",
  Please_enter_the_link_address: "Please enter the link address",
  Please_enter_the_live_broadcast_room_ID:
    "Please enter the live broadcast room ID",
  Please_enter_the_information_ID: "Please enter the information ID",
  Please_enter_the_chat_room_ID: "Please enter the chat room ID",
  The_input_information_format_is_incorrect:
    "The input information format is incorrect",
  Chat_room_ID: "Chat room ID",
  Brocade_Bag_ID: "Brocade Bag ID",
  hint_640: "Only jpg/png/gif files can be uploaded. The recommended size is",
  Please_enter_the_sorting_number: "Please enter the sorting number",
  Sort: "Sort",
  The_length_cannot_exceed_20: "The length cannot exceed 20",
  hint_78:
    "After offline, the pop-up window will be removed from the mobile end. Are you sure you want to offline this pop-up window?",
  hint_639: "The size of the uploaded image cannot exceed 1MB",
  Page_Name: "Page Name",
  Please_enter_the_page_name: "Please enter the page name",
  Parameters: "Parameters",
  PC_Link: "PC Link",
  H5_Link: "H5 Link",
  PC_Preview: "PC Preview",
  H5_Preview: "H5 Preview",
  PC_Resource_Map: "PC Resource Map",
  H5_Resource_Map: "H5 Resource Map",
  Please_enter_parameters: "Please enter parameters",
  Background_name: "Background name",
  Background_image: "Background image",
  Set_background_name: "Set background name",
  Set_sorting: "Set sorting",
  Modify_background_image: "Modify background image",
  Add_background_image: "Add background image",
  Please_fill_in_the_background_name: "Please fill in the background name",
  Please_fill_in_the_sorting: "Please fill in the sorting",
  Successfully_taken_down: "Successfully taken down",
  Successfully_listed: "Successfully listed",
  Effective: "Effective",
  Invalid: "Invalid",
  hint_606: "Number of channel broadcasts (counted every 10 minutes)",
  Go_live: "Go live",
  hint_605: "Please set the sorting number (1-999)",
  Please_enter_the_tab_name: "Please enter the tab name",
  hint_79:
    "Only jpg/png files can be uploaded, and the image size needs to be 670 * 376",
  Please_set_the_status: "Please set the status",
  Are_you_sure_you_want_to_go_online_: "Are you sure you want to go online?",
  Are_you_sure_you_want_to_go_offline_: "Are you sure you want to go offline?",
  Anchor_userid: "Anchor userid",
  Chat_room_title: "Chat room title",
  Cut_off_flow: "Cut off flow",
  hint_638: "Are you sure this chat room is disconnected?",
  Upload_time: "Upload time",
  Cover_type: "Cover type",
  Batch_pass: "Batch pass",
  Batch_rejection: "Batch rejection",
  Voice_chat_room: "Voice chat room",
  Reject: "Reject",
  Operated: "Operated",
  Passed: "Passed",
  Live_streaming_3: "Live streaming",
  hint_637: "Are you sure you want to delete the cover?",
  Music_name: "Music name",
  Please_enter_the_music_name: "Please enter the music name",
  Author_name: "Author's name",
  Please_enter_the_author_name: "Please enter the author's name",
  Size: "Size",
  Playback_count: "Playback count",
  Trial_listening: "Trial listening",
  Please_enter_the_song_name: "Please enter the song name",
  Song_title: "Song title",
  Upload_music_files: "Upload music files",
  Only_supports_MP3_format: "Only supports MP3 format",
  hint_80: "Aspect ratio 60 * 60",
  Please_upload_music_files: "Please upload music files",
  Edit_Music: "Edit Music",
  Add_music: "Add music",
  Only_MP3_format_files_can_be_uploaded:
    "Only MP3 format files can be uploaded",
  The_size_cannot_exceed_20MB_: "The size cannot exceed 20MB!",
  hint_635: "Are you sure you want to put this music on the shelves?",
  hint_636: "Are you sure you want to remove this music?",
  Add_Recommendation: "Add Recommendation",
  Please_set_the_sorting_number_1_999_: "Please set the sorting number (1-999)",
  Editor_recommendation: "Editor's recommendation",
  UserId_cannot_be_empty: "UserId cannot be empty",
  Shutdown_time: "Shutdown time",
  Peak_heat_value: "Peak heat value",
  Edit_Page: "Edit Page",
  Add_Page: "Add Page",
  Upload_at_least_one_resource_image: "Upload at least one resource image",
  hint_81:
    "After publishing, this link will be published online. Are you sure you want to publish this page?",
  hint_82:
    "After delisting, this link will no longer be accessible online. Are you sure you want to delist this page?",
  Select_Cover: "Select Cover",
  hint_633: "Attention: The effective time cannot overlap!!!",
  hint_83:
    "There is incomplete information, please fill it out completely before submitting!!",
  hint_634: "The size of the uploaded image cannot exceed 500K!",
  Recommended_image: "Recommended image",
  Display_sorting: "Display sorting",
  Edit_Secondary_Classification: "Edit Secondary Classification",
  Classification: "Classification",
  Add_Category: "Add Category",
  Please_enter_the_activity_name: "Please enter the activity name",
  Activity_status: "Activity status",
  Image: "Image",
  Configuration_date: "Configuration date",
  Please_enter_the_button_link: "Please enter the button link",
  Button_link: "Button link",
  Button_Text: "Button Text",
  hint_607: "Please enter the button text, maximum of 4 words",
  Button_color: "Button color",
  hint_608: "Please enter the button color, such as # CCCCCC",
  Edit_Configuration: "Edit Configuration",
  New_configuration: "New configuration",
  hint_609: "Please enter the button text",
  Please_enter_the_button_color: "Please enter the button color",
  hint_84:
    "Are you sure you want to list this template? The page template will take effect after it is listed",
  hint_85:
    "Are you sure you want to remove this template? The page button will become invalid after removal",
  APP_end: "APP end",
  H5_end: "H5 end",
  AK_APP_end: "AK-APP end",
  AK_PC_end: "AK-PC end",
  AK_H5_end: "AK-H5 end",
  Anchor_details: "Anchor details",
  Recommended_number_of_anchors: "Recommended number of anchors",
  Anchor_: "Anchor {num}",
  Please_enter_the_anchor_ID: "Please enter the anchor ID",
  Please_fill_in_the_resource_slot_name:
    "Please fill in the resource slot name",
  Please_fill_in_at_least_one_anchor_information:
    "Please fill in at least one anchor information",
  Secondary_classification_valid_all: "Secondary classification (valid/all)",
  SM_static_resources: "SM static resources",
  AK_static_resources: "AK static resources",
  More_Jumps: "More Jumps",
  Editorial_Expert_Recommendation_Group:
    "Editorial Expert Recommendation Group",
  Add_expert_recommendation_group: "Add expert recommendation group",
  Near_0_in_0: "Near 0 in 0",
  Highest: "Highest",
  Lian_Hong: "Lian Hong",
  Recently: "Recently",
  Permissions: "Permissions",
  Are_you_sure_you_want_to_turn_it_on_: "Are you sure you want to turn it on?",
  Are_you_sure_you_want_to_close_it_: "Are you sure you want to close it?",
  Live_broadcast_room_name: "Live broadcast room name",
  Please_select_sorting: "Please select sorting",
  Edit_popular_recommendations: "Edit popular recommendations",
  Add_popular_recommendations: "Add popular recommendations",
  Advertising_name: "Advertising name",
  Resource_type: "Resource type",
  Video_upload: "Video upload",
  Your_browser_does_not_support_video_playback:
    "Your browser does not support video playback",
  Re_upload: "Re upload",
  Please_upload_the_video: "Please upload the video",
  Offline: "Offline",
  Square_banner: "Square banner",
  Page: "Page",
  Information_details: "Information details",
  Edit_open_screen_advertisement: "Edit open screen advertisement",
  Please_select_a_resource_type: "Please select a resource type",
  Please_select_an_open_screen_advertisement:
    "Please select an open screen advertisement",
  The_uploaded_video_size_cannot_exceed_5MB_:
    "The uploaded video size cannot exceed 5MB.",
  Please_upload_the_correct_video_format:
    "Please upload the correct video format",
  Add_patches: "Add patches",
  Display_location: "Display location",
  Please_enter_the_title_of_the_patch_advertisement:
    "Please enter the title of the patch advertisement",
  hint_86:
    "Only jpg/png files can be uploaded, and the image size needs to be 100X100.",
  Edit_patch_ads: "Edit patch ads",
  Add_patch_ads: "Add patch ads",
  Please_enter_the_advertisement_title: "Please enter the advertisement title",
  Please_select_a_display_location: "Please select a display location",
  Recommended_by_experts_on_the_homepage:
    "Recommended by experts on the homepage",
  Live_streaming_page_anchor_recommendation:
    "Live streaming page anchor recommendation",
  hint_87: "Uploading images can only be in JPG, PNG, or GIF format! ",
  Pop_up_name: "Pop up name",
  APP_Advertising: "APP Advertising",
  Open_screen_advertising: "Open screen advertising",
  Patch_advertising: "Patch advertising",
  Activity_pop_up: "Activity pop-up",
  hint_611: "Note: All functions for listing and operation have been opened.",
  Assistant: "Assistant",
  Homepage_Live: "Homepage Live",
  Live_broadcast_room_announcement: "Live broadcast room announcement",
  Advertising_resource_allocation: "Advertising resource allocation",
  hint_610: "If enabled, request old advertising resource materials",
  hint_88:
    "Note: During the official review period, some functions were not opened",
  APP_Brand_Name_: "APP Brand Name:",
  Please_enter_the_APP_brand_name: "Please enter the APP brand name",
  Please_enter_contact_customer_service:
    "Please enter contact customer service",
  hint_89:
    "The size of the uploaded image is incorrect, please upload according to the format requirements",
  Switch_initialization: "Switch initialization",
  Version_configuration: "Version configuration",
  Review_status: "Review status",
  Operation_status: "Operation status",
  AppStore_version_number: "AppStore version number",
  Please_enter_the_version_number: "Please enter the version number",
  Version_status: "Version status",
  Update_instructions: "Update instructions",
  Configure_time: "Configure time",
  Please_enter_the_interface_version_number:
    "Please enter the interface version number",
  hint_90:
    "The interface version number is provided by the technology and cannot be customized privately!",
  Please_enter_update_instructions: "Please enter update instructions",
  Please_select_version_status: "Please select version status",
  Please_enter_the_AppStore_version_number:
    "Please enter the AppStore version number",
  Please_enter_the_short_video_title: "Please enter the short video title",
  Edit_Title: "Edit Title",
  Short_video_title_: "Short video title:",
  hint_91:
    "Publish this short video to the mobile end. Are you sure you want to publish it?",
  Crawling_website_sites: "Crawling website sites",
  Statistical_time: "Statistical time",
  UV_data: "UV data",
  Platform: "Platform",
  Add_circle_recommendations: "Add circle recommendations",
  Are_you_sure_you_want_to_pause_: "Are you sure you want to pause?",
  Access_date: "Access date",
  Posting_time: "Posting time",
  Poster_nickname: "Poster's nickname",
  Interactive_red_envelopes: "Interactive red envelopes",
  Topic_Name: "Topic Name",
  Please_enter_the_topic_name: "Please enter the topic name",
  hint_632: "Topic heat (counted every 10 minutes)",
  Accumulated_number_of_related_posts: "Accumulated number of related posts",
  Save_and_publish: "Save and publish",
  Recommendation: "Recommendation",
  Add_recommended_topics: "Add recommended topics",
  Number_of_participants_in_the_topic: "Number of participants in the topic",
  Number_of_topic_views: "Number of topic views",
  Topic_release_time: "Topic release time",
  Topic_Top_Time: "Topic Top Time",
  Choose_a_topic: "Choose a topic",
  Enter_the_topic_name_you_want_to_recommend:
    "Enter the topic name you want to recommend",
  Editor_recommended_topic: "Editor's recommended topic",
  The_topic_cannot_be_empty: "The topic cannot be empty",
  hint_631: "Please enter the topic name (within 15 words)",
  hint_630: "Please enter a topic introduction (within 100 words)",
  Successfully_created_topic: "Successfully created topic",
  Recharge: "Recharge",
  Consumption: "Consumption",
  Revenue: "Revenue",
  Order_number_1: "Order number",
  Recharge_amount: "Recharge amount",
  consumption_type: "Consumption type",
  consumption_amount: "Consumption amount",
  revenue_type: "Revenue type",
  revenue_amount: "Revenue amount",
  Additional_issuance: "Additional issuance",
  Uploading: "Uploading...",
  Batch_issuance: "Batch issuance",
  Download_batch_issuance_templates: "Download batch issuance templates",
  Additional_currency_issuance: "Additional currency issuance",
  Increase_experience_value_of_issuance:
    "Increase experience value of issuance",
  Please_enter_user_userid: "Please enter user userid",
  Please_enter_the_quantity_of_coins: "Please enter the quantity of coins",
  Please_enter_a_note: "Please enter a note",
  VIP_level: "VIP level",
  Additional_issuance_time: "Additional issuance time",
  Are_you_sure_you_want_to_refuse_: "Are you sure you want to refuse?",
  Personal_currency_issuance: "Personal currency issuance",
  VIP_currency_issuance: "VIP currency issuance",
  Total_consumption_in_gold_coins: "Total consumption (in gold coins)",
  Total_rechargeyuan: "Total recharge (yuan)",
  Number_of_Rechargers: "Number of Rechargers",
  Recharge_switch: "Recharge switch",
  RMB_amount_yuan_: "RMB amount (yuan)",
  Number_of_gold_coins: "Number of gold coins",
  Edit_recharge_configuration: "Edit recharge configuration",
  Add_recharge_configuration: "Add recharge configuration",
  Recharge_time: "Recharge time",
  Payment_channels: "Payment channels",
  Please_enter_the_order_number_1: "Please enter the order number",
  Payment_time: "Payment time",
  Order_time: "Order time",
  Payment_amount_yuan: "Payment amount (yuan)",
  Balance_inquiry: "Balance inquiry",
  Ordinary_user_currency_balance: "Ordinary user currency balance",
  Details_1: "Details",
  Football_quiz_activity: "Football quiz activity",
  Football_winning_statistics: "Football winning statistics",
  Basketball_quiz_activity: "Basketball quiz activity",
  Basketball_winning_statistics: "Basketball winning statistics",
  Guessing_question_name: "Guessing question name",
  Please_enter_the_name_of_the_quiz_question:
    "Please enter the name of the quiz question",
  Start_time: "Start time",
  Add_a_question: "Add a question",
  Rule_configuration: "Rule configuration",
  Style_Configuration: "Style Configuration",
  Number_of_periods: "Number of periods",
  Reward_base: "Reward base",
  Stop: "Stop",
  End: "End",
  Title: "Title",
  The_problem_name_cannot_be_empty: "The problem name cannot be empty",
  Please_enter_the_question_name: "Please enter the question name",
  Please_enter_question_options: "Please enter question options",
  question_options_1: "Answer options",
  Add_Options: "Add Options",
  Please_enter_the_number_of_currencies_for_the_reward_base:
    "Please enter the number of currencies for the reward base",
  Save: "Save",
  Cancel: "Cancel",
  Please_submit_results_with_caution: "Please submit results with caution",
  The_answer_to_the_question_cannot_be_empty:
    "The answer to the question cannot be empty",
  Flow: "Flow",
  Activity_Rule_Configuration: "Activity Rule Configuration",
  Rule_configuration_cannot_be_empty: "Rule configuration cannot be empty",
  Limited_to_200_words_in_length: "Limited to 200 words in length",
  Guessing_Style_Configuration: "Guessing Style Configuration",
  PC_Banner_diagram: "PC Banner diagram",
  PC_Banner_image_cannot_be_empty: "PC Banner image cannot be empty",
  hint_612: "Size: 1920x624, only jpg/png files can be uploaded",
  H5_APP_Banner_image: "H5/APP Banner image",
  H5_APP_Banner_image_cannot_be_empty: "H5/APP Banner image cannot be empty",
  hint_613: "Size: 750x1012, only jpg/png files can be uploaded",
  Theme_color: "Theme color",
  The_theme_color_cannot_be_empty: "The theme color cannot be empty",
  hint_619: "Color configuration at the bottom of the page",
  Event_details: "Event details",
  Correct_answer: "Correct answer",
  Stopped: "Stopped",
  Add_a_guessing_activity: "Add a guessing activity",
  hint_618: "Please fill in the name of the quiz question",
  Please_select_the_effective_time_range:
    "Please select the effective time range",
  Please_fill_in_the_reward_base: "Please fill in the reward base",
  hint_92:
    "After cancellation, the current quiz activity will not be published. Are you sure you want to cancel this quiz activity?",
  Success: "Success",
  Cancel_successful: "Cancel successful",
  hint_93:
    "After stopping, users will not be able to participate in the guessing activity. Are you sure to stop the guessing activity?",
  hint_614: "Please enter a reward base of a positive integer from 1 to 99999",
  Cannot_select_past_time: "Cannot select past time",
  hint_615: "Are you sure you want to announce the answer?",
  Save_successful: "Save successful",
  hint_616: "Set at least 2 answer options for question {num}",
  Number_of_participants: "Number of participants",
  Number_of_winners: "Number of winners",
  hint_617: "Number of rewards (in gold coins)",
  Question_Name: "Question Name",
  Number_of_correct_answers: "Number of correct answers",
  Activity_List: "Activity List",
  Top_of_the_event: "Top of the event",
  PC_end_carousel: "PC end carousel",
  AK_Activity_List: "AK Activity List",
  AK_Activity_Topping: "AK Activity Topping",
  AK_PC_end_carousel: "AK-PC end carousel",
  Create_activity_records: "Create activity records",
  Event_main_title: "Event main title",
  Activity_subtitle: "Activity subtitle",
  PC_thumbnail: "PC thumbnail",
  App_thumbnail: "App thumbnail",
  Activity_time: "Activity time",
  Long_term_activities: "Long term activities",
  Please_enter_the_main_title_of_the_activity:
    "Please enter the main title of the activity",
  Please_enter_the_activity_subtitle: "Please enter the activity subtitle",
  PC_activity_redirect_address: "PC activity redirect address",
  Activity_jump_button_background: "Activity jump button background",
  App_side_thumbnail: "App side thumbnail",
  hint_901: "Size 355 * 148px",
  App_activity_redirect_address: "App activity redirect address",
  Time_type: "Time type",
  There_is_a_time_limit: "There is a time limit",
  Edit_activity_records: "Edit activity records",
  hint_629: "Are you sure you want to top this activity record?",
  hint_620: "Are you sure you want to cancel topping this activity record?",
  Please_set_the_activity_time: "Please set the activity time",
  Jump_URL: "Jump URL",
  Queue: "Queue",
  Cover: "Cover",
  Mobile_download_page: "Mobile download page",
  PC_Download_Page: "PC Download Page",
  Android_copywriting: "Android copywriting",
  hint_94: "Please enter the text, with a maximum support of 10 characters",
  IOS_Web_App_Copywriting: "IOS Web App Copywriting",
  IOS_copywriting: "IOS copywriting",
  PC_background_configuration: "PC background configuration",
  Group_stage_competition: "Group stage competition",
  "1_8_elimination_round": "1/8 elimination round",
  "1_4_elimination_round": "1/4 elimination round",
  Semi_finals: "Semi finals",
  Finals: "Finals",
  Location: "Location",
  Facing_each_other: "Facing each other",
  hint_95:
    "Please enter the host room number and separate it with commas, with a maximum of four digits.",
  Fen: "Fen",
  hint_628: "You can only select a maximum of 4 anchor room numbers",
  SM_redemption_page: "SM redemption page",
  AK_redemption_page: "AK redemption page",
  H5_end_thumbnail: "H5 end thumbnail",
  hint_96: "Only jpg/png/gif files can be uploaded, with a size of 1440 * 315",
  Category_ID: "Category ID",
  Classification_name: "Classification name",
  Please_enter_the_category_name: "Please enter the category name",
  Thai_tag_name: "Thai tag name",
  Please_enter_the_Thai_tag_name: "Please enter the Thai tag name",
  English_tag_name: "English tag name",
  Please_enter_the_English_tag_name: "Please enter the English tag name",
  Vietnamese_label_name: "Vietnamese label name",
  Please_enter_the_Vietnamese_label_name:
    "Please enter the Vietnamese label name",
  Korean_tag_name: "Korean tag name",
  Please_enter_the_Korean_tag_name: "Please enter the Korean tag name",
  Brazilian_label_name: "Brazilian label name",
  Please_enter_the_Brazilian_label_name:
    "Please enter the Brazilian label name",
  Add_a_new_category: "Add a new category",
  hint_888: "High quality goods",
  Please_select_the_status: "Please select the status",
  hint_627: "Are you sure you want to launch this product?",
  hint_626: "Are you sure you want to take the product category offline?",
  Warning: "Warning",
  hint_97:
    "The category contains the corresponding product and cannot be taken offline",
  Edit_Classification: "Edit Classification",
  Redemption_page_BANNER: "Redemption page BANNER",
  hint_625: "The size of the uploaded image cannot exceed 5M!",
  Delisted_products: "Delisted products",
  Listing_products: "Listing products",
  Classification_management: "Classification management",
  Label_management: "Label management",
  Order_List: "Order List",
  Product_ID: "Product ID",
  Please_enter_the_product_ID: "Please enter the product ID",
  Product_name: "Product name",
  Please_enter_the_product_name: "Please enter the product name",
  Do_we_need_delivery_: "Do we need delivery?",
  Switch: "Switch",
  Unit_price: "Unit price",
  Discounted_price: "Discounted price",
  Current_inventory: "Current inventory",
  Redeemed: "Redeemed",
  Inventory_changes: "Inventory changes",
  Select_thumbnail: "Select thumbnail",
  Details_page_image: "Details page image",
  Select_detailed_image: "Select detailed image",
  Details: "Details",
  Please_enter_the_unit_price: "Please enter the unit price",
  Discounts: "Discounts",
  Please_enter_the_discounted_price_of_the_product:
    "Please enter the discounted price of the product",
  Please_select_a_category: "Please select a category",
  Label: "Label",
  Product_purchase_restriction_period: "Product purchase restriction period",
  No_purchase_restrictions: "No purchase restrictions",
  Day: "Day",
  Week: "Week",
  Cumulative: "Cumulative",
  Product_purchase_limit: "Product purchase limit",
  hint_98:
    "Please enter the number of product purchase restrictions within the cycle",
  Inventory: "Inventory",
  Add: "Add",
  Reduce: "Reduce",
  The_product_name_cannot_be_empty: "The product name cannot be empty",
  Boutique: "Boutique",
  Life: "Life",
  Product_addition: "Product addition",
  Please_upload_thumbnail: "Please upload thumbnail",
  Please_upload_detailed_images: "Please upload detailed images",
  Please_select_whether_delivery_is_required:
    "Please select whether delivery is required",
  Purchase_unit_price_cannot_be_empty: "Purchase unit price cannot be empty",
  Classification_cannot_be_empty: "Classification cannot be empty",
  hint_99: "The number of product purchase restrictions cannot be empty",
  Details_cannot_be_empty: "Details cannot be empty",
  Please_select_increase_decrease: "Please select 'increase decrease'",
  Are_you_sure_you_want_to_save_: "Are you sure you want to save?",
  Cat_coin_price: "Cat coin price",
  Diamond_price: "Diamond price",
  Thai_product_name: "Thai product name",
  Please_enter_the_Thai_product_name: "Please enter the Thai product name",
  English_product_name: "English product name",
  Please_enter_the_English_product_name:
    "Please enter the English product name",
  Korean_product_name: "Korean product name",
  Please_enter_the_Korean_product_name: "Please enter the Korean product name",
  Brazilian_product_name: "Brazilian product name",
  Please_enter_the_Brazilian_product_name:
    "Please enter the Brazilian product name",
  hint_902: "Suggested size 174 * 174",
  hint_903: "Suggested size 375 * 375",
  Thai_language_details: "Thai language details",
  English_detailed_explanation: "English detailed explanation",
  Vietnamese_language_details: "Vietnamese language details",
  Korean_language_details: "Korean language details",
  Brazilian_language_details: "Brazilian language details",
  Please_enter_the_diamond_price_of_the_product:
    "Please enter the diamond price of the product",
  Are_you_sure_you_want_to_delist_this_product_:
    "Are you sure you want to delist this product?",
  Label_ID: "Label ID",
  Please_enter_the_label_name: "Please enter the label name",
  Label_Name: "Label Name",
  hint_624: "Are you sure you want to launch this product label?",
  hint_101: "Are you sure you want to take the product label offline?",
  Edit_label: "Edit label",
  Order_number: "Order number",
  Please_enter_the_order_number: "Please enter the order number",
  Transaction_time: "Transaction time",
  Receiving_address: "Receiving address",
  Signee: "Signee",
  Transaction_volume: "Transaction volume",
  Total_transaction_amount: "Total transaction amount",
  Transaction_status: "Transaction status",
  Unsuccessful: "Unsuccessful",
  Limited_to_50_words_in_length: "Limited to 50 words in length",
  Inviter_UID: "Inviter UID",
  Inviting_person_nickname: "Inviting person's nickname",
  Invitee_UID: "Invitee UID",
  Invitee_registration_time: "Invitee registration time",
  Please_enter_the_inviter_reward: "Please enter the inviter reward",
  Please_enter_the_invitee_reward: "Please enter the invitee reward",
  Rule_Description: "Rule Description",
  APP_landing_page_background_image: "APP landing page background image",
  hint_621: "Size: 710X1086, can only upload jpg/png files",
  Watch_the_live_broadcast: "Watch the live broadcast",
  Live_room_speech: "Live room speech",
  Push_order_configuration: "Push order configuration",
  hint_102:
    "Friendly reminder: 1. Please configure the speech configuration in order. If configured to the second sentence, only the 1 and 2 speech configurations will be executed.",
  hint_905: "The {num} speech configuration",
  Reward_amount: "Reward amount",
  Please_enter_the_speech_reward_amount:
    "Please enter the speech reward amount",
  Valid_characters: "Valid characters",
  hint_623: "Please enter valid characters for your speech",
  hint_103:
    "The reward amount for the first speech configuration cannot be empty",
  hint_104:
    "The reward amount for the second speech configuration cannot be empty",
  hint_105:
    "The reward amount for the third speech configuration cannot be empty",
  Friendly_reminder: "Friendly reminder",
  hint_1:
    "1. The push order data and user participation data can be viewed in the push order module of the live broadcast room under Live Management.",
  hint_2:
    "2-Reward interval for push orders: The rewards for the anchor's push orders will be randomly generated within this interval;",
  hint_3:
    "3- Peak daily reward for users: If the peak is exceeded, users will continue to participate in push orders and cannot receive rewards;",
  hint_889: "Reward interval for push orders",
  Maximum_reward: "Maximum reward",
  Minimum_reward: "Minimum reward",
  Users_get_peak_daily: "Users get peak daily",
  hint_622: "Please enter the maximum daily user acquisition value",
  Please_enter_the_maximum_reward: "Please enter the maximum reward",
  Please_enter_the_minimum_reward: "Please enter the minimum reward",
  Please_enter_the_user_daily_peak_gain:
    "Please enter the user's daily peak gain",
  Viewing_duration: "Viewing duration",
  hint_107:
    "Please enter the viewing duration, which must be a positive integer",
  Watch_rewards: "Watch rewards",
  Please_enter_the_viewing_reward: "Please enter the viewing reward",
  Reward_channels: "Reward channels",
  hint_106:
    "After opening, watching live broadcasts on this channel will receive rewards, and different channels will only receive rewards once;",
  Please_enter_the_viewing_duration: "Please enter the viewing duration",
  Please_select_a_reward_channel: "Please select a reward channel",
  First_line_copy: "First line copy",
  hint_108: "Please enter the text, with a maximum support of 10 characters",
  Second_line_copy: "Second line copy",
  Please_enter_the_first_line_of_text: "Please enter the first line of text",
  Please_enter_the_second_line_of_text: "Please enter the second line of text",
  Effective_date: "Effective date",
  Display_copy: "Display copy",
  Gift_channels: "Gift channels",
  Login_rewards: "Login rewards",
  Accumulated_Gifts: "Accumulated Gifts",
  Additional_rewards_for_app_login: "Additional rewards for app login",
  hint_109:
    "Please enter the login reward. If it is 0, there will be no reward.",
  Please_enter_the_reward_amount: "Please enter the reward amount",
  Login_Activity_Rewards: "Login Activity Rewards",
  Please_enter_display_copy: "Please enter display copy",
  Please_enter_the_login_reward: "Please enter the login reward",
  Please_enter_a_valid_date: "Please enter a valid date",
  Please_enter_to_select_the_gift_channel:
    "Please enter to select the gift channel",
  Login_reward_activity: "Login reward activity",
  Download_copy_configuration: "Download copy configuration",
  Lottery_time: "Lottery time",
  Activity_ID: "Activity ID",
  Accessing_Users: "Accessing Users",
  Participating_users: "Participating users",
  Data_details: "Data details",
  Channel_sources: "Channel sources",
  Click_on_the_number_of_lucky_draws: "Click on the number of lucky draws",
  Page_visits: "Page visits",
  Number_of_times_participating_in_the_lottery:
    "Number of times participating in the lottery",
  Winning_users: "Winning users",
  Users_who_win_physical_prizes: "Users who win physical prizes",
  Click_to_go_to_the_assistant_user: "Click to go to the assistant user",
  Click_to_download_app_users: "Click to download app users",
  Award_pool_type: "Award pool type",
  Lucky_Wheel: "Lucky Wheel",
  Super_Wheel: "Super Wheel",
  Lottery_Record_Daily_Turntable: "Lottery Record - Daily Turntable",
  Physical_rewards: "Physical rewards",
  Draw_lottery_tickets: "Draw lottery tickets",
  Processing_personnel: "Processing personnel",
  Not_issued: "Not issued",
  Issued: "Issued",
  Virtual: "Virtual",
  Operation_turntable: "Operation turntable",
  Activity_Management: "Activity Management",
  Lottery_record: "Lottery record",
  Operation_turntable_activity_data: "Operation turntable activity data",
  Activity_type: "Activity type",
  Activity_carousel: "Activity carousel",
  Daily_turntable: "Daily turntable",
  Activity_statistics: "Activity statistics",
  Number_of_super_lottery_tickets_distributed:
    "Number of super lottery tickets distributed",
  Lucky_Wheel_Lucky_Draw_Times: "Lucky Wheel Lucky Draw Times",
  Super_Wheel_Lottery_Times: "Super Wheel Lottery Times",
  Basic_information: "Basic information",
  Activity_Rules: "Activity Rules",
  Please_enter_activity_rules: "Please enter activity rules",
  PC_banner_image: "PC banner image",
  H5_Jump_Page: "H5 Jump Page",
  In_use: "In use",
  Total_inventory: "Total inventory",
  Prizes: "Prizes",
  Single_day_upper_limit: "Single day upper limit",
  Individual_users_can_receive_upper_limits:
    "Individual users can receive upper limits",
  Lucky_draw_probability_in_tens_of_thousands_:
    "Lucky draw probability (in tens of thousands)",
  Is_it_a_running_horse_lamp_: "Is it a running horse lamp?",
  Is_it_a_bottom_line: "Is it a bottom line",
  Add_prize_pool: "Add prize pool",
  Cloning: "Cloning",
  Single_consumption: "Single consumption",
  Daily_limit: "Daily limit",
  Activity_channels: "Activity channels",
  H5_banner_diagram: "H5 banner diagram",
  How_to_earn_cat_coins: "How to earn cat coins",
  Please_select_the_activity_time_range:
    "Please select the activity time range",
  Edit_anchor_recommendation_group: "Edit anchor recommendation group",
  Add_a_new_anchor_recommendation_group:
    "Add a new anchor recommendation group",
  Please_upload_the_PC_banner_image: "Please upload the PC banner image",
  Please_upload_the_h5_banner_image: "Please upload the h5 banner image",
  Please_enter_PC_Jump_Page: "Please enter 'PC Jump Page'",
  Please_enter_h5_to_jump_to_the_page: "Please enter h5 to jump to the page",
  Please_enter_a_single_consumption: "Please enter a single consumption",
  Please_enter_the_daily_limit: "Please enter the daily limit",
  Please_select_the_activity_channel: "Please select the activity channel",
  Prize_Pool: "Prize Pool",
  hint_110:
    "The opening time of the prize pool cannot be earlier than the event opening time",
  hint_111:
    "The end time of the prize pool cannot be later than the end time of the activity",
  The_total_probability_does_not_equal_10000:
    "The total probability does not equal 10000",
  Sum_of_probabilities: "Sum of probabilities",
  Please_select_the_prize_for_prize_: "Please select the prize for prize {num}",
  Please_enter_the_total_inventory_of_prize_:
    "Please enter the total inventory of prize {num}",
  _The_daily_limit_is_greater_than_the_total_inventory:
    "{num} The daily limit is greater than the total inventory",
  hint_112:
    "Please enter the lottery probability (in tens of thousands) for prize {num}",
  hint_115: "Please select whether the prize {num} is a running errand",
  hint_116: "Each prize pool must select a bottom prize!",
  hint_114: "Please fill in the effective time of the prize pool",
  hint_113: "Are you sure you want to delete the prize pool?",
  Data_Overview: "Data Overview",
  Quantity_of_currency: "Quantity of currency",
  Please_enter_the_quantity_of_currency:
    "Please enter the quantity of currency",
  Number_of_lottery_tickets: "Number of lottery tickets",
  Please_enter_the_number_of_lottery_tickets:
    "Please enter the number of lottery tickets",
  Select_icon: "Select icon",
  Please_select_the_type_of_prize: "Please select the type of prize",
  Please_upload_the_icon: "Please upload the icon",
  Editing_prizes: "Editing prizes",
  Please_enter_a_positive_integer: "Please enter a positive integer",
  single_1: "Pieces",
  submit_results_1: "Submit results",
  rules_options: "Rule configuration",
  rules_options_1: "Rule configuration cannot be empty",
  edit_activity_cai: "Edit guessing activities",
  created_topic_1: "Create a topic",
  created_topic_2: "Edit Topic",
  briefing_topic_1: "Topic Introduction",
  briefing_topic_2: "Topic thumbnail",
  hint_899:
    "Only jpg/png files can be uploaded, with a limit of 1M and a size of 790 * 215px",
  hint_907:
    "Only jpg/png files can be uploaded, with a limit of 1M and a size of 150 * 58px",
  hint_917:
    "Only jpg/png files can be uploaded, with a limit of 1M and a size of 355 * 148px",
  hint_918:
    "The recommended image size is 256x256, only jpg/png files can be uploaded.",
  lookDetail: "View",
  text_1: "Are you sure you want to remove it?",
  text_2: "Are you sure you want to list it?",
  matchPlan_1: "Competition progress",
  group_1: "Group",
  shopEdit: "Product Editing",
  viShopEditName: "Vietnamese product name",
  viShopEditName1: "Please enter the Vietnamese product name",
  hint_1001: "Purchasing Cat Coin cannot be empty",
  hint_1002: "Purchasing diamonds cannot be empty",
  hint_1003: "Please enter Cat Coin",
  remark_edit: "Note editing",
  currency_cat: "Cat Coin",
  diamond_1: "Diamonds",
  Invitee_UID_1: "Inviter UID",
  Invitee_edit_1: "Invitation Configuration",
  minute_1: "Minutes",
  establish_1: "Create",
  hint_1005: "Football winning statistics",
  hint_1006: "Basketball winning statistics",
  hint_1007: "Winning Details",
  hint_1008: "Starting",
  hint_1009: "Stop",
  hint_1011: "Not filled in",
  hint_1012: "Select banner image",
  hint_1013: "PC Jump Page",
  hint_1014: "Using",
  hint_1015: "Prize ID",
  hint_1016: "Draw lottery tickets",
  hint_1017: "Link",
  hint_1018: "Information ID",
  hint_1019: "Upload music thumbnail",
  hint_1021: "English letter level restrictions",
  hint_1022: "Live streaming room push orders will automatically pop up",
  advertising_title: "Advertising Title",
  Author_name_1: "Author",
  text_7: "User private message anchor",
  text_8: "Anchor private message users",
  text_9: "User private message administrator",
  text_10: "Private chat anchor level",
  text_11: "Download the top logo of the page",
  text_12: "APP/H5 Album Page Status",
  text_13: "IOS Triple Plot",
  text_14: "The first picture",
  text_15: "The second one",
  text_16: "The third one",
  text_17: "The fourth picture",
  text_18: "The content of the ${num} th page cannot be empty",
  text_19: "The ${num} th image cannot be empty",
  text_20:
    "The recommended image size is 40x40, and only jpg/png/gif files can be uploaded.",
  text_21:
    "The recommended image size is 40x40, and only jpg/png/gif files can be uploaded.",
  text_22: "PC album page name",
  text_23:
    "Please enter the URL corresponding to src. If you need to count multiple domain names, please use | | to distinguish",
  text_24: "About Page Copying Configuration",
  text_25: "Please fill in the content of the copy",
  text_26: "Please enter the developer account",
  text_27: "Beginner's Guide Page",
  text_28: "No need to update",
  text_29: "Optional updates",
  text_30: "Force updates",
  text_31: "Public key",
  text_32: "Private key",
  text_33: "Function Description",
  text_34: "Android configuration",
  text_35: "Send red envelopes in the live broadcast room",
  text_36: "Live room gift giving",
  text_37: "Start APP buried point reporting",
  text_38: "Domain Name Type",
  text_39: "Edit domain name",
  text_40: "Add domain name",
  have_1: "have",
  have_2: "None",
  updateTime_1: "Update time",
  text_41: "Are you sure you want to delete this domain name?",
  text_42:
    "After lifting the ban, the word can continue to be sent on the platform. Are you sure to lift the ban?",
  text_43: "Live room banner URL",
  text_44: "H5banner",
  text_45: "Please enter a description",
  text_46: "Please enter the homepage title",
  text_47: "Please enter the title of the live streaming page",
  text_48:
    "Only jpg/jpeg/png files can be uploaded, and a maximum of 9 files can be uploaded.",
  text_49: "Upload video files",
  text_50: "Please enter the circle ID",
  text_51: "Supported formats: JPG, PNG, GIF, MP4, size: up to 3M:",
  text_52: "Unpublished",
  text_53: "Please enter the introduction",
  text_54:
    "Only jpg/png/gif files can be uploaded, with a limit of 1M and a size of 790 * 215px",
  text_55: "Set the maximum number of people",
  text_56: "Submission time",
  text_57: "Please set the maximum number of people",
  text_58: "The maximum number of people shall not be less than 50",
  text_59: "Real name",
  text_60: "Agreed",
  text_61: "Number of circles created",
  text_62: "Number of circle users",
  text_63: "Joining time",
  text_64: "ID No.:",
  text_65: "Please enter your phone number",
  text_66: "Submitter",
  text_67: "Sports",
  text_68: "Entertainment",
  text_69: "Other",
  text_70:
    "Only jpg/png files can be uploaded, and the image size needs to be 120X120.",
  text_71: "Add channel",
  text_72: "All channels",
  channel_1: "Channel",
  text_73: "Specify Room",
  text_74: "Successfully launched",
  text_75: "Location",
  text_76: "PC background image",
  text_77: "PC Title Image",
  text_78: "Gameplay instructions",
  text_79: "Watch the live broadcast in minutes",
  text_80: "Probability",
  text_81:
    "0 means it is impossible to win a red envelope, and this is the probability that the user will win a prize red envelope.",
  text_82: "Sign in time",
  text_84: "Reward status",
  text_85: "Distribute rewards",
  text_86: "Are you sure to distribute the reward?",
  text_87: "Sign in type",
  text_88: "Sender userid",
  text_89: "Please enter the sender's userid",
  text_90: "Red envelope ID",
  text_91: "Sender nickname",
  text_92: "Initiate room number",
  text_93: "Please enter the room number for sending",
  text_94: "Red envelope gear",
  text_95: "Number of claims",
  text_96: "Number of failures",
  text_97: "Sending time",
  text_98: "Enabled",
  text_99: "Theme ID",
  text_101: "Win or lose",
  text_102: "Red envelope rain ID",
  text_103: "Total number of red envelopes",
  text_104: "Effective",
  text_105: "Invalid",
  text_106: "Bind event/anchor",
  text_107: "Number of red envelopes received",
  text_108: "Number of recipients",
  text_110: "Activity",
  text_111: "Claim",
  text_112: "Room",
  text_113: "Accumulated platform share",
  text_114: "Logout time",
  text_115: "Register IP",
  text_116: "Online duration",
  text_117: "Distribution status:",
  text_118: "Change time",
  text_119: "Session ID",
  text_120: "Live competition ID",
  text_121: "playing method",
  text_122: "Push order content",
  text_123: "Stage",
  text_124: "Support",
  text_125: "Anchor Results",
  text_126: "Oppose",
  text_127: "Correction results",
  text_128: "Reward amount",
  text_129: "Total distribution",
  text_130: "The whistleblower",
  text_131: "Description",
  text_132: "Reply",
  text_133: "APP background",
  live_time: "Live broadcast duration (minutes)",
  Please_select_the_results: "Please select the result of this order",
  red_1: "Red",
  black_1: "Black",
  go_1: "Let's go",
  corner: "Corner kick",
  win_or_lose: "Let's make the score win or lose",
  Size_division: "Size division",
  take_care_1: "Note: The size is 572x420",
  take_care_2: "Note: The size is 926x126",
  discount_1: "discount",
  discount_name_1: "Discount Name",
  text_135: "Please enter the channel nickname",
  characters_1: "Text",
  picture_1: "Image",
  Please_fill_in_the_link_1: "Please fill in the link",
  Please_fill_in_the_link_2: "Please fill in the link correctly",
  publish_this_announcement:
    "Are you sure you want to publish this announcement?",
  send_state: "Sending status",
  Create_marketing_messages: "Create marketing messages",
  SMS_content: "SMS content",
  View_receiving_users: "View receiving users",
  Upload_list: "Upload List",
  Are_you_sure_to_cancel: "Are you sure you want to cancel?",
  User_Import_Template: "User Import Template",
  channel_2: "Channel",
  autograph: "Signature",
  SMS_type: "SMS type",
  SMS_template_editing: "SMS template editing",
  SMS_template_add: "New SMS template added",
  Editing_time: "Editing time",
  Instant_push: "Instant push",
  Timed_push: "Timed push",
  Page_H5: "H5 page",
  Menu_Type: "Menu Type",
  Role_authorization: "Role Authorization",
  name_111: "Name",
  role_111: "Role",
  Modifying_Merchant_Basic_Information: "Modifying Merchant Basic Information",
  Gift_ID: "Gift ID",
  Number_of_deliveries: "Number of sent items",
  Delivery_value_1: "Send out value (gold coins/10000 pieces)",
  broadcast_room_1: "Send out the live broadcast room",
  broadcast_room_2: "Please enter 'send out live broadcast room'",
  broadcast_room_3: "Sending out value",
  Gift_giving_time: "Gift giving time",
  Online_1: "Online",
  Gift_unit_price: "Gift unit price",
  hint_1110:
    "Only jpg/png files can be uploaded, with a size not exceeding 1MB!",
  hint_1112: "Preview effect",
  hint_1113: "Only GIF files can be uploaded, with a size not exceeding 1MB!",
  hint_1114: "Playback effect",
  hint_1115: "Only SVGA files can be uploaded, with a size not exceeding 10MB!",
  Full_platform: "All platforms",
  This_room: "This room",
  hint_1116: "Can only upload. Only jpg/png files can be uploaded!",
  Type_of_issuance: "Distribution type",
  Designated_category: "Designated category",
  hint_1117:
    "Please enter the specified room number, separate multiple rooms with commas in English.",
  hint_1118: "Please enter the gift ID",
  hint_1119: "You can enter up to 99 gift IDs",
  hint_1120: "No spaces can be entered",
  hint_1121: "Chinese commas cannot be entered",
  hint_1122: "Only Chinese, English, and numbers can be entered",
  hint_1123: "Please select the distribution type",
  hint_1124: "Please select the scope of distribution",
  hint_1125: "Please select a category",
  hint_1126: "Please specify a room",
  Publishable_status: "Postable status",
  Expert_nickname: "Expert nickname",
  Expert_brief: "Expert Introduction",
  Expert_detail_1: "Brocade Bag Details",
  article_1:
    "Amiens scored 13 points in 15 rounds of matches, ranking 18th and ranking in the relegation zone. In the last round, they suffered a complete defeat away from home, suffering two consecutive losses and in a low state of form. However, they won 3 games and lost 4 at home, ranking 12th. Although their performance was not outstanding, they still relied on home to grab points. In terms of lineup, the team had one midfielder injured and two players in doubt. The away team ranked second in Ligue 1 last season, but has declined significantly this season. Currently, they have 10 points in 15 rounds, ranking 19th and ranking in the relegation zone. In the last round, they lost away, and the team also suffered two consecutive losses, in a poor state. This season, they have 2 wins, 1 draw, and 4 losses away, ranking 13th. Although the performance was average, all the victories were won away and there is a certain ability to play away. In terms of lineup, the team has been plagued by injury leadership, with up to 14 players currently injured. The initial Asian index provides cautious support to the away team by drawing a draw and receiving a draw of half a set for this game; In the latest index, some institutions have adjusted to tie hands, and their confidence in the visiting team has weakened; The latest average index of Europa Pacific has lowered the winning loss, while multiple institutions have simultaneously lowered the flat loss and raised the negative loss, which is unfavorable for the visiting team.",
  user_1111: "User",
  Import_1111: "Import",
  hint_1127: "We have reached the maximum addition limit",
  Closed_111: "Closed",
  Opened_111: "Opened",
  Select_111: "Select",
  less_than: "Less than",
  Publisher_11: "Publisher",
  Let_the_ball: "Let the ball go",
  analysis: "Analysis",
  Please_enter_the_parsing_content: "Please enter the parsing content",
  price_11: "Price",
  free_11: "free",
  release_1: "Publish",
  hint_1200: "The title needs to be at least 4 words",
  hint_1201: "Recommendation reasons need to be more than 20 words",
  Ball_selection: "Ball selection",
  Top_failure_time: "Top failure time",
  refund: "Refund",
  Topping_time: "Top time",
  System_messages: "System messages",
  Please_enter_the_message_content: "Please enter the message content",
  abnormal: "Exception",
  Let_the_ball_win: "Let the ball win or lose",
  Please_set_the_time: "Please set the time",
  Please_message_content: "Please fill in the message content",
  hint_20001: "The top time cannot be earlier than the current time",
  hint_20002: "Are you sure you want to cancel the topping?",
  hint_20003: "Warehouse construction time",
  Purchase_records: "Purchase records",
  Edit_Resource_Bits: "Edit Resource Bits",
  ADD_Resource_Bits: "Add resource slots",
  hint_20091: "Move Up",
  hint_20092: "Move Down",
  hint_20093: "Add popular items",
  hint_20094: "Video",
  hint_20095: "Page",
  hint_20096: "Add background",
  hint_20097: "Are you sure you want to remove this background image?",
  hint_20098:
    "Only jpg/jpeg/png files can be uploaded, with an aspect ratio of 60 * 60",
  hint_20111: "Please select the barrage library",
  hint_20112: "Please enter the issuance type",
  hint_20113: "Please enter the distribution range",
  hint_20114: "Are you sure you want to '{status}' this configuration?",
  hint_20115: "{status}",
  hint_20116: "The bullet screen content cannot be empty",
  hint_20117: "Add up to 1000 items at a time.",
  hint_20118: "Add time",
  hint_20119:
    "After opening, the live broadcast room of the anchor will re-enter the robot. Are you sure to open it?",
  hint_20121: "Anchor type",
  hint_20122: "Please enter your ID card",
  hint_20123: "Housing management",
  hint_20124: "Please enter uid",
  hint_20125: "User userId",
  hint_20126: "Please enter user userId",
  hint_20127: "Please enter the live broadcast title",
  hint_20128: "Account Merge",
  hint_20129: "Message scope",
  hint_20130: "This message",
  hint_20888: "News within this live broadcast room",
  hint_20131: "All live broadcast room messages",
  hint_20132: "Reason for deletion",
  hint_20133: "User prohibition",
  hint_20134: "Prohibited users",
  hint_20135: "UID cannot be empty",
  hint_20136: "Prohibition period cannot be empty",
  hint_20137: "The reason for the ban cannot be empty",
  hint_20138: "Room number cannot be empty",
  hint_20139: "Please enter the anchor userid",
  hint_20400: "Initiate review",
  hint_20401: "Audit failed",
  hint_20402: "Export to Excel",
  hint_20403: "Settlement cycle",
  hint_20404: "Adjustment amount:",
  hint_20405: "Yuan",
  hint_20406: "Not initiated",
  hint_20407: "Settlement Bill",
  hint_20408: "Before editing",
  hint_20410: "After editing",
  hint_20411: "Pending review",
  hint_20412: "Add Guild",
  hint_20413: "Please enter the name of the receiving account",
  hint_20414: "Please enter the account opening bank",
  hint_20415: "Please enter your bank card number",
  hint_20416: "Please enter the account opening address",
  hint_20417: "Please enter no more than 100 words",
  hint_20418: "Editorial Guild",
  hint_20419:
    "After deactivation, the guild cannot be assigned to the anchor. Are you sure you want to deactivate this guild?",
  hint_20420: "Only jpg/png files can be uploaded, recommended size is",
  hint_20421: "Configure account",
  hint_20422: "Find content",
  hint_20423: "Replace content",
  hint_20424: "Signal source jump address",
  hint_20425: "Please enter the signal source address",
  hint_20426: "Please enter the signal source jump address",
  hint_20427: "Please fill in the iOS Scheme",
  hint_20428: "Please fill in the information about the content",
  hint_20429: "Interface version number",
  hint_20430: "Configuration personnel",
  hint_20431:
    "No short videos have been selected. Please select a short video before publishing!",
  hint_20432: "Successfully published",
  hint_20433:
    "Successfully published, this short video has entered the publishing library",
  hint_20434: "Crawling time period",
  hint_20435: "Please select a time period",
  hint_20436: "Disabled",
  hint_20437: "Set successfully",
  hint_20438: "Whether to place it at the top",
  hint_20439: "Sort position",
  hint_20440: "Comment",
  hint_20441: "Short video cover",
  hint_20442: "Edit Top",
  hint_20443: "Please enter the sorting position",
  hint_20444:
    "After publishing, modifications cannot be made. Are you sure you want to publish?",
  hint_20445: "Access channels",
  hint_20446: "Source domain name",
  hint_20447: "Assistant UV",
  notice_111: "Announcement UV",
  index_111: "Exponential",
  test_111: "Are you sure you want to '{status}' this topic?",
  test_112: "Posting topics",
  test_113: "Are you sure you want to delete it?",
  Batch_approval: "Batch approval",
  Reviewer_111: "Reviewer",
  you_want_to_pass: "Are you sure you want to pass?",
  Download_failed: "Download failed",
  Individual_currency: "Personal currency batch issuance template",
  pass_all_of_them: "Are you sure you want to pass all of them?",
  Fill_in_at_least:
    "Fill in at least one of the options for issuing additional currency or experience value",
  quantity_of_additional_currency:
    "Please enter the quantity of additional currency to be issued",
  money_111: "Amount",
  Input_cannot_be_greater: "Input cannot be greater than 99999 or less than 1",
  Recharge_status: "Recharge status",
  Gold_coins: "Gold coins",
  Alipay_payment: "Alipay payment",
  WeChat_payment: "WeChat Pay",
  ApplePay: "Apple Pay",
  Unpaid_111: "Unpaid",
  Paid_111: "Paid",
  Balance_ranking: "Balance ranking",
  ranking_111: "Ranking",
  Member_Change_Record: "Member Change Record",
  phone_area_code: "Please set your phone area code",
  your_phone_number: "Please set your phone number",
  Successfully_lifted: "Successfully lifted the ban",
  Risk_categories: "Risk category",
  No_type_selected: "No type selected",
  hint2222:
    "After removing the permission, the live broadcast room of the anchor will no longer enter the robot. Are you sure to remove it?",
  hint2223:
    "After disabling, this activity will not be displayed in the red envelope rain library in the policy. Are you sure you want to disable this activity?",
  hint2224:
    "After enabling, this activity will only be displayed in the red envelope rain library in the policy. Are you sure you want to enable this activity?",
  Uploader: "Uploader",
  hint2225: "Cannot be greater than 99999",
  hint2226: "Golden Egg 1",
  hint2227: "15 minutes",
  // 新增
  member_limit: 'Member of the qualified',
  // 总控后台======
  // 新增
  hint2228: "Competitive Recommendation ",
  hint2229: "Content Distribution ",
  hint2230: "Merchant Name ",
  hint2231: "Whether to distribute the bag ",
  hint2232: "Assign content form ",
  hint2233: "Whether to operate content on behalf of others ",
  hint2234: "Whether to assign all content ",
  hint2235: "Original Content Distribution Scope ",
  hint2236: "Whether to assign unlabeled content ",
  hint2237: "Whether to assign all labeled content ",
  hint2238: "Level 1 Label Allocation Range ",
  hint2239: "Current tag ",
  hint2240: "Tag Library ",
  hint2241: "Select all current field ",
  // 2
  hint2242: "All Content Form ",
  hint2243: "Do not assign content ",
  hint2244: "Graphic only ",
  hint2245: "Video only ",
  hint2246: "All Original ",
  hint2247: "No original content assigned ",
  hint2248: "Graphic, Video ",
  hint2249: "Whether to assign all unlabeled content ",
  hint2250: "Whether to assign all labeled content ",
  hint2251: "First Class Label ",
  hint2252: "Please enter tag search ",
  hint2253: "Content Form ",
  hint2254: "Graphic ",
  hint2255: "Video ",
  hint2256: "Tag Editing ",
  hint2257: "Secondary Tags ",

  hint2258: "Create Labels ",
  hint2259: "Edit Tags ",
  hint2260: "Tag Level ",
  hint2261: "First Class ",
  hint2262: "Level 2 ",
  hint2263: "Corresponding primary label ",
  hint2264: "Unfold ",
  hint2265: "Tag status ",
  hint2266: "Bulk Generation Release ",
  hint2267: "Mass removal ",
  hint2268: "Title Keyword ",
  hint2269: "Access to Time ",
  hint2270: "Crawl ",
  hint2271: "Event Center ",
  hint2272: "Original ",
  hint2273: "Source release time ",
  hint2274: "Search tagged content ",
  hint2275: "Generation release status ",
  hint2276: "Release status change time ",
  hint2277: "Please enter title keyword ",
  hint2278: "Audit Return ",
  hint2279: "Please enter first level tag search ",
  hint2281: "Please enter secondary tag search ",
  // 3
  hint2282: "Do you want to return this content data?",
  hint2283:
    "After the return, the merchant will not be able to query the text content of this article for the time being, and it can be reviewed and approved again in the text collection database.",
  hint2284: "Are you sure you want to publish this content data on behalf of the merchant?",
  hint2285: "Are you sure you want to remove this content data on behalf of the merchant?",
  hint2286: "Return ",
  hint2287: "Release ",
  hint2288: "Removed from sale ",
  hint2289: "New Original ",
  hint2299: "Please select category ",
  hint2290: "Audit time ",
  hint2291: "Content Editing ",
  hint2292: "Video Editing ",
  hint2293: "Forward looking ",
  hint2294: "Essence ",
  hint2295: "Entertainment ",
  hint2296: "Upload Video ",
  hint2297: "The format is limited to mp4 or flv files, the file size is small, limited to one ",
  // 新增
  original_content_img: "Image in original content ",
  Custom_Image: "Custom image ",
  insert_the_cover: "Please insert the cover image ",
  Please_improve_the_title: "Please improve the title ",
  upload_the_video: "Please upload video ",
  upload_the_cover_image: "Please upload cover image ",
  result_for_this_content: "Please select audit results for this content ",
  to_the_distribution_repository: "If approved, the content data will be submitted to the distribution library ",
  reviewed_again: "Review rejected, can be reviewed again ",
  Set_crawling_time: "Set the crawl time ",
  What_crawling_task: "Should I crawl the task?",
  Is_it_true: "Whether ",
  Please_refine_the_author: "Please improve the author ",


  Vest_UID: 'Vest UID',
  Operator_ID: 'Operator ID',
  Operator_account: 'Operator account',
  Operator_nickname: 'Operator nickname',
  Explanation_111: 'Explanation: The vest account corresponds to a real C-end user account on the front end. Currently, each account is limited to 10 vest accounts;',
  Failed_to_obtain_user_information: 'Failed to obtain user information',
  Failed_to_obtain_token: 'Failed to obtain token',
  Customer_service: 'Customer service (unavailable)',
  Switching_operational_accounts: 'Switch operational accounts',
  Quick_reply_script: 'Quick reply script',
  marked_27: '{num} users have been automatically extracted',
  Update: 'Update',
  Clear: 'Clear',
  Extract_users: 'Extract users',
  // Add 444
  marked_66: 'Business ID',
  marked_67: 'Exporting...',
  marked_68: 'Tag status',
  marked_69: 'Please select tag status',
  marked_70: 'Add at least one more tag',
  marked_71: 'Number/Nickname',
  marked_72: 'Registration date',
  marked_73: 'User details',
  marked_74: 'User avatar',
  marked_75: 'Event time',
  marked_76: 'Master',
  marked_77: 'Historical reception users',
  marked_78: 'Confirm to force offline? ',
  marked_79: 'APP user',
  marked_80: 'Customer service group',
  marked_81: 'Source of initiation',
  marked_82: 'User initiated',
  marked_83: 'Customer service live room scooped up',
  marked_84: 'User ID',
  marked_85: 'Close type',
  marked_86: 'Session end time',
  marked_87: 'Success',
  marked_88: 'Login password',
  marked_89: 'Remove customer service group',
  marked_90: 'Are you sure you want to enable this account? ',
  marked_91: 'Please select the role status',
  marked_92: 'Authorization successful',
  marked_93: 'New nickname',
  marked_94: 'Customer service information',
  marked_95: 'Please complete the form and provide the parameters required for preview',
  marked_96: 'Please enter a new nickname',
  marked_97: 'Account level',
  marked_98: 'Enable as my vest',
  marked_99: '(unavailable)',
  marked_100: 'Send',
  marked_101: 'Speech hit',
  marked_102: 'Lock operation successful',
  marked_103: 'The keyword has reached the upper limit and cannot be added',
  marked_104: 'No group chat yet',
  marked_105: 'Install the application',
  marked_106: 'Conversion',
  marked_107: 'Convert user',
  style_select: 'Style selection',
  button111: 'Button',

  // Added 3333
  Offline: 'Offline',
  Online: 'Online',
  Busy: 'Busy',
  Historical_status: 'Historical status',
  Reception_traffic_entrance: 'Reception traffic entrance',
  Sender_User_ID: 'Sender user ID',
  User_type_of_sender: 'Sender user type',
  Message_ID: 'Message ID',
  Message_type: 'Message type',
  Message_content: 'Message content',
  Private_conversation_ID_1: 'Private chat session ID',
  Recipient_User_ID: 'Recipient user ID',
  Recipient_user_type: 'Recipient user type',
  Sending_time: 'Sending time',
  Number_of_reception_sessions: 'Number of reception sessions',
  View_current_chat_history: 'View current chat history',
  View_all_chat_records: 'View all chat records',
  Number_of_receptionists: 'Number of receptionists',
  Private_conversation_ID: 'Private chat session ID:',
  Please_enter_the_private_chat_session_ID: 'Please enter the private chat session ID',
  Session_start_time: 'Session start time',
  Customer_service_account_ID: 'Customer service account ID',
  Customer_service_nickname: 'Customer service nickname',
  marked_19: 'Customer service live broadcast room picked up',
  marked_20: 'User triggers traffic entry change release',
  marked_21: 'Customer service takes the initiative to release',
  Customer_service_switch_offline_release: 'Customer service switches to offline release',
  marked_1: 'User private chat message sends customer service signal offline release',
  marked_2: 'User private chat message sends customer service seat offline release',
  marked_3: 'User private chat message sent to customer service agent status unknown release',
  marked_4: 'User private chat message sent to customer service information missing release',
  marked_5: 'User private chat message sent to traffic entrance change release',
  Unknown: 'Unknown',
  Add_menu: 'Add menu',
  catalogue: 'Catalog',
  Menu: 'Menu',
  Menu_icon: 'Menu icon',
  Menu_Title: 'Menu title',
  Routing_address: 'Routing address',
  Whether_to_hide_or_not: 'Whether to hide',
  Menu_Name: 'Menu name',
  Permission_identifier: 'Permission identifier',
  Component_path: 'Component path',
  Please_enter_the_menu_title: 'Please enter the menu title',
  Please_enter_the_routing_address: 'Please enter the routing address',
  Please_select_the_superior_category: 'Please select the parent category',
  Are_you_sure_you_want_to_delete: 'Are you sure you want to delete {num}? ',
  Please_enter_your_account_number: 'Please enter your account number',
  Please_enter_your_name: 'Please enter your name',
  Please_select_a_role: 'Please select a role',
  Please_enter_password: 'Please enter your password',
  marked_22: 'Please select at least one customer service group',
  Reset_password: 'Reset password',
  Customer_Service_Group: 'Customer service group',
  Role: 'Role',
  marked_23: 'Are you sure you want to take this account offline? ',
  marked_24: 'Are you sure you want to deactivate this account? ',
  marked_25: "Are you sure you want to reset this account's password? ",
  Modify_profile_picture: 'Modify profile picture',
  New_avatar: 'New profile picture',
  Please_select_a_profile_picture: 'Please select a profile picture',
  marked_26: 'Change successfully, please log in again',
  Change_nickname: 'Modify nickname',
  Business_System_Api: 'Business system Api',
  Conversion_System_Api: 'Conversion system Api',
  Please_enter_the_business_system_Api: 'Please enter the business system Api',
  Please_enter_the_conversion_system_Api: 'Please enter the conversion system Api',
  Official_avatar: 'Official profile picture',
  Official_nickname: 'Official nickname',
  Please_enter_the_official_nickname: 'Please enter the official nickname',
  Page_Title: 'Page title',
  Please_enter_the_page_title: 'Please enter the page title',
  Red_series: 'Red series',
  Green_series: 'Green series',
  Black_series: 'Black series',
  Please_upload_your_profile_picture: 'Please upload your profile picture',
  Please_select_a_style: 'Please select a style',
  Configuration_query_failed: 'Configuration query failed',
  Preview_Configuration: 'Preview configuration',
  Please_complete_the_form: 'Please complete the form',
  Provide_the_necessary_parameters_for_preview: 'Provide the parameters required for preview',
  Vest_UID: 'Vest UID',
  Operator_ID: 'Operator ID',
  Operator_account: 'Operator account',
  Operator_nickname: 'Operator nickname',
  Explanation_111: 'Note: The vest account corresponds to a real C-end user account on the front end. Currently, each account is limited to 10 vest accounts;',
  Failed_to_obtain_user_information: 'Failed to obtain user information',
  Failed_to_obtain_token: 'Failed to obtain token',
  Customer_service: 'Customer service (unavailable)',
  Switching_operational_accounts: 'Switch operational accounts',
  Quick_reply_script: 'Quick reply script',
  marked_27: '{num} users have been automatically extracted',
  Update: 'Update',
  Clear: 'Clear',
  Extract_users: 'Extract users',
  block: 'Blacklist',
  Locked: 'Lock',
  marked_28: 'Are you sure you want to clear the extracted users? ',
  Successfully_blacklisted: 'Blacklisting operation is successful',
  User_group_comments: 'Comments within the user group',
  marked_29: 'Comments hit and automatically extract users',
  marked_30: 'Only Chinese and English can be entered',
  Successfully_deleted_keyword: 'Keyword deleted successfully',
  User_Group: 'User Group',
  Registration_days: 'Registration days',
  Release: 'Release',
  Label: 'Label',
  Tagging: 'Tag',
  Conversion_Description: 'Conversion description',
  Application_Name: 'Application name',
  Installation_time: 'Installation time',
  User_nickname: 'User nickname',
  Chat_content: 'Chat content',
  Time_of_occurrence: 'Occurrence time',
  Private_Chat: 'Private chat',
  Business_System_UID: 'Business system UID',
  speak111: 'Speak',
  marked_35: 'Please enter the name of the community',
  Name1111: 'Name',
  password111: 'Password',
  enter_the_entrance_ID: 'Please enter the entrance ID',
  enter_the_entrance_111: 'Please enter the entrance name',
  enter_the_entrance_112: 'Please enter the entrance description',
  enter_the_entrance_113: 'Please select the entrance background image',

  // Added 222
  Edit_anchor_circle: 'Edit anchor circle',
  Administrator: 'Administrator',
  Please_select_an_administrator: 'Please select an administrator',
  Anchor_Circle_Name: 'Anchor circle name',
  Anchor_Circle_ID: 'Anchor circle ID',
  Anchor_Circle_Avatar: 'Anchor circle avatar',
  Number_of_members: 'Number of members',
  Free_speech: 'Free speech',
  All_staff_are_prohibited_from_speaking_1: 'All staff are prohibited from speaking',
  Group_entry_time: 'Group entry time',
  Restricted_person: 'Restricted person',
  Is_it_in_the_group: 'Is it in the group',
  Remove: 'Remove',
  Enter: 'Enter',
  Reason: 'Reason',
  Operator: 'Operator',
  Edit_Community_Circle: 'Edit social circle',
  Add_social_circles: 'Add a new social circle',
  Community_nickname: 'Community circle nickname',
  Community_circle_avatar: 'Community circle avatar',
  Group_Leader: 'Group leader',
  All_staff_are_prohibited_from_speaking: 'All staff are prohibited from speaking (excluding administrators)',
  Please_enter_the_social_circle_nickname: 'Please enter the social circle nickname',
  Please_select_the_community_circle_avatar: 'Please select the community circle avatar',
  Please_select_the_group_leader: 'Please select the group leader',
  Please_select_the_speaking_status: 'Please select the speaking status',
  Community_Circle_ID: 'Community circle ID',
  Community_Circle_Name: 'Community circle name',
  Are_you_sure_you_want_to_disable_this_circle: 'Are you sure you want to disable this circle? ',
  Are_you_sure_you_want_to_enable_this_circle: 'Are you sure you want to enable this circle? ',
  User_cancels_operation: 'User cancels operation',
  Speech_status: 'Speech status',
  Reason_for_group_membership: 'Reason for pulling group',
  Group_pull_date: 'Group pull date',
  Move_into_a_new_circle: 'Move into a new circle',
  New_Circle_Name: 'New circle name',
  Circle_Announcement: 'Circle announcement',
  marked_6: 'Please fill in the complete announcement link information before submitting',
  Community_circle: 'Community circle',
  Community_Circle_Management: 'Community circle management',
  Anchor_Circle_Management: 'Anchor circle management',
  Please_enter_the_name_of_the_anchor_circle: 'Please enter the anchor circle name',
  Community_Circle_View: 'Community circle view',
  Anchor_Circle_View: 'Anchor circle view',
  Edit_customer_service_group_classification: 'Edit customer service group classification',
  Add_customer_service_group_classification: 'Add a new customer service group classification',
  Operation_failed: 'Operation failed',
  Edit_traffic_entry: 'Edit traffic entry',
  Add_traffic_entry: 'Add a new traffic entry',
  Entry_Name: 'Entrance name',
  Entrance_ID: 'Entrance ID',
  Sort_Number: 'Sort number',
  Entry_Description: 'Entry description',
  Entrance_background_image: 'Entrance background image',
  Classification_ID: 'Classification ID',
  Policy_application_update: 'Policy application update',
  Set_up_customer_service_group: 'Set up customer service group',
  Strategy_number: 'Strategy number',
  Tourists: 'Tourists',
  Members: 'Members',
  Reception_customer_service_team: 'Reception Customer Service Group',
  Are_you_sure_you_want_to_deactivate_this_account: 'Are you sure you want to deactivate this account? ',
  Are_you_sure_you_want_to_enable_this_account: 'Are you sure you want to enable this account? ',
  Traffic_entrance: 'Traffic entrance',
  Traffic_Entry_Name: 'Traffic entrance name',
  Traffic_Entry_Description: 'Traffic entrance description',
  Customer_service_group_classification: 'Customer service group classification',
  Are_you_sure_you_want_to_disable_this_entry: 'Are you sure you want to disable this entrance? ',
  Are_you_sure_you_want_to_enable_this_entry: 'Are you sure you want to enable this entrance? ',
  Entering_delay_time_in_milliseconds: 'Entering delay time in milliseconds',
  marked_8: 'Please enter the delay time in milliseconds',
  Entering_display_time_in_milliseconds: 'Entering display time in milliseconds',
  marked_9: 'Please enter the display time in milliseconds',
  marked_10: 'Time interval between users displaying fake messages in minutes',
  marked_11: 'Please enter the time interval between users displaying fake messages in minutes',
  Entering_Chinese_script_content: 'Entering script content',
  Global_configuration: 'Global configuration',
  Edit_account: 'Edit account',
  Add_account: 'Add new account',
  A_vest: 'Vest',
  Customer_service_1: 'Customer service',
  Account_type: 'Account type',
  Account_avatar: 'Account avatar',
  Account_nickname: 'Account nickname',
  Please_enter_your_account_nickname: 'Please enter your account nickname',
  Please_select_account_type: 'Please select your account type',
  Please_upload_your_account_avatar: 'Please upload your account avatar',
  Please_select_account_status: 'Please select your account status',
  Log_content: 'Log content',
  marked_50: 'Number',
  System_logs: 'System logs',
  Exporting: 'Exporting...',
  marked_12: 'Missing required parameters for exporting data',
  Last_7_days: 'Last 7 days',
  In_the_past_30_days: 'Last 30 days',
  In_the_past_60_days: 'Last 60 days',
  In_the_past_90_days: 'Last 90 days',
  Time_range: 'Time range',
  System_account: 'System account',
  marked_13: 'Number of newly converted users on the day',
  marked_14: 'Number of newly deconverted users on the day',
  Total_number_of_converted_users: 'Total number of converted users',
  Edit_private_script: 'Edit private script',
  Add_private_script: 'Add private script',
  Script_content: 'Script content',
  Script_state: 'Script status',
  Please_enter_the_script: 'Please enter the script',
  Please_select_the_script_status: 'Please select the script status',
  Are_you_sure_you_want_to_deactivate_this_script: 'Are you sure you want to deactivate this script? ',
  Are_you_sure_you_want_to_enable_this_script: 'Are you sure you want to enable this script? ',
  Edit_public_automatic_scripts: 'Edit public automatic scripts',
  Add_public_automatic_scripts: 'Add public automatic scripts',
  Script_Title: 'Script title',
  Associated_traffic_entry: 'Associated traffic entry',
  Associate_with_customer_service_group: 'Associated customer service group',
  Please_enter_the_script_title: 'Please enter the script title',
  Please_enter_the_sorting_number: 'Please enter the sorting number',
  Please_select_the_associated_traffic_entry: 'Please select the associated traffic entry',
  marked_15: 'Please select the associated customer service group',
  Editing_public_language: 'Edit public language',
  Add_public_language_skills: 'Add public language',
  Script_number: 'Script number',
  Public_speaking: 'Public speaking',
  Public_automatic_language: 'Public automatic language',
  marked_17: 'Are you sure you want to disable this tag? ',
  marked_16: 'Are you sure you want to enable this tag? ',
  Edit_custom_user_groups: 'Edit custom user groups',
  Add_custom_user_groups: 'Add custom user groups',
  Custom_user_group_name: 'Custom user group name:',
  Custom_user_group_description: 'Custom user group description',
  Master_Administrator: 'Master Administrator',
  Speech_settings: 'Speech settings',
  marked_18: 'Please enter a custom user group name',
  Please_select_an_icon: 'Please select an icon',
  Please_select_the_cover_image: 'Please select a cover image',
  Please_select_the_primary_administrator: 'Please select the primary administrator',
  Please_select_speech_settings: 'Please select speech settings',
  Circle: 'Circle',
  Add_internal_members: 'Add internal members',
  Operator_Name: 'Operator name',
  Vest_account_nickname: 'Vest account nickname',
  Third_party_ID: 'Third',


  //小助手缺失翻译
  Business_ID: 'Business ID',
  Please_select_a_member: 'Please select a member ',
  User_group_number: 'User group number ',
  User_group_name: 'User group name ',
  User_group_label: 'User group label ',
  Updated_by: 'update person ',
  Member_management: 'Member management ',
  Cancel_administrator: 'cancel the administrator ',
  Remove_user_group: 'Remove user group ',
  Set_as_administrator: 'Set as administrator ',
  Business_UID: 'business UID',
  Are_you_sure_you_want_to_proceed: 'Are you sure about the operation? ',
  User_ID: 'User ID ',
  User_group_user_records: 'User group user records ',
  Previous_user_group: 'Previous user group ',
  Current_user_group: 'Current user group ',
  Explanation: 'Explanation ',
  Cancel_conversion: 'Cancel conversion ',
  Initial_reporting_time: 'indicates the initial report time.',
  In_the_anchor_circle: 'anchor_circle ',
  Community_Circle: 'Community circle ',
  Are_you_sure_you_want: 'Are you sure you want {num} this user? ',
  User_Information: 'User information ',
  Information_line: 'Information line ',
  Domain_Name_Source: 'domain name source ',
  Please_enter_the_domain_name_source: 'Please enter domain name source ',
  Please_enter_ID: 'Please enter ID',
  Access_time: 'access time ',
  Assistant_data: 'little assistant data ',
  Customer_service_seat_details: 'Customer Service agent details ',
  Force_offline: 'force offline',
  Signal_status: 'Signal status ',
  Seat_status: 'Seat status ',
  Current_reception: 'Current reception ',
  Recently_offline: 'Recently offline ',
  Recently_launched: 'Recently launched ',
  Currently_receiving_users: 'Currently receiving user ',
  There_are_currently_no_customers: 'No customers, there_ARE_CURRENTLY_NO_customers',
  There_is_no_more: 'There is no more ',
  Seat_status_log: 'Seat status log ',
  Signal_status_log: 'Signal status log ',

  // New ==
  Member_Change_Record: 'Member Change Record',
  phone_area_code: 'Please set the mobile phone area code',
  your_phone_number: 'Please set the mobile phone number',
  Successfully_lifted: 'Successfully lifted the ban',
  // New
  Risk_categories: 'Risk category',
  No_type_selected: 'No type selected',

  // New in the assistant
  Click_here_to_retry: 'Click here to retry',
  Loading_failed: 'Loading failed',
  Invalid_data: 'Invalid data',
  Insert_Image: 'Insert image',
  download_111: 'Download',
  not_support_preview: 'The file does not support preview',
  hint_801: 'The file is too large, please upload again',
  hint_802: 'The file format is wrong, please upload again',
  hint_803: 'File upload failed',
  hint_804: 'File exceeds limit',
  Member_Information: 'Member information',
  Personnel_changes: 'Personnel changes',
  Anchor_Circle: 'Anchor circle',
  Total_number_of_people: 'Total number of people',
  New_additions_today: 'Added today',
  Today_decrease: 'Decreased today',
  Today_cumulative_changes: 'Cumulative changes today',
  person_111: 'People',
  // Added 8
  Edit_Resource_Bits: 'Edit resource bits',
  ADD_Resource_Bits: 'Add new resource bits',
  hint_20091: 'Move up',
  hint_20092: 'Move down',
  hint_20093: 'Add popular',
  hint_20094: 'Video',
  hint_20095: 'Page',
  hint_20096: 'Add background',
  hint_20097: 'Are you sure you want to remove this background image? ',
  hint_20098: 'Only jpg/jpeg/png files can be uploaded, with an aspect ratio of 60*60',
  //Added 9
  hint_20111: 'Please select the bullet screen library',
  hint_20112: 'Please enter the type of distribution',
  hint_20113: 'Please enter the distribution range',
  hint_20114: 'Are you sure you want to {status} this configuration? ',
  hint_20115: 'Already {status}',
  hint_20116: 'The bullet screen content cannot be empty',
  hint_20117: 'A maximum of 1,000 items can be added at a time. ',
  hint_20118: 'Add time',
  hint_20119: "After turning it on, the anchor's live broadcast room will re-enter the robot. Are you sure you want to turn it on",
  hint_20121: 'Anchor type',
  hint_20122: 'Please enter ID card',
  hint_20123: 'House management',
  hint_20124: 'Please enter uid',
  hint_20125: 'User userId',
  hint_20126: 'Please enter user userId',
  hint_20127: 'Please enter live title',
  hint_20128: 'Account merger',
  hint_20129: 'Message range',
  hint_20130: 'This message',
  hint_20888: 'Messages in this live room',
  hint_20131: 'All live room messages',
  hint_20132: 'Reason for deletion',
  hint_20133: 'User ban',
  hint_20134: 'Banned user',
  hint_20135: 'UID cannot be empty',
  hint_20136: 'Banned period cannot be empty',
  hint_20137: 'Banned reason cannot be empty',
  hint_20138: 'Room number cannot be empty',
  hint_20139: 'Please enter the anchor userid',
  hint_20400: 'Initiate review',
  hint_20401: 'Review failed',
  hint_20402: 'Export excel',
  hint_20403: 'Settlement period',
  hint_20404: 'Adjustment amount:',
  hint_20405: 'Yuan',
  hint_20406: 'Not initiated',
  hint_20407: 'Settlement bill',
  hint_20408: 'Before editing',
  hint_20410: 'After editing',
  hint_20411: 'To be reviewed',
  hint_20412: 'Add guild',
  hint_20413: 'Please enter the name of the receiving account',
  hint_20414: 'Please enter the bank where the account is opened',
  hint_20415: 'Please enter the bank card number',
  hint_20416: 'Please enter the account address',
  hint_20417: 'Please enter within 100 characters',
  hint_20418: 'Edit guild',
  hint_20419: 'After deactivation, the guild cannot be assigned to the anchor. Are you sure you want to deactivate this guild? ',
  hint_20420: 'Only jpg/png files can be uploaded, the recommended size is',
  hint_20421: 'Configure account',
  hint_20422: 'Search content',
  hint_20423: 'Replace content',
  hint_20424: 'Signal source jump address',
  hint_20425: 'Please enter the signal source address',
  hint_20426: 'Please enter the signal source jump address',
  hint_20427: 'Please fill in ios Scheme',
  hint_20428: 'Please fill in the about content',
  hint_20429: 'Interface version number',
  hint_20430: 'Configurator',
  hint_20431: 'No short video has been selected, please select a short video before publishing! ',
  hint_20432: 'Published successfully',
  hint_20433: 'Published successfully, this short video enters the publishing library',
  hint_20434: 'Crawling time period',
  hint_20435: 'Please select a time period',
  hint_20436: 'Disabled',
  hint_20437: 'Set successfully',
  hint_20438: 'Is it pinned to the top',
  hint_20439: 'Sort position',
  hint_20440: 'Comment',
  hint_20441: 'Short video cover',
  hint_20442: 'Edit and pin',
  hint_20443: 'Please enter the sort position',
  hint_20444: 'It cannot be modified after publishing, do you confirm the publication? ',
  hint_20445: 'Access channel',
  hint_20446: 'Source domain name',
  hint_20447: 'Assistant UV',
  notice_111: 'Announcement UV',
  index_111: 'Index',
  test_111: 'Are you sure you want to {status} this topic? ',
  test_112: 'Publish topic',
  test_113: 'Are you sure you want to delete it? ',
  Batch_approval: 'Batch approval',
  Reviewer_111: 'Reviewer',
  you_want_to_pass: 'Are you sure you want to pass? ',
  Download_failed: 'Download failed',
  Individual_currency: 'Personal currency batch issuance template',
  pass_all_of_them: 'Are you sure you want to pass all? ',
  Fill_in_at_least: 'Additional currency, additional experience value, at least one of them should be filled in',
  quantity_of_additional_currency: 'Please enter the amount of additional currency',
  money_111: 'Amount',
  Input_cannot_be_greater: 'The input cannot be greater than 99999 or less than 1',
  Recharge_status: 'Recharge status',
  Gold_coins: 'Gold coins',
  Alipay_payment: 'Alipay payment',
  WeChat_payment: 'WeChat payment',
  ApplePay: 'Apple payment',
  Unpaid_111: 'Unpaid',
  Paid_111: 'Paid',
  Balance_ranking: 'Balance ranking',
  ranking_111: 'Ranking',
  // Add 5
  live_time: 'Live broadcast duration (minutes)',
  Please_select_the_results: 'Please select the results of this order',
  red_1: 'Red',
  black_1: 'Black',
  go_1: 'Go',
  corner: 'Corner kick',
  win_or_lose: 'Handicap win or lose',
  Size_division: 'Over/under',
  take_care_1: 'Note: size is 572x420',
  take_care_2: 'Note: size is 926x126',
  discount_1: 'Discount',
  discount_name_1: 'Discount name',
  text_135: 'Please enter the channel nickname',
  characters_1: 'Text',
  picture_1: 'Picture',
  Please_fill_in_the_link_1: 'Please fill in the link',
  Please_fill_in_the_link_2: 'Please fill in the link correctly',
  publish_this_announcement: 'Are you sure you want to publish this announcement? ',
  send_state: 'Send status',
  Create_marketing_messages: 'Create marketing SMS',
  SMS_content: 'SMS content',
  View_receiving_users: 'View receiving users',
  Upload_list: 'Upload list',
  Are_you_sure_to_cancel: 'Confirm cancellation',
  User_Import_Template: 'User import template',
  channel_2: 'Channel',
  autograph: 'Signature',
  SMS_type: 'SMS type',
  SMS_template_editing: 'SMS template editing',
  SMS_template_add: 'SMS template addition',
  Editing_time: 'Editing time',
  Instant_push: 'Instant push',
  Timed_push: 'Timed push',
  Page_H5: 'H5 page',
  Menu_Type: 'Menu type',
  Role_authorization: 'Role authorization',
  name_111: 'Name',
  role_111: 'Role',
  Modifying_Merchant_Basic_Information: 'Modify merchant basic information',
  Gift_ID: 'Gift ID',
  Number_of_deliveries: 'Number of deliveries',
  Delivery_value_1: 'Delivery value (gold coins/10,000)',
  broadcast_room_1: 'Delivery live room',
  broadcast_room_2: 'Please enter the delivery live room',
  broadcast_room_3: 'Delivery value',
  Gift_giving_time: 'Gift time',
  Online_1: 'Online',
  Gift_unit_price: 'Gift unit price',
  hint_1110: 'Only jpg/png files can be uploaded, and the size does not exceed 1MB! ',
  hint_1112: 'Preview effect',
  hint_1113: 'Only gif files can be uploaded, and the size does not exceed 1MB! ',
  hint_1114: 'Playback effect',
  hint_1115: 'Only svga files can be uploaded, and the size should not exceed 10MB! ',
  Full_platform: 'Full platform',
  This_room: 'This room',
  hint_1116: 'You can only upload jpg/png files!',
  Type_of_issuance: 'Issuance type',
  Designated_category: 'Specified category',
  hint_1117: 'Please enter the designated room number. Multiple rooms are separated by English commas',
  hint_1118: 'Please enter the gift ID',
  hint_1119: 'You can enter up to 99 gift IDs',
  hint_1120: 'No spaces allowed',
  hint_1121: 'No Chinese commas allowed',
  hint_1122: 'You can only enter Chinese, English, and numbers',
  hint_1123: 'Please select the type of issuance',
  hint_1124: 'Please select the scope of issuance',
  hint_1125: 'Please select the category',
  hint_1126: 'Please specify a room',
  Publishable_status: 'Publishable status',
  Expert_nickname: 'Expert nickname',
  Expert_brief: 'Expert profile',
  Expert_detail_1: 'Tips details',
  article_1: "Amiens has 13 points in 15 games, ranking 18th, in the relegation zone; they lost the last round away game, suffered two consecutive defeats, and are in a slump; and they have 3 wins and 4 losses at home, ranking 12th. Although the performance is not outstanding, they still rely on home games to grab points; in terms of lineup, the team has 1 midfielder injured and 2 players are doubtful. The visiting team ranked second in Ligue 1 last season, but has fallen sharply this season. Currently, they have 10 points in 15 games and are ranked 19th, in the relegation zone. They lost the last round of away games, and the team also suffered two consecutive defeats, and their form is poor. This season, they have 2 wins, 1 draw and 4 losses away from home, ranking 13th. Although their performance is average, all the wins were won away from home, and they have a certain ability to play away games. In terms of lineup, the team is plagued by injuries, and currently there are as many as 14 players injured. The initial Asian index opened a draw and a half-draw for the home team in this game, giving cautious support to the visiting team. In the latest index, some institutions have adjusted to a draw, and the institutions' confidence in the visiting team has weakened. The latest average index of European odds lowers the odds of winning, and many institutions simultaneously lower the odds of drawing and raise the odds of losing, which is unfavorable to the visiting team. ",
  user_1111: 'User',
  Import_1111: 'Import',
  hint_1127: 'The upper limit of adding has been reached',
  Closed_111: 'Closed',
  Opened_111: 'Opened',
  Select_111: 'Select',
  less_than: 'Less than',
  Publisher_11: 'Publisher',
  Let_the_ball: 'Let the ball',
  analysis: 'Analysis',
  Please_enter_the_parsing_content: 'Please enter the parsing content',
  price_11: 'Price',
  free_11: 'Free',
  release_1: 'Release',
  hint_1200: 'The title needs to be more than 4 words',
  hint_1201: 'Recommendation reason needs to be more than 20 words',
  Ball_selection: 'Ball selection',
  Top_failure_time: 'Top expiration time',
  refund: 'Refund',
  Topping_time: 'Top time',
  System_messages: 'System message',
  Please_enter_the_message_content: 'Please enter the message content',
  abnormal: 'Abnormal',
  Let_the_ball_win: 'Let the ball win, draw or lose',
  Please_set_the_time: 'Please set the time',
  Please_message_content: 'Please fill in the message content',
  hint_20001: 'Top time cannot be earlier than the current time',
  hint_20002: 'Are you sure you want to cancel the top? ',
  hint_20003: 'Position opening time',
  Purchase_records: 'Purchase records',
  // Added 4
  text_7: 'User private message to anchor',
  text_8: 'Anchor private message to user',
  text_9: 'User private message to administrator',
  text_10: 'Private chat anchor level',
  text_11: 'Logo at the top of the download page',
  text_12: 'APP/H5 album page status',
  text_13: 'IOS triple picture',
  text_14: 'First picture',
  text_15: 'Second picture',
  text_16: 'Third picture',
  text_17: 'Fourth picture',
  text_18: 'The content of the ${num}th picture cannot be empty',
  text_19: 'The ${num}th picture cannot be empty',
  text_20: 'The recommended image size is 40x40, and only jpg/png/gif files can be uploaded',
  text_21: 'The recommended image size is 40x40. Only jpg/png/gif files can be uploaded',
  text_22: 'PC album page name',
  text_23: 'Please enter the URL corresponding to src. If you need to count multiple domain names, please use || to distinguish',
  text_24: 'About page copy configuration',
  text_25: 'Please fill in the copy content',
  text_26: 'Please enter the developer account',
  text_27: 'Newbie guide page',
  text_28: 'No need to update',
  text_29: 'Optional update',
  text_30: 'Forced update',
  text_31: 'Public key',
  text_32: 'Private key',
  text_33: 'Function description',
  text_34: 'Android configuration',
  text_35: 'Send red envelopes in the live broadcast room',
  text_36: 'Give gifts in the live broadcast room',
  text_37: 'Start APP tracking point reporting',
  text_38: 'Domain type',
  text_39: 'Edit domain name',
  text_40: 'Add domain name',
  have_1: 'Yes',
  have_2: 'No',
  updateTime_1: 'Update time',
  text_41: 'Are you sure you want to delete this domain name? ',
  text_42: 'After the ban is lifted, the word can continue to be sent on the platform. Are you sure to lift the ban? ',
  text_43: 'Banner URL of live broadcast room',
  text_44: 'H5banner',
  text_45: 'Please enter description',
  text_46: 'Please enter homepage title',
  text_47: 'Please enter live page title',
  text_48: 'Only jpg/jpeg/png files can be uploaded, and up to 9 photos can be uploaded',
  text_49: 'Upload video files',
  text_50: 'Please enter circle id',
  text_51: 'Supported formats: JPG, PNG, GIF, MP4, size: within 3M:',
  text_52: 'Unpublished',
  text_53: 'Please enter introduction',
  text_54: 'Only jpg/png/gif files can be uploaded, limited to 1M, size 790*215px',
  text_55: 'Set the upper limit of the number of people',
  text_56: 'Submission time',
  text_57: 'Please set the upper limit of the number of people',
  text_58: 'The upper limit of the number of people must not be less than 50',
  text_59: 'Real name',
  text_60: 'Agreed',
  text_61: 'Number of circles created',
  text_62: 'Number of circle users',
  text_63: 'Joining time',
  text_64: 'ID number:',
  text_65: 'Please enter your mobile phone number',
  text_66: 'Submitter',
  text_67: 'Sports',
  text_68: 'Entertainment',
  text_69: 'Others',
  text_70: 'Only jpg/png files can be uploaded, and the image size must be 120X120',
  text_71: 'Add channel',
  text_72: 'All channels',
  channel_1: 'Channel',
  text_73: 'Specified room',
  text_74: 'Online Success',
  text_75: 'Location',
  text_76: 'PC Background Image',
  text_77: 'PC Title Image',
  text_78: 'Game Instructions',
  text_79: 'Watching Live Streaming Duration (Minutes)',
  text_80: 'Probability',
  text_81: '0 means it is impossible to draw a red envelope, this is the probability of the user drawing a winning red envelope',
  text_82: 'Sign-in Time',
  text_84: 'Reward Status',
  text_85: 'Distribute Rewards',
  text_86: 'Are you sure you want to distribute rewards? ',
  text_87: 'Sign-in type',
  text_88: 'Sender userid',
  text_89: 'Please enter the sender userid',
  text_90: 'Red envelope ID',
  text_91: 'Sender nickname',
  text_92: 'Initiating room number',
  text_93: 'Please enter the sending room number',
  text_94: 'Red envelope level',
  text_95: 'Number of received',
  text_96: 'Number of invalidated',
  text_97: 'Sending time',
  text_98: 'Enabled',
  text_99: 'Topic ID',
  text_101: 'Win or lose',
  text_102: 'Red envelope rain ID',
  text_103: 'Total number of red envelopes',
  text_104: 'Effective',
  text_105: 'Invalidated',
  text_106: 'Bound event/anchor',
  text_107: 'Number of red envelopes received',
  text_108: 'Number of recipients',
  text_110: 'Event',
  text_111: 'Receive',
  text_112: 'Room',
  text_113: 'Platform cumulative share',
  text_114: 'Logout time',
  text_115: 'Registered IP',
  text_116: 'Online time',
  text_117: 'Distribution status:',
  text_118: 'Change time',
  text_119: 'Match ID',
  text_120: 'Live broadcast match ID',
  text_121: 'Gameplay',
  text_122: 'Push order content',
  text_123: 'Stage',
  text_124: 'Support',
  text_125: 'Anchor result',
  text_126: 'Opposition',
  text_127: 'Correction result',
  text_128: 'Amount of reward',
  text_129: 'Total amount issued',
  text_130: 'Reporter',
  text_131: 'Description',
  text_132: 'Reply',
  text_133: 'APP background',

  // Added
  created_topic_2: "Edit topic",
  briefing_topic_1: "Topic introduction",
  briefing_topic_2: "Topic thumbnail",
  hint_899: "Only jpg/png files can be uploaded, limited to 1M, size 790*215px",
  hint_907: "Only jpg/png files can be uploaded, limited to 1M, size 150*58px",
  hint_917: "Only jpg/png files can be uploaded, limited to 1M, size 355*148px",
  hint_918: "The recommended image size is 256x256, only jpg/png files can be uploaded",
  lookDetail: "View",
  text_1: "Are you sure to remove it from the shelves?",
  text_2: "Are you sure to put it on the shelves?",
  // Added 2
  matchPlan_1: 'Competition progress',
  group_1: 'Group',
  shopEdit: 'Product edit',
  viShopEditName: 'Vietnamese product name',
  viShopEditName1: 'Please enter the Vietnamese product name',
  hint_1001: 'The purchase of cat coins cannot be empty',
  hint_1002: 'The purchase of diamonds cannot be empty',
  hint_1003: 'Please enter cat coins',
  // Add 3
  remark_edit: 'Remark Edit',
  currency_cat: 'Cat Coins',
  diamond_1: 'Diamonds',
  Invitee_UID_1: "Invitee UID",
  Invitee_edit_1: "Invitation Configuration",
  minute_1: "Minutes",
  establish_1: "Create",
  hint_1005: "Football Winning Statistics",
  hint_1006: "Basketball Winning Statistics",
  hint_1007: "Winning Details",
  hint_1008: "Start",
  hint_1009: "End",
  hint_1011: "Not filled in",
  hint_1012: "Select banner image",
  hint_1013: "PC jump page",
  hint_1014: "Use",
  hint_1015: "Prize ID",
  hint_1016: "Lottery ticket",
  hint_1017: "Link",
  hint_1018: "Information ID",
  hint_1019: "Upload music thumbnail",
  hint_1021: "English letter level limit",
  hint_1022: "Live broadcast room push order automatically pops up",
  advertising_title: "Advertising title",
  Author_name_1: "Author",

  Lottery_time: "Lottery time",
  Activity_ID: "Activity ID",
  Accessing_Users: "Accessing users",
  Participating_users: "Participating users",
  Data_details: "Data details",
  Channel_sources: "Channel sources",
  Click_on_the_number_of_lucky_draws: "Click on the number of lucky draws",
  Page_visits: "Number of page visits",
  Number_of_times_participating_in_the_lottery: "Number of times participating in the lottery",
  Winning_users: "Winning users",
  Users_who_win_physical_prizes: "Physical prize winning users",
  Click_to_go_to_the_assistant_user: "Click to go to the assistant user",
  Click_to_download_app_users: "Click to download APP users",
  Award_pool_type: "Award pool type",
  Lucky_Wheel: "Lucky Wheel",
  Super_Wheel: "Super Wheel",
  Lottery_Record_Daily_Turntable: "Lottery Record-Daily Turntable",
  Physical_rewards: "Physical Rewards",
  Draw_lottery_tickets: "Lottery Tickets",
  Processing_personnel: "Processing Person",
  Not_issued: "Not Issued",
  Issued: "Issued",
  Virtual: "Virtual",
  Operation_turntable: "Operation Turntable",
  Activity_Management: "Activity Management",
  Lottery_record: "Lottery Record",
  Operation_turntable_activity_data: "Operation Turntable Activity Data",
  Activity_type: "Activity Type",
  Activity_carousel: "Activity Turntable",
  Daily_turntable: "Daily Turntable",
  Activity_statistics: "Activity Statistics",
  Number_of_super_lottery_tickets_distributed: "Number of Super Lottery Tickets Distributed",
  Lucky_Wheel_Lucky_Draw_Times: "Number of Lucky Wheel Draws",
  Super_Wheel_Lottery_Times: "Super Wheel Lottery Times",
  Basic_information: "Basic Information",
  Activity_Rules: "Activity Rules",
  Please_enter_activity_rules: "Please enter the activity rules",
  PC_banner_image: "pc banner image",
  H5_Jump_Page: "h5 jump page",
  In_use: "In use",
  Total_inventory: "Total inventory",
  Prizes: "Prizes",
  Single_day_upper_limit: "Single day upper limit",
  Individual_users_can_receive_upper_limits: "Individual user can receive upper limit",
  Lucky_draw_probability_in_tens_of_thousands_: "Lucky draw probability (ten thousand points)",
  Is_it_a_running_horse_lamp_: "Is it a running horse lamp",
  Is_it_a_bottom_line: "Is it a bottom line",
  Add_prize_pool: "Add prize pool",
  Cloning: "Clone",
  Single_consumption: "Single consumption",
  Daily_limit: "Daily limit",
  Activity_channels: "Activity channels",
  H5_banner_diagram: "H5 banner diagram",
  How_to_earn_cat_coins: "How to earn cat coins",
  Please_select_the_activity_time_range: "Please select the activity time range",
  Edit_anchor_recommendation_group: "Edit anchor recommendation group",
  Add_a_new_anchor_recommendation_group: "Add anchor recommendation group",
  Please_upload_the_PC_banner_image: "Please upload PC banner image",
  Please_upload_the_h5_banner_image: "Please upload h5 banner image",
  Please_enter_PC_Jump_Page: "Please enter PC jump page",
  Please_enter_h5_to_jump_to_the_page: "Please enter h5 jump page",
  Please_enter_a_single_consumption: "Please enter the single consumption",
  Please_enter_the_daily_limit: "Please enter the daily limit",
  Please_select_the_activity_channel: "Please select the activity channel",
  Prize_Pool: "Prize Pool",
  hint_110: "Prize Pool opening time cannot be earlier than the activity opening time",
  hint_111: "Prize Pool ending time cannot be later than the activity ending time",
  The_total_probability_does_not_equal_10000: "The total probability is not equal to 10000",
  Sum_of_probabilities: "The total probability",
  Please_select_the_prize_for_prize_: "Please select the prize for prize {num}",
  Please_enter_the_total_inventory_of_prize_: "Please enter the total inventory of prize {num}",
  _The_daily_limit_is_greater_than_the_total_inventory: "{num} daily limit is greater than the total inventory",
  hint_112: "Please enter the prize {num}'s probability of winning (in ten thousand points)",
  hint_115: "Please select whether the prize {num} is a marquee",
  hint_116: "Each prize pool must select a bottom prize!",
  hint_114: "Please fill in the effective time of the prize pool",
  hint_113: "Are you sure to delete this prize pool?",
  Data_Overview: "Data Overview",
  Quantity_of_currency: "Currency Quantity",
  Please_enter_the_quantity_of_currency: "Please enter the currency quantity",
  Number_of_lottery_tickets: "Number of Lottery Tickets",
  Please_enter_the_number_of_lottery_tickets: "Please enter the number of lottery tickets",
  Select_icon: "Select icon",
  Please_select_the_type_of_prize: "Please select the prize type",
  Please_upload_the_icon: "Please upload icon",
  Editing_prizes: "Editing prizes",
  Please_enter_a_positive_integer: "Please enter a positive integer",
  single_1: "pieces",
  submit_results_1: "Submit results",
  rules_options: "Rule configuration",
  rules_options_1: "Rule configuration cannot be empty",
  edit_activity_cai: "Edit guessing activity",
  created_topic_1: "Create topic",
  Thai_language_details: "Detailed explanation in Thai",
  English_detailed_explanation: "Detailed explanation in English",
  Vietnamese_language_details: "Detailed explanation in Vietnamese",
  Korean_language_details: "Detailed explanation in Korean",
  Brazilian_language_details: "Detailed explanation in Brazilian",
  Please_enter_the_diamond_price_of_the_product: "Please enter the diamond price of the product",
  Are_you_sure_you_want_to_delist_this_product_: "Are you sure you want to delist this product?",
  Label_ID: "Label ID",
  Please_enter_the_label_name: "Please enter the label name",
  Label_Name: "Label name",
  hint_624: "Are you sure you want to add this product label?",
  hint_101: "Are you sure you want to remove this product label?",
  Edit_label: "Edit label",
  Order_number: "Order number",
  Please_enter_the_order_number: "Please enter the order number",
  Transaction_time: "Transaction time",
  Receiving_address: "Receiving address",
  Signee: "Signee",
  Transaction_volume: "Transaction volume",
  Total_transaction_amount: "Total transaction amount",
  Transaction_status: "Transaction status",
  Unsuccessful: "Unsuccessful",
  Limited_to_50_words_in_length: "Limited to 50 words",
  Inviter_UID: "Inviter UID",
  Inviting_person_nickname: "Inviter nickname",
  Invitee_UID: "Invitee UID",
  Invitee_registration_time: "Invitee registration time",
  Please_enter_the_inviter_reward: "Please enter the inviter reward",
  Please_enter_the_invitee_reward: "Please enter the invitee reward",
  Rule_Description: "Rule description",
  APP_landing_page_background_image: "APP landing page background image",
  hint_621: "Size: 710X1086 Only jpg/png files can be uploaded",
  Watch_the_live_broadcast: "Watch live broadcast",
  Live_room_speech: "Live room speech",
  Push_order_configuration: "Push order configuration",
  hint_102: "Friendly reminder: 1-Please configure the speech configuration in order. If you configure to the second sentence, only the speech configuration contents of 1 and 2 will be executed. ",
  hint_905: "Configuration of {num}th speech",
  Reward_amount: "Reward amount",
  Please_enter_the_speech_reward_amount: "Please enter the speech reward amount",
  Valid_characters: "Valid characters",
  hint_623: "Please enter valid characters for speech",
  hint_103: "The reward amount for the first speech configuration cannot be empty",
  hint_104: "The reward amount for the second speech configuration cannot be empty",
  hint_105: "The reward amount for the third speech configuration cannot be empty",
  Friendly_reminder: "Friendly reminder",
  hint_1: "1-Push order data and user participation data can be viewed in Live Management> Live Room Push Order Module;",
  hint_2: "2-Push order reward interval: The reward for the anchor's push order will be randomly generated in this interval;",
  hint_3: "3-User daily reward peak: If the peak value is exceeded, continue to participate in the push order and no reward will be obtained;",
  hint_889: "Push order reward interval",
  Maximum_reward: "Maximum reward",
  Minimum_reward: "Minimum reward",
  Users_get_peak_daily: "Users get peak value daily",
  hint_622: "Please enter the maximum daily gain value of users",
  Please_enter_the_maximum_reward: "Please enter the maximum reward",
  Please_enter_the_minimum_reward: "Please enter the minimum reward",
  Please_enter_the_user_daily_peak_gain: "Please enter the user's daily peak gain",
  Viewing_duration: "Watching duration",
  hint_107: "Please enter the viewing duration, which must be a positive integer",
  Watch_rewards: "Watching rewards",
  Please_enter_the_viewing_reward: "Please enter the viewing rewards",
  Reward_channels: "Reward channels",
  hint_106: "After opening, you will get rewards for watching live broadcasts on this channel. Watching on different channels will only be rewarded once;",
  Please_enter_the_viewing_duration: "Please enter the viewing duration",
  Please_select_a_reward_channel: "Please select a reward channel",
  First_line_copy: "First line of text",
  hint_108: "Please enter the text, up to 10 characters supported",
  Second_line_copy: "Second line of text",
  Please_enter_the_first_line_of_text: "Please enter the first line of text",
  Please_enter_the_second_line_of_text: "Please enter the second line of text",
  Effective_date: "Effective date",
  Display_copy: "Display text",
  Gift_channels: "Gift channels",
  Login_rewards: "Login rewards",
  Accumulated_Gifts: "Accumulated gifts",
  Additional_rewards_for_app_login: "Additional rewards for APP login",
  hint_109: "Please enter the login reward, no reward if 0",
  Please_enter_the_reward_amount: "Please enter the reward amount",
  Login_Activity_Rewards: "Login activity rewards",
  Please_enter_display_copy: "Please enter the display copy",
  Please_enter_the_login_reward: "Please enter the login reward",
  Please_enter_a_valid_date: "Please enter the valid date",
  Please_enter_to_select_the_gift_channel: "Please enter the gift channel",
  Login_reward_activity: "Login reward activity",
  Download_copy_configuration: "Download copy configuration",
  Semi_finals: "Semifinals",
  Finals: "Finals",
  Location: "Location",
  Facing_each_other: "Fight",
  hint_95: "Please enter the anchor room number and separate it with commas, up to four digits",
  Fen: "Fen",
  hint_628: "You can only select up to 4 anchor room numbers",
  SM_redemption_page: "SM redemption page",
  AK_redemption_page: "AK redemption page",
  H5_end_thumbnail: "H5 end thumbnail",
  hint_96: "Only jpg/png/gif files can be uploaded, size 1440*315",
  Category_ID: "Category ID",
  Classification_name: "Classification name",
  Please_enter_the_category_name: "Please enter the category name",
  Thai_tag_name: "Thai tag name",
  Please_enter_the_Thai_tag_name: "Please enter the Thai tag name",
  English_tag_name: "English tag name",
  Please_enter_the_English_tag_name: "Please enter the English tag name",
  Vietnamese_label_name: "Vietnamese label name",
  Please_enter_the_Vietnamese_label_name: "Please enter the Vietnamese tag name",
  Korean_tag_name: "Korean label name",
  Please_enter_the_Korean_tag_name: "Please enter the Korean tag name",
  Brazilian_label_name: "Brazilian label name",
  Please_enter_the_Brazilian_label_name: "Please enter the Brazilian label name",
  Add_a_new_category: "Add a new category",
  hint_888: "Fine products",
  Please_select_the_status: "Please select the status",
  hint_627: "Are you sure to put this product online?",
  hint_626: "Are you sure to take this product category offline?",
  Warning: "Warning",
  hint_97: "The category contains its own products and cannot be taken offline",
  Edit_Classification: "Edit category",
  Redemption_page_BANNER: "Redemption page BANNER",
  hint_625: "The uploaded image size cannot exceed 5M!",
  Delisted_products: "Delisted products",
  Listing_products: "Listed products",
  Classification_management: "Classification management",
  Label_management: "Label management",
  Order_List: "Order list",
  Product_ID: "Product ID",
  Please_enter_the_product_ID: "Please enter the product ID",
  Product_name: "Product name",
  Please_enter_the_product_name: "Please enter the product name",
  Do_we_need_delivery_: "Do we need delivery",
  Switch: "Switch",
  Unit_price: "Unit price",
  Discounted_price: "Discounted price",
  Current_inventory: "Current inventory",
  Redeemed: "Redeemed",
  Inventory_changes: "Inventory changes",
  Select_thumbnail: "Select thumbnail",
  Details_page_image: "Details page image",
  Select_detailed_image: "Select details image",
  Details: "Details description",
  Please_enter_the_unit_price: "Please enter the unit price",
  Discounts: "Discounted price",
  Please_enter_the_discounted_price_of_the_product: "Please enter the discounted price of the product",
  Please_select_a_category: "Please select a category",
  Label: "Label",
  Product_purchase_restriction_period: "Product purchase restriction period",
  No_purchase_restrictions: "No purchase restrictions",
  Day: "Day",
  Week: "Week",
  Cumulative: "Cumulative",
  Product_purchase_limit: "Product purchase limit number",
  hint_98: "Please enter the product purchase limit number within the period",
  Inventory: "Inventory",
  Add: "Increase",
  Reduce: "Reduce",
  The_product_name_cannot_be_empty: "Product name cannot be empty",
  Boutique: "Boutique",
  Life: "Life",
  Product_addition: "Product addition",
  Please_upload_thumbnail: "Please upload thumbnail",
  Please_upload_detailed_images: "Please upload detailed images",
  Please_select_whether_delivery_is_required: "Please select whether delivery is required",
  Purchase_unit_price_cannot_be_empty: "Purchase unit price cannot be empty",
  Classification_cannot_be_empty: "Classification cannot be empty",
  hint_99: "Product purchase limit cannot be empty",
  Details_cannot_be_empty: "Details cannot be empty",
  Please_select_increase_decrease: "Please select increase or decrease",
  Are_you_sure_you_want_to_save_: "Are you sure to save? ",
  Cat_coin_price: "Cat coin price",
  Diamond_price: "Diamond price",
  Thai_product_name: "Thai product name",
  Please_enter_the_Thai_product_name: "Please enter the Thai product name",
  English_product_name: "English product name",
  Please_enter_the_English_product_name: "Please enter the English product name",
  Korean_product_name: "Korean product name",
  Please_enter_the_Korean_product_name: "Please enter the Korean product name",
  Brazilian_product_name: "Brazilian product name",
  Please_enter_the_Brazilian_product_name: "Please enter the Brazilian product name",
  hint_902: "Size recommendation 174*174",
  hint_903: "Size recommendation 375*375",


  The_problem_name_cannot_be_empty: "The problem name cannot be empty",
  Please_enter_the_question_name: "Please enter the question name",
  Please_enter_question_options: "Please enter the question options",
  question_options_1: "Question options",
  Add_Options: "Add options",
  Please_enter_the_number_of_currencies_for_the_reward_base: "Please enter the number of currencies for the reward base",
  Save: "Save",
  Cancel: "Cancel",
  Please_submit_results_with_caution: "Please submit results with caution",
  The_answer_to_the_question_cannot_be_empty: "The answer to the question cannot be empty",
  Flow: "Flow",
  Activity_Rule_Configuration: "Activity rule configuration",
  Rule_configuration_cannot_be_empty: "Rule configuration cannot be empty",
  Limited_to_200_words_in_length: "Limited to 200 characters",
  Guessing_Style_Configuration: "Guessing style configuration",
  PC_Banner_diagram: "PC Banner diagram",
  PC_Banner_image_cannot_be_empty: "PC Banner diagram cannot be empty",
  hint_612: "Size: 1920x624 Only jpg/png files can be uploaded",
  H5_APP_Banner_image: "H5/APP Banner diagram",
  H5_APP_Banner_image_cannot_be_empty: "H5/APP Banner diagram cannot be empty",
  hint_613: "Size: 750x1012 Only jpg/png files can be uploaded",
  Theme_color: "Theme color",
  The_theme_color_cannot_be_empty: "Theme color cannot be empty",
  hint_619: "Color configuration at the bottom of the page",
  Event_details: "Event details",
  Correct_answer: "Correct answer",
  Stopped: "Stopped",
  Add_a_guessing_activity: "Add a guessing activity",
  hint_618: "Please fill in the name of the guessing question",
  Please_select_the_effective_time_range: "Please select the effective time range",
  Please_fill_in_the_reward_base: "Please fill in the reward base",
  hint_92: "After cancellation, the guessing activity will not be released. Are you sure to cancel the guessing activity?",
  Success: "Success",
  Cancel_successful: "Cancel successfully",
  hint_93: "After stopping, users will not be able to participate in the guessing activity. Are you sure to stop the guessing activity?",
  hint_614: "Please enter a positive integer reward base of 1-99999",
  Cannot_select_past_time: "Cannot select past time",
  hint_615: "Are you sure to publish the answer? ",
  Save_successful: "Save successfully",
  hint_616: "The answer options for question {num} must have at least 2 options",
  Number_of_participants: "Number of participants",
  Number_of_winners: "Number of winners",
  hint_617: "Number of rewards (gold coins)",
  Question_Name: "Question name",
  Number_of_correct_answers: "Number of correct answers",
  Activity_List: "Activity list",
  Top_of_the_event: "Top of the event",
  PC_end_carousel: "PC carousel",
  AK_Activity_List: "AK-Activity list",
  AK_Activity_Topping: "AK-Top of the event",
  AK_PC_end_carousel: "AK-PC carousel",
  Create_activity_records: "Create activity records",
  Event_main_title: "Activity main title",
  Activity_subtitle: "Activity subtitle",
  PC_thumbnail: "PC thumbnail",
  App_thumbnail: "App thumbnail",
  Activity_time: "Activity time",
  Long_term_activities: "Long-term activities",
  Please_enter_the_main_title_of_the_activity: "Please enter the main title of the activity",
  Please_enter_the_activity_subtitle: "Please enter the subtitle of the activity",
  PC_activity_redirect_address: "PC activity jump address",
  Activity_jump_button_background: "Activity jump button background",
  App_side_thumbnail: "App thumbnail",
  hint_901: "Size 355*148px",
  App_activity_redirect_address: "App activity jump address",
  Time_type: "Time type",
  There_is_a_time_limit: "There is a time limit",
  Edit_activity_records: "Edit activity records",
  hint_629: "Are you sure you want to pin this activity record? ",
  hint_620: "Are you sure you want to unpin this activity record?",
  Please_set_the_activity_time: "Please set the activity time",
  Jump_URL: "Jump URL",
  Queue: "Queue",
  Cover: "Cover",
  Mobile_download_page: "Mobile download page",
  PC_Download_Page: "PC download page",
  Android_copywriting: "Android copywriting",
  hint_94: "Please enter the copywriting, up to 10 characters supported",
  IOS_Web_App_Copywriting: "iOS-Web version APP copywriting",
  IOS_copywriting: "iOS copywriting",
  PC_background_configuration: "PC background configuration",
  Group_stage_competition: "Group stage",
  "1_8_elimination_round": "1/8 elimination round",
  "1_4_elimination_round": "1/4 elimination round",

  Enter_the_topic_name_you_want_to_recommend: "Enter the name of the topic you want to recommend",
  Editor_recommended_topic: "Edit the recommended topic",
  The_topic_cannot_be_empty: "The topic cannot be empty",
  hint_631: "Please enter the topic name (within 15 characters)",
  hint_630: "Please enter the topic description (within 100 characters)",
  Successfully_created_topic: "Successfully created topic",
  Recharge: "Recharge",
  Consumption: "Consumption",
  Revenue: "Revenue",
  Order_number_1: "Order number",
  Recharge_amount: "Recharge amount",
  Consumption_type: "Consumption type",
  Consumption_amount: "Consumption amount",
  Revenue_type: "Revenue type",
  Revenue_amount: "Revenue amount",
  Additional_issuance: "Additional issue",
  Uploading: "Uploading...",
  Batch_issuance: "Batch issuance",
  Download_batch_issuance_templates: "Download batch issuance templates",
  Additional_currency_issuance: "Increase currency",
  Increase_experience_value_of_issuance: "Increase experience value",
  Please_enter_user_userid: "Please enter userid",
  Please_enter_the_quantity_of_coins: "Please enter the quantity of gold coins",
  Please_enter_a_note: "Please enter a note",
  VIP_level: "VIP level",
  Additional_issuance_time: "Increase time",
  Are_you_sure_you_want_to_refuse_: "Are you sure you want to refuse?",
  Personal_currency_issuance: "Personal currency issuance",
  VIP_currency_issuance: "VIP currency issuance",
  Total_consumption_in_gold_coins: "Total consumption (gold coins)",
  Total_rechargeyuan: "Total recharge (yuan)",
  Number_of_Rechargers: "Number of Rechargers",
  Recharge_switch: "Recharge switch",
  RMB_amount_yuan_: "RMB amount (yuan)",
  Number_of_gold_coins: "Number of gold coins",
  Edit_recharge_configuration: "Edit recharge configuration",
  Add_recharge_configuration: "Add recharge configuration",
  Recharge_time: "Recharge time",
  Payment_channels: "Payment channels",
  Please_enter_the_order_number_1: "Please enter the order number",
  Payment_time: "Payment time",
  Order_time: "Order time",
  Payment_amount_yuan: "Payment amount (yuan)",
  Balance_inquiry: "Balance inquiry",
  Ordinary_user_currency_balance: "Ordinary user currency balance",
  Details_1: "Details",
  Football_quiz_activity: "Football quiz activity",
  Football_winning_statistics: "Football winning statistics",
  Basketball_quiz_activity: "Basketball quiz activity",
  Basketball_winning_statistics: "Basketball winning statistics",
  Guessing_question_name: "Guess the name of the question",
  Please_enter_the_name_of_the_quiz_question: "Please enter the name of the quiz question",
  Start_time: "Start time",
  Add_a_question: "Add a new question",
  Rule_configuration: "Rule configuration",
  Style_Configuration: "Style configuration",
  Number_of_periods: "Number of periods",
  Reward_base: "Reward base",
  Stop: "Stop",
  End: "End",
  Title: "Question",

  Re_upload: "Re-upload",
  Please_upload_the_video: "Please upload the video",
  Offline: "Offline",
  Square_banner: "Square banner",
  Page: "Page",
  Information_details: "Information details",
  Edit_open_screen_advertisement: "Edit open screen advertisement",
  Please_select_a_resource_type: "Please select resource type",
  Please_select_an_open_screen_advertisement: "Please select open screen advertisement",
  The_uploaded_video_size_cannot_exceed_5MB_: "The uploaded video size cannot exceed 5MB",
  Please_upload_the_correct_video_format: "Please upload the correct video format",
  Add_patches: "Add new patches",
  Display_location: "Display location",
  Please_enter_the_title_of_the_patch_advertisement: "Please enter the patch advertisement title",
  hint_86: "Only jpg/png files can be uploaded, and the image size must be 100X100",
  Edit_patch_ads: "Edit patch ads",
  Add_patch_ads: "Add patch ads",
  Please_enter_the_advertisement_title: "Please enter the ad title",
  Please_select_a_display_location: "Please select the display location",
  Recommended_by_experts_on_the_homepage: "Homepage expert recommendation",
  Live_streaming_page_anchor_recommendation: "Live streaming page anchor recommendation",
  hint_87: "Uploaded images can only be in JPG, PNG or gif format!",
  Pop_up_name: "Pop-up name",
  APP_Advertising: "APP advertising",
  Open_screen_advertising: "Open screen advertising",
  Patch_advertising: "Patch advertising",
  Activity_pop_up: "Activity pop-up",
  hint_611: "Note: All functions of listing operations are open",
  Assistant: "Assistant",
  Homepage_Live: "Homepage Live",
  Live_broadcast_room_announcement: "Live broadcast room announcement",
  Advertising_resource_allocation: "Advertising resource allocation",
  hint_610: "If enabled, request old advertising resource materials",
  hint_88: "Note: During the official review period, some functions are not open",
  APP_Brand_Name_: "APP brand name:",
  Please_enter_the_APP_brand_name: "Please enter the APP brand name",
  Please_enter_contact_customer_service: "Please enter the contact customer service",
  hint_89: "The uploaded image size is incorrect, please upload according to the format requirements",
  Switch_initialization: "Switch initialization",
  Version_configuration: "Version configuration",
  Review_status: "Review status",
  Operation_status: "Operation status",
  AppStore_version_number: "AppStore version number",
  Please_enter_the_version_number: "Please enter the version number",
  Version_status: "Version status",
  Update_instructions: "Update instructions",
  Configure_time: "Configure time",
  Please_enter_the_interface_version_number: "Please enter the interface version number",
  hint_90: "The interface version number is provided by technology and cannot be customized!",
  Please_enter_update_instructions: "Please enter the update instructions",
  Please_select_version_status: "Please select the version status",
  Please_enter_the_AppStore_version_number: "Please enter the AppStore version number",
  Please_enter_the_short_video_title: "Please enter the short video title",
  Edit_Title: "Edit title",
  Short_video_title_: "Short video title:",
  hint_91: "Publish this short video to the mobile terminal. Do you confirm the release? ",
  Crawling_website_sites: "Crawling website sites",
  Statistical_time: "Statistical time",
  UV_data: "uv data",
  Platform: "Platform",
  Add_circle_recommendations: "Add circle recommendations",
  Are_you_sure_you_want_to_pause_: "Are you sure to pause? ",
  Access_date: "Access date",
  Posting_time: "Posting time",
  Poster_nickname: "Poster nickname",
  Interactive_red_envelopes: "Interactive red envelopes",
  Topic_Name: "Topic name",
  Please_enter_the_topic_name: "Please enter the topic name",
  hint_632: "Topic popularity (counted every 10 minutes)",
  Accumulated_number_of_related_posts: "Accumulated number of related posts",
  Save_and_publish: "Save and publish",
  Recommend: "Recommend",
  Add_recommended_topics: "Add recommended topics",
  Number_of_participants_in_the_topic: "Number of participants in the topic",
  Number_of_topic_views: "Number of views of the topic",
  Topic_release_time: "Topic release time",
  Topic_Top_Time: "Topic top time",
  Choose_a_topic: "Choose a topic",

  Please_enter_the_music_name: "Please enter the name of the music",
  Author_name: "Author name",
  Please_enter_the_author_name: "Please enter the author name",
  Size: "Size",
  Playback_count: "Playback times",
  Trial_listening: "Trial listening",
  Please_enter_the_song_name: "Please enter the song name",
  Song_title: "Song name",
  Upload_music_files: "Upload music files",
  Only_supports_MP3_format: "Only supports MP3 format",
  hint_80: "Aspect ratio 60*60",
  Please_upload_music_files: "Please upload music files",
  Edit_Music: "Edit music",
  Add_music: "Add music",
  Only_MP3_format_files_can_be_uploaded: "Only mp3 format files can be uploaded",
  The_size_cannot_exceed_20MB_: "The size cannot exceed 20MB!",
  hint_635: "Are you sure you want to put this music on the shelves?",
  hint_636: "Are you sure you want to remove this music?",
  Add_Recommendation: "Add a new recommendation",
  Please_set_the_sorting_number_1_999_: "Please set the sorting number (1-999)",
  Editor_recommendation: "Edit recommendation",
  UserId_cannot_be_empty: "UserId cannot be empty",
  Shutdown_time: "Shutdown time",
  Peak_heat_value: "Peak value of heat",
  Edit_Page: "Edit page",
  Add_Page: "Add page",
  Upload_at_least_one_resource_image: "Upload at least one resource image",
  hint_81: "After publishing, the link will be published online. Are you sure to publish this page?",
  hint_82: "After delisting, the link will not be accessible online. Are you sure to remove this page?",
  Select_Cover: "Select cover",
  hint_633: "Note: The effective time cannot overlap!!!",
  hint_83: "There is incomplete information, please fill it out and submit it!! ",
  hint_634: "The uploaded image size cannot exceed 500K!",
  Recommended_image: "Recommended image",
  Display_sorting: "Display sorting",
  Edit_Secondary_Classification: "Edit secondary classification",
  Classification: "Classification",
  Add_Category: "Add classification",
  Please_enter_the_activity_name: "Please enter the activity name",
  Activity_status: "Activity status",
  Image: "Image",
  Configuration_date: "Configuration date",
  Please_enter_the_button_link: "Please enter the button link",
  Button_link: "Button link",
  Button_Text: "Button text",
  hint_607: "Please enter the button text, up to 4 characters",
  Button_color: "Button color",
  hint_608: "Please enter the button color, such as #CCCCCC",
  Edit_Configuration: "Edit configuration",
  New_configuration: "Add new configuration",
  hint_609: "Please enter the button text",
  Please_enter_the_button_color: "Please enter the button color",
  hint_84: "Are you sure you want to put this template on the shelves? The page template will take effect after it is put on the shelves",
  hint_85: "Are you sure you want to remove this template? The page button will be invalid after it is removed",
  APP_end: "APP end",
  H5_end: "H5 end",
  AK_APP_end: "AK-APP end",
  AK_PC_end: "AK-PC end",
  AK_H5_end: "AK-H5 end",
  Anchor_details: "Anchor details",
  Recommended_number_of_anchors: "Recommended anchor number",
  Anchor_: "Anchor {num}",
  Please_enter_the_anchor_ID: "Please enter the anchor ID",
  Please_fill_in_the_resource_slot_name: "Please fill in the resource slot name",
  Please_fill_in_at_least_one_anchor_information: "Please fill in at least one anchor information",
  Secondary_classification_valid_all: "Secondary classification (valid/all)",
  SM_static_resources: "SM static resources",
  AK_static_resources: "AK static resources",
  More_Jumps: "More jumps",
  Editorial_Expert_Recommendation_Group: "Edit expert recommendation group",
  Add_expert_recommendation_group: "Add expert recommendation group",
  Near_0_in_0: "Near 0 in 0",
  Highest: "Highest",
  Lian_Hong: "Lianhong",
  Recently: "Recently",
  Permissions: "Permissions",
  Are_you_sure_you_want_to_turn_it_on_: "Are you sure to turn it on?",
  Are_you_sure_you_want_to_close_it_: "Are you sure to close it?",
  Live_broadcast_room_name: "Live broadcast room name",
  Please_select_sorting: "Please select sorting",
  Edit_popular_recommendations: "Edit popular recommendations",
  Add_popular_recommendations: "Add popular recommendations",
  Advertising_name: "Advertising name",
  Resource_type: "Resource type",
  Video_upload: "Video upload",
  Your_browser_does_not_support_video_playback: "Your browser does not support video playback",

  Add_an_open_screen_advertisement: "Add an open screen advertisement",
  hint_641: "Please ensure that the video format is MP4 and does not exceed 5M",
  hint_77: "Only jpg/png/gif files can be uploaded, and the image size must be 750X1624",
  Please_enter_the_link_address: "Please enter the link address",
  Please_enter_the_live_broadcast_room_ID: "Please enter the live broadcast room ID",
  Please_enter_the_information_ID: "Please enter the information ID",
  Please_enter_the_chat_room_ID: "Please enter the chat room ID",
  The_input_information_format_is_incorrect: "The input information format is incorrect",
  Chat_room_ID: "Chat room ID",
  Brocade_Bag_ID: "Bag ID",
  hint_640: "Only jpg/png/gif files can be uploaded, the recommended size is",
  Please_enter_the_sorting_number: "Please enter the sort number",
  Sort: "Sort",
  The_length_cannot_exceed_20: "The length cannot exceed 20",
  hint_78: "After going offline, this pop-up window will be removed from the mobile terminal. Are you sure to take this pop-up window offline?",
  hint_639: "The uploaded image size cannot exceed 1MB",
  Page_Name: "Page name",
  Please_enter_the_page_name: "Please enter the page name",
  Parameters: "Parameters",
  PC_Link: "PC link",
  H5_Link: "H5 link",
  PC_Preview: "PC preview",
  H5_Preview: "H5 preview",
  PC_Resource_Map: "PC resource map",
  H5_Resource_Map: "H5 resource map",
  Please_enter_parameters: "Please enter parameters",
  Background_name: "Background name",
  Background_image: "Background image",
  Set_background_name: "Set background name",
  Set_sorting: "Set sorting",
  Modify_background_image: "Modify background image",
  Add_background_image: "Add background image",
  Please_fill_in_the_background_name: "Please fill in the background name",
  Please_fill_in_the_sorting: "Please fill in the sorting",
  Successfully_taken_down: "Successfully taken down",
  Successfully_listed: "Successfully listed",
  Effective: "Effective",
  Invalid: "Invalid",
  hint_606: "Number of channels launched (counted every 10 minutes)",
  Go_live: "Go online",
  hint_605: "Please set the sorting number (1-999)",
  Please_enter_the_tab_name: "Please enter the tab name",
  hint_79: "Only jpg/png files can be uploaded, and the image size must be 670*376",
  Please_set_the_status: "Please set the status",
  Are_you_sure_you_want_to_go_online_: "Are you sure you want to go online",
  Are_you_sure_you_want_to_go_offline_: "Are you sure you want to go offline?",
  Anchor_userid: "Anchor userid",
  Chat_room_title: "Chat room title",
  Cut_off_flow: "Cut flow",
  hint_638: "Are you sure you want to cut off this chat room?",
  Upload_time: "Upload time",
  Cover_type: "Cover type",
  Batch_pass: "Batch pass",
  Batch_rejection: "Batch rejection",
  Voice_chat_room: "Voice chat room",
  Reject: "Reject",
  Operated: "Operated",
  Passed: "Passed",
  Live_streaming_3: "Live broadcast",
  hint_637: "Are you sure you want to delete the cover?",
  Music_name: "Music name",

  hint_73: "Are you sure you want to remove it? After it is removed, this short video will not appear on the mobile terminal?",
  Bullet_Screen_Library: "Bullet Screen Library",
  Add_bullet_library: "Add Bullet Screen Library",
  Batch_Import: "Batch Import",
  Add_bullet_comments: "Add Bullet Screen",
  Bullet_screen_content: "Bullet Screen Content",
  Recent_Editors: "Recent Editors",
  Import_data: "Import Data",
  Edit_bullet_screen: "Edit Bullet Screen",
  hint_649: "Supports adding multiple bullet screens, multiple bullet screens are separated by English commas, and a maximum of 1,000 bullet screens can be added at a time.",
  Label_One: "Label One",
  Label_2: "Label Two",
  Label_Three: "Label Three",
  Label_Four: "Label Four",
  Label_Five: "Label Five",
  Please_enter_the_bullet_content: "Please enter the bullet screen content",
  hint_648: "The upload size cannot exceed 10MB!",
  Successfully_added_barrage: "Added barrage successfully",
  Update_barrage_successful: "Updated barrage successfully",
  hint_647: "Uploaded files can only be in xls format!",
  Import_failed: "Import failed",
  Import_successful: "Import successful",
  hint_646: "Are you sure you want to delete this barrage?",
  Number_of_fans: "Number of fans",
  Live_broadcast_time: "Live broadcast time",
  Duration_minutes_: "Duration (minutes)",
  Number_of_gift_givers: "Number of gift givers (pieces)",
  Number_of_gifts_given: "Number of gifts (pieces)",
  Live_room_revenue_feather_: "Live room revenue (feather)",
  Guilds: "Guild",
  Settlable_income_yuan_: "Settlable income (yuan)",
  Reward_and_punishment_income_yuan: "Reward and punishment income (yuan)",
  Total_revenue_yuan_: "Total income (yuan)",
  Adjustment_type_: "Adjustment type:",
  hint_645: "Reason for reward and punishment:",
  Settlement_bill_review: "Settlement bill review",
  Originating_Reviewer: "Originating reviewer",
  Operations_Manager_: "Operation manager",
  Reissue: "Reissue",
  Through: "Through",
  Please_enter_the_amount: "Please enter the amount",
  Please_enter_the_reason: "Please enter the reason",
  Guild_name: "Guild name",
  Please_enter_the_guild_name: "Please enter the guild name",
  Guild_ID: "Guild ID",
  Receiving_account_name: "Receiving account name",
  Opening_Bank: "Opening bank",
  Bank_card_number: "Bank card number",
  Account_opening_address: "Opening address",
  Number_of_anchors: "Number of anchors",
  Please_fill_in_your_phone_number: "Please fill in your phone number",
  Please_fill_in_your_bank_card_number: "Please fill in your bank card number",
  hint_75: "After enabling, you can assign this guild to the anchor. Are you sure to enable this guild?",
  Resource_location: "Resource location",
  Is_it_effective_: "Is it effective?",
  Jump_position: "Jump position",
  Please_enter_the_jump_location: "Please enter the jump position",
  Please_select_a_cover: "Please select the cover",
  Please_select_a_jump_type: "Please select the jump type",
  Please_select_a_resource_location: "Please select the resource location",
  hint_76: "Note: The greater the weight, the closer the current ad position is",
  Is_the_browser_open_: "Is the browser open?",
  hint_644: "Are you sure you want to enable this ad?",
  hint_643: "Are you sure you want to disable this ad?",
  hint_642: "Are you sure you want to remove this ad slot?",
  Expert_type: "Expert type",
  Import_experts: "Import experts",
  Introduction: "Introduction",
  Hit_rate: "Hit rate",
  Recent_status: "Recent status",
  The_highest_consecutive_red: "The highest consecutive win",
  hint_604: "Recent consecutive win",
  Number_of_proposals: "Number of proposals",
  Number_of_buyers: "Number of buyers",
  Accumulated_total_revenue: "Accumulated total revenue",
  Settlable_income: "Settlable revenue",
  Add_Expert: "Add expert",
  User_userid_cannot_be_empty: "User userid cannot be empty",
  Please_enter_the_league_and_team_name: "Please enter the league and team name",
  Home_team_vs_away_team: "Home team VS away team",
  League: "League",
  Choose_a_competition: "Select a competition",
  Event_information: "Event information",
  Select_recommendation: "Select recommendation",
  Yazhi: "Asian Handicap",
  The_main_winner: "Main Win",
  Customer_win: "Guest Win",
  Small_and_large_balls: "Big and Small Balls",
  Greater_than: "Greater Than",
  ball: "Ball",
  European_Compensation: "European Compensation",
  A_draw: "Draw",
  Wanbo: "Wanbo",
  Le_Dong: "Le Dong",
  Macau: "Macau",
  crown: "Crown",
  hint_655: "Please select the play method first",
  Add_recommendation_group: "Add Recommended Group",
  Recommended_group_name: "Recommended Group Name",
  Circle_details: "Circle Details",
  Number_of_recommended_circles: "Number of Recommended Circles",
  Resource_bit_name: "Resource Bit Name",
  Expert_details: "Expert Details",
  Number_of_recommended_experts: "Number of Recommended Experts",
  Pause: "Pause",
  Add_anchors: "Add anchor",
  X_connected_red: "X consecutive red",
  Near_X_in_X: "Near X in X",
  Return_rate: "Return rate",
  hint_653: "Please fill in at least one complete anchor information",
  hint_654: "Are you sure you want to recommend this group?",
  hint_652: "Are you sure you want to suspend this recommended group?",
  Up_to_20_can_be_configured: "Up to 20 can be configured",
  Edit_selling_price: "Edit selling price",
  First_gear: "First gear",
  Second_gear: "Second gear",
  Third_gear: "Third gear",
  Release_Brocade_Bag: "Release Brocade Bag",
  Brocade_Bag_Type_: "Brocade Bag Type:",
  Football_recommendations: "Football Recommendations",
  Basketball_recommendations: "Basketball Recommendations",
  hint_651: "Please select the event to set the recommendation options",
  Please_enter_the_publisher_account: "Please enter the publisher account",
  Please_enter_the_recommendation_reason: "Please enter the recommendation reason",
  Please_set_the_publishing_time: "Please set the release time",
  hint_650: "The game has ended and you need to select the release time",
  Expert_management: "Expert Management",
  Popular_experts: "Popular Experts",
  Hit: "Hit",
  Returned: "Returned",
  To_be_returned: "To be returned",
  On_sale: "On sale",
  Cut_off_orders: "Cut off orders",
  Order_status: "Order status",
  Purchase_time: "Purchase time",
  Please_enter_the_competition: "Please enter the competition",
  Competition: "Competition",
  Publisher: "Publisher",
  Please_enter_the_publisher: "Please enter the publisher",
  Purchase_successful: "Purchase successful",


  Bind_characters: "Bind character",
  Latest_reset_time: "Latest reset time",
  Please_select_a_role: "Please select a role",
  Input_cannot_be_empty: "Input cannot be empty",
  hint_6601: "Special characters and numbers cannot be entered",
  Edit_Users: "Edit user",
  hint_659: "Are you sure you want to deactivate this account?",
  Account_deactivated: "Account deactivated",
  hint_658: "Are you sure you want to reset this account password?",
  Reset_successful: "Reset successful",
  The_new_password_is: "New password is",
  Gift_Name: "Gift name",
  Please_enter_the_gift_name: "Please enter the gift name",
  Gift_category: "Gift category",
  VIP_gift: "VIP gift",
  Add_gift: "Add gift",
  Gift_unit_price_in_gold_coins_: "Gift unit price (gold coins)",
  Banner_gift: "Banner gift",
  Full_screen_gift: "Full screen gift",
  Gift_icon: "Gift icon",
  Size_does_not_exceed_1MB_: "Size does not exceed 1MB!",
  Enable_floating_screen: "Enable floating screen",
  Floating_screen_category: "Floating screen category",
  hint_657: "Please select floating screen category",
  Please_select_VIP_level: "Please select VIP level",
  hint_656: "VIP users of this level and above can use it",
  hint_601: "You can enter up to 4 Chinese characters or 8 English letters or 8 numbers",
  Please_enter_the_price: "Please enter the price",
  hint_602: "The unit price of the gift cannot be greater than 9999999 or less than 0",
  Please_upload_the_gift_icon: "Please upload the gift icon",
  Please_upload_the_playback_effect: "Please upload the playback effect",
  Please_upload_the_preview_effect: "Please upload the preview effect",
  Edit_Gift: "Edit gift",
  Please_choose_a_gift_type: "Please select the gift type",
  Please_select_the_floating_screen_type: "Please select the floating screen type",
  Please_select_whether_combo_is_possible: "Please select whether combo is possible",
  Only_SVGA_files_can_be_uploaded_: "Only SVGA files can be uploaded!",
  Only_GIF_files_can_be_uploaded: "Only GIF files can be uploaded",
  hint_66: "If you take the gift offline, the gift will be offline from the gift warehouse, that is, the gift will not appear in the configured gift strategy. Are you sure you want to take it offline? ",
  Successfully_taken_offline: "Offline successfully",
  hint_67: "If the gift is online, it will be re-online to the gift warehouse, that is, the gift will reappear in the configured gift strategy. Are you sure to go online?",
  Gift_data_statistics: "Gift data statistics",
  User_gift_details: "User gift details",
  Gift_List: "Gift list",
  Distribution_scope: "Distribution scope",
  Effective_time: "Effective time",
  Last_modified_time: "Last modification time",
  Last_Editor: "Last editor",
  hint_68: "Please enter the gift ID. Multiple gifts are separated by English commas. The input order is the order of gift display on the front end",
  Sending_gifts: "Sending gifts",
  hint_72: "Deleting the gift strategy will cause the strategy to be deleted. Are you sure to delete it? ? ",
  hint_70: "Offline the gift strategy will cause the gifts in the strategy room to be offline from the front end. Are you sure to go offline? ",
  hint_71: "If you launch this gift strategy, the gifts in the room under this strategy will be launched from the front end. Are you sure you want to launch it?",


  Please_fill_in_the_channel_template_ID: "Please fill in the channel template ID",
  Message_type: "Message type",
  New_announcement: "New announcement",
  Official_announcement_title: "Official announcement title",
  Unpublish: "Unpublish",
  Jump_type: "Jump type",
  Push_time: "Push time",
  Please_enter_the_bag_ID: "Please enter the bag ID",
  Decimal_cannot_be_entered: "Cannot enter a decimal",
  Please_enter_the_H5_link: "Please enter the H5 link",
  Push_details: "Push details",
  Edit_push: "Edit push",
  Add_push: "Add push",
  Push_order_page: "Push single page",
  Please_select_the_push_time: "Please select push time",
  Please_select_the_effective_time: "Please select effective time",
  hint_660: "Are you sure you want to cancel this push task?",
  Add_menu: "Add a new menu",
  catalogue: "Catalog",
  Menu: "Menu",
  Menu_icons: "Menu icons",
  Menu_Title: "Menu title",
  Routing_address: "Routing address",
  Component_Name: "Component name",
  Component_path: "Component path",
  Whether_to_hide: "Whether to hide",
  Superior_categories: "Superior categories",
  Select_superior_category: "Select a superior category",
  Menu_Name: "Menu name",
  Permission_identification: "Permission identification",
  Edit_menu: "Edit menu",
  Please_enter_the_menu_title: "Please enter the menu title",
  Please_enter_the_routing_address: "Please enter the routing address",
  Please_enter_the_component_name: "Please enter the component name",
  Please_enter_the_component_path: "Please enter the component path",
  Please_select_the_superior_category: "Please select the superior category",
  Top_level_categories: "Top-level categories",
  hint_661: "Are you sure you want to delete this menu?",
  Please_enter_the_role_name: "Please enter the role name",
  Add_new_role: "Add a new role",
  Role_ID: "Role ID",
  Creator: "Creator",
  Authorization: "Authorization",
  Edit_Role: "Edit role",
  Audit_scenario: "Audit scenario",
  Please_enter_the_review_scenario: "Please enter the audit scenario",
  Review_page: "Review page",
  Edit_audit_scenario_account: "Edit audit scenario account",
  Operations_Manager_Account: "Operations manager account",
  Please_enter_the_review_account: "Please enter the review account",
  Please_enter_your_account: "Please enter the account",
  New_users: "Add a new user",


  Create_Announcement: "Create Announcement",
  Announcement_type: "Announcement Type",
  Image_and_text_links: "Image and text links",
  Picture_and_text_messages: "Image and text messages",
  Activity_link: "Activity Link",
  Announcement_Title: "Announcement Title",
  Cover_image: "Cover Image",
  Whether_to_push: "Whether to push",
  Select_content: "Select Content",
  Activity_Title: "Activity Title",
  Event_Introduction: "Activity Introduction",
  Please_select_the_announcement_type: "Please select the announcement type",
  Please_select_the_publishing_time: "Please select the publishing time",
  Please_enter_the_announcement_title: "Please enter the announcement title",
  Please_enter_the_activity_title: "Please enter the activity title",
  Please_enter_the_announcement_content: "Please enter the announcement content",
  Please_fill_in_the_activity_introduction: "Please fill in the activity introduction",
  Please_enter_the_text_message_content: "Please enter the text message content",
  Send_to: "Send to",
  Upload_data: "Upload data",
  Download_List_Excel_Template: "Download list Excel template",
  Receive_user_list: "Receive user list",
  All_platform_users: "All platform users",
  Not_sent: "Not sent",
  Sent: "Sent",
  Cancel_sending: "Cancel send",
  Specify_user: "Specify user",
  Please_enter_the_theme: "Please enter the theme",
  Multiple_separated_by_commas: "Multiple separated by commas",
  Template_ID: "Template ID",
  Instant_sending: "Instant send",
  Please_select_template_ID: "Please select template ID",
  Are_you_sure_to_send_to_all_users_: "Are you sure to send to all users? ",
  Sending_successful: "Sending Successfully",
  Channel_template_ID: "Channel Template ID",
  Please_enter_the_channel_template_ID: "Please enter the channel template ID",
  Template_content: "Template content",
  Please_enter_template_content: "Please enter the template content",
  Please_select_a_channel: "Please select a channel",
  Please_enter_your_signature: "Please enter your signature",
  Alibaba_Cloud: "Alibaba Cloud",
  Notification: "Notification",
  Marketing: "Marketing",
  Verification_code: "Verification Code",
  First_select_the_SMS_type: "First select the SMS type",


  Initial_heating_value_: "Initial heating value:",
  Please_enter_the_heat_value: "Please enter the heating value",
  Please_fill_in_the_heat_value: "Please fill in the heating value",
  Please_enter_the_anchor_UID: "Please enter the anchor UID",
  Live_streaming_services: "Live streaming services",
  Batch_editing: "Batch editing",
  All_anchors: "All anchors",
  Platform_anchor_stream: "Platform anchor stream",
  Event_clearance: "Event clearing stream",
  Anchor_event_flow: "Anchor event stream",
  Esports_Entertainment_Stream: "Esports entertainment stream",
  Live_streaming_2: "Live broadcast",
  Field: "Field",
  hint_665: "Are you sure you want to take this live broadcast room off the air?",
  hint_664: "Are you sure you want to ban this live broadcast room?",
  This_PV: "This field PV",
  UV_in_this_field: "UV of this game",
  Peak_value: "Peak value",
  Accumulated_viewing_time_minutes_: "Accumulated viewing time (minutes)",
  Total_number_of_followers: "Total number of followers",
  Number_of_followers_in_this_session: "Number of followers in this game",
  The_number_of_levels_taken_in_this_game: "Number of followers taken in this game",
  Net_increase_in_levels_in_this_game: "Net increase in levels in this game",
  Live_broadcast_session_data_of_anchors: "Anchor live broadcast data",
  Forbidden_List: "Forbidden list",
  Team: "Team",
  Please_enter_the_team: "Please enter the team",
  Push_order_status: "Push order status",
  hint_306: "Are you sure you want to hide this push order? It will not be visible on the front end after hiding",
  hint_305: "Are you sure you want to show this push order? It will be visible on the front end after showing",
  Please_select_the_correction_result: "Please select the correction result",
  Reporting_time: "Reporting time",
  Whistleblower_userid: "Reporter userid",
  Enter_the_whistleblower_userid: "Enter reporter userid",
  Enter_room_number: "Enter room number",
  Reply_or_not: "Do you want to reply",
  Reason_for_reporting: "Reason for reporting",
  Reply_content: "Reply content",
  hint_663: "Please enter the reply content (within 100 words)",
  The_reply_content_cannot_be_empty: "Reply content cannot be empty",
  Homepage_Configuration: "Homepage configuration",
  Level_animation_switch: "Level animation switch",
  Cat_coin_animation_switch: "Cat coin animation switch",
  Enter_animation_switch_: "Entry animation switch:",
  APP_membership_background: "APP member background",
  Membership_Benefits_Background: "Membership Benefits Background",
  APP_H5_Membership_Benefits_Background: "APP/H5 Membership Benefits Background",
  Background_of_PC_Membership_Benefits: "PC Membership Benefits Background",
  hint_409: "Note: The size is 926x126",
  VIP_discount_configuration: "vip discount configuration",
  Please_enter_VIP: "Please enter VIP",
  hint_402: "Please enter vip discount (for example: 0.9)",
  hint_403: "Please enter vip discount name (for example: 10% off)",
  Edit_Channel: "Edit Channel",
  Add_a_new_channel: "Add a new channel",
  Channel_avatar: "Channel Avatar",
  hint_401: "Size: 114*114 Only jpg/png files can be uploaded",
  Channel_Cover: "Channel Cover",
  hint_405: "Size: 522*294 Only jpg/png files can be uploaded",
  hint_406: "Tip: Automatically capture the streaming screen, and use the default cover if it fails",
  hint_407: "Are you sure you want to restore the live broadcast permission of this channel?",
  hint_408: "Are you sure you want to ban this channel?",
  Schedule_data: "Schedule data",
  Edit_Announcement: "Edit announcement",


  The_ID_photo_is_blurry: "The ID photo is blurry",
  The_ID_number_does_not_match_the_ID_photo: "The ID number does not match the ID photo",
  My_ID_card_does_not_match_me: "The ID card does not match me",
  ID_card_number_is_blocked: "The ID number is blocked",
  Facial_occlusion: "Face occlusion",
  Room: "Room",
  Please_select_the_reason: "Please select the reason",
  hint_669: "Please enter the name or link of the announcement to be replaced",
  Are_you_sure_you_want_to_pass_the_review_: "Are you sure you want to pass the review?",
  hint_301: "Are you sure you want to restore the live broadcast rights of this anchor?",
  Are_you_sure_you_want_to_ban_this_anchor_: "Are you sure you want to ban this anchor?",
  Main_account_UID: "Main account UID",
  Please_enter_the_main_account_UID: "Please enter the main account UID",
  Merge_account_UID: "Merge account UID",
  Please_enter_the_merged_account_UID: "Please enter the merged account UID",
  Add_Merge: "Add a new merge",
  Master_account_nickname: "Master account nickname",
  Merge_account_nicknames: "Merge account nicknames",
  Merge_time: "Merge time",
  hint_64: "After merging accounts, the merged anchor account will use the main account for live broadcast",
  Merge_accounts: "Merge account",
  Refresh: "Refresh",
  hint_668: "Note: Online data is slightly delayed",
  Note: "Note",
  Basic_information_1: "Basic information",
  Anchor_Information: "Anchor information",
  Real_time_live_streaming_data: "Real-time live broadcast data",
  hint_667: "Current stream information is unavailable!",
  Anchor_Management: "Anchor Management",
  Merge_anchor_accounts: "Anchor Account Merge",
  Identify_the_main_switch: "Identify the Main Switch",
  The_current_status_is: "The current status is",
  There_is_currently_no_data_available: "No data",
  Jian_Huang: "Jian Huang",
  Fear_prevention: "Jian Fear",
  Jianzheng: "Jian Zheng",
  hint_303: "Are you sure you want to interrupt this live streaming? ",
  Thermal_value_coefficient: "Thermal value coefficient",
  Heat_value_base: "Thermal value base",
  Modified_by: "Modifier",
  Latest_modification_time: "Latest modification time",
  Heat_value_setting: "Thermal value setting",
  Role_Name: "Role name",
  Whether_to_enable: "Whether to enable",
  Live_broadcast_room_type: "Live broadcast room type",
  Initial_heat_value: "Initial thermal value",
  Last_editing_time: "Last editing time",
  Edit_heat_value: "Edit thermal value",


  hint_672: "The maximum number of online users in the current live broadcast (deduplication)",
  hint_63: "The statistics include non-logged-in users, and non-logged-in users are not deduplicated.",
  Field_Description: "Field Description",
  Anchor_UID: "Anchor UID",
  Live_Channel: "Live Channel",
  Start_time_1: "Start Time",
  Downtime: "Down Time",
  Opening_duration_minutes_: "Opening Duration (Minutes)",
  Accumulated_views: "Accumulated Views",
  Accumulated_number_of_online_users: "Accumulated Number of Online Users",
  Average_viewing_time_minutes_: "Average Viewing Time (Minutes)",
  The_entire_live_broadcast_data: "Entire Live Broadcast Data",
  Edit_data: "Edit Data",
  Add_announcement_link: "Add Announcement Link",
  Announcement_link: "Announcement Link",
  Please_enter_the_display_link: "Please enter the display link",
  Recommended_display: "Recommended display",
  Browser_Open: "Browser opens",
  Display_end: "Display end",
  Mobile_end: "Mobile end",
  PC_end: "PC end",
  Application_time: "Application time",
  Global_announcement_adjustment: "Global announcement adjustment",
  Sports_type: "Sports type",
  On_the_front_of_the_ID_card: "Front of the ID card",
  On_the_back_of_the_ID_card: "Back of the ID card",
  Holding_an_ID_card: "Holding an ID card",
  Reason_for_rejection: "Reason for rejection",
  Lifting_the_ban: "Lifting the ban",
  Refused_to_pass: "Refused to pass",
  Please_enter_the_anchor_name: "Please enter the anchor name",
  ID_number: "ID number",
  Front_photo_of_ID_card: "Front photo of ID card",
  Back_photo_of_ID_card: "Back photo of ID card",
  Housing_Management_Uid: "Housing Management Uid",
  Please_enter_the_housing_management_Uid: "Please enter the Housing Management Uid",
  Housing_management_nickname: "Housing Management Nickname",
  hint_671: "Please enter the Housing Management Nickname",
  Add_property_management: "Add Housing Management",
  Cancel_housing_management: "Cancel Housing Management",
  Please_enter_your_name: "Please enter your name",
  hint_670: "Please enter your name in Chinese characters",
  Approved: "Approved",
  Rejected_for_review: "Rejected for review",
  Prohibited: "Banned",

  Results: "Results",
  Number_of_bets: "Number of bettors",
  Anchor_sharing_3: "Anchor share (3%)",
  Platform_share_2: "Platform share (2%)",
  The_anchor_prophecy: "Anchor prophecy",
  The_prophecy_of_the_whole_nation: "Whole nation prophecy",
  Login_time: "Login time",
  Online_duration: "Online duration",
  Inviters_nickname: "Inviter nickname",
  Invitee_UID_: "Invitee UID:",
  Invitation_time: "Invitation time",
  Inviter_payment_method: "Inviter payment method",
  Alipay: "Alipay",
  WeChat_account: "WeChat ID",
  QQ_account: "QQ account",
  Inviting_person_rewards: "Inviter rewards",
  Distributed: "Distributed",
  Not_passed: "Not passed",
  Accumulated: "Accumulated",
  Invitee_nickname: "Invitee nickname",
  Invitee_rewards: "Invitee rewards",
  Select_distribution_type: "Select distribution type",
  Please_enter_a_rule_description: "Please enter a rule description",
  Are_you_sure_to_cancel_the_distribution_: "Are you sure to cancel the distribution?",
  APP_user_login_statistics: "APP user login statistics",
  hint_676: "Inviter rewards cannot be less than 0",
  hint_677: "Inviter rewards cannot be greater than 99999",
  hint_675: "Invitee rewards cannot be less than 0",
  hint_678: "Invitee rewards cannot be greater than 99999",
  Room_type: "Room type",
  The_ban_was_successful: "Banned successfully",
  hint_62: "After deletion, the message will not be displayed in the live broadcast room/chat room on the App, PC, and H5 sides. Are you sure to hide it?",
  Please_enter_the_user_UID: "Please enter the user UID",
  Prohibition_time: "Forbidden time",
  Forbidden_live_broadcast_room: "Forbidden live broadcast room",
  Prohibition_type: "Forbidden type",
  Prohibition_reasons: "Forbidden reason",
  Operator_UID: "Operator UID",
  Successfully_unblocked: "Successful unblocking",
  Opening_duration: "Opening duration",
  The_duration_of_the_current_live_broadcast: "The duration of the current live broadcast",
  Current_number_of_views: "Current number of views",
  hint_674: "The number of views of the current live broadcast (deduplication)",
  Current_number_of_online_users: "The current number of online users",
  hint_673: "The number of online users of the current live broadcast (deduplication)",
  The_highest_number_of_online_users: "The highest number of online users",


  Time: "Time",
  Anchor_userID: "Anchor userID",
  Please_enter_the_anchor_userID: "Please enter anchor userID",
  Betting_amount: "Betting amount",
  Prophecy_betting_list: "Prophecy betting list",
  Please_enter_the_name_of_the_main_team: "Please enter the name of the main team",
  Please_enter_the_name_of_the_visiting_team: "Please enter the name of the visiting team",
  Please_enter_the_status: "Please enter the status",
  Team_PK_ID: "Team PK ID",
  The_winner: "Winner",
  Number_of_gift_givers_1: "Number of gift givers",
  Total_value_in_gold_coins: "Total value (gold coins)",
  The_losing_side: "Losing side",
  Binding_events: "Bound events",
  Please_enter_the_binding_event: "Please enter the binding event",
  Bind_anchor: "Bind anchor",
  Please_enter_the_bound_anchor: "Please enter the bound anchor",
  Activity_Strategy_Name: "Activity strategy name",
  hint_682: "Please enter the activity strategy name",
  Red_envelope_rain_claim_details: "Red envelope rain claim details",
  Effective_anchor_room_details: "Effective anchor room details",
  To_be_published: "To be published",
  Published: "Published",
  Canceled: "Cancelled",
  hint_681: "Are you sure to cancel the publication",
  Main_switch: "Main switch",
  Policy_Name: "Policy name",
  Please_enter_a_policy_name: "Please enter the policy name",
  Add_Policy: "Add policy",
  Red_envelope_rain_storage: "Red envelope rain storage",
  Red_envelope_rain_type: "Red envelope rain type",
  Event_red_envelope_rain: "Event Red Envelope Rain",
  Live_broadcast_room_chat_room: "Live Broadcast Room/Chat Room",
  Event_type: "Event Type",
  Distribute_users: "Distribute Users",
  hint_60: "Please enter the user UID. Multiple UIDs are separated by English commas",
  Please_select_the_type_of_red_envelope_rain: "Please select the type of red envelope rain",
  Please_select_an_event: "Please select an event",
  Please_select_the_red_envelope_rain_storage: "Please select the red envelope rain storage",
  Edit_Policy: "Edit Strategy",
  Strategy_details: "Strategy Details",
  hint_61: "Taking this activity strategy offline will cause the activity to be taken offline from the front end. Are you sure you want to take it offline",
  Offline_2: "Offline",
  Live_room_room_number: "Live Room Number",
  hint_680: "Please enter the Live Room Number",
  Configuration_Management: "Configuration Management",
  Prize_configuration: "Prize configuration",
  Prize_Record: "Prize record",
  Anchor_cumulative_share: "Anchor cumulative share",
  Option_A_cumulative_betting: "Option A cumulative betting",
  Option_A: "Option A",
  Option_B: "Option B",
  Option_B_cumulative_betting: "Option B cumulative betting",


  hint_58: "0 means no red envelope rain, this is the upper limit of the maximum number of red envelope rain rounds in a game",
  Wheel: "Round",
  Pieces: "Pieces",
  Task_type: "Task type",
  Reward_Name: "Reward name",
  Task_Title: "Task title",
  Task_icon: "Task icon",
  Task_count: "Number of tasks",
  Application_channels: "Application channels",
  Edit_task: "Edit task",
  Reward_type: "Reward type",
  Reward_quantity: "Number of rewards",
  Please_enter_the_number_of_rewards: "Please enter the number of rewards",
  Please_select_the_reward_type_icon: "Please select the reward type icon",
  Please_select_the_application_channel: "Please select the application channel",
  Task_title_cannot_be_empty: "Task title cannot be empty",
  Task_count_cannot_be_empty: "Number of tasks cannot be empty",
  To_be_effective: "To be effective",
  In_effect: "In effect",
  Daily_tasks: "Daily tasks",
  Novice_Task: "Novice tasks",
  Sign_in_task: "Sign in tasks",
  Attendance_Record: "Sign in records",
  Please_enter_the_task_title: "Please enter the task title",
  Check_in_switch: "Sign in switch",
  No_1: "No. 1",
  Number_of_check_in_days: "Sign in days",
  Reward_details: "Reward details",
  Reward_icon: "Reward icon",
  Edit_check_in: "Edit sign in",
  Lottery: "Lottery",
  Physical_object: "Physical object",
  Please_upload_the_reward_type_icon: "Please upload the reward type icon",
  Sign_in_has_been_closed: "Sign in has been closed",
  Check_in_has_been_opened: "Sign in has been opened",
  Please_fill_in_the_quantity: "Please fill in the quantity",
  Please_enter_the_room_number_for_sending: "Please enter the room number for sending",
  Send_room_number: "Sending room number",
  Sending_time: "Sending time",
  Details_of_receiving_red_envelopes: "Red envelope collection details",
  hint_683: "No red envelope collection details",
  Anchor_room_number: "Anchor room number",
  Please_enter_the_host_room_number: "Please enter the host room number",
  Remove_permissions: "Remove permissions",
  Add_anchor: "Add anchor",

  UID: "UID",
  Please_enter_UID: "Please enter UID",
  Obtain_time: "Obtain time",
  Export: "Export",
  hint_688: "Smashing golden eggs in the live broadcast room-prize record",
  Basic_Settings: "Basic settings",
  Red_envelope_rain_name: "Red envelope rain name",
  Edit_Red_Envelope_Rain_Library: "Edit Red Envelope Rain Library",
  New_Red_Envelope_Rain_Warehouse: "New Red Envelope Rain Library",
  Please_enter_the_name_of_the_red_envelope_rain: "Please enter the name of the red envelope rain",
  Trigger_type: "Trigger type",
  Goal_scoring_events: "Goal event",
  Event_timeline: "Event time point",
  Specify_a_time_point: "Specify time point",
  Maximum_number_of_red_envelope_rain_wheels: "Red envelope rain wheel number limit",
  Please_enter_the_number_of_rounds: "Please enter the number of rounds",
  hint_54: "0 means no red envelopes, this is the upper limit of the maximum number of red envelopes in a game",
  hint_687: "The maximum number of red envelopes (single user per round)",
  hint_55: "0 means no red envelopes, after reaching the number of red envelopes, no more red envelopes will be grabbed",
  Fixed_point_in_time: "Fixed time point",
  Select_date: "Select date",
  Probability_of_winning_a_prize_red_envelope: "Probability of winning a prize red envelope",
  Please_enter_the_probability: "Please enter the probability",
  hint_56: "0 means it is impossible to draw a red envelope, this is the probability of a user drawing a prize red envelope",
  hint_686: "Please enter the upper limit of the number of red envelopes",
  Award_Pool_Settings: "Award Pool Settings",
  Add_prizes: "Add prizes",
  Number_of_individual_red_envelope_rewards: "Number of individual red envelope rewards",
  Please_enter_the_number: "Please enter the number",
  Number_of_red_envelopes: "Red envelope quantity",
  Please_enter_the_quantity: "Please enter the quantity",
  Prize_weight: "Prize weight",
  Please_fill_in_the_fixed_time_point: "Please fill in the fixed time point",
  Please_select_a_time_point_for_the_competition: "Please select the competition time point",
  hint_685: "Only positive integers from 0 to 100 can be configured",
  Please_add_at_least_one_prize: "Please add at least one prize",
  hint_684: "Please fill in the prize information before submitting",
  hint_57: "Incorrect input, the number of individual red envelope rewards and the quantity of red envelopes can only be configured with positive integers from 1 to 99999",
  Add_has_reached_the_limit: "Addition has reached the upper limit",
  Golden_Egg_Switch: "Golden Egg Switch",
  Basic_Information_Configuration: "Basic Information Configuration",
  APP_background_image: "APP background image",
  APP_Title_Image: "APP title image",
  The_total_probability_must_be_10000: "The total probability must be 10000",
  Red_envelope_rain_storage_details: "Red Envelope Rain Storage Details",


  Dynamic: "Dynamic",
  Brocade_Bag: "Tips",
  The_expiration_time_has_not_been_set_yet: "The expiration time has not been set yet",
  Please_select_a_future_time: "Please select a future time",
  Homepage_Content_Management: "Homepage Content Management",
  Top_management_on_homepage: "Homepage Top Management",
  Please_enter_information_keywords: "Please enter information keywords",
  Keywords: "Keywords",
  Batch_publishing: "Batch Publishing",
  Batch_scheduled_release: "Batch Scheduled Release",
  Thumbnails: "Thumbnails",
  Title_1: "Title",
  Crawling_time: "Crawling Time",
  hint_47: "You can choose to schedule the release at any time within 5 minutes or within the next week. You can re-edit and cancel it before the set time",
  Timed_release: "Scheduled Release",
  hint_48: "You cannot modify or cancel the release after it is released. Do you confirm the release?",
  hint_691: "No information has been selected. Please select information before publishing!",
  Please_choose_a_reasonable_publishing_time: "Please choose a reasonable publishing time",
  Edit_information: "Edit information",
  New_information: "Add new information",
  Please_fill_in_the_necessary_title_and_author: "Please fill in the necessary title and author",
  Information_Title: "Information Title",
  Comment_content: "Comment Content",
  hint_49: "After deletion, the comment will not be displayed on the APP or PC. Are you sure to delete it?",
  hint_50: "Are you sure to cancel the release? After cancellation, the information will enter the draft box",
  hint_51: "Are you sure to remove it? After removal, the mobile terminal will not display this information!",
  Cancel_Top_Time: "Cancel top time",
  Publishing_Library: "Publishing Library",
  Top_Library: "Top Library",
  Crawling_website_titles: "Crawling website titles",
  Crawling_website_links: "Crawling website links",
  Crawling_time_period: "Crawling time period",
  Set_crawling_time_period: "Set crawling time period",
  End_time: "End time",
  Select_a_time_range: "Select time range",
  hint_52: "After disabling, the website data will not be crawled. Are you sure to disable?",
  hint_690: "Are you sure you want to enable this configuration?",
  hint_689: "Are you sure you want to delete this information? ",
  Prize_Name: "Prize Name",
  Please_enter_the_name_of_the_prize: "Please enter the name of the prize",
  Prize_type: "Prize Type",
  Query: "Query",
  Add_prizes_1: "Add a new prize",
  Prize_icon: "Prize icon",
  Please_enter_the_number_of_prizes: "Please enter the number of prizes",
  quantity: "Quantity",
  Only_jpg_png_gif_files_can_be_uploaded: "Only jpg/png/gif files can be uploaded",
  Modify: "Modify",
  New: "Add",
  Virtual_currency: "Virtual Currency",
  Experience_value: "Experience Value",
  hint_53: "Uploaded images can only be in JPG, PNG or GIF format!",
  Winning_number: "Winning Number",
  Please_enter_the_winning_number: "Please enter the winning number",

  Please_enter_the_jump_link: "Please enter the jump link",
  To_be_reviewed: "To be reviewed",
  Listed: "Listed",
  Offline_1: "Removed",
  Rejected: "Rejected",
  Deleted: "Deleted",
  hint_692: "Please fill in the complete announcement link information before submitting",
  The_circle_has_disbanded: "The circle has been disbanded",
  Are_you_sure_you_want_to_dissolve_this_circle_: "Are you sure you want to dissolve this circle?",
  Dissolution: "Dissolution",
  hint_44: "Are you sure you want to {status} this dynamic?",
  hint_693: "Are you sure you want to reject this dynamic?",
  Successfully: "Successfully",
  Remove_from_shelves: "Removed",
  Listing: "Listed",
  Circle_dynamics: "Number of circle dynamics",
  Creator_nickname: "Creator nickname",
  Creator_userid: "creator userid",
  In_operation: "In operation",
  View_details: "View details",
  Chat_messages: "Chat messages",
  Edit_anchor_information: "Edit anchor information",
  Review: "Review",
  Anchor_Name: "Anchor name",
  Guild: "Guild",
  Please_select_a_guild: "Please select a guild",
  Split_ratio: "Sharing ratio",
  Please_select_the_sharing_ratio: "Please select the sharing ratio",
  Basic_salary: "Basic salary",
  Please_enter_the_base_salary: "Please enter basic salary",
  Review_details: "Review details",
  Review_details_1: "Details",
  Please_enter_the_ID_number: "Please enter ID number",
  Please_enter_the_host_nickname: "Please enter host nickname",
  hint_43: "Are you sure you want to delete this user from the group owner's authorization list? ",
  Add_channel: "Add channel",
  Default_sorting: "Default sort",
  Channel_ID: "Channel ID",
  Icon: "Icon",
  Page_template: "Page template",
  Tab_name: "tab name",
  Channel_Type: "Channel type",
  hint_46: "Only jpg/png files can be uploaded, the image size must be 120X120",
  Please_enter_a_valid_tab_name_: "Please enter a valid tab name! ",
  Enter_tab_name: "Enter tab name",
  Content_ID: "Content ID",
  Please_enter_ID: "Please enter ID",
  Content_type: "Content type",
  Topped: "Topped",
  Top: "Top",
  Cancel_Topping: "Cancel top",
  Restore_Display: "Restore display",
  Expiration_time: "Expiration time",
  Select_date_and_time: "Select date and time",
  Sealing_the_picture: "Sealing picture",
  real_time_info: "Information",
  Short_video: "Short video",

  hint_694: "Please enter the third-party in-game event ID",
  There_are_streaming_anchors: "There is a push flow anchor",
  There_is_a_push_flow_room: "There is a push flow room",
  Click_on_the_nickname_modification_announcement: "(Click on the nickname modification announcement)",
  Please_select_a_popular_event: "Please select a popular event",
  Edit_successful: "Edit successful",
  E_sports_streaming: "Esports stream",
  Entertainment_stream: "Entertainment stream",
  Create_Popular: "Create popular",
  Official_Clearflow: "Official clear flow",
  Anchor_Qingliu: "Anchor clear flow",
  Score: "Score",
  The_competition_team: "Competition team",
  Create_circles: "Create a circle",
  Limit_1M: "Limit 1M",
  hint_900: "Size 790*215px",
  Associate_Creator: "Associated creator",
  Operation_label: "Operational tags",
  Add_tags: "Add tags",
  Confirm_creation: "Confirm creation",
  Label_: "Tag {num}",
  Please_select_an_image: "Please select an image",
  Please_select_the_associated_creator: "Please select the associated creator",
  Publisher_userid: "Publisher userid",
  Message_content: "Message content",
  Chat_Room: "Chat room",
  hint_45: "After deletion, the message will no longer be displayed in the circle on the App side. Are you sure to delete it?",
  Circle_Introduction: "Circle Introduction",
  Circle_name: "Circle Name",
  Circle_avatar: "Circle Avatar",
  Creator_1: "Creator",
  Number_of_people_in_the_circle: "Number of people in the circle",
  The_maximum_number_of_people_in_the_circle: "The maximum number of people in the circle",
  Circle_Cover: "Circle Cover",
  Circle_dynamics_1: "Circle Dynamics",
  Circle_Announcement: "Circle Announcement",
  Creation_time: "Creation time",
  Dissolve_the_circle: "Dissolve the circle",
  Dissolved: "Dissolved",
  Submission_dynamics: "Submission dynamics",
  User_userid: "User userid",
  Number_of_comments: "Number of comments",
  Like_count: "Number of likes",
  Preview: "Preview",
  Setting_up_circle_announcements: "Set up circle announcements",
  Add_Announcement: "Add announcement",
  Please_enter_the_link_name: "Please enter the link name",
  Display_link: "Display link",
  Jump_link: "Jump link",

  Go_back_to_the_homepage: "Go back to the homepage",
  hint_40: "Please check if the URL you entered is correct. Please click the button below to return to the homepage or send an error report",
  Return_to_homepage: "Go back to the homepage",
  hint_41: "Tip: 1-The weight is a positive integer. The larger the value, the higher the priority; 2-Default front-end display, hidden front-end not displayed",
  Anchor_nickname: "Anchor nickname",
  Please_enter_sorting_weight: "Please enter sorting weight",
  Display: "Show",
  Hidden: "Hide",
  Associated_channels_categories: "Associated channels/categories",
  Anchor_Name_1: "Anchor name",
  Please_enter_the_anchor_name_1: "Please enter anchor name",
  Room_number: "Room number",
  Please_enter_the_room_number: "Please enter room number",
  Source_of_flow: "Source of flow",
  Anchor_profile_picture: "Anchor avatar",
  Source_room_number: "Source room number",
  Live_streaming_categories: "Live streaming categories",
  Live_streaming_title: "Live streaming title",
  Stream_address: "Streaming address",
  Forbidden_broadcasting: "Forbidden broadcasting",
  Live_streaming_1: "Live streaming",
  Ended: "Ended",
  Unlocking: "Unlocking",
  All: "All",
  Douyu: "Douyu",
  Tiger_Fang: "Tiger Fang",
  hint_701: "Are you sure to unblock this anchor?",
  hint_700: "Are you sure to ban this anchor?",
  Competition_time: "Competition time",
  Competition_status: "Competition status",
  Please_enter_keywords_2: "Please enter keywords",
  League_Cup_Tournament: "League/Cup",
  The_home_team: "Home team",
  The_visiting_team: "Visiting team",
  Event_ID: "Event ID",
  Anchor_Qingliu_Live_Room: "Anchor Qingliu Live Room",
  Associated_Channel: "Associated Channel",
  Unknown_status: "Unknown Status",
  Not_started: "Not Started",
  In_progress: "In Progress",
  The_competition_has_been_postponed: "Competition Postponed",
  Competition_cancelled: "Competition Cancelled",
  Direct_room_number: "Direct Push Room Number",
  Progress_time: "Progress Time",
  Live_streaming_status: "Live Status",
  Next_broadcast: "Down Broadcast",
  Binding_successful: "Binding Successfully",
  hint_699: "Are you sure you want to take this live broadcast offline? ",
  Anchor_sorting: "Anchor sorting",
  hint_42: "Tip: 1-The weight is a positive integer, the larger the value, the higher the position; 2-The default front-end display, hidden front-end does not display;",
  Update: "Update",
  Channel_sorting: "Channel sorting",
  Channel_nickname: "Channel nickname",
  Secondary_classification: "Secondary classification",
  Live_streaming_type: "Live broadcast type",
  hint_698: "When associating multiple items, please use English commas to separate them",


  hint_713: "Are you sure you want to delete the configuration?",
  Please_configure_TF_configuration_correctly: "Please configure TF configuration correctly",
  Version_update_reminder: "Version update reminder",
  TF_Package_Management: "TF package management",
  Batch_upload: "Batch upload",
  Posting_updates: "Post updates",
  Account: "Account",
  Content: "Content",
  Upload_images: "Upload images",
  Only_jpg_jpeg_png_files_can_be_uploaded: "Only jpg/jpeg/png files can be uploaded",
  Up_to_9_images_can_be_uploaded: "Upload up to 9 images",
  Click_to_select_file: "Click to select file",
  Only_supports_video_format_files: "Only supports video format files",
  Related_circles: "Related circles",
  Please_select_a_circle: "Please select a circle",
  Related_topics: "Related topics",
  Please_select_a_topic: "Please select a topic",
  Please_upload_the_video_file: "Please upload the video file",
  Please_select_an_account: "Please select an account",
  hint_712: "Uploaded images can only be in JPG or PNG format!",
  hint_707: "Uploaded images cannot exceed 10MB!",
  hint_710: "Only mp4 files can be uploaded!",
  hint_711: "The size cannot exceed 200MB!",
  Please_enter_your_nickname: "Please enter your nickname",
  Joined_the_circle: "Joined the circle",
  Import_time: "Import time",
  Disabled: "Disabled",
  Add_Circle: "Add circle",
  Cannot_be_greater_than_80: "Cannot be greater than 80",
  hint_38: "Are you sure to disable this user? After disabling, you will not be able to use this account to post dynamics?",
  hint_39: "Are you sure to enable this user? After enabling, you can use this account to post dynamics again? ",
  Dynamic_configuration: "Dynamic configuration",
  Publisher_nickname: "Publisher nickname",
  Accumulated_likes: "Accumulated number of likes",
  Accumulated_number_of_comments: "Accumulated number of comments",
  Topic: "Topic",
  Circle: "Circle",
  Release_time: "Release time",
  Remove_this_comment: "Remove this comment",
  Dynamic_permission_configuration: "Dynamic permission configuration",
  Publish_dynamic_user_level_configuration: "Publish dynamic user level configuration",
  Name: "Name",
  Level: "Level",
  hint_703: "This configuration is the level that ordinary users need to reach to publish dynamics",
  Cannot_be_less_than_1: "Cannot be less than 1",
  Please_fill_in_the_user_level: "Please fill in the user level",
  There_are_currently_no_related_posts: "No related posts",
  hint_702: "Are you sure you want to remove this post comment? ",
  Comment_removed_successfully: "Comment removed successfully",
  hint_704: "Are you sure you want to remove this topic?",
  hint_705: "Are you sure you want to put this topic back on the shelf?",
  Return: "Return",
  hint_706: "You do not have permission to go to this page",
  hint_309: "If you are dissatisfied, please contact your leader",
  Or_you_can_go: "Or you can go",


  Homepage_banner_URL: "Homepage banner url",
  Please_enter_the_banner_URL_on_the_homepage: "Please enter the homepage bannerUrl",
  Live_room_banner: "Live broadcast room banner",
  hint_716: "Please enter the live broadcast room bannerUrl",
  Homepage_title: "Homepage-title",
  Please_enter_the_title: "Please enter title",
  Home_page_description_1: "Homepage-description",
  Live_room_title: "Live broadcast room-title",
  Bottom_copyright_text: "Bottom copyright text",
  Please_enter_the_copyright_text_at_the_bottom: "Please enter the bottom copyright text",
  APP_download_URL: "APP download URL",
  Please_enter_the_APP_download_URL: "Please enter the APP download URL",
  hint_715: "The image size must be 750x94",
  H5_Live_Banner: "H5 live banner",
  Please_enter_h5RoomBannerRedirectUrl: "Please enter h5RoomBannerRedirectUrl",
  Please_enter_the_brand_name: "Please enter the brand name",
  Please_enter_the_main_site_redirect: "Please enter the main site jump",
  Please_enter_H5_main_site_redirect: "Please enter the H5 main site jump",
  Please_enter_the_homepage: "Please enter the homepage",
  Please_enter_the_homepage_banner_to_jump_to: "Please enter the homepage banner jump",
  Please_enter_the_homepage_description: "Please enter the homepage description",
  Please_enter_homepage_keywords: "Please enter the homepage keywords",
  Please_enter_the_live_streaming_page: "Please enter the live streaming page",
  hint_714: "Please enter the live streaming page description",
  Please_enter_keywords_for_the_live_streaming_page: "Please enter the live streaming page keywords",
  Please_enter_the_website_copyright_statement: "Please enter the website copyright statement",
  Please_enter_the_app_download_URL: "Please enter the app download url",
  Please_enter_the_site_h5BannerRedirectUrl: "Please enter the site h5BannerRedirectUrl",
  Add_Site: "Add site",
  Edit_Site: "Edit site",
  Upload_up_to_1_photo_: "Upload at most 1 photo!",
  Home_page_description: "Homepage description",
  Homepage_keywords: "Homepage keywords",
  Live_broadcast_room_title: "Live broadcast room title",
  Live_broadcast_room_description: "Live broadcast room-description",
  Live_broadcast_room_keywords: "Live broadcast room-keywords",
  App_download_URL: "App download URL",
  H5_homepage_banner_URL: "h5 homepage bannerurl",
  H5_homepage_banner_redirect_URL: "h5 homepage banner jump url",
  H5_live_broadcast_room_banner_URL: "h5 live broadcast room bannerurl",
  H5_Live_Room_Banner_Jump_URL: "h5 live broadcast room banner jump url",
  Live_streaming_services_1: "Live broadcast service",
  SMS_service: "SMS service",
  Certificate_file_name: "Certificate file name",
  Certificate_type: "Certificate type",
  General_Supplier_Configuration: "General supplier configuration",
  Whether_to_configure_TF_package_name: "Whether to configure TF package name",
  Package_Name_Configuration: "Package name configuration",
  Supplier_Configuration: "Supplier Configuration",
  Edit_Supplier_Configuration: "Edit Supplier Configuration",
  Add_supplier_configuration: "Add Supplier Configuration",
  Please_fill_in_the_configuration_information: "Please fill in the configuration information",


  Picture_matching: "Picture matching",
  Source: "Source",
  Feedback_type: "Feedback type",
  Number: "Serial number",
  Feedback_details: "Feedback details",
  Contact_information: "Contact information",
  Feedback_time: "Feedback time",
  Navigation_Station_Switch: "Navigation station switch",
  Page_Configuration: "Page configuration",
  Weight: "Weight",
  hint_37: "The larger the number, the higher the ranking",
  Button_Name: "Button name",
  Domain_name: "Domain name",
  Please_enter_the_weight: "Please enter the weight",
  Please_enter_the_button_name: "Please enter the button name",
  Mobile_related: "Mobile related",
  PC_related: "PC related",
  Personal_information: "Personal information",
  Login_account: "Login account",
  User_Name: "User name",
  Security_Settings: "Security settings",
  Change_password: "Change password",
  Original_password: "Original password",
  New_password: "New password",
  Confirm_new_password: "Confirm new password",
  hint_721: "Password length cannot be less than 6 characters",
  The_passwords_entered_twice_do_not_match: "The passwords entered twice do not match",
  Please_enter_your_password_again: "Please enter your password again",
  Merchant_Basic_Information: "Merchant basic information",
  Merchant_ID: "Merchant ID",
  Enterprise_Name: "Enterprise name",
  Contact_person: "Contact person",
  Contact_email: "Contact email",
  Contact_phone_number: "Contact phone number",
  SMS_verification_code: "SMS verification code",
  Please_enter_the_merchant_ID: "Please enter merchant ID",
  Please_enter_the_company_name: "Please enter company name",
  Please_enter_the_contact_person: "Please enter contact person",
  Please_enter_the_contact_person_phone_number: "Please enter the contact's phone number",
  Please_enter_the_contact_email: "Please enter the contact's email address",
  Please_enter_the_verification_code: "Please enter the verification code",
  Get_verification_code: "Get the verification code",
  Please_enter_sensitive_words: "Please enter sensitive words",
  Batch_Add: "Batch add",
  Query_results: "Query results",
  hint_720: "Sorry, the sensitive word was not found",
  Activity_Name: "Activity name",

  // Error end====

  hint_718: "Please enter a sensitive word and the length must not be less than two characters",
  hint_308: "Are you sure to delete this keyword?",
  continue_to_be_sent_on_the_platform: "After the ban is lifted, the word can continue to be sent on the platform",
  Are_you_sure_to_lift_the_ban_: "Are you sure to lift the ban? ",
  The_image_size_needs_to_be_40_x40: "The image size needs to be 40x40",
  Brand_name: "Brand name",
  Main_site_name: "Main site name",
  Please_enter_the_name_of_the_main_site: "Please enter the main site name",
  Main_site_redirect_URL: "Main site jump url",
  Please_enter_the_main_site_redirect_URL: "Please enter the main site jump url",
  H5_Main_Station_Jump_URL: "H5 main station jump url",
  Please_enter_the_H5_main_site_redirect_URL: "Please enter the H5 main station jump url",
  Homepage_banner: "Homepage banner",
  hint_717: "The image size needs to be 1200x150",

  Only_JPG_or_PNG_format_images_are_allowed_to_be_uploaded: "Only JPG or PNG format images are allowed to be uploaded",
  Are_you_sure_you_want_to_save_the_changes_: "Are you sure to save the changes?",
  hint_320: "Password modification is successful, please log in again",
  Review_version: "Review version",
  Please_enter_version: "Please enter version",
  Tag_number: "Tag number",
  List_Fields: "List field",
  Form_Description: "Form description",
  Remarks: "Remarks",
  Pre_explanation: "Pre-explanation",
  hint_35: "1. For the iOS version control of the review environment, hide the function; 2. Avoid obvious review environment control comments or code at the code level; 3. No control is required on the Android side",
  Version_control: "Version control",
  hint_36: "1. Display the version number that needs to be controlled at present. For example, if the version that needs to be reviewed is V1.1, then 1.1 needs to be entered in the input box, and the corresponding module that needs to be hidden is checked below. Then, in the V1.1 version, the module entries that are checked below are all hidden. 2. Enter the version number without the actual version and save it. It does not affect the display of any version module;",
  Control_module: "Control module",
  hint_317: "Display the function name that needs to be hidden in the review environment. The default check state is removed. After saving, this module will not be hidden in this version;",
  Please_fill_in_version_information: "Please fill in the version information",
  Select_language: "Select language",
  General_configuration: "General configuration",
  IOS_configuration: "IOS configuration",
  H5_configuration: "H5 configuration",
  New_download_page_configuration: "New download page configuration",
  Streaming_tool_configuration: "Push stream tool configuration",
  PC_configuration: "PC configuration",
  Mobile_streaming_configuration: "Mobile streaming configuration",
  Advertising_restriction_configuration: "Advertising restriction configuration",
  Virtual_Number_Restrictions: "Virtual number restrictions",
  Reporting_restrictions_for_third_party_data_apps: "Third-party data APP reporting restrictions",
  Report_domain_name_configuration: "Reporting domain name configuration",
  Please_enter_the_domain_name: "Please enter the domain name",
  hint_723: "Download APP first point reporting",
  Channel_code_parameter_acquisition: "Channel code parameter acquisition",
  PC_data_reporting: "PC data reporting",
  H5_data_reporting: "H5 data reporting",
  Domain_Name_Address: "Domain name address",
  Please_enter_the_domain_name_address: "Please enter the domain name address",
  Deactivate: "Deactivate",
  Please_select_the_type: "Please select the type",
  Are_you_sure_to_enable_the_domain_name_: "Are you sure to enable the domain name?",
  hint_722: "Are you sure to deactivate the domain name?",
  Update_successful: "Update successful",
  Recommended: "Recommended",
  Paused: "Paused",


  System: "System",
  Add_1: "Add",
  Update_type: "Update type",
  Version_number: "Version number",
  Prompt_package: "Prompt package",
  Update_package: "Update package",
  Reminder_date: "Reminder date",
  Recently_edited_by: "Recent editor",
  Last_edited_time: "Recent edit time",
  Version_update: "Version update",
  TF_Package_Update: "TF package update",
  Download_address: "Download address",
  Select_prompt_package: "Select prompt package",
  Please_fill_in_the_download_address: "Please fill in the download address",
  Select_Update_Package: "Select update package",
  Remaining: "Remaining",
  Heaven: "Heaven",
  Start_reminder_date: "Start reminder date",
  Update_content: "Update content",
  Please_enter_the_updated_content: "Please enter the updated content",
  hint_33: "Supported formats: PNG, JPG, size requirements: 750*1624",
  Edit_version_update: "Edit version update",
  Add_version_update: "Add version update",
  Please_set_an_updated_version: "Please set the updated version",
  Please_set_the_prompt_version: "Please set the prompt version",
  Please_select_the_system: "Please select the system",
  Please_choose_a_method: "Please select the method",
  Please_enter_the_content: "Please enter the content",
  Please_set_the_reminder_date: "Please set the reminder date",
  hint_725: "Uploaded images can only be in JPG or PNG format!",
  hint_724: "Uploaded image size cannot exceed 1MB!",
  Modified_successfully: "Modified successfully",
  User_Management: "User Management",
  IP_blacklist: "IP Blacklist",
  Application_ID: "Application ID",
  Application_Name: "Application Name",
  Last_modified_date: "Last modified date",
  Create_an_application: "Create an application",
  View_Key: "View key",
  Module: "Module",
  Supplier: "Supplier",
  Add_Module: "Add module",
  Reset_Key: "Reset key",
  New_Module: "Create a new module",
  Please_enter_the_application_name: "Please enter the application name",
  Please_select_a_module: "Please select a module",
  Please_select_a_supplier: "Please select a supplier",
  Please_enter_supplier_configuration: "Please enter supplier configuration",
  Edit_application: "Edit application",
  hint_34: "If the application has the function of using this module, deleting the record may make the function unavailable. Are you sure you want to delete it?",
  Start_page: "Start page",
  Change: "Change",


  Site_icon: "Site icon",
  Click_to_select: "Click to select",
  hint_28: "Only ico files can be uploaded, the image size must be 32x32, and the size must not exceed 20kb",
  Please_fill_in_the_statistics_link: "Please fill in the statistics link",
  Customer_service_code_: "Customer service code",
  Please_fill_in_the_customer_service_code: "Please fill in the customer service code",
  PC_album_page_status: "PC album page status",
  PC_album_page_link: "PC album page link",
  Please_fill_in_the_title: "Please fill in the title",
  Please_fill_in_the_description: "Please fill in the description",
  Please_fill_in_the_keywords: "Please fill in the keywords",
  Please_fill_in_the_record_number_document: "Please fill in the record number document",
  Please_fill_in_your_email_address: "Please fill in the email address",
  Please_fill_in_the_h5_download_page_address: "Please fill in the h5 download page address",
  Please_select_the_Feixin_QR_code_image: "Please select the Feixin QR code image",
  Please_upload_the_logo_icon: "Please upload the logo icon",
  Please_upload_the_logo_title: "Please upload the logo title",
  Please_enter: "Please enter",
  Only_ICO_files_can_be_uploaded_: "Only ICO files can be uploaded!",
  hint_729: "The uploaded image size cannot exceed 20KB!",
  Please_enter_the_theme_name: "Please enter the theme name",
  Add_a_new_theme: "Add a new theme",
  There_is_currently_no_content: "No content",
  Last_modification_time: "Last modification time",
  Please_enter_sorting: "Please enter the sort",
  Are_you_sure_you_want_to_delete_it_: "Are you sure you want to delete? ",
  Delete_successful: "Deleted successfully",
  Theme: "Theme",
  Publish: "Publish",
  Please_select_a_theme: "Please select a theme",
  Please_enter_the_content_of_the_agreement: "Please enter the content of the agreement",
  Please_enter_the_protocol_name: "Please enter the name of the agreement",
  hint_728: "Are you sure to publish the agreement information",
  hint_727: "Are you sure to save the agreement information",
  User_Registration_Agreement: "User Registration Agreement",
  User_Privacy_Agreement: "User Privacy Agreement",
  Live_streaming_protocol: "Live Streaming Agreement",
  Payment_Agreement: "Payment Agreement",
  Settlement_rules: "Settlement Rules",
  Recharge_Service_Agreement: "Recharge Service Agreement",
  About: "About",
  Please_select_the_protocol_type: "Please select the agreement type",
  Login_Page_Logo: "Login Page Logo",
  hint_29: "Only jpg/png files can be uploaded, the image size must be 109X140, and a maximum of 1 image",
  Main_interface_logo: "Main interface Logo",
  hint_30: "Only jpg/png files can be uploaded, the image size must be 69X67, and a maximum of 1 image",
  About_Page_Logo: "About page Logo",
  hint_31: "Only jpg/png files can be uploaded, the image size must be 109X109, and a maximum of 1 image",
  hint_32: "The uploaded image size is incorrect, please upload according to the format requirements!",
  Add_TF_package: "Add TF package",
  Package_name: "Package name",
  Download_link: "Download link",
  Developer_account: "Developer account",
  Effective_date_1: "Effective date",
  Expiration_date: "Expiration date",
  Remaining_valid_days: "Remaining valid days",
  Please_enter_valid_days: "Please enter the valid days",
  Effective_days: "Valid days",
  Please_enter_the_package_name: "Please enter the package name",
  Edit_TF_package: "Edit TF package",
  Please_fill_in_the_download_link: "Please fill in the download link",
  Please_select_the_effective_date: "Please select the effective date",
  hint_726: "The effective days must be greater than or equal to 90",
  Created_successfully: "Created successfully",
  Update_method: "Update method",


  Operation_time: "Operation time",
  Operator: "Operator",
  No: "No",
  Yes: "Yes",
  C_end_user_data: "C-end user data",
  Batch_import_of_member_templates: "Batch import of member templates",
  Account_has_been_banned: "Account has been banned",
  hint_734: "Are you sure you want to enable this account?",
  Prompt: "Prompt",
  Account_enabled: "Account has been enabled",
  hint_733: "Are you sure you want to reset the password for this user? The initial password is: Aa123456",
  Password_reset_successful: "Password reset successful",
  Password_reset_failed: "Password reset failed",
  Download_the_homepage_logo_: "Download page homepage Logo:",
  Click_to_select_image: "Click to select image",
  hint_20: "Only jpg/png files can be uploaded, the image size must be 344X120, and a maximum of 1 image",
  hint_21: "Only jpg/png files can be uploaded, the image size must be 288X100, and a maximum of 1 image",
  Download_the_top_screen_background_image: "Download page top screen background image",
  hint_22: "Only jpg/png files can be uploaded, the image size must be 375X732, and a maximum of 1 image",
  Download_the_top_screen_copy_image: "Download page top screen copy image",
  hint_23: "Only jpg/png files can be uploaded, the image size must be 1980x1650, and a maximum of 1 image",
  Download_page_application_introduction_image: "Download page application introduction image",
  hint_24: "Only jpg/png files can be uploaded, the image size must be 360X842, and a maximum of 4 images",
  Download_page_application_introduction: "Download page application introduction",
  Download_page_features: "Download page feature functions",
  PWA_configuration: "pwa configuration",
  Only_json_files_can_be_uploaded: "Only .json files can be uploaded",
  Download_floating_bar_description: "Download floating bar description",
  New_H5_logo: "New H5-logo",
  hint_732: "The recommended image size is 40x40",
  New_H5_Download_floating_bar: "New H5-Download floating bar",
  hint_731: "The recommended image size is 400x60",
  Youmeng_Statistics: "Youmeng Statistics",
  Please_enter_the_URL_corresponding_to_src: "Please enter the url corresponding to src",
  hint_25: "If you need to count multiple domain names, please use || to distinguish",
  Baidu_Statistics: "Baidu Statistics",
  Version_download_address: "Version download address",
  Quick_Version: "Quick version",
  Please_enter_the_iOS_shortcut_address: "Please enter the iOS shortcut version address",
  Please_enter_Android_Scheme: "Please enter Android Scheme",
  H5_Station_Download_Jump_Status: "H5 station download jump status",
  APP_H5_Album_Page_Name: "APP/H5 album page name",
  Please_enter_a_name: "Please enter a name",
  APP_H5_Album_Page_Link: "APP/H5 album page link",
  Please_upload_pictures: "Please upload pictures",
  Please_upload_the_PWA_configuration_file: "Please upload pwa configuration file",
  Circle_: "Circle {num}",
  Please_enter_the_circle_ID: "Please enter circle ID",
  Please_fill_in_the_Android_app_download_address: "Please fill in Android APP download address",
  Please_fill_in_the_iOS_app_download_address: "Please fill in iOS APP download address",
  Please_fill_in_the_application_introduction: "Please fill in the application introduction",
  Please_fill_in_the_featured_features: "Please fill in the featured features",
  Please_fill_in_the_description_1: "Please fill in the description",
  hint_730: "Please fill in the iOS stable version download address",
  Please_fill_in_the_Android_Scheme: "Please fill in the Android Scheme",
  Please_fill_in_the_iOS_Scheme: "Please fill in the iOS Scheme",
  hint_18: "You can only upload jpg, jpeg or png files",
  Upload_up_to_1_image: "Upload at most 1 image",
  hint_19: "You can only upload jpg, jpeg or png files!",
  The_upload_size_cannot_exceed_1_5M_: "The upload size cannot exceed 1.5M!",
  Only_json_format_files_can_be_uploaded_: "Only .json format files can be uploaded!",
  The_size_cannot_exceed_1MB_: "Size cannot exceed 1MB!",
  IOS_double_plot: "IOS double plot",
  Protocol_identifier_ID: "Protocol identification ID",
  Protocol_identifier: "Protocol identification",
  Protocol_Name: "Protocol name",
  Last_editing_date: "Last editing date",
  Release_status: "Release status",
  H5_download_page_address: "H5 download page address",
  Application_Introduction: "Application introduction",
  Image_and_Text_Configuration: "Image and text configuration",
  Page_: "Page {num}",
  Only_jpg_png_files_can_be_uploaded: "Only jpg/png files can be uploaded",
  The_size_is_394_X798: "Size is 394X798",
  Please_enter_the_application_introduction: "Please enter the application introduction",
  Please_enter_the_address: "Please enter the address",
  The_title_of_the_th_page_cannot_be_empty: "The title of the ${num}th page cannot be empty",
  Logo_icon: "logo-icon",
  Logo_Title: "logo-title",
  The_recommended_image_size_is_140_x70: "The recommended image size is 140x70",
  Navigation_bar_activity_map: "Navigation bar activity map",
  hint_307: "Size: 312*126, only jpg/png files can be uploaded",
  PC_download_copy: "PC download copy",
  hint_26: "Please enter the first line of copy, no more than 10 characters",
  hint_27: "Please enter the second line of copy, no more than 10 characters",
  Page_title_: "Page title:",
  Please_enter_a_title: "Please enter the title",
  Page_keywords: "Page keywords",
  Page_Description: "Page description",
  Please_enter_a_description: "Please enter a description",
  Please_enter_the_download_address_for_the_anchor_tool: "Please enter the anchor tool download address",
  H5_download_page_address_1: "h5 download page address",
  Please_enter_the_h5_download_page_address: "Please enter the h5 download page address",
  Anchor_Recruitment_Description: "Anchor Recruitment Description",
  Please_enter_the_anchor_recruitment_description: "Please enter the anchor recruitment description",
  Anchor_Recruitment_QR_Code: "Anchor Recruitment QR Code",
  The_recommended_image_size_is_256x256: "The recommended image size is 256x256",
  Contact_email_1: "Contact Email",
  Please_enter_your_contact_email: "Please enter the contact email",
  Bottom_copyright_information: "Copyright information at the bottom",
  Please_enter_copyright_information: "Please enter copyright information",
  PC_Link_Configuration: "PC link configuration",
  Please_enter_the_PC_domain_name_link_configuration: "Please enter the PC domain name link configuration",


  // Error start
  nickname: "nickname",
  Avatar: "avatar",
  Mobile_area_code: "mobile area code",
  Mobile_phone_number: "mobile number",
  Third_party_binding: "third party binding",
  Recent_login_time: "recent login time",
  Recently_logged_in_IP: "recent login IP",
  Last_visited_time: "recent visit time",
  Anchor: "anchor",
  Experts: "experts",
  Anchor_level: "anchor level",
  Ban: "ban",
  Enable: "enable",
  Detailed: "detailed",
  Prohibition: "ban",
  Reset_password: "reset password",
  Personal_information_1: "personal information",
  Mobile_phone_number_and_area_code: "mobile number area code",
  Settings: "settings",
  Phone_number: "mobile number",
  Please_enter_your_phone_number: "please enter your mobile number",
  User_status: "user status",
  Normal_1: "Normal",
  hint_737: "Is it a live streamer",
  hint_736: "Is it a tipster",
  Recommendation_code: "Recommendation code",
  Personal_signature: "Personal signature",
  User_UID: "User UID",
  Prohibition_period_: "Ban period",
  Release_the_ban: "Release the ban",
  Reasons_for_Prohibition: "Ban reason",
  Please_select_the_reason_for_the_ban: "Please select the ban reason",
  Delete_chat_messages: "Delete chat messages",
  Delete_all_chat_messages_in_the_live_broadcast_room: "Delete all chat messages in the live broadcast room",
  hint_735: "Are you sure you want to ban this account?",
  Is_IP_banned_: "Is IP banned?",
  Current_level: "Current level",


  Triple_plot: "Triple plot",
  Double_plot: "Double plot",
  Please_fill_in_the_brand_name: "Please fill in the brand name",
  Please_fill_in_the_prefix: "Please fill in the prefix",
  Please_fill_in_the_currency_name: "Please fill in the currency name",
  Please_fill_in_customer_service_WeChat: "Please fill in the customer service WeChat",
  Please_fill_in_the_download_address_for_the_anchor_tool: "Please fill in the anchor tool download address",
  Please_fill_in_the_ICP_information: "Please fill in the ICP information",
  Please_fill_in_the_default_message_for_the_live_broadcast_room: "Please fill in the default message for the live broadcast room",
  Please_fill_in_the_assistant_display_time: "Please fill in the assistant display time",
  hint_15: "The upload size is incorrect, please upload according to the format requirements!",
  hint_16: "Only png files can be uploaded!",
  The_upload_size_cannot_exceed: "The upload size cannot exceed",
  hint_17: "The upload size cannot exceed 800K!",
  Select: "Select",
  Please_upload_the_edited_Excel_template_file: "Please upload the edited excel template file",
  Confirm: "Confirm",
  Importing: "Importing",
  Please_upload_the_file: "Please upload the file",
  Only_xlsx_format_files_can_be_uploaded: "Only .xlsx format files can be uploaded!",
  hint_742: "The uploaded image size cannot exceed 500MB!",
  Successfully_added: "Added successfully",
  Import_member_failure_list: "Import member failure list",
  OK: "Confirm",
  Select_file: "Select file",
  Please_enter_the_ID: "Please enter the id",
  User_nickname: "User nickname",
  hint_740: "Please enter the user nickname",
  User_phone_number: "User phone number",
  hint_739: "Please enter user phone number",
  User_level: "User level",
  Please_enter_user_level: "Please enter user level",
  Membership_level: "Membership level",
  Game_users: "Game users",
  Status: "Status",
  Registration_time: "Registration time",
  Identity: "Identity",
  User_type: "User type",
  Inviter_userid: "Inviter userid",
  hint_738: "Please enter inviter userid",
  Registration_Platform: "Registration platform",
  hint_741: "Please enter registration platform website",
  Batch_import_of_members: "Batch import members",
  Download_batch_import_templates: "Download batch import templates",
  Export_data: "Export data",
  Total_number_of_registered_users: "Total number of registered users",
  Number_of_registered_users_today: "Today's number of registered users",

  Operation: "Operation",
  Upload: "Upload",
  Ordinary_user_currency_icon: "Ordinary user currency icon",
  Size_1: "Size",
  Type: "Type",
  Preview_image: "Preview image",
  Live_settlement_currency_icon: "Live settlement currency icon",
  Brocade_Bag_Settlement_Currency_icon: "Bag settlement currency icon",
  hint_11: "Supported format: PNG, size: within 500K",
  Android_Triple_Image: "Android triple image",
  Top_of_homepage_top_of_activity_page: "Homepage top, activity page top",
  At_the_top_of_the_competition_page_my: "Competition page top, my",
  Signal_source_top: "Signal source top",
  Please_select: "Please select",
  Please_upload: "Please upload",
  Operation_successful: "Operation successful",
  Can_only_upload_can_only_upload_PNG_files: "Only png files can be uploaded",
  hint_800: "The upload size cannot exceed 500K!",
  Login_address_configuration: "Login address configuration",
  Please_enter_your_login_address: "Please enter the login address",
  hint_12: "This configuration is only effective for the merchant side user system used by the push stream end. After configuration, the push stream end supports identifying the merchant side's own username and password",
  Recommended_by_the_editorial_circle: "Edit circle recommendation",
  Please_enter_the_resource_name: "Please enter the resource name",
  Circle_List_Recommendation: "Circle list recommendation",
  Recommended_by_Square_Circle: "Square circle recommendation",
  Please_enter_userid: "Please enter userid",
  Please_enter_the_IP_address: "Please enter IP",
  Search: "Search",
  Refresh_IP_blacklist_cache: "Refresh IP blacklist cache",
  Reset: "Reset",
  Loading: "Loading...",
  Merchant_ID_1: "Merchant ID",
  Prohibition_time_1: "Banned time",
  Remove: "Remove",
  hint_743: "Are you sure you want to remove this IP? ",
  APP_brand_name: "APP brand name",
  Default_username_prefix: "Default username prefix",
  Common_user_currency_name: "Common user currency name",
  Live_settlement_currency_name: "Live settlement currency name",
  The_name_of_the_settlement_currency_for_the_brocade_bag: "Brocade settlement currency name",
  Contact_customer_service: "Contact customer service",
  Assistant_default_icon: "Assistant default icon",
  Click_to_select_resources: "Click to select resources",
  hint_13: "Only svga files can be uploaded, and the image size cannot exceed 800k",
  Assistant_unread_message_icon: "Assistant unread message icon",
  Assistant_displays_icon_size: "Assistant display icon size",
  Small: "Small",
  Medium: "Medium",
  Big: "Large",
  Assistant_display_time: "Assistant display time",
  Online_customer_service_address: "Online customer service address",
  Initialize_account: "Initialize account",
  Anchor_tool_download_address: "Anchor tool download address",
  ICP_Filing_Information: "ICP filing information",
  Live_broadcast_room_default_message: "Live broadcast room default message",
  Conversation_background: "Conversation background",
  Default_official_anchor_profile_picture: "Default official anchor avatar",
  Default_official_message_avatar: "Default official message avatar",
  Login_registration_page_background: "Login registration page background",
  hint_14: "Supported formats: JPG, PNG, GIF, MP4, size: within 3M",
  Delete: "Delete",

  Edit: "Edit",
  User_Configuration: "User Configuration",
  Please_enter_a_numerical_level: "Please enter a numerical level",
  hint_4: "Nicknames containing English letters are not allowed below this level",
  Nickname_English_letter_level_limit: "Nickname English letter level limit",
  Live_broadcast_room_configuration: "Live broadcast room configuration",
  hint_6: "Live broadcast room chat is not allowed below this level",
  hint_5: "Live broadcast room chat English letters are replaced below this level*",
  Open: "Open",
  Close: "Close",

  Circle_Speech_Configuration: "Circle speech configuration",
  hint_7: "Private messages to anchors are not allowed within the circle below this level",
  Speech_level_limit: "Speech level limit",
  hint_8_: "Chatting and speaking within the circle is not allowed below this level",
  Posting_updates_submission_levels: "Posting dynamics/submission levels",
  hint_9: "Posting dynamics/submissions are not allowed below this level",
  hint_911: "Publish dynamics/submissions",
  Cancel_1: "Cancel",
  Save_1: "Save",
  Please_enter_level: "Please enter the level",
  APP_bottom_label: "APP bottom label",
  Initial_icon: "Initial icon",
  Activity_icon: "Activity icon",
  World_Cup_icon: "World Cup icon",
  The_bottom_marketing_position_of_the_APP: "APP bottom marketing position",
  Please_enter_the_redirect_address: "Please enter the jump address",
  Event_start_time: "Activity start time",
  APP_homepage_my_background: "APP homepage, my background",
  hint_10: "Size: 1125*624, only jpg/png files can be uploaded",

  // 新增
  'Member_login_data': 'Member login data',
  'Login_type': 'Login type',
  // 新增
  'Such_as': 'for example ',
  'marked_108': 'cannot be less than 0',
  'marked_109': 'No space allowed ',
  'marked_110': 'Can only upload jpg/png files, the image size must be 360X842, maximum 4 ',
  'marked_111': 'The recommended image size is 400x60, only jpg/png/gif files can be uploaded ',
  'marked_112': 'Upload up to 4 photos ',
  'marked_113': 'Please enter text ',
  'marked_114': 'Please set the cover ',
  'Comment_details': 'Comment details',
  'marked_115': 'Note: Maximum 20 data ',
  'marked_116': 'Topic background graph ',
  'marked_117': 'When enabled, the data of the website is automatically crawled. Do you want to confirm that it is enabled? ',
  'marked_118': "{num} sheet ",
  // 新增
  'marked_119': 'Please select label level ',
  'marked_120': 'Please select the corresponding level 1 tag ',
  'marked_121': 'Batch change secondary status ',
  'marked_122': 'Created tag, does not support deletion, change of name or own tag level; Only the level-1 label and label status can be changed. Do you want to create a label? ',
  'marked_123': 'Confirm the change? ',
  // 新增
  'marked_124': 'Allocate data volume ',
  'marked_125': 'publication volume ',
  'unaudited': 'unaudited',
  'Video_size': 'Video size',
  'marked_126': 'Please fill in the login address',
  //新手通道
  'new_passage': 'newbie passage',
  'vip_passage': 'VIP passage',
  'zhanshang_passage': 'Investment Channel ',
  'click_num': 'Number of clicks ',
  'small_help': 'Little Assistant click statistics ',

  'So_save_it': 'Save first ',
  'if_save_to_web': 'Whether to update to front end ',
  'match_name': 'Race name',
  'if_candle_hot': 'Whether to cancel hot',
  'candle_hot': 'Unhit ',
  'go_hot': 'popular on the Internet ',
  'candle_hot_time': 'Cancel hot time',
  'please_add_match': 'Please add a match',
  'hint3333': 'Are you sure to delete',
  'hint3334': 'Cancel popular events',
  'hint3335': 'Are you sure to update?',
  'hint3336': 'Are you sure it hot?',
  'hint3337': 'Hidden? ',
  'hint3338': 'Display? ',
  'hint3339': 'Only jpg/png/jpeg/gif files can be uploaded',
  'hint3340': 'Assistant response speed',
  'hint3341': 'Assistant opening speed',
  'hint3342': 'Assistant processing satisfaction',
  'hint3343': 'Feedback',
  'hint3344': 'Assistant satisfaction data'
};
