import limitNum from './input-valid'
import lowerCase from './input-lowercase'
import trim from './input-trim'
const directives = {
  limitNum,
  lowerCase,
  trim
}

export default directives
