var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showWrap
    ? _c(
        "div",
        { staticClass: "wrap", class: _vm.showContent ? "fadein" : "fadeout" },
        [_vm._v(_vm._s(_vm.text))]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }