import login from './login'
import navbar from './navbar'
import route from './route'
import common from './common'

export default {
  login,
  navbar,
  route,
  common,

}
