export default {
  title: 'คอนโซลการจัดการ',
  Merchant: 'รหัสร้านค้า',
  logIn: 'เข้าสู่ระบบ',
  username: 'หมายเลขบัญชี',
  password: 'รหัสผ่าน',
  MerchantName: 'รหัสร้านค้า',
  usernameTips: 'โปรดป้อนชื่อผู้ใช้ที่ถูกต้อง',
  passwordTips: 'รหัสผ่านต้องมีความยาวไม่น้อยกว่า 6 หลัก',
  merchantNameTips: 'กรุณากรอกรหัสร้านค้าให้ถูกต้อง',
  ForgotPasswordPrompt: 'ติดต่อผู้ดูแลระบบเพื่อรีเซ็ตรหัสผ่านหากคุณลืมรหัสผ่าน'
}