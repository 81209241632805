import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import i18n from './locale' // internationalization
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters

import importDirective from '@/directive'

import TreeTable from 'tree-table-vue'
import {
  registryToast
} from './components/ToastMessage' // message 提示消息插件
import {
  resetForm,
  download
} from '@/utils'
import {
  deepCopy
} from '@/utils/tools'
Vue.use(registryToast)
// 导入moment.js
import './utils/moment.js'

/**
 * NODE_ENV为mock时使用本地模拟数据调试
 */
if (process.env.NODE_ENV === 'mock') {
  const {
    mockXHR
  } = require('../mock')
  mockXHR()
}
Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(TreeTable)

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Object.assign(Vue.prototype, {
  resetForm,
  download,
  deepCopy
})

importDirective(Vue)

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
