export default {
  title: '管理控台',
  merchant: '商户ID',
  logIn: '登录',
  username: '账号',
  password: '密码',
  merchantName: '商户ID',
  usernameTips: '请输入正确的用户名',
  passwordTips: '密码长度不能少于6位',
  merchantNameTips: '请输入正确的商户ID',
  ForgotPasswordPrompt: '如忘记密码，请联系管理员重置密码。'
}
