import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang
import elementVILocale from 'element-ui/lib/locale/lang/vi' // element-ui lang
import elementTHLocale from 'element-ui/lib/locale/lang/th' // element-ui lang
import elementzhTWLocale from 'element-ui/lib/locale/lang/zh-TW' // element-ui lang
import enLocale from './lang/en/index'
import zhLocale from './lang/zn/index'
import viLocale from './lang/vi/index'
import thLocale from './lang/th/index'
import zhtLocale from './lang/zht/index'
// 测试语言

Vue.use(VueI18n)

const messages = {
  th: {
    ...thLocale,
    ...elementTHLocale
  },
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  },
  vi: {
    ...viLocale,
    ...elementVILocale
  },
  zht: {
    ...zhtLocale,
    ...elementzhTWLocale
  },
}
export function getLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) return chooseLanguage
  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}

const i18n = new VueI18n({
  // set locale
  // options: en | zh
  locale: getLanguage(),
  // locale:'en',
  // locale: 'vi',
  // set locale messages
  messages
})

export default i18n
