<template>
  <div class="app-container" style="margin: 10px 150px 100px 150px">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-card class="box-card" style="padding-bottom: 300px">
          <div slot="header" class="clearfix">
            <span>{{ $t('common.Personal_information') }}</span>
          </div>
          <div>
            <ul class="user-info">
              <li>
                <div style="height: 100%">
                  <svg-icon icon-class="login" />
                  {{ $t('common.Login_account') }}
                  <div class="user-right">{{ name }}</div>
                </div>
              </li>
              <li>
                <svg-icon icon-class="user1" />
                {{ $t('common.User_Name') }}
                <div class="user-right">{{ realName }}</div>
              </li>
              <li>
                <svg-icon icon-class="anq" />
                {{ $t('common.Security_Settings') }}
                <div class="user-right">
                  <a @click="dialogFormVisible = true">
                    {{ $t('common.Change_password') }}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </el-card>
        <el-dialog
          :title="$t('common.Change_password')"
          :visible.sync="dialogFormVisible"
          center
          width="700px"
          :destroy-on-close="true"
          @closed="closeDialog()"
        >
          <el-form ref="dataForm" :rules="rules" :model="form">
            <el-form-item
              :label="$t('common.Original_password')"
              prop="oldPassword"
            >
              <el-input
                v-model="form.oldPassword"
                type="password"
                :minlength="6"
                :maxlength="16"
              />
            </el-form-item>
            <el-form-item :label="$t('common.New_password')" prop="newPassword">
              <el-input
                v-model="form.newPassword"
                type="password"
                :minlength="6"
                :maxlength="16"
              />
            </el-form-item>
            <el-form-item
              :label="$t('common.Confirm_new_password')"
              prop="confirmationPassword"
            >
              <el-input
                v-model="form.confirmationPassword"
                type="password"
                :minlength="6"
                :maxlength="16"
              />
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">
              {{ $t('common.Cancel_1') }}
            </el-button>
            <el-button type="primary" :loading="loading" @click="doSubmit()">
              {{ $t('common.Confirm') }}
            </el-button>
          </div>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { modifyPassword } from '@/api/permission/user'

export default {
  name: 'Profile',
  data() {
    const passwordValidate = (rule, value, callback) => {
      if (value && value.length > 5) {
        callback()
      } else {
        callback(new Error(this.$t('common.hint_721')))
      }
    }
    const confirmPass = (rule, value, callback) => {
      if (value) {
        if (this.form.newPassword !== value) {
          callback(new Error(this.$t('common.The_passwords_entered_twice_do_not_match')))
        } else {
          callback()
        }
      } else {
        callback(new Error(this.$t('common.Please_enter_your_password_again')))
      }
    }
    return {
      loading: false,
      form: { oldPassword: '', newPassword: '', confirmationPassword: '' },
      dialogFormVisible: false,
      rules: {
        oldPassword: [{ required: true, validator: passwordValidate, trigger: 'blue' }],
        newPassword: [{ required: true, validator: passwordValidate, trigger: 'blue' }],
        confirmationPassword: [{ required: true, validator: confirmPass, trigger: 'change' }]
      }
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'realName'
    ])
  },
  mounted() {
  },
  methods: {
    doSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          modifyPassword(this.form).then(res => {
            this.resetForm()
            this.$notify({
              title: this.$t('common.hint_320'),
              type: 'success',
              duration: 1500
            })
            setTimeout(() => {
              store.dispatch('LogOut').then(() => {
                location.reload() // 为了重新实例化vue-router对象 避免bug
              })
            }, 1500)
            this.loading = false
          }).catch(error => {
            this.loading = false
            console.log(error.message)
          })
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.dialogFormVisible = false
      this.$refs['dataForm'].resetFields()
      this.form = { oldPassword: '', newPassword: '', confirmationPassword: '' }
    },
    closeDialog() {
      this.resetForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  padding-left: 0;
  list-style: none;
  li {
    border-bottom: 1px solid #f0f3f4;
    padding: 11px 0;
    font-size: 13px;
  }
  .user-right {
    float: right;

    a {
      color: #317ef3;
    }
  }
}
</style>
