<template>
  <div class="app-container" style="margin: 10px 150px 100px 150px">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-card class="box-card" style="padding-bottom: 300px">
          <div slot="header" class="clearfix">
            <span>{{ $t('common.Merchant_Basic_Information') }}</span>
          </div>
          <div>
            <ul class="user-info">
              <li>
                <div style="height: 100%">
                  <svg-icon icon-class="login" />
                  {{ $t('common.Merchant_ID') }}
                  <div class="user-right">{{ forms.merchantId }}</div>
                </div>
              </li>
              <li>
                <svg-icon icon-class="user1" />
                {{ $t('common.Enterprise_Name') }}
                <div class="user-right">{{ forms.name }}</div>
              </li>
              <li>
                <svg-icon icon-class="user1" />
                {{ $t('common.Contact_person') }}
                <div class="user-right">{{ forms.contactName }}</div>
              </li>
              <li>
                <svg-icon icon-class="user1" />
                {{ $t('common.Contact_email') }}
                <div class="user-right">{{ forms.contactEmail }}</div>
              </li>
              <li>
                <svg-icon icon-class="user1" />
                {{ $t('common.Contact_phone_number') }}
                <div class="user-right">{{ forms.contactMobile }}</div>
              </li>
              <li>
                <svg-icon icon-class="anq" />
                {{ $t('common.Security_Settings') }}
                <div class="user-right">
                  <a @click="selectFun">{{ $t('common.Change') }}</a>
                </div>
              </li>
            </ul>
          </div>
        </el-card>
        <el-dialog
          :title="$t('common.Modifying_Merchant_Basic_Information')"
          :visible.sync="dialogFormVisible"
          center
          top="4vh"
          width="700px"
          :destroy-on-close="true"
          @closed="closeDialog()"
        >
          <el-row style="max-height: 500px; overflow: auto">
            <el-form ref="dataForm" :rules="rules" :model="form">
              <el-form-item :label="$t('common.Merchant_ID')" prop="merchantId">
                <el-input v-model="form.merchantId" :disabled="true" />
              </el-form-item>
              <el-form-item :label="$t('common.Enterprise_Name')" prop="name">
                <el-input v-model="form.name" :disabled="true" />
              </el-form-item>
              <el-form-item
                :label="$t('common.Contact_person')"
                prop="contactName"
              >
                <el-input v-model="form.contactName" />
              </el-form-item>
              <el-form-item
                :label="$t('common.Contact_email')"
                prop="contactEmail"
              >
                <el-input v-model="form.contactEmail" />
              </el-form-item>
              <el-form-item
                :label="$t('common.Contact_phone_number')"
                prop="contactMobile"
              >
                <el-input v-model="form.contactMobile" />
              </el-form-item>
              <el-form-item
                :label="$t('common.SMS_verification_code')"
                prop="code"
              >
                <el-input v-model="form.code" type="number" />
              </el-form-item>
              <el-form-item>
                <div class="button-item" @click="getInfoCode()">
                  {{ buttonTitle }}
                </div>
              </el-form-item>
            </el-form>
          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">
              {{ $t('common.Cancel_1') }}
            </el-button>
            <el-button type="primary" :loading="loading" @click="doSubmit()">
              {{ $t('common.Save_1') }}
            </el-button>
          </div>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getMerchantsInfo, sendSms, merchantEdit } from '@/api/permission/user'
import { deepClone } from '@/utils'
export default {
  name: 'Merchants',
  data() {
    return {
      loading: false,
      form: { name: '', merchantId: '', contactName: '', contactMobile: '', contactEmail: '' },
      forms: { name: '', merchantId: '', contactName: '', contactMobile: '', contactEmail: '' },
      dialogFormVisible: false,
      rules: {
        merchantId: [{ required: true, message: this.$t('common.Please_enter_the_merchant_ID') }],
        name: [{ required: true, message: this.$t('common.Please_enter_the_company_name') }],
        contactName: [{ required: true, message: this.$t('common.Please_enter_the_contact_person') }],
        contactMobile: [{ required: true, message: this.$t('common.Please_enter_the_contact_person_phone_number') }],
        contactEmail: [{ required: true, message: this.$t('common.Please_enter_the_contact_email') }],
        code: [{ required: true, message: this.$t('common.Please_enter_the_verification_code') }]
      },
      buttonTitle: this.$t('common.Get_verification_code'),
      timer: null
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    selectFun() {
      this.dialogFormVisible = true
      this.form = deepClone(this.forms)
    },
    getInfo() {
      getMerchantsInfo().then(res => {
        this.forms = res.data
      }).catch(error => {

      })
    },
    getInfoCode() {
      if (this.buttonTitle !== this.$t('common.Get_verification_code')) {
        return
      }
      sendSms({ mobile: this.form.contactMobile }).then(res => {
        let tem = 60
        this.timer = setInterval(() => {
          tem--
          this.buttonTitle = (tem < 10 ? '0' + tem : tem) + 's'
          if (tem === 0) {
            this.buttonTitle = this.$t('common.Get_verification_code')
            clearInterval(this.timer)
          }
        }, 1000)
      }).catch(error => {
        this.buttonTitle = this.$t('common.Get_verification_code')
      })
    },
    doSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          merchantEdit(this.form).then(res => {
            this.getInfo()
            this.dialogFormVisible = false
            this.loading = false
          }).catch(error => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    closeDialog() {
      this.$nextTick(() => {
        this.$refs.dataForm.resetFields()
        this.$refs.dataForm.clearValidate()
      })
      clearInterval(this.timer)
      this.buttonTitle = this.$t('common.Get_verification_code')
    }
  }
}
</script>

<style lang="scss" scoped>
.button-item {
  width: 110px;
  text-align: center;
  padding: 10px 0;
  background: #fff;
  border: 1px solid #dcdfe6;
  display: inline-block;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  margin-top: 10px;
}
.user-info {
  padding-left: 0;
  list-style: none;
  li {
    border-bottom: 1px solid #f0f3f4;
    padding: 11px 0;
    font-size: 13px;
  }
  .user-right {
    float: right;

    a {
      color: #317ef3;
    }
  }
}
</style>
