export default {
  title: "Điều khiển quản lý",
  merchant: 'ID người bán',
  logIn: 'Đăng nhập',
  username: 'Tài khoản',
  password: 'mật khẩu',
  merchantName: 'Merchant ID',
  usernameTips: 'Vui lòng nhập tên người dùng chính xác',
  passwordTips: "Mật khẩu không thể dài ít hơn 6 chữ số",
  merchantNameTips: 'Vui lòng nhập ID người bán chính xác',
  ForgotPasswordPrompt: "Nếu bạn quên mật khẩu, vui lòng liên hệ với quản trị viên để đặt lại mật khẩu."
}
