export default {
  dashboard: '首頁',
  directivePermission: '指令許可權',
  icons: '圖示',
  components: '組件',
  componentMixin: '小組件',
  backToTop: '返回頂部',
  errorPages: '錯誤頁面',
  page401: '401',
  page404: '404',
  errorLog: '錯誤日誌',
  clipboardDemo: 'Clipboard',
  i18n: '國際化',
  profile: '個人中心'
}
