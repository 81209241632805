export default {
  Edit: "编辑",
  User_Configuration: "用户配置",
  Please_enter_a_numerical_level: "请输入数字等级",
  hint_4: "低于该等级不允许设置包含英文字母昵称",
  Nickname_English_letter_level_limit: "昵称英文字母等级限制",
  Live_broadcast_room_configuration: "直播间配置",
  hint_6: "低于该等级则不允许直播间聊天",
  hint_5: "低于该等级直播间聊天英文字母被替换*",
  Open: "开启",
  Close: "关闭",
  Circle_Speech_Configuration: "圈子发言配置",
  hint_7: "低于该等级则不允许圈子内私信主播",
  Speech_level_limit: "发言等级限制",
  hint_8_: "低于该等级则不允许圈子内聊天发言",
  Posting_updates_submission_levels: "发布动态/投稿等级",
  hint_9: "低于该等级则不允许发布动态/投稿",
  hint_911: "发布动态/投稿",
  Cancel_1: "取消",
  Save_1: "保存",
  Please_enter_level: "请输入等级",
  APP_bottom_label: "APP底部标签",
  Initial_icon: "初始icon",
  Activity_icon: "活动icon",
  World_Cup_icon: "世界杯icon",
  The_bottom_marketing_position_of_the_APP: "APP底部营销位",
  Please_enter_the_redirect_address: "请输入跳转地址",
  Event_start_time: "活动开始时间",
  APP_homepage_my_background: "APP首页、我的背景",
  hint_10: "尺寸： 1125*624，只能上传jpg/png文件",
  Operation: "操作",
  Upload: "上传",
  Ordinary_user_currency_icon: "普通用户货币icon",
  Size_1: "尺寸",
  Type: "类型",
  Preview_image: "预览图",
  Live_settlement_currency_icon: "直播结算货币icon",
  Brocade_Bag_Settlement_Currency_icon: "锦囊结算货币icon",
  hint_11: "支持格式：PNG，大小：500K以内",
  Android_Triple_Image: "Android三倍图",
  Top_of_homepage_top_of_activity_page: "首页顶部、活动页顶部",
  At_the_top_of_the_competition_page_my: "赛事页顶部、我的",
  Signal_source_top: "信号源顶部",
  Please_select: "请选择",
  Please_upload: "请上传",
  Operation_successful: "操作成功",
  Can_only_upload_can_only_upload_PNG_files: "只能上传只能上传png文件",
  hint_800: "上传大小不能超过 500K!",
  Login_address_configuration: "登录地址配置",
  Please_enter_your_login_address: "请输入登录地址",
  hint_12:
    "此配置仅针对推流端使用商户侧用户系统生效，配置后，推流端支持识别商户侧自有用户名及密码",
  Recommended_by_the_editorial_circle: "编辑圈子推荐",
  Please_enter_the_resource_name: "请输入资源名称",
  Circle_List_Recommendation: "圈子列表推荐",
  Recommended_by_Square_Circle: "广场圈子推荐",
  Please_enter_userid: "请输入userid",
  Please_enter_the_IP_address: "请输入IP",
  Search: "搜索",
  Refresh_IP_blacklist_cache: "刷新IP黑名单缓存",
  Reset: "重置",
  Loading: "加载中...",
  Merchant_ID_1: "商户编号",
  Prohibition_time_1: "封禁时间",
  Remove: "移除",
  hint_743: "确定要移除此IP吗？",
  APP_brand_name: "APP品牌名称",
  Default_username_prefix: "默认用户名前缀",
  Common_user_currency_name: "普通用户货币名称",
  Live_settlement_currency_name: "直播结算货币名称",
  The_name_of_the_settlement_currency_for_the_brocade_bag: "锦囊结算货币名称",
  Contact_customer_service: "联系客服",
  Assistant_default_icon: "小助手默认图标",
  Click_to_select_resources: "点击选取资源",
  hint_13: "只能上传svga文件，图片大小不能超过800k",
  Assistant_unread_message_icon: "小助手未读消息图标",
  Assistant_displays_icon_size: "小助手显示图标尺寸",
  Small: "小",
  Medium: "中",
  Big: "大",
  Assistant_display_time: "小助手展示时间",
  Online_customer_service_address: "在线客服地址",
  Initialize_account: "初始化账号",
  Anchor_tool_download_address: "主播工具下载地址",
  ICP_Filing_Information: "ICP备案信息",
  Live_broadcast_room_default_message: "直播间默认消息",
  Conversation_background: "语聊背景",
  Default_official_anchor_profile_picture: "默认官方主播头像",
  Default_official_message_avatar: "默认官方消息头像",
  Login_registration_page_background: "登录注册页面背景",
  hint_14: "支持格式：JPG、PNG、GIF、MP4，大小：3M以内",
  Delete: "删除",
  Triple_plot: "三倍图",
  Double_plot: "二倍图",
  Please_fill_in_the_brand_name: "请填写品牌名称",
  Please_fill_in_the_prefix: "请填写前缀",
  Please_fill_in_the_currency_name: "请填写货币名称",
  Please_fill_in_customer_service_WeChat: "请填写客服微信",
  Please_fill_in_the_download_address_for_the_anchor_tool:
    "请填写主播工具下载地址",
  Please_fill_in_the_ICP_information: "请填写ICP信息",
  Please_fill_in_the_default_message_for_the_live_broadcast_room:
    "请填写直播间默认消息",
  Please_fill_in_the_assistant_display_time: "请填写小助手展示时间",
  hint_15: "上传尺寸有误，请按格式要求上传!",
  hint_16: "只能上传只能上传png文件!",
  The_upload_size_cannot_exceed: "上传大小不能超过",
  hint_17: "传大小不能超过 800K!",
  Select: "选取",
  Please_upload_the_edited_Excel_template_file: "请上传编辑好的excel模板文件",
  Confirm: "确认",
  Importing: "导入中",
  Please_upload_the_file: "请上传文件",
  Only_xlsx_format_files_can_be_uploaded: "只能上传.xlsx格式文件!",
  hint_742: "上传图片大小不能超过 500MB!",
  Successfully_added: "添加成功",
  Import_member_failure_list: "导入会员失败名单",
  OK: "确定",
  Select_file: "选择文件",
  Please_enter_the_ID: "请输入id",
  User_nickname: "用户昵称",
  hint_740: "请输入用户昵称",
  User_phone_number: "用户手机号",
  hint_739: "请输入用户手机号",
  User_level: "用户等级",
  Please_enter_user_level: "请输入用户等级",
  Membership_level: "会员等级",
  Game_users: "游戏用户",
  Status: "状态",
  Registration_time: "注册时间",
  Identity: "身份",
  User_type: "用户类型",
  Inviter_userid: "邀请人userid",
  hint_738: "请输入邀请人userid",
  Registration_Platform: "注册平台",
  hint_741: "请输入注册平台网址",
  Batch_import_of_members: "批量导入会员",
  Download_batch_import_templates: "下载批量导入模板",
  Export_data: "导出数据",
  Total_number_of_registered_users: "总注册用户数",
  Number_of_registered_users_today: "今日注册用户数",

  // 错误开始
  nickname: "昵称",
  Avatar: "头像",
  Mobile_area_code: "手机区号",
  Mobile_phone_number: "手机号码",
  Third_party_binding: "第三方绑定",
  Recent_login_time: "最近登录时间",
  Recently_logged_in_IP: "最近登录IP",
  Last_visited_time: "最近访问时间",
  Anchor: "主播",
  Experts: "专家",
  Anchor_level: "主播等级",
  Ban: "封禁",
  Enable: "启用",
  Detailed: "详细",
  Prohibition: "禁言",
  Reset_password: "重置密码",
  Personal_information_1: "的个人资料",
  Mobile_phone_number_and_area_code: "手机号区号",
  Settings: "设置",
  Phone_number: "手机号",
  Please_enter_your_phone_number: "请输入手机号",
  User_status: "用户状态",
  Normal_1: "正常",
  hint_737: "是否主播",
  hint_736: "是否锦囊专家",
  Recommendation_code: "推荐码",
  Personal_signature: "个人签名",
  User_UID: "用户UID",
  Prohibition_period_: "禁言周期：",
  Release_the_ban: "解除禁言",
  Reasons_for_Prohibition: "禁言原因",
  Please_select_the_reason_for_the_ban: "请选择禁言原因",
  Delete_chat_messages: "删除聊天消息",
  Delete_all_chat_messages_in_the_live_broadcast_room: "删除所有直播间聊天消息",
  hint_735: "确定要封禁此账户吗？",
  Is_IP_banned_: "是否封禁IP",
  Current_level: "当前等级",
  Operation_time: "操作时间",
  Operator: "操作人",
  No: "否",
  Yes: "是",
  C_end_user_data: "c端用户数据",
  Batch_import_of_member_templates: "批量导入会员模板",
  Account_has_been_banned: "账户已封禁",
  hint_734: "确定要启用此账户吗？",
  Prompt: "提示",
  Account_enabled: "账户已启用",
  hint_733: "确认重置该用户密码吗？初始密码为：Aa123456",
  Password_reset_successful: "重置密码成功",
  Password_reset_failed: "重置密码失败",
  Download_the_homepage_logo_: "下载页首屏Logo：",
  Click_to_select_image: "点击选取图片",
  hint_20: "只能上传jpg/png文件，图片尺寸需为344X120，最多1张",
  hint_21: "只能上传jpg/png文件，图片尺寸需为288X100，最多1张",
  Download_the_top_screen_background_image: "下载页首屏背景图",
  hint_22: "只能上传jpg/png文件，图片尺寸需为375X732，最多1张",
  Download_the_top_screen_copy_image: "下载页首屏文案图",
  hint_23: "只能上传jpg/png文件，图片尺寸需为1980x1650，最多1张",
  Download_page_application_introduction_image: "下载页应用介绍图",
  hint_24: "只能上传jpg/png文件，图片尺寸需为360X842，最多4张",
  Download_page_application_introduction: "下载页应用介绍",
  Download_page_features: "下载页特色功能",
  PWA_configuration: "pwa配置",
  Only_json_files_can_be_uploaded: "只能上传.json文件",
  Download_floating_bar_description: "下载悬浮条描述",
  New_H5_logo: "新H5-logo",
  hint_732: "图片尺寸建议为40x40",
  New_H5_Download_floating_bar: "新H5-下载悬浮条",
  hint_731: "图片尺寸建议为400x60",
  Youmeng_Statistics: "友盟统计",
  Please_enter_the_URL_corresponding_to_src: "请输入src对应的url",
  hint_25: "如需统计多个域名，请用||区分",
  Baidu_Statistics: "百度统计",
  Version_download_address: "版本下载地址",
  Quick_Version: "快捷版",
  Please_enter_the_iOS_shortcut_address: "请输入iOS快捷版地址",
  Please_enter_Android_Scheme: "请输入Android Scheme",
  H5_Station_Download_Jump_Status: "H5站下载跳转状态",
  APP_H5_Album_Page_Name: "APP/H5专辑页名称",
  Please_enter_a_name: "请输入名称",
  APP_H5_Album_Page_Link: "APP/H5专辑页链接",
  Please_upload_pictures: "请上传图片",
  Please_upload_the_PWA_configuration_file: "请上pwa配置文件",
  Circle_: "圈子{num}",
  Please_enter_the_circle_ID: "请输入圈子ID",
  Please_fill_in_the_Android_app_download_address: "请填Android APP下载地址",
  Please_fill_in_the_iOS_app_download_address: "请填iOS APP下载地址",
  Please_fill_in_the_application_introduction: "请填写应用介绍",
  Please_fill_in_the_featured_features: "请填写特色功能",
  Please_fill_in_the_description_1: "请填写描述内容",
  hint_730: "请填写iOS稳定版下载地址",
  Please_fill_in_the_Android_Scheme: "请填写Android Scheme",
  Please_fill_in_the_iOS_Scheme: "请填写iOS Scheme",
  hint_18: "只能上传只能上传jpg、jpeg或png文件",
  Upload_up_to_1_image: "最多上传1张",
  hint_19: "只能上传只能上传jpg、jpeg或png文件!",
  The_upload_size_cannot_exceed_1_5M_: "上传大小不能超过 1.5M!",
  Only_json_format_files_can_be_uploaded_: "只能上传.json格式文件!",
  The_size_cannot_exceed_1MB_: "大小不能超过 1MB!",
  IOS_double_plot: "IOS二倍图",
  Protocol_identifier_ID: "协议标识ID",
  Protocol_identifier: "协议标识",
  Protocol_Name: "协议名称",
  Last_editing_date: "最后编辑日期",
  Release_status: "发布状态",
  H5_download_page_address: "H5下载页面地址",
  Application_Introduction: "应用介绍",
  Image_and_Text_Configuration: "图文配置",
  Page_: "第{num}张",
  Only_jpg_png_files_can_be_uploaded: "只能上传jpg/png文件",
  The_size_is_394_X798: "尺寸为394X798",
  Please_enter_the_application_introduction: "请输入应用介绍",
  Please_enter_the_address: "请输入下地址",
  The_title_of_the_th_page_cannot_be_empty: "第${num}张标题不能为空",
  Logo_icon: "logo-图标",
  Logo_Title: "logo-标题",
  The_recommended_image_size_is_140_x70: "图片尺寸建议为140x70",
  Navigation_bar_activity_map: "导航栏活动图",
  hint_307: "尺寸： 312*126，只能上传jpg/png文件",
  PC_download_copy: "PC下载文案",
  hint_26: "请输入第一行文案，不超过10个字符",
  hint_27: "请输入第二行文案，不超过10个字符",
  Page_title_: "页面title：",
  Please_enter_a_title: "请输入标题",
  Page_keywords: "页面关键词",
  Page_Description: "页面描述",
  Please_enter_a_description: "请输入描述",
  Please_enter_the_download_address_for_the_anchor_tool:
    "请输入主播工具下载地址",
  H5_download_page_address_1: "h5下载页地址",
  Please_enter_the_h5_download_page_address: "请输入h5下载页地址",
  Anchor_Recruitment_Description: "主播招聘描述",
  Please_enter_the_anchor_recruitment_description: "请输入主播招聘描述",
  Anchor_Recruitment_QR_Code: "主播招聘二维码",
  The_recommended_image_size_is_256x256: "图片尺寸建议为256x256",
  Contact_email_1: "联系邮箱",
  Please_enter_your_contact_email: "请输入联系邮箱",
  Bottom_copyright_information: "底部版权信息",
  Please_enter_copyright_information: "请输入版权信息",
  PC_Link_Configuration: "PC端链接配置",
  Please_enter_the_PC_domain_name_link_configuration: "请输入PC端域名链接配置",
  Site_icon: "站点icon",
  Click_to_select: "点击选取",
  hint_28: "只能上传ico文件，图片尺寸需为32x32，大小不超过20kb",
  Please_fill_in_the_statistics_link: "请填写统计链接",
  Customer_service_code_: "客服代码",
  Please_fill_in_the_customer_service_code: "请填写客服代码",
  PC_album_page_status: "PC专辑页状态",
  PC_album_page_link: "PC专辑页链接",
  Please_fill_in_the_title: "请填写标题",
  Please_fill_in_the_description: "请填写描述",
  Please_fill_in_the_keywords: "请填写关键词",
  Please_fill_in_the_record_number_document: "请填写备案号文案",
  Please_fill_in_your_email_address: "请填写邮箱",
  Please_fill_in_the_h5_download_page_address: "请填写h5下载页地址",
  Please_select_the_Feixin_QR_code_image: "请选择飞信二维码图片",
  Please_upload_the_logo_icon: "请上传logo图标",
  Please_upload_the_logo_title: "请上传logo标题",
  Please_enter: "请输入",
  Only_ICO_files_can_be_uploaded_: "只能上传ico文件!",
  hint_729: "上传图片大小不能超过 20KB!",
  Please_enter_the_theme_name: "请输入主题名称",
  Add_a_new_theme: "新增主题",
  There_is_currently_no_content: "暂无内容",
  Last_modification_time: "最后修改时间",
  Please_enter_sorting: "请输入排序",
  Are_you_sure_you_want_to_delete_it_: "是否确认删除？",
  Delete_successful: "删除成功",
  Theme: "主题",
  Publish: "发布",
  Please_select_a_theme: "请选择主题",
  Please_enter_the_content_of_the_agreement: "请输入协议内容",
  Please_enter_the_protocol_name: "请输入协议名称",
  hint_728: "是否确认发布协议信息",
  hint_727: "是否确认保存协议信息",
  User_Registration_Agreement: "用户注册协议",
  User_Privacy_Agreement: "用户隐私协议",
  Live_streaming_protocol: "直播协议",
  Payment_Agreement: "支付协议",
  Settlement_rules: "结算规则",
  Recharge_Service_Agreement: "充值服务协议",
  About: "关于",
  Please_select_the_protocol_type: "请选择协议类型",
  Login_Page_Logo: "登录页Logo",
  hint_29: "只能上传jpg/png文件，图片尺寸需为109X140，最多1张",
  Main_interface_logo: "主界面Logo",
  hint_30: "只能上传jpg/png文件，图片尺寸需为69X67，最多1张",
  About_Page_Logo: "关于页面Logo",
  hint_31: "只能上传jpg/png文件，图片尺寸需为109X109，最多1张",
  hint_32: "上传图片尺寸有误，请按格式要求上传!",
  Add_TF_package: "添加TF包",
  Package_name: "包名",
  Download_link: "下载链接",
  Developer_account: "开发者账号",
  Effective_date_1: "生效日期",
  Expiration_date: "失效日期",
  Remaining_valid_days: "剩余有效天数",
  Please_enter_valid_days: "请输入有效天数",
  Effective_days: "有效天数",
  Please_enter_the_package_name: "请输入包名",
  Edit_TF_package: "编辑TF包",
  Please_fill_in_the_download_link: "请填写下载链接",
  Please_select_the_effective_date: "请选择生效日期",
  hint_726: "有效天数必须大于等于90",
  Created_successfully: "创建成功",
  Update_method: "更新方式",
  System: "系统",
  Add_1: "添加",
  Update_type: "更新类型",
  Version_number: "版本号",
  Prompt_package: "提示包",
  Update_package: "更新包",
  Reminder_date: "提醒日期",
  Recently_edited_by: "最近编辑人",
  Last_edited_time: "最近编辑时间",
  Version_update: "版本更新",
  TF_Package_Update: "TF包更新",
  Download_address: "下载地址",
  Select_prompt_package: "选择提示包",
  Please_fill_in_the_download_address: "请填写下载地址",
  Select_Update_Package: "选择更新包",
  Remaining: "剩余",
  Heaven: "天",
  Start_reminder_date: "开始提醒日期",
  Update_content: "更新内容",
  Please_enter_the_updated_content: "请输入更新内容",
  hint_33: "支持格式：PNG、JPG，尺寸要求：750*1624",
  Edit_version_update: "编辑版本更新",
  Add_version_update: "添加版本更新",
  Please_set_an_updated_version: "请设置更新版本",
  Please_set_the_prompt_version: "请设置提示版本",
  Please_select_the_system: "请选择系统",
  Please_choose_a_method: "请选择方式",
  Please_enter_the_content: "请输入内容",
  Please_set_the_reminder_date: "请设置提醒日期",
  hint_725: "上传图片只能是 JPG 或 PNG 格式!",
  hint_724: "上传图片大小不能超过 1MB!",
  Modified_successfully: "修改成功",
  User_Management: "用户管理",
  IP_blacklist: "IP黑名单",
  Application_ID: "应用ID",
  Application_Name: "应用名称",
  Last_modified_date: "最后修改日期",
  Create_an_application: "创建应用",
  View_Key: "查看密钥",
  Module: "模块",
  Supplier: "供应商",
  Add_Module: "添加模块",
  Reset_Key: "重置密钥",
  New_Module: "新建模块",
  Please_enter_the_application_name: "请输入应用名称",
  Please_select_a_module: "请选择模块",
  Please_select_a_supplier: "请选择供应商",
  Please_enter_supplier_configuration: "请输入供应商配置",
  Edit_application: "编辑应用",
  hint_34:
    "若该应用有使用此模块的功能，删除记录可能会导致功能不可用。确认是否要删除？",
  Start_page: "启动页",
  Change: "变更",
  Only_JPG_or_PNG_format_images_are_allowed_to_be_uploaded:
    "只允许上传JPG格式或PNG格式图片",
  Are_you_sure_you_want_to_save_the_changes_: "是否确认保存变更",
  hint_320: "密码修改成功，请重新登录",
  Review_version: "审核版本",
  Please_enter_version: "请输入版本",
  Tag_number: "标记号",
  List_Fields: "列表字段",
  Form_Description: "表单说明",
  Remarks: "备注",
  Pre_explanation: "前置说明",
  hint_35:
    "1.针对ios端提审环境版本控制隐藏功能；2.代码层面规避明显做审核环境控制注释或代码；3.Android端无需控制",
  Version_control: "版本控制",
  hint_36:
    "1.显示当前需要控制的版本号，例如：当前需要提审的版本为V1.1，则输入框需输入1.1，且下方勾选对应需要隐藏的模块，则在V1.1版本里以下勾选的模块入口均隐藏。2.输入无实际版本的版本号保存，不影响显示任意版本模块；",
  Control_module: "控制模块",
  hint_317:
    "显示所需要再提审环境隐藏的功能名称，默认勾选状态，取掉勾选，且保存后，则在此版本中不隐藏此模块；",
  Please_fill_in_version_information: "请填写版本信息",
  Select_language: "选择语言",
  General_configuration: "通用配置",
  IOS_configuration: "IOS配置",
  H5_configuration: "H5配置",
  New_download_page_configuration: "新下载页配置",
  Streaming_tool_configuration: "推流工具配置",
  PC_configuration: "PC配置",
  Mobile_streaming_configuration: "移动推流配置",
  Advertising_restriction_configuration: "广告限制配置",
  Virtual_Number_Restrictions: "虚拟号码限制",
  Reporting_restrictions_for_third_party_data_apps: "三方数据APP上报限制",
  Report_domain_name_configuration: "上报域名配置",
  Please_enter_the_domain_name: "请输入域名",
  hint_723: "下载APP首次埋点上报",
  Channel_code_parameter_acquisition: "渠道码参数获取",
  PC_data_reporting: "PC数据上报",
  H5_data_reporting: "H5数据上报",
  Domain_Name_Address: "域名地址",
  Please_enter_the_domain_name_address: "请输入域名地址",
  Deactivate: "停用",
  Please_select_the_type: "请选择类型",
  Are_you_sure_to_enable_the_domain_name_: "确认启用域名吗？",
  hint_722: "确认停用域名吗？",
  Update_successful: "更新成功",
  Recommended: "推荐中",
  Paused: "已暂停",
  Picture_matching: "配图",
  Source: "来源",
  Feedback_type: "反馈类型",
  Number: "序号",
  Feedback_details: "反馈详情",
  Contact_information: "联系方式",
  Feedback_time: "反馈时间",
  Navigation_Station_Switch: "导航站开关",
  Page_Configuration: "页面配置",
  Weight: "权重",
  hint_37: "数字越大排名越靠前",
  Button_Name: "按钮名称",
  Domain_name: "域名",
  Please_enter_the_weight: "请输入权重",
  Please_enter_the_button_name: "请输入按钮名称",
  Mobile_related: "移动端相关",
  PC_related: "PC端相关",
  Personal_information: "个人信息",
  Login_account: "登录账号",
  User_Name: "用户姓名",
  Security_Settings: "安全设置",
  Change_password: "修改密码",
  Original_password: "原密码",
  New_password: "新密码",
  Confirm_new_password: "确认新密码",
  hint_721: "密码长度不能少于6位",
  The_passwords_entered_twice_do_not_match: "两次输入的密码不一致",
  Please_enter_your_password_again: "请再次输入密码",
  Merchant_Basic_Information: "商户基本信息",
  Merchant_ID: "商户ID",
  Enterprise_Name: "企业名称",
  Contact_person: "联系人",
  Contact_email: "联系人邮箱",
  Contact_phone_number: "联系人手机",
  SMS_verification_code: "短信验证码",
  Please_enter_the_merchant_ID: "请输入商户ID",
  Please_enter_the_company_name: "请输入企业名称",
  Please_enter_the_contact_person: "请输入联系人",
  Please_enter_the_contact_person_phone_number: "请输入联系人手机",
  Please_enter_the_contact_email: "请输入联系人邮箱",
  Please_enter_the_verification_code: "请输入验证码",
  Get_verification_code: "获取验证码",
  Please_enter_sensitive_words: "请输入敏感词",
  Batch_Add: "批量添加",
  Query_results: "查询结果",
  hint_720: "抱歉，没有找到该敏感词",
  Activity_Name: "活动名称",

  // 错误end====

  hint_718: "请输入敏感词且长度不能少于两个字符",
  hint_308: "确定删除该关键词？",
  continue_to_be_sent_on_the_platform: "解禁后则该词可继续在平台发送",
  Are_you_sure_to_lift_the_ban_: "确认解禁吗？",
  The_image_size_needs_to_be_40_x40: "图片尺寸需为40x40",
  Brand_name: "品牌名称",
  Main_site_name: "主站名称",
  Please_enter_the_name_of_the_main_site: "请输入主站名称",
  Main_site_redirect_URL: "主站跳转url",
  Please_enter_the_main_site_redirect_URL: "请输入主站跳转url",
  H5_Main_Station_Jump_URL: "H5主站跳转url",
  Please_enter_the_H5_main_site_redirect_URL: "请输入H5主站跳转url",
  Homepage_banner: "首页banner",
  hint_717: "图片尺寸需为1200x150",
  Homepage_banner_URL: "首页banner url",
  Please_enter_the_banner_URL_on_the_homepage: "请输入首页bannerUrl",
  Live_room_banner: "直播间banner",
  hint_716: "请输入直播间bannerUrl",
  Homepage_title: "首页-title",
  Please_enter_the_title: "请输入title",
  Home_page_description_1: "首页-description",
  Homepage_keywords_1: "首页-keywords",
  Please_enter_keywords_1: "请输入keywords",
  Live_room_title: "直播间-title",
  Live_broadcast_room_keywords_1: "直播间-keywords",
  Bottom_copyright_text: "底部版权文字",
  Please_enter_the_copyright_text_at_the_bottom: "请输入底部版权文字",
  APP_download_URL: "APP下载URL",
  Please_enter_the_APP_download_URL: "请输入APP下载URL",
  hint_715: "图片尺寸需为750x94",
  H5_Live_Banner: "H5直播banner",
  Please_enter_h5RoomBannerRedirectUrl: "请输入h5RoomBannerRedirectUrl",
  Please_enter_the_brand_name: "请输入品牌名称",
  Please_enter_the_main_site_redirect: "请输入主站跳转",
  Please_enter_H5_main_site_redirect: "请输入H5主站跳转",
  Please_enter_the_homepage: "请输入首页",
  Please_enter_the_homepage_banner_to_jump_to: "请输入首页 banner 跳转",
  Please_enter_the_homepage_description: "请输入首页描述",
  Please_enter_homepage_keywords: "请输入首页关键词",
  Please_enter_the_live_streaming_page: "请输入直播页",
  hint_714: "请输入直播页描述",
  Please_enter_keywords_for_the_live_streaming_page: "请输入直播页关键词",
  Please_enter_the_website_copyright_statement: "请输入站点版权申明",
  Please_enter_the_app_download_URL: "请输入app 下载 url",
  Please_enter_the_site_h5BannerRedirectUrl: "请输入站点h5BannerRedirectUrl",
  Add_Site: "添加站点",
  Edit_Site: "编辑站点",
  Upload_up_to_1_photo_: "最多上传1张!",
  Home_page_description: "首页description",
  Homepage_keywords: "首页keywords",
  Live_broadcast_room_title: "直播间title",
  Live_broadcast_room_description: "直播间-description",
  Live_broadcast_room_keywords: "直播间-keywords",
  App_download_URL: "App下载URL",
  H5_homepage_banner_URL: "h5首页bannerurl",
  H5_homepage_banner_redirect_URL: "h5首页banner跳转url",
  H5_live_broadcast_room_banner_URL: "h5直播间bannerurl",
  H5_Live_Room_Banner_Jump_URL: "h5直播间banner跳转url",
  Live_streaming_services_1: "直播服务",
  SMS_service: "短信服务",
  Certificate_file_name: "证书文件名",
  Certificate_type: "证书类型",
  General_Supplier_Configuration: "通用供应商配置",
  Whether_to_configure_TF_package_name: "是否配置TF包名",
  Package_Name_Configuration: "包名配置",
  Supplier_Configuration: "供应商配置",
  Edit_Supplier_Configuration: "编辑供应商配置",
  Add_supplier_configuration: "新增供应商配置",
  Please_fill_in_the_configuration_information: "请填写配置信息",
  hint_713: "是否确定删除配置？",
  Please_configure_TF_configuration_correctly: "请正确配置TF配置",
  Version_update_reminder: "版本更新提醒",
  TF_Package_Management: "TF包管理",
  Batch_upload: "批量上传",
  Posting_updates: "发布动态",
  Account: "账号",
  Content: "内容",
  Upload_images: "上传图片",
  Only_jpg_jpeg_png_files_can_be_uploaded: "只能上传jpg/jpeg/png文件",
  Up_to_9_images_can_be_uploaded: "最多支持上传9张",
  Click_to_select_file: "点击选取文件",
  Only_supports_video_format_files: "仅支持视频格式文件",
  Related_circles: "关联圈子",
  Please_select_a_circle: "请选择圈子",
  Related_topics: "关联话题",
  Please_select_a_topic: "请选择话题",
  Please_upload_the_video_file: "请上视频文件",
  Please_select_an_account: "请选择账号",
  hint_712: "上传图片只能是 JPG 或 PNG格式!",
  hint_707: "上传图片大小不能超过 10MB!",
  hint_710: "只能上传mp4格式文件!",
  hint_711: "大小不能超过 200MB!",
  Please_enter_your_nickname: "请输入昵称",
  Joined_the_circle: "已加入圈子",
  Import_time: "导入时间",
  Disabled: "禁用",
  Add_Circle: "添加圈子",
  Cannot_be_greater_than_80: "不能大于80",
  hint_38: "确认禁用该用户吗？禁用后将无法使用该账号发布动态？",
  hint_39: "确认启用该用户吗？启用后将可重新使用该账号发布动态？",
  Dynamic_configuration: "动态配置",
  Publisher_nickname: "发布人昵称",
  Accumulated_likes: "累计点赞数",
  Accumulated_number_of_comments: "累计评论数",
  Topic: "话题",
  Circle: "圈子",
  Release_time: "发布时间",
  Remove_this_comment: "下架此评论",
  Dynamic_permission_configuration: "动态权限配置",
  Publish_dynamic_user_level_configuration: "发布动态用户等级配置",
  Name: "名称",
  Level: "等级",
  hint_703: "此项配置为普通用户发布动态需达到的等级",
  Cannot_be_less_than_1: "不能小于1",
  Please_fill_in_the_user_level: "请填写用户等级",
  There_are_currently_no_related_posts: "暂无相关帖子",
  hint_702: "确认要下架此条帖子评论吗？",
  Comment_removed_successfully: "评论下架成功",
  hint_704: "确定要下架吗？",
  hint_705: "确定要上架吗？",
  Return: "返回",
  hint_706: "你没有权限去该页面",
  hint_309: "如有不满请联系你领导",
  Or_you_can_go: "或者你可以去",
  Go_back_to_the_homepage: "回首页",
  hint_40: "请检查您输入的网址是否正确，请点击以下按钮返回主页或者发送错误报告",
  Return_to_homepage: "返回首页",
  hint_41:
    "提示：1-权重为正整数，数值越大越靠前；2-默认前端展示，隐藏则前端不展示",
  Anchor_nickname: "主播昵称",
  Please_enter_sorting_weight: "请输入排序权重",
  Display: "显示",
  Hidden: "隐藏",
  Associated_channels_categories: "关联频道/分类",
  Anchor_Name_1: "主播名称",
  Please_enter_the_anchor_name_1: "请输入主播名称",
  Room_number: "房间号",
  Please_enter_the_room_number: "请输入房间号",
  Source_of_flow: "流来源",
  Anchor_profile_picture: "主播头像",
  Source_room_number: "来源房间号",
  Live_streaming_categories: "直播品类",
  Live_streaming_title: "直播标题",
  Stream_address: "流地址",
  Forbidden_broadcasting: "禁播",
  Live_streaming_1: "直播中",
  Ended: "已结束",
  Unlocking: "解禁",
  All: "全部",
  Douyu: "斗鱼",
  Tiger_Fang: "虎牙",
  hint_701: "是否确认解禁该主播？",
  hint_700: "是否确认禁播该主播？",
  Competition_time: "比赛时间",
  Competition_status: "比赛状态",
  Please_enter_keywords_2: "请输入关键字",
  League_Cup_Tournament: "联赛/杯赛",
  The_home_team: "主队",
  The_visiting_team: "客队",
  Event_ID: "赛事ID",
  Anchor_Qingliu_Live_Room: "主播清流直播间",
  Associated_Channel: "关联频道",
  Unknown_status: "状态未知",
  Not_started: "未开始",
  In_progress: "进行中",
  The_competition_has_been_postponed: "比赛推迟",
  Competition_cancelled: "比赛取消",
  Direct_room_number: "直推房间号",
  Progress_time: "进行时间",
  Live_streaming_status: "直播状态",
  Next_broadcast: "下播",
  Binding_successful: "绑定成功",
  hint_699: "确定将该直播下播吗？",
  Anchor_sorting: "主播排序",
  hint_42:
    "提示：1-权重为正整数，数值越大越靠前；2-默认前端显示，隐藏则前端不展示；",
  Update: "更新",
  Channel_sorting: "频道排序",
  Channel_nickname: "频道昵称",
  Secondary_classification: "二级分类",
  Live_streaming_type: "直播类型",
  hint_698: "关联多项，请使用英文逗号分割",
  Streaming_settings: "推流设置",
  End_all_live_broadcasts: "结束全部直播",
  Signal_source: "信号源",
  Push_flow_room: "推流房间",
  Streaming_Title: "推流标题",
  Push_Streaming_Channel: "推流频道",
  There_are_currently_no_channels_available: "当前暂无频道",
  Streaming_address: "推流地址",
  Streaming_address_server_address_: "推流地址（服务器地址）",
  Copy: "复制",
  Push_stream_code_stream_key_: "推流码（串流秘钥)",
  End_Live: "结束直播",
  hint_321: "如没有可使用的频道，请先：",
  Create_channel: "创建频道",
  You_are_about_to_use_it: "您即将用",
  Start_broadcasting: "进行开播",
  Start_live_streaming: "开始直播",
  Copy_successful: "复制成功",
  hint_697: "请填写完整信息再提交",
  hint_696: "确定要结束直播吗？",
  hint_695: "确定要结束全部直播吗？",
  Choose_a_live_broadcast_room: "选择直播间",
  Live_streaming_room: "直播间",
  League_name: "联赛名称",
  Please_enter_the_league_name: "请输入联赛名称",
  Popular: "热门",
  Normal: "普通",
  The_continent_where_it_is_located: "所在洲",
  Football: "足球",
  Basketball: "篮球",
  Date: "日期",
  Start_date: "开始日期",
  To: "至",
  End_date: "结束日期",
  In_the_competition: "比赛中",
  Delay: "推迟",
  Popular_events: "热门赛事",
  Keywords_1: "关键词",
  Please_enter_keywords: "请输入关键词",
  Batch_editing_of_popular_content: "批量编辑热门",
  Batch_creation_of_reserved_events: "批量创建预约赛事",
  Country_Region: "国家/地区",
  Current_round: "当前轮次",
  Edit_Appointment_Anchor: "编辑预约主播",
  Please_choose_to_create_a_schedule: "请选择创建赛程",
  Please_select_the_associated_channel: "请选择关联频道",
  Basketball_schedule_data: "篮球赛程数据",
  No_selected_items: "无选中项",
  Main_Station_Event_ID: "总台赛事ID",
  Please_enter_the_main_event_ID: "请输入总台赛事ID",
  Third_party_in_game_event_ID: "第三方赛中赛事ID",
  hint_694: "请输入第三方赛中赛事ID",
  There_are_streaming_anchors: "存在推流主播",
  There_is_a_push_flow_room: "存在推流房间",
  Click_on_the_nickname_modification_announcement: "（点击昵称修改公告）",
  Please_select_a_popular_event: "请选择热门赛事",
  Edit_successful: "编辑成功",
  E_sports_streaming: "电竞流",
  Entertainment_stream: "娱乐流",
  Create_Popular: "创建热门",
  Official_Clearflow: "官方清流",
  Anchor_Qingliu: "主播清流",
  Score: "比分",
  The_competition_team: "比赛球队",
  Create_circles: "创建圈子",
  Limit_1M: "限制1M",
  hint_900: "大小790*215px",
  Associate_Creator: "关联创建者",
  Operation_label: "运营标签",
  Add_tags: "新增标签",
  Confirm_creation: "确定创建",
  Label_: "标签{num}",
  Please_select_an_image: "请选择图片",
  Please_select_the_associated_creator: "请选择关联创建者",
  Publisher_userid: "发布人userid",
  Message_content: "消息内容",
  Chat_Room: "聊天室",
  hint_45: "删除后该消息将不在App端的该圈子内展示，确认删除吗？",
  Circle_Introduction: "圈子简介",
  Circle_name: "圈子名称",
  Circle_avatar: "圈子头像",
  Creator_1: "创建者",
  Number_of_people_in_the_circle: "圈子人数",
  The_maximum_number_of_people_in_the_circle: "圈子人数上限",
  Circle_Cover: "圈子封面",
  Circle_dynamics_1: "圈子动态",
  Circle_Announcement: "圈子公告",
  Creation_time: "创建时间",
  Dissolve_the_circle: "解散圈子",
  Dissolved: "已解散",
  Submission_dynamics: "投稿动态",
  User_userid: "用户userid",
  Number_of_comments: "评论数",
  Like_count: "点赞数",
  Preview: "预览",
  Setting_up_circle_announcements: "设置圈子公告",
  Add_Announcement: "添加公告",
  Please_enter_the_link_name: "请输入链接名称",
  Display_link: "展示链接",
  Jump_link: "跳转链接",
  Please_enter_the_jump_link: "请输入跳转链接",
  To_be_reviewed: "待审核",
  Listed: "已上架",
  Offline_1: "已下架",
  Rejected: "已拒绝",
  Deleted: "已删除",
  hint_692: "请填写完整公告链接信息再提交",
  The_circle_has_disbanded: "圈子已解散",
  Are_you_sure_you_want_to_dissolve_this_circle_: "是否确认要解散该圈子？",
  Dissolution: "解散",
  hint_44: "是否确认要{status}该条动态？",
  hint_693: "是否确认要拒绝该条动态？",
  Successfully: "已成功",
  Remove_from_shelves: "下架",
  Listing: "上架",
  Circle_dynamics: "圈子动态数",
  Creator_nickname: "创建者昵称",
  Creator_userid: "创建者userid",
  In_operation: "运营中",
  View_details: "查看详情",
  Chat_messages: "聊天消息",
  Edit_anchor_information: "编辑主播信息",
  Review: "审核",
  Anchor_Name: "主播姓名",
  Guild: "所在公会",
  Please_select_a_guild: "请选择公会",
  Split_ratio: "分成比例",
  Please_select_the_sharing_ratio: "请选择分成比例",
  Basic_salary: "底薪",
  Please_enter_the_base_salary: "请输入底薪",
  Review_details: "审核详情",
  Review_details_1: "详情",
  Please_enter_the_ID_number: "请输入身份证号码",
  Please_enter_the_host_nickname: "请输入主播昵称",
  hint_43: "确定把该用户从群主授权列表中删除吗？",
  Add_channel: "增加频道",
  Default_sorting: "默认排序",
  Channel_ID: "频道ID",
  Icon: "图标",
  Page_template: "页面模板",
  Tab_name: "tab名称",
  Channel_Type: "频道类型",
  hint_46: "只能上传jpg/png文件，图片尺寸需为120X120",
  Please_enter_a_valid_tab_name_: "请输入有效的tab名称！",
  Enter_tab_name: "输入tab名称",
  Content_ID: "内容ID",
  Please_enter_ID: "请输入ID",
  Topped: "已置顶",
  Top: "置顶",
  Cancel_Topping: "取消置顶",
  Restore_Display: "恢复显示",
  Expiration_time: "失效时间",
  Select_date_and_time: "选择日期时间",
  Sealing_the_picture: "封图",
  real_time_info: "资讯",
  Short_video: "短视频",
  Dynamic: "动态",
  Brocade_Bag: "锦囊",
  The_expiration_time_has_not_been_set_yet: "还未设置失效时间",
  Please_select_a_future_time: "请选择未来时间",
  Homepage_Content_Management: "首页内容管理",
  Top_management_on_homepage: "首页置顶管理",
  Please_enter_information_keywords: "请输入资讯关键字",
  Keywords: "关键字",
  Batch_publishing: "批量发布",
  Batch_scheduled_release: "批量定时发布",
  Thumbnails: "缩略图",
  Title_1: "标题",
  Crawling_time: "爬取时间",
  hint_47:
    "你可以选择5分钟后，今后一个星期内的任意时间定时发布，在设定时间之前可以重新编辑和取消",
  Timed_release: "定时发布",
  hint_48: "发布后无法进行修改和撤销，是否确认发布？",
  hint_691: "未选择任何资讯，请选择资讯后再发布！",
  Please_choose_a_reasonable_publishing_time: "请选择合理的发布时间",
  Edit_information: "编辑资讯",
  New_information: "新增资讯",
  Please_fill_in_the_necessary_title_and_author: "请填写必要的标题和作者",
  Information_Title: "资讯标题",
  Comment_content: "评论内容",
  hint_49: "删除后该评论将不在APP端、PC端内展示，确定删除吗？",
  hint_50: "确定取消发布吗？取消后该条资讯将进入草稿箱",
  hint_51: "确定下架吗？下架后移动端将不出现此条资讯！",
  Cancel_Top_Time: "取消置顶时间",
  Publishing_Library: "发布库",
  Top_Library: "置顶库",
  Crawling_website_titles: "爬取网站标题",
  Crawling_website_links: "爬取网站链接",
  Crawling_time_period: "爬取时段",
  Set_crawling_time_period: "设置爬取时间段",
  End_time: "结束时间",
  Select_a_time_range: "选择时间范围",
  hint_52: "禁用后则不爬取该网站数据，是否确认禁用？",
  hint_690: "确定要启用此配置吗？",
  hint_689: "确定要删除此条资讯吗？",
  Prize_Name: "奖品名称",
  Please_enter_the_name_of_the_prize: "请输入奖品名称",
  Prize_type: "奖品类型",
  Query: "查询",
  Add_prizes_1: "新增奖品",
  Prize_icon: "奖品icon",
  Please_enter_the_number_of_prizes: "请输入奖品数量",
  quantity: "数量",
  Only_jpg_png_gif_files_can_be_uploaded: "只能上传jpg/png/gif文件",
  Modify: "修改",
  New: "新增",
  Virtual_currency: "虚拟货币",
  Experience_value: "经验值",
  hint_53: "上传图片只能是 JPG 或 PNG 或 GIF格式!",
  Winning_number: "中奖编号",
  Please_enter_the_winning_number: "请输入中奖编号",
  UID: "UID",
  Please_enter_UID: "请输入UID",
  Obtain_time: "获得时间",
  Export: "导出",
  hint_688: "直播间砸金蛋-奖品记录",
  Basic_Settings: "基本设置",
  Red_envelope_rain_name: "红包雨名称",
  Edit_Red_Envelope_Rain_Library: "编辑红包雨库",
  New_Red_Envelope_Rain_Warehouse: "新建红包雨库",
  Please_enter_the_name_of_the_red_envelope_rain: "请输入红包雨名称",
  Trigger_type: "触发类型",
  Goal_scoring_events: "进球事件",
  Event_timeline: "赛事时间点",
  Specify_a_time_point: "指定时间点",
  Maximum_number_of_red_envelope_rain_wheels: "红包雨轮数上限",
  Please_enter_the_number_of_rounds: "请输入轮数",
  hint_54: "0为不下红包雨，此为一场比赛最多红包雨轮数的上限",
  hint_687: "最多中红包个数(单个用户单轮)",
  hint_55: "0为抢不到红包，达到红包个数数后则不会再抢到红包",
  Fixed_point_in_time: "固定时间点",
  Select_date: "选择日期",
  Probability_of_winning_a_prize_red_envelope: "抽中有奖红包概率",
  Please_enter_the_probability: "请输入概率",
  hint_56: "0为不可能抽中红包，此为用户抽中有奖红包的概率",
  hint_686: "请输入红包雨轮数上限",
  Award_Pool_Settings: "奖池设置",
  Add_prizes: "添加奖品",
  Number_of_individual_red_envelope_rewards: "单个红包奖励个数",
  Please_enter_the_number: "请输入个数",
  Number_of_red_envelopes: "红包数量",
  Please_enter_the_quantity: "请输入数量",
  Prize_weight: "奖品权重",
  Please_fill_in_the_fixed_time_point: "请填写固定时间点",
  Please_select_a_time_point_for_the_competition: "请选择赛事时间点",
  hint_685: "仅可配置0 - 100的正整数数字",
  Please_add_at_least_one_prize: "请至少添加一条奖品",
  hint_684: "请填写完整奖品信息再提交",
  hint_57: "输入有误，单个红包奖励个数、红包数量仅可配置1 - 99999的正整数数字",
  Add_has_reached_the_limit: "添加已达到上限",
  Golden_Egg_Switch: "金蛋开关",
  Basic_Information_Configuration: "基本信息配置",
  APP_background_image: "APP背景图",
  APP_Title_Image: "APP标题图",
  The_total_probability_must_be_10000: "概率总和必需为10000",
  Red_envelope_rain_storage_details: "红包雨库详情",
  hint_58: " 0为不下红包雨，此为一场比赛最多红包雨轮数的上限",
  Wheel: "轮",
  Pieces: "个",
  Task_type: "任务类型",
  Reward_Name: "奖励名称",
  Task_Title: "任务标题",
  Task_icon: "任务icon",
  Task_count: "任务次数",
  Application_channels: "应用渠道",
  Edit_task: "编辑任务",
  Reward_type: "奖励类型",
  Reward_quantity: "奖励数量",
  Please_enter_the_number_of_rewards: "请输入奖励数量",
  Please_select_the_reward_type_icon: "请选择奖励类型icon",
  Please_select_the_application_channel: "请选择应用渠道",
  Task_title_cannot_be_empty: "任务标题不能为空",
  Task_count_cannot_be_empty: "任务次数不能为空",
  To_be_effective: "待生效",
  In_effect: "生效中",
  Daily_tasks: "每日任务",
  Novice_Task: "新手任务",
  Sign_in_task: "签到任务",
  Attendance_Record: "签到记录",
  Please_enter_the_task_title: "请输入任务标题",
  Check_in_switch: "签到开关",
  No_1: "第",
  Number_of_check_in_days: "签到天数",
  Reward_details: "奖励明细",
  Reward_icon: "奖励icon",
  Edit_check_in: "编辑签到",
  Lottery: "彩金",
  Physical_object: "实物",
  Please_upload_the_reward_type_icon: "请上传奖励类型icon",
  Sign_in_has_been_closed: "已关闭签到",
  Check_in_has_been_opened: "已打开签到",
  Please_fill_in_the_quantity: "请填写数量",
  Please_enter_the_room_number_for_sending: "请输入发送房间号",
  Send_room_number: "发送房间号",
  Sending_time: "发送时间",
  Details_of_receiving_red_envelopes: "红包领取详情",
  hint_683: "暂无红包领取详情",
  Anchor_room_number: "主播房间号",
  Please_enter_the_host_room_number: "请输入主播房间号",
  Remove_permissions: "移除权限",
  Add_anchor: "添加主播",
  Time: "时间",
  Anchor_userID: "主播userID",
  Please_enter_the_anchor_userID: "请输入主播userID",
  Betting_amount: "投注金额",
  Prophecy_betting_list: "预言投注列表",
  Please_enter_the_name_of_the_main_team: "请输入主队名称",
  Please_enter_the_name_of_the_visiting_team: "请输入客队名称",
  Please_enter_the_status: "请输入状态",
  Team_PK_ID: "球队PK ID",
  The_winner: "胜方",
  Number_of_gift_givers_1: "送礼人数",
  Total_value_in_gold_coins: "总价值(金币)",
  The_losing_side: "败方",
  Binding_events: "绑定赛事",
  Please_enter_the_binding_event: "请输入绑定赛事",
  Bind_anchor: "绑定主播",
  Please_enter_the_bound_anchor: "请输入绑定主播",
  Activity_Strategy_Name: "活动策略名称",
  hint_682: "请输入活动策略名称",
  Red_envelope_rain_claim_details: "红包雨领取详情",
  Effective_anchor_room_details: "生效主播房间详情",
  To_be_published: "待发布",
  Published: "已发布",
  Canceled: "已取消",
  hint_681: "是否确认取消发布",
  Main_switch: "总开关",
  Policy_Name: "策略名称",
  Please_enter_a_policy_name: "请输入策略名称",
  Add_Policy: "添加策略",
  Red_envelope_rain_storage: "红包雨库",
  Red_envelope_rain_type: "红包雨类型",
  Event_red_envelope_rain: "赛事红包雨",
  Live_broadcast_room_chat_room: "直播间/语聊室",
  Event_type: "赛事类型",
  Distribute_users: "下发用户",
  hint_60: "请输入用户UID，多个UID使用英文逗号隔开",
  Please_select_the_type_of_red_envelope_rain: "请选择红包雨类型",
  Please_select_an_event: "请选择赛事",
  Please_select_the_red_envelope_rain_storage: "请选择红包雨库",
  Edit_Policy: "编辑策略",
  Strategy_details: "策略详情",
  hint_61: "下线该活动策略将导致该活动从前端下线，确定下线吗",
  Offline_2: "下线",
  Live_room_room_number: "直播间房间号",
  hint_680: "请输入直播间房间号",
  Configuration_Management: "配置管理",
  Prize_configuration: "奖品配置",
  Prize_Record: "奖品记录",
  Anchor_cumulative_share: "主播累计分成",
  Option_A_cumulative_betting: "选项A累计投注",
  Option_A: "选项A",
  Option_B: "选项B",
  Option_B_cumulative_betting: "选项B累计投注",
  Results: "结果",
  Number_of_bets: "投注人数",
  Anchor_sharing_3: "主播分成（3%）",
  Platform_share_2: "平台分成（2%）",
  The_anchor_prophecy: "主播预言",
  The_prophecy_of_the_whole_nation: "全民预言",
  Login_time: "登录时间",
  Online_duration: "在线时长",
  Inviters_nickname: "邀请人昵称",
  Invitee_UID_: "被邀请人UID：",
  Invitation_time: "邀请时间",
  Inviter_payment_method: "邀请人收款方式",
  Alipay: "支付宝",
  WeChat_account: "微信号",
  QQ_account: "QQ账号",
  Inviting_person_rewards: "邀请人奖励",
  Distributed: "已派发",
  Not_passed: "未通过",
  Accumulated: "累计中",
  Invitee_nickname: "被邀请人昵称",
  Invitee_rewards: "被邀请人奖励",
  Select_distribution_type: "选择派发类型",
  Please_enter_a_rule_description: "请输入规则说明",
  Are_you_sure_to_cancel_the_distribution_: "确认取消派发吗？",
  APP_user_login_statistics: "APP用户登录统计",
  hint_676: "邀请人奖励不能小于0",
  hint_677: "邀请人奖励不能大于99999",
  hint_675: "被邀请人奖励不能小于0",
  hint_678: "被邀请人奖励不能大于99999",
  Room_type: "房间类型",
  The_ban_was_successful: "禁言成功",
  hint_62:
    "删除后该消息将不在App端、PC端、H5端的该直播间/聊天室内展示，确定隐藏吗？",
  Please_enter_the_user_UID: "请输入用户UID",
  Prohibition_time: "禁言时间",
  Forbidden_live_broadcast_room: "禁言直播间",
  Prohibition_type: "禁言类型",
  Prohibition_reasons: "禁言理由",
  Operator_UID: "操作人UID",
  Successfully_unblocked: "解禁成功",
  Opening_duration: "开播时长",
  The_duration_of_the_current_live_broadcast: "当前直播的直播时长",
  Current_number_of_views: "当前浏览次数",
  hint_674: "当前直播的浏览次数（去重）",
  Current_number_of_online_users: "当前在线人数",
  hint_673: "当前直播的在线观看人数（去重）",
  The_highest_number_of_online_users: "最高在线人数",
  hint_672: "当前直播的最高在线人数（去重）",
  hint_63: "统计包含未登录用户，未登录用户未去重。",
  Field_Description: "字段说明",
  Anchor_UID: "主播UID",
  Live_Channel: "直播频道",
  Start_time_1: "开播时间",
  Downtime: "下播时间",
  Opening_duration_minutes_: "开播时长(分钟)",
  Accumulated_views: "累计浏览次数",
  Accumulated_number_of_online_users: "累计在线人数",
  Average_viewing_time_minutes_: "平均观看时长(分钟)",
  The_entire_live_broadcast_data: "整场直播数据",
  Edit_data: "编辑资料",
  Add_announcement_link: "添加公告链接",
  Announcement_link: "公告链接",
  Please_enter_the_display_link: "请输入展示链接",
  Recommended_display: "推荐展示",
  Browser_Open: "浏览器打开",
  Display_end: "显示端",
  Mobile_end: "移动端",
  PC_end: "PC端",
  Application_time: "申请时间",
  Global_announcement_adjustment: "全局公告调整",
  Sports_type: "体育类型",
  On_the_front_of_the_ID_card: "身份证正面",
  On_the_back_of_the_ID_card: "身份证反面",
  Holding_an_ID_card: "手持身份证",
  Reason_for_rejection: "拒绝原因",
  Lifting_the_ban: "解除封禁",
  Refused_to_pass: "拒绝通过",
  Please_enter_the_anchor_name: "请输入主播姓名",
  ID_number: "身份证号",
  Front_photo_of_ID_card: "身份证正面照",
  Back_photo_of_ID_card: "身份证反面照",
  Housing_Management_Uid: "房管Uid",
  Please_enter_the_housing_management_Uid: "请输入房管Uid",
  Housing_management_nickname: "房管昵称",
  hint_671: "请输入房管昵称",
  Add_property_management: "添加房管",
  Cancel_housing_management: "取消房管",
  Please_enter_your_name: "请输入姓名",
  hint_670: "姓名请输入汉字",
  Approved: "审核通过",
  Rejected_for_review: "审核拒绝",
  Prohibited: "已封禁",
  The_ID_photo_is_blurry: "证件照模糊",
  The_ID_number_does_not_match_the_ID_photo: "身份证号码与证件照不匹配",
  My_ID_card_does_not_match_me: "身份证与本人不匹配",
  ID_card_number_is_blocked: "身份证号码遮挡",
  Facial_occlusion: "人脸遮挡",
  Room: "房间",
  Please_select_the_reason: "请选择原因",
  hint_669: "请输入要替换的公告名称或链接",
  Are_you_sure_you_want_to_pass_the_review_: "确定要审核通过吗？",
  hint_301: "确定恢复该主播直播权限吗？",
  Are_you_sure_you_want_to_ban_this_anchor_: "确定将该主播封禁吗？",
  Main_account_UID: "主账号UID",
  Please_enter_the_main_account_UID: "请输入主账号UID",
  Merge_account_UID: "合并账号UID",
  Please_enter_the_merged_account_UID: "请输入合并账号UID",
  Add_Merge: "新增合并",
  Master_account_nickname: "主账号昵称",
  Merge_account_nicknames: "合并账号昵称",
  Merge_time: "合并时间",
  hint_64: "合并账号后，被合并主播账号开播，将使用主账号进行上播展示",
  Merge_accounts: "合并账号",
  Refresh: "刷新",
  hint_668: "注：在线数据略有延迟",
  Note: "注",
  Basic_information_1: "基础信息",
  Anchor_Information: "主播信息",
  Real_time_live_streaming_data: "实时直播数据",
  hint_667: "当前流信息不可用！",
  Anchor_Management: "主播管理",
  Merge_anchor_accounts: "主播账号合并",
  Identify_the_main_switch: "鉴别总开关",
  The_current_status_is: "当前状态为",
  There_is_currently_no_data_available: "暂无数据",
  Jian_Huang: "鉴黄",
  Fear_prevention: "鉴恐",
  Jianzheng: "鉴政",
  hint_303: "确定要将此直播间断流吗？",
  Thermal_value_coefficient: "热力值系数",
  Heat_value_base: "热力值基数",
  Modified_by: "修改人",
  Latest_modification_time: "最新修改时间",
  Heat_value_setting: "热力值设置",
  Role_Name: "角色名称",
  Whether_to_enable: "是否启用",
  Live_broadcast_room_type: "直播间类型",
  Initial_heat_value: "初始热力值",
  Last_editing_time: "最后编辑时间",
  Edit_heat_value: "编辑热力值",
  Initial_heating_value_: "初始热力值：",
  Please_enter_the_heat_value: "请输入热力值",
  Please_fill_in_the_heat_value: "请填写热力值",
  Please_enter_the_anchor_UID: "请输入主播UID",
  Live_streaming_services: "直播流服务",
  Batch_editing: "批量编辑",
  All_anchors: "全部主播",
  Platform_anchor_stream: "平台主播流",
  Event_clearance: "赛事清流",
  Anchor_event_flow: "主播赛事流",
  Esports_Entertainment_Stream: "电竞娱乐流",
  Live_streaming_2: "正在直播",
  Field: "场",
  hint_665: "确定要将此直播间下播吗？",
  hint_664: "确定要将此直播间禁播吗？",
  This_PV: "本场PV",
  UV_in_this_field: "本场UV",
  Peak_value: "峰值",
  Accumulated_viewing_time_minutes_: "累计观看时长(分钟)",
  Total_number_of_followers: "总关注数",
  Number_of_followers_in_this_session: "本场关注数",
  The_number_of_levels_taken_in_this_game: "本场取关数",
  Net_increase_in_levels_in_this_game: "本场净增关数",
  Live_broadcast_session_data_of_anchors: "主播直播场次数据",
  Forbidden_List: "禁言列表",
  Team: "球队",
  Please_enter_the_team: "请输入球队",
  Push_order_status: "推单状态",
  hint_306: "确认要隐藏该推单吗？隐藏后前端不可见",
  hint_305: "确认要显示该推单吗？显示后前端可见",
  Please_select_the_correction_result: "请选择修正结果",
  Reporting_time: "举报时间",
  Whistleblower_userid: "举报人userid",
  Enter_the_whistleblower_userid: "输入举报人userid",
  Enter_room_number: "输入房间号",
  Reply_or_not: "是否回复",
  Reason_for_reporting: "举报原因",
  Reply_content: "回复内容",
  hint_663: "请输入回复内容(100字以内)",
  The_reply_content_cannot_be_empty: "回复内容不能为空",
  Homepage_Configuration: "首页配置",
  Level_animation_switch: "等级动画开关",
  Cat_coin_animation_switch: "猫币动画开关",
  Enter_animation_switch_: "进场动画开关：",
  APP_membership_background: "APP会员背景",
  Membership_Benefits_Background: "会员权益背景",
  APP_H5_Membership_Benefits_Background: "APP/H5会员权益背景",
  Background_of_PC_Membership_Benefits: "PC会员权益背景",
  hint_409: "注：尺寸为926x126",
  VIP_discount_configuration: "vip折扣配置",
  Please_enter_VIP: "请输入VIP",
  hint_402: "请输入vip折扣（例如：0.9）",
  hint_403: "请输入vip折扣名称（例如：9折）",
  Edit_Channel: "编辑频道",
  Add_a_new_channel: "新增频道",
  Channel_avatar: "频道头像",
  hint_401: "尺寸：114*114 只能上传jpg/png文件",
  Channel_Cover: "频道封面",
  hint_405: "尺寸：522*294 只能上传jpg/png文件",
  hint_406: "提示：自动截取流画面，失败后则使用默认封面",
  hint_407: "确定恢复该频道直播权限吗？",
  hint_408: "确定将该频道封禁吗？",
  Schedule_data: "赛程数据",
  Edit_Announcement: "编辑公告",
  Create_Announcement: "创建公告",
  Announcement_type: "公告类型",
  Image_and_text_links: "图文链接",
  Picture_and_text_messages: "图文消息",
  Activity_link: "活动链接",
  Announcement_Title: "公告标题",
  Cover_image: "封面图",
  Whether_to_push: "是否推送",
  Select_content: "选择内容",
  Activity_Title: "活动标题",
  Event_Introduction: "活动简介",
  Please_select_the_announcement_type: "请选择公告类型",
  Please_select_the_publishing_time: "请选择发布时间",
  Please_enter_the_announcement_title: "请输入公告标题",
  Please_enter_the_activity_title: "请输入活动标题",
  Please_enter_the_announcement_content: "请输入公告内容",
  Please_fill_in_the_activity_introduction: "请填写活动简介",
  Please_enter_the_text_message_content: "请输入短信内容",
  Send_to: "发送对象",
  Upload_data: "上传数据",
  Download_List_Excel_Template: "下载名单Excel模板",
  Receive_user_list: "接收用户列表",
  All_platform_users: "全平台用户",
  Not_sent: "未发送",
  Sent: "已发送",
  Cancel_sending: "取消发送",
  Specify_user: "指定用户",
  Please_enter_the_theme: "请输入主题",
  Multiple_separated_by_commas: "多个以逗号隔开",
  Template_ID: "模板ID",
  Instant_sending: "即时发送",
  Please_select_template_ID: "请选择模板ID",
  Are_you_sure_to_send_to_all_users_: "是否确认向所有用户发送？",
  Sending_successful: "发送成功",
  Channel_template_ID: "渠道方模板ID",
  Please_enter_the_channel_template_ID: "请输入渠道方模板ID",
  Template_content: "模板内容",
  Please_enter_template_content: "请输入模板内容",
  Please_select_a_channel: "请选择渠道",
  Please_enter_your_signature: "请输入签名",
  Alibaba_Cloud: "阿里云",
  Notification: "通知",
  Marketing: "营销",
  Verification_code: "验证码",
  First_select_the_SMS_type: "先选择短信类型",
  Please_fill_in_the_channel_template_ID: "请填写渠道方模板ID",
  Message_type: "消息类型",
  New_announcement: "新增公告",
  Official_announcement_title: "官方公告标题",
  Unpublish: "取消发布",
  Jump_type: "跳转类型",
  Push_time: "推送时间",
  Please_enter_the_bag_ID: "请输入锦囊ID",
  Decimal_cannot_be_entered: "不能输入小数",
  Please_enter_the_H5_link: "请输入H5链接",
  Push_details: "推送详情",
  Edit_push: "编辑推送",
  Add_push: "新增推送",
  Push_order_page: "推单页",
  Please_select_the_push_time: "请选择推送时间",
  Please_select_the_effective_time: "请选择生效时间",
  hint_660: "确定撤销本次推送任务吗？",
  Add_menu: "新增菜单",
  catalogue: "目录",
  Menu: "菜单",
  Menu_icons: "菜单图标",
  Menu_Title: "菜单标题",
  Routing_address: "路由地址",
  Component_Name: "组件名称",
  Component_path: "组件路径",
  Whether_to_hide: "是否隐藏",
  Superior_categories: "上级类目",
  Select_superior_category: "选择上级类目",
  Menu_Name: "菜单名称",
  Permission_identification: "权限标识",
  Edit_menu: "编辑菜单",
  Please_enter_the_menu_title: "请输入菜单标题",
  Please_enter_the_routing_address: "请输入路由地址",
  Please_enter_the_component_name: "请输入组件名称",
  Please_enter_the_component_path: "请输入组件路径",
  Please_select_the_superior_category: "请选择上级类目",
  Top_level_categories: "顶级类目",
  hint_661: "确定要删除此菜单吗？",
  Please_enter_the_role_name: "请输入角色名称",
  Add_new_role: "新增角色",
  Role_ID: "角色ID",
  Creator: "创建人",
  Authorization: "授权",
  Edit_Role: "编辑角色",
  Audit_scenario: "审核场景",
  Please_enter_the_review_scenario: "请输入审核场景",
  Review_page: "审核页面",
  Edit_audit_scenario_account: "编辑审核场景账号",
  Operations_Manager_Account: "运营负责人账号",
  Please_enter_the_review_account: "请输入审核账号",
  Please_enter_your_account: "请输入账号",
  New_users: "新增用户",
  Bind_characters: "绑定角色",
  Latest_reset_time: "最新重置时间",
  Please_select_a_role: "请选择角色",
  Input_cannot_be_empty: "输入不能为空",
  Edit_Users: "编辑用户",
  hint_659: "确定要停用此账户吗？",
  Account_deactivated: "账户已停用",
  hint_658: "确定要重置此账号密码吗？",
  Reset_successful: "重置成功",
  The_new_password_is: "新密码为",
  Gift_Name: "礼物名称",
  Please_enter_the_gift_name: "请输入礼物名称",
  Gift_category: "礼物类别",
  VIP_gift: "VIP礼物",
  Add_gift: "添加礼物",
  Gift_unit_price_in_gold_coins_: "礼物单价(金币)",
  Banner_gift: "横幅礼物",
  Full_screen_gift: "全屏礼物",
  Gift_icon: "礼物图标",
  Size_does_not_exceed_1MB_: "大小不超过1MB！",
  Enable_floating_screen: "启用飘屏",
  Floating_screen_category: "飘屏类别",
  hint_657: "请选择飘屏类别",
  Please_select_VIP_level: "请选择VIP等级",
  hint_656: "该等级及以上VIP用户可使用",
  hint_601: "最多可输入4个中文汉字或者8个英文字母或者8个数字",
  Please_enter_the_price: "请输入价格",
  hint_602: "礼物单价不能大于9999999或小于0",
  Please_upload_the_gift_icon: "请上传礼物图标",
  Please_upload_the_playback_effect: "请上传播放效果",
  Please_upload_the_preview_effect: "请上传预览效果",
  Edit_Gift: "编辑礼物",
  Please_choose_a_gift_type: "请选择礼物类型",
  Please_select_the_floating_screen_type: "请选择飘屏类型",
  Please_select_whether_combo_is_possible: "请选择是否可连击",
  Only_SVGA_files_can_be_uploaded_: "只能上传svga文件!",
  Only_GIF_files_can_be_uploaded: "只能上传gif文件",
  hint_66:
    "下线该礼物则该礼物从礼物仓库中下线，即配置礼物策略中不出现该礼物，确定下线吗？",
  Successfully_taken_offline: "下线成功",
  hint_67:
    "上线该礼物则该礼物重新上线至礼物仓库，即配置礼物策略中重新出现该礼物，确定上线吗",
  Gift_data_statistics: "礼物数据统计",
  User_gift_details: "用户送礼明细",
  Gift_List: "礼物列表",
  Distribution_scope: "下发范围",
  Effective_time: "生效时间",
  Last_modified_time: "最近修改时间",
  Last_Editor: "最后编辑人",
  hint_68:
    "请输入礼物ID，多个礼物之间使用英文逗号隔开，输入顺序则为前端礼物展示顺序",
  Sending_gifts: "下发礼物",
  hint_72: "删除该礼物策略将导致该策略删除，确定删除吗？？",
  hint_70: "下线该礼物策略将导致该策略房间的礼物从前端下线，确定下线吗？",
  hint_71: "上线该礼物策略则该策略房间的礼物从前端上线，确定上线吗？",
  Expert_type: "专家类型",
  Import_experts: "导入专家",
  Introduction: "简介",
  Hit_rate: "命中率",
  Recent_status: "近期状态",
  The_highest_consecutive_red: "最高连红",
  hint_604: "近期连红",
  Number_of_proposals: "方案数量",
  Number_of_buyers: "购买人数",
  Accumulated_total_revenue: "累计总收益",
  Settlable_income: "可结算收益",
  Add_Expert: "添加专家",
  User_userid_cannot_be_empty: "用户userid不能为空",
  Please_enter_the_league_and_team_name: "请输入联赛、球队名称",
  Home_team_vs_away_team: "主队VS客队",
  League: "联赛",
  Choose_a_competition: "选择赛事",
  Event_information: "赛事信息",
  Select_recommendation: "选择推荐",
  Yazhi: "亚指",
  The_main_winner: "主胜",
  Customer_win: "客胜",
  Small_and_large_balls: "大小球",
  Greater_than: "大于",
  ball: "球",
  European_Compensation: "欧赔",
  A_draw: "平局",
  Wanbo: "万博",
  Le_Dong: "乐动",
  Macau: "澳门",
  crown: "皇冠",
  hint_655: "请先选择玩法",
  Add_recommendation_group: "添加推荐组",
  Recommended_group_name: "推荐组名称",
  Circle_details: "圈子明细",
  Number_of_recommended_circles: "推荐圈子数量",
  Resource_bit_name: "资源位名称",
  Expert_details: "专家明细",
  Number_of_recommended_experts: "推荐专家数量",
  Pause: "暂停",
  Add_anchors: "增加主播",
  X_connected_red: "X连红",
  Near_X_in_X: "近X中X",
  Return_rate: "回报率",
  hint_653: "请填写至少一条完整主播信息",
  hint_654: "确定推荐该组吗？",
  hint_652: "确定暂停该推荐组吗？",
  Up_to_20_can_be_configured: "最多可以配置加20个",
  Edit_selling_price: "编辑售价",
  First_gear: "第一档",
  Second_gear: "第二档",
  Third_gear: "第三档",
  Release_Brocade_Bag: "发布锦囊",
  Brocade_Bag_Type_: "锦囊类型：",
  Football_recommendations: "足球推荐",
  Basketball_recommendations: "篮球推荐",
  hint_651: "请选择赛事设置推荐选项",
  Please_enter_the_publisher_account: "请输入发布者账号",
  Please_enter_the_recommendation_reason: "请输入推荐理由",
  Please_set_the_publishing_time: "请设置发布时间",
  hint_650: "已结束比赛需要选择发布时间",
  Expert_management: "专家管理",
  Popular_experts: "热门专家",
  Hit: "命中",
  Returned: "已返还",
  To_be_returned: "待返还",
  On_sale: "售卖中",
  Cut_off_orders: "截单",
  Order_status: "订单状态",
  Purchase_time: "购买时间",
  Please_enter_the_competition: "请输入赛事",
  Competition: "赛事",
  Publisher: "发布人",
  Please_enter_the_publisher: "请输入发布人",
  Purchase_successful: "购买成功",
  hint_73: "确定下架吗？下架后移动端将不出现此条短视频？",
  Bullet_Screen_Library: "弹幕库",
  Add_bullet_library: "添加弹幕库",
  Batch_Import: "批量导入",
  Add_bullet_comments: "添加弹幕",
  Bullet_screen_content: "弹幕内容",
  Recent_Editors: "最近的编辑人",
  Import_data: "导入数据",
  Edit_bullet_screen: "编辑弹幕",
  hint_649: "支持添加多条弹幕，多个弹幕用英文逗号隔开，单次最多添加1000条。",
  Label_One: "标签一",
  Label_2: "标签二",
  Label_Three: "标签三",
  Label_Four: "标签四",
  Label_Five: "标签五",
  Please_enter_the_bullet_content: "请输入弹幕内容",
  hint_648: "上传大小不能超过 10MB!",
  Successfully_added_barrage: "添加弹幕成功",
  Update_barrage_successful: "更新弹幕成功",
  hint_647: "上传文件只能是 xls格式!",
  Import_failed: "导入失败",
  Import_successful: "导入成功",
  hint_646: "确定要删除此条弹幕吗？",
  Number_of_fans: "粉丝数",
  Live_broadcast_time: "直播时间",
  Duration_minutes_: "时长(分钟)",
  Number_of_gift_givers: "送礼人数(个)",
  Number_of_gifts_given: "送礼个数(个)",
  Live_room_revenue_feather_: "直播间收益(羽毛)",
  Guilds: "公会",
  Settlable_income_yuan_: "可结算收益(元)",
  Reward_and_punishment_income_yuan: "奖惩收益(元)",
  Total_revenue_yuan_: "总收益(元)",
  Adjustment_type_: "调整类型：",
  hint_645: "奖惩原因：",
  Settlement_bill_review: "结算账单审核",
  Originating_Reviewer: "发起审核人",
  Operations_Manager_: "运营负责人",
  Reissue: "重新发起",
  Through: "通过",
  Please_enter_the_amount: "请输入金额",
  Please_enter_the_reason: "请输入原因",
  Guild_name: "公会名称",
  Please_enter_the_guild_name: "请输入公会名称",
  Guild_ID: "公会ID",
  Receiving_account_name: "收款账户名称",
  Opening_Bank: "开户行",
  Bank_card_number: "银行卡号",
  Account_opening_address: "开户地址",
  Number_of_anchors: "主播个数",
  Please_fill_in_your_phone_number: "请填写手机号码",
  Please_fill_in_your_bank_card_number: "请填写银行卡号",
  hint_75: "启用后将可为主播分配该公会，是否确定启用此公会",
  Resource_location: "资源位置",
  Is_it_effective_: "是否生效",
  Jump_position: "跳转位置",
  Please_enter_the_jump_location: "请输入跳转位置",
  Please_select_a_cover: "请选择封面",
  Please_select_a_jump_type: "请选择跳转类型",
  Please_select_a_resource_location: "请选择资源位置",
  hint_76: "注：权重越大当前广告位越靠前",
  Is_the_browser_open_: "是否浏览器打开",
  hint_644: "确认要启用该广告吗？",
  hint_643: "确认要停用该广告吗？",
  hint_642: "确认要移除该广告位吗？",
  Add_an_open_screen_advertisement: "新增开屏广告",
  hint_641: "请保证视频格式为MP4格式，且不超过5M",
  hint_77: "只能上传jpg/png/gif文件，图片尺寸需为750X1624",
  Please_enter_the_link_address: "请输入链接地址",
  Please_enter_the_live_broadcast_room_ID: "请输入直播间ID",
  Please_enter_the_information_ID: "请输入资讯ID",
  Please_enter_the_chat_room_ID: "请输入聊天室ID",
  The_input_information_format_is_incorrect: "输入信息格式有误",
  Chat_room_ID: "聊天室ID",
  Brocade_Bag_ID: "锦囊ID",
  hint_640: "只能上传jpg/png/gif文件，建议尺寸为",
  Please_enter_the_sorting_number: "请输入排序号",
  Sort: "排序",
  The_length_cannot_exceed_20: "长度不能走过20",
  hint_78: "下线后该弹窗从移动端下架，是否确定下线此弹窗？",
  hint_639: "上传图片大小不能超过 1MB",
  Page_Name: "页面名称",
  Please_enter_the_page_name: "请输入页面名称",
  Parameters: "参数",
  PC_Link: "PC链接",
  H5_Link: "H5链接",
  PC_Preview: "PC预览",
  H5_Preview: "H5预览",
  PC_Resource_Map: "PC资源图",
  H5_Resource_Map: "H5资源图",
  Please_enter_parameters: "请输入参数",
  Background_name: "背景名",
  Background_image: "背景图",
  Set_background_name: "设置背景名",
  Set_sorting: "设置排序",
  Modify_background_image: "修改背景图",
  Add_background_image: "新增背景图",
  Please_fill_in_the_background_name: "请填写背景名称",
  Please_fill_in_the_sorting: "请填写排序",
  Successfully_taken_down: "下架成功",
  Successfully_listed: "上架成功",
  Effective: "有效",
  Invalid: "无效",
  hint_606: "频道开播数量（10分钟统计一次）",
  Go_live: "上线",
  hint_605: "请设置排序号（1-999)",
  Please_enter_the_tab_name: "请输入tab名称",
  hint_79: "只能上传jpg/png文件，图片尺寸需为670*376",
  Please_set_the_status: "请设置状态",
  Are_you_sure_you_want_to_go_online_: "确定要上线吗",
  Are_you_sure_you_want_to_go_offline_: "确定要下线吗",
  Anchor_userid: "主播userid",
  Chat_room_title: "聊天室标题",
  Cut_off_flow: "断流",
  hint_638: "确定对此聊天室断流吗？",
  Upload_time: "上传时间",
  Cover_type: "封面类型",
  Batch_pass: "批量通过",
  Batch_rejection: "批量拒绝",
  Voice_chat_room: "语音聊天室",
  Reject: "拒绝",
  Operated: "已操作",
  Passed: "已通过",
  Live_streaming_3: "直播",
  hint_637: "确定要删除封面吗？",
  Music_name: "音乐名",
  Please_enter_the_music_name: "请输入音乐名称",
  Author_name: "作者名",
  Please_enter_the_author_name: "请输入作者名称",
  Size: "大小",
  Playback_count: "播放次数",
  Trial_listening: "试听",
  Please_enter_the_song_name: "请输入歌曲名",
  Song_title: "歌曲名",
  Upload_music_files: "上传音乐文件",
  Only_supports_MP3_format: "仅支持MP3格式",
  hint_80: "宽高比 60*60",
  Please_upload_music_files: "请上音乐文件",
  Edit_Music: "编辑音乐",
  Add_music: "新增音乐",
  Only_MP3_format_files_can_be_uploaded: "只能上传mp3格式文件",
  The_size_cannot_exceed_20MB_: "大小不能超过 20MB!",
  hint_635: "确定要上架该音乐吗？",
  hint_636: "确定要下架该音乐吗？",
  Add_Recommendation: "新增推荐",
  Please_set_the_sorting_number_1_999_: "请设置排序号（1-999）",
  Editor_recommendation: "编辑推荐",
  UserId_cannot_be_empty: "UserId不能为空",
  Shutdown_time: "关播时间",
  Peak_heat_value: "热力值峰值",
  Edit_Page: "编辑页面",
  Add_Page: "添加页面",
  Upload_at_least_one_resource_image: "至少上传一端资源图片",
  hint_81: "发布后该链接将发布至线上，是否确定发布该页面？",
  hint_82: "下架后该链接将不可在线上访问，是否确定下架该页面？",
  Select_Cover: "选择封面",
  hint_633: "注意：生效时间不可重叠！！！",
  hint_83: "存在不完整信息，请填写完整再提交！！",
  hint_634: "上传图片大小不能超过 500K!",
  Recommended_image: "推荐图",
  Display_sorting: "显示排序",
  Edit_Secondary_Classification: "编辑二级分类",
  Classification: "分类",
  Add_Category: "添加分类",
  Please_enter_the_activity_name: "请输入活动名称",
  Activity_status: "活动状态",
  Image: "图片",
  Configuration_date: "配置日期",
  Please_enter_the_button_link: "请输入按钮链接",
  Button_link: "按钮链接",
  Button_Text: "按钮文案",
  hint_607: "请输入按钮文案，最大4个字",
  Button_color: "按钮颜色",
  hint_608: "请输入按钮颜色，如#CCCCCC",
  Edit_Configuration: "编辑配置",
  New_configuration: "新增配置",
  hint_609: "请输入按钮文案",
  Please_enter_the_button_color: "请输入按钮颜色",
  hint_84: "确定要上架此模板吗，上架后页面模板会生效",
  hint_85: "确定要下架此模板吗，下架后页面按钮会失效",
  APP_end: "APP端",
  H5_end: "H5端",
  AK_APP_end: "AK-APP端",
  AK_PC_end: "AK-PC端",
  AK_H5_end: "AK-H5端",
  Anchor_details: "主播明细",
  Recommended_number_of_anchors: "推荐主播数量",
  Anchor_: "主播{num}",
  Please_enter_the_anchor_ID: "请输入主播ID",
  Please_fill_in_the_resource_slot_name: "请填写资源位名称",
  Please_fill_in_at_least_one_anchor_information: "请填写至少一条主播信息",
  Secondary_classification_valid_all: "二级分类(有效/全部)",
  SM_static_resources: "SM静态资源",
  AK_static_resources: "AK静态资源",
  More_Jumps: "更多跳转",
  Editorial_Expert_Recommendation_Group: "编辑专家推荐组",
  Add_expert_recommendation_group: "新增专家推荐组",
  Near_0_in_0: "近0中0",
  Highest: "最高",
  Lian_Hong: "连红",
  Recently: "近期",
  Permissions: "权限",
  Are_you_sure_you_want_to_turn_it_on_: "确定开启吗？",
  Are_you_sure_you_want_to_close_it_: "确定关闭吗？",
  Live_broadcast_room_name: "直播间名称",
  Please_select_sorting: "请选择排序",
  Edit_popular_recommendations: "编辑热门推荐",
  Add_popular_recommendations: "新增热门推荐",
  Advertising_name: "广告名称",
  Resource_type: "资源类型",
  Video_upload: "视频上传",
  Your_browser_does_not_support_video_playback: "您的浏览器不支持视频播放",
  Re_upload: "重新上传",
  Please_upload_the_video: "请上传视频",
  Offline: "已下线",
  Square_banner: "广场banner",
  Page: "页面",
  Information_details: "资讯详情",
  Edit_open_screen_advertisement: "编辑开屏广告",
  Please_select_a_resource_type: "请选择资源类型",
  Please_select_an_open_screen_advertisement: "请选择开屏广告",
  The_uploaded_video_size_cannot_exceed_5MB_: "上传视频大小不能超过5MB哦",
  Please_upload_the_correct_video_format: "请上传正确的视频格式",
  Add_patches: "新增贴片",
  Display_location: "展示位置",
  Please_enter_the_title_of_the_patch_advertisement: "请输入贴片广告标题",
  hint_86: "只能上传jpg/png文件，图片尺寸需为100X100",
  Edit_patch_ads: "编辑贴片广告",
  Add_patch_ads: "新增贴片广告",
  Please_enter_the_advertisement_title: "请输入广告标题",
  Please_select_a_display_location: "请选择展示位置",
  Recommended_by_experts_on_the_homepage: "首页专家推荐",
  Live_streaming_page_anchor_recommendation: "直播页主播推荐",
  hint_87: "上传图片只能是 JPG、PNG或gif格式!",
  Pop_up_name: "弹窗名称",
  APP_Advertising: "APP广告",
  Open_screen_advertising: "开屏广告",
  Patch_advertising: "贴片广告",
  Activity_pop_up: "活动弹窗",
  hint_611: "注：上架运营全部功能都已开放",
  Assistant: "小助手",
  Homepage_Live: "首页直播",
  Live_broadcast_room_announcement: "直播间公告",
  Advertising_resource_allocation: "广告资源位配置",
  hint_610: "开启则请求旧的广告资源位素材",
  hint_88: "注：官方审核期间，部分功能未开放",
  APP_Brand_Name_: "APP品牌名称：",
  Please_enter_the_APP_brand_name: "请输入APP品牌名称",
  Please_enter_contact_customer_service: "请输入联系客服",
  hint_89: "上传图片尺寸有误，请按格式要求上传",
  Switch_initialization: "开关初始化",
  Version_configuration: "版本配置",
  Review_status: "审核状态",
  Operation_status: "运营状态",
  AppStore_version_number: "AppStore版本号",
  Please_enter_the_version_number: "请输入版本号",
  Version_status: "版本状态",
  Update_instructions: "更新说明",
  Configure_time: "配置时间",
  Please_enter_the_interface_version_number: "请输入接口版本号",
  hint_90: "接口版本号由技术提供，不可私自定义！",
  Please_enter_update_instructions: "请输入更新说明",
  Please_select_version_status: "请选择版本状态",
  Please_enter_the_AppStore_version_number: "请输入AppStore版本号",
  Please_enter_the_short_video_title: "请输入短视频标题",
  Edit_Title: "编辑标题",
  Short_video_title_: "短视频标题",
  hint_91: "发布该条短视频至移动端，是否确认发布？",
  Crawling_website_sites: "爬取网站站点",
  Statistical_time: "统计时间",
  UV_data: "uv数据",
  Platform: "平台",
  Add_circle_recommendations: "新增圈子推荐",
  Are_you_sure_you_want_to_pause_: "是否确定暂停？",
  Access_date: "访问日期",
  Posting_time: "发帖时间",
  Poster_nickname: "发帖人昵称",
  Interactive_red_envelopes: "互动红包",
  Topic_Name: "话题名称",
  Please_enter_the_topic_name: "请输入话题名称",
  hint_632: "话题热度(10分钟统计一次)",
  Accumulated_number_of_related_posts: "累计相关帖子数",
  Save_and_publish: "保存并发布",
  Recommendation: "推荐",
  Add_recommended_topics: "新增推荐话题",
  Number_of_participants_in_the_topic: "话题参与人数",
  Number_of_topic_views: "话题浏览次数",
  Topic_release_time: "话题发布时间",
  Topic_Top_Time: "话题置顶时间",
  Choose_a_topic: "选择话题",
  Enter_the_topic_name_you_want_to_recommend: "输入需要推荐的话题名称",
  Editor_recommended_topic: "编辑推荐话题",
  The_topic_cannot_be_empty: "话题不能为空",
  hint_631: "请输入话题名称（15字以内）",
  hint_630: "请输入话题简介（100字以内）",
  Successfully_created_topic: "创建话题成功",
  Recharge: "充值",
  Consumption: "消费",
  Revenue: "收益",
  Order_number_1: "订单编号",
  Recharge_amount: "充值金额",
  consumption_type: "消费类型",
  consumption_amount: "消费金额",
  revenue_type: "收益类型",
  revenue_amount: "收益金额",
  Additional_issuance: "增发",
  Uploading: "上传中。。。",
  Batch_issuance: "批量增发",
  Download_batch_issuance_templates: "下载批量增发模板",
  Additional_currency_issuance: "增发货币",
  Increase_experience_value_of_issuance: "增发经验值",
  Please_enter_user_userid: "请输入用户userid",
  Please_enter_the_quantity_of_coins: "请输入金币数量",
  Please_enter_a_note: "请输入备注",
  VIP_level: "VIP等级",
  Additional_issuance_time: "增发时间",
  Are_you_sure_you_want_to_refuse_: "确定要拒绝吗？",
  Personal_currency_issuance: "个人货币增发",
  VIP_currency_issuance: "VIP货币增发",
  Total_consumption_in_gold_coins: "总消耗（金币）",
  Total_rechargeyuan: "总充值（元）",
  Number_of_Rechargers: "充值人数",
  Recharge_switch: "充值开关",
  RMB_amount_yuan_: "人民币金额（元）",
  Number_of_gold_coins: "金币数量",
  Edit_recharge_configuration: "编辑充值配置",
  Add_recharge_configuration: "新增充值配置",
  Recharge_time: "充值时间",
  Payment_channels: "支付渠道",
  Please_enter_the_order_number_1: "请输入订单编号",
  Payment_time: "支付时间",
  Order_time: "订单时间",
  Payment_amount_yuan: "支付金额（元）",
  Balance_inquiry: "余额查询",
  Ordinary_user_currency_balance: "普通用户货币余额",
  Details_1: "明细",
  Football_quiz_activity: "足球答题活动",
  Football_winning_statistics: "足球中奖统计",
  Basketball_quiz_activity: "篮球答题活动",
  Basketball_winning_statistics: "篮球中奖统计",
  Guessing_question_name: "竞猜答题名称",
  Please_enter_the_name_of_the_quiz_question: "请输入竞猜答题名称",
  Start_time: "开始时间",
  Add_a_question: "新增答题",
  Rule_configuration: "规则配置",
  Style_Configuration: "样式配置",
  Number_of_periods: "期数",
  Reward_base: "奖励基数",
  Stop: "停止",
  End: "结束",
  Title: "题目",
  The_problem_name_cannot_be_empty: "问题名称不能为空",
  Please_enter_the_question_name: "请输入问题名称",
  Please_enter_question_options: "请输入问答题选项",
  question_options_1: "答题选项",
  Add_Options: "添加选项",
  Please_enter_the_number_of_currencies_for_the_reward_base:
    "请输入奖励基数的货币个数",
  Save: "保 存",
  Cancel: "取 消",
  Please_submit_results_with_caution: "请谨慎提交结果",
  The_answer_to_the_question_cannot_be_empty: "问题答案不能为空",
  Flow: "流局",
  Activity_Rule_Configuration: "活动规则配置",
  Rule_configuration_cannot_be_empty: "规则配置不能为空",
  Limited_to_200_words_in_length: "限长200个字",
  Guessing_Style_Configuration: "竟猜样式配置",
  PC_Banner_diagram: "PC Banner图",
  PC_Banner_image_cannot_be_empty: "PC Banner图不能为空",
  hint_612: "尺寸：1920x624 只能上传jpg/png文件",
  H5_APP_Banner_image: "H5/APP Banner图",
  H5_APP_Banner_image_cannot_be_empty: "H5/APP Banner图不能为空",
  hint_613: "尺寸：750x1012 只能上传jpg/png文件",
  Theme_color: "主题色",
  The_theme_color_cannot_be_empty: "主题色不能为空",
  hint_619: "页面底部的颜色配置",
  Event_details: "活动详情",
  Correct_answer: "正确答案",
  Stopped: "已停止",
  Add_a_guessing_activity: "新增竞猜活动",
  hint_618: "请填写竞猜答题名称",
  Please_select_the_effective_time_range: "请选择生效时间范围",
  Please_fill_in_the_reward_base: "请填写奖励基数",
  hint_92: "取消后则不发布该期答题活动，是否确定取消该期竞猜答题活动?",
  Success: "成功",
  Cancel_successful: "取消成功",
  hint_93: "停止后用户将无法参与竞猜答题，是否确定停止竞猜答题活动",
  hint_614: "请输入1-99999正整数的奖励基数",
  Cannot_select_past_time: "不可选择过去时间",
  hint_615: "是否确定公布答案？",
  Save_successful: "保存成功",
  hint_616: "题目{num} 的答案选项最少设置2个选项",
  Number_of_participants: "参与人数",
  Number_of_winners: "中奖人数",
  hint_617: "奖励个数（金币）",
  Question_Name: "问题名称",
  Number_of_correct_answers: "答对人数",
  Activity_List: "活动列表",
  Top_of_the_event: "活动置顶",
  PC_end_carousel: "PC端轮播",
  AK_Activity_List: "AK-活动列表",
  AK_Activity_Topping: "AK-活动置顶",
  AK_PC_end_carousel: "AK-PC端轮播",
  Create_activity_records: "创建活动记录",
  Event_main_title: "活动主标题",
  Activity_subtitle: "活动副标题",
  PC_thumbnail: "PC端缩略图",
  App_thumbnail: "App缩略图",
  Activity_time: "活动时间",
  Long_term_activities: "长期活动",
  Please_enter_the_main_title_of_the_activity: "请输入活动主标题",
  Please_enter_the_activity_subtitle: "请输入活动副标题",
  PC_activity_redirect_address: "PC活动跳转地址",
  Activity_jump_button_background: "活动跳转按钮背景",
  App_side_thumbnail: "App端缩略图",
  hint_901: "大小355*148px",
  App_activity_redirect_address: "App 活动跳转地址",
  Time_type: "时间类型",
  There_is_a_time_limit: "有时间限制",
  Edit_activity_records: "编辑活动记录",
  hint_629: "确定置顶此条活动记录吗？",
  hint_620: "确定取消置顶此条活动记录吗？",
  Please_set_the_activity_time: "请设置活动时间",
  Jump_URL: "跳转URL",
  Queue: "队列",
  Cover: "封面",
  Mobile_download_page: "移动端下载页",
  PC_Download_Page: "PC端下载页",
  Android_copywriting: "安卓文案",
  hint_94: "请输入文案，最大支持10个字符",
  IOS_Web_App_Copywriting: "iOS-网页版APP文案",
  IOS_copywriting: "iOS文案",
  PC_background_configuration: "PC背景配置",
  Group_stage_competition: "小组赛",
  "1_8_elimination_round": "1/8淘汰赛",
  "1_4_elimination_round": "1/4淘汰赛",
  Semi_finals: "半决赛",
  Finals: "决赛",
  Location: "地点",
  Facing_each_other: "对阵",
  hint_95: "请输入主播房间号并用逗号隔开，最多四位",
  Fen: "分",
  hint_628: "最多只能选择4个主播房间号",
  SM_redemption_page: "SM兑换页面",
  AK_redemption_page: "AK兑换页面",
  H5_end_thumbnail: "H5端缩略图",
  hint_96: "只能上传jpg/png/gif文件，尺寸1440*315",
  Category_ID: "类别ID",
  Classification_name: "分类名称",
  Please_enter_the_category_name: "请输入分类名称",
  Thai_tag_name: "泰语标签名称",
  Please_enter_the_Thai_tag_name: "请输入泰语标签名称",
  English_tag_name: "英语标签名称",
  Please_enter_the_English_tag_name: "请输入英语标签名称",
  Vietnamese_label_name: "越南语标签名称",
  Please_enter_the_Vietnamese_label_name: "请输入越南语标签名称",
  Korean_tag_name: "韩语标签名称",
  Please_enter_the_Korean_tag_name: "请输入韩语标签名称",
  Brazilian_label_name: "巴西语标签名称",
  Please_enter_the_Brazilian_label_name: "请输入巴西语标签名称",
  Add_a_new_category: "新增分类",
  hint_888: "精品好物",
  Please_select_the_status: "请选择状态",
  hint_627: "是否确定上线该商品？",
  hint_626: "是否确定下线该商品分类？",
  Warning: "警告",
  hint_97: "分类下含有所属商品，不可下线",
  Edit_Classification: "编辑分类",
  Redemption_page_BANNER: "兑换页BANNER",
  hint_625: "上传图片大小不能超过 5M!",
  Delisted_products: "下架商品",
  Listing_products: "上架商品",
  Classification_management: "分类管理",
  Label_management: "标签管理",
  Order_List: "订单列表",
  Product_ID: "商品ID",
  Please_enter_the_product_ID: "请输入商品ID",
  Product_name: "商品名称",
  Please_enter_the_product_name: "请输入商品名称",
  Do_we_need_delivery_: "是否需配送",
  Switch: "开关",
  Unit_price: "单价",
  Discounted_price: "折后价",
  Current_inventory: "当前库存",
  Redeemed: "已兑换",
  Inventory_changes: "库存变动",
  Select_thumbnail: "选择缩略图",
  Details_page_image: "详情页图",
  Select_detailed_image: "选择详情图",
  Details: "详情说明",
  Please_enter_the_unit_price: "请输入单价",
  Discounts: "打折价",
  Please_enter_the_discounted_price_of_the_product: "请输入商品打折价",
  Please_select_a_category: "请选择类别",
  Label: "标签",
  Product_purchase_restriction_period: "商品限购周期",
  No_purchase_restrictions: "不限购",
  Day: "日",
  Week: "周",
  Cumulative: "累计",
  Product_purchase_limit: "商品限购数",
  hint_98: "请输入周期内的商品限购数",
  Inventory: "库存",
  Add: "增加",
  Reduce: "减少",
  The_product_name_cannot_be_empty: "商品名称不可为空",
  Boutique: "精品",
  Life: "生活",
  Product_addition: "商品新增",
  Please_upload_thumbnail: "请上传缩略图",
  Please_upload_detailed_images: "请上传详情图",
  Please_select_whether_delivery_is_required: "请选择是否需配送",
  Purchase_unit_price_cannot_be_empty: "购买单价不可为空",
  Classification_cannot_be_empty: "分类不可为空",
  hint_99: "商品限购数不能为空",
  Details_cannot_be_empty: "详情说明不能为空",
  Please_select_increase_decrease: "请选择增加减少",
  Are_you_sure_you_want_to_save_: "是否确定保存？",
  Cat_coin_price: "猫币价",
  Diamond_price: "钻石价",
  Thai_product_name: "泰语商品名称",
  Please_enter_the_Thai_product_name: "请输入泰语商品名称",
  English_product_name: "英语商品名称",
  Please_enter_the_English_product_name: "请输入英语商品名称",
  Korean_product_name: "韩语商品名称",
  Please_enter_the_Korean_product_name: "请输入韩语商品名称",
  Brazilian_product_name: "巴西语商品名称",
  Please_enter_the_Brazilian_product_name: "请输入巴西语商品名称",
  hint_902: "尺寸建议174*174",
  hint_903: "尺寸建议375*375",
  Thai_language_details: "泰语详情说明",
  English_detailed_explanation: "英语详情说明",
  Vietnamese_language_details: "越南语详情说明",
  Korean_language_details: "韩语详情说明",
  Brazilian_language_details: "巴西语详情说明",
  Please_enter_the_diamond_price_of_the_product: "请输入商品钻石价",
  Are_you_sure_you_want_to_delist_this_product_: "是否确定下架该商品？",
  Label_ID: "标签ID",
  Please_enter_the_label_name: "请输入标签名称",
  Label_Name: "标签名称",
  hint_624: "是否确定上线该商品标签？",
  hint_101: "是否确定下线该商品标签？",
  Edit_label: "编辑标签",
  Order_number: "订单号",
  Please_enter_the_order_number: "请输入订单号",
  Transaction_time: "交易时间",
  Receiving_address: "收货地址",
  Signee: "签收人",
  Transaction_volume: "交易量",
  Total_transaction_amount: "交易总额",
  Transaction_status: "交易状态",
  Unsuccessful: "不成功",
  Limited_to_50_words_in_length: "限长50个字",
  Inviter_UID: "邀请人UID",
  Inviting_person_nickname: "邀请人昵称",
  Invitee_UID: "被邀请人UID",
  Invitee_registration_time: "被邀请人注册时间",
  Please_enter_the_inviter_reward: "请输入邀请人奖励",
  Please_enter_the_invitee_reward: "请输入被邀请人奖励",
  Rule_Description: "规则说明",
  APP_landing_page_background_image: "APP落地页背景图",
  hint_621: "尺寸：710X1086 只能上传jpg/png文件",
  Watch_the_live_broadcast: "观看直播",
  Live_room_speech: "直播间发言",
  Push_order_configuration: "推单配置",
  hint_102:
    "友情提示：1-发言配置请按照顺序依次进行配置。如配置到第2句，则仅会执行1，2发言配置内容。",
  hint_905: "第{num}次发言配置",
  Reward_amount: "奖励金额",
  Please_enter_the_speech_reward_amount: "请输入发言奖励金额",
  Valid_characters: "有效字符",
  hint_623: "请输入发言有效字符",
  hint_103: "第一次发言配置奖励金额不能为空",
  hint_104: "第二次发言配置奖励金额不能为空",
  hint_105: "第三次发言配置奖励金额不能为空",
  Friendly_reminder: "友情提示",
  hint_1: "1-推单数据及用户参与数据可前往 直播管理》直播间推单 模块查看；",
  hint_2: "2-推单奖励区间：主播推单的奖励会随机在该区间生成；",
  hint_3: "3-用户每日奖励峰值：超过该峰值则继续参与推单，不可获得奖励；",
  hint_889: "推单奖励区间",
  Maximum_reward: "最大奖励",
  Minimum_reward: "最小奖励",
  Users_get_peak_daily: "用户每日获得峰值",
  hint_622: "请输入用户每日最大获取值",
  Please_enter_the_maximum_reward: "请输入最大奖励",
  Please_enter_the_minimum_reward: "请输入最小奖励",
  Please_enter_the_user_daily_peak_gain: "请输入用户每日获得峰值",
  Viewing_duration: "观看时长",
  hint_107: "请输入观看时长，需为正整数",
  Watch_rewards: "观看奖励",
  Please_enter_the_viewing_reward: "请输入观看奖励",
  Reward_channels: "奖励渠道",
  hint_106: "开通后在该渠道观看直播会获得奖励，不同渠道观看仅奖励一次；",
  Please_enter_the_viewing_duration: "请输入观看时长",
  Please_select_a_reward_channel: "请选择奖励渠道",
  First_line_copy: "第一行文案",
  hint_108: "请输入文案，最大支持10字符",
  Second_line_copy: "第二行文案",
  Please_enter_the_first_line_of_text: "请输入第一行文案",
  Please_enter_the_second_line_of_text: "请输入第二行文案",
  Effective_date: "有效日期",
  Display_copy: "展示文案",
  Gift_channels: "赠送渠道",
  Login_rewards: "登陆奖励",
  Accumulated_Gifts: "累计赠送",
  Additional_rewards_for_app_login: "APP登陆额外奖励",
  hint_109: "请输入登陆奖励，为0则不奖励",
  Please_enter_the_reward_amount: "请输入奖励金额",
  Login_Activity_Rewards: "登录活动奖励",
  Please_enter_display_copy: "请输入展示文案",
  Please_enter_the_login_reward: "请输入登陆奖励",
  Please_enter_a_valid_date: "请输入有效日期",
  Please_enter_to_select_the_gift_channel: "请输入选择赠送渠道",
  Login_reward_activity: "登陆奖励活动",
  Download_copy_configuration: "下载文案配置",
  Lottery_time: "抽奖时间",
  Activity_ID: "活动id",
  Accessing_Users: "访问用户",
  Participating_users: "参与用户",
  Data_details: "数据明细",
  Channel_sources: "渠道来源",
  Click_on_the_number_of_lucky_draws: "点击抽奖次数",
  Page_visits: "页面访问次数",
  Number_of_times_participating_in_the_lottery: "参与抽奖次数",
  Winning_users: "中奖用户",
  Users_who_win_physical_prizes: "实物中奖用户",
  Click_to_go_to_the_assistant_user: "点击去小助手用户",
  Click_to_download_app_users: "点击下载APP用户",
  Award_pool_type: "奖池类型",
  Lucky_Wheel: "幸运转盘",
  Super_Wheel: "超级转盘",
  Lottery_Record_Daily_Turntable: "抽奖记录-日常转盘",
  Physical_rewards: "实物奖励",
  Draw_lottery_tickets: "抽奖券",
  Processing_personnel: "处理人",
  Not_issued: "未发放",
  Issued: "已发放",
  Virtual: "虚拟",
  Operation_turntable: "运营转盘",
  Activity_Management: "活动管理",
  Lottery_record: "抽奖纪录",
  Operation_turntable_activity_data: "运营转盘活动数据",
  Activity_type: "活动类型",
  Activity_carousel: "活动转盘",
  Daily_turntable: "日常转盘",
  Activity_statistics: "活动统计",
  Number_of_super_lottery_tickets_distributed: "超级抽奖券发放数量",
  Lucky_Wheel_Lucky_Draw_Times: "幸运转盘抽奖次数",
  Super_Wheel_Lottery_Times: "超级转盘抽奖次数",
  Basic_information: "基本信息",
  Activity_Rules: "活动规则",
  Please_enter_activity_rules: "请输入活动规则",
  PC_banner_image: "pc banner图",
  H5_Jump_Page: "h5跳转页面",
  In_use: "使用中",
  Total_inventory: "总库存",
  Prizes: "奖品",
  Single_day_upper_limit: "单日上限",
  Individual_users_can_receive_upper_limits: "单个用户获得上限",
  Lucky_draw_probability_in_tens_of_thousands_: "抽奖概率(万分）",
  Is_it_a_running_horse_lamp_: "是否跑马灯",
  Is_it_a_bottom_line: "是否兜底",
  Add_prize_pool: "添加奖池",
  Cloning: "克隆",
  Single_consumption: "单次消耗",
  Daily_limit: "每日上限",
  Activity_channels: "活动渠道",
  H5_banner_diagram: "h5 banner图",
  How_to_earn_cat_coins: "如何赚猫币",
  Please_select_the_activity_time_range: "请选择活动时间范围",
  Edit_anchor_recommendation_group: "编辑主播推荐组",
  Add_a_new_anchor_recommendation_group: "新增主播推荐组",
  Please_upload_the_PC_banner_image: "请上传pc banner图",
  Please_upload_the_h5_banner_image: "请上传h5 banner图",
  Please_enter_PC_Jump_Page: "请输入pc跳转页面",
  Please_enter_h5_to_jump_to_the_page: "请输入h5跳转页面",
  Please_enter_a_single_consumption: "请输入单次消耗",
  Please_enter_the_daily_limit: "请输入每日上限",
  Please_select_the_activity_channel: "请选择活动渠道",
  Prize_Pool: "奖池",
  hint_110: "奖池开启时间不可早于活动开启时间",
  hint_111: "奖池结束时间不可晚于活动结束时间",
  The_total_probability_does_not_equal_10000: "概率总和不等于10000",
  Sum_of_probabilities: "概率总和",
  Please_select_the_prize_for_prize_: "请选择奖品{num}的奖品",
  Please_enter_the_total_inventory_of_prize_: "请输入奖品{num}的总库存",
  _The_daily_limit_is_greater_than_the_total_inventory:
    "{num}单日上限大于总库存",
  hint_112: "请输入奖品{num}的抽奖概率(万分）",
  hint_115: "请选择奖品{num}的是否跑马灯",
  hint_116: "每个奖池必须选择一个兜底奖品!",
  hint_114: "请填写奖池的生效时间",
  hint_113: "是否确认删除该奖池?",
  Data_Overview: "数据概览",
  Quantity_of_currency: "货币数量",
  Please_enter_the_quantity_of_currency: "请输入货币数量",
  Number_of_lottery_tickets: "奖券数量",
  Please_enter_the_number_of_lottery_tickets: "请输入奖券数量",
  Select_icon: "选择icon",
  Please_select_the_type_of_prize: "请选择奖品类型",
  Please_upload_the_icon: "请上传icon",
  Editing_prizes: "编辑奖品",
  Please_enter_a_positive_integer: "请输入正整数",
  single_1: "个",
  submit_results_1: "提交结果",
  rules_options: "规则配置",
  rules_options_1: "规则配置不能为空",
  edit_activity_cai: "编辑竞猜活动",
  created_topic_1: "创建话题",
  created_topic_2: "编辑话题",
  briefing_topic_1: "话题简介",
  briefing_topic_2: "话题缩略图",
  hint_899: "只能上传jpg/png文件,限制1M,大小790*215px",
  hint_907: "只能上传jpg/png文件,限制1M,大小150*58px",
  hint_917: "只能上传jpg/png文件,限制1M,大小355*148px",
  hint_918: "图片尺寸建议为256x256,只能上传jpg/png文件",
  lookDetail: "查看",
  text_1: "是否确定下架？",
  text_2: "是否确定上架？",
  matchPlan_1: "比赛进度",
  group_1: "小组",
  shopEdit: "商品编辑",
  viShopEditName: "越南语商品名称",
  viShopEditName1: "请输入越南语商品名称",
  hint_1001: "购买猫币不可为空",
  hint_1002: "购买钻石不可为空",
  hint_1003: "请输入猫币",
  remark_edit: "备注编辑",
  currency_cat: "猫币",
  diamond_1: "钻石",
  Invitee_UID_1: "邀请人UID",
  Invitee_edit_1: "邀请配置",
  minute_1: "分钟",
  establish_1: "创建",
  hint_1005: "足球中奖统计数据",
  hint_1006: "篮球中奖统计数据",
  hint_1007: "中奖详情",
  hint_1008: "始",
  hint_1009: "止",
  hint_1011: "未填写",
  hint_1012: "选择banner图",
  hint_1013: "pc跳转页面",
  hint_1014: "使用",
  hint_1015: "奖品ID",
  hint_1016: "抽奖券",
  hint_1017: "链接",
  hint_1018: "资讯ID",
  hint_1019: "上传音乐缩略图",
  hint_1021: "英文字母等级限制",
  hint_1022: "直播间推单自动弹出",
  advertising_title: "广告标题",
  Author_name_1: "作者",
  text_7: "用户私信主播",
  text_8: "主播私信用户",
  text_9: "用户私信管理员",
  text_10: "私聊主播等级",
  text_11: "下载页顶部Logo",
  text_12: "APP/H5专辑页状态",
  text_13: "IOS三倍图",
  text_14: "第一张",
  text_15: "第二张",
  text_16: "第三张",
  text_17: "第四张",
  text_18: "第${num}张内容不能为空",
  text_19: "第${num}张图片不能为空",
  text_20: "图片尺寸建议为40x40,只能上传jpg/png/gif文件",
  text_21: "图片尺寸建议为40x40,只能上传jpg/png/gif文件",
  text_22: "PC专辑页名称",
  text_23: "请输入src对应的url,如需统计多个域名，请用||区分",
  text_24: "关于页面文案配置",
  text_25: "请填写文案内容",
  text_26: "请输入开发者账号",
  text_27: "新手引导页",
  text_28: "不用更新",
  text_29: "可选更新",
  text_30: "强制更新",
  text_31: "公钥",
  text_32: "私钥",
  text_33: "功能说明",
  text_34: "Android配置",
  text_35: "直播间发送红包",
  text_36: "直播间送礼",
  text_37: "启动APP埋点上报",
  text_38: "域名类型",
  text_39: "编辑域名",
  text_40: "添加域名",
  have_1: "有",
  have_2: "无",
  updateTime_1: "更新时间",
  text_41: "确定要删除该域名吗？",
  text_42: "解禁后则该词可继续在平台发送,确认解禁吗？",
  text_43: "直播间banner url",
  text_44: "H5banner",
  text_45: "请输入description",
  text_46: "请输入首页 title",
  text_47: "请输入直播页 title",
  text_48: "只能上传jpg/jpeg/png文件,最多支持上传9张",
  text_49: "上传视频文件",
  text_50: "请输入圈子id",
  text_51: "支持格式：JPG、PNG、GIF、MP4，大小：3M以内:",
  text_52: "未发布",
  text_53: "请输入简介",
  text_54: "只能上传jpg/png/gif文件,限制1M,大小790*215px",
  text_55: "设置人数上限",
  text_56: "投稿时间",
  text_57: "请设置人数上限",
  text_58: "人数上限不得小于50人",
  text_59: "真实姓名",
  text_60: "已同意",
  text_61: "创建圈子数",
  text_62: "圈子用户数",
  text_63: "加入时间",
  text_64: "身份证号码：",
  text_65: "请输入手机号码",
  text_66: "提交人",
  text_67: "体育类",
  text_68: "娱乐类",
  text_69: "其他",
  text_70: "只能上传jpg/png文件，图片尺寸需为120X120",
  text_71: "添加频道",
  text_72: "全部频道",
  channel_1: "频道",
  text_73: "指定房间",
  text_74: "上线成功",
  text_75: "位置",
  text_76: "PC背景图",
  text_77: "PC标题图",
  text_78: "玩法说明",
  text_79: "观看直播时长(分钟)",
  text_80: "概率",
  text_81: "0为不不可能抽中红包，此为用户抽中有奖红包的概率",
  text_82: "签到时间",
  text_84: "奖励状态",
  text_85: "派发奖励",
  text_86: "确定派发奖励吗？",
  text_87: "签到类型",
  text_88: "发送人userid",
  text_89: "请输入发送人userid",
  text_90: "红包ID",
  text_91: "发送人昵称",
  text_92: "发起房间号",
  text_93: "请输入发送房间号",
  text_94: "红包档位",
  text_95: "领取个数",
  text_96: "失效个数",
  text_97: "发送时间",
  text_98: "已启用",
  text_99: "主题ID",
  text_101: "输赢",
  text_102: "红包雨ID",
  text_103: "红包总数",
  text_104: "生效",
  text_105: "失效",
  text_106: "绑定赛事/主播",
  text_107: "已领红包个数",
  text_108: "领取人数",
  text_110: "活动",
  text_111: "领取",
  text_112: "房间",
  text_113: "平台累计分成",
  text_114: "登出时间",
  text_115: "注册IP",
  text_116: "在线时长",
  text_117: "派发状态：",
  text_118: "更改时间",
  text_119: "场次ID",
  text_120: "直播比赛ID",
  text_121: "玩法",
  text_122: "推单内容",
  text_123: "赛段",
  text_124: "支持",
  text_125: "主播结果",
  text_126: "反对",
  text_127: "修正结果",
  text_128: "奖励数额",
  text_129: "总发放",
  text_130: "举报人",
  text_131: "描述",
  text_132: "回复",
  text_133: "APP背景",
  // 新增5
  live_time: "直播时长(分钟)",
  Please_select_the_results: "请选择本单的结果",
  red_1: "红",
  black_1: "黑",
  go_1: "走",
  corner: "角球",
  win_or_lose: "让分胜负",
  Size_division: "大小分",
  take_care_1: "注：尺寸为572x420",
  take_care_2: "注：尺寸为926x126",
  discount_1: "折扣",
  discount_name_1: "折扣名称",
  text_135: "请输入频道昵称",
  characters_1: "文字",
  picture_1: "图片",
  Please_fill_in_the_link_1: "请填写链接",
  Please_fill_in_the_link_2: "请正确填写链接",
  publish_this_announcement: "是否确定发布该公告？",
  send_state: "发送状态",
  Create_marketing_messages: "创建营销短信",
  SMS_content: "短信内容",
  View_receiving_users: "查看接收用户",
  Upload_list: "上传名单",
  Are_you_sure_to_cancel: "是否确认取消",
  User_Import_Template: "用户导入模板",
  channel_2: "渠道",
  autograph: "签名",
  SMS_type: "短信类型",
  SMS_template_editing: "短信模板编辑",
  SMS_template_add: "短信模板新增",
  Editing_time: "编辑时间",
  Instant_push: "即时推送",
  Timed_push: "定时推送",
  Page_H5: "H5页",
  Menu_Type: "菜单类型",
  Role_authorization: "角色授权",
  name_111: "姓名",
  role_111: "角色",
  Modifying_Merchant_Basic_Information: "修改商户基本信息",
  Gift_ID: "礼物ID",
  Number_of_deliveries: "送出个数",
  Delivery_value_1: "送出价值(金币/万个)",
  broadcast_room_1: "送出直播间",
  broadcast_room_2: "请输入送出直播间",
  broadcast_room_3: "送出价值",
  Gift_giving_time: "送礼时间",
  Online_1: "已上线",
  Gift_unit_price: "礼物单价",
  hint_1110: "只能上传jpg/png文件,大小不超过1MB！",
  hint_1112: "预览效果",
  hint_1113: " 只能上传gif文件,大小不超过1MB！",
  hint_1114: "播放效果",
  hint_1115: "只能上传svga文件,大小不超过10MB！",
  Full_platform: "全平台",
  This_room: "本房间",
  hint_1116: "只能上传只能上传jpg/png文件!",
  Type_of_issuance: "下发类型",
  Designated_category: "指定品类",
  hint_1117: "请输入指定房间号，多个房间之间使用英文的逗号隔开",
  hint_1118: "请输入礼物ID",
  hint_1119: "最多可输入99个礼物ID",
  hint_1120: "不可输入空格",
  hint_1121: "不可输入中文逗号",
  hint_1122: "只可输入中文、英文、数字",
  hint_1123: "请选择下发类型",
  hint_1124: "请选择下发范围",
  hint_1125: "请选择品类",
  hint_1126: "请指定房间",
  Publishable_status: "可发布状态",
  Expert_nickname: "专家昵称",
  Expert_brief: "专家简介",
  Expert_detail_1: "锦囊详情",
  article_1:
    "亚眠15轮比赛积分13分，排名第18位，居降级附加区；上轮比赛客场完败,，遭遇两连败,，状态低迷；而他们主场3胜4负，排名第12位，虽然表现不出色，但还是依靠主场抢分；阵容方面，球队有1名中场伤停，2名于球员出战存疑。客队上赛季排名法甲第二，本赛季下滑严重，目前15轮比赛积分10分，排名第19位，居降级区内；上轮比赛客场告负，球队同样遭遇两连败，状态较差；本赛季客场2胜1平4负，排名第13位，虽然表现一般，但所获胜利全为客场所得，有一定客战能力；阵容方面，球队饱受伤病领导困扰，目前有多达14名球员伤停。亚洲初始指数为本场比赛开出平手盘和主受平半盘，给予客队谨慎支持；最新指数中部分机构多调整为平手盘，机构对客队信心有所减弱；欧赔最新平均指数压低胜赔，多机构同时压低平赔，抬升负赔，不利客队。",
  user_1111: "用户",
  Import_1111: "导入",
  hint_1127: "已经达到添加上限了",
  Closed_111: "已关闭",
  Opened_111: "已开启",
  Select_111: "选择",
  less_than: "小于",
  Publisher_11: "发布者",
  Let_the_ball: "让球",
  analysis: "解析",
  Please_enter_the_parsing_content: "请输入解析内容",
  price_11: "售价",
  free_11: "免费",
  release_1: "发布",
  hint_1200: "标题需要4字以上",
  hint_1201: "推荐理由需要20字以上",
  Ball_selection: "球类选择",
  Top_failure_time: "置顶失效时间",
  refund: "退款",
  Topping_time: "置顶时间",
  System_messages: "系统消息",
  Please_enter_the_message_content: "请输入消息内容",
  abnormal: "异常",
  Let_the_ball_win: "让球胜平负",
  Please_set_the_time: "请设置时间",
  Please_message_content: "请填写消息内容",
  hint_20001: "置顶时间不能早于当前时间",
  hint_20002: "确定取消置顶吗？",
  hint_20003: "建仓时间",
  Purchase_records: "购买记录",
  // 新增8
  Edit_Resource_Bits: "编辑资源位",
  ADD_Resource_Bits: "新增资源位",
  hint_20091: "上移",
  hint_20092: "下移",
  hint_20093: "新增热门",
  hint_20094: "视频",
  hint_20095: "页面",
  hint_20096: "添加背景",
  hint_20097: "确定要下架此背景图吗？",
  hint_20098: "只能上传jpg/jpeg/png文件,宽高比 60*60",
  //新增9
  hint_20111: "请选择弹幕库",
  hint_20112: "请输入下发类型",
  hint_20113: "请输入下发范围",
  hint_20114: "确定要{status}此配置吗？",
  hint_20115: "已{status}",
  hint_20116: "弹幕内容不能为空",
  hint_20117: "单次最多添加1000条。",
  hint_20118: "添加时间",
  hint_20119: "开启后，该主播直播间将重新进入机器人，确定开启吗？",
  hint_20121: "主播类型",
  hint_20122: "请输入身份证",
  hint_20123: "房管",
  hint_20124: "请输入uid",
  hint_20125: "用户userId",
  hint_20126: "请输入用户userId",
  hint_20127: "请输入直播标题",
  hint_20128: "账号合并",
  hint_20129: "消息范围",
  hint_20130: "本条消息",
  hint_20888: "本直播间内消息",
  hint_20131: "所有直播间消息",
  hint_20132: "删除原因",
  hint_20133: "用户禁言",
  hint_20134: "禁言用户",
  hint_20135: "UID不能为空",
  hint_20136: "禁言周期不能为空",
  hint_20137: "禁言原因不能为空",
  hint_20138: "房间号不能为空",
  hint_20139: "请输入主播userid",
  hint_20400: "发起审核",
  hint_20401: "审核失败",
  hint_20402: "导出excel",
  hint_20403: "结算周期",
  hint_20404: "调整金额：",
  hint_20405: "元",
  hint_20406: "未发起",
  hint_20407: "结算账单",
  hint_20408: "编辑前",
  hint_20410: "编辑后",
  hint_20411: "待提审",
  hint_20412: "添加公会",
  hint_20413: "请输入收款账户名称",
  hint_20414: "请输入开户行",
  hint_20415: "请输入银行卡号",
  hint_20416: "请输入开户地址",
  hint_20417: "请输入100字以内",
  hint_20418: "编辑公会",
  hint_20419: "停用后将不可为主播分配该公会，是否确定停用此公会？",
  hint_20420: "只能上传jpg/png文件，建议尺寸为",
  hint_20421: "配置账号",
  hint_20422: "查找内容",
  hint_20423: "替换内容",
  hint_20424: "信号源跳转地址",
  hint_20425: "请输入信号源地址",
  hint_20426: "请输入信号源跳转地址",
  hint_20427: "请填写ios Scheme",
  hint_20428: "请填写关于内容",
  hint_20429: "接口版本号",
  hint_20430: "配置人",
  hint_20431: "未选择任何短视频，请选择短视频后再发布！",
  hint_20432: "发布成功",
  hint_20433: "发布成功，此条短视频进入发布库",
  hint_20434: "爬取时间段",
  hint_20435: "请选择时间段",
  hint_20436: "已禁用",
  hint_20437: "设置成功",
  hint_20438: "是否置顶",
  hint_20439: "排序位置",
  hint_20440: "评论",
  hint_20441: "短视频封面",
  hint_20442: "编辑置顶",
  hint_20443: "请输入排序位置",
  hint_20444: "发布后无法进行修改，是否确认发布？",
  hint_20445: "访问渠道",
  hint_20446: "来源域名",
  hint_20447: "小助手UV",
  notice_111: "公告UV",
  index_111: "指数",
  test_111: "确定要{status}该话题吗？",
  test_112: "发布话题",
  test_113: "确定要删除吗？",
  Batch_approval: "批量审批",
  Reviewer_111: "审核人",
  you_want_to_pass: "确定要通过吗？",
  Download_failed: "下载失败",
  Individual_currency: "个人货币批量增发模板",
  pass_all_of_them: "确认要全部通过吗？",
  Fill_in_at_least: "增发货币、增发经验值至少填写其中一项",
  quantity_of_additional_currency: "请输入增发货币数量",
  money_111: "金额",
  Input_cannot_be_greater: "输入不能大于99999或小于1",
  Recharge_status: "充值状态",
  Gold_coins: "金币",
  Alipay_payment: "支付宝支付",
  WeChat_payment: "微信支付",
  ApplePay: "苹果支付",
  Unpaid_111: "未支付",
  Paid_111: "已支付",
  Balance_ranking: "余额排行",
  ranking_111: "排名",
  Member_Change_Record: "会员变更记录",
  phone_area_code: "请设置手机区号",
  your_phone_number: "请设置手机号",
  Successfully_lifted: "解除禁言成功",
  Risk_categories: "风险类别",
  No_type_selected: "未选择类型",
  hint2222: "移除权限后，该主播直播间将不再进入机器人，确定移除吗",
  hint2223: "禁用后该活动不在策略中的红包雨库中展示，是否确定禁用此活动？",
  hint2224: "启用后该活动仅将在策略中的红包雨库中展示，是否确定启用此活动？",
  Uploader: "上传人",
  hint2225: "不能大于99999",
  hint2226: "金蛋1",
  hint2227: "15分钟",
  member_limit: "会员限定",
  member_limit_info: "请选择会员限定",
  hint2228: "竞足推荐",
  hint2229: "内容分配",
  hint2230: "商户名称",
  hint2231: "是否分配锦囊",
  hint2232: "分配内容形式",
  hint2233: "是否代运营内容",
  hint2234: "是否分配全部内容",
  hint2235: "原创内容分配范围",
  hint2236: "是否分配无标签内容",
  hint2237: "是否分配全部有标签内容",
  hint2238: "一级标签分配范围",
  hint2239: "当前标签",
  hint2240: "标签库",
  hint2241: "当前栏全选",
  hint2242: "全部内容形式",
  hint2243: "不分配内容",
  hint2244: "仅图文",
  hint2245: "仅视频",
  hint2246: "全部原创",
  hint2247: "不分配原创内容",
  hint2248: "图文，视频",
  hint2249: "是否分配全部无标签内容",
  hint2250: "是否分配全部有标签内容",
  hint2251: "一级标签",
  hint2252: "请输入标签搜索",
  hint2253: "内容形式",
  hint2254: "图文",
  hint2255: "视频",
  hint2256: "标签编辑",
  hint2257: "二级标签",

  hint2258: "创建标签",
  hint2259: "编辑标签",
  hint2260: "标签级别",
  hint2261: "一级",
  hint2262: "二级",
  hint2263: "对应一级标签",
  hint2264: "展开",
  hint2265: "标签状态",
  hint2266: "批量代发布",
  hint2267: "批量代下架",
  hint2268: "标题关键字",
  hint2269: "获取时间",
  hint2270: "爬取",
  hint2271: "赛事中心",
  hint2272: "原创",
  hint2273: "源发布时间",
  hint2274: "搜索有标签内容",
  hint2275: "代发布状态",
  hint2276: "发布状态变更时间",
  hint2277: "请输入标题关键字",
  hint2278: "审核退回",
  hint2279: "请输入一级标签搜索",
  hint2281: "请输入二级标签搜索",
  hint2282: "是否确认要退回该条内容数据？",
  hint2283:
    "退回后，商户将暂时无法查询到本条图文内容，可在图文采集库再次审核通过。",
  hint2284: "是否确认要代商户发布本组内容数据？",
  hint2285: "是否确认要代商户下架本条内容数据？",
  hint2286: "退回",
  hint2287: "发布",
  hint2288: "下架",
  hint2289: "新增原创",
  hint2299: "请选择分类",
  hint2290: "审核时间",
  hint2291: "内容编辑",
  hint2292: "视频编辑",
  hint2293: "前瞻",
  hint2294: "精华",
  hint2295: "娱乐",
  hint2296: "上传视频",
  hint2297: "格式限制为mp4或flv文件，文件大小以小为佳，限传一个",
  original_content_img: "原内容中的图片",
  Custom_Image: "自定义图片",
  insert_the_cover: "请插入封面图",
  Please_improve_the_title: "请完善标题",
  upload_the_video: "请上传视频",
  upload_the_cover_image: "请上传封面图",
  result_for_this_content: "请选择本条内容的审核结果",
  to_the_distribution_repository: "审核通过的，该条内容数据才会提交至分发库",
  reviewed_again: "审核拒绝的，可再次进行审核",
  Set_crawling_time: "设置爬取时间",
  What_crawling_task: "用该爬取任务？",
  Is_it_true: "是否",
  Please_refine_the_author: "请完善作者",


  // 小助手
  Edit: "编辑",
  User_Configuration: "用户配置",
  Please_enter_a_numerical_level: "请输入数字等级",
  hint_4: "低于该等级不允许设置包含英文字母昵称",
  Nickname_English_letter_level_limit: "昵称英文字母等级限制",
  Live_broadcast_room_configuration: "直播间配置",
  hint_6: "低于该等级则不允许直播间聊天",
  hint_5: "低于该等级直播间聊天英文字母被替换*",
  Open: "开启",
  Close: "关闭",


  Circle_Speech_Configuration: "圈子发言配置",
  hint_7: "低于该等级则不允许圈子内私信主播",
  Speech_level_limit: "发言等级限制",
  hint_8_: "低于该等级则不允许圈子内聊天发言",
  Posting_updates_submission_levels: "发布动态/投稿等级",
  hint_9: "低于该等级则不允许发布动态/投稿",
  hint_911: "发布动态/投稿",
  Cancel_1: "取消",
  Save_1: "保存",
  APP_bottom_label: "APP底部标签",
  Initial_icon: "初始icon",
  Activity_icon: "活动icon",
  World_Cup_icon: "世界杯icon",
  The_bottom_marketing_position_of_the_APP: "APP底部营销位",
  Please_enter_the_redirect_address: "请输入跳转地址",
  Event_start_time: "活动开始时间",
  APP_homepage_my_background: "APP首页、我的背景",
  hint_10: "尺寸： 1125*624，只能上传jpg/png文件",
  Operation: "操作",
  Upload: "上传",
  Ordinary_user_currency_icon: "普通用户货币icon",
  Size_1: "尺寸",
  Type: "类型",
  Preview_image: "预览图",
  Live_settlement_currency_icon: "直播结算货币icon",
  Brocade_Bag_Settlement_Currency_icon: "锦囊结算货币icon",
  hint_11: "支持格式：PNG，大小：500K以内",
  Android_Triple_Image: "Android三倍图",
  Top_of_homepage_top_of_activity_page: "首页顶部、活动页顶部",
  At_the_top_of_the_competition_page_my: "赛事页顶部、我的",
  Signal_source_top: "信号源顶部",
  Please_select: "请选择",
  Please_upload: "请上传",
  Operation_successful: "操作成功",
  Can_only_upload_can_only_upload_PNG_files: "只能上传只能上传png文件",
  hint_800: "上传大小不能超过 500K!",
  Login_address_configuration: "登录地址配置",
  Please_enter_your_login_address: "请输入登录地址",
  hint_12: "此配置仅针对推流端使用商户侧用户系统生效，配置后，推流端支持识别商户侧自有用户名及密码",
  Recommended_by_the_editorial_circle: "编辑圈子推荐",
  Please_enter_the_resource_name: "请输入资源名称",
  Circle_List_Recommendation: "圈子列表推荐",
  Recommended_by_Square_Circle: "广场圈子推荐",
  Please_enter_userid: "请输入userid",
  Please_enter_the_IP_address: "请输入IP",
  Search: "搜索",
  Refresh_IP_blacklist_cache: "刷新IP黑名单缓存",
  Reset: "重置",
  Loading: "加载中...",
  Merchant_ID_1: "商户编号",
  Prohibition_time_1: "封禁时间",
  Remove: "移除",
  hint_743: "确定要移除此IP吗？",
  APP_brand_name: "APP品牌名称",
  Default_username_prefix: "默认用户名前缀",
  Common_user_currency_name: "普通用户货币名称",
  Live_settlement_currency_name: "直播结算货币名称",
  The_name_of_the_settlement_currency_for_the_brocade_bag: "锦囊结算货币名称",
  Contact_customer_service: "联系客服",
  Assistant_default_icon: "小助手默认图标",
  Click_to_select_resources: "点击选取资源",
  hint_13: "只能上传svga文件，图片大小不能超过800k",
  Assistant_unread_message_icon: "小助手未读消息图标",
  Assistant_displays_icon_size: "小助手显示图标尺寸",
  Small: "小",
  Medium: "中",
  Big: "大",
  Assistant_display_time: "小助手展示时间",
  Online_customer_service_address: "在线客服地址",
  Initialize_account: "初始化账号",
  Anchor_tool_download_address: "主播工具下载地址",
  ICP_Filing_Information: "ICP备案信息",
  Live_broadcast_room_default_message: "直播间默认消息",
  Conversation_background: "语聊背景",
  Default_official_anchor_profile_picture: "默认官方主播头像",
  Default_official_message_avatar: "默认官方消息头像",
  Login_registration_page_background: "登录注册页面背景",
  hint_14: "支持格式：JPG、PNG、GIF、MP4，大小：3M以内",
  Delete: "删除",
  Triple_plot: "三倍图",
  Double_plot: "二倍图",
  Please_fill_in_the_brand_name: "请填写品牌名称",
  Please_fill_in_the_prefix: "请填写前缀",
  Please_fill_in_the_currency_name: "请填写货币名称",
  Please_fill_in_customer_service_WeChat: "请填写客服微信",
  Please_fill_in_the_download_address_for_the_anchor_tool: "请填写主播工具下载地址",
  Please_fill_in_the_ICP_information: "请填写ICP信息",
  Please_fill_in_the_default_message_for_the_live_broadcast_room: "请填写直播间默认消息",
  Please_fill_in_the_assistant_display_time: "请填写小助手展示时间",
  hint_15: "上传尺寸有误，请按格式要求上传!",
  hint_16: "只能上传只能上传png文件!",
  The_upload_size_cannot_exceed: "上传大小不能超过",
  hint_17: "传大小不能超过 800K!",
  Select: "选取",
  Please_upload_the_edited_Excel_template_file: "请上传编辑好的excel模板文件",
  Confirm: "确认",
  Importing: "导入中",
  Please_upload_the_file: "请上传文件",
  Only_xlsx_format_files_can_be_uploaded: "只能上传.xlsx格式文件!",
  hint_742: "上传图片大小不能超过 500MB!",
  Successfully_added: "添加成功",
  Import_member_failure_list: "导入会员失败名单",
  OK: "确定",
  Select_file: "选择文件",
  Please_enter_the_ID: "请输入id",
  User_nickname: "用户昵称",
  hint_740: "请输入用户昵称",
  User_phone_number: "用户手机号",
  hint_739: "请输入用户手机号",
  User_level: "用户等级",
  Please_enter_user_level: "请输入用户等级",
  Membership_level: "会员等级",
  Game_users: "游戏用户",
  Status: "状态",
  Registration_time: "注册时间",
  Identity: "身份",
  User_type: "用户类型",
  Inviter_userid: "邀请人userid",
  hint_738: "请输入邀请人userid",
  Registration_Platform: "注册平台",
  hint_741: "请输入注册平台网址",
  Batch_import_of_members: "批量导入会员",
  Download_batch_import_templates: "下载批量导入模板",
  Export_data: "导出数据",
  Total_number_of_registered_users: "总注册用户数",
  Number_of_registered_users_today: "今日注册用户数",

  // 错误开始
  nickname: "昵称",
  Avatar: "头像",
  Mobile_area_code: "手机区号",
  Mobile_phone_number: "手机号码",
  Third_party_binding: "第三方绑定",
  Recent_login_time: "最近登录时间",
  Recently_logged_in_IP: "最近登录IP",
  Last_visited_time: "最近访问时间",
  Anchor: "主播",
  Experts: "专家",
  Anchor_level: "主播等级",
  Ban: "封禁",
  Enable: "启用",
  Detailed: "详细",
  Prohibition: "禁言",
  Reset_password: "重置密码",
  Personal_information_1: "的个人资料",
  Mobile_phone_number_and_area_code: "手机号区号",
  Settings: "设置",
  Phone_number: "手机号",
  Please_enter_your_phone_number: "请输入手机号",
  User_status: "用户状态",
  Normal_1: "正常",
  hint_737: "是否主播",
  hint_736: "是否锦囊专家",
  Recommendation_code: "推荐码",
  Personal_signature: "个人签名",
  User_UID: "用户UID",
  Prohibition_period_: "禁言周期",
  Release_the_ban: "解除禁言",
  Reasons_for_Prohibition: "禁言原因",
  Please_select_the_reason_for_the_ban: "请选择禁言原因",
  Delete_chat_messages: "删除聊天消息",
  Delete_all_chat_messages_in_the_live_broadcast_room: "删除所有直播间聊天消息",
  hint_735: "确定要封禁此账户吗？",
  Is_IP_banned_: "是否封禁IP",
  Current_level: "当前等级",
  Operation_time: "操作时间",
  Operator: "操作人",
  No: "否",
  Yes: "是",
  C_end_user_data: "c端用户数据",
  Batch_import_of_member_templates: "批量导入会员模板",
  Account_has_been_banned: "账户已封禁",
  hint_734: "确定要启用此账户吗？",
  Prompt: "提示",
  Account_enabled: "账户已启用",
  hint_733: "确认重置该用户密码吗？初始密码为：Aa123456",
  Password_reset_successful: "重置密码成功",
  Password_reset_failed: "重置密码失败",
  Download_the_homepage_logo_: "下载页首屏Logo：",
  Click_to_select_image: "点击选取图片",
  hint_20: "只能上传jpg/png文件，图片尺寸需为344X120，最多1张",
  hint_21: "只能上传jpg/png文件，图片尺寸需为288X100，最多1张",
  Download_the_top_screen_background_image: "下载页首屏背景图",
  hint_22: "只能上传jpg/png文件，图片尺寸需为375X732，最多1张",
  Download_the_top_screen_copy_image: "下载页首屏文案图",
  hint_23: "只能上传jpg/png文件，图片尺寸需为1980x1650，最多1张",
  Download_page_application_introduction_image: "下载页应用介绍图",
  hint_24: "只能上传jpg/png文件，图片尺寸需为360X842，最多4张",
  Download_page_application_introduction: "下载页应用介绍",
  Download_page_features: "下载页特色功能",
  PWA_configuration: "pwa配置",
  Only_json_files_can_be_uploaded: "只能上传.json文件",
  Download_floating_bar_description: "下载悬浮条描述",
  New_H5_logo: "新H5-logo",
  hint_732: "图片尺寸建议为40x40",
  New_H5_Download_floating_bar: "新H5-下载悬浮条",
  hint_731: "图片尺寸建议为400x60",
  Youmeng_Statistics: "友盟统计",
  Please_enter_the_URL_corresponding_to_src: "请输入src对应的url",
  hint_25: "如需统计多个域名，请用||区分",
  Baidu_Statistics: "百度统计",
  Version_download_address: "版本下载地址",
  Quick_Version: "快捷版",
  Please_enter_the_iOS_shortcut_address: "请输入iOS快捷版地址",
  Please_enter_Android_Scheme: "请输入Android Scheme",
  H5_Station_Download_Jump_Status: "H5站下载跳转状态",
  APP_H5_Album_Page_Name: "APP/H5专辑页名称",
  Please_enter_a_name: "请输入名称",
  APP_H5_Album_Page_Link: "APP/H5专辑页链接",
  Please_upload_pictures: "请上传图片",
  Please_upload_the_PWA_configuration_file: "请上pwa配置文件",
  Circle_: "圈子{num}",
  Please_enter_the_circle_ID: "请输入圈子ID",
  Please_fill_in_the_Android_app_download_address: "请填Android APP下载地址",
  Please_fill_in_the_iOS_app_download_address: "请填iOS APP下载地址",
  Please_fill_in_the_application_introduction: "请填写应用介绍",
  Please_fill_in_the_featured_features: "请填写特色功能",
  Please_fill_in_the_description_1: "请填写描述内容",
  hint_730: "请填写iOS稳定版下载地址",
  Please_fill_in_the_Android_Scheme: "请填写Android Scheme",
  Please_fill_in_the_iOS_Scheme: "请填写iOS Scheme",
  hint_18: "只能上传只能上传jpg、jpeg或png文件",
  Upload_up_to_1_image: "最多上传1张",
  hint_19: "只能上传只能上传jpg、jpeg或png文件!",
  The_upload_size_cannot_exceed_1_5M_: "上传大小不能超过 1.5M!",
  Only_json_format_files_can_be_uploaded_: "只能上传.json格式文件!",
  The_size_cannot_exceed_1MB_: "大小不能超过 1MB!",
  IOS_double_plot: "IOS二倍图",
  Protocol_identifier_ID: "协议标识ID",
  Protocol_identifier: "协议标识",
  Protocol_Name: "协议名称",
  Last_editing_date: "最后编辑日期",
  Release_status: "发布状态",
  H5_download_page_address: "H5下载页面地址",
  Application_Introduction: "应用介绍",
  Image_and_Text_Configuration: "图文配置",
  Page_: "第{num}张",
  Only_jpg_png_files_can_be_uploaded: "只能上传jpg/png文件",
  The_size_is_394_X798: "尺寸为394X798",
  Please_enter_the_application_introduction: "请输入应用介绍",
  Please_enter_the_address: "请输入下地址",
  The_title_of_the_th_page_cannot_be_empty: "第${num}张标题不能为空",
  Logo_icon: "logo-图标",
  Logo_Title: "logo-标题",
  The_recommended_image_size_is_140_x70: "图片尺寸建议为140x70",
  Navigation_bar_activity_map: "导航栏活动图",
  hint_307: "尺寸： 312*126，只能上传jpg/png文件",
  PC_download_copy: "PC下载文案",
  hint_26: "请输入第一行文案，不超过10个字符",
  hint_27: "请输入第二行文案，不超过10个字符",
  Page_title_: "页面title：",
  Please_enter_a_title: "请输入标题",
  Page_keywords: "页面关键词",
  Page_Description: "页面描述",
  Please_enter_a_description: "请输入描述",
  Please_enter_the_download_address_for_the_anchor_tool: "请输入主播工具下载地址",
  H5_download_page_address_1: "h5下载页地址",
  Please_enter_the_h5_download_page_address: "请输入h5下载页地址",
  Anchor_Recruitment_Description: "主播招聘描述",
  Please_enter_the_anchor_recruitment_description: "请输入主播招聘描述",
  Anchor_Recruitment_QR_Code: "主播招聘二维码",
  The_recommended_image_size_is_256x256: "图片尺寸建议为256x256",
  Contact_email_1: "联系邮箱",
  Please_enter_your_contact_email: "请输入联系邮箱",
  Bottom_copyright_information: "底部版权信息",
  Please_enter_copyright_information: "请输入版权信息",
  PC_Link_Configuration: "PC端链接配置",
  Please_enter_the_PC_domain_name_link_configuration: "请输入PC端域名链接配置",
  Site_icon: "站点icon",
  Click_to_select: "点击选取",
  hint_28: "只能上传ico文件，图片尺寸需为32x32，大小不超过20kb",
  Please_fill_in_the_statistics_link: "请填写统计链接",
  Customer_service_code_: "客服代码",
  Please_fill_in_the_customer_service_code: "请填写客服代码",
  PC_album_page_status: "PC专辑页状态",
  PC_album_page_link: "PC专辑页链接",
  Please_fill_in_the_title: "请填写标题",
  Please_fill_in_the_description: "请填写描述",
  Please_fill_in_the_keywords: "请填写关键词",
  Please_fill_in_the_record_number_document: "请填写备案号文案",
  Please_fill_in_your_email_address: "请填写邮箱",
  Please_fill_in_the_h5_download_page_address: "请填写h5下载页地址",
  Please_select_the_Feixin_QR_code_image: "请选择飞信二维码图片",
  Please_upload_the_logo_icon: "请上传logo图标",
  Please_upload_the_logo_title: "请上传logo标题",
  Please_enter: "请输入",
  Only_ICO_files_can_be_uploaded_: "只能上传ico文件!",
  hint_729: "上传图片大小不能超过 20KB!",
  Please_enter_the_theme_name: "请输入主题名称",
  Add_a_new_theme: "新增主题",
  There_is_currently_no_content: "暂无内容",
  Last_modification_time: "最后修改时间",
  Please_enter_sorting: "请输入排序",
  Are_you_sure_you_want_to_delete_it_: "是否确认删除？",
  Delete_successful: "删除成功",
  Theme: "主题",
  Publish: "发布",
  Please_select_a_theme: "请选择主题",
  Please_enter_the_content_of_the_agreement: "请输入协议内容",
  Please_enter_the_protocol_name: "请输入协议名称",
  hint_728: "是否确认发布协议信息",
  hint_727: "是否确认保存协议信息",
  User_Registration_Agreement: "用户注册协议",
  User_Privacy_Agreement: "用户隐私协议",
  Live_streaming_protocol: "直播协议",
  Payment_Agreement: "支付协议",
  Settlement_rules: "结算规则",
  Recharge_Service_Agreement: "充值服务协议",
  About: "关于",
  Please_select_the_protocol_type: "请选择协议类型",
  Login_Page_Logo: "登录页Logo",
  hint_29: "只能上传jpg/png文件，图片尺寸需为109X140，最多1张",
  Main_interface_logo: "主界面Logo",
  hint_30: "只能上传jpg/png文件，图片尺寸需为69X67，最多1张",
  About_Page_Logo: "关于页面Logo",
  hint_31: "只能上传jpg/png文件，图片尺寸需为109X109，最多1张",
  hint_32: "上传图片尺寸有误，请按格式要求上传!",
  Add_TF_package: "添加TF包",
  Package_name: "包名",
  Download_link: "下载链接",
  Developer_account: "开发者账号",
  Effective_date_1: "生效日期",
  Expiration_date: "失效日期",
  Remaining_valid_days: "剩余有效天数",
  Please_enter_valid_days: "请输入有效天数",
  Effective_days: "有效天数",
  Please_enter_the_package_name: "请输入包名",
  Edit_TF_package: "编辑TF包",
  Please_fill_in_the_download_link: "请填写下载链接",
  Please_select_the_effective_date: "请选择生效日期",
  hint_726: "有效天数必须大于等于90",
  Created_successfully: "创建成功",
  Update_method: "更新方式",
  System: "系统",
  Add_1: "添加",
  Update_type: "更新类型",
  Version_number: "版本号",
  Prompt_package: "提示包",
  Update_package: "更新包",
  Reminder_date: "提醒日期",
  Recently_edited_by: "最近编辑人",
  Last_edited_time: "最近编辑时间",
  Version_update: "版本更新",
  TF_Package_Update: "TF包更新",
  Download_address: "下载地址",
  Select_prompt_package: "选择提示包",
  Please_fill_in_the_download_address: "请填写下载地址",
  Select_Update_Package: "选择更新包",
  Remaining: "剩余",
  Heaven: "天",
  Start_reminder_date: "开始提醒日期",
  Update_content: "更新内容",
  Please_enter_the_updated_content: "请输入更新内容",
  hint_33: "支持格式：PNG、JPG，尺寸要求：750*1624",
  Edit_version_update: "编辑版本更新",
  Add_version_update: "添加版本更新",
  Please_set_an_updated_version: "请设置更新版本",
  Please_set_the_prompt_version: "请设置提示版本",
  Please_select_the_system: "请选择系统",
  Please_choose_a_method: "请选择方式",
  Please_enter_the_content: "请输入内容",
  Please_set_the_reminder_date: "请设置提醒日期",
  hint_725: "上传图片只能是 JPG 或 PNG 格式!",
  hint_724: "上传图片大小不能超过 1MB!",
  Modified_successfully: "修改成功",
  User_Management: "用户管理",
  IP_blacklist: "IP黑名单",
  Application_ID: "应用ID",
  Application_Name: "应用名称",
  Last_modified_date: "最后修改日期",
  Create_an_application: "创建应用",
  View_Key: "查看密钥",
  Module: "模块",
  Supplier: "供应商",
  Add_Module: "添加模块",
  Reset_Key: "重置密钥",
  New_Module: "新建模块",
  Please_enter_the_application_name: "请输入应用名称",
  Please_select_a_module: "请选择模块",
  Please_select_a_supplier: "请选择供应商",
  Please_enter_supplier_configuration: "请输入供应商配置",
  Edit_application: "编辑应用",
  hint_34: "若该应用有使用此模块的功能，删除记录可能会导致功能不可用。确认是否要删除？",
  Start_page: "启动页",
  Change: "变更",
  Only_JPG_or_PNG_format_images_are_allowed_to_be_uploaded: "只允许上传JPG格式或PNG格式图片",
  Are_you_sure_you_want_to_save_the_changes_: "是否确认保存变更",
  hint_320: "密码修改成功，请重新登录",
  Review_version: "审核版本",
  Please_enter_version: "请输入版本",
  Tag_number: "标记号",
  List_Fields: "列表字段",
  Form_Description: "表单说明",
  Remarks: "备注",
  Pre_explanation: "前置说明",
  hint_35: "1.针对ios端提审环境版本控制隐藏功能；2.代码层面规避明显做审核环境控制注释或代码；3.Android端无需控制",
  Version_control: "版本控制",
  hint_36: "1.显示当前需要控制的版本号，例如：当前需要提审的版本为V1.1，则输入框需输入1.1，且下方勾选对应需要隐藏的模块，则在V1.1版本里以下勾选的模块入口均隐藏。2.输入无实际版本的版本号保存，不影响显示任意版本模块；",
  Control_module: "控制模块",
  hint_317: "显示所需要再提审环境隐藏的功能名称，默认勾选状态，取掉勾选，且保存后，则在此版本中不隐藏此模块；",
  Please_fill_in_version_information: "请填写版本信息",
  Select_language: "选择语言",
  General_configuration: "通用配置",
  IOS_configuration: "IOS配置",
  H5_configuration: "H5配置",
  New_download_page_configuration: "新下载页配置",
  Streaming_tool_configuration: "推流工具配置",
  PC_configuration: "PC配置",
  Mobile_streaming_configuration: "移动推流配置",
  Advertising_restriction_configuration: "广告限制配置",
  Virtual_Number_Restrictions: "虚拟号码限制",
  Reporting_restrictions_for_third_party_data_apps: "三方数据APP上报限制",
  Report_domain_name_configuration: "上报域名配置",
  Please_enter_the_domain_name: "请输入域名",
  hint_723: "下载APP首次埋点上报",
  Channel_code_parameter_acquisition: "渠道码参数获取",
  PC_data_reporting: "PC数据上报",
  H5_data_reporting: "H5数据上报",
  Domain_Name_Address: "域名地址",
  Please_enter_the_domain_name_address: "请输入域名地址",
  Deactivate: "停用",
  Please_select_the_type: "请选择类型",
  Are_you_sure_to_enable_the_domain_name_: "确认启用域名吗？",
  hint_722: "确认停用域名吗？",
  Update_successful: "更新成功",
  Recommended: "推荐中",
  Paused: "已暂停",
  Picture_matching: "配图",
  Source: "来源",
  Feedback_type: "反馈类型",
  Number: "序号",
  Feedback_details: "反馈详情",
  Contact_information: "联系方式",
  Feedback_time: "反馈时间",
  Navigation_Station_Switch: "导航站开关",
  Page_Configuration: "页面配置",
  Weight: "权重",
  hint_37: "数字越大排名越靠前",
  Button_Name: "按钮名称",
  Domain_name: "域名",
  Please_enter_the_weight: "请输入权重",
  Please_enter_the_button_name: "请输入按钮名称",
  Mobile_related: "移动端相关",
  PC_related: "PC端相关",
  Personal_information: "个人信息",
  Login_account: "登录账号",
  User_Name: "用户姓名",
  Security_Settings: "安全设置",
  Change_password: "修改密码",
  Original_password: "原密码",
  New_password: "新密码",
  Confirm_new_password: "确认新密码",
  hint_721: "密码长度不能少于6位",
  The_passwords_entered_twice_do_not_match: "两次输入的密码不一致",
  Please_enter_your_password_again: "请再次输入密码",
  Merchant_Basic_Information: "商户基本信息",
  Merchant_ID: "商户ID",
  Enterprise_Name: "企业名称",
  Contact_person: "联系人",
  Contact_email: "联系人邮箱",
  Contact_phone_number: "联系人手机",
  SMS_verification_code: "短信验证码",
  Please_enter_the_merchant_ID: "请输入商户ID",
  Please_enter_the_company_name: "请输入企业名称",
  Please_enter_the_contact_person: "请输入联系人",
  Please_enter_the_contact_person_phone_number: "请输入联系人手机",
  Please_enter_the_contact_email: "请输入联系人邮箱",
  Please_enter_the_verification_code: "请输入验证码",
  Get_verification_code: "获取验证码",
  Please_enter_sensitive_words: "请输入敏感词",
  Batch_Add: "批量添加",
  Query_results: "查询结果",
  hint_720: "抱歉，没有找到该敏感词",
  Activity_Name: "活动名称",

  // 错误end====

  hint_718: "请输入敏感词且长度不能少于两个字符",
  hint_308: "确定删除该关键词？",
  continue_to_be_sent_on_the_platform: "解禁后则该词可继续在平台发送",
  Are_you_sure_to_lift_the_ban_: "确认解禁吗？",
  The_image_size_needs_to_be_40_x40: "图片尺寸需为40x40",
  Brand_name: "品牌名称",
  Main_site_name: "主站名称",
  Please_enter_the_name_of_the_main_site: "请输入主站名称",
  Main_site_redirect_URL: "主站跳转url",
  Please_enter_the_main_site_redirect_URL: "请输入主站跳转url",
  H5_Main_Station_Jump_URL: "H5主站跳转url",
  Please_enter_the_H5_main_site_redirect_URL: "请输入H5主站跳转url",
  Homepage_banner: "首页banner",
  hint_717: "图片尺寸需为1200x150",
  Homepage_banner_URL: "首页banner url",
  Please_enter_the_banner_URL_on_the_homepage: "请输入首页bannerUrl",
  Live_room_banner: "直播间banner",
  hint_716: "请输入直播间bannerUrl",
  Homepage_title: "首页-title",
  Please_enter_the_title: "请输入title",
  Home_page_description_1: "首页-description",
  Homepage_keywords_1: "首页-keywords",
  Please_enter_keywords_1: "请输入keywords",
  Live_room_title: "直播间-title",
  Live_broadcast_room_keywords_1: "直播间-keywords",
  Bottom_copyright_text: "底部版权文字",
  Please_enter_the_copyright_text_at_the_bottom: "请输入底部版权文字",
  APP_download_URL: "APP下载URL",
  Please_enter_the_APP_download_URL: "请输入APP下载URL",
  hint_715: "图片尺寸需为750x94",
  H5_Live_Banner: "H5直播banner",
  Please_enter_h5RoomBannerRedirectUrl: "请输入h5RoomBannerRedirectUrl",
  Please_enter_the_brand_name: "请输入品牌名称",
  Please_enter_the_main_site_redirect: "请输入主站跳转",
  Please_enter_H5_main_site_redirect: "请输入H5主站跳转",
  Please_enter_the_homepage: "请输入首页",
  Please_enter_the_homepage_banner_to_jump_to: "请输入首页 banner 跳转",
  Please_enter_the_homepage_description: "请输入首页描述",
  Please_enter_homepage_keywords: "请输入首页关键词",
  Please_enter_the_live_streaming_page: "请输入直播页",
  hint_714: "请输入直播页描述",
  Please_enter_keywords_for_the_live_streaming_page: "请输入直播页关键词",
  Please_enter_the_website_copyright_statement: "请输入站点版权申明",
  Please_enter_the_app_download_URL: "请输入app 下载 url",
  Please_enter_the_site_h5BannerRedirectUrl: "请输入站点h5BannerRedirectUrl",
  Add_Site: "添加站点",
  Edit_Site: "编辑站点",
  Upload_up_to_1_photo_: "最多上传1张!",
  Home_page_description: "首页description",
  Homepage_keywords: "首页keywords",
  Live_broadcast_room_title: "直播间title",
  Live_broadcast_room_description: "直播间-description",
  Live_broadcast_room_keywords: "直播间-keywords",
  App_download_URL: "App下载URL",
  H5_homepage_banner_URL: "h5首页bannerurl",
  H5_homepage_banner_redirect_URL: "h5首页banner跳转url",
  H5_live_broadcast_room_banner_URL: "h5直播间bannerurl",
  H5_Live_Room_Banner_Jump_URL: "h5直播间banner跳转url",
  Live_streaming_services_1: "直播服务",
  SMS_service: "短信服务",
  Certificate_file_name: "证书文件名",
  Certificate_type: "证书类型",
  General_Supplier_Configuration: "通用供应商配置",
  Whether_to_configure_TF_package_name: "是否配置TF包名",
  Package_Name_Configuration: "包名配置",
  Supplier_Configuration: "供应商配置",
  Edit_Supplier_Configuration: "编辑供应商配置",
  Add_supplier_configuration: "新增供应商配置",
  Please_fill_in_the_configuration_information: "请填写配置信息",
  hint_713: "是否确定删除配置？",
  Please_configure_TF_configuration_correctly: "请正确配置TF配置",
  Version_update_reminder: "版本更新提醒",
  TF_Package_Management: "TF包管理",
  Batch_upload: "批量上传",
  Posting_updates: "发布动态",
  Account: "账号",
  Content: "内容",
  Upload_images: "上传图片",
  Only_jpg_jpeg_png_files_can_be_uploaded: "只能上传jpg/jpeg/png文件",
  Up_to_9_images_can_be_uploaded: "最多支持上传9张",
  Click_to_select_file: "点击选取文件",
  Only_supports_video_format_files: "仅支持视频格式文件",
  Related_circles: "关联圈子",
  Please_select_a_circle: "请选择圈子",
  Related_topics: "关联话题",
  Please_select_a_topic: "请选择话题",
  Please_upload_the_video_file: "请上视频文件",
  Please_select_an_account: "请选择账号",
  hint_712: "上传图片只能是 JPG 或 PNG格式!",
  hint_707: "上传图片大小不能超过 10MB!",
  hint_710: "只能上传mp4格式文件!",
  hint_711: "大小不能超过 200MB!",
  Please_enter_your_nickname: "请输入昵称",
  Joined_the_circle: "已加入圈子",
  Import_time: "导入时间",
  Disabled: "禁用",
  Add_Circle: "添加圈子",
  Cannot_be_greater_than_80: "不能大于80",
  hint_38: "确认禁用该用户吗？禁用后将无法使用该账号发布动态？",
  hint_39: "确认启用该用户吗？启用后将可重新使用该账号发布动态？",
  Dynamic_configuration: "动态配置",
  Publisher_nickname: "发布人昵称",
  Accumulated_likes: "累计点赞数",
  Accumulated_number_of_comments: "累计评论数",
  Topic: "话题",
  Circle: "圈子",
  Release_time: "发布时间",
  Remove_this_comment: "下架此评论",
  Dynamic_permission_configuration: "动态权限配置",
  Publish_dynamic_user_level_configuration: "发布动态用户等级配置",
  Name: "名称",
  Level: "等级",
  hint_703: "此项配置为普通用户发布动态需达到的等级",
  Cannot_be_less_than_1: "不能小于1",
  Please_fill_in_the_user_level: "请填写用户等级",
  There_are_currently_no_related_posts: "暂无相关帖子",
  hint_702: "确认要下架此条帖子评论吗？",
  Comment_removed_successfully: "评论下架成功",
  hint_704: "确定要下架该话题吗？",
  hint_705: "确定要上架该话题吗？",
  Return: "返回",
  hint_706: "你没有权限去该页面",
  hint_309: "如有不满请联系你领导",
  Or_you_can_go: "或者你可以去",
  Go_back_to_the_homepage: "回首页",
  hint_40: "请检查您输入的网址是否正确，请点击以下按钮返回主页或者发送错误报告",
  Return_to_homepage: "返回首页",
  hint_41: "提示：1-权重为正整数，数值越大越靠前；2-默认前端展示，隐藏则前端不展示",
  Anchor_nickname: "主播昵称",
  Please_enter_sorting_weight: "请输入排序权重",
  Display: "显示",
  Hidden: "隐藏",
  Associated_channels_categories: "关联频道/分类",
  Anchor_Name_1: "主播名称",
  Please_enter_the_anchor_name_1: "请输入主播名称",
  Room_number: "房间号",
  Please_enter_the_room_number: "请输入房间号",
  Source_of_flow: "流来源",
  Anchor_profile_picture: "主播头像",
  Source_room_number: "来源房间号",
  Live_streaming_categories: "直播品类",
  Live_streaming_title: "直播标题",
  Stream_address: "流地址",
  Forbidden_broadcasting: "禁播",
  Live_streaming_1: "直播中",
  Ended: "已结束",
  Unlocking: "解禁",
  All: "全部",
  Douyu: "斗鱼",
  Tiger_Fang: "虎牙",
  hint_701: "是否确认解禁该主播？",
  hint_700: "是否确认禁播该主播？",
  Competition_time: "比赛时间",
  Competition_status: "比赛状态",
  Please_enter_keywords_2: "请输入关键字",
  League_Cup_Tournament: "联赛/杯赛",
  The_home_team: "主队",
  The_visiting_team: "客队",
  Event_ID: "赛事ID",
  Anchor_Qingliu_Live_Room: "主播清流直播间",
  Associated_Channel: "关联频道",
  Unknown_status: "状态未知",
  Not_started: "未开始",
  In_progress: "进行中",
  The_competition_has_been_postponed: "比赛推迟",
  Competition_cancelled: "比赛取消",
  Direct_room_number: "直推房间号",
  Progress_time: "进行时间",
  Live_streaming_status: "直播状态",
  Next_broadcast: "下播",
  Binding_successful: "绑定成功",
  hint_699: "确定将该直播下播吗？",
  Anchor_sorting: "主播排序",
  hint_42: "提示：1-权重为正整数，数值越大越靠前；2-默认前端显示，隐藏则前端不展示；",
  Update: "更新",
  Channel_sorting: "频道排序",
  Channel_nickname: "频道昵称",
  Secondary_classification: "二级分类",
  Live_streaming_type: "直播类型",
  hint_698: "关联多项，请使用英文逗号分割",
  Streaming_settings: "推流设置",
  End_all_live_broadcasts: "结束全部直播",
  Signal_source: "信号源",
  Push_flow_room: "推流房间",
  Streaming_Title: "推流标题",
  Push_Streaming_Channel: "推流频道",
  There_are_currently_no_channels_available: "当前暂无频道",
  Streaming_address: "推流地址",
  Streaming_address_server_address_: "推流地址（服务器地址）",
  Copy: "复制",
  Push_stream_code_stream_key_: "推流码（串流秘钥)",
  End_Live: "结束直播",
  hint_321: "如没有可使用的频道，请先：",
  Create_channel: "创建频道",
  You_are_about_to_use_it: "您即将用",
  Start_broadcasting: "进行开播",
  Start_live_streaming: "开始直播",
  Copy_successful: "复制成功",
  hint_697: "请填写完整信息再提交",
  hint_696: "确定要结束直播吗？",
  hint_695: "确定要结束全部直播吗？",
  Choose_a_live_broadcast_room: "选择直播间",
  Live_streaming_room: "直播间",
  League_name: "联赛名称",
  Please_enter_the_league_name: "请输入联赛名称",
  Popular: "热门",
  Normal: "普通",
  The_continent_where_it_is_located: "所在洲",
  Football: "足球",
  Basketball: "篮球",
  Date: "日期",
  Start_date: "开始日期",
  To: "至",
  End_date: "结束日期",
  In_the_competition: "比赛中",
  Delay: "推迟",
  Popular_events: "热门赛事",
  Keywords_1: "关键词",
  Please_enter_keywords: "请输入关键词",
  Batch_editing_of_popular_content: "批量编辑热门",
  Batch_creation_of_reserved_events: "批量创建预约赛事",
  Country_Region: "国家/地区",
  Current_round: "当前轮次",
  Edit_Appointment_Anchor: "编辑预约主播",
  Please_choose_to_create_a_schedule: "请选择创建赛程",
  Please_select_the_associated_channel: "请选择关联频道",
  Basketball_schedule_data: "篮球赛程数据",
  No_selected_items: "无选中项",
  Main_Station_Event_ID: "总台赛事ID",
  Please_enter_the_main_event_ID: "请输入总台赛事ID",
  Third_party_in_game_event_ID: "第三方赛中赛事ID",
  hint_694: "请输入第三方赛中赛事ID",
  There_are_streaming_anchors: "存在推流主播",
  There_is_a_push_flow_room: "存在推流房间",
  Click_on_the_nickname_modification_announcement: "（点击昵称修改公告）",
  Please_select_a_popular_event: "请选择热门赛事",
  Edit_successful: "编辑成功",
  E_sports_streaming: "电竞流",
  Entertainment_stream: "娱乐流",
  Create_Popular: "创建热门",
  Official_Clearflow: "官方清流",
  Anchor_Qingliu: "主播清流",
  Score: "比分",
  The_competition_team: "比赛球队",
  Create_circles: "创建圈子",
  Limit_1M: "限制1M",
  hint_900: "大小790*215px",
  Associate_Creator: "关联创建者",
  Operation_label: "运营标签",
  Add_tags: "新增标签",
  Confirm_creation: "确定创建",
  Label_: "标签{num}",
  Please_select_an_image: "请选择图片",
  Please_select_the_associated_creator: "请选择关联创建者",
  Publisher_userid: "发布人userid",
  Message_content: "消息内容",
  Chat_Room: "聊天室",
  hint_45: "删除后该消息将不在App端的该圈子内展示，确认删除吗？",
  Circle_Introduction: "圈子简介",
  Circle_name: "圈子名称",
  Circle_avatar: "圈子头像",
  Creator_1: "创建者",
  Number_of_people_in_the_circle: "圈子人数",
  The_maximum_number_of_people_in_the_circle: "圈子人数上限",
  Circle_Cover: "圈子封面",
  Circle_dynamics_1: "圈子动态",
  Circle_Announcement: "圈子公告",
  Creation_time: "创建时间",
  Dissolve_the_circle: "解散圈子",
  Dissolved: "已解散",
  Submission_dynamics: "投稿动态",
  User_userid: "用户userid",
  Number_of_comments: "评论数",
  Like_count: "点赞数",
  Preview: "预览",
  Setting_up_circle_announcements: "设置圈子公告",
  Add_Announcement: "添加公告",
  Please_enter_the_link_name: "请输入链接名称",
  Display_link: "展示链接",
  Jump_link: "跳转链接",
  Please_enter_the_jump_link: "请输入跳转链接",
  To_be_reviewed: "待审核",
  Listed: "已上架",
  Offline_1: "已下架",
  Rejected: "已拒绝",
  Deleted: "已删除",
  hint_692: "请填写完整公告链接信息再提交",
  The_circle_has_disbanded: "圈子已解散",
  Are_you_sure_you_want_to_dissolve_this_circle_: "是否确认要解散该圈子？",
  Dissolution: "解散",
  hint_44: "是否确认要{status}该条动态？",
  hint_693: "是否确认要拒绝该条动态？",
  Successfully: "已成功",
  Remove_from_shelves: "下架",
  Listing: "上架",
  Circle_dynamics: "圈子动态数",
  Creator_nickname: "创建者昵称",
  Creator_userid: "创建者userid",
  In_operation: "运营中",
  View_details: "查看详情",
  Chat_messages: "聊天消息",
  Edit_anchor_information: "编辑主播信息",
  Review: "审核",
  Anchor_Name: "主播姓名",
  Guild: "所在公会",
  Please_select_a_guild: "请选择公会",
  Split_ratio: "分成比例",
  Please_select_the_sharing_ratio: "请选择分成比例",
  Basic_salary: "底薪",
  Please_enter_the_base_salary: "请输入底薪",
  Review_details: "审核详情",
  Review_details_1: "详情",
  Please_enter_the_ID_number: "请输入身份证号码",
  Please_enter_the_host_nickname: "请输入主播昵称",
  hint_43: "确定把该用户从群主授权列表中删除吗？",
  Default_sorting: "默认排序",
  Channel_ID: "频道ID",
  Icon: "图标",
  Page_template: "页面模板",
  Tab_name: "tab名称",
  Channel_Type: "频道类型",
  hint_46: "只能上传jpg/png文件，图片尺寸需为120X120",
  Please_enter_a_valid_tab_name_: "请输入有效的tab名称！",
  Enter_tab_name: "输入tab名称",
  Content_ID: "内容ID",
  Please_enter_ID: "请输入ID",
  Content_type: "内容类型",
  Topped: "已置顶",
  Top: "置顶",
  Cancel_Topping: "取消置顶",
  Restore_Display: "恢复显示",
  Expiration_time: "失效时间",
  Select_date_and_time: "选择日期时间",
  Sealing_the_picture: "封图",
  real_time_info: "资讯",
  Short_video: "短视频",
  Dynamic: "动态",
  Brocade_Bag: "锦囊",
  The_expiration_time_has_not_been_set_yet: "还未设置失效时间",
  Please_select_a_future_time: "请选择未来时间",
  Top_management_on_homepage: "首页置顶管理",
  Please_enter_information_keywords: "请输入资讯关键字",
  Keywords: "关键字",
  Batch_publishing: "批量发布",
  Batch_scheduled_release: "批量定时发布",
  Thumbnails: "缩略图",
  Title_1: "标题",
  Crawling_time: "爬取时间",
  hint_47: "你可以选择5分钟后，今后一个星期内的任意时间定时发布，在设定时间之前可以重新编辑和取消",
  Timed_release: "定时发布",
  hint_48: "发布后无法进行修改和撤销，是否确认发布？",
  hint_691: "未选择任何资讯，请选择资讯后再发布！",
  Please_choose_a_reasonable_publishing_time: "请选择合理的发布时间",
  Edit_information: "编辑资讯",
  New_information: "新增资讯",
  Please_fill_in_the_necessary_title_and_author: "请填写必要的标题和作者",
  Information_Title: "资讯标题",
  Comment_content: "评论内容",
  hint_49: "删除后该评论将不在APP端、PC端内展示，确定删除吗？",
  hint_50: "确定取消发布吗？取消后该条资讯将进入草稿箱",
  hint_51: "确定下架吗？下架后移动端将不出现此条资讯！",
  Cancel_Top_Time: "取消置顶时间",
  Publishing_Library: "发布库",
  Top_Library: "置顶库",
  Crawling_website_titles: "爬取网站标题",
  Crawling_website_links: "爬取网站链接",
  Crawling_time_period: "爬取时段",
  Set_crawling_time_period: "设置爬取时间段",
  End_time: "结束时间",
  Select_a_time_range: "选择时间范围",
  hint_52: "禁用后则不爬取该网站数据，是否确认禁用？",
  hint_690: "确定要启用此配置吗？",
  hint_689: "确定要删除此条资讯吗？",
  Prize_Name: "奖品名称",
  Please_enter_the_name_of_the_prize: "请输入奖品名称",
  Prize_type: "奖品类型",
  Query: "查询",
  Add_prizes_1: "新增奖品",
  Prize_icon: "奖品icon",
  Please_enter_the_number_of_prizes: "请输入奖品数量",
  quantity: "数量",
  Only_jpg_png_gif_files_can_be_uploaded: "只能上传jpg/png/gif文件",
  Modify: "修改",
  New: "新增",
  Virtual_currency: "虚拟货币",
  Experience_value: "经验值",
  hint_53: "上传图片只能是 JPG 或 PNG 或 GIF格式!",
  Winning_number: "中奖编号",
  Please_enter_the_winning_number: "请输入中奖编号",
  UID: "UID",
  Please_enter_UID: "请输入UID",
  Obtain_time: "获得时间",
  Export: "导出",
  hint_688: "直播间砸金蛋-奖品记录",
  Basic_Settings: "基本设置",
  Red_envelope_rain_name: "红包雨名称",
  Edit_Red_Envelope_Rain_Library: "编辑红包雨库",
  New_Red_Envelope_Rain_Warehouse: "新建红包雨库",
  Please_enter_the_name_of_the_red_envelope_rain: "请输入红包雨名称",
  Trigger_type: "触发类型",
  Goal_scoring_events: "进球事件",
  Event_timeline: "赛事时间点",
  Maximum_number_of_red_envelope_rain_wheels: "红包雨轮数上限",
  Please_enter_the_number_of_rounds: "请输入轮数",
  hint_54: "0为不下红包雨，此为一场比赛最多红包雨轮数的上限",
  hint_687: "最多中红包个数(单个用户单轮)",
  hint_55: "0为抢不到红包，达到红包个数数后则不会再抢到红包",
  Fixed_point_in_time: "固定时间点",
  Select_date: "选择日期",
  Probability_of_winning_a_prize_red_envelope: "抽中有奖红包概率",
  Please_enter_the_probability: "请输入概率",
  hint_56: "0为不可能抽中红包，此为用户抽中有奖红包的概率",
  hint_686: "请输入红包雨轮数上限",
  Award_Pool_Settings: "奖池设置",
  Add_prizes: "添加奖品",
  Number_of_individual_red_envelope_rewards: "单个红包奖励个数",
  Please_enter_the_number: "请输入个数",
  Number_of_red_envelopes: "红包数量",
  Please_enter_the_quantity: "请输入数量",
  Prize_weight: "奖品权重",
  Please_fill_in_the_fixed_time_point: "请填写固定时间点",
  Please_select_a_time_point_for_the_competition: "请选择赛事时间点",
  hint_685: "仅可配置0 - 100的正整数数字",
  Please_add_at_least_one_prize: "请至少添加一条奖品",
  hint_684: "请填写完整奖品信息再提交",
  hint_57: "输入有误，单个红包奖励个数、红包数量仅可配置1 - 99999的正整数数字",
  Add_has_reached_the_limit: "添加已达到上限",
  Golden_Egg_Switch: "金蛋开关",
  Basic_Information_Configuration: "基本信息配置",
  APP_background_image: "APP背景图",
  APP_Title_Image: "APP标题图",
  The_total_probability_must_be_10000: "概率总和必需为10000",
  Red_envelope_rain_storage_details: "红包雨库详情",
  hint_58: " 0为不下红包雨，此为一场比赛最多红包雨轮数的上限",
  Wheel: "轮",
  Pieces: "个",
  Task_type: "任务类型",
  Reward_Name: "奖励名称",
  Task_Title: "任务标题",
  Task_icon: "任务icon",
  Task_count: "任务次数",
  Application_channels: "应用渠道",
  Edit_task: "编辑任务",
  Reward_type: "奖励类型",
  Reward_quantity: "奖励数量",
  Please_enter_the_number_of_rewards: "请输入奖励数量",
  Please_select_the_reward_type_icon: "请选择奖励类型icon",
  Please_select_the_application_channel: "请选择应用渠道",
  Task_title_cannot_be_empty: "任务标题不能为空",
  Task_count_cannot_be_empty: "任务次数不能为空",
  To_be_effective: "待生效",
  In_effect: "生效中",
  Daily_tasks: "每日任务",
  Novice_Task: "新手任务",
  Sign_in_task: "签到任务",
  Attendance_Record: "签到记录",
  Please_enter_the_task_title: "请输入任务标题",
  Check_in_switch: "签到开关",
  No_1: "第",
  Number_of_check_in_days: "签到天数",
  Reward_details: "奖励明细",
  Reward_icon: "奖励icon",
  Edit_check_in: "编辑签到",
  Lottery: "彩金",
  Physical_object: "实物",
  Please_upload_the_reward_type_icon: "请上传奖励类型icon",
  Sign_in_has_been_closed: "已关闭签到",
  Check_in_has_been_opened: "已打开签到",
  Please_fill_in_the_quantity: "请填写数量",
  Please_enter_the_room_number_for_sending: "请输入发送房间号",
  Send_room_number: "发送房间号",
  Sending_time: "发送时间",
  Details_of_receiving_red_envelopes: "红包领取详情",
  hint_683: "暂无红包领取详情",
  Anchor_room_number: "主播房间号",
  Please_enter_the_host_room_number: "请输入主播房间号",
  Remove_permissions: "移除权限",
  Add_anchor: "添加主播",
  Time: "时间",
  Anchor_userID: "主播userID",
  Please_enter_the_anchor_userID: "请输入主播userID",
  Betting_amount: "投注金额",
  Prophecy_betting_list: "预言投注列表",
  Please_enter_the_name_of_the_main_team: "请输入主队名称",
  Please_enter_the_name_of_the_visiting_team: "请输入客队名称",
  Please_enter_the_status: "请输入状态",
  Team_PK_ID: "球队PK ID",
  The_winner: "胜方",
  Number_of_gift_givers_1: "送礼人数",
  Total_value_in_gold_coins: "总价值(金币)",
  The_losing_side: "败方",
  Binding_events: "绑定赛事",
  Please_enter_the_binding_event: "请输入绑定赛事",
  Bind_anchor: "绑定主播",
  Please_enter_the_bound_anchor: "请输入绑定主播",
  Activity_Strategy_Name: "活动策略名称",
  hint_682: "请输入活动策略名称",
  Red_envelope_rain_claim_details: "红包雨领取详情",
  Effective_anchor_room_details: "生效主播房间详情",
  To_be_published: "待发布",
  Published: "已发布",
  Canceled: "已取消",
  hint_681: "是否确认取消发布",
  Main_switch: "总开关",
  Policy_Name: "策略名称",
  Please_enter_a_policy_name: "请输入策略名称",
  Add_Policy: "添加策略",
  Red_envelope_rain_storage: "红包雨库",
  Red_envelope_rain_type: "红包雨类型",
  Event_red_envelope_rain: "赛事红包雨",
  Live_broadcast_room_chat_room: "直播间/语聊室",
  Event_type: "赛事类型",
  Distribute_users: "下发用户",
  hint_60: "请输入用户UID，多个UID使用英文逗号隔开",
  Please_select_the_type_of_red_envelope_rain: "请选择红包雨类型",
  Please_select_an_event: "请选择赛事",
  Please_select_the_red_envelope_rain_storage: "请选择红包雨库",
  Edit_Policy: "编辑策略",
  Strategy_details: "策略详情",
  hint_61: "下线该活动策略将导致该活动从前端下线，确定下线吗",
  Offline_2: "下线",
  Live_room_room_number: "直播间房间号",
  hint_680: "请输入直播间房间号",
  Configuration_Management: "配置管理",
  Prize_configuration: "奖品配置",
  Prize_Record: "奖品记录",
  Anchor_cumulative_share: "主播累计分成",
  Option_A_cumulative_betting: "选项A累计投注",
  Option_A: "选项A",
  Option_B: "选项B",
  Option_B_cumulative_betting: "选项B累计投注",
  Results: "结果",
  Number_of_bets: "投注人数",
  Anchor_sharing_3: "主播分成（3%）",
  Platform_share_2: "平台分成（2%）",
  The_anchor_prophecy: "主播预言",
  The_prophecy_of_the_whole_nation: "全民预言",
  Login_time: "登录时间",
  Online_duration: "在线时长",
  Inviters_nickname: "邀请人昵称",
  Invitee_UID_: "被邀请人UID：",
  Invitation_time: "邀请时间",
  Inviter_payment_method: "邀请人收款方式",
  Alipay: "支付宝",
  WeChat_account: "微信号",
  QQ_account: "QQ账号",
  Inviting_person_rewards: "邀请人奖励",
  Distributed: "已派发",
  Not_passed: "未通过",
  Accumulated: "累计中",
  Invitee_nickname: "被邀请人昵称",
  Invitee_rewards: "被邀请人奖励",
  Select_distribution_type: "选择派发类型",
  Please_enter_a_rule_description: "请输入规则说明",
  Are_you_sure_to_cancel_the_distribution_: "确认取消派发吗？",
  APP_user_login_statistics: "APP用户登录统计",
  hint_676: "邀请人奖励不能小于0",
  hint_677: "邀请人奖励不能大于99999",
  hint_675: "被邀请人奖励不能小于0",
  hint_678: "被邀请人奖励不能大于99999",
  Room_type: "房间类型",
  The_ban_was_successful: "禁言成功",
  hint_62: "删除后该消息将不在App端、PC端、H5端的该直播间/聊天室内展示，确定隐藏吗？",
  Please_enter_the_user_UID: "请输入用户UID",
  Prohibition_time: "禁言时间",
  Forbidden_live_broadcast_room: "禁言直播间",
  Prohibition_type: "禁言类型",
  Prohibition_reasons: "禁言理由",
  Operator_UID: "操作人UID",
  Successfully_unblocked: "解禁成功",
  Opening_duration: "开播时长",
  The_duration_of_the_current_live_broadcast: "当前直播的直播时长",
  Current_number_of_views: "当前浏览次数",
  hint_674: "当前直播的浏览次数（去重）",
  Current_number_of_online_users: "当前在线人数",
  hint_673: "当前直播的在线观看人数（去重）",
  The_highest_number_of_online_users: "最高在线人数",
  hint_672: "当前直播的最高在线人数（去重）",
  hint_63: "统计包含未登录用户，未登录用户未去重。",
  Field_Description: "字段说明",
  Anchor_UID: "主播UID",
  Live_Channel: "直播频道",
  Start_time_1: "开播时间",
  Downtime: "下播时间",
  Opening_duration_minutes_: "开播时长(分钟)",
  Accumulated_views: "累计浏览次数",
  Accumulated_number_of_online_users: "累计在线人数",
  Average_viewing_time_minutes_: "平均观看时长(分钟)",
  The_entire_live_broadcast_data: "整场直播数据",
  Edit_data: "编辑资料",
  Add_announcement_link: "添加公告链接",
  Announcement_link: "公告链接",
  Please_enter_the_display_link: "请输入展示链接",
  Recommended_display: "推荐展示",
  Browser_Open: "浏览器打开",
  Display_end: "显示端",
  Mobile_end: "移动端",
  PC_end: "PC端",
  Application_time: "申请时间",
  Global_announcement_adjustment: "全局公告调整",
  Sports_type: "体育类型",
  On_the_front_of_the_ID_card: "身份证正面",
  On_the_back_of_the_ID_card: "身份证反面",
  Holding_an_ID_card: "手持身份证",
  Reason_for_rejection: "拒绝原因",
  Lifting_the_ban: "解除封禁",
  Refused_to_pass: "拒绝通过",
  Please_enter_the_anchor_name: "请输入主播姓名",
  ID_number: "身份证号",
  Front_photo_of_ID_card: "身份证正面照",
  Back_photo_of_ID_card: "身份证反面照",
  Housing_Management_Uid: "房管Uid",
  Please_enter_the_housing_management_Uid: "请输入房管Uid",
  Housing_management_nickname: "房管昵称",
  hint_671: "请输入房管昵称",
  Add_property_management: "添加房管",
  Cancel_housing_management: "取消房管",
  Please_enter_your_name: "请输入姓名",
  hint_670: "姓名请输入汉字",
  Approved: "审核通过",
  Rejected_for_review: "审核拒绝",
  Prohibited: "已封禁",
  The_ID_photo_is_blurry: "证件照模糊",
  The_ID_number_does_not_match_the_ID_photo: "身份证号码与证件照不匹配",
  My_ID_card_does_not_match_me: "身份证与本人不匹配",
  ID_card_number_is_blocked: "身份证号码遮挡",
  Facial_occlusion: "人脸遮挡",
  Room: "房间",
  Please_select_the_reason: "请选择原因",
  hint_669: "请输入要替换的公告名称或链接",
  Are_you_sure_you_want_to_pass_the_review_: "确定要审核通过吗？",
  hint_301: "确定恢复该主播直播权限吗？",
  Are_you_sure_you_want_to_ban_this_anchor_: "确定将该主播封禁吗？",
  Main_account_UID: "主账号UID",
  Please_enter_the_main_account_UID: "请输入主账号UID",
  Merge_account_UID: "合并账号UID",
  Please_enter_the_merged_account_UID: "请输入合并账号UID",
  Add_Merge: "新增合并",
  Master_account_nickname: "主账号昵称",
  Merge_account_nicknames: "合并账号昵称",
  Merge_time: "合并时间",
  hint_64: "合并账号后，被合并主播账号开播，将使用主账号进行上播展示",
  Merge_accounts: "合并账号",
  Refresh: "刷新",
  hint_668: "注：在线数据略有延迟",
  Note: "注",
  Basic_information_1: "基础信息",
  Anchor_Information: "主播信息",
  Real_time_live_streaming_data: "实时直播数据",
  hint_667: "当前流信息不可用！",
  Anchor_Management: "主播管理",
  Merge_anchor_accounts: "主播账号合并",
  Identify_the_main_switch: "鉴别总开关",
  The_current_status_is: "当前状态为",
  There_is_currently_no_data_available: "暂无数据",
  Jian_Huang: "鉴黄",
  Fear_prevention: "鉴恐",
  Jianzheng: "鉴政",
  hint_303: "确定要将此直播间断流吗？",
  Thermal_value_coefficient: "热力值系数",
  Heat_value_base: "热力值基数",
  Modified_by: "修改人",
  Latest_modification_time: "最新修改时间",
  Heat_value_setting: "热力值设置",
  Role_Name: "角色名称",
  Whether_to_enable: "是否启用",
  Live_broadcast_room_type: "直播间类型",
  Initial_heat_value: "初始热力值",
  Last_editing_time: "最后编辑时间",
  Edit_heat_value: "编辑热力值",
  Initial_heating_value_: "初始热力值：",
  Please_enter_the_heat_value: "请输入热力值",
  Please_fill_in_the_heat_value: "请填写热力值",
  Please_enter_the_anchor_UID: "请输入主播UID",
  Live_streaming_services: "直播流服务",
  Batch_editing: "批量编辑",
  All_anchors: "全部主播",
  Platform_anchor_stream: "平台主播流",
  Event_clearance: "赛事清流",
  Anchor_event_flow: "主播赛事流",
  Esports_Entertainment_Stream: "电竞娱乐流",
  Live_streaming_2: "正在直播",
  Field: "场",
  hint_665: "确定要将此直播间下播吗？",
  hint_664: "确定要将此直播间禁播吗？",
  This_PV: "本场PV",
  UV_in_this_field: "本场UV",
  Peak_value: "峰值",
  Accumulated_viewing_time_minutes_: "累计观看时长(分钟)",
  Total_number_of_followers: "总关注数",
  Number_of_followers_in_this_session: "本场关注数",
  The_number_of_levels_taken_in_this_game: "本场取关数",
  Net_increase_in_levels_in_this_game: "本场净增关数",
  Live_broadcast_session_data_of_anchors: "主播直播场次数据",
  Forbidden_List: "禁言列表",
  Team: "球队",
  Please_enter_the_team: "请输入球队",
  Push_order_status: "推单状态",
  hint_306: "确认要隐藏该推单吗？隐藏后前端不可见",
  hint_305: "确认要显示该推单吗？显示后前端可见",
  Please_select_the_correction_result: "请选择修正结果",
  Reporting_time: "举报时间",
  Whistleblower_userid: "举报人userid",
  Enter_the_whistleblower_userid: "输入举报人userid",
  Enter_room_number: "输入房间号",
  Reply_or_not: "是否回复",
  Reason_for_reporting: "举报原因",
  Reply_content: "回复内容",
  hint_663: "请输入回复内容(100字以内)",
  The_reply_content_cannot_be_empty: "回复内容不能为空",
  Homepage_Configuration: "首页配置",
  Level_animation_switch: "等级动画开关",
  Cat_coin_animation_switch: "猫币动画开关",
  Enter_animation_switch_: "进场动画开关：",
  APP_membership_background: "APP会员背景",
  Membership_Benefits_Background: "会员权益背景",
  APP_H5_Membership_Benefits_Background: "APP/H5会员权益背景",
  Background_of_PC_Membership_Benefits: "PC会员权益背景",
  hint_409: "注：尺寸为926x126",
  VIP_discount_configuration: "vip折扣配置",
  Please_enter_VIP: "请输入VIP",
  hint_402: "请输入vip折扣（例如：0.9）",
  hint_403: "请输入vip折扣名称（例如：9折）",
  Edit_Channel: "编辑频道",
  Add_a_new_channel: "新增频道",
  Channel_avatar: "频道头像",
  hint_401: "尺寸：114*114 只能上传jpg/png文件",
  Channel_Cover: "频道封面",
  hint_405: "尺寸：522*294 只能上传jpg/png文件",
  hint_406: "提示：自动截取流画面，失败后则使用默认封面",
  hint_407: "确定恢复该频道直播权限吗？",
  hint_408: "确定将该频道封禁吗？",
  Schedule_data: "赛程数据",
  Edit_Announcement: "编辑公告",
  Create_Announcement: "创建公告",
  Announcement_type: "公告类型",
  Image_and_text_links: "图文链接",
  Picture_and_text_messages: "图文消息",
  Activity_link: "活动链接",
  Announcement_Title: "公告标题",
  Cover_image: "封面图",
  Whether_to_push: "是否推送",
  Select_content: "选择内容",
  Activity_Title: "活动标题",
  Event_Introduction: "活动简介",
  Please_select_the_announcement_type: "请选择公告类型",
  Please_select_the_publishing_time: "请选择发布时间",
  Please_enter_the_announcement_title: "请输入公告标题",
  Please_enter_the_activity_title: "请输入活动标题",
  Please_enter_the_announcement_content: "请输入公告内容",
  Please_fill_in_the_activity_introduction: "请填写活动简介",
  Please_enter_the_text_message_content: "请输入短信内容",
  Send_to: "发送对象",
  Upload_data: "上传数据",
  Download_List_Excel_Template: "下载名单Excel模板",
  Receive_user_list: "接收用户列表",
  All_platform_users: "全平台用户",
  Not_sent: "未发送",
  Sent: "已发送",
  Cancel_sending: "取消发送",
  Specify_user: "指定用户",
  Please_enter_the_theme: "请输入主题",
  Multiple_separated_by_commas: "多个以逗号隔开",
  Template_ID: "模板ID",
  Instant_sending: "即时发送",
  Please_select_template_ID: "请选择模板ID",
  Are_you_sure_to_send_to_all_users_: "是否确认向所有用户发送？",
  Sending_successful: "发送成功",
  Channel_template_ID: "渠道方模板ID",
  Please_enter_the_channel_template_ID: "请输入渠道方模板ID",
  Template_content: "模板内容",
  Please_enter_template_content: "请输入模板内容",
  Please_select_a_channel: "请选择渠道",
  Please_enter_your_signature: "请输入签名",
  Alibaba_Cloud: "阿里云",
  Notification: "通知",
  Marketing: "营销",
  Verification_code: "验证码",
  First_select_the_SMS_type: "先选择短信类型",
  Please_fill_in_the_channel_template_ID: "请填写渠道方模板ID",
  Message_type: "消息类型",
  New_announcement: "新增公告",
  Official_announcement_title: "官方公告标题",
  Unpublish: "取消发布",
  Jump_type: "跳转类型",
  Push_time: "推送时间",
  Please_enter_the_bag_ID: "请输入锦囊ID",
  Decimal_cannot_be_entered: "不能输入小数",
  Please_enter_the_H5_link: "请输入H5链接",
  Push_details: "推送详情",
  Edit_push: "编辑推送",
  Add_push: "新增推送",
  Push_order_page: "推单页",
  Please_select_the_push_time: "请选择推送时间",
  Please_select_the_effective_time: "请选择生效时间",
  hint_660: "确定撤销本次推送任务吗？",
  Add_menu: "新增菜单",
  catalogue: "目录",
  Menu: "菜单",
  Menu_icons: "菜单图标",
  Menu_Title: "菜单标题",
  Routing_address: "路由地址",
  Component_Name: "组件名称",
  Component_path: "组件路径",
  Whether_to_hide: "是否隐藏",
  Superior_categories: "上级类目",
  Select_superior_category: "选择上级类目",
  Menu_Name: "菜单名称",
  Permission_identification: "权限标识",
  Edit_menu: "编辑菜单",
  Please_enter_the_menu_title: "请输入菜单标题",
  Please_enter_the_routing_address: "请输入路由地址",
  Please_enter_the_component_name: "请输入组件名称",
  Please_enter_the_component_path: "请输入组件路径",
  Please_select_the_superior_category: "请选择上级类目",
  Top_level_categories: "顶级类目",
  hint_661: "确定要删除此菜单吗？",
  Please_enter_the_role_name: "请输入角色名称",
  Add_new_role: "新增角色",
  Role_ID: "角色ID",
  Creator: "创建人",
  Authorization: "授权",
  Edit_Role: "编辑角色",
  Audit_scenario: "审核场景",
  Please_enter_the_review_scenario: "请输入审核场景",
  Review_page: "审核页面",
  Edit_audit_scenario_account: "编辑审核场景账号",
  Operations_Manager_Account: "运营负责人账号",
  Please_enter_the_review_account: "请输入审核账号",
  Please_enter_your_account: "请输入账号",
  New_users: "新增用户",
  Bind_characters: "绑定角色",
  Latest_reset_time: "最新重置时间",
  Please_select_a_role: "请选择角色",
  Input_cannot_be_empty: "输入不能为空",
  hint_6601: "不能输入特殊字符及数字",
  Edit_Users: "编辑用户",
  hint_659: "确定要停用此账户吗？",
  Account_deactivated: "账户已停用",
  hint_658: "确定要重置此账号密码吗？",
  Reset_successful: "重置成功",
  The_new_password_is: "新密码为",
  Gift_Name: "礼物名称",
  Please_enter_the_gift_name: "请输入礼物名称",
  Gift_category: "礼物类别",
  VIP_gift: "VIP礼物",
  Add_gift: "添加礼物",
  Gift_unit_price_in_gold_coins_: "礼物单价(金币)",
  Banner_gift: "横幅礼物",
  Full_screen_gift: "全屏礼物",
  Gift_icon: "礼物图标",
  Size_does_not_exceed_1MB_: "大小不超过1MB！",
  Enable_floating_screen: "启用飘屏",
  Floating_screen_category: "飘屏类别",
  hint_657: "请选择飘屏类别",
  Please_select_VIP_level: "请选择VIP等级",
  hint_656: "该等级及以上VIP用户可使用",
  hint_601: "最多可输入4个中文汉字或者8个英文字母或者8个数字",
  Please_enter_the_price: "请输入价格",
  hint_602: "礼物单价不能大于9999999或小于0",
  Please_upload_the_gift_icon: "请上传礼物图标",
  Please_upload_the_playback_effect: "请上传播放效果",
  Please_upload_the_preview_effect: "请上传预览效果",
  Edit_Gift: "编辑礼物",
  Please_choose_a_gift_type: "请选择礼物类型",
  Please_select_the_floating_screen_type: "请选择飘屏类型",
  Please_select_whether_combo_is_possible: "请选择是否可连击",
  Only_SVGA_files_can_be_uploaded_: "只能上传svga文件!",
  Only_GIF_files_can_be_uploaded: "只能上传gif文件",
  hint_66: "下线该礼物则该礼物从礼物仓库中下线，即配置礼物策略中不出现该礼物，确定下线吗？",
  Successfully_taken_offline: "下线成功",
  hint_67: "上线该礼物则该礼物重新上线至礼物仓库，即配置礼物策略中重新出现该礼物，确定上线吗",
  Gift_data_statistics: "礼物数据统计",
  User_gift_details: "用户送礼明细",
  Gift_List: "礼物列表",
  Distribution_scope: "下发范围",
  Effective_time: "生效时间",
  Last_modified_time: "最近修改时间",
  Last_Editor: "最后编辑人",
  hint_68: "请输入礼物ID，多个礼物之间使用英文逗号隔开，输入顺序则为前端礼物展示顺序",
  Sending_gifts: "下发礼物",
  hint_72: "删除该礼物策略将导致该策略删除，确定删除吗？？",
  hint_70: "下线该礼物策略将导致该策略房间的礼物从前端下线，确定下线吗？",
  hint_71: "上线该礼物策略则该策略房间的礼物从前端上线，确定上线吗？",
  Expert_type: "专家类型",
  Import_experts: "导入专家",
  Introduction: "简介",
  Hit_rate: "命中率",
  Recent_status: "近期状态",
  The_highest_consecutive_red: "最高连红",
  hint_604: "近期连红",
  Number_of_proposals: "方案数量",
  Number_of_buyers: "购买人数",
  Accumulated_total_revenue: "累计总收益",
  Settlable_income: "可结算收益",
  Add_Expert: "添加专家",
  User_userid_cannot_be_empty: "用户userid不能为空",
  Please_enter_the_league_and_team_name: "请输入联赛、球队名称",
  Home_team_vs_away_team: "主队VS客队",
  League: "联赛",
  Choose_a_competition: "选择赛事",
  Event_information: "赛事信息",
  Select_recommendation: "选择推荐",
  Yazhi: "亚指",
  The_main_winner: "主胜",
  Customer_win: "客胜",
  Small_and_large_balls: "大小球",
  Greater_than: "大于",
  ball: "球",
  European_Compensation: "欧赔",
  A_draw: "平局",
  Wanbo: "万博",
  Le_Dong: "乐动",
  Macau: "澳门",
  crown: "皇冠",
  hint_655: "请先选择玩法",
  Add_recommendation_group: "添加推荐组",
  Recommended_group_name: "推荐组名称",
  Circle_details: "圈子明细",
  Number_of_recommended_circles: "推荐圈子数量",
  Resource_bit_name: "资源位名称",
  Expert_details: "专家明细",
  Number_of_recommended_experts: "推荐专家数量",
  Pause: "暂停",
  Add_anchors: "增加主播",
  X_connected_red: "X连红",
  Near_X_in_X: "近X中X",
  Return_rate: "回报率",
  hint_653: "请填写至少一条完整主播信息",
  hint_654: "确定推荐该组吗？",
  hint_652: "确定暂停该推荐组吗？",
  Up_to_20_can_be_configured: "最多可以配置加20个",
  Edit_selling_price: "编辑售价",
  First_gear: "第一档",
  Second_gear: "第二档",
  Third_gear: "第三档",
  Release_Brocade_Bag: "发布锦囊",
  Brocade_Bag_Type_: "锦囊类型：",
  Football_recommendations: "足球推荐",
  Basketball_recommendations: "篮球推荐",
  hint_651: "请选择赛事设置推荐选项",
  Please_enter_the_publisher_account: "请输入发布者账号",
  Please_enter_the_recommendation_reason: "请输入推荐理由",
  Please_set_the_publishing_time: "请设置发布时间",
  hint_650: "已结束比赛需要选择发布时间",
  Expert_management: "专家管理",
  Popular_experts: "热门专家",
  Hit: "命中",
  Returned: "已返还",
  To_be_returned: "待返还",
  On_sale: "售卖中",
  Cut_off_orders: "截单",
  Order_status: "订单状态",
  Purchase_time: "购买时间",
  Please_enter_the_competition: "请输入赛事",
  Competition: "赛事",
  Publisher: "发布人",
  Please_enter_the_publisher: "请输入发布人",
  Purchase_successful: "购买成功",
  hint_73: "确定下架吗？下架后移动端将不出现此条短视频？",
  Bullet_Screen_Library: "弹幕库",
  Add_bullet_library: "添加弹幕库",
  Batch_Import: "批量导入",
  Add_bullet_comments: "添加弹幕",
  Bullet_screen_content: "弹幕内容",
  Recent_Editors: "最近的编辑人",
  Import_data: "导入数据",
  Edit_bullet_screen: "编辑弹幕",
  hint_649: "支持添加多条弹幕，多个弹幕用英文逗号隔开，单次最多添加1000条。",
  Label_One: "标签一",
  Label_2: "标签二",
  Label_Three: "标签三",
  Label_Four: "标签四",
  Label_Five: "标签五",
  Please_enter_the_bullet_content: "请输入弹幕内容",
  hint_648: "上传大小不能超过 10MB!",
  Successfully_added_barrage: "添加弹幕成功",
  Update_barrage_successful: "更新弹幕成功",
  hint_647: "上传文件只能是 xls格式!",
  Import_failed: "导入失败",
  Import_successful: "导入成功",
  hint_646: "确定要删除此条弹幕吗？",
  Number_of_fans: "粉丝数",
  Live_broadcast_time: "直播时间",
  Duration_minutes_: "时长(分钟)",
  Number_of_gift_givers: "送礼人数(个)",
  Number_of_gifts_given: "送礼个数(个)",
  Live_room_revenue_feather_: "直播间收益(羽毛)",
  Guilds: "公会",
  Settlable_income_yuan_: "可结算收益(元)",
  Reward_and_punishment_income_yuan: "奖惩收益(元)",
  Total_revenue_yuan_: "总收益(元)",
  Adjustment_type_: "调整类型：",
  hint_645: "奖惩原因：",
  Settlement_bill_review: "结算账单审核",
  Originating_Reviewer: "发起审核人",
  Operations_Manager_: "运营负责人",
  Reissue: "重新发起",
  Through: "通过",
  Please_enter_the_amount: "请输入金额",
  Please_enter_the_reason: "请输入原因",
  Guild_name: "公会名称",
  Please_enter_the_guild_name: "请输入公会名称",
  Guild_ID: "公会ID",
  Receiving_account_name: "收款账户名称",
  Opening_Bank: "开户行",
  Bank_card_number: "银行卡号",
  Account_opening_address: "开户地址",
  Number_of_anchors: "主播个数",
  Please_fill_in_your_phone_number: "请填写手机号码",
  Please_fill_in_your_bank_card_number: "请填写银行卡号",
  hint_75: "启用后将可为主播分配该公会，是否确定启用此公会",
  Resource_location: "资源位置",
  Is_it_effective_: "是否生效",
  Jump_position: "跳转位置",
  Please_enter_the_jump_location: "请输入跳转位置",
  Please_select_a_cover: "请选择封面",
  Please_select_a_jump_type: "请选择跳转类型",
  Please_select_a_resource_location: "请选择资源位置",
  hint_76: "注：权重越大当前广告位越靠前",
  Is_the_browser_open_: "是否浏览器打开",
  hint_644: "确认要启用该广告吗？",
  hint_643: "确认要停用该广告吗？",
  hint_642: "确认要移除该广告位吗？",
  Add_an_open_screen_advertisement: "新增开屏广告",
  hint_641: "请保证视频格式为MP4格式，且不超过5M",
  hint_77: "只能上传jpg/png/gif文件，图片尺寸需为750X1624",
  Please_enter_the_link_address: "请输入链接地址",
  Please_enter_the_live_broadcast_room_ID: "请输入直播间ID",
  Please_enter_the_information_ID: "请输入资讯ID",
  Please_enter_the_chat_room_ID: "请输入聊天室ID",
  The_input_information_format_is_incorrect: "输入信息格式有误",
  Chat_room_ID: "聊天室ID",
  Brocade_Bag_ID: "锦囊ID",
  hint_640: "只能上传jpg/png/gif文件，建议尺寸为",
  Please_enter_the_sorting_number: "请输入排序号",
  Sort: "排序",
  The_length_cannot_exceed_20: "长度不能走过20",
  hint_78: "下线后该弹窗从移动端下架，是否确定下线此弹窗？",
  hint_639: "上传图片大小不能超过 1MB",
  Page_Name: "页面名称",
  Please_enter_the_page_name: "请输入页面名称",
  Parameters: "参数",
  PC_Link: "PC链接",
  H5_Link: "H5链接",
  PC_Preview: "PC预览",
  H5_Preview: "H5预览",
  PC_Resource_Map: "PC资源图",
  H5_Resource_Map: "H5资源图",
  Please_enter_parameters: "请输入参数",
  Background_name: "背景名",
  Background_image: "背景图",
  Set_background_name: "设置背景名",
  Set_sorting: "设置排序",
  Modify_background_image: "修改背景图",
  Add_background_image: "新增背景图",
  Please_fill_in_the_background_name: "请填写背景名称",
  Please_fill_in_the_sorting: "请填写排序",
  Successfully_taken_down: "下架成功",
  Successfully_listed: "上架成功",
  Effective: "有效",
  Invalid: "无效",
  hint_606: "频道开播数量（10分钟统计一次）",
  Go_live: "上线",
  hint_605: "请设置排序号（1-999)",
  Please_enter_the_tab_name: "请输入tab名称",
  hint_79: "只能上传jpg/png文件，图片尺寸需为670*376",
  Please_set_the_status: "请设置状态",
  Are_you_sure_you_want_to_go_online_: "确定要上线吗",
  Are_you_sure_you_want_to_go_offline_: "确定要下线吗",
  Anchor_userid: "主播userid",
  Chat_room_title: "聊天室标题",
  Cut_off_flow: "断流",
  hint_638: "确定对此聊天室断流吗？",
  Upload_time: "上传时间",
  Cover_type: "封面类型",
  Batch_pass: "批量通过",
  Batch_rejection: "批量拒绝",
  Voice_chat_room: "语音聊天室",
  Reject: "拒绝",
  Operated: "已操作",
  Passed: "已通过",
  Live_streaming_3: "直播",
  hint_637: "确定要删除封面吗？",
  Music_name: "音乐名",
  Please_enter_the_music_name: "请输入音乐名称",
  Author_name: "作者名",
  Please_enter_the_author_name: "请输入作者名称",
  Size: "大小",
  Playback_count: "播放次数",
  Trial_listening: "试听",
  Please_enter_the_song_name: "请输入歌曲名",
  Song_title: "歌曲名",
  Upload_music_files: "上传音乐文件",
  Only_supports_MP3_format: "仅支持MP3格式",
  hint_80: "宽高比 60*60",
  Please_upload_music_files: "请上音乐文件",
  Edit_Music: "编辑音乐",
  Add_music: "新增音乐",
  Only_MP3_format_files_can_be_uploaded: "只能上传mp3格式文件",
  The_size_cannot_exceed_20MB_: "大小不能超过 20MB!",
  hint_635: "确定要上架该音乐吗？",
  hint_636: "确定要下架该音乐吗？",
  Add_Recommendation: "新增推荐",
  Please_set_the_sorting_number_1_999_: "请设置排序号（1-999）",
  Editor_recommendation: "编辑推荐",
  UserId_cannot_be_empty: "UserId不能为空",
  Shutdown_time: "关播时间",
  Peak_heat_value: "热力值峰值",
  Edit_Page: "编辑页面",
  Add_Page: "添加页面",
  Upload_at_least_one_resource_image: "至少上传一端资源图片",
  hint_81: "发布后该链接将发布至线上，是否确定发布该页面？",
  hint_82: "下架后该链接将不可在线上访问，是否确定下架该页面？",
  Select_Cover: "选择封面",
  hint_633: "注意：生效时间不可重叠！！！",
  hint_83: "存在不完整信息，请填写完整再提交！！",
  hint_634: "上传图片大小不能超过 500K!",
  Recommended_image: "推荐图",
  Display_sorting: "显示排序",
  Edit_Secondary_Classification: "编辑二级分类",
  Classification: "分类",
  Add_Category: "添加分类",
  Please_enter_the_activity_name: "请输入活动名称",
  Activity_status: "活动状态",
  Image: "图片",
  Configuration_date: "配置日期",
  Please_enter_the_button_link: "请输入按钮链接",
  Button_link: "按钮链接",
  Button_Text: "按钮文案",
  hint_607: "请输入按钮文案，最大4个字",
  Button_color: "按钮颜色",
  hint_608: "请输入按钮颜色，如#CCCCCC",
  Edit_Configuration: "编辑配置",
  New_configuration: "新增配置",
  hint_609: "请输入按钮文案",
  Please_enter_the_button_color: "请输入按钮颜色",
  hint_84: "确定要上架此模板吗，上架后页面模板会生效",
  hint_85: "确定要下架此模板吗，下架后页面按钮会失效",
  APP_end: "APP端",
  H5_end: "H5端",
  AK_APP_end: "AK-APP端",
  AK_PC_end: "AK-PC端",
  AK_H5_end: "AK-H5端",
  Anchor_details: "主播明细",
  Recommended_number_of_anchors: "推荐主播数量",
  Anchor_: "主播{num}",
  Please_enter_the_anchor_ID: "请输入主播ID",
  Please_fill_in_the_resource_slot_name: "请填写资源位名称",
  Please_fill_in_at_least_one_anchor_information: "请填写至少一条主播信息",
  Secondary_classification_valid_all: "二级分类(有效/全部)",
  SM_static_resources: "SM静态资源",
  AK_static_resources: "AK静态资源",
  More_Jumps: "更多跳转",
  Editorial_Expert_Recommendation_Group: "编辑专家推荐组",
  Add_expert_recommendation_group: "新增专家推荐组",
  Near_0_in_0: "近0中0",
  Highest: "最高",
  Lian_Hong: "连红",
  Recently: "近期",
  Permissions: "权限",
  Are_you_sure_you_want_to_turn_it_on_: "确定开启吗？",
  Are_you_sure_you_want_to_close_it_: "确定关闭吗？",
  Live_broadcast_room_name: "直播间名称",
  Please_select_sorting: "请选择排序",
  Edit_popular_recommendations: "编辑热门推荐",
  Add_popular_recommendations: "新增热门推荐",
  Advertising_name: "广告名称",
  Resource_type: "资源类型",
  Video_upload: "视频上传",
  Your_browser_does_not_support_video_playback: "您的浏览器不支持视频播放",
  Re_upload: "重新上传",
  Please_upload_the_video: "请上传视频",
  Offline: "已下线",
  Square_banner: "广场banner",
  Page: "页面",
  Information_details: "资讯详情",
  Edit_open_screen_advertisement: "编辑开屏广告",
  Please_select_a_resource_type: "请选择资源类型",
  Please_select_an_open_screen_advertisement: "请选择开屏广告",
  The_uploaded_video_size_cannot_exceed_5MB_: "上传视频大小不能超过5MB哦",
  Please_upload_the_correct_video_format: "请上传正确的视频格式",
  Add_patches: "新增贴片",
  Display_location: "展示位置",
  Please_enter_the_title_of_the_patch_advertisement: "请输入贴片广告标题",
  hint_86: "只能上传jpg/png文件，图片尺寸需为100X100",
  Edit_patch_ads: "编辑贴片广告",
  Add_patch_ads: "新增贴片广告",
  Please_enter_the_advertisement_title: "请输入广告标题",
  Please_select_a_display_location: "请选择展示位置",
  Recommended_by_experts_on_the_homepage: "首页专家推荐",
  Live_streaming_page_anchor_recommendation: "直播页主播推荐",
  hint_87: "上传图片只能是 JPG、PNG或gif格式!",
  Pop_up_name: "弹窗名称",
  APP_Advertising: "APP广告",
  Open_screen_advertising: "开屏广告",
  Patch_advertising: "贴片广告",
  Activity_pop_up: "活动弹窗",
  hint_611: "注：上架运营全部功能都已开放",
  Assistant: "小助手",
  Homepage_Live: "首页直播",
  Live_broadcast_room_announcement: "直播间公告",
  Advertising_resource_allocation: "广告资源位配置",
  hint_610: "开启则请求旧的广告资源位素材",
  hint_88: "注：官方审核期间，部分功能未开放",
  APP_Brand_Name_: "APP品牌名称：",
  Please_enter_the_APP_brand_name: "请输入APP品牌名称",
  Please_enter_contact_customer_service: "请输入联系客服",
  hint_89: "上传图片尺寸有误，请按格式要求上传",
  Switch_initialization: "开关初始化",
  Version_configuration: "版本配置",
  Review_status: "审核状态",
  Operation_status: "运营状态",
  AppStore_version_number: "AppStore版本号",
  Please_enter_the_version_number: "请输入版本号",
  Version_status: "版本状态",
  Update_instructions: "更新说明",
  Configure_time: "配置时间",
  Please_enter_the_interface_version_number: "请输入接口版本号",
  hint_90: "接口版本号由技术提供，不可私自定义！",
  Please_enter_update_instructions: "请输入更新说明",
  Please_select_version_status: "请选择版本状态",
  Please_enter_the_AppStore_version_number: "请输入AppStore版本号",
  Please_enter_the_short_video_title: "请输入短视频标题",
  Edit_Title: "编辑标题",
  Short_video_title_: "短视频标题：",
  hint_91: "发布该条短视频至移动端，是否确认发布？",
  Crawling_website_sites: "爬取网站站点",
  Statistical_time: "统计时间",
  UV_data: "uv数据",
  Platform: "平台",
  Add_circle_recommendations: "新增圈子推荐",
  Are_you_sure_you_want_to_pause_: "是否确定暂停？",
  Access_date: "访问日期",
  Posting_time: "发帖时间",
  Poster_nickname: "发帖人昵称",
  Interactive_red_envelopes: "互动红包",
  Topic_Name: "话题名称",
  Please_enter_the_topic_name: "请输入话题名称",
  hint_632: "话题热度(10分钟统计一次)",
  Accumulated_number_of_related_posts: "累计相关帖子数",
  Save_and_publish: "保存并发布",
  Recommendation: "推荐",
  Add_recommended_topics: "新增推荐话题",
  Number_of_participants_in_the_topic: "话题参与人数",
  Number_of_topic_views: "话题浏览次数",
  Topic_release_time: "话题发布时间",
  Topic_Top_Time: "话题置顶时间",
  Choose_a_topic: "选择话题",
  Enter_the_topic_name_you_want_to_recommend: "输入需要推荐的话题名称",
  Editor_recommended_topic: "编辑推荐话题",
  The_topic_cannot_be_empty: "话题不能为空",
  hint_631: "请输入话题名称（15字以内）",
  hint_630: "请输入话题简介（100字以内）",
  Successfully_created_topic: "创建话题成功",
  Recharge: "充值",
  Consumption: "消费",
  Revenue: "收益",
  Order_number_1: "订单编号",
  Recharge_amount: "充值金额",
  Consumption_type: "消费类型",
  Consumption_amount: "消费金额",
  Revenue_type: "收益类型",
  Revenue_amount: "收益金额",
  Additional_issuance: "增发",
  Uploading: "上传中。。。",
  Batch_issuance: "批量增发",
  Download_batch_issuance_templates: "下载批量增发模板",
  Additional_currency_issuance: "增发货币",
  Increase_experience_value_of_issuance: "增发经验值",
  Please_enter_user_userid: "请输入用户userid",
  Please_enter_the_quantity_of_coins: "请输入金币数量",
  Please_enter_a_note: "请输入备注",
  VIP_level: "VIP等级",
  Additional_issuance_time: "增发时间",
  Are_you_sure_you_want_to_refuse_: "确定要拒绝吗？",
  Personal_currency_issuance: "个人货币增发",
  VIP_currency_issuance: "VIP货币增发",
  Total_consumption_in_gold_coins: "总消耗（金币）",
  Total_rechargeyuan: "总充值（元）",
  Number_of_Rechargers: "充值人数",
  Recharge_switch: "充值开关",
  RMB_amount_yuan_: "人民币金额（元）",
  Number_of_gold_coins: "金币数量",
  Edit_recharge_configuration: "编辑充值配置",
  Add_recharge_configuration: "新增充值配置",
  Recharge_time: "充值时间",
  Payment_channels: "支付渠道",
  Please_enter_the_order_number_1: "请输入订单编号",
  Payment_time: "支付时间",
  Order_time: "订单时间",
  Payment_amount_yuan: "支付金额（元）",
  Balance_inquiry: "余额查询",
  Ordinary_user_currency_balance: "普通用户货币余额",
  Details_1: "明细",
  Football_quiz_activity: "足球答题活动",
  Football_winning_statistics: "足球中奖统计",
  Basketball_quiz_activity: "篮球答题活动",
  Basketball_winning_statistics: "篮球中奖统计",
  Guessing_question_name: "竞猜答题名称",
  Please_enter_the_name_of_the_quiz_question: "请输入竞猜答题名称",
  Start_time: "开始时间",
  Add_a_question: "新增答题",
  Rule_configuration: "规则配置",
  Style_Configuration: "样式配置",
  Number_of_periods: "期数",
  Reward_base: "奖励基数",
  Stop: "停止",
  End: "结束",
  Title: "题目",
  The_problem_name_cannot_be_empty: "问题名称不能为空",
  Please_enter_the_question_name: "请输入问题名称",
  Please_enter_question_options: "请输入问答题选项",
  question_options_1: "答题选项",
  Add_Options: "添加选项",
  Please_enter_the_number_of_currencies_for_the_reward_base: "请输入奖励基数的货币个数",
  Save: "保 存",
  Cancel: "取 消",
  Please_submit_results_with_caution: "请谨慎提交结果",
  The_answer_to_the_question_cannot_be_empty: "问题答案不能为空",
  Flow: "流局",
  Activity_Rule_Configuration: "活动规则配置",
  Rule_configuration_cannot_be_empty: "规则配置不能为空",
  Limited_to_200_words_in_length: "限长200个字",
  Guessing_Style_Configuration: "竟猜样式配置",
  PC_Banner_diagram: "PC Banner图",
  PC_Banner_image_cannot_be_empty: "PC Banner图不能为空",
  hint_612: "尺寸：1920x624 只能上传jpg/png文件",
  H5_APP_Banner_image: "H5/APP Banner图",
  H5_APP_Banner_image_cannot_be_empty: "H5/APP Banner图不能为空",
  hint_613: "尺寸：750x1012 只能上传jpg/png文件",
  Theme_color: "主题色",
  The_theme_color_cannot_be_empty: "主题色不能为空",
  hint_619: "页面底部的颜色配置",
  Event_details: "活动详情",
  Correct_answer: "正确答案",
  Stopped: "已停止",
  Add_a_guessing_activity: "新增竞猜活动",
  hint_618: "请填写竞猜答题名称",
  Please_select_the_effective_time_range: "请选择生效时间范围",
  Please_fill_in_the_reward_base: "请填写奖励基数",
  hint_92: "取消后则不发布该期答题活动，是否确定取消该期竞猜答题活动?",
  Success: "成功",
  Cancel_successful: "取消成功",
  hint_93: "停止后用户将无法参与竞猜答题，是否确定停止竞猜答题活动",
  hint_614: "请输入1-99999正整数的奖励基数",
  Cannot_select_past_time: "不可选择过去时间",
  hint_615: "是否确定公布答案？",
  Save_successful: "保存成功",
  hint_616: "题目{num} 的答案选项最少设置2个选项",
  Number_of_participants: "参与人数",
  Number_of_winners: "中奖人数",
  hint_617: "奖励个数（金币）",
  Question_Name: "问题名称",
  Number_of_correct_answers: "答对人数",
  Activity_List: "活动列表",
  Top_of_the_event: "活动置顶",
  PC_end_carousel: "PC端轮播",
  AK_Activity_List: "AK-活动列表",
  AK_Activity_Topping: "AK-活动置顶",
  AK_PC_end_carousel: "AK-PC端轮播",
  Create_activity_records: "创建活动记录",
  Event_main_title: "活动主标题",
  Activity_subtitle: "活动副标题",
  PC_thumbnail: "PC端缩略图",
  App_thumbnail: "App缩略图",
  Activity_time: "活动时间",
  Long_term_activities: "长期活动",
  Please_enter_the_main_title_of_the_activity: "请输入活动主标题",
  Please_enter_the_activity_subtitle: "请输入活动副标题",
  PC_activity_redirect_address: "PC活动跳转地址",
  Activity_jump_button_background: "活动跳转按钮背景",
  App_side_thumbnail: "App端缩略图",
  hint_901: "大小355*148px",
  App_activity_redirect_address: "App 活动跳转地址",
  Time_type: "时间类型",
  There_is_a_time_limit: "有时间限制",
  Edit_activity_records: "编辑活动记录",
  hint_629: "确定置顶此条活动记录吗？",
  hint_620: "确定取消置顶此条活动记录吗？",
  Please_set_the_activity_time: "请设置活动时间",
  Jump_URL: "跳转URL",
  Queue: "队列",
  Cover: "封面",
  Mobile_download_page: "移动端下载页",
  PC_Download_Page: "PC端下载页",
  Android_copywriting: "安卓文案",
  hint_94: "请输入文案，最大支持10个字符",
  IOS_Web_App_Copywriting: "iOS-网页版APP文案",
  IOS_copywriting: "iOS文案",
  PC_background_configuration: "PC背景配置",
  Group_stage_competition: "小组赛",
  "1_8_elimination_round": "1/8淘汰赛",
  "1_4_elimination_round": "1/4淘汰赛",
  Semi_finals: "半决赛",
  Finals: "决赛",
  Location: "地点",
  Facing_each_other: "对阵",
  hint_95: "请输入主播房间号并用逗号隔开，最多四位",
  Fen: "分",
  hint_628: "最多只能选择4个主播房间号",
  SM_redemption_page: "SM兑换页面",
  AK_redemption_page: "AK兑换页面",
  H5_end_thumbnail: "H5端缩略图",
  hint_96: "只能上传jpg/png/gif文件，尺寸1440*315",
  Category_ID: "类别ID",
  Classification_name: "分类名称",
  Please_enter_the_category_name: "请输入分类名称",
  Thai_tag_name: "泰语标签名称",
  Please_enter_the_Thai_tag_name: "请输入泰语标签名称",
  English_tag_name: "英语标签名称",
  Please_enter_the_English_tag_name: "请输入英语标签名称",
  Vietnamese_label_name: "越南语标签名称",
  Please_enter_the_Vietnamese_label_name: "请输入越南语标签名称",
  Korean_tag_name: "韩语标签名称",
  Please_enter_the_Korean_tag_name: "请输入韩语标签名称",
  Brazilian_label_name: "巴西语标签名称",
  Please_enter_the_Brazilian_label_name: "请输入巴西语标签名称",
  Add_a_new_category: "新增分类",
  hint_888: "精品好物",
  Please_select_the_status: "请选择状态",
  hint_627: "是否确定上线该商品？",
  hint_626: "是否确定下线该商品分类？",
  Warning: "警告",
  hint_97: "分类下含有所属商品，不可下线",
  Edit_Classification: "编辑分类",
  Redemption_page_BANNER: "兑换页BANNER",
  hint_625: "上传图片大小不能超过 5M!",
  Delisted_products: "下架商品",
  Listing_products: "上架商品",
  Classification_management: "分类管理",
  Label_management: "标签管理",
  Order_List: "订单列表",
  Product_ID: "商品ID",
  Please_enter_the_product_ID: "请输入商品ID",
  Product_name: "商品名称",
  Please_enter_the_product_name: "请输入商品名称",
  Do_we_need_delivery_: "是否需配送",
  Switch: "开关",
  Unit_price: "单价",
  Discounted_price: "折后价",
  Current_inventory: "当前库存",
  Redeemed: "已兑换",
  Inventory_changes: "库存变动",
  Select_thumbnail: "选择缩略图",
  Details_page_image: "详情页图",
  Select_detailed_image: "选择详情图",
  Details: "详情说明",
  Please_enter_the_unit_price: "请输入单价",
  Discounts: "打折价",
  Please_enter_the_discounted_price_of_the_product: "请输入商品打折价",
  Please_select_a_category: "请选择类别",
  Label: "标签",
  Product_purchase_restriction_period: "商品限购周期",
  No_purchase_restrictions: "不限购",
  Day: "日",
  Week: "周",
  Cumulative: "累计",
  Product_purchase_limit: "商品限购数",
  hint_98: "请输入周期内的商品限购数",
  Inventory: "库存",
  Add: "增加",
  Reduce: "减少",
  The_product_name_cannot_be_empty: "商品名称不可为空",
  Boutique: "精品",
  Life: "生活",
  Product_addition: "商品新增",
  Please_upload_thumbnail: "请上传缩略图",
  Please_upload_detailed_images: "请上传详情图",
  Please_select_whether_delivery_is_required: "请选择是否需配送",
  Purchase_unit_price_cannot_be_empty: "购买单价不可为空",
  Classification_cannot_be_empty: "分类不可为空",
  hint_99: "商品限购数不能为空",
  Details_cannot_be_empty: "详情说明不能为空",
  Please_select_increase_decrease: "请选择增加减少",
  Are_you_sure_you_want_to_save_: "是否确定保存？",
  Cat_coin_price: "猫币价",
  Diamond_price: "钻石价",
  Thai_product_name: "泰语商品名称",
  Please_enter_the_Thai_product_name: "请输入泰语商品名称",
  English_product_name: "英语商品名称",
  Please_enter_the_English_product_name: "请输入英语商品名称",
  Korean_product_name: "韩语商品名称",
  Please_enter_the_Korean_product_name: "请输入韩语商品名称",
  Brazilian_product_name: "巴西语商品名称",
  Please_enter_the_Brazilian_product_name: "请输入巴西语商品名称",
  hint_902: "尺寸建议174*174",
  hint_903: "尺寸建议375*375",
  Thai_language_details: "泰语详情说明",
  English_detailed_explanation: "英语详情说明",
  Vietnamese_language_details: "越南语详情说明",
  Korean_language_details: "韩语详情说明",
  Brazilian_language_details: "巴西语详情说明",
  Please_enter_the_diamond_price_of_the_product: "请输入商品钻石价",
  Are_you_sure_you_want_to_delist_this_product_: "是否确定下架该商品？",
  Label_ID: "标签ID",
  Please_enter_the_label_name: "请输入标签名称",
  Label_Name: "标签名称",
  hint_624: "是否确定上线该商品标签？",
  hint_101: "是否确定下线该商品标签？",
  Edit_label: "编辑标签",
  Order_number: "订单号",
  Please_enter_the_order_number: "请输入订单号",
  Transaction_time: "交易时间",
  Receiving_address: "收货地址",
  Signee: "签收人",
  Transaction_volume: "交易量",
  Total_transaction_amount: "交易总额",
  Transaction_status: "交易状态",
  Unsuccessful: "不成功",
  Limited_to_50_words_in_length: "限长50个字",
  Inviter_UID: "邀请人UID",
  Inviting_person_nickname: "邀请人昵称",
  Invitee_UID: "被邀请人UID",
  Invitee_registration_time: "被邀请人注册时间",
  Please_enter_the_inviter_reward: "请输入邀请人奖励",
  Please_enter_the_invitee_reward: "请输入被邀请人奖励",
  Rule_Description: "规则说明",
  APP_landing_page_background_image: "APP落地页背景图",
  hint_621: "尺寸：710X1086 只能上传jpg/png文件",
  Watch_the_live_broadcast: "观看直播",
  Live_room_speech: "直播间发言",
  Push_order_configuration: "推单配置",
  hint_102: "友情提示：1-发言配置请按照顺序依次进行配置。如配置到第2句，则仅会执行1，2发言配置内容。",
  hint_905: "第{num}次发言配置",
  Reward_amount: "奖励金额",
  Please_enter_the_speech_reward_amount: "请输入发言奖励金额",
  Valid_characters: "有效字符",
  hint_623: "请输入发言有效字符",
  hint_103: "第一次发言配置奖励金额不能为空",
  hint_104: "第二次发言配置奖励金额不能为空",
  hint_105: "第三次发言配置奖励金额不能为空",
  Friendly_reminder: "友情提示",
  hint_1: "1-推单数据及用户参与数据可前往 直播管理》直播间推单 模块查看；",
  hint_2: "2-推单奖励区间：主播推单的奖励会随机在该区间生成；",
  hint_3: "3-用户每日奖励峰值：超过该峰值则继续参与推单，不可获得奖励；",
  hint_889: "推单奖励区间",
  Maximum_reward: "最大奖励",
  Minimum_reward: "最小奖励",
  Users_get_peak_daily: "用户每日获得峰值",
  hint_622: "请输入用户每日最大获取值",
  Please_enter_the_maximum_reward: "请输入最大奖励",
  Please_enter_the_minimum_reward: "请输入最小奖励",
  Please_enter_the_user_daily_peak_gain: "请输入用户每日获得峰值",
  Viewing_duration: "观看时长",
  hint_107: "请输入观看时长，需为正整数",
  Watch_rewards: "观看奖励",
  Please_enter_the_viewing_reward: "请输入观看奖励",
  Reward_channels: "奖励渠道",
  hint_106: "开通后在该渠道观看直播会获得奖励，不同渠道观看仅奖励一次；",
  Please_enter_the_viewing_duration: "请输入观看时长",
  Please_select_a_reward_channel: "请选择奖励渠道",
  First_line_copy: "第一行文案",
  hint_108: "请输入文案，最大支持10字符",
  Second_line_copy: "第二行文案",
  Please_enter_the_first_line_of_text: "请输入第一行文案",
  Please_enter_the_second_line_of_text: "请输入第二行文案",
  Effective_date: "有效日期",
  Display_copy: "展示文案",
  Gift_channels: "赠送渠道",
  Login_rewards: "登陆奖励",
  Accumulated_Gifts: "累计赠送",
  Additional_rewards_for_app_login: "APP登陆额外奖励",
  hint_109: "请输入登陆奖励，为0则不奖励",
  Please_enter_the_reward_amount: "请输入奖励金额",
  Login_Activity_Rewards: "登录活动奖励",
  Please_enter_display_copy: "请输入展示文案",
  Please_enter_the_login_reward: "请输入登陆奖励",
  Please_enter_a_valid_date: "请输入有效日期",
  Please_enter_to_select_the_gift_channel: "请输入选择赠送渠道",
  Login_reward_activity: "登陆奖励活动",
  Download_copy_configuration: "下载文案配置",
  Lottery_time: "抽奖时间",
  Activity_ID: "活动id",
  Accessing_Users: "访问用户",
  Participating_users: "参与用户",
  Data_details: "数据明细",
  Channel_sources: "渠道来源",
  Click_on_the_number_of_lucky_draws: "点击抽奖次数",
  Page_visits: "页面访问次数",
  Number_of_times_participating_in_the_lottery: "参与抽奖次数",
  Winning_users: "中奖用户",
  Users_who_win_physical_prizes: "实物中奖用户",
  Click_to_go_to_the_assistant_user: "点击去小助手用户",
  Click_to_download_app_users: "点击下载APP用户",
  Award_pool_type: "奖池类型",
  Lucky_Wheel: "幸运转盘",
  Super_Wheel: "超级转盘",
  Lottery_Record_Daily_Turntable: "抽奖记录-日常转盘",
  Physical_rewards: "实物奖励",
  Draw_lottery_tickets: "抽奖券",
  Processing_personnel: "处理人",
  Not_issued: "未发放",
  Issued: "已发放",
  Virtual: "虚拟",
  Operation_turntable: "运营转盘",
  Activity_Management: "活动管理",
  Lottery_record: "抽奖纪录",
  Operation_turntable_activity_data: "运营转盘活动数据",
  Activity_type: "活动类型",
  Activity_carousel: "活动转盘",
  Daily_turntable: "日常转盘",
  Activity_statistics: "活动统计",
  Number_of_super_lottery_tickets_distributed: "超级抽奖券发放数量",
  Lucky_Wheel_Lucky_Draw_Times: "幸运转盘抽奖次数",
  Super_Wheel_Lottery_Times: "超级转盘抽奖次数",
  Basic_information: "基本信息",
  Activity_Rules: "活动规则",
  Please_enter_activity_rules: "请输入活动规则",
  PC_banner_image: "pc banner图",
  H5_Jump_Page: "h5跳转页面",
  In_use: "使用中",
  Total_inventory: "总库存",
  Prizes: "奖品",
  Single_day_upper_limit: "单日上限",
  Individual_users_can_receive_upper_limits: "单个用户获得上限",
  Lucky_draw_probability_in_tens_of_thousands_: "抽奖概率(万分）",
  Is_it_a_running_horse_lamp_: "是否跑马灯",
  Is_it_a_bottom_line: "是否兜底",
  Add_prize_pool: "添加奖池",
  Cloning: "克隆",
  Single_consumption: "单次消耗",
  Daily_limit: "每日上限",
  Activity_channels: "活动渠道",
  H5_banner_diagram: "h5 banner图",
  How_to_earn_cat_coins: "如何赚猫币",
  Please_select_the_activity_time_range: "请选择活动时间范围",
  Edit_anchor_recommendation_group: "编辑主播推荐组",
  Add_a_new_anchor_recommendation_group: "新增主播推荐组",
  Please_upload_the_PC_banner_image: "请上传pc banner图",
  Please_upload_the_h5_banner_image: "请上传h5 banner图",
  Please_enter_PC_Jump_Page: "请输入pc跳转页面",
  Please_enter_h5_to_jump_to_the_page: "请输入h5跳转页面",
  Please_enter_a_single_consumption: "请输入单次消耗",
  Please_enter_the_daily_limit: "请输入每日上限",
  Please_select_the_activity_channel: "请选择活动渠道",
  Prize_Pool: "奖池",
  hint_110: "奖池开启时间不可早于活动开启时间",
  hint_111: "奖池结束时间不可晚于活动结束时间",
  The_total_probability_does_not_equal_10000: "概率总和不等于10000",
  Sum_of_probabilities: "概率总和",
  Please_select_the_prize_for_prize_: "请选择奖品{num}的奖品",
  Please_enter_the_total_inventory_of_prize_: "请输入奖品{num}的总库存",
  _The_daily_limit_is_greater_than_the_total_inventory: "{num}单日上限大于总库存",
  hint_112: "请输入奖品{num}的抽奖概率(万分）",
  hint_115: "请选择奖品{num}的是否跑马灯",
  hint_116: "每个奖池必须选择一个兜底奖品!",
  hint_114: "请填写奖池的生效时间",
  hint_113: "是否确认删除该奖池?",
  Data_Overview: "数据概览",
  Quantity_of_currency: "货币数量",
  Please_enter_the_quantity_of_currency: "请输入货币数量",
  Number_of_lottery_tickets: "奖券数量",
  Please_enter_the_number_of_lottery_tickets: "请输入奖券数量",
  Select_icon: "选择icon",
  Please_select_the_type_of_prize: "请选择奖品类型",
  Please_upload_the_icon: "请上传icon",
  Editing_prizes: "编辑奖品",
  Please_enter_a_positive_integer: "请输入正整数",
  single_1: "个",
  submit_results_1: "提交结果",
  rules_options: "规则配置",
  rules_options_1: "规则配置不能为空",
  edit_activity_cai: "编辑竞猜活动",
  created_topic_1: "创建话题",
  // 新增
  created_topic_2: "编辑话题",
  briefing_topic_1: "话题简介",
  briefing_topic_2: "话题缩略图",
  hint_899: "只能上传jpg/png文件,限制1M,大小790*215px",
  hint_907: "只能上传jpg/png文件,限制1M,大小150*58px",
  hint_917: "只能上传jpg/png文件,限制1M,大小355*148px",
  hint_918: "图片尺寸建议为256x256,只能上传jpg/png文件",
  lookDetail: "查看",
  text_1: "是否确定下架？",
  text_2: "是否确定上架？",
  // 新增2
  matchPlan_1: '比赛进度',
  group_1: '小组',
  shopEdit: '商品编辑',
  viShopEditName: '越南语商品名称',
  viShopEditName1: '请输入越南语商品名称',
  hint_1001: '购买猫币不可为空',
  hint_1002: '购买钻石不可为空',
  hint_1003: '请输入猫币',
  // 新增3
  remark_edit: '备注编辑',
  currency_cat: '猫币',
  diamond_1: '钻石',
  Invitee_UID_1: "邀请人UID",
  Invitee_edit_1: "邀请配置",
  minute_1: "分钟",
  establish_1: "创建",
  hint_1005: "足球中奖统计数据",
  hint_1006: "篮球中奖统计数据",
  hint_1007: "中奖详情",
  hint_1008: "始",
  hint_1009: "止",
  hint_1011: "未填写",
  hint_1012: "选择banner图",
  hint_1013: "pc跳转页面",
  hint_1014: "使用",
  hint_1015: "奖品ID",
  hint_1016: "抽奖券",
  hint_1017: "链接",
  hint_1018: "资讯ID",
  hint_1019: "上传音乐缩略图",
  hint_1021: "英文字母等级限制",
  hint_1022: "直播间推单自动弹出",
  advertising_title: "广告标题",
  Author_name_1: "作者",
  // 新增4
  text_7: '用户私信主播',
  text_8: '主播私信用户',
  text_9: '用户私信管理员',
  text_10: '私聊主播等级',
  text_11: '下载页顶部Logo',
  text_12: 'APP/H5专辑页状态',
  text_13: 'IOS三倍图',
  text_14: '第一张',
  text_15: '第二张',
  text_16: '第三张',
  text_17: '第四张',
  text_18: '第${num}张内容不能为空',
  text_19: '第${num}张图片不能为空',
  text_20: '图片尺寸建议为40x40,只能上传jpg/png/gif文件',
  text_21: '图片尺寸建议为40x40,只能上传jpg/png/gif文件',
  text_22: 'PC专辑页名称',
  text_23: '请输入src对应的url,如需统计多个域名，请用||区分',
  text_24: '关于页面文案配置',
  text_25: '请填写文案内容',
  text_26: '请输入开发者账号',
  text_27: '新手引导页',
  text_28: '不用更新',
  text_29: '可选更新',
  text_30: '强制更新',
  text_31: '公钥',
  text_32: '私钥',
  text_33: '功能说明',
  text_34: 'Android配置',
  text_35: '直播间发送红包',
  text_36: '直播间送礼',
  text_37: '启动APP埋点上报',
  text_38: '域名类型',
  text_39: '编辑域名',
  text_40: '添加域名',
  have_1: '有',
  have_2: '无',
  updateTime_1: '更新时间',
  text_41: '确定要删除该域名吗？',
  text_42: '解禁后则该词可继续在平台发送,确认解禁吗？',
  text_43: '直播间banner url',
  text_44: 'H5banner',
  text_45: '请输入description',
  text_46: '请输入首页 title',
  text_47: '请输入直播页 title',
  text_48: '只能上传jpg/jpeg/png文件,最多支持上传9张',
  text_49: '上传视频文件',
  text_50: '请输入圈子id',
  text_51: '支持格式：JPG、PNG、GIF、MP4，大小：3M以内:',
  text_52: '未发布',
  text_53: '请输入简介',
  text_54: '只能上传jpg/png/gif文件,限制1M,大小790*215px',
  text_55: '设置人数上限',
  text_56: '投稿时间',
  text_57: '请设置人数上限',
  text_58: '人数上限不得小于50人',
  text_59: '真实姓名',
  text_60: '已同意',
  text_61: '创建圈子数',
  text_62: '圈子用户数',
  text_63: '加入时间',
  text_64: '身份证号码：',
  text_65: '请输入手机号码',
  text_66: '提交人',
  text_67: '体育类',
  text_68: '娱乐类',
  text_69: '其他',
  text_70: '只能上传jpg/png文件，图片尺寸需为120X120',
  text_71: '添加频道',
  text_72: '全部频道',
  channel_1: '频道',
  text_73: '指定房间',
  text_74: '上线成功',
  text_75: '位置',
  text_76: 'PC背景图',
  text_77: 'PC标题图',
  text_78: '玩法说明',
  text_79: '观看直播时长(分钟)',
  text_80: '概率',
  text_81: '0为不不可能抽中红包，此为用户抽中有奖红包的概率',
  text_82: '签到时间',
  text_84: '奖励状态',
  text_85: '派发奖励',
  text_86: '确定派发奖励吗？',
  text_87: '签到类型',
  text_88: '发送人userid',
  text_89: '请输入发送人userid',
  text_90: '红包ID',
  text_91: '发送人昵称',
  text_92: '发起房间号',
  text_93: '请输入发送房间号',
  text_94: '红包档位',
  text_95: '领取个数',
  text_96: '失效个数',
  text_97: '发送时间',
  text_98: '已启用',
  text_99: '主题ID',
  text_101: '输赢',
  text_102: '红包雨ID',
  text_103: '红包总数',
  text_104: '生效',
  text_105: '失效',
  text_106: '绑定赛事/主播',
  text_107: '已领红包个数',
  text_108: '领取人数',
  text_110: '活动',
  text_111: '领取',
  text_112: '房间',
  text_113: '平台累计分成',
  text_114: '登出时间',
  text_115: '注册IP',
  text_116: '在线时长',
  text_117: '派发状态：',
  text_118: '更改时间',
  text_119: '场次ID',
  text_120: '直播比赛ID',
  text_121: '玩法',
  text_122: '推单内容',
  text_123: '赛段',
  text_124: '支持',
  text_125: '主播结果',
  text_126: '反对',
  text_127: '修正结果',
  text_128: '奖励数额',
  text_129: '总发放',
  text_130: '举报人',
  text_131: '描述',
  text_132: '回复',
  text_133: 'APP背景',
  // 新增5
  live_time: '直播时长(分钟)',
  Please_select_the_results: '请选择本单的结果',
  red_1: '红',
  black_1: '黑',
  go_1: '走',
  corner: '角球',
  win_or_lose: '让分胜负',
  Size_division: '大小分',
  take_care_1: '注：尺寸为572x420',
  take_care_2: '注：尺寸为926x126',
  discount_1: '折扣',
  discount_name_1: '折扣名称',
  text_135: '请输入频道昵称',
  characters_1: '文字',
  picture_1: '图片',
  Please_fill_in_the_link_1: '请填写链接',
  Please_fill_in_the_link_2: '请正确填写链接',
  publish_this_announcement: '是否确定发布该公告？',
  send_state: '发送状态',
  Create_marketing_messages: '创建营销短信',
  SMS_content: '短信内容',
  View_receiving_users: '查看接收用户',
  Upload_list: '上传名单',
  Are_you_sure_to_cancel: '是否确认取消',
  User_Import_Template: '用户导入模板',
  channel_2: '渠道',
  autograph: '签名',
  SMS_type: '短信类型',
  SMS_template_editing: '短信模板编辑',
  SMS_template_add: '短信模板新增',
  Editing_time: '编辑时间',
  Instant_push: '即时推送',
  Timed_push: '定时推送',
  Page_H5: 'H5页',
  Menu_Type: '菜单类型',
  Role_authorization: '角色授权',
  name_111: '姓名',
  role_111: '角色',
  Modifying_Merchant_Basic_Information: '修改商户基本信息',
  Gift_ID: '礼物ID',
  Number_of_deliveries: '送出个数',
  Delivery_value_1: '送出价值(金币/万个)',
  broadcast_room_1: '送出直播间',
  broadcast_room_2: '请输入送出直播间',
  broadcast_room_3: '送出价值',
  Gift_giving_time: '送礼时间',
  Online_1: '已上线',
  Gift_unit_price: '礼物单价',
  hint_1110: '只能上传jpg/png文件,大小不超过1MB！',
  hint_1112: '预览效果',
  hint_1113: ' 只能上传gif文件,大小不超过1MB！',
  hint_1114: '播放效果',
  hint_1115: '只能上传svga文件,大小不超过10MB！',
  Full_platform: '全平台',
  This_room: '本房间',
  hint_1116: '只能上传只能上传jpg/png文件!',
  Type_of_issuance: '下发类型',
  Designated_category: '指定品类',
  hint_1117: '请输入指定房间号，多个房间之间使用英文的逗号隔开',
  hint_1118: '请输入礼物ID',
  hint_1119: '最多可输入99个礼物ID',
  hint_1120: '不可输入空格',
  hint_1121: '不可输入中文逗号',
  hint_1122: '只可输入中文、英文、数字',
  hint_1123: '请选择下发类型',
  hint_1124: '请选择下发范围',
  hint_1125: '请选择品类',
  hint_1126: '请指定房间',
  Publishable_status: '可发布状态',
  Expert_nickname: '专家昵称',
  Expert_brief: '专家简介',
  Expert_detail_1: '锦囊详情',
  article_1: '亚眠15轮比赛积分13分，排名第18位，居降级附加区；上轮比赛客场完败,，遭遇两连败,，状态低迷；而他们主场3胜4负，排名第12位，虽然表现不出色，但还是依靠主场抢分；阵容方面，球队有1名中场伤停，2名于球员出战存疑。客队上赛季排名法甲第二，本赛季下滑严重，目前15轮比赛积分10分，排名第19位，居降级区内；上轮比赛客场告负，球队同样遭遇两连败，状态较差；本赛季客场2胜1平4负，排名第13位，虽然表现一般，但所获胜利全为客场所得，有一定客战能力；阵容方面，球队饱受伤病领导困扰，目前有多达14名球员伤停。亚洲初始指数为本场比赛开出平手盘和主受平半盘，给予客队谨慎支持；最新指数中部分机构多调整为平手盘，机构对客队信心有所减弱；欧赔最新平均指数压低胜赔，多机构同时压低平赔，抬升负赔，不利客队。',
  user_1111: '用户',
  Import_1111: '导入',
  hint_1127: '已经达到添加上限了',
  Closed_111: '已关闭',
  Opened_111: '已开启',
  Select_111: '选择',
  less_than: '小于',
  Publisher_11: '发布者',
  Let_the_ball: '让球',
  analysis: '解析',
  Please_enter_the_parsing_content: '请输入解析内容',
  price_11: '售价',
  free_11: '免费',
  release_1: '发布',
  hint_1200: '标题需要4字以上',
  hint_1201: '推荐理由需要20字以上',
  Ball_selection: '球类选择',
  Top_failure_time: '置顶失效时间',
  refund: '退款',
  Topping_time: '置顶时间',
  System_messages: '系统消息',
  Please_enter_the_message_content: '请输入消息内容',
  abnormal: '异常',
  Let_the_ball_win: '让球胜平负',
  Please_set_the_time: '请设置时间',
  Please_message_content: '请填写消息内容',
  hint_20001: '置顶时间不能早于当前时间',
  hint_20002: '确定取消置顶吗？',
  hint_20003: '建仓时间',
  Purchase_records: '购买记录',
  // 新增8
  Edit_Resource_Bits: '编辑资源位',
  ADD_Resource_Bits: '新增资源位',
  hint_20091: '上移',
  hint_20092: '下移',
  hint_20093: '新增热门',
  hint_20094: '视频',
  hint_20095: '页面',
  hint_20096: '添加背景',
  hint_20097: '确定要下架此背景图吗？',
  hint_20098: '只能上传jpg/jpeg/png文件,宽高比 60*60',
  //新增9
  hint_20111: '请选择弹幕库',
  hint_20112: '请输入下发类型',
  hint_20113: '请输入下发范围',
  hint_20114: '确定要{status}此配置吗？',
  hint_20115: '已{status}',
  hint_20116: '弹幕内容不能为空',
  hint_20117: '单次最多添加1000条。',
  hint_20118: '添加时间',
  hint_20119: '开启后，该主播直播间将重新进入机器人，确定开启吗？',
  hint_20121: '主播类型',
  hint_20122: '请输入身份证',
  hint_20123: '房管',
  hint_20124: '请输入uid',
  hint_20125: '用户userId',
  hint_20126: '请输入用户userId',
  hint_20127: '请输入直播标题',
  hint_20128: '账号合并',
  hint_20129: '消息范围',
  hint_20130: '本条消息',
  hint_20888: '本直播间内消息',
  hint_20131: '所有直播间消息',
  hint_20132: '删除原因',
  hint_20133: '用户禁言',
  hint_20134: '禁言用户',
  hint_20135: 'UID不能为空',
  hint_20136: '禁言周期不能为空',
  hint_20137: '禁言原因不能为空',
  hint_20138: '房间号不能为空',
  hint_20139: '请输入主播userid',
  hint_20400: '发起审核',
  hint_20401: '审核失败',
  hint_20402: '导出excel',
  hint_20403: '结算周期',
  hint_20404: '调整金额：',
  hint_20405: '元',
  hint_20406: '未发起',
  hint_20407: '结算账单',
  hint_20408: '编辑前',
  hint_20410: '编辑后',
  hint_20411: '待提审',
  hint_20412: '添加公会',
  hint_20413: '请输入收款账户名称',
  hint_20414: '请输入开户行',
  hint_20415: '请输入银行卡号',
  hint_20416: '请输入开户地址',
  hint_20417: '请输入100字以内',
  hint_20418: '编辑公会',
  hint_20419: '停用后将不可为主播分配该公会，是否确定停用此公会？',
  hint_20420: '只能上传jpg/png文件，建议尺寸为',
  hint_20421: '配置账号',
  hint_20422: '查找内容',
  hint_20423: '替换内容',
  hint_20424: '信号源跳转地址',
  hint_20425: '请输入信号源地址',
  hint_20426: '请输入信号源跳转地址',
  hint_20427: '请填写ios Scheme',
  hint_20428: '请填写关于内容',
  hint_20429: '接口版本号',
  hint_20430: '配置人',
  hint_20431: '未选择任何短视频，请选择短视频后再发布！',
  hint_20432: '发布成功',
  hint_20433: '发布成功，此条短视频进入发布库',
  hint_20434: '爬取时间段',
  hint_20435: '请选择时间段',
  hint_20436: '已禁用',
  hint_20437: '设置成功',
  hint_20438: '是否置顶',
  hint_20439: '排序位置',
  hint_20440: '评论',
  hint_20441: '短视频封面',
  hint_20442: '编辑置顶',
  hint_20443: '请输入排序位置',
  hint_20444: '发布后无法进行修改，是否确认发布？',
  hint_20445: '访问渠道',
  hint_20446: '来源域名',
  hint_20447: '小助手UV',
  notice_111: '公告UV',
  index_111: '指数',
  test_111: '确定要{status}该话题吗？',
  test_112: '发布话题',
  test_113: '确定要删除吗？',
  Batch_approval: '批量审批',
  Reviewer_111: '审核人',
  you_want_to_pass: '确定要通过吗？',
  Download_failed: '下载失败',
  Individual_currency: '个人货币批量增发模板',
  pass_all_of_them: '确认要全部通过吗？',
  Fill_in_at_least: '增发货币、增发经验值至少填写其中一项',
  quantity_of_additional_currency: '请输入增发货币数量',
  money_111: '金额',
  Input_cannot_be_greater: '输入不能大于99999或小于1',
  Recharge_status: '充值状态',
  Gold_coins: '金币',
  Alipay_payment: '支付宝支付',
  WeChat_payment: '微信支付',
  ApplePay: '苹果支付',
  Unpaid_111: '未支付',
  Paid_111: '已支付',
  Balance_ranking: '余额排行',
  ranking_111: '排名',
  // 新增==
  Member_Change_Record: '会员变更记录',
  phone_area_code: '请设置手机区号',
  your_phone_number: '请设置手机号',
  Successfully_lifted: '解除禁言成功',
  // 新增
  Risk_categories: '风险类别',
  No_type_selected: '未选择类型',

  // 小助手新增
  Click_here_to_retry: '点此重试',
  Loading_failed: '加载失败',
  Invalid_data: '数据无效',
  Insert_Image: '插入图片',
  download_111: '下载',
  not_support_preview: '该文件不支持预览',
  hint_801: '文件过大，请重新上传',
  hint_802: '文件格式错误，请重新上传',
  hint_803: '文件上传失败',
  hint_804: '文件超出限制',
  Member_Information: '成员信息',
  Personnel_changes: '人员变化',
  Anchor_Circle: '主播圈',
  Total_number_of_people: '总人数',
  New_additions_today: '今日新增',
  Today_decrease: '今日减少',
  Today_cumulative_changes: '今日累计变换',
  person_111: '人',
  // 新增222
  Edit_anchor_circle: '编辑主播圈',
  Administrator: '管理员',
  Please_select_an_administrator: '请选择管理员',
  Anchor_Circle_Name: '主播圈名称',
  Anchor_Circle_ID: '主播圈ID',
  Anchor_Circle_Avatar: '主播圈头像',
  Number_of_members: '成员数',
  Free_speech: '自由发言',
  All_staff_are_prohibited_from_speaking_1: '全员禁言',
  Group_entry_time: '进群时间',
  Restricted_person: '限制人',
  Is_it_in_the_group: '是否在群',
  Remove: '移出',
  Enter: '进入',
  Reason: '原因',
  Operator: '操作员',
  Edit_Community_Circle: '编辑社群圈',
  Add_social_circles: '新增社群圈',
  Community_nickname: '社群圈昵称',
  Community_circle_avatar: '社群圈头像',
  Group_Leader: '群主',
  All_staff_are_prohibited_from_speaking: '全员禁言（不包含管理员）',
  Please_enter_the_social_circle_nickname: '请输入社群圈昵称',
  Please_select_the_community_circle_avatar: '请选择社群圈头像',
  Please_select_the_group_leader: '请选择群主',
  Please_select_the_speaking_status: '请选择发言状态',
  Community_Circle_ID: '社群圈ID',
  Community_Circle_Name: '社群圈名称',
  Are_you_sure_you_want_to_disable_this_circle: '确定要禁用该圈子？',
  Are_you_sure_you_want_to_enable_this_circle: '确认要启用该圈子？',
  User_cancels_operation: '用户取消操作',
  Speech_status: '发言状态',
  Reason_for_group_membership: '拉群原因',
  Group_pull_date: '拉群日期',
  Move_into_a_new_circle: '移入新圈',
  New_Circle_Name: '新圈名称',
  Circle_Announcement: '圈子公告',
  marked_6: '请填写完整公告链接信息再提交',
  Community_circle: '社群圈',
  Community_Circle_Management: '社群圈管理',
  Anchor_Circle_Management: '主播圈管理',
  Please_enter_the_name_of_the_anchor_circle: '请输入主播圈名称',
  Community_Circle_View: '社群圈查看',
  Anchor_Circle_View: '主播圈查看',
  Edit_customer_service_group_classification: '编辑客服组分类',
  Add_customer_service_group_classification: '新增客服组分类',
  Operation_failed: '操作失败',
  Edit_traffic_entry: '编辑流量入口',
  Add_traffic_entry: '新增流量入口',
  Entry_Name: '入口名称',
  Entrance_ID: '入口ID',
  Sort_Number: '排序编号',
  Entry_Description: '入口描述',
  Entrance_background_image: '入口背景图',
  Classification_ID: '分类ID',
  Policy_application_update: '策略应用更新',
  Set_up_customer_service_group: '设置客服组',
  Strategy_number: '策略编号',
  Tourists: '游客',
  Members: '会员',
  Reception_customer_service_team: '接待客服组',
  Are_you_sure_you_want_to_deactivate_this_account: '确定要停用该账号？',
  Are_you_sure_you_want_to_enable_this_account: '确认要启用该账号吗？',
  Traffic_entrance: '流量入口',
  Traffic_Entry_Name: '流量入口名称',
  Traffic_Entry_Description: '流量入口描述',
  Customer_service_group_classification: '客服组分类',
  Are_you_sure_you_want_to_disable_this_entry: '确定要禁用该入口？',
  Are_you_sure_you_want_to_enable_this_entry: '确认要启用该入口？',
  Entering_delay_time_in_milliseconds: '正在输入延迟时间毫秒',
  marked_8: '请输入正在输入延迟时间毫秒',
  Entering_display_time_in_milliseconds: '正在输入显示时间毫秒',
  marked_9: '请输入正在输入显示时间毫秒',
  marked_10: '用户显示假消息的时间间隔分钟',
  marked_11: '请输入用户显示假消息的时间间隔分钟',
  Entering_Chinese_script_content: '正在输入中话术内容',
  Global_configuration: '全局配置',
  Edit_account: '编辑账号',
  Add_account: '新增账号',
  A_vest: '马甲',
  Customer_service_1: '客服',
  Account_type: '账号类型',
  Account_avatar: '账号头像',
  Account_nickname: '账号昵称',
  Please_enter_your_account_nickname: '请输入账号昵称',
  Please_select_account_type: '请选择账号类型',
  Please_upload_your_account_avatar: '请上传账号头像',
  Please_select_account_status: '请选择账号状态',
  Log_content: '日志内容',
  marked_50: '编号',
  System_logs: '系统日志',
  Exporting: '导出中...',
  marked_12: '缺少导出数据必须的参数',
  Last_7_days: '最近7天',
  In_the_past_30_days: '最近30天',
  In_the_past_60_days: '最近60天',
  In_the_past_90_days: '最近90天',
  Time_range: '时间范围',
  System_account: '系统账号',
  marked_13: '当日新增转化用户数',
  marked_14: '当日新增取消转化用户数',
  Total_number_of_converted_users: '转化用户总数',
  Edit_private_script: '编辑私有话术',
  Add_private_script: '新增私有话术',
  Script_content: '话术内容',
  Script_state: '话术状态',
  Please_enter_the_script: '请输入话术',
  Please_select_the_script_status: '请选择话术状态',
  Are_you_sure_you_want_to_deactivate_this_script: '确定要停用该话术？',
  Are_you_sure_you_want_to_enable_this_script: '确认要启用该话术？',
  Edit_public_automatic_scripts: '编辑公共自动话术',
  Add_public_automatic_scripts: '新增公共自动话术',
  Script_Title: '话术标题',
  Associated_traffic_entry: '关联流量入口',
  Associate_with_customer_service_group: '关联客服组',
  Please_enter_the_script_title: '请输入话术标题',
  Please_enter_the_sorting_number: '请输入排序编号',
  Please_select_the_associated_traffic_entry: '请选择关联流量入口',
  marked_15: '请选择关联客服组',
  Editing_public_language: '编辑公共话术',
  Add_public_language_skills: '新增公共话术',
  Script_number: '话术编号',
  Public_speaking: '公共话术',
  Public_automatic_language: '公共自动话术',
  marked_17: '确定要停用该标签？',
  marked_16: '确认要启用该标签？',
  Edit_custom_user_groups: '编辑自定义用户组',
  Add_custom_user_groups: '新增自定义用户组',
  Custom_user_group_name: '自定义用户组名称：',
  Custom_user_group_description: '自定义用户组描述',
  Master_Administrator: '主管理员',
  Speech_settings: '发言设置',
  marked_18: '请输入自定义用户组名称',
  Please_select_an_icon: '请选择图标',
  Please_select_the_cover_image: '请选择封面图',
  Please_select_the_primary_administrator: '请选择主管理员',
  Please_select_speech_settings: '请选择发言设置',
  Circle: '圈子',
  Add_internal_members: '新增内部成员',
  Operator_Name: '操作员姓名',
  Vest_account_nickname: '马甲账号昵称',
  Third_party_ID: '第三方ID',

  //小助手缺失翻译
  Business_ID: '业务ID',
  Please_select_a_member: '请选择成员',
  User_group_number: '用户组编号',
  User_group_name: '用户组名称',
  User_group_label: '用户组标签',
  Updated_by: '更新人',
  Member_management: '成员管理',
  Cancel_administrator: '取消管理员',
  Remove_user_group: '移除用户组',
  Set_as_administrator: '设为管理员',
  Business_UID: '业务UID',
  Are_you_sure_you_want_to_proceed: '确定操作吗？',
  User_ID: '用户编号',
  User_group_user_records: '用户组用户记录',
  Previous_user_group: '上一用户组',
  Current_user_group: '当前用户组',
  Explanation: '说明',
  Cancel_conversion: '取消转化',
  Initial_reporting_time: '初次上报时间',
  In_the_anchor_circle: '所在主播圈',
  Community_Circle: '所在社群圈',
  Are_you_sure_you_want: '确定要{num}该用户吗？',
  User_Information: '用户信息',
  Information_line: '信息线',
  Domain_Name_Source: '域名来源',
  Please_enter_the_domain_name_source: '请输入域名来源',
  Please_enter_ID: '请输入ID',
  Access_time: '访问时间',
  Assistant_data: '小助手数据',
  Customer_service_seat_details: '客服坐席详情',
  Force_offline: '强制下线',
  Signal_status: '信号状态',
  Seat_status: '坐席状态',
  Current_reception: '当前接待',
  Recently_offline: '最近下线',
  Recently_launched: '最近上线',
  Currently_receiving_users: '当前接待用户',
  There_are_currently_no_customers: '暂无客户',
  There_is_no_more: '没有更多了',
  Seat_status_log: '坐席状态日志',
  Signal_status_log: '信号状态日志',

  // 新增3333
  Offline: '离线',
  Online: '在线',
  Busy: '忙碌',
  Historical_status: '历史状态',
  Reception_traffic_entrance: '接待流量入口',
  Sender_User_ID: '发送人用户ID',
  User_type_of_sender: '发送人用户类型',
  Message_ID: '消息ID',
  Message_type: '消息类型',
  Message_content: '消息内容',
  Private_conversation_ID_1: '私聊会话ID',
  Recipient_User_ID: '接收人用户ID',
  Recipient_user_type: '接收人用户类型',
  Sending_time: '发送时间',
  Number_of_reception_sessions: '接待会话数',
  View_current_chat_history: '查看当前聊天记录',
  View_all_chat_records: '查看所有聊天记录',
  Number_of_receptionists: '接待人数',
  Private_conversation_ID: '私聊会话ID：',
  Please_enter_the_private_chat_session_ID: '请输入私聊会话ID',
  Session_start_time: '会话开始时间',
  Customer_service_account_ID: '客服账号ID',
  Customer_service_nickname: '客服昵称',
  marked_19: '客服直播间捞起',
  marked_20: '用户触发流量入口变更释放',
  marked_21: '客服主动释放',
  Customer_service_switch_offline_release: '客服切换离线释放',
  marked_1: '用户私聊信息发送客服信号离线释放',
  marked_2: '用户私聊信息发送客服坐席离线释放',
  marked_3: '用户私聊信息发送客服坐席状态未知释放',
  marked_4: '用户私聊信息发送客服资料缺失释放',
  marked_5: '用户私聊信息发送流量入口变更释放',
  Unknown: '未知',
  Add_menu: '新增菜单',
  catalogue: '目录',
  Menu: '菜单',
  Menu_icon: '菜单图标',
  Menu_Title: '菜单标题',
  Routing_address: '路由地址',
  Whether_to_hide_or_not: '是否隐藏',
  Menu_Name: '菜单名称',
  Permission_identifier: '权限标识',
  Component_path: '组件路径',
  Please_enter_the_menu_title: '请输入菜单标题',
  Please_enter_the_routing_address: '请输入路由地址',
  Please_select_the_superior_category: '请选择上级类目',
  Are_you_sure_you_want_to_delete: '确定要删除{num}该项菜单吗？',
  Please_enter_your_account_number: '请输入账号',
  Please_enter_your_name: '请输入姓名',
  Please_select_a_role: '请选择角色',
  Please_enter_password: '请输入密码',
  marked_22: '请至少选择一个客服组',
  Reset_password: '重置密码',
  Customer_Service_Group: '所属客服组',
  Role: '角色',
  marked_23: '确定要下线该账户？',
  marked_24: '确定要停用该账户？',
  marked_25: '确定要重置该账户密码？',
  Modify_profile_picture: '修改头像',
  New_avatar: '新头像',
  Please_select_a_profile_picture: '请选择头像',
  marked_26: '修改成功，请重新登录',
  Change_nickname: '修改昵称',
  Business_System_Api: '业务系统Api',
  Conversion_System_Api: '转化系统Api',
  Please_enter_the_business_system_Api: '请输入业务系统Api',
  Please_enter_the_conversion_system_Api: '请输入转化系统Api',
  Official_avatar: '官方头像',
  Official_nickname: '官方昵称',
  Please_enter_the_official_nickname: '请输入官方昵称',
  Page_Title: '页面标题',
  Please_enter_the_page_title: '请输入页面标题',
  Red_series: '红色系',
  Green_series: '绿色系',
  Black_series: '黑色系',
  Please_upload_your_profile_picture: '请上传头像',
  Please_select_a_style: '请选择样式',
  Configuration_query_failed: '配置查询失败',
  Preview_Configuration: '预览配置',
  Please_complete_the_form: '请完善表单',
  Provide_the_necessary_parameters_for_preview: '提供预览需要的参数',
  Vest_UID: '马甲UID',
  Operator_ID: '操作员ID',
  Operator_account: '操作员账号',
  Operator_nickname: '操作员昵称',
  Explanation_111: '说明：马甲账号对应前端的一个真实C端用户账号，目前每个账号仅限10个马甲账号；',
  Failed_to_obtain_user_information: '获取用户信息失败',
  Failed_to_obtain_token: '获取token失败',
  Customer_service: '客服(不可用)',
  Switching_operational_accounts: '切换运营账号',
  Quick_reply_script: '快速回复话术',
  marked_27: '已自动提取用户{num}位',
  Update: '更新',
  Clear: '清空',
  Extract_users: '提取用户',
  block: '拉黑',
  Locked: '锁定',
  marked_28: '确定要清空提取用户吗？',
  Successfully_blacklisted: '拉黑操作成功',
  User_group_comments: '用户组内发言',
  marked_29: '发言命中自动提取用户',
  marked_30: '只能输入中英文',
  Successfully_deleted_keyword: '删除关键词成功',
  User_Group: '用户组',
  Registration_days: '注册天数',
  Release: '释放',
  Label: '标签',
  Tagging: '打标签',
  Conversion_Description: '转化说明',
  Application_Name: '应用名称',
  Installation_time: '安装时间',
  User_nickname: '用户昵称',
  Chat_content: '聊天内容',
  Time_of_occurrence: '发生时间',
  Private_Chat: '私聊',
  Business_System_UID: '业务系统UID',
  speak111: '发言',
  marked_35: '请输入社群圈名称',
  Name1111: '名称',
  password111: '密码',
  enter_the_entrance_ID: '请输入入口ID',
  enter_the_entrance_111: '请输入入口名称',
  enter_the_entrance_112: '请输入入口描述',
  enter_the_entrance_113: '请选择入口背景图',

  // 新增444
  marked_66: '业务端ID',
  marked_67: '导出中...',
  marked_68: '标签状态',
  marked_69: '请选择标签状态',
  marked_70: '至少再添加一个标签',
  marked_71: '编号/昵称',
  marked_72: '注册日期',
  marked_73: '用户详情',
  marked_74: '用户头像',
  marked_75: '赛事时间',
  marked_76: '主',
  marked_77: '历史接待用户',
  marked_78: '确认强制下线吗？',
  marked_79: 'APP用户',
  marked_80: '客服组',
  marked_81: '发起来源',
  marked_82: '用户发起',
  marked_83: '客服直播间捞起',
  marked_84: '用户ID',
  marked_85: '关闭类型',
  marked_86: '会话结束时间',
  marked_87: '成功',
  marked_88: '登录密码',
  marked_89: '移除客服组',
  marked_90: '确认要启用该账户吗？',
  marked_91: '请选择角色状态',
  marked_92: '授权成功',
  marked_93: '新昵称',
  marked_94: '客服信息',
  marked_95: '请完善表单,提供预览需要的参数',
  marked_96: '请输入新的昵称',
  marked_97: '账号等级',
  marked_98: '启用为我的马甲',
  marked_99: '(不可用)',
  marked_100: '发送',
  marked_101: '发言命中',
  marked_102: '锁定操作成功',
  marked_103: '关键词已达上限，无法再添加',
  marked_104: '暂无群聊',
  marked_105: '安装应用',
  marked_106: '转化',
  marked_107: '转化用户',
  style_select: '样式选择',
  button111: '按钮',
  // 新增
  'Member_login_data': '会员登入数据',
  'Login_type': '登录类型',
  // 新增
  'Such_as': '例如',
  'marked_108': '不能小于0',
  'marked_109': '不能输入空格',
  'marked_110': '只能上传jpg/png文件，图片尺寸需为360X842，最多4张',
  'marked_111': '图片尺寸建议为400x60,只能上传jpg/png/gif文件',
  'marked_112': '最多上传4张',
  'marked_113': '请输入正文',
  'marked_114': '请设置封面',
  'Comment_details': '评论详情',
  'marked_115': '备注：最多置顶20条数据',
  'marked_116': '话题背景图',
  'marked_117': '启用后则自动爬取该网站的数据，是否确认启用？',
  'marked_118': "第{num}张",
  // 新增
  'marked_119': '请选择标签级别',
  'marked_120': '请选择对应一级标签',
  'marked_121': '批量变更所属二级状态',
  'marked_122': '创建的标签，不支持删除，变更名称或自身标签级别；只支持变更所属的一级标签，和标签状态。是否确认创建标签？',
  'marked_123': '是否确认变更？',
  // 新增
  'marked_124': '分配数据量',
  'marked_125': '发布量',
  'unaudited': '未审核',
  'Video_size': '视频尺寸',
  'marked_126': '请填写登录地址',
  //新手通道
  'new_passage': '新手通道',
  'vip_passage': 'VIP通道',
  'zhanshang_passage': '招商通道',
  'click_num': '点击数量',
  'small_help': '小助手点击量统计数据',
  // 新增
  'So_save_it': '先保存',
  'if_save_to_web': '是否更新到前端',
  'match_name': '赛事名称',
  'if_candle_hot': '是否取消热门',
  'candle_hot': '取消热门',
  'go_hot': '上热门',
  'candle_hot_time': '取消热门时间',
  'please_add_match': '请添加赛事',
  'hint3333': '确定删除吗',
  'hint3334': '取消热门赛事',
  'hint3335': '确定更新吗',
  'hint3336': '确定上热门吗',
  'hint3337': '隐藏?',
  'hint3338': '显示?',
  'hint3339': '只能上传jpg/png/jpeg/gif文件',
  'hint3340': '小助手回复速度',
  'hint3341': '小助手开启速度',
  'hint3342': '小助手处理满意度',
  'hint3343': '意见反馈',
  'hint3344':'小助手满意度数据'
};
