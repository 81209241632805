import axios from 'axios'
import { Notification, MessageBox } from 'element-ui'
import router from '@/router'
import store from '@/store'
import { getToken, getMerchantId } from '@/utils/auth'
import { getLanguage } from '@/locale/index'
// create an axios instance
const VUE_APP_URL_2 = process.env.VUE_APP_URL_2
const VUE_APP_URL = process.env.VUE_APP_URL
const service = axios.create({
  baseURL: VUE_APP_URL,
  timeout: 15000 // request timeout
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['X-ACCESS-TOKEN'] = getToken()
    }
    if (getMerchantId()) {
      config.headers['Merchantid'] = getMerchantId()
    }
    config.headers['platform'] = 'h5'
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept-Language'] = getLanguage()
    if (config.url && config.url.indexOf('/console') > -1 && config.url.indexOf('/console/currency') == -1) {
      config.baseURL = VUE_APP_URL_2
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const status = response.status
    const code = response.data.code
    if (status < 200 || status > 300) {
      Notification.error({
        title: response.message
      })
      return Promise.reject('error')
    }

    if (code === 1303) {
      // token过期，退出登录
      store.dispatch('CleanUserInfo').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    }

    if (code === 1045) {
      return Promise.reject(response)
    }
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.data.msg
      })
      return Promise.reject('error')
    }
    return response.data
  },
  error => {
    let status = 0
    try {
      status = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 5000
        })
        return Promise.reject(error)
      }
    }
    if (status) {
      if (status === 401) {
        MessageBox.confirm(
          '登录状态已过期，您可以继续留在该页面，或者重新登录',
          '系统提示',
          {
            confirmButtonText: '重新登录',
            cancelButtonText: this.$t('common.Cancel_1'),
            type: 'warning'
          }
        ).then(() => {
          store.dispatch('LogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      } else if (status === 403) {
        router.push({ path: '/401' })
      } else if (status === 1045) {
        console.log('error', error.response.data.message)
      } else {
        const errorMsg = error.response.data.message
        if (errorMsg !== undefined) {
          Notification.error({
            title: errorMsg,
            duration: 5000
          })
        }
      }
    } else {
      Notification.error({
        title: '接口请求失败',
        duration: 5000
      })
    }
    return Promise.reject(error)
  }
)
export default service
