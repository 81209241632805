
/**
 * @param {string} path
 * @returns {Boolean}
 */
import { getLanguage } from '@/locale/index'
import i18n from '@/locale'
const languageInfo = i18n.messages[getLanguage()].common
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  return str.length > 1
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @param {any} rule
 * @param {any} value
 * @param {any} callback
 */
export function isGreaterThanZero(rule, value, callback) {
  if (parseFloat(value) < 0) {
    callback(new Error(languageInfo.marked_108))
  }
  callback()
}

/**
 * @param {any} rule
 * @param {any} value
 * @param {any} callback
 */
export function noSpaces(rule, value, callback) {
  if (value === '') {
    callback(new Error(languageInfo.Input_cannot_be_empty))
  }
  if (/(^\s+)|(\s+$)|\s+/g.test(value)) {
    callback(new Error(languageInfo.marked_109))
  }
  callback()
}

/**
 * @param {any} rule
 * @param {any} value
 * @param {any} callback
 */
export function noSpecialCharacters(rule, value, callback) {
  if (value === '') {
    callback(new Error(languageInfo.Input_cannot_be_empty))
  }
  if (!/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(value)) {
    callback(new Error(languageInfo.hint_6601))
  }
  callback()
}

/**
 * @param {any} rule
 * @param {any} value
 * @param {any} callback
 */
export function noDecimal(rule, value, callback) {
  if ((value + '').indexOf('.') !== -1) {
    callback(new Error(languageInfo.Decimal_cannot_be_entered))
  }
  callback()
}
