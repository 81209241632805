import request from '@/api/httpRequest'

export function login(params) {
  return request({
    url: '/admin/user/loginPlus',
    method: 'post',
    data: params
  })
}

export function getInfo() {
  return request({
    url: '/admin/user/search',
    method: 'post',
    data: {}
  })
}
export function getMerchantsInfo() {
  return request({
    url: '/admin/merchant/info',
    method: 'post',
    data: {}
  })
}
export function sendSms(params) {
  return request({
    url: '/admin/merchant/sms/send',
    method: 'post',
    data: params
  })
}

export function merchantEdit(params) {
  return request({
    url: '/admin/merchant/edit',
    method: 'post',
    data: params
  })
}

export function logout() {
  return request({
    url: '/admin/user/loginOut',
    method: 'post'
  })
}

export function fetchList(params) {
  return request({
    url: '/admin/user/page',
    method: 'post',
    data: params
  })
}

export function create(params) {
  return request({
    url: '/admin/user/create',
    method: 'post',
    data: params
  })
}

export function updateUserinfo(params) {
  return request({
    url: '/admin/user/update',
    method: 'post',
    data: params
  })
}

export function updateStatus(params) {
  return request({
    url: '/admin/user/updateStatus',
    method: 'post',
    data: params
  })
}

export function resetPassword(params) {
  return request({
    url: '/admin/user/resetPassword',
    method: 'post',
    data: params
  })
}

export function modifyPassword(params) {
  return request({
    url: '/admin/user/modifyPassword',
    method: 'post',
    data: params
  })
}
