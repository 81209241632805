import { render, staticRenderFns } from "./404.vue?vue&type=template&id=281ca300&scoped=true"
import script from "./404.vue?vue&type=script&lang=js"
export * from "./404.vue?vue&type=script&lang=js"
import style0 from "./404.vue?vue&type=style&index=0&id=281ca300&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "281ca300",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/rongjia/Desktop/newSM/new-sportsLive-merchant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('281ca300')) {
      api.createRecord('281ca300', component.options)
    } else {
      api.reload('281ca300', component.options)
    }
    module.hot.accept("./404.vue?vue&type=template&id=281ca300&scoped=true", function () {
      api.rerender('281ca300', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/features/404.vue"
export default component.exports