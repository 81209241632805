import request from '@/api/httpRequest'

export function fetchList(params) {
  return request({
    url: '/menu/list',
    method: 'get',
    data: params
  })
}

export function buildMenus() {
  return request({
    url: '/admin/acl/resource/build',
    method: 'post',
    data: {}
  })
}

export function getPrivileges(params) {
  return request({
    url: '/admin/acl/resource/getMenu',
    method: 'post',
    data: {}
  })
}

export function createMenu(params) {
  return request({
    url: '/admin/acl/resource/create',
    method: 'post',
    data: params
  })
}

export function updateMenu(params) {
  return request({
    url: '/admin/acl/resource/update',
    method: 'post',
    data: params
  })
}

export function deleteMenu(params) {
  return request({
    url: '/admin/acl/resource/delete',
    method: 'post',
    data: params
  })
}
