/**
 * @param {obj} 任意参数
 * @returns {String}
 * @description 判断参数类型
 */
export const getType = (obj) => {
  // tostring会返回对应不同的标签的构造函数
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
   * @param {data} 任意参数
   * @returns {Object}
   * @description 任意数据深拷贝
   */
export const deepClone = (data) => {
  const type = getType(data)
  let obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (let i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (const key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}

/**
 * 深度拷贝（简单版）
 * 注：仅拷贝普通对象或数组中的自身可迭代属性，其他的都不拷贝，拷贝规则如下：
 * boolean、number、string、null、undefined 和 function 、RegExp、Date 类型采用浅拷贝，
 * 除此以外的引用类型（对象和数组），使用深拷贝。
 * @param { Array | Object } source 被拷贝对象
 * @returns 深度拷贝后的对象
 */

export const deepCopy = source => {
  let target
  if (Array.isArray(source)) {
    target = []
  } else if ([RegExp, Date].includes(source.constructor)) {
    return source
  } else if (typeof source === 'object') {
    target = {}
  } else {
    return source
  }
  const deepCopyArray = array => {
    return array.map(item => {
      if (typeof item !== 'object' || item === null) {
        return item
      } else if (item.constructor === Array) {
        return deepCopyArray(item)
      } else {
        return deepCopy(item)
      }
    })
  }
  let once
  for (const key in source) {
    once = true
    if (!source.hasOwnProperty(key)) {
      continue
    }
    const value = source[key]
    if (typeof value !== 'object' || value === null) {
      target[key] = value
    } else if (value.constructor === Array) {
      target[key] = deepCopyArray(value)
    } else {
      target[key] = deepCopy(value)
    }
  }
  if (!once) {
    if (Array.isArray(source)) {
      target = []
    }
  }
  return target
}
