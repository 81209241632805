export default {
  Edit: "Biên tập viên",
  User_Configuration: "Cấu hình người dùng",
  Please_enter_a_numerical_level: "Vui lòng nhập số cấp độ.",
  hint_4: "Dưới cấp độ này không cho phép cài đặt bao gồm biệt danh chữ cái tiếng Anh",
  Nickname_English_letter_level_limit: "Biệt danh tiếng Anh hạn chế cấp bậc chữ cái.",
  Live_broadcast_room_configuration: "Cấu hình phòng phát sóng trực tiếp.",
  hint_6: "Thấp hơn cấp bậc này thì không cho phép nói chuyện trực tiếp.",
  hint_5: "Dưới cấp độ này phòng trực tiếp trò chuyện chữ cái tiếng Anh bị thay thế *",
  Open: "Mở ra.",
  Close: "Đóng cửa.",
  Circle_Speech_Configuration: "Cấu hình phát biểu vòng tròn",
  hint_7: "Thấp hơn cấp bậc này thì không cho phép người dẫn chương trình tin nhắn riêng.",
  Speech_level_limit: "Giới hạn cấp độ phát biểu.",
  hint_8_: "Thấp hơn cấp bậc này thì không cho phép nói chuyện phiếm trong giới.",
  Posting_updates_submission_levels: "Xuất bản năng động/đóng góp xếp hạng",
  hint_9: "Dưới cấp độ này thì không được phép đăng động/đóng góp.",
  hint_911: "Phát hành động/đóng góp",
  Cancel_1: "Hủy bỏ.",
  Save_1: "Tiết kiệm.",
  Please_enter_level: "Vui lòng nhập cấp bậc.",
  APP_bottom_label: "Nhãn dưới cùng của APP",
  Initial_icon: "icon ban đầu",
  Activity_icon: "Biểu tượng hoạt động",
  World_Cup_icon: "Icon World Cup bằng tiếng Anh",
  The_bottom_marketing_position_of_the_APP: "Vị trí tiếp thị dưới cùng của APP",
  Please_enter_the_redirect_address: "Vui lòng nhập địa chỉ nhảy",
  Event_start_time: "Thời gian bắt đầu sự kiện",
  APP_homepage_my_background: "Trang đầu của APP，bối cảnh của tôi.",
  hint_10: "Kích thước： 1125 * 624，chỉ có thể tải lên tệp jpg/png",
  Operation: "Hoạt động.",
  Upload: "Tải lên.",
  Ordinary_user_currency_icon: "Đồng tiền người dùng thông thường icon",
  Size_1: "Kích thước.",
  Type: "Loại",
  Preview_image: "Biểu đồ xem trước",
  Live_settlement_currency_icon: "Phát sóng trực tiếp thanh toán tiền tệ icon",
  Brocade_Bag_Settlement_Currency_icon: "Tiền tệ thanh toán túi gấm.",
  hint_11: "Hỗ trợ định dạng： PNG，Kích thước： Trong vòng 500K",
  Android_Triple_Image: "Bản đồ Android Triple Chart",
  Top_of_homepage_top_of_activity_page: "Đầu trang，đầu trang sự kiện",
  At_the_top_of_the_competition_page_my: "Trên trang thi đấu，của tôi.",
  Signal_source_top: "Đỉnh nguồn tín hiệu",
  Please_select: "Vui lòng chọn",
  Please_upload: "Xin hãy tải lên.",
  Operation_successful: "Chiến dịch thành công",
  Can_only_upload_can_only_upload_PNG_files: "Chỉ có thể tải lên chỉ có thể tải lên tệp png",
  hint_800: "Kích thước tải lên không thể vượt quá 500K!",
  Login_address_configuration: "Cấu hình địa chỉ đăng nhập",
  Please_enter_your_login_address: "Vui lòng nhập địa chỉ đăng nhập",
  hint_12: "Cấu hình này chỉ có hiệu lực đối với đầu đẩy sử dụng hệ thống người dùng bên thương gia，sau khi cấu hình，đầu đẩy hỗ trợ nhận dạng tên người dùng và mật khẩu riêng của bên thương gia",
  Recommended_by_the_editorial_circle: "Giới thiệu vòng tròn biên tập",
  Please_enter_the_resource_name: "Vui lòng nhập tên tài nguyên",
  Circle_List_Recommendation: "Giới thiệu danh sách vòng tròn",
  Recommended_by_Square_Circle: "Giới thiệu vòng tròn quảng trường.",
  Please_enter_userid: "Vui lòng nhập userid.",
  Please_enter_the_IP_address: "Vui lòng nhập IP",
  Search: "Tìm kiếm.",
  Refresh_IP_blacklist_cache: "Làm mới bộ nhớ cache danh sách đen IP",
  Reset: "Đặt lại.",
  Loading: "Đang tải...",
  Merchant_ID_1: "Số thương gia.",
  Prohibition_time_1: "Thời gian phong cấm.",
  Remove: "Loại bỏ.",
  hint_743: "Bạn có chắc chắn muốn xóa IP này không?",
  APP_brand_name: "Tên thương hiệu APP",
  Default_username_prefix: "Tiền tố tên người dùng mặc định",
  Common_user_currency_name: "Tên tiền tệ của người dùng thông thường",
  Live_settlement_currency_name: "Live Settlement Money Name(bằng tiếng Anh)",
  The_name_of_the_settlement_currency_for_the_brocade_bag: "Tên tiền tệ thanh toán túi gấm.",
  Contact_customer_service: "Liên hệ với dịch vụ khách hàng.",
  Assistant_default_icon: "Biểu tượng mặc định của trợ lý nhỏ",
  Click_to_select_resources: "Click vào Select Resources",
  hint_13: "Chỉ có thể tải lên tệp svga，kích thước hình ảnh không thể vượt quá 800k",
  Assistant_unread_message_icon: "Biểu tượng tin nhắn chưa đọc cho trợ lý nhỏ",
  Assistant_displays_icon_size: "Trợ lý nhỏ hiển thị kích thước biểu tượng",
  Small: "Tiểu nhân.",
  Medium: "Trung.",
  Big: "Đại.",
  Assistant_display_time: "Tiểu trợ lý trình diễn thời gian.",
  Online_customer_service_address: "Địa chỉ dịch vụ khách hàng trực tuyến",
  Initialize_account: "Khởi tạo tài khoản",
  Anchor_tool_download_address: "Anchor Tool Download Address (bằng tiếng Anh)",
  ICP_Filing_Information: "Thông tin nộp hồ sơ ICP",
  Live_broadcast_room_default_message: "Tin tức mặc định trong phòng phát sóng trực tiếp.",
  Conversation_background: "Bối cảnh trò chuyện.",
  Default_official_anchor_profile_picture: "Avatar chính thức mặc định",
  Default_official_message_avatar: "Avatar thông điệp chính thức mặc định",
  Login_registration_page_background: "Đăng nhập vào trang đăng ký nền",
  hint_14: "Hỗ trợ định dạng： JPG，PNG，GIF，MP4，Kích thước： Trong vòng 3M",
  Delete: "Xóa.",
  Triple_plot: "Bản đồ gấp ba lần.",
  Double_plot: "Bản đồ gấp đôi.",
  Please_fill_in_the_brand_name: "Vui lòng điền tên thương hiệu.",
  Please_fill_in_the_prefix: "Vui lòng điền tiền tố.",
  Please_fill_in_the_currency_name: "Vui lòng điền tên tiền.",
  Please_fill_in_customer_service_WeChat: "Xin vui lòng điền vào wechat của dịch vụ khách hàng.",
  Please_fill_in_the_download_address_for_the_anchor_tool: "Vui lòng điền địa chỉ tải xuống công cụ neo",
  Please_fill_in_the_ICP_information: "Vui lòng điền thông tin ICP",
  Please_fill_in_the_default_message_for_the_live_broadcast_room: "Xin hãy điền vào thông điệp mặc định của phòng phát sóng trực tiếp.",
  Please_fill_in_the_assistant_display_time: "Xin hãy điền vào thời gian trình diễn của trợ lý nhỏ.",
  hint_15: "Tải lên kích thước sai，xin vui lòng tải lên theo yêu cầu định dạng!",
  hint_16: "Chỉ có thể upload，chỉ có thể upload file png!",
  The_upload_size_cannot_exceed: "Kích thước tải lên không thể vượt quá.",
  hint_17: "Kích thước truyền không thể vượt quá 800K!",
  Select: "Chọn.",
  Please_upload_the_edited_Excel_template_file: "Vui lòng tải lên tập tin mẫu Excel đã chỉnh sửa",
  Confirm: "Xác nhận.",
  Importing: "Nhập khẩu",
  Please_upload_the_file: "Vui lòng tải tập tin lên.",
  Only_xlsx_format_files_can_be_uploaded: "Chỉ có thể tải lên các tập tin định dạng.xlsx!",
  hint_742: "Kích thước ảnh tải lên không được vượt quá 500MB!",
  Successfully_added: "Thêm thành công",
  Import_member_failure_list: "Nhập danh sách thành viên thất bại",
  OK: "Xác định.",
  Select_file: "Chọn tập tin",
  Please_enter_the_ID: "Vui lòng nhập ID.",
  User_nickname: "Biệt danh người dùng",
  hint_740: "Vui lòng nhập nickname người dùng",
  User_phone_number: "Số điện thoại người dùng",
  hint_739: "Vui lòng nhập số điện thoại người dùng",
  User_level: "Cấp độ người dùng",
  Please_enter_user_level: "Vui lòng nhập cấp độ người dùng",
  Membership_level: "Đẳng cấp hội viên.",
  Game_users: "Người dùng trò chơi",
  Status: "Trạng thái.",
  Registration_time: "Thời gian đăng ký",
  Identity: "Thân phận.",
  User_type: "Loại người dùng",
  Inviter_userid: "Mời người dùng",
  hint_738: "Vui lòng nhập người mời userid",
  Registration_Platform: "Nền tảng đăng ký",
  hint_741: "Vui lòng nhập địa chỉ web nền tảng đăng ký",
  Batch_import_of_members: "Thành viên nhập khẩu hàng loạt",
  Download_batch_import_templates: "Tải xuống mẫu nhập khẩu hàng loạt",
  Export_data: "Xuất dữ liệu",
  Total_number_of_registered_users: "Tổng số người dùng đã đăng ký",
  Number_of_registered_users_today: "Số lượng người dùng đăng ký hôm nay",

  // 错误开始
  nickname: "Biệt danh.",
  Avatar: "Avatar.",
  Mobile_area_code: "Mã vùng di động.",
  Mobile_phone_number: "Số điện thoại di động.",
  Third_party_binding: "Liên kết của bên thứ ba",
  Recent_login_time: "Thời gian đăng nhập gần đây",
  Recently_logged_in_IP: "Đăng nhập IP gần đây",
  Last_visited_time: "Thời gian thăm viếng gần đây",
  Anchor: "Người dẫn chương trình.",
  Experts: "Chuyên gia.",
  Anchor_level: "Đẳng cấp dẫn chương trình.",
  Ban: "Phong cấm.",
  Enable: "Bật.",
  Detailed: "Chi tiết.",
  Prohibition: "Cấm ngôn.",
  Reset_password: "Đặt lại mật khẩu",
  Personal_information_1: "Tư liệu cá nhân.",
  Mobile_phone_number_and_area_code: "Mã vùng di động.",
  Settings: "Cài đặt.",
  Phone_number: "Số điện thoại di động.",
  Please_enter_your_phone_number: "Vui lòng nhập số điện thoại di động.",
  User_status: "Trạng thái người dùng",
  Normal_1: "Bình thường.",
  hint_737: "Có phải là người dẫn chương trình hay không?",
  hint_736: "Có phải chuyên gia túi gấm không?",
  Recommendation_code: "Mã giới thiệu.",
  Personal_signature: "Chữ ký cá nhân",
  User_UID: "UID người dùng",
  Prohibition_period_: "Chu kỳ cấm ngôn",
  Release_the_ban: "Giải trừ cấm ngôn.",
  Reasons_for_Prohibition: "Nguyên nhân cấm ngôn.",
  Please_select_the_reason_for_the_ban: "Xin mời lựa chọn nguyên nhân cấm ngôn.",
  Delete_chat_messages: "Xóa tin nhắn trò chuyện",
  Delete_all_chat_messages_in_the_live_broadcast_room: "Xóa tất cả các tin nhắn trò chuyện trực tiếp.",
  hint_735: "Có chắc là bạn muốn khóa tài khoản này không?",
  Is_IP_banned_: "Có cấm IP không?",
  Current_level: "Đẳng cấp hiện tại.",
  Operation_time: "Thời gian hoạt động",
  Operator: "Người điều hành",
  No: "Không.",
  Yes: "Vâng.",
  C_end_user_data: "Dữ liệu người dùng phía c",
  Batch_import_of_member_templates: "Nhập khẩu hàng loạt các mẫu thành viên",
  Account_has_been_banned: "Tài khoản bị khóa",
  hint_734: "Bạn có chắc chắn muốn kích hoạt tài khoản này không?",
  Prompt: "Gợi ý.",
  Account_enabled: "Tài khoản đã được kích hoạt",
  hint_733: "Xác nhận đặt lại mật khẩu người dùng? Mật khẩu ban đầu là： Aa123456",
  Password_reset_successful: "Đặt lại mật khẩu thành công",
  Password_reset_failed: "Đặt lại mật khẩu không thành công",
  Download_the_homepage_logo_: "Tải xuống logo màn hình đầu trang：",
  Click_to_select_image: "Click để chọn hình ảnh",
  hint_20: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 344X120，tối đa 1 ảnh",
  hint_21: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 288X100，tối đa 1 ảnh",
  Download_the_top_screen_background_image: "Tải xuống ảnh nền màn hình đầu trang",
  hint_22: "Bạn chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần là 375X732，tối đa 1 ảnh",
  Download_the_top_screen_copy_image: "Tải xuống bản sao màn hình đầu trang",
  hint_23: "Bạn chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 1980x1650，tối đa 1 ảnh",
  Download_page_application_introduction_image: "Tải xuống sơ đồ giới thiệu ứng dụng trang",
  hint_24: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 360X842，tối đa 4 ảnh",
  Download_page_application_introduction: "Giới thiệu ứng dụng Download Page",
  Download_page_features: "Download page features features features (Tải về trang tính năng nổi bật)",
  PWA_configuration: "Cấu hình pwa",
  Only_json_files_can_be_uploaded: "Chỉ có thể tải lên tệp.json",
  Download_floating_bar_description: "Tải xuống mô tả thanh treo",
  New_H5_logo: "Logo H5 mới",
  hint_732: "Kích thước hình ảnh được đề xuất là 40x40",
  New_H5_Download_floating_bar: "New H5 - Tải xuống thanh treo",
  hint_731: "Kích thước hình ảnh được đề xuất là 400x60",
  Youmeng_Statistics: "Thống kê Hữu Minh",
  Please_enter_the_URL_corresponding_to_src: "Vui lòng nhập URL tương ứng với src",
  hint_25: "Để đếm nhiều tên miền，hãy phân biệt bằng ||",
  Baidu_Statistics: "Baidu Statistics(bằng tiếng Anh)",
  Version_download_address: "Địa chỉ download phiên bản",
  Quick_Version: "Phiên bản nhanh",
  Please_enter_the_iOS_shortcut_address: "Vui lòng nhập địa chỉ phím tắt iOS của bạn",
  Please_enter_Android_Scheme: "Vui lòng nhập lịch Android",
  H5_Station_Download_Jump_Status: "Trạm H5 tải xuống trạng thái nhảy",
  APP_H5_Album_Page_Name: "APP/H5 album page name (bằng tiếng Anh)",
  Please_enter_a_name: "Vui lòng nhập tên",
  APP_H5_Album_Page_Link: "APP/H5 album page link (bằng tiếng Anh)",
  Please_upload_pictures: "Xin vui lòng tải lên hình ảnh.",
  Please_upload_the_PWA_configuration_file: "Vui lòng tải tập tin cấu hình pwa lên.",
  Circle_: "Vòng tròn (Circle)",
  Please_enter_the_circle_ID: "Vui lòng nhập ID vòng tròn.",
  Please_fill_in_the_Android_app_download_address: "Vui lòng điền địa chỉ tải xuống ứng dụng Android",
  Please_fill_in_the_iOS_app_download_address: "Vui lòng điền địa chỉ tải xuống ứng dụng iOS",
  Please_fill_in_the_application_introduction: "Vui lòng điền vào phần giới thiệu ứng dụng.",
  Please_fill_in_the_featured_features: "Vui lòng điền vào các tính năng đặc biệt.",
  Please_fill_in_the_description_1: "Vui lòng điền vào mô tả.",
  hint_730: "Vui lòng điền địa chỉ tải xuống ổn định cho iOS",
  Please_fill_in_the_Android_Scheme: "Vui lòng điền vào Android Scheme",
  Please_fill_in_the_iOS_Scheme: "Vui lòng điền vào lịch trình iOS",
  hint_18: "Chỉ tải lên chỉ có thể tải lên tệp jpg，jpeg hoặc png",
  Upload_up_to_1_image: "Tải lên tối đa 1 tấm.",
  hint_19: "Chỉ tải lên chỉ có thể tải lên tệp jpg，jpeg hoặc png!",
  The_upload_size_cannot_exceed_1_5M_: "Kích thước tải lên không thể vượt quá 1.5M!",
  Only_json_format_files_can_be_uploaded_: "Chỉ có thể tải lên tập tin định dạng.json!",
  The_size_cannot_exceed_1MB_: "Không được vượt quá 1MB!",
  IOS_double_plot: "Sơ đồ IOS gấp đôi",
  Protocol_identifier_ID: "ID nhận dạng giao thức",
  Protocol_identifier: "Nhận dạng giao thức",
  Protocol_Name: "Tên giao thức",
  Last_editing_date: "Ngày biên tập cuối cùng",
  Release_status: "Phát hành trạng thái",
  H5_download_page_address: "Địa chỉ trang tải xuống H5",
  Application_Introduction: "Giới thiệu ứng dụng",
  Image_and_Text_Configuration: "Cấu hình đồ họa.",
  Page_: "Tấm thứ nhất.",
  Only_jpg_png_files_can_be_uploaded: "Chỉ có thể tải lên tệp jpg/png",
  The_size_is_394_X798: "Kích thước 394X798",
  Please_enter_the_application_introduction: "Vui lòng nhập giới thiệu ứng dụng",
  Please_enter_the_address: "Vui lòng nhập địa chỉ.",
  The_title_of_the_th_page_cannot_be_empty: "Tiêu đề ${num} không thể để trống.",
  Logo_icon: "logo - biểu tượng",
  Logo_Title: "logo - tiêu đề",
  The_recommended_image_size_is_140_x70: "Kích thước hình ảnh được đề xuất là 140x70",
  Navigation_bar_activity_map: "Biểu đồ hoạt động của Navigation Bar",
  hint_307: "Kích thước： 312 * 126，chỉ có thể tải lên tệp jpg/png",
  PC_download_copy: "Bản sao tải xuống PC",
  hint_26: "Vui lòng nhập dòng đầu tiên của bản sao，không quá 10 ký tự",
  hint_27: "Vui lòng nhập dòng thứ hai của bản sao，không quá 10 ký tự",
  Page_title_: "Tiêu đề trang：",
  Please_enter_a_title: "Vui lòng nhập tiêu đề",
  Page_keywords: "Trang từ khóa",
  Page_Description: "Mô tả trang",
  Please_enter_a_description: "Vui lòng nhập mô tả",
  Please_enter_the_download_address_for_the_anchor_tool: "Vui lòng nhập địa chỉ tải xuống công cụ neo",
  H5_download_page_address_1: "Địa chỉ trang tải h5",
  Please_enter_the_h5_download_page_address: "Vui lòng nhập địa chỉ trang tải về h5",
  Anchor_Recruitment_Description: "Mô tả tuyển dụng của Anchor",
  Please_enter_the_anchor_recruitment_description: "Vui lòng nhập mô tả tuyển dụng neo.",
  Anchor_Recruitment_QR_Code: "Anchor Recruitment QR Code(bằng tiếng Anh).",
  The_recommended_image_size_is_256x256: "Kích thước hình ảnh được đề xuất là 256x256",
  Contact_email_1: "Liên lạc với hòm thư.",
  Please_enter_your_contact_email: "Vui lòng nhập hộp thư liên lạc.",
  Bottom_copyright_information: "Thông tin bản quyền dưới cùng",
  Please_enter_copyright_information: "Vui lòng nhập thông tin bản quyền",
  PC_Link_Configuration: "Cấu hình liên kết phía PC",
  Please_enter_the_PC_domain_name_link_configuration: "Vui lòng nhập cấu hình liên kết tên miền phía PC",
  Site_icon: "Biểu tượng trang web",
  Click_to_select: "Click để chọn",
  hint_28: "Chỉ có thể tải lên tập tin ico，kích thước hình ảnh cần là 32x32，kích thước không vượt quá 20kb.",
  Please_fill_in_the_statistics_link: "Vui lòng điền vào liên kết thống kê.",
  Customer_service_code_: "Mã dịch vụ khách hàng：",
  Please_fill_in_the_customer_service_code: "Vui lòng điền mã dịch vụ khách hàng.",
  PC_album_page_status: "Trạng thái trang album PC",
  PC_album_page_link: "Liên kết trang album PC",
  Please_fill_in_the_title: "Vui lòng điền vào tiêu đề.",
  Please_fill_in_the_description: "Vui lòng điền vào mô tả.",
  Please_fill_in_the_keywords: "Vui lòng điền vào các từ khóa.",
  Please_fill_in_the_record_number_document: "Xin điền vào số hồ sơ.",
  Please_fill_in_your_email_address: "Vui lòng điền vào hòm thư.",
  Please_fill_in_the_h5_download_page_address: "Vui lòng điền địa chỉ trang download h5",
  Please_select_the_Feixin_QR_code_image: "Vui lòng chọn hình ảnh mã QR của Flying Mail",
  Please_upload_the_logo_icon: "Vui lòng tải lên biểu tượng logo",
  Please_upload_the_logo_title: "Vui lòng tải lên tiêu đề logo.",
  Please_enter: "Vui lòng nhập vào.",
  Only_ICO_files_can_be_uploaded_: "Chỉ có thể tải lên tập tin ico!",
  hint_729: "Kích thước ảnh tải lên không được vượt quá 20KB!",
  Please_enter_the_theme_name: "Vui lòng nhập tên chủ đề",
  Add_a_new_theme: "Chủ đề mới",
  There_is_currently_no_content: "Tạm thời không có nội dung.",
  Last_modification_time: "Thời gian sửa đổi cuối cùng.",
  Please_enter_sorting: "Vui lòng nhập thứ tự.",
  Are_you_sure_you_want_to_delete_it_: "Có xác nhận xóa không?",
  Delete_successful: "Xóa thành công",
  Theme: "Chủ đề",
  Publish: "Phát hành.",
  Please_select_a_theme: "Vui lòng chọn chủ đề",
  Please_enter_the_content_of_the_agreement: "Vui lòng nhập nội dung thỏa thuận.",
  Please_enter_the_protocol_name: "Vui lòng nhập tên giao thức",
  hint_728: "Có xác nhận việc phát hành thông tin thỏa thuận hay không",
  hint_727: "Xác nhận việc lưu thông tin thỏa thuận",
  User_Registration_Agreement: "Thỏa thuận đăng ký người dùng",
  User_Privacy_Agreement: "Thỏa thuận bảo mật người dùng",
  Live_streaming_protocol: "Thỏa thuận phát sóng trực tiếp.",
  Payment_Agreement: "Thỏa thuận thanh toán",
  Settlement_rules: "Quy tắc thanh toán.",
  Recharge_Service_Agreement: "Thỏa thuận dịch vụ nạp tiền",
  About: "Về.",
  Please_select_the_protocol_type: "Vui lòng chọn loại giao thức",
  Login_Page_Logo: "Logo trang đích",
  hint_29: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 109X140，tối đa 1 ảnh",
  Main_interface_logo: "Logo giao diện chính",
  hint_30: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 69X67，tối đa 1 ảnh",
  About_Page_Logo: "Giới thiệu về trang Logo",
  hint_31: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 109X109，tối đa 1 ảnh",
  hint_32: "Tải lên hình ảnh kích thước sai，xin vui lòng tải lên theo định dạng yêu cầu!",
  Add_TF_package: "Thêm gói TF",
  Package_name: "Bao danh.",
  Download_link: "Liên kết tải xuống",
  Developer_account: "Tài khoản nhà phát triển.",
  Effective_date_1: "Ngày có hiệu lực",
  Expiration_date: "Ngày hết hạn.",
  Remaining_valid_days: "Số ngày còn lại có giá trị",
  Please_enter_valid_days: "Vui lòng nhập số ngày hợp lệ",
  Effective_days: "Số ngày có giá trị",
  Please_enter_the_package_name: "Vui lòng nhập tên gói",
  Edit_TF_package: "Chỉnh sửa gói TF",
  Please_fill_in_the_download_link: "Vui lòng điền vào link download.",
  Please_select_the_effective_date: "Vui lòng chọn ngày có hiệu lực",
  hint_726: "Số ngày hợp lệ phải lớn hơn hoặc bằng 90.",
  Created_successfully: "Tạo ra thành công",
  Update_method: "Cập nhật cách thức",
  System: "Hệ thống",
  Add_1: "Thêm vào",
  Update_type: "Cập nhật loại",
  Version_number: "Số phiên bản.",
  Prompt_package: "Tip Pack (bằng tiếng Anh).",
  Update_package: "Cập nhật gói",
  Reminder_date: "Nhắc nhở ngày tháng",
  Recently_edited_by: "Biên tập viên gần đây",
  Last_edited_time: "Thời gian biên tập gần đây",
  Version_update: "Cập nhật phiên bản",
  TF_Package_Update: "Cập nhật gói TF",
  Download_address: "Tải xuống địa chỉ",
  Select_prompt_package: "Chọn gói gợi ý",
  Please_fill_in_the_download_address: "Vui lòng điền địa chỉ download.",
  Select_Update_Package: "Chọn gói cập nhật",
  Remaining: "Còn lại.",
  Heaven: "Trời ạ.",
  Start_reminder_date: "Bắt đầu nhắc nhở ngày tháng.",
  Update_content: "Cập nhật nội dung",
  Please_enter_the_updated_content: "Vui lòng nhập nội dung cập nhật",
  hint_33: "Hỗ trợ định dạng： PNG，JPG，Yêu cầu kích thước： 750 * 1624",
  Edit_version_update: "Chỉnh sửa phiên bản cập nhật",
  Add_version_update: "Thêm cập nhật phiên bản",
  Please_set_an_updated_version: "Vui lòng cài đặt phiên bản mới hơn.",
  Please_set_the_prompt_version: "Vui lòng thiết lập phiên bản nhắc nhở",
  Please_select_the_system: "Vui lòng chọn hệ thống",
  Please_choose_a_method: "Vui lòng chọn phương thức.",
  Please_enter_the_content: "Vui lòng nhập nội dung",
  Please_set_the_reminder_date: "Vui lòng đặt ngày nhắc nhở.",
  hint_725: "Tải lên hình ảnh chỉ có thể ở định dạng JPG hoặc PNG!",
  hint_724: "Kích thước ảnh tải lên không được vượt quá 1MB!",
  Modified_successfully: "Sửa đổi thành công.",
  User_Management: "Quản lý người dùng",
  IP_blacklist: "Danh sách đen IP",
  Application_ID: "ID ứng dụng",
  Application_Name: "Tên ứng dụng",
  Last_modified_date: "Ngày sửa đổi cuối cùng",
  Create_an_application: "Tạo ứng dụng",
  View_Key: "Xem chìa khóa.",
  Module: "Mô-đun",
  Supplier: "Nhà cung cấp",
  Add_Module: "Thêm mô-đun",
  Reset_Key: "Đặt lại chìa khóa",
  New_Module: "Mô-đun mới",
  Please_enter_the_application_name: "Vui lòng nhập tên ứng dụng",
  Please_select_a_module: "Vui lòng chọn Module",
  Please_select_a_supplier: "Vui lòng chọn nhà cung cấp",
  Please_enter_supplier_configuration: "Vui lòng nhập cấu hình nhà cung cấp",
  Edit_application: "Ứng dụng biên tập",
  hint_34: "Nếu ứng dụng có khả năng sử dụng mô-đun này，việc xóa bản ghi có thể khiến chức năng không khả dụng. Xác nhận xem bạn có muốn xóa không?",
  Start_page: "Trang khởi động",
  Change: "Thay đổi",
  Only_JPG_or_PNG_format_images_are_allowed_to_be_uploaded: "Chỉ cho phép tải lên hình ảnh ở định dạng JPG hoặc PNG",
  Are_you_sure_you_want_to_save_the_changes_: "Xác nhận các thay đổi đã lưu",
  hint_320: "Thay đổi mật khẩu thành công，vui lòng đăng nhập lại.",
  Review_version: "Phiên bản kiểm duyệt",
  Please_enter_version: "Vui lòng nhập phiên bản.",
  Tag_number: "Đánh dấu số",
  List_Fields: "Trường danh sách",
  Form_Description: "Mô tả hình thức",
  Remarks: "Ghi chú.",
  Pre_explanation: "Ghi chú trước",
  hint_35: "1. chức năng ẩn của kiểm soát phiên bản đối với môi trường xem xét phía iOS; 2. cấp độ mã phá vỡ rõ ràng làm kiểm soát môi trường xem xét bình luận hoặc mã; 3. Android không cần kiểm soát",
  Version_control: "Kiểm soát phiên bản",
  hint_36: "1. Hiển thị số phiên bản hiện tại cần điều khiển，ví dụ： phiên bản hiện tại cần xem xét là V1.1，hộp nhập cần nhập 1.1，và đánh dấu bên dưới mô-đun tương ứng cần ẩn，sau đó nhập mô-đun được đánh dấu bên dưới trong phiên bản V1.1 được ẩn. 2. nhập số phiên bản không có phiên bản thực tế để lưu，không ảnh hưởng đến việc hiển thị bất kỳ mô-đun phiên bản nào",
  Control_module: "Mô-đun điều khiển",
  hint_317: "Hiển thị tên chức năng ẩn của môi trường review cần thiết，trạng thái đánh dấu mặc định，bỏ chọn，và sau khi lưu，mô-đun này không bị ẩn trong phiên bản này;",
  Please_fill_in_version_information: "Vui lòng điền thông tin phiên bản.",
  Select_language: "Chọn ngôn ngữ",
  General_configuration: "Cấu hình phổ quát",
  IOS_configuration: "Cấu hình IOS",
  H5_configuration: "Cấu hình H5",
  New_download_page_configuration: "Cấu hình trang tải xuống mới",
  Streaming_tool_configuration: "Cấu hình công cụ push-flow",
  PC_configuration: "Cấu hình PC",
  Mobile_streaming_configuration: "Cấu hình đẩy di động",
  Advertising_restriction_configuration: "Cấu hình giới hạn quảng cáo",
  Virtual_Number_Restrictions: "Giới hạn số ảo",
  Reporting_restrictions_for_third_party_data_apps: "Giới hạn báo cáo APP dữ liệu ba bên",
  Report_domain_name_configuration: "Báo cáo cấu hình tên miền",
  Please_enter_the_domain_name: "Vui lòng nhập tên miền",
  hint_723: "Tải xuống APP lần đầu tiên để báo cáo.",
  Channel_code_parameter_acquisition: "Thu thập thông số mã kênh",
  PC_data_reporting: "PC Data Report (bằng tiếng Anh).",
  H5_data_reporting: "Báo cáo dữ liệu H5",
  Domain_Name_Address: "Địa chỉ tên miền",
  Please_enter_the_domain_name_address: "Vui lòng nhập địa chỉ tên miền",
  Deactivate: "Ngừng hoạt động.",
  Please_select_the_type: "Vui lòng chọn loại",
  Are_you_sure_to_enable_the_domain_name_: "Xác nhận kích hoạt tên miền?",
  hint_722: "Xác nhận hủy kích hoạt tên miền?",
  Update_successful: "Update thành công",
  Recommended: "Đang tiến cử.",
  Paused: "Tạm dừng.",
  Picture_matching: "Phối đồ.",
  Source: "Nguồn gốc",
  Feedback_type: "Các loại phản hồi",
  Number: "Số thứ tự.",
  Feedback_details: "Thông tin phản hồi chi tiết",
  Contact_information: "Thông tin liên lạc.",
  Feedback_time: "Thời gian phản hồi",
  Navigation_Station_Switch: "Công tắc trạm dẫn đường",
  Page_Configuration: "Cấu hình trang",
  Weight: "Trọng lượng.",
  hint_37: "Con số càng lớn，xếp hạng càng cao.",
  Button_Name: "Tên nút",
  Domain_name: "Tên miền",
  Please_enter_the_weight: "Vui lòng nhập trọng lượng.",
  Please_enter_the_button_name: "Vui lòng nhập tên nút",
  Mobile_related: "Điện thoại di động có liên quan",
  PC_related: "Kết quả tìm kiếm cho PC-side relevant",
  Personal_information: "Thông tin cá nhân",
  Login_account: "Đăng nhập tài khoản.",
  User_Name: "Tên người dùng",
  Security_Settings: "Cài đặt an ninh",
  Change_password: "Thay đổi mật khẩu",
  Original_password: "Mật mã gốc.",
  New_password: "Mật khẩu mới",
  Confirm_new_password: "Xác nhận mật khẩu mới",
  hint_721: "Mật khẩu không thể dài ít hơn 6 chữ số",
  The_passwords_entered_twice_do_not_match: "Mật khẩu nhập hai lần không khớp.",
  Please_enter_your_password_again: "Vui lòng nhập lại mật khẩu.",
  Merchant_Basic_Information: "Thông tin cơ bản về Merchant",
  Merchant_ID: "Merchant ID (bằng tiếng Anh)",
  Enterprise_Name: "Tên doanh nghiệp",
  Contact_person: "Người liên lạc",
  Contact_email: "Hộp thư liên lạc",
  Contact_phone_number: "Điện thoại liên lạc.",
  SMS_verification_code: "Mã xác minh SMS",
  Please_enter_the_merchant_ID: "Vui lòng nhập ID người bán",
  Please_enter_the_company_name: "Vui lòng nhập tên doanh nghiệp",
  Please_enter_the_contact_person: "Vui lòng nhập liên hệ",
  Please_enter_the_contact_person_phone_number: "Vui lòng nhập số điện thoại liên lạc",
  Please_enter_the_contact_email: "Vui lòng nhập hộp thư liên lạc",
  Please_enter_the_verification_code: "Vui lòng nhập CAPTCHA",
  Get_verification_code: "Lấy CAPTCHA.",
  Please_enter_sensitive_words: "Vui lòng nhập từ nhạy cảm.",
  Batch_Add: "Thêm hàng loạt",
  Query_results: "Kết quả truy vấn",
  hint_720: "Xin lỗi，không tìm được từ nhạy cảm.",
  Activity_Name: "Tên chiến dịch",

  // 错误结束====

  hint_718: "Vui lòng nhập từ nhạy cảm và không được dài ít hơn hai ký tự.",
  hint_308: "Có chắc là xóa từ khóa đó không?",
  continue_to_be_sent_on_the_platform: "Sau khi bỏ lệnh cấm，từ này có thể tiếp tục được gửi trên nền tảng.",
  Are_you_sure_to_lift_the_ban_: "Xác nhận bỏ lệnh cấm sao?",
  The_image_size_needs_to_be_40_x40: "Hình ảnh cần có kích thước 40x40",
  Brand_name: "Tên thương hiệu",
  Main_site_name: "Tên trạm chính",
  Please_enter_the_name_of_the_main_site: "Vui lòng nhập tên trạm chính.",
  Main_site_redirect_URL: "Trang web chính nhảy url",
  Please_enter_the_main_site_redirect_URL: "Vui lòng nhập URL nhảy của trạm chính",
  H5_Main_Station_Jump_URL: "H5 Main Station nhảy url",
  Please_enter_the_H5_main_site_redirect_URL: "Vui lòng nhập URL nhảy của trạm chính H5",
  Homepage_banner: "Trang chủ banner",
  hint_717: "Kích thước hình ảnh cần là 1200x150",
  Homepage_banner_URL: "Trang chủ banner url",
  Please_enter_the_banner_URL_on_the_homepage: "Vui lòng nhập bannerUrl trang đầu tiên",
  Live_room_banner: "Kênh truyền hình trực tiếp.",
  hint_716: "Vui lòng nhập bannerUrl phòng phát sóng trực tiếp.",
  Homepage_title: "Trang đầu - title",
  Please_enter_the_title: "Vui lòng nhập title",
  Home_page_description_1: "Trang chủ - description",
  Homepage_keywords_1: "Trang chủ - keywords",
  Please_enter_keywords_1: "Vui lòng nhập keywords",
  Live_room_title: "Live Room – Tiêu đề",
  Live_broadcast_room_keywords_1: "Lời bài hát： Keywords",
  Bottom_copyright_text: "Văn bản bản quyền dưới cùng",
  Please_enter_the_copyright_text_at_the_bottom: "Vui lòng nhập văn bản bản quyền dưới cùng",
  APP_download_URL: "URL tải xuống APP",
  Please_enter_the_APP_download_URL: "Vui lòng nhập URL tải xuống APP",
  hint_715: "Hình ảnh cần có kích thước 750x94",
  H5_Live_Banner: "H5 Live Banner(bằng tiếng Anh)",
  Please_enter_h5RoomBannerRedirectUrl: "Vui lòng nhập h5RoomBannerRedirectUrl",
  Please_enter_the_brand_name: "Vui lòng nhập tên thương hiệu",
  Please_enter_the_main_site_redirect: "Vui lòng nhập trạm chính để nhảy.",
  Please_enter_H5_main_site_redirect: "Vui lòng nhập H5 Main Station Jump",
  Please_enter_the_homepage: "Vui lòng nhập trang đầu tiên.",
  Please_enter_the_homepage_banner_to_jump_to: "Vui lòng nhập banner đầu tiên để nhảy",
  Please_enter_the_homepage_description: "Vui lòng nhập mô tả trang đầu tiên",
  Please_enter_homepage_keywords: "Vui lòng nhập từ khóa trang nhất",
  Please_enter_the_live_streaming_page: "Vui lòng nhập trang phát sóng trực tiếp.",
  hint_714: "Vui lòng nhập mô tả trang trực tiếp",
  Please_enter_keywords_for_the_live_streaming_page: "Vui lòng nhập từ khóa trang phát sóng trực tiếp",
  Please_enter_the_website_copyright_statement: "Vui lòng nhập thông báo bản quyền trang web",
  Please_enter_the_app_download_URL: "Vui lòng nhập URL tải xuống ứng dụng",
  Please_enter_the_site_h5BannerRedirectUrl: "Vui lòng nhập trang web h5BannerRedirectUrl",
  Add_Site: "Thêm một trang web",
  Edit_Site: "Trang web biên tập",
  Upload_up_to_1_photo_: "Tối đa tải lên một tấm!",
  Home_page_description: "Mô tả trang đầu tiên",
  Homepage_keywords: "Trang chủ Keywords",
  Live_broadcast_room_title: "Tiêu đề phòng phát sóng trực tiếp",
  Live_broadcast_room_description: "Mô tả phòng trực tiếp",
  Live_broadcast_room_keywords: "Phòng phát sóng trực tiếp keywords",
  App_download_URL: "URL tải xuống ứng dụng",
  H5_homepage_banner_URL: "h5 trang đầu bannerurl",
  H5_homepage_banner_redirect_URL: "h5 trang đầu banner nhảy url",
  H5_live_broadcast_room_banner_URL: "h5 phòng trực tiếp bannerurl",
  H5_Live_Room_Banner_Jump_URL: "h5 phòng trực tiếp banner nhảy url",
  Live_streaming_services_1: "Dịch vụ phát sóng trực tiếp",
  SMS_service: "Dịch vụ SMS",
  Certificate_file_name: "Tên tập tin chứng nhận",
  Certificate_type: "Loại giấy chứng nhận",
  General_Supplier_Configuration: "Cấu hình nhà cung cấp chung",
  Whether_to_configure_TF_package_name: "Cấu hình tên gói TF hay không",
  Package_Name_Configuration: "Cấu hình tên gói",
  Supplier_Configuration: "Cấu hình nhà cung cấp",
  Edit_Supplier_Configuration: "Chỉnh sửa cấu hình nhà cung cấp",
  Add_supplier_configuration: "Thêm cấu hình nhà cung cấp",
  Please_fill_in_the_configuration_information: "Vui lòng điền thông tin cấu hình",
  hint_713: "Bạn có chắc chắn về việc xóa cấu hình không?",
  Please_configure_TF_configuration_correctly: "Vui lòng cấu hình cấu hình TF chính xác",
  Version_update_reminder: "Cập nhật phiên bản nhắc nhở",
  TF_Package_Management: "Quản lý gói TF",
  Batch_upload: "Tải lên hàng loạt.",
  Posting_updates: "Phát hành Dynamic",
  Account: "Tài khoản.",
  Content: "Nội dung.",
  Upload_images: "Upload hình ảnh",
  Only_jpg_jpeg_png_files_can_be_uploaded: "Chỉ có thể tải lên tệp jpg/jpeg/png",
  Up_to_9_images_can_be_uploaded: "Hỗ trợ upload tối đa 9 tấm.",
  Click_to_select_file: "Click để chọn file",
  Only_supports_video_format_files: "Chỉ hỗ trợ các tập tin định dạng video",
  Related_circles: "Vòng tròn kết nối",
  Please_select_a_circle: "Vui lòng chọn vòng tròn.",
  Related_topics: "Chủ đề liên quan.",
  Please_select_a_topic: "Vui lòng chọn chủ đề.",
  Please_upload_the_video_file: "Xin mời lên tập tin video.",
  Please_select_an_account: "Vui lòng chọn số tài khoản",
  hint_712: "Tải lên hình ảnh chỉ có thể ở định dạng JPG hoặc PNG!",
  hint_707: "Kích thước ảnh tải lên không được vượt quá 10MB!",
  hint_710: "Chỉ có thể tải lên các tập tin định dạng mp4!",
  hint_711: "Không được vượt quá 200MB!",
  Please_enter_your_nickname: "Vui lòng nhập nick name.",
  Joined_the_circle: "Đã gia nhập vòng tròn.",
  Import_time: "Nhập thời gian",
  Disabled: "Cấm dùng.",
  Add_Circle: "Thêm vòng tròn",
  Cannot_be_greater_than_80: "Không thể lớn hơn 80.",
  hint_38: "Xác nhận vô hiệu hóa người dùng? Sau khi vô hiệu hóa sẽ không thể sử dụng tài khoản để đăng động?",
  hint_39: "Xác nhận kích hoạt người dùng? Sau khi kích hoạt có thể sử dụng lại tài khoản để đăng động không?",
  Dynamic_configuration: "Cấu hình động (Dynamic Configuration)",
  Publisher_nickname: "Biệt danh của người xuất bản.",
  Accumulated_likes: "Tổng số lượt like.",
  Accumulated_number_of_comments: "Số lượng bình luận tích lũy",
  Topic: "Đề tài.",
  Circle: "Vòng tròn.",
  Release_time: "Thời gian phát hành",
  Remove_this_comment: "Hạ giá bình luận này.",
  Dynamic_permission_configuration: "Cấu hình quyền động (Dynamic Permission Configuration)",
  Publish_dynamic_user_level_configuration: "Phát hành cấu hình cấp người dùng động",
  Name: "Tên.",
  Level: "Đẳng cấp.",
  hint_703: "Mục này được cấu hình để người dùng thông thường phát hành mức độ cần thiết để đạt được động lực",
  Cannot_be_less_than_1: "Không thể nhỏ hơn 1.",
  Please_fill_in_the_user_level: "Vui lòng điền vào User Level",
  There_are_currently_no_related_posts: "Tạm thời không có bài viết liên quan.",
  hint_702: "Xác nhận muốn hạ giá bình luận bài post này sao?",
  Comment_removed_successfully: "Bình luận về việc hạ cánh thành công.",
  hint_704: "Có chắc là muốn hạ đề tài này không?",
  hint_705: "Có chắc là muốn lên đề tài này không?",
  Return: "Trở về.",
  hint_706: "Bạn không có quyền truy cập vào trang này.",
  hint_309: "Nếu có bất mãn xin liên lạc với lãnh đạo của anh.",
  Or_you_can_go: "Hoặc là anh có thể đi.",
  Go_back_to_the_homepage: "Quay lại trang đầu.",
  hint_40: "Vui lòng kiểm tra xem địa chỉ web bạn đã nhập có chính xác không，vui lòng nhấp vào nút sau để quay lại trang chủ hoặc gửi báo cáo lỗi.",
  Return_to_homepage: "Trở lại trang đầu tiên.",
  hint_41: "Gợi ý： 1 - trọng lượng là số nguyên dương，giá trị càng lớn càng cao; 2 - hiển thị front-end mặc định，ẩn thì front-end không hiển thị",
  Anchor_nickname: "Biệt danh của người dẫn chương trình.",
  Please_enter_sorting_weight: "Vui lòng nhập trọng số sắp xếp",
  Display: "Hiển thị",
  Hidden: "Ẩn núp.",
  Associated_channels_categories: "Kênh liên kết/phân loại",
  Anchor_Name_1: "Tên người dẫn chương trình.",
  Please_enter_the_anchor_name_1: "Vui lòng nhập tên neo.",
  Room_number: "Số phòng.",
  Please_enter_the_room_number: "Vui lòng nhập số phòng.",
  Source_of_flow: "Nguồn gốc của stream",
  Anchor_profile_picture: "Avatar của người dẫn chương trình.",
  Source_room_number: "Số phòng nguồn.",
  Live_streaming_categories: "Phát sóng trực tiếp.",
  Live_streaming_title: "Tiêu đề trực tiếp",
  Stream_address: "Địa chỉ Stream",
  Forbidden_broadcasting: "Cấm phát sóng.",
  Live_streaming_1: "Đang phát sóng trực tiếp.",
  Ended: "Đã kết thúc.",
  Unlocking: "Bỏ lệnh cấm.",
  All: "Toàn bộ.",
  Douyu: "Cá chọi.",
  Tiger_Fang: "Hổ Nha.",
  hint_701: "Có xác nhận bỏ lệnh cấm không?",
  hint_700: "Có xác nhận cấm phát sóng không?",
  Competition_time: "Thời gian thi đấu.",
  Competition_status: "Trạng thái thi đấu.",
  Please_enter_keywords_2: "Vui lòng nhập từ khóa",
  League_Cup_Tournament: "&quot;League/Cup&quot; (bằng tiếng Anh).",
  The_home_team: "Đội chủ nhà.",
  The_visiting_team: "Đội khách.",
  Event_ID: "ID trận đấu",
  Anchor_Qingliu_Live_Room: "Phòng phát sóng trực tiếp Thanh Lưu.",
  Associated_Channel: "Các kênh liên kết",
  Unknown_status: "Trạng thái không rõ",
  Not_started: "Chưa bắt đầu.",
  In_progress: "Đang tiến hành.",
  The_competition_has_been_postponed: "Trận đấu bị hoãn lại",
  Competition_cancelled: "Trận đấu bị hủy bỏ.",
  Direct_room_number: "Đẩy thẳng số phòng.",
  Progress_time: "Thời gian tiến hành.",
  Live_streaming_status: "Trạng thái phát sóng trực tiếp.",
  Next_broadcast: "Phát sóng.",
  Binding_successful: "Liên kết thành công",
  hint_699: "Có chắc là sẽ phát sóng trực tiếp không?",
  Anchor_sorting: "Người dẫn chương trình.",
  hint_42: "Gợi ý： 1 - trọng lượng là số nguyên dương，giá trị càng lớn càng cao; 2 - hiển thị front-end mặc định，ẩn thì front-end không hiển thị;",
  Update: "Cập nhật",
  Channel_sorting: "Sắp xếp kênh",
  Channel_nickname: "Biệt danh kênh.",
  Secondary_classification: "Phân loại cấp hai.",
  Live_streaming_type: "Thể loại live stream",
  hint_698: "Liên quan đến nhiều thứ，xin hãy sử dụng dấu phẩy tiếng Anh để phân chia.",
  Streaming_settings: "Cài đặt Push Flow",
  End_all_live_broadcasts: "Kết thúc toàn bộ truyền hình trực tiếp.",
  Signal_source: "Nguồn tín hiệu",
  Push_flow_room: "Lời bài hát： Push The Flow Room",
  Streaming_Title: "Push stream title (ấn bản 1)",
  Push_Streaming_Channel: "Push stream channel(bằng tiếng Anh)",
  There_are_currently_no_channels_available: "Hiện tại tạm thời không có kênh.",
  Streaming_address: "Địa chỉ Pushstream",
  Streaming_address_server_address_: "Push stream address (địa chỉ máy chủ)",
  Copy: "Sao chép.",
  Push_stream_code_stream_key_: "Push stream code (Chìa khóa phát trực tuyến)",
  End_Live: "Kết thúc phát sóng trực tiếp.",
  hint_321: "Nếu bạn không có kênh nào để sử dụng，vui lòng：",
  Create_channel: "Tạo một kênh",
  You_are_about_to_use_it: "Ngài sắp dùng rồi.",
  Start_broadcasting: "Tiến hành phát sóng.",
  Start_live_streaming: "Bắt đầu phát sóng trực tiếp.",
  Copy_successful: "Sao chép thành công.",
  hint_697: "Vui lòng điền đầy đủ thông tin trước khi gửi.",
  hint_696: "Có chắc là muốn kết thúc phát sóng trực tiếp không?",
  hint_695: "Có chắc là muốn kết thúc toàn bộ chương trình phát sóng trực tiếp không?",
  Choose_a_live_broadcast_room: "Chọn phòng phát sóng trực tiếp.",
  Live_streaming_room: "Phòng truyền hình trực tiếp.",
  League_name: "Tên giải đấu",
  Please_enter_the_league_name: "Vui lòng nhập tên giải đấu.",
  Popular: "Hot nhất.",
  Normal: "Bình thường.",
  The_continent_where_it_is_located: "Châu sở tại.",
  Football: "Bóng đá.",
  Basketball: "Bóng rổ.",
  Date: "Hẹn hò.",
  Start_date: "Ngày bắt đầu.",
  To: "Tới.",
  End_date: "Ngày kết thúc",
  In_the_competition: "Trong trận đấu.",
  Delay: "Trì hoãn.",
  Popular_events: "Những trận đấu nóng bỏng",
  Keywords_1: "Từ khóa.",
  Please_enter_keywords: "Vui lòng nhập từ khóa",
  Batch_editing_of_popular_content: "Batch Editing Hot (bằng tiếng Anh).",
  Batch_creation_of_reserved_events: "Tạo ra các cuộc thi có lịch hẹn với số lượng lớn",
  Country_Region: "quốc gia/khu vực",
  Current_round: "Vòng quay hiện tại",
  Edit_Appointment_Anchor: "Biên tập viên đã hẹn người dẫn chương trình.",
  Please_choose_to_create_a_schedule: "Vui lòng chọn lịch thi đấu",
  Please_select_the_associated_channel: "Vui lòng chọn kênh liên kết",
  Basketball_schedule_data: "Dữ liệu lịch thi đấu bóng rổ.",
  No_selected_items: "Không chọn giữa mục",
  Main_Station_Event_ID: "ID trận đấu tổng đài.",
  Please_enter_the_main_event_ID: "Vui lòng nhập ID trận đấu tổng đài.",
  Third_party_in_game_event_ID: "ID trận đấu của bên thứ ba",
  hint_694: "Vui lòng nhập ID trận đấu của bên thứ ba.",
  There_are_streaming_anchors: "Có một người dẫn chương trình đẩy.",
  There_is_a_push_flow_room: "Có một căn phòng đẩy.",
  Click_on_the_nickname_modification_announcement: "REFERENCES [Tên bảng tham chiếu]",
  Please_select_a_popular_event: "Vui lòng chọn giải đấu nóng",
  Edit_successful: "Biên tập viên thành công",
  E_sports_streaming: "Esports Stream (bằng tiếng Anh)",
  Entertainment_stream: "Entertainment stream (bằng tiếng Anh)",
  Create_Popular: "Tạo ra một hit",
  Official_Clearflow: "Thanh Lưu chính thức.",
  Anchor_Qingliu: "Người dẫn chương trình Thanh Lưu.",
  Score: "Điểm số.",
  The_competition_team: "Đội thi đấu.",
  Create_circles: "Tạo vòng tròn",
  Limit_1M: "Giới hạn 1M",
  hint_900: "Kích thước 790 * 215px",
  Associate_Creator: "Người sáng tạo liên kết",
  Operation_label: "Nhãn hiệu hoạt động",
  Add_tags: "Thêm thẻ mới",
  Confirm_creation: "Xác định việc tạo ra",
  Label_: "Nhãn hiệu {num}",
  Please_select_an_image: "Vui lòng chọn hình ảnh",
  Please_select_the_associated_creator: "Vui lòng chọn người tạo liên kết",
  Publisher_userid: "Nhà xuất bản userid",
  Message_content: "Nội dung tin nhắn.",
  Chat_Room: "Phòng chat.",
  hint_45: "Tin nhắn sẽ không hiển thị trong vòng kết nối đó ở cuối ứng dụng sau khi xóa，xác nhận xóa chứ?",
  Circle_Introduction: "Giới thiệu vắn tắt về vòng tròn.",
  Circle_name: "Tên của vòng tròn",
  Circle_avatar: "Avatar vòng tròn.",
  Creator_1: "Người sáng tạo",
  Number_of_people_in_the_circle: "Số vòng tròn.",
  The_maximum_number_of_people_in_the_circle: "Giới hạn số người trong vòng tròn.",
  Circle_Cover: "Lời bài hát： Circle Cover",
  Circle_dynamics_1: "Circle Dynamics (bằng tiếng Anh).",
  Circle_Announcement: "Thông báo vòng tròn.",
  Creation_time: "Thời gian tạo ra",
  Dissolve_the_circle: "Giải tán vòng tròn.",
  Dissolved: "Giải tán.",
  Submission_dynamics: "Gửi bản thảo.",
  User_userid: "Người dùng userid",
  Number_of_comments: "Số lượng bình luận",
  Like_count: "Số lượt like.",
  Preview: "Xem trước",
  Setting_up_circle_announcements: "Thiết lập thông báo vòng tròn.",
  Add_Announcement: "Thêm thông báo",
  Please_enter_the_link_name: "Vui lòng nhập tên liên kết",
  Display_link: "Hiển thị liên kết",
  Jump_link: "Nhảy liên kết",
  Please_enter_the_jump_link: "Vui lòng nhập liên kết nhảy",
  To_be_reviewed: "Chờ xét duyệt.",
  Listed: "Đã lên kệ.",
  Offline_1: "Đã hạ giá.",
  Rejected: "Đã cự tuyệt.",
  Deleted: "Đã xóa",
  hint_692: "Vui lòng điền đầy đủ thông tin liên kết thông báo trước khi gửi.",
  The_circle_has_disbanded: "Vòng tròn đã giải tán.",
  Are_you_sure_you_want_to_dissolve_this_circle_: "Có xác nhận muốn giải tán vòng tròn này không?",
  Dissolution: "Giải tán.",
  hint_44: "Bạn có xác nhận rằng bạn muốn {status} động thái của thanh không?",
  hint_693: "Có xác nhận muốn từ chối động thái này không?",
  Successfully: "Đã thành công.",
  Remove_from_shelves: "Hạ giá.",
  Listing: "Lên giá.",
  Circle_dynamics: "Số động vòng tròn",
  Creator_nickname: "Biệt danh của người sáng tạo.",
  Creator_userid: "Người tạo userid",
  In_operation: "Đang hoạt động.",
  View_details: "Xem chi tiết.",
  Chat_messages: "Tin nhắn chat.",
  Edit_anchor_information: "Chỉnh sửa thông tin neo",
  Review: "Thẩm định.",
  Anchor_Name: "Tên người dẫn chương trình.",
  Guild: "Công hội sở tại.",
  Please_select_a_guild: "Xin mời chọn công hội.",
  Split_ratio: "Phân chia tỷ lệ.",
  Please_select_the_sharing_ratio: "Vui lòng chọn chia tỷ lệ.",
  Basic_salary: "Lương cơ bản.",
  Please_enter_the_base_salary: "Vui lòng nhập mức lương cơ bản.",
  Review_details: "Xem xét chi tiết.",
  Review_details_1: "Chi tiết",
  Please_enter_the_ID_number: "Vui lòng nhập số ID.",
  Please_enter_the_host_nickname: "Xin hãy nhập nick name của người dẫn chương trình.",
  hint_43: "Bạn có chắc chắn xóa người dùng khỏi danh sách ủy quyền của nhóm không?",
  Add_channel: "Tăng kênh",
  Default_sorting: "Sắp xếp mặc định",
  Channel_ID: "ID kênh",
  Icon: "Biểu tượng.",
  Page_template: "Mẫu trang",
  Tab_name: "Tên tab",
  Channel_Type: "Loại kênh",
  hint_46: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần là 120X120",
  Please_enter_a_valid_tab_name_: "Vui lòng nhập tên tab hợp lệ!",
  Enter_tab_name: "Nhập tên tab",
  Content_ID: "Content ID (ID nội dung)",
  Please_enter_ID: "Vui lòng nhập ID.",
  Content_type: "Loại nội dung",
  Topped: "Đã đặt đỉnh.",
  Top: "Đặt đỉnh.",
  Cancel_Topping: "Hủy bỏ việc lên đỉnh.",
  Restore_Display: "Hiển thị phục hồi",
  Expiration_time: "Thời gian mất hiệu lực.",
  Select_date_and_time: "Chọn ngày giờ",
  Sealing_the_picture: "Phong Đồ.",
  real_time_info: "Thông tin.",
  Short_video: "Đoạn video ngắn",
  Dynamic: "Năng động.",
  Brocade_Bag: "Túi gấm.",
  The_expiration_time_has_not_been_set_yet: "Chưa đặt thời gian hết hiệu lực.",
  Please_select_a_future_time: "Vui lòng chọn thời gian tương lai.",
  Homepage_Content_Management: "Quản lý nội dung trang nhất",
  Top_management_on_homepage: "Quản lý trang đầu.",
  Please_enter_information_keywords: "Vui lòng nhập từ khóa thông tin.",
  Keywords: "Từ khóa.",
  Batch_publishing: "Phát hành hàng loạt",
  Batch_scheduled_release: "Batch Timed Release(bằng tiếng Anh).",
  Thumbnails: "Hình thu nhỏ",
  Title_1: "Tiêu đề.",
  Crawling_time: "Trèo lên thời gian.",
  hint_47: "Bạn có thể chọn đăng bài theo lịch trình bất cứ lúc nào trong vòng 5 phút trong tuần tới，chỉnh sửa lại và hủy bỏ trước khi đặt thời gian.",
  Timed_release: "Timed Release (bằng tiếng Anh).",
  hint_48: "Không thể sửa đổi và hủy bỏ sau khi đăng，bạn có xác nhận đăng không?",
  hint_691: "Không có thông tin nào được chọn，vui lòng chọn thông tin trước khi đăng!",
  Please_choose_a_reasonable_publishing_time: "Vui lòng chọn thời gian hợp lý để đăng bài.",
  Edit_information: "Thông tin biên tập",
  New_information: "Thông tin mới",
  Please_fill_in_the_necessary_title_and_author: "Vui lòng điền vào các tiêu đề và tác giả cần thiết.",
  Information_Title: "Tiêu đề thông tin.",
  Comment_content: "Nội dung bình luận",
  hint_49: "Sau khi xóa bình luận này sẽ không hiển thị trong ứng dụng，PC，có chắc là xóa không?",
  hint_50: "Có chắc là hủy đăng không? Sau khi hủy，thông tin sẽ được đưa vào hộp nháp.",
  hint_51: "Xác định hạ giá chứ? Di chuyển phía sau hạ giá sẽ không xuất hiện tin nhắn này!",
  Cancel_Top_Time: "Hủy bỏ thời gian đặt hàng.",
  Publishing_Library: "Thư viện phát hành",
  Top_Library: "Đặt đỉnh kho.",
  Crawling_website_titles: "Thu thập tiêu đề trang web",
  Crawling_website_links: "Thu thập liên kết đến các trang web",
  Crawling_time_period: "Thời gian leo núi.",
  Set_crawling_time_period: "Thiết lập thời gian thu thập thông tin",
  End_time: "Thời gian kết thúc.",
  Select_a_time_range: "Chọn khung thời gian",
  hint_52: "Sau khi vô hiệu hóa thì không thu thập dữ liệu của trang web này，có xác nhận vô hiệu hóa không?",
  hint_690: "Bạn có chắc muốn kích hoạt cấu hình này không?",
  hint_689: "Bạn có chắc chắn muốn xóa thông tin này không?",
  Prize_Name: "Tên giải thưởng",
  Please_enter_the_name_of_the_prize: "Vui lòng nhập tên giải thưởng.",
  Prize_type: "Các loại giải thưởng",
  Query: "Truy vấn.",
  Add_prizes_1: "Giải thưởng mới",
  Prize_icon: "Giải thưởng icon",
  Please_enter_the_number_of_prizes: "Vui lòng nhập số lượng giải thưởng.",
  quantity: "Số lượng.",
  Only_jpg_png_gif_files_can_be_uploaded: "Chỉ có thể tải lên tệp jpg/png/gif",
  Modify: "Sửa đổi.",
  New: "Mới thêm",
  Virtual_currency: "Tiền ảo",
  Experience_value: "Điểm kinh nghiệm.",
  hint_53: "Tải lên hình ảnh chỉ có thể ở định dạng JPG hoặc PNG hoặc GIF!",
  Winning_number: "Số trúng thưởng.",
  Please_enter_the_winning_number: "Vui lòng nhập số trúng thưởng.",
  UID: "UID",
  Please_enter_UID: "Vui lòng nhập UID.",
  Obtain_time: "Lấy thời gian",
  Export: "Xuất khẩu",
  hint_688: "Phòng phát sóng trực tiếp đập trứng vàng - kỷ lục giải thưởng",
  Basic_Settings: "Cài đặt cơ bản",
  Red_envelope_rain_name: "Tên của mưa hồng bao.",
  Edit_Red_Envelope_Rain_Library: "Biên tập viên ngân hàng mưa phong bì đỏ",
  New_Red_Envelope_Rain_Warehouse: "Kho mưa tiền lì xì mới xây",
  Please_enter_the_name_of_the_red_envelope_rain: "Vui lòng nhập tên mưa hồng bao",
  Trigger_type: "Loại kích hoạt",
  Goal_scoring_events: "Sự kiện ghi bàn",
  Event_timeline: "Thời gian thi đấu.",
  Specify_a_time_point: "Xác định thời điểm",
  Maximum_number_of_red_envelope_rain_wheels: "Giới hạn số vòng mưa hồng bao.",
  Please_enter_the_number_of_rounds: "Vui lòng nhập số vòng.",
  hint_54: "0 là không có mưa tiền lì xì，đây là giới hạn số vòng mưa tiền lì xì nhiều nhất trong một cuộc thi.",
  hint_687: "Số lượng bao lì xì trung bình tối đa (một vòng người dùng duy nhất)",
  hint_55: "0 vì không cướp được tiền lì xì，sau khi đếm đến tiền lì xì sẽ không cướp được tiền lì xì nữa.",
  Fixed_point_in_time: "Một thời điểm cố định.",
  Select_date: "Chọn ngày",
  Probability_of_winning_a_prize_red_envelope: "Xác suất rút trúng bao lì xì có thưởng.",
  Please_enter_the_probability: "Vui lòng nhập xác suất.",
  hint_56: "0 là không thể rút trúng bao lì xì，đây là xác suất người dùng rút trúng bao lì xì có thưởng.",
  hint_686: "Vui lòng nhập giới hạn số vòng mưa trong bao lì xì.",
  Award_Pool_Settings: "Thiết lập hồ bơi giải thưởng",
  Add_prizes: "Thêm giải thưởng",
  Number_of_individual_red_envelope_rewards: "Thưởng một bao lì xì.",
  Please_enter_the_number: "Vui lòng nhập số.",
  Number_of_red_envelopes: "Số lượng phong bì màu đỏ",
  Please_enter_the_quantity: "Vui lòng nhập số lượng.",
  Prize_weight: "Trọng lượng giải thưởng.",
  Please_fill_in_the_fixed_time_point: "Vui lòng điền vào thời gian cố định.",
  Please_select_a_time_point_for_the_competition: "Vui lòng chọn thời điểm thi đấu.",
  hint_685: "Chỉ có thể cấu hình các số nguyên dương từ 0 đến 100",
  Please_add_at_least_one_prize: "Vui lòng thêm ít nhất một phần thưởng.",
  hint_684: "Vui lòng điền đầy đủ thông tin giải thưởng trước khi gửi.",
  hint_57: "Nhập sai，một bao lì xì thưởng số lượng，số lượng bao lì xì chỉ có thể cấu hình 1 - 99999 số nguyên dương",
  Add_has_reached_the_limit: "Việc bổ sung đã đạt đến giới hạn trên",
  Golden_Egg_Switch: "Công tắc trứng vàng.",
  Basic_Information_Configuration: "Cấu hình thông tin cơ bản",
  APP_background_image: "Hình nền ứng dụng",
  APP_Title_Image: "Biểu đồ tiêu đề APP",
  The_total_probability_must_be_10000: "Tổng xác suất phải là 10.000.",
  Red_envelope_rain_storage_details: "Chi tiết kho mưa phong bì đỏ",
  hint_58: "0 là không có mưa tiền lì xì，đây là giới hạn số vòng mưa tiền lì xì nhiều nhất trong một cuộc thi.",
  Wheel: "Bánh xe.",
  Pieces: "Cái.",
  Task_type: "Loại nhiệm vụ",
  Reward_Name: "Tên phần thưởng",
  Task_Title: "Tiêu đề nhiệm vụ.",
  Task_icon: "Biểu tượng nhiệm vụ",
  Task_count: "Số nhiệm vụ.",
  Application_channels: "Kênh ứng dụng",
  Edit_task: "Nhiệm vụ biên tập",
  Reward_type: "Các loại phần thưởng",
  Reward_quantity: "Số lượng phần thưởng",
  Please_enter_the_number_of_rewards: "Vui lòng nhập số tiền thưởng.",
  Please_select_the_reward_type_icon: "Vui lòng chọn loại hình thưởng icon",
  Please_select_the_application_channel: "Vui lòng chọn kênh ứng dụng",
  Task_title_cannot_be_empty: "Tiêu đề nhiệm vụ không được để trống.",
  Task_count_cannot_be_empty: "Số lượng nhiệm vụ không thể để trống.",
  To_be_effective: "Sắp có hiệu lực",
  In_effect: "Có hiệu lực",
  Daily_tasks: "Nhiệm vụ hàng ngày",
  Novice_Task: "Nhiệm vụ tân thủ.",
  Sign_in_task: "Ký nhận nhiệm vụ.",
  Attendance_Record: "Ký vào hồ sơ.",
  Please_enter_the_task_title: "Vui lòng nhập tiêu đề nhiệm vụ.",
  Check_in_switch: "Check-in-the-switch (bằng tiếng Anh).",
  No_1: "Thứ nhất.",
  Number_of_check_in_days: "Ký tên vào số ngày.",
  Reward_details: "Phần thưởng chi tiết.",
  Reward_icon: "Biểu tượng khen thưởng",
  Edit_check_in: "Biên tập viên đã ký.",
  Lottery: "Tiền màu.",
  Physical_object: "Vật chất.",
  Please_upload_the_reward_type_icon: "Vui lòng tải lên biểu tượng phần thưởng",
  Sign_in_has_been_closed: "Đã đóng cửa.",
  Check_in_has_been_opened: "Đã mở，đã ký.",
  Please_fill_in_the_quantity: "Vui lòng điền vào số lượng.",
  Please_enter_the_room_number_for_sending: "Vui lòng nhập số phòng đã gửi.",
  Send_room_number: "Gửi số phòng đi.",
  Sending_time: "Gửi thời gian",
  Details_of_receiving_red_envelopes: "Phong bì đỏ nhận chi tiết.",
  hint_683: "Tạm thời không có tiền lì xì để nhận chi tiết.",
  Anchor_room_number: "Số phòng dẫn chương trình.",
  Please_enter_the_host_room_number: "Vui lòng nhập số phòng phát thanh viên.",
  Remove_permissions: "Xóa quyền truy cập",
  Add_anchor: "Thêm một neo.",
  Time: "Thời gian.",
  Anchor_userID: "Người dẫn chương trình userID",
  Please_enter_the_anchor_userID: "Vui lòng nhập mã người dùng neo",
  Betting_amount: "Số tiền đặt cược",
  Prophecy_betting_list: "Danh sách cá cược tiên tri",
  Please_enter_the_name_of_the_main_team: "Vui lòng nhập tên đội chủ nhà.",
  Please_enter_the_name_of_the_visiting_team: "Vui lòng nhập tên đội khách.",
  Please_enter_the_status: "Vui lòng nhập trạng thái",
  Team_PK_ID: "Đội PK ID.",
  The_winner: "Bên thắng.",
  Number_of_gift_givers_1: "Số người tặng quà.",
  Total_value_in_gold_coins: "Tổng giá trị (tiền vàng)",
  The_losing_side: "Bên bại trận.",
  Binding_events: "Giải đấu bị ràng buộc",
  Please_enter_the_binding_event: "Vui lòng nhập giải đấu ràng buộc.",
  Bind_anchor: "Người dẫn chương trình bị trói.",
  Please_enter_the_bound_anchor: "Vui lòng nhập neo liên kết.",
  Activity_Strategy_Name: "Tên chiến dịch chính sách",
  hint_682: "Vui lòng nhập tên chính sách hoạt động",
  Red_envelope_rain_claim_details: "Hồng bao vũ lĩnh chi tiết.",
  Effective_anchor_room_details: "Chi tiết về phòng phát thanh viên có hiệu lực.",
  To_be_published: "Được xuất bản",
  Published: "Đã xuất bản",
  Canceled: " Đã hủy bỏ.",
  hint_681: "Xác nhận hủy đăng bài hay không",
  Main_switch: " Tổng công tắc.",
  Policy_Name: "Tên chính sách",
  Please_enter_a_policy_name: "Vui lòng nhập tên chính sách",
  Add_Policy: "Thêm chính sách",
  Red_envelope_rain_storage: "Hồng bao vũ khố.",
  Red_envelope_rain_type: "Phong bì màu đỏ loại mưa",
  Event_red_envelope_rain: "Cuộc thi Mưa hồng bao",
  Live_broadcast_room_chat_room: "Phòng phát sóng trực tiếp.",
  Event_type: "Thể loại sự kiện",
  Distribute_users: "Người dùng gửi xuống.",
  hint_60: "Vui lòng nhập UID người dùng，nhiều UID được ngăn cách bằng dấu phẩy tiếng Anh",
  Please_select_the_type_of_red_envelope_rain: "Vui lòng chọn loại mưa phong bì màu đỏ",
  Please_select_an_event: "Vui lòng chọn giải đấu",
  Please_select_the_red_envelope_rain_storage: "Xin mời chọn kho mưa hồng bao.",
  Edit_Policy: "Chiến lược biên tập",
  Strategy_details: "Chi tiết chiến lược",
  hint_61: "Offline chiến lược chiến dịch này sẽ dẫn đến chiến dịch offline từ đầu phía trước，hãy chắc chắn rằng chiến dịch offline.",
  Offline_2: "logout.",
  Live_room_room_number: "Số phòng truyền hình trực tiếp.",
  hint_680: "Xin vui lòng nhập số phòng trực tiếp.",
  Configuration_Management: "Quản lý cấu hình",
  Prize_configuration: "Cấu hình giải thưởng",
  Prize_Record: "Kỷ lục giải thưởng",
  Anchor_cumulative_share: "Người dẫn chương trình chia đều.",
  Option_A_cumulative_betting: "Lựa chọn A đặt cược tích lũy",
  Option_A: "Lựa chọn A",
  Option_B: "Lựa chọn B",
  Option_B_cumulative_betting: "Cược tích lũy tùy chọn B",
  Results: "Kết quả.",
  Number_of_bets: "Số người đặt cược",
  Anchor_sharing_3: "Phát thanh viên (3%)",
  Platform_share_2: "Phân chia nền tảng (2%)",
  The_anchor_prophecy: "Lời tiên tri của người dẫn chương trình.",
  The_prophecy_of_the_whole_nation: "Lời tiên tri toàn dân",
  Login_time: "Thời gian đăng nhập",
  Online_duration: "Thời gian online dài",
  Inviters_nickname: "Biệt danh của người mời：",
  Invitee_UID_: "UID được mời：",
  Invitation_time: "Thời gian mời",
  Inviter_payment_method: "Cách mời người nhận tiền",
  Alipay: "Alipay.",
  WeChat_account: "Vi tín hiệu.",
  QQ_account: "Tài khoản QQ.",
  Inviting_person_rewards: "Mời người khen thưởng.",
  Distributed: "Đã gửi đi.",
  Not_passed: "Không thông qua.",
  Accumulated: "Đang tích lũy.",
  Invitee_nickname: "Biệt danh của người được mời.",
  Invitee_rewards: "Thưởng cho người được mời",
  Select_distribution_type: "Chọn loại phân phối",
  Please_enter_a_rule_description: "Vui lòng nhập mô tả quy tắc.",
  Are_you_sure_to_cancel_the_distribution_: "Xác nhận hủy bỏ giao hàng sao?",
  APP_user_login_statistics: "Thống kê đăng nhập người dùng APP",
  hint_676: "Phần thưởng của người mời không được nhỏ hơn 0.",
  hint_677: "Phần thưởng của người mời không được lớn hơn 99999",
  hint_675: "Phần thưởng của người được mời không được nhỏ hơn 0.",
  hint_678: "Phần thưởng của người được mời không được lớn hơn 99999.",
  Room_type: "Loại phòng",
  The_ban_was_successful: "Cấm ngôn thành công.",
  hint_62: "Sau khi xóa，tin nhắn này sẽ không hiển thị trong phòng phát trực tiếp/phòng chat này ở phía ứng dụng，phía PC，phía H5，có chắc là nó bị ẩn không?",
  Please_enter_the_user_UID: "Vui lòng nhập UID người dùng",
  Prohibition_time: "Thời gian cấm ngôn.",
  Forbidden_live_broadcast_room: "Phòng phát sóng trực tiếp cấm ngôn.",
  Prohibition_type: "Thể loại cấm ngôn",
  Prohibition_reasons: "Lý do cấm ngôn.",
  Operator_UID: "Người vận hành UID",
  Successfully_unblocked: "Bỏ lệnh cấm thành công.",
  Opening_duration: "Thời gian phát sóng dài",
  The_duration_of_the_current_live_broadcast: "Thời gian phát sóng trực tiếp hiện tại",
  Current_number_of_views: "Số lượt xem hiện tại",
  hint_674: "Lời bài hát： Current Live Views (De Heavy)",
  Current_number_of_online_users: "Số người online hiện tại",
  hint_673: "Số người xem trực tuyến hiện đang phát sóng trực tiếp (giảm cân)",
  The_highest_number_of_online_users: "Số người online cao nhất",
  hint_672: "Số người trực tuyến cao nhất hiện đang phát sóng trực tiếp (giảm cân)",
  hint_63: "Số liệu thống kê bao gồm người dùng chưa đăng nhập，người dùng chưa đăng nhập không giảm trọng lượng.",
  Field_Description: "Field Description(bằng tiếng Anh)",
  Anchor_UID: "Người dẫn chương trình UID.",
  Live_Channel: "Kênh trực tiếp.",
  Start_time_1: "Thời gian phát sóng.",
  Downtime: "Thời gian phát sóng.",
  Opening_duration_minutes_: "Thời gian phát sóng (phút)",
  Accumulated_views: "Tích lũy lượt xem",
  Accumulated_number_of_online_users: "Số lượng người online tích lũy",
  Average_viewing_time_minutes_: "Thời gian xem trung bình (phút)",
  The_entire_live_broadcast_data: "Toàn bộ dữ liệu trực tiếp.",
  Edit_data: "Biên tập tài liệu",
  Add_announcement_link: "Thêm liên kết thông báo",
  Announcement_link: "Liên kết thông báo",
  Please_enter_the_display_link: "Vui lòng nhập liên kết hiển thị",
  Recommended_display: "Đề nghị trưng bày",
  Browser_Open: "Mở trình duyệt",
  Display_end: "Hiển thị kết thúc",
  Mobile_end: "Kết thúc di động",
  PC_end: "Kết thúc PC",
  Application_time: "Thời gian nộp đơn.",
  Global_announcement_adjustment: "Điều chỉnh thông báo toàn cầu",
  Sports_type: "Thể thao thể thao",
  On_the_front_of_the_ID_card: "Mặt trước của thẻ căn cước.",
  On_the_back_of_the_ID_card: "Trái ngược với thẻ căn cước.",
  Holding_an_ID_card: "Cầm chứng minh thư trong tay.",
  Reason_for_rejection: "Lý do từ chối",
  Lifting_the_ban: "Giải trừ phong cấm",
  Refused_to_pass: "Từ chối thông qua.",
  Please_enter_the_anchor_name: "Vui lòng nhập tên người dẫn chương trình.",
  ID_number: "Số chứng minh thư.",
  Front_photo_of_ID_card: "Ảnh chính diện chứng minh thư.",
  Back_photo_of_ID_card: "Ảnh trái của thẻ căn cước.",
  Housing_Management_Uid: "Quản lý bất động sản.",
  Please_enter_the_housing_management_Uid: "Vui lòng nhập UID của quản lý bất động sản.",
  Housing_management_nickname: "Biệt danh quản lý bất động sản.",
  hint_671: "Xin hãy nhập nick name của quản lý nhà đất.",
  Add_property_management: "Thêm bất động sản.",
  Cancel_housing_management: "Hủy bỏ quản lý nhà đất.",
  Please_enter_your_name: "Vui lòng nhập tên",
  hint_670: "Tên，xin hãy nhập chữ Hán.",
  Approved: "Thông qua xét duyệt.",
  Rejected_for_review: "Xét duyệt cự tuyệt.",
  Prohibited: "Đã phong cấm.",
  The_ID_photo_is_blurry: "Giấy tờ mơ hồ.",
  The_ID_number_does_not_match_the_ID_photo: "Số chứng minh thư không khớp với ảnh chứng minh thư.",
  My_ID_card_does_not_match_me: "Chứng minh thư không khớp với chính mình.",
  ID_card_number_is_blocked: "Số chứng minh thư bị chặn.",
  Facial_occlusion: "Che mặt người.",
  Room: "Phòng.",
  Please_select_the_reason: "Vui lòng chọn lý do.",
  hint_669: "Vui lòng nhập tên hoặc liên kết đến thông báo mà bạn muốn thay thế",
  Are_you_sure_you_want_to_pass_the_review_: "Xác định muốn xét duyệt thông qua sao?",
  hint_301: "Xác định khôi phục quyền phát sóng trực tiếp của người dẫn chương trình chứ?",
  Are_you_sure_you_want_to_ban_this_anchor_: "Xác định phong cấm người dẫn chương trình này sao?",
  Main_account_UID: "Tài khoản chính UID",
  Please_enter_the_main_account_UID: "Vui lòng nhập UID tài khoản chính.",
  Merge_account_UID: "Hợp nhất tài khoản UID",
  Please_enter_the_merged_account_UID: "Vui lòng nhập UID tài khoản hợp nhất.",
  Add_Merge: "Hợp nhất mới",
  Master_account_nickname: "Biệt danh tài khoản chính.",
  Merge_account_nicknames: "Mật danh tài khoản hợp nhất.",
  Merge_time: "Thời gian hợp nhất.",
  hint_64: "Sau khi hợp nhất tài khoản，được hợp nhất phát sóng，sẽ sử dụng tài khoản chính để phát sóng.",
  Merge_accounts: "Hợp nhất tài khoản.",
  Refresh: "Làm mới lại.",
  hint_668: "Lưu ý： Dữ liệu trực tuyến bị chậm trễ một chút",
  Note: "Đặt cược.",
  Basic_information_1: "Thông tin cơ bản",
  Anchor_Information: "Thông tin neo",
  Real_time_live_streaming_data: "Live streaming data (bằng tiếng Anh).",
  hint_667: "Dòng thông tin hiện tại không có sẵn!",
  Anchor_Management: "Quản lý neo.",
  Merge_anchor_accounts: "Hợp nhất tài khoản chủ trì.",
  Identify_the_main_switch: "Phân biệt tổng công tắc.",
  The_current_status_is: "Trạng thái hiện tại là",
  There_is_currently_no_data_available: "Tạm thời không có số liệu.",
  Jian_Huang: "Giám Hoàng.",
  Fear_prevention: "Giám định khủng bố.",
  Jianzheng: "Giám chính.",
  hint_303: "Có chắc là muốn cắt đứt đoạn phát sóng trực tiếp này không?",
  Thermal_value_coefficient: "Hệ số giá trị nhiệt.",
  Heat_value_base: "Cơ sở giá trị nhiệt",
  Modified_by: " Sửa đổi người.",
  Latest_modification_time: "Thời gian sửa đổi mới nhất.",
  Heat_value_setting: "Thiết lập giá trị nhiệt",
  Role_Name: "Tên nhân vật",
  Whether_to_enable: "Bật hay không bật",
  Live_broadcast_room_type: "Loại phòng trực tiếp",
  Initial_heat_value: "Giá trị nhiệt ban đầu",
  Last_editing_time: "Thời gian biên tập cuối cùng",
  Edit_heat_value: "Chỉnh sửa giá trị nhiệt",
  Initial_heating_value_: "Giá trị nhiệt ban đầu：",
  Please_enter_the_heat_value: "Vui lòng nhập giá trị nhiệt.",
  Please_fill_in_the_heat_value: "Vui lòng điền vào giá trị nhiệt.",
  Please_enter_the_anchor_UID: "Vui lòng nhập giao diện người dẫn chương trình",
  Live_streaming_services: "Live streaming service (bằng tiếng Anh).",
  Batch_editing: "Chỉnh sửa hàng loạt",
  All_anchors: "Toàn bộ người dẫn chương trình.",
  Platform_anchor_stream: "Platform Anchor Stream (bằng tiếng Anh).",
  Event_clearance: "Giải đấu Thanh Lưu.",
  Anchor_event_flow: "Dẫn chương trình phát sóng sự kiện",
  Esports_Entertainment_Stream: "Esports Entertainment Stream (bằng tiếng Anh).",
  Live_streaming_2: "Đang phát sóng trực tiếp.",
  Field: "Trường.",
  hint_665: "Có chắc là muốn phát sóng trực tiếp không?",
  hint_664: "Có chắc là muốn cấm phát sóng trực tiếp không?",
  This_PV: "PV này.",
  UV_in_this_field: "UV ở đây.",
  Peak_value: "Đỉnh núi.",
  Accumulated_viewing_time_minutes_: "Thời gian xem tích lũy (phút)",
  Total_number_of_followers: "Tổng số chú ý.",
  Number_of_followers_in_this_session: "Số lượng người quan tâm.",
  The_number_of_levels_taken_in_this_game: "Trận này lấy số cửa ải.",
  Net_increase_in_levels_in_this_game: "Trận này tịnh tăng quan số",
  Live_broadcast_session_data_of_anchors: "Dữ liệu phát sóng trực tiếp.",
  Forbidden_List: "Danh sách cấm ngôn.",
  Team: "Đội bóng.",
  Please_enter_the_team: "Vui lòng nhập đội.",
  Push_order_status: "Trạng thái Push",
  hint_306: "Xác nhận rằng bạn muốn ẩn đẩy đó? Ẩn mặt trước phía sau là vô hình",
  hint_305: "Xác nhận rằng bạn muốn hiển thị đẩy đó? Hiển thị mặt trước phía sau có thể nhìn thấy",
  Please_select_the_correction_result: "Vui lòng chọn kết quả sửa chữa",
  Reporting_time: "Thời gian tố cáo.",
  Whistleblower_userid: "Người tố giác người dùng",
  Enter_the_whistleblower_userid: "Nhập người tố cáo người dùng",
  Enter_room_number: "Nhập số phòng",
  Reply_or_not: "Có trả lời hay không.",
  Reason_for_reporting: " Nguyên nhân tố cáo.",
  Reply_content: "Trả lời nội dung",
  hint_663: "Vui lòng nhập nội dung trả lời (trong vòng 100 từ)",
  The_reply_content_cannot_be_empty: "Nội dung trả lời không thể để trống",
  Homepage_Configuration: "Cấu hình trang đầu",
  Level_animation_switch: "Công tắc hoạt hình cấp bậc",
  Cat_coin_animation_switch: "Công tắc hoạt hình Cat Coin",
  Enter_animation_switch_: "Công tắc hoạt hình tiếp cận：",
  APP_membership_background: "Bối cảnh thành viên APP",
  Membership_Benefits_Background: "Bối cảnh quyền lợi thành viên.",
  APP_H5_Membership_Benefits_Background: "Bối cảnh quyền lợi của thành viên APP/H5",
  Background_of_PC_Membership_Benefits: "Nền tảng về quyền lợi của thành viên PC",
  hint_409: "Lưu ý： Kích thước là 926x126",
  VIP_discount_configuration: "Cấu hình giảm giá VIP",
  Please_enter_VIP: "Vui lòng nhập VIP.",
  hint_402: "Vui lòng nhập giảm giá vip (ví dụ： 0.9)",
  hint_403: "Vui lòng nhập tên giảm giá vip (ví dụ： giảm giá 9%)",
  Edit_Channel: "Kênh biên tập",
  Add_a_new_channel: "Thêm kênh mới",
  Channel_avatar: "Avatar kênh.",
  hint_401: "Kích thước： 114 * 114 chỉ có thể tải lên tệp jpg/png",
  Channel_Cover: "Bìa kênh",
  hint_405: "Kích thước： 522 * 294 chỉ có thể tải lên tệp jpg/png",
  hint_406: "Gợi ý： Tự động chặn màn hình luồng và sử dụng bìa mặc định sau khi thất bại",
  hint_407: " Xác định khôi phục quyền phát sóng trực tiếp kênh này chứ?",
  hint_408: "Anh có chắc là mình sẽ chặn kênh này không?",
  Schedule_data: "Dữ liệu lịch thi đấu.",
  Edit_Announcement: "Thông báo biên tập",
  Create_Announcement: "Tạo một thông báo",
  Announcement_type: "Các loại thông báo",
  Image_and_text_links: "Liên kết text",
  Picture_and_text_messages: "Tin tức hình ảnh.",
  Activity_link: "Liên kết hoạt động",
  Announcement_Title: "Tiêu đề thông báo.",
  Cover_image: "Ảnh bìa.",
  Whether_to_push: "Có đẩy hay không.",
  Select_content: "Chọn nội dung",
  Activity_Title: "Tiêu đề chiến dịch",
  Event_Introduction: "Giới thiệu sự kiện",
  Please_select_the_announcement_type: "Vui lòng chọn loại thông báo",
  Please_select_the_publishing_time: "Vui lòng chọn thời gian phát hành.",
  Please_enter_the_announcement_title: "Vui lòng nhập tiêu đề thông báo",
  Please_enter_the_activity_title: "Vui lòng nhập tiêu đề chiến dịch",
  Please_enter_the_announcement_content: "Vui lòng nhập nội dung thông báo.",
  Please_fill_in_the_activity_introduction: " Vui lòng điền vào hồ sơ hoạt động.",
  Please_enter_the_text_message_content: "Vui lòng nhập nội dung tin nhắn SMS",
  Send_to: "Gửi đối tượng",
  Upload_data: "Tải lên dữ liệu",
  Download_List_Excel_Template: "Tải xuống danh sách mẫu Excel",
  Receive_user_list: "Nhận danh sách người dùng",
  All_platform_users: "Người dùng toàn nền tảng",
  Not_sent: "Không gửi đi",
  Sent: "Đã gửi",
  Cancel_sending: "Hủy gửi",
  Specify_user: "Chỉ định người dùng",
  Please_enter_the_theme: "Vui lòng nhập chủ đề",
  Multiple_separated_by_commas: "Nhiều ngăn cách bằng dấu phẩy",
  Template_ID: "Mẫu ID",
  Instant_sending: "Gửi ngay lập tức",
  Please_select_template_ID: "Vui lòng chọn mẫu ID",
  Are_you_sure_to_send_to_all_users_: "Đã xác nhận gửi cho tất cả người dùng chưa?",
  Sending_successful: "Gửi thành công",
  Channel_template_ID: "Mã mẫu bên kênh",
  Please_enter_the_channel_template_ID: "Vui lòng nhập ID mẫu bên kênh",
  Template_content: "Nội dung mẫu",
  Please_enter_template_content: "Vui lòng nhập nội dung mẫu",
  Please_select_a_channel: "Vui lòng chọn kênh",
  Please_enter_your_signature: "Vui lòng nhập chữ ký.",
  Alibaba_Cloud: "A Lý Vân.",
  Notification: "Thông báo.",
  Marketing: "Tiếp thị.",
  Verification_code: "CAPTCHA (bằng tiếng Anh).",
  First_select_the_SMS_type: "Chọn loại tin nhắn SMS đầu tiên",
  Please_fill_in_the_channel_template_ID: "Vui lòng điền vào mẫu ID của bên kênh",
  Message_type: "Loại tin nhắn",
  New_announcement: "Thông báo mới",
  Official_announcement_title: "Tiêu đề thông báo chính thức",
  Unpublish: "Hủy xuất bản",
  Jump_type: "Loại nhảy",
  Push_time: "Đẩy thời gian.",
  Please_enter_the_bag_ID: "Vui lòng nhập ID túi gấm.",
  Decimal_cannot_be_entered: "Không thể nhập số thập phân.",
  Please_enter_the_H5_link: "Vui lòng nhập liên kết H5",
  Push_details: "Gửi thông tin chi tiết.",
  Edit_push: "Biên tập viên Push",
  Add_push: "Push mới",
  Push_order_page: "Push the Single Page (bằng tiếng Anh).",
  Please_select_the_push_time: "Vui lòng chọn thời gian đẩy",
  Please_select_the_effective_time: "Vui lòng chọn thời gian có hiệu lực.",
  hint_660: "Xác định hủy bỏ nhiệm vụ đẩy đi lần này sao?",
  Add_menu: "Thực đơn mới",
  catalogue: "Thư mục.",
  Menu: "Thực đơn",
  Menu_icons: "Biểu tượng menu",
  Menu_Title: "Tiêu đề menu",
  Routing_address: "Địa chỉ định tuyến",
  Component_Name: "Tên thành phần",
  Component_path: "Đường dẫn thành phần",
  Whether_to_hide: "Có giấu hay không.",
  Superior_categories: "Danh mục cấp trên",
  Select_superior_category: "Chọn mục cấp cao hơn",
  Menu_Name: "Tên thực đơn",
  Permission_identification: "Xác định quyền hạn",
  Edit_menu: "Chỉnh sửa menu",
  Please_enter_the_menu_title: "Vui lòng nhập tiêu đề menu",
  Please_enter_the_routing_address: "Vui lòng nhập địa chỉ định tuyến",
  Please_enter_the_component_name: "Vui lòng nhập tên thành phần",
  Please_enter_the_component_path: "Vui lòng nhập đường dẫn thành phần",
  Please_select_the_superior_category: "Vui lòng chọn mục cấp trên",
  Top_level_categories: "Top-level category (bằng tiếng Anh).",
  hint_661: "Bạn có chắc chắn muốn xóa menu này không?",
  Please_enter_the_role_name: "Vui lòng nhập tên nhân vật",
  Add_new_role: "Thêm nhân vật mới",
  Role_ID: "ID nhân vật",
  Creator: "Người sáng tạo",
  Authorization: "ủy quyền",
  Edit_Role: "Vai trò biên tập viên",
  Audit_scenario: "Thẩm định cảnh tượng.",
  Please_enter_the_review_scenario: "Vui lòng nhập cảnh đánh giá",
  Review_page: "Trang đánh giá",
  Edit_audit_scenario_account: "Biên tập viên xem xét tài khoản cảnh quay.",
  Operations_Manager_Account: "Số tài khoản của người phụ trách hoạt động.",
  Please_enter_the_review_account: "Vui lòng nhập tài khoản xét duyệt.",
  Please_enter_your_account: "Vui lòng nhập số tài khoản.",
  New_users: "Thêm người dùng",
  Bind_characters: "Những nhân vật bị trói buộc",
  Latest_reset_time: "Thời gian reset mới nhất",
  Please_select_a_role: "Vui lòng chọn nhân vật.",
  Input_cannot_be_empty: "Input không thể để trống",
  hint_6601: "Không thể nhập ký tự và số đặc biệt",
  Edit_Users: "Người dùng biên tập",
  hint_659: "Bạn có chắc chắn muốn vô hiệu hóa tài khoản này không?",
  Account_deactivated: "Tài khoản đã bị vô hiệu hóa",
  hint_658: "Có chắc là bạn muốn đặt lại mật khẩu tài khoản này không?",
  Reset_successful: "Reset thành công",
  The_new_password_is: "Mật khẩu mới là",
  Gift_Name: "Tên của món quà",
  Please_enter_the_gift_name: "Vui lòng nhập tên món quà.",
  Gift_category: "Danh mục quà tặng",
  VIP_gift: "Quà VIP.",
  Add_gift: "Thêm một món quà.",
  Gift_unit_price_in_gold_coins_: "Đơn giá quà tặng (tiền vàng)",
  Banner_gift: "banner quà tặng",
  Full_screen_gift: "Quà tặng toàn màn hình",
  Gift_icon: "Biểu tượng quà tặng",
  Size_does_not_exceed_1MB_: "Kích thước không quá 1MB!",
  Enable_floating_screen: "Bật màn hình theo chiều gió",
  Floating_screen_category: "Thể loại màn hình bay.",
  hint_657: "Vui lòng chọn thể loại màn hình theo chiều gió",
  Please_select_VIP_level: "Vui lòng chọn cấp VIP",
  hint_656: "Có sẵn cho người dùng VIP ở cấp độ này trở lên",
  hint_601: "Bạn có thể nhập tối đa 4 ký tự Trung Quốc hoặc 8 chữ cái tiếng Anh hoặc 8 số.",
  Please_enter_the_price: "Vui lòng nhập giá.",
  hint_602: "Đơn giá quà tặng không được lớn hơn 999999 hoặc nhỏ hơn 0.",
  Please_upload_the_gift_icon: "Vui lòng tải lên biểu tượng quà tặng.",
  Please_upload_the_playback_effect: "Xin vui lòng tải lên hiệu ứng phát sóng.",
  Please_upload_the_preview_effect: "Vui lòng tải lên hiệu ứng xem trước",
  Edit_Gift: "Quà tặng biên tập",
  Please_choose_a_gift_type: "Vui lòng chọn loại quà tặng",
  Please_select_the_floating_screen_type: "Vui lòng chọn loại màn hình theo chiều gió",
  Please_select_whether_combo_is_possible: "Vui lòng chọn nếu bạn có thể kết hợp.",
  Only_SVGA_files_can_be_uploaded_: "Chỉ có thể tải lên tập tin svga!",
  Only_GIF_files_can_be_uploaded: "Chỉ có thể upload file gif",
  hint_66: "Tuyến dưới món quà này thì món quà này tuyến dưới kho quà tặng，tức là trong chiến lược cấu hình quà tặng không xuất hiện món quà này，có chắc chắn tuyến dưới không?",
  Successfully_taken_offline: "logout thành công.",
  hint_67: "Món quà này online thì món quà này online lại đến kho quà，tức là trong chiến lược cấu hình quà tặng lại xuất hiện món quà này，xác định login không?",
  Gift_data_statistics: "Gift Data Statistics (bằng tiếng Anh).",
  User_gift_details: "Chi tiết quà tặng của người dùng",
  Gift_List: "Danh sách quà tặng",
  Distribution_scope: "Phạm vi hạ phát.",
  Effective_time: "Thời gian có hiệu lực.",
  Last_modified_time: "Thời gian sửa đổi gần đây.",
  Last_Editor: "Người biên tập cuối cùng",
  hint_68: "Vui lòng nhập ID quà tặng，cách nhau giữa nhiều món quà bằng dấu phẩy tiếng Anh，thứ tự nhập là thứ tự hiển thị quà tặng phía trước",
  Sending_gifts: "Gửi quà đi.",
  hint_72: "Xóa chính sách quà tặng này sẽ dẫn đến xóa chính sách này，bạn có chắc chắn xóa không?",
  hint_70: "Offline chiến lược quà tặng này sẽ làm cho quà tặng của phòng chiến lược đó offline từ đầu phía trước，chắc chắn rằng offline?",
  hint_71: "Sách lược tặng quà online thì sách lược tặng quà online từ đầu，xác định login không?",
  Expert_type: "Loại chuyên gia",
  Import_experts: "Chuyên gia nhập khẩu",
  Introduction: "Giới thiệu vắn tắt.",
  Hit_rate: "Tỷ lệ trúng mục tiêu.",
  Recent_status: "Trạng thái gần đây.",
  The_highest_consecutive_red: "Cao nhất là Liên Hồng.",
  hint_604: "Gần đây Liên Hồng.",
  Number_of_proposals: "Số lượng chương trình",
  Number_of_buyers: "Số lượng người mua",
  Accumulated_total_revenue: "Tổng lợi nhuận tích lũy",
  Settlable_income: "Thu nhập có thể thanh toán.",
  Add_Expert: "Thêm chuyên gia",
  User_userid_cannot_be_empty: "User userid không thể để trống",
  Please_enter_the_league_and_team_name: "Vui lòng nhập tên đội",
  Home_team_vs_away_team: "Đội chủ nhà vs đội khách.",
  League: "Giải đấu.",
  Choose_a_competition: "Chọn giải đấu",
  Event_information: "Thông tin giải đấu",
  Select_recommendation: "Chọn một đề xuất",
  Yazhi: "Á chỉ.",
  The_main_winner: "Chủ thắng.",
  Customer_win: "Khách thắng.",
  Small_and_large_balls: "Quả bóng lớn nhỏ.",
  Greater_than: "Lớn hơn.",
  ball: "Quả bóng.",
  European_Compensation: "Âu Bồi.",
  A_draw: "Hòa.",
  Wanbo: "Vạn Bác.",
  Le_Dong: "Nhạc Động.",
  Macau: "Ma Cao.",
  crown: "Vương miện",
  hint_655: "Xin hãy chọn cách chơi trước.",
  Add_recommendation_group: "Thêm nhóm giới thiệu",
  Recommended_group_name: "Tên nhóm giới thiệu",
  Circle_details: "Vòng tròn chi tiết.",
  Number_of_recommended_circles: "Số lượng vòng tròn giới thiệu",
  Resource_bit_name: "Tên bit tài nguyên",
  Expert_details: "Chuyên gia chi tiết.",
  Number_of_recommended_experts: "Số lượng chuyên gia được đề xuất",
  Pause: "Tạm dừng.",
  Add_anchors: "Phát triển Anchor",
  X_connected_red: "X Liên Hồng.",
  Near_X_in_X: "Gần X trong X",
  Return_rate: "Tỷ lệ hoàn vốn.",
  hint_653: "Vui lòng điền vào ít nhất một thông tin neo đầy đủ.",
  hint_654: "Chắc chắn đề cử nhóm này chứ?",
  hint_652: "Xác định tạm dừng nhóm đề cử này sao?",
  Up_to_20_can_be_configured: "Có thể cấu hình tối đa 20 cái.",
  Edit_selling_price: "Biên tập giá bán",
  First_gear: "Số một",
  Second_gear: "Số hai.",
  Third_gear: "Số 3.",
  Release_Brocade_Bag: "Phát hành túi gấm.",
  Brocade_Bag_Type_: "Loại túi gấm：",
  Football_recommendations: "Giới thiệu bóng đá",
  Basketball_recommendations: "Lời khuyên bóng rổ.",
  hint_651: "Vui lòng chọn tùy chọn đề xuất cài đặt giải đấu",
  Please_enter_the_publisher_account: "Vui lòng nhập số tài khoản nhà xuất bản.",
  Please_enter_the_recommendation_reason: "Vui lòng nhập lý do đề cử.",
  Please_set_the_publishing_time: "Vui lòng đặt thời gian phát hành.",
  hint_650: "Trò chơi đã kết thúc cần chọn thời gian phát hành.",
  Expert_management: "Chuyên gia quản lý",
  Popular_experts: "Chuyên gia nóng bỏng",
  Hit: "Trúng mục tiêu.",
  Returned: "Đã trả lại.",
  To_be_returned: "Đợi trả lại.",
  On_sale: "Đang bán.",
  Cut_off_orders: "Cắt đơn.",
  Order_status: "Trạng thái lệnh",
  Purchase_time: "Thời gian mua hàng",
  Please_enter_the_competition: "Vui lòng nhập cuộc thi.",
  Competition: "Cuộc thi.",
  Publisher: "Nhà xuất bản",
  Please_enter_the_publisher: "Vui lòng nhập người xuất bản",
  Purchase_successful: "Mua thành công",
  hint_73: "Xác định hạ giá không? Đoạn video ngắn này sẽ không xuất hiện ở đầu di chuyển phía sau hạ giá?",
  Bullet_Screen_Library: "Kho chứa súng",
  Add_bullet_library: "Thêm thư viện bắn súng",
  Batch_Import: "Nhập khẩu hàng loạt",
  Add_bullet_comments: "Thêm màn hình bom.",
  Bullet_screen_content: "Nội dung màn bom.",
  Recent_Editors: "Biên tập viên gần đây nhất",
  Import_data: "Nhập dữ liệu",
  Edit_bullet_screen: "Chỉnh sửa màn bắn",
  hint_649: "Hỗ trợ thêm nhiều màn hình，nhiều màn hình bằng tiếng Anh,Tách dấu phẩy，thêm tối đa 1000 thanh một lần.",
  Label_One: "Nhãn hiệu một.",
  Label_2: "Nhãn hiệu 2.",
  Label_Three: "Nhãn hiệu số 3.",
  Label_Four: "Nhãn hiệu 4.",
  Label_Five: "Nhãn hiệu 5.",
  Please_enter_the_bullet_content: "Vui lòng nhập nội dung màn bom.",
  hint_648: "Kích thước tải lên không thể vượt quá 10MB!",
  Successfully_added_barrage: "Thêm màn bắn thành công",
  Update_barrage_successful: "Cập nhật màn bom thành công.",
  hint_647: "Tập tin tải lên chỉ có thể ở định dạng xls!",
  Import_failed: "Nhập thất bại",
  Import_successful: "Import thành công",
  hint_646: "Bạn có chắc chắn muốn xóa màn hình này không?",
  Number_of_fans: "Số lượng fan hâm mộ.",
  Live_broadcast_time: "Thời gian phát sóng trực tiếp.",
  Duration_minutes_: "Thời gian (phút)",
  Number_of_gift_givers: "Số người tặng quà.",
  Number_of_gifts_given: "Số lượng quà tặng.",
  Live_room_revenue_feather_: "Thu nhập phòng trực tiếp (lông vũ)",
  Guilds: "Công hội.",
  Settlable_income_yuan_: "Thu nhập có thể thanh toán được.",
  Reward_and_punishment_income_yuan: "Tiền thưởng và phạt.",
  Total_revenue_yuan_: "Tổng lợi nhuận (Yuan)",
  Adjustment_type_: "Loại điều chỉnh：",
  hint_645: "Lý do thưởng và phạt：",
  Settlement_bill_review: "Kiểm toán hóa đơn thanh toán",
  Originating_Reviewer: "Người khởi xướng cuộc kiểm toán",
  Operations_Manager_: "Giám đốc điều hành",
  Reissue: "Khởi động lại.",
  Through: "Thông qua.",
  Please_enter_the_amount: "Vui lòng nhập số tiền",
  Please_enter_the_reason: "Vui lòng nhập lý do.",
  Guild_name: "Tên của guild",
  Please_enter_the_guild_name: "Vui lòng nhập tên guild.",
  Guild_ID: "Guild ID (bằng tiếng Anh).",
  Receiving_account_name: "Tên tài khoản nhận tiền",
  Opening_Bank: "Mở tài khoản.",
  Bank_card_number: "Số thẻ ngân hàng.",
  Account_opening_address: "Địa chỉ mở tài khoản",
  Number_of_anchors: "Số người dẫn chương trình.",
  Please_fill_in_your_phone_number: "Vui lòng điền số điện thoại di động.",
  Please_fill_in_your_bank_card_number: "Vui lòng điền số thẻ ngân hàng.",
  hint_75: "Guild này sẽ có thể được gán cho Anchor khi được kích hoạt，bạn có chắc chắn rằng guild này sẽ được kích hoạt không?",
  Resource_location: "Vị trí tài nguyên",
  Is_it_effective_: "Có hiệu lực hay không.",
  Jump_position: "Vị trí nhảy",
  Please_enter_the_jump_location: "Vui lòng nhập vị trí nhảy",
  Please_select_a_cover: "Vui lòng chọn bìa.",
  Please_select_a_jump_type: "Vui lòng chọn kiểu nhảy",
  Please_select_a_resource_location: "Vui lòng chọn vị trí tài nguyên",
  hint_76: "Lưu ý： Quyền lực càng lớn thì vị trí quảng cáo hiện tại càng cao.",
  Is_the_browser_open_: " Cho dù trình duyệt đang mở",
  hint_644: "Xác nhận bạn muốn kích hoạt quảng cáo?",
  hint_643: "Xác nhận bạn muốn vô hiệu hóa quảng cáo?",
  hint_642: "Xác nhận rằng bạn muốn xóa quảng cáo?",
  Add_an_open_screen_advertisement: "Thêm quảng cáo mở màn hình mới",
  hint_641: "Vui lòng đảm bảo định dạng video là MP4 và không vượt quá 5M",
  hint_77: "Chỉ có thể tải lên tệp jpg/png/gif，kích thước ảnh cần 750X1624",
  Please_enter_the_link_address: "Vui lòng nhập địa chỉ liên kết",
  Please_enter_the_live_broadcast_room_ID: "Vui lòng nhập ID phòng phát sóng trực tiếp.",
  Please_enter_the_information_ID: "Vui lòng nhập ID thông tin.",
  Please_enter_the_chat_room_ID: "Vui lòng nhập ID phòng chat",
  The_input_information_format_is_incorrect: "Nhập thông tin sai định dạng",
  Chat_room_ID: "ID phòng chat",
  Brocade_Bag_ID: "ID túi gấm.",
  hint_640: "Chỉ có thể tải lên tệp jpg/png/gif，kích thước đề xuất là",
  Please_enter_the_sorting_number: "Vui lòng nhập số sắp xếp",
  Sort: "Sắp xếp.",
  The_length_cannot_exceed_20: "Chiều dài không thể vượt qua 20.",
  hint_78: "Sau khi hạ cánh，cửa sổ này có chắc chắn hạ xuống không?",
  hint_639: "Kích thước ảnh tải lên không được vượt quá 1MB",
  Page_Name: "Tên trang",
  Please_enter_the_page_name: "Vui lòng nhập tên trang",
  Parameters: "Tham số.",
  PC_Link: "Liên kết PC",
  H5_Link: "Liên kết H5",
  PC_Preview: "Xem trước PC",
  H5_Preview: "Xem trước H5",
  PC_Resource_Map: "Bản đồ tài nguyên PC",
  H5_Resource_Map: "Bản đồ tài nguyên H5",
  Please_enter_parameters: "Vui lòng nhập các thông số",
  Background_name: "Tên nền",
  Background_image: "Hình nền",
  Set_background_name: "Đặt tên nền",
  Set_sorting: "Thiết lập sắp xếp",
  Modify_background_image: "Thay đổi hình nền",
  Add_background_image: "Thêm hình nền mới",
  Please_fill_in_the_background_name: "Vui lòng điền tên nền.",
  Please_fill_in_the_sorting: "Xin hãy điền vào thứ tự sắp xếp",
  Successfully_taken_down: "Hạ giá thành công.",
  Successfully_listed: "Lên kệ thành công.",
  Effective: "Hiệu quả.",
  Invalid: "Không có hiệu quả.",
  hint_606: "Số lượng kênh phát sóng (10 phút thống kê một lần)",
  Go_live: "Đang online.",
  hint_605: "Vui lòng đặt số thứ tự (1-999)",
  Please_enter_the_tab_name: "Vui lòng nhập tên tab",
  hint_79: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 670 * 376",
  Please_set_the_status: "Vui lòng thiết lập trạng thái",
  Are_you_sure_you_want_to_go_online_: "Có chắc là muốn online không?",
  Are_you_sure_you_want_to_go_offline_: "Có chắc là muốn logout không?",
  Anchor_userid: "Người dẫn chương trình.",
  Chat_room_title: "Tiêu đề phòng chat",
  Cut_off_flow: "Đoạn lưu.",
  hint_638: "Có chắc là phòng chat này bị đứt không?",
  Upload_time: "Thời gian tải lên",
  Cover_type: "Loại bìa",
  Batch_pass: "Thông qua hàng loạt.",
  Batch_rejection: "Từ chối hàng loạt.",
  Voice_chat_room: "Phòng chat bằng giọng nói.",
  Reject: "Từ chối.",
  Operated: "Hoạt động",
  Passed: "Đã thông qua.",
  Live_streaming_3: "Phát sóng trực tiếp.",
  hint_637: "Bạn có chắc chắn muốn xóa bìa không?",
  Music_name: "Tên nhạc",
  Please_enter_the_music_name: "Vui lòng nhập tên nhạc.",
  Author_name: "Tên tác giả",
  Please_enter_the_author_name: "Vui lòng nhập tên tác giả",
  Size: "Kích thước.",
  Playback_count: "Số lần chơi",
  Trial_listening: "Thử nghe xem.",
  Please_enter_the_song_name: "Vui lòng nhập tên bài hát.",
  Song_title: "Tên bài hát",
  Upload_music_files: "Upload file nhạc lên",
  Only_supports_MP3_format: "Chỉ hỗ trợ định dạng MP3",
  hint_80: "Tỷ lệ khung hình 60 * 60",
  Please_upload_music_files: "Xin mời vào tập tin nhạc.",
  Edit_Music: "Biên tập âm nhạc",
  Add_music: "Âm nhạc mới",
  Only_MP3_format_files_can_be_uploaded: "Chỉ có thể tải lên các tập tin định dạng mp3",
  The_size_cannot_exceed_20MB_: "Không được vượt quá 20MB!",
  hint_635: "Có chắc là anh muốn lên đĩa nhạc không?",
  hint_636: "Có chắc là anh muốn bỏ nhạc không?",
  Add_Recommendation: "Khuyến nghị mới",
  Please_set_the_sorting_number_1_999_: "Vui lòng đặt số thứ tự (1-999)",
  Editor_recommendation: "Giới thiệu biên tập viên",
  UserId_cannot_be_empty: "UserId không thể để trống",
  Shutdown_time: "Tắt thời gian phát sóng.",
  Peak_heat_value: "Đỉnh điểm của giá trị nhiệt",
  Edit_Page: "Trang biên tập",
  Add_Page: "Thêm một trang",
  Upload_at_least_one_resource_image: "Tải lên ít nhất một hình ảnh tài nguyên.",
  hint_81: "Liên kết sẽ được đăng trực tuyến sau khi đăng，bạn có chắc chắn rằng trang sẽ được đăng không?",
  hint_82: "Liên kết này sẽ không thể truy cập trực tuyến sau khi hạ giá，bạn có chắc chắn hạ giá trang này không?",
  Select_Cover: "Chọn bìa",
  hint_633: "Chú ý： Thời gian có hiệu lực không thể chồng chéo lên nhau!!!",
  hint_83: "Có thông tin không đầy đủ，xin điền đầy đủ rồi đệ trình!",
  hint_634: "Kích thước ảnh tải lên không được vượt quá 500K!",
  Recommended_image: "Bản đồ đề cử.",
  Display_sorting: "Hiển thị sắp xếp",
  Edit_Secondary_Classification: "Phân loại biên tập cấp độ 2",
  Classification: "Phân loại.",
  Add_Category: "Thêm phân loại",
  Please_enter_the_activity_name: "Vui lòng nhập tên chiến dịch",
  Activity_status: "Trạng thái hoạt động",
  Image: "Hình ảnh.",
  Configuration_date: "Cấu hình ngày",
  Please_enter_the_button_link: "Vui lòng nhập liên kết nút",
  Button_link: "Liên kết nút",
  Button_Text: "nút copywriting",
  hint_607: "Vui lòng nhập bản sao nút，tối đa 4 từ",
  Button_color: "Nút màu",
  hint_608: "Vui lòng nhập màu nút，chẳng hạn như #CCCCCC",
  Edit_Configuration: "Chỉnh sửa cấu hình",
  New_configuration: "Thêm cấu hình",
  hint_609: "Vui lòng nhập nút sao chép",
  Please_enter_the_button_color: "Vui lòng nhập màu nút",
  hint_84: "Bạn có chắc chắn muốn tải lên mẫu này không，mẫu trang sẽ có hiệu lực sau khi tải lên",
  hint_85: "Có chắc là bạn muốn gỡ bỏ mẫu này không，nút Page sẽ không hoạt động sau khi gỡ bỏ mẫu này.",
  APP_end: "Kết thúc APP.",
  H5_end: "Kết thúc H5.",
  AK_APP_end: "Kết thúc AK-APP",
  AK_PC_end: "Kết thúc AK-PC",
  AK_H5_end: "Kết thúc AK-H5",
  Anchor_details: "Người dẫn chương trình chi tiết.",
  Recommended_number_of_anchors: "Số lượng Anchors được đề xuất",
  Anchor_: "Người dẫn chương trình",
  Please_enter_the_anchor_ID: "Vui lòng nhập ID neo.",
  Please_fill_in_the_resource_slot_name: "Vui lòng điền vào tên tài nguyên bit.",
  Please_fill_in_at_least_one_anchor_information: "Vui lòng điền vào ít nhất một thông tin neo.",
  Secondary_classification_valid_all: "Phân loại thứ cấp (hợp lệ/tất cả)",
  SM_static_resources: "Tài nguyên tĩnh SM",
  AK_static_resources: "Tài nguyên tĩnh AK",
  More_Jumps: "Nhảy nhiều hơn",
  Editorial_Expert_Recommendation_Group: "Nhóm giới thiệu chuyên gia biên tập",
  Add_expert_recommendation_group: "Thêm nhóm giới thiệu chuyên gia",
  Near_0_in_0: "Gần 0 trong 0.",
  Highest: "Cao nhất.",
  Lian_Hong: "Liên Hồng.",
  Recently: "Gần đây.",
  Permissions: "Quyền hạn.",
  Are_you_sure_you_want_to_turn_it_on_: "Có chắc là mở không?",
  Are_you_sure_you_want_to_close_it_: "Có chắc là đóng cửa không?",
  Live_broadcast_room_name: "Tên phòng phát sóng trực tiếp.",
  Please_select_sorting: "Vui lòng chọn Sort",
  Edit_popular_recommendations: "Biên tập viên Hot Recommendations",
  Add_popular_recommendations: "Các khuyến nghị nóng mới được thêm vào",
  Advertising_name: "Tên quảng cáo",
  Resource_type: "Loại tài nguyên",
  Video_upload: "Tải lên video",
  Your_browser_does_not_support_video_playback: "Trình duyệt của bạn không hỗ trợ phát lại video",
  Re_upload: "Tải lên lại.",
  Please_upload_the_video: "Vui lòng tải video lên.",
  Offline: "Đã tắt",
  Square_banner: "Quảng trường banner",
  Page: "Trang này.",
  Information_details: "Thông tin chi tiết",
  Edit_open_screen_advertisement: "Biên tập quảng cáo mở màn hình",
  Please_select_a_resource_type: "Vui lòng chọn loại tài nguyên",
  Please_select_an_open_screen_advertisement: "Vui lòng chọn quảng cáo mở màn hình",
  The_uploaded_video_size_cannot_exceed_5MB_: "Kích thước video tải lên không được vượt quá 5 MB.",
  Please_upload_the_correct_video_format: "Vui lòng tải lên đúng định dạng video",
  Add_patches: "Thêm miếng dán mới",
  Display_location: "Hiển thị vị trí",
  Please_enter_the_title_of_the_patch_advertisement: "Vui lòng nhập tiêu đề quảng cáo bản vá",
  hint_86: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần là 100X100",
  Edit_patch_ads: "Biên tập quảng cáo vá",
  Add_patch_ads: "Thêm quảng cáo vá mới",
  Please_enter_the_advertisement_title: "Vui lòng nhập tiêu đề quảng cáo",
  Please_select_a_display_location: "Vui lòng chọn vị trí hiển thị",
  Recommended_by_experts_on_the_homepage: "Trang chủ chuyên gia giới thiệu",
  Live_streaming_page_anchor_recommendation: "Phát sóng trực tiếp dẫn chương trình giới thiệu",
  hint_87: "Tải lên hình ảnh chỉ có thể ở định dạng JPG，PNG hoặc gif!",
  Pop_up_name: "Tên cửa sổ đạn",
  APP_Advertising: "quảng cáo app",
  Open_screen_advertising: "Mở màn hình quảng cáo.",
  Patch_advertising: "Quảng cáo Patch",
  Activity_pop_up: "Cửa sổ bắn hoạt động.",
  hint_611: "Lưu ý： Tất cả các chức năng của hoạt động trên kệ đã được mở.",
  Assistant: "Tiểu trợ lý.",
  Homepage_Live: "Phát sóng trực tiếp trên trang nhất",
  Live_broadcast_room_announcement: "Thông báo trực tiếp.",
  Advertising_resource_allocation: "Cấu hình bit tài nguyên quảng cáo",
  hint_610: "Mở ra thì yêu cầu tài nguyên quảng cáo cũ.",
  hint_88: "Lưu ý： Một số chức năng không được mở trong quá trình đánh giá chính thức",
  APP_Brand_Name_: "Tên thương hiệu APP：",
  Please_enter_the_APP_brand_name: "Vui lòng nhập tên thương hiệu APP",
  Please_enter_contact_customer_service: "Xin vui lòng nhập liên hệ với dịch vụ khách hàng",
  hint_89: "Tải lên hình ảnh kích thước sai，xin vui lòng tải lên theo định dạng yêu cầu",
  Switch_initialization: "Khởi tạo công tắc",
  Version_configuration: "Cấu hình phiên bản",
  Review_status: "Trạng thái xét duyệt",
  Operation_status: "Tình trạng hoạt động",
  AppStore_version_number: "Số phiên bản AppStore",
  Please_enter_the_version_number: "Vui lòng nhập số phiên bản",
  Version_status: "Trạng thái phiên bản",
  Update_instructions: "Cập nhật hướng dẫn",
  Configure_time: "Cấu hình thời gian",
  Please_enter_the_interface_version_number: "Vui lòng nhập số phiên bản giao diện",
  hint_90: "Số phiên bản giao diện do kỹ thuật cung cấp，không thể tùy chỉnh riêng!",
  Please_enter_update_instructions: "Vui lòng nhập hướng dẫn cập nhật",
  Please_select_version_status: "Vui lòng chọn trạng thái phiên bản",
  Please_enter_the_AppStore_version_number: "Vui lòng nhập số phiên bản AppStore",
  Please_enter_the_short_video_title: "Vui lòng nhập tiêu đề video ngắn",
  Edit_Title: "Chỉnh sửa tiêu đề",
  Short_video_title_: "Tiêu đề video ngắn：",
  hint_91: "Phát hành đoạn video ngắn này đến đầu di động，có xác nhận phát hành không?",
  Crawling_website_sites: "Thu thập thông tin trang web",
  Statistical_time: "Thống kê thời gian",
  UV_data: "Dữ liệu UV",
  Platform: "Nền tảng.",
  Add_circle_recommendations: "Giới thiệu vòng tròn mới.",
  Are_you_sure_you_want_to_pause_: "Có xác định tạm dừng không?",
  Access_date: "Ngày truy cập",
  Posting_time: "Thời gian đăng bài.",
  Poster_nickname: "Biệt danh của người gửi.",
  Interactive_red_envelopes: "Phong bì đỏ tương tác",
  Topic_Name: "Tên chủ đề",
  Please_enter_the_topic_name: "Vui lòng nhập tên chủ đề",
  hint_632: "Nhiệt độ chủ đề (10 phút thống kê một lần)",
  Accumulated_number_of_related_posts: "Tích lũy số lượng bài viết liên quan",
  Save_and_publish: "Lưu và xuất bản",
  Recommendation: "Đề cử.",
  Add_recommended_topics: "Thêm đề xuất chủ đề",
  Number_of_participants_in_the_topic: "Số lượng người tham gia chủ đề",
  Number_of_topic_views: "Số lượt xem chủ đề",
  Topic_release_time: "Thời gian đăng chủ đề",
  Topic_Top_Time: "Đề tài được đặt lên hàng đầu.",
  Choose_a_topic: "Chọn chủ đề",
  Enter_the_topic_name_you_want_to_recommend: "Nhập tên chủ đề mà bạn cần đề xuất",
  Editor_recommended_topic: "Biên tập viên đề xuất chủ đề",
  The_topic_cannot_be_empty: "Đề tài không thể để trống.",
  hint_631: "Vui lòng nhập tên chủ đề (trong vòng 15 từ)",
  hint_630: "Vui lòng nhập giới thiệu về chủ đề (trong vòng 100 từ)",
  Successfully_created_topic: "Tạo chủ đề thành công",
  Recharge: "Nạp tiền.",
  Consumption: "Tiêu thụ.",
  Revenue: "Lợi nhuận.",
  Order_number_1: "Số thứ tự",
  Recharge_amount: "Số tiền nạp.",
  Consumption_type: "Loại người tiêu dùng",
  Consumption_amount: "Số tiền tiêu dùng",
  Revenue_type: "Các loại lợi nhuận",
  Revenue_amount: "Số tiền thu được",
  Additional_issuance: "Tăng phát.",
  Uploading: "Đang tải lên......",
  Batch_issuance: "Tăng trưởng hàng loạt.",
  Download_batch_issuance_templates: "Tải xuống mẫu tăng trưởng hàng loạt",
  Additional_currency_issuance: "Phát hành thêm tiền",
  Increase_experience_value_of_issuance: "Tăng điểm kinh nghiệm.",
  Please_enter_user_userid: "Vui lòng nhập user userid",
  Please_enter_the_quantity_of_coins: "Xin hãy nhập số lượng kim tệ.",
  Please_enter_a_note: "Vui lòng nhập ghi chú.",
  VIP_level: "Đẳng cấp VIP.",
  Additional_issuance_time: "Thời gian tăng trưởng.",
  Are_you_sure_you_want_to_refuse_: "Có chắc là muốn từ chối không?",
  Personal_currency_issuance: "Tăng trưởng tiền tệ cá nhân",
  VIP_currency_issuance: "Phát hành thêm tiền VIP",
  Total_consumption_in_gold_coins: "Total Consumption (đồng tiền vàng)",
  Total_rechargeyuan: "Tổng giá trị gia tăng.",
  Number_of_Rechargers: "Số người nạp tiền.",
  Recharge_switch: "Công tắc nạp tiền.",
  RMB_amount_yuan_: "Số tiền CNY (Yuan)",
  Number_of_gold_coins: "Số lượng kim tệ.",
  Edit_recharge_configuration: "Chỉnh sửa cấu hình nạp tiền",
  Add_recharge_configuration: "Thêm cấu hình nạp tiền",
  Recharge_time: "Thời gian nạp tiền.",
  Payment_channels: "Các kênh thanh toán",
  Please_enter_the_order_number_1: "Vui lòng nhập số đơn đặt hàng",
  Payment_time: "Thời gian thanh toán",
  Order_time: "Thời gian đặt hàng",
  Payment_amount_yuan: "Số tiền thanh toán (Yuan)",
  Balance_inquiry: "Truy vấn số dư.",
  Ordinary_user_currency_balance: "Số dư tiền tệ của người dùng thông thường",
  Details_1: "Chi tiết.",
  Football_quiz_activity: "Hoạt động trả lời câu hỏi bóng đá.",
  Football_winning_statistics: "Football Winning Statistics (bằng tiếng Anh)",
  Basketball_quiz_activity: "Hoạt động trả lời câu hỏi bóng rổ.",
  Basketball_winning_statistics: "Basketball Winning Statistics (bằng tiếng Anh).",
  Guessing_question_name: "Tên câu trả lời phỏng đoán.",
  Please_enter_the_name_of_the_quiz_question: "Xin vui lòng nhập tên câu trả lời.",
  Start_time: "Thời gian bắt đầu.",
  Add_a_question: "Câu trả lời mới.",
  Rule_configuration: "Cấu hình quy tắc",
  Style_Configuration: "Cấu hình kiểu dáng",
  Number_of_periods: "Số kỳ.",
  Reward_base: "Cơ sở khen thưởng.",
  Stop: "Dừng lại.",
  End: "Kết thúc.",
  Title: "Đề mục.",
  The_problem_name_cannot_be_empty: "Tên câu hỏi không thể để trống",
  Please_enter_the_question_name: "Vui lòng nhập tên câu hỏi",
  Please_enter_question_options: "Vui lòng nhập tùy chọn câu hỏi và câu trả lời",
  question_options_1: "Lựa chọn trả lời",
  Add_Options: "Thêm tùy chọn",
  Please_enter_the_number_of_currencies_for_the_reward_base: "Vui lòng nhập số tiền của cơ sở phần thưởng",
  Save: "Tiết kiệm.",
  Cancel: "Hủy bỏ.",
  Please_submit_results_with_caution: "Xin hãy cẩn thận trình bày kết quả.",
  The_answer_to_the_question_cannot_be_empty: "Câu trả lời cho câu hỏi không thể để trống",
  Flow: "Lưu cục trưởng.",
  Activity_Rule_Configuration: "Cấu hình quy tắc hoạt động",
  Rule_configuration_cannot_be_empty: "Cấu hình quy tắc không thể để trống",
  Limited_to_200_words_in_length: "Giới hạn 200 chữ.",
  Guessing_Style_Configuration: "Đoán kiểu dáng cấu hình.",
  PC_Banner_diagram: "Biểu đồ PC Banner",
  PC_Banner_image_cannot_be_empty: "Biểu đồ PC Banner không thể để trống",
  hint_612: "Kích thước： 1920x624 chỉ có thể tải lên tệp jpg/png",
  H5_APP_Banner_image: "Biểu đồ Banner H5/APP",
  H5_APP_Banner_image_cannot_be_empty: "Biểu đồ H5/APP Banner không thể để trống.",
  hint_613: "Kích thước： 750x1012 chỉ có thể tải lên tệp jpg/png",
  Theme_color: "Màu sắc chủ đề",
  The_theme_color_cannot_be_empty: "Màu chủ đề không thể để trống",
  hint_619: "Cấu hình màu ở cuối trang",
  Event_details: "Chi tiết sự kiện",
  Correct_answer: "Đáp án chính xác.",
  Stopped: "Đã dừng lại.",
  Add_a_guessing_activity: "Hoạt động cạnh tranh mới.",
  hint_618: "Mời điền tên đáp án cạnh tranh.",
  Please_select_the_effective_time_range: "Vui lòng chọn khung thời gian có hiệu lực",
  Please_fill_in_the_reward_base: "Xin hãy điền vào số tiền thưởng.",
  hint_92: "Sau khi hủy thì không công bố hoạt động trả lời kỳ này，có xác định hủy bỏ hoạt động trả lời phỏng đoán kỳ này không?",
  Success: "Thành công.",
  Cancel_successful: "Hủy bỏ thành công.",
  hint_93: "Sau khi dừng，người dùng sẽ không thể tham gia vào các câu trả lời và có chắc chắn ngừng hoạt động không?",
  hint_614: "Vui lòng nhập cơ sở phần thưởng cho số nguyên dương 1-99999",
  Cannot_select_past_time: "Không thể lựa chọn thời gian đã qua.",
  hint_615: "Có xác định công bố đáp án không?",
  Save_successful: "Tiết kiệm thành công",
  hint_616: "Câu trả lời cho câu hỏi {số} được đặt tối thiểu 2 lựa chọn",
  Number_of_participants: "Số người tham gia",
  Number_of_winners: "Số người trúng thưởng",
  hint_617: "Số tiền thưởng (tiền vàng)",
  Question_Name: "Tên câu hỏi",
  Number_of_correct_answers: "Số người trả lời đúng.",
  Activity_List: "Danh sách các hoạt động",
  Top_of_the_event: "Hoạt động lên đỉnh.",
  PC_end_carousel: "PC-side-round (bằng tiếng Anh).",
  AK_Activity_List: "AK - Danh sách các hoạt động",
  AK_Activity_Topping: "AK – Sự kiện đỉnh cao",
  AK_PC_end_carousel: "AK-PC-End Roundup (bằng tiếng Anh).",
  Create_activity_records: "Tạo một bản ghi hoạt động",
  Event_main_title: "Tiêu đề chính của chiến dịch",
  Activity_subtitle: "Phụ đề chiến dịch",
  PC_thumbnail: "Hình thu nhỏ phía PC",
  App_thumbnail: "Hình thu nhỏ ứng dụng",
  Activity_time: "Thời gian hoạt động",
  Long_term_activities: "Hoạt động lâu dài",
  Please_enter_the_main_title_of_the_activity: "Vui lòng nhập tiêu đề chính của chiến dịch",
  Please_enter_the_activity_subtitle: "Vui lòng nhập phụ đề hoạt động",
  PC_activity_redirect_address: "Địa chỉ chuyển hoạt động PC",
  Activity_jump_button_background: "Nền nút nhảy hoạt động",
  App_side_thumbnail: "Hình thu nhỏ phía ứng dụng",
  hint_901: "Kích thước 355 * 148px",
  App_activity_redirect_address: "Địa chỉ nhảy hoạt động ứng dụng",
  Time_type: "Loại thời gian",
  There_is_a_time_limit: "Có giới hạn thời gian.",
  Edit_activity_records: "Biên tập hồ sơ hoạt động",
  hint_629: "Có chắc là ghi lại hoạt động này không?",
  hint_620: "Xác định hủy bỏ ghi chép hoạt động này không?",
  Please_set_the_activity_time: "Vui lòng đặt thời gian hoạt động.",
  Jump_URL: "Nhảy URL",
  Queue: "Hàng đợi.",
  Cover: "Trang bìa.",
  Mobile_download_page: "Trang tải xuống di động",
  PC_Download_Page: "Trang tải xuống phía PC",
  Android_copywriting: "Copywriting cho Android",
  hint_94: "Vui lòng nhập bản sao，hỗ trợ tối đa 10 ký tự",
  IOS_Web_App_Copywriting: "iOS - Web App Copywriting (bằng tiếng Anh).",
  IOS_copywriting: "Copywriting cho iOS",
  PC_background_configuration: "Cấu hình nền PC",
  Group_stage_competition: "Vòng bảng.",
  "1_8_elimination_round": "Vòng loại 1/8.",
  "1_4_elimination_round": "Vòng loại 1/4.",
  Semi_finals: "Bán kết.",
  Finals: "Trận chung kết.",
  Location: "Địa điểm.",
  Facing_each_other: "Trận đấu.",
  hint_95: "Vui lòng nhập số phòng neo và ngăn cách bằng dấu phẩy，tối đa bốn chữ số",
  Fen: "Phân.",
  hint_628: "Tối đa chỉ có thể chọn 4 số phòng.",
  SM_redemption_page: "Trang trao đổi SM",
  AK_redemption_page: "Trang trao đổi AK",
  H5_end_thumbnail: "Hình thu nhỏ cuối H5",
  hint_96: "Chỉ có thể tải lên tệp jpg/png/gif，kích thước 1440 * 315",
  Category_ID: "Danh mục ID",
  Classification_name: "Tên phân loại",
  Please_enter_the_category_name: "Vui lòng nhập tên phân loại",
  Thai_tag_name: "Tên nhãn mác Thái Lan",
  Please_enter_the_Thai_tag_name: "Vui lòng nhập tên thẻ Thái Lan.",
  English_tag_name: "Tên nhãn tiếng Anh",
  Please_enter_the_English_tag_name: "Vui lòng nhập tên nhãn tiếng Anh",
  Vietnamese_label_name: "Tên nhãn mác tiếng Việt",
  Please_enter_the_Vietnamese_label_name: "Vui lòng nhập tên nhãn tiếng Việt",
  Korean_tag_name: "Tên nhãn mác Hàn Quốc",
  Please_enter_the_Korean_tag_name: "Vui lòng nhập tên nhãn tiếng Hàn",
  Brazilian_label_name: "Tên nhãn mác trong tiếng Brazil",
  Please_enter_the_Brazilian_label_name: "Vui lòng nhập tên nhãn mác tiếng Brazil",
  Add_a_new_category: "Phân loại mới",
  hint_888: "Tinh phẩm tốt.",
  Please_select_the_status: "Vui lòng chọn trạng thái",
  hint_627: "Có xác định hàng hóa này đang online không?",
  hint_626: "Có xác định được phân loại hàng hóa này không?",
  Warning: "Cảnh báo.",
  hint_97: "Phân loại có chứa hàng hóa thuộc sở hữu，không thể logout.",
  Edit_Classification: "Phân loại biên tập",
  Redemption_page_BANNER: "Đổi trang BANNER",
  hint_625: "Kích thước ảnh tải lên không được vượt quá 5M!",
  Delisted_products: "Hàng hóa hạ giá.",
  Listing_products: "Hàng hóa lên kệ.",
  Classification_management: "Quản lý phân loại.",
  Label_management: "Quản lý nhãn mác",
  Order_List: "Danh sách các đơn đặt hàng",
  Product_ID: "Mã sản phẩm",
  Please_enter_the_product_ID: "Vui lòng nhập ID sản phẩm",
  Product_name: "Tên sản phẩm",
  Please_enter_the_product_name: "Vui lòng nhập tên sản phẩm",
  Do_we_need_delivery_: "Có cần giao hàng hay không?",
  Switch: "Công tắc.",
  Unit_price: "Đơn giá.",
  Discounted_price: "Giảm giá.",
  Current_inventory: "Hàng tồn kho hiện tại",
  Redeemed: "Đã đổi.",
  Inventory_changes: "Thay đổi hàng tồn kho",
  Select_thumbnail: "Chọn hình thu nhỏ",
  Details_page_image: "Sơ đồ trang chi tiết",
  Select_detailed_image: "Chọn biểu đồ chi tiết",
  Details: "Mô tả chi tiết",
  Please_enter_the_unit_price: "Vui lòng nhập đơn giá.",
  Discounts: "Giảm giá đi.",
  Please_enter_the_discounted_price_of_the_product: "Vui lòng nhập hàng hóa để giảm giá.",
  Please_select_a_category: "Vui lòng chọn loại",
  Label: "Nhãn hiệu.",
  Product_purchase_restriction_period: "Chu kỳ hạn chế mua hàng hóa.",
  No_purchase_restrictions: "Không hạn chế mua.",
  Day: "Ngày.",
  Week: "Chu.",
  Cumulative: "Tích lũy.",
  Product_purchase_limit: "Số lượng hạn chế mua hàng.",
  hint_98: "Vui lòng nhập giới hạn mua hàng trong chu kỳ.",
  Inventory: "Hàng tồn kho.",
  Add: "Tăng lên.",
  Reduce: "Giảm.",
  The_product_name_cannot_be_empty: "Tên sản phẩm không được để trống",
  Boutique: "Tinh phẩm.",
  Life: "Cuộc sống.",
  Product_addition: "Hàng hóa mới.",
  Please_upload_thumbnail: "Vui lòng tải lên hình thu nhỏ.",
  Please_upload_detailed_images: "Xin vui lòng tải lên bản đồ chi tiết.",
  Please_select_whether_delivery_is_required: "Vui lòng chọn nếu bạn muốn giao hàng",
  Purchase_unit_price_cannot_be_empty: "Đơn giá mua không được để trống",
  Classification_cannot_be_empty: "Phân loại không được để trống.",
  hint_99: "Số lượng hạn chế mua hàng không được để trống.",
  Details_cannot_be_empty: "Thông tin chi tiết không thể để trống",
  Please_select_increase_decrease: "Vui lòng chọn tăng giảm",
  Are_you_sure_you_want_to_save_: "Có xác định bảo tồn hay không?",
  Cat_coin_price: "Giá mèo.",
  Diamond_price: "Giá kim cương.",
  Thai_product_name: "Tên thương mại Thái Lan",
  Please_enter_the_Thai_product_name: "Vui lòng nhập tên hàng hóa bằng tiếng Thái.",
  English_product_name: "Tên thương mại bằng tiếng Anh",
  Please_enter_the_English_product_name: "Vui lòng nhập tên sản phẩm bằng tiếng Anh",
  Korean_product_name: "Tên thương mại Hàn Quốc",
  Please_enter_the_Korean_product_name: "Vui lòng nhập tên hàng hóa bằng tiếng Hàn.",
  Brazilian_product_name: "Tên thương mại Brazil",
  Please_enter_the_Brazilian_product_name: "Vui lòng nhập tên thương mại bằng tiếng Brazil.",
  hint_902: "Kích thước đề nghị 174 * 174",
  hint_903: "Kích thước đề xuất 375 * 375",
  Thai_language_details: "Mô tả chi tiết bằng tiếng Thái",
  English_detailed_explanation: "Thông tin chi tiết bằng tiếng Anh",
  Vietnamese_language_details: "Thông tin chi tiết bằng tiếng Việt",
  Korean_language_details: "Mô tả chi tiết bằng tiếng Hàn",
  Brazilian_language_details: "Mô tả chi tiết tiếng Brazil",
  Please_enter_the_diamond_price_of_the_product: "Vui lòng nhập giá kim cương hàng hóa.",
  Are_you_sure_you_want_to_delist_this_product_: "Có xác định hạ giá sản phẩm này không?",
  Label_ID: "Thẻ ID",
  Please_enter_the_label_name: "Vui lòng nhập tên nhãn mác",
  Label_Name: "Tên nhãn mác",
  hint_624: "Có xác định đăng nhập nhãn hàng không?",
  hint_101: "Bạn có chắc chắn rằng bạn đã tắt nhãn sản phẩm này không?",
  Edit_label: "Nhãn hiệu biên tập",
  Order_number: "Số đơn đặt hàng",
  Please_enter_the_order_number: "Vui lòng nhập số đơn đặt hàng",
  Transaction_time: "Thời gian giao dịch",
  Receiving_address: "Địa chỉ nhận hàng.",
  Signee: "Người ký nhận.",
  Transaction_volume: "Khối lượng giao dịch",
  Total_transaction_amount: "Tổng số giao dịch",
  Transaction_status: "Trạng thái giao dịch",
  Unsuccessful: "Không thành công.",
  Limited_to_50_words_in_length: "Giới hạn 50 chữ.",
  Inviter_UID: "UID người mời",
  Inviting_person_nickname: "Biệt danh của người mời.",
  Invitee_UID: "UID được mời",
  Invitee_registration_time: "Thời gian đăng ký người được mời",
  Please_enter_the_inviter_reward: "Mời nhập phần thưởng người mời.",
  Please_enter_the_invitee_reward: "Xin mời nhập phần thưởng cho người được mời.",
  Rule_Description: "Quy tắc giải thích.",
  APP_landing_page_background_image: "Bản đồ nền trang hạ cánh của APP",
  hint_621: "Kích thước： 710X1086 chỉ có thể tải lên tệp jpg/png",
  Watch_the_live_broadcast: "Xem trực tiếp.",
  Live_room_speech: "Phát biểu trực tiếp.",
  Push_order_configuration: "Cấu hình đẩy",
  hint_102: "Gợi ý thân thiện： 1 - cấu hình phát biểu Vui lòng cấu hình theo thứ tự. Nếu cấu hình đến câu 2，chỉ 1，2 phát biểu cấu hình nội dung sẽ được thực hiện.",
  hint_905: "Cấu hình phát biểu lần thứ nhất.",
  Reward_amount: "Số tiền thưởng.",
  Please_enter_the_speech_reward_amount: "Vui lòng nhập số tiền thưởng phát biểu.",
  Valid_characters: "Ký tự hợp lệ",
  hint_623: "Vui lòng nhập ký tự hợp lệ để phát biểu",
  hint_103: "Số tiền thưởng cấu hình phát biểu đầu tiên không thể để trống",
  hint_104: "Số tiền thưởng cấu hình phát biểu thứ hai không thể để trống.",
  hint_105: "Số tiền thưởng cấu hình phát biểu lần thứ ba không thể để trống.",
  Friendly_reminder: "Gợi ý hữu nghị.",
  hint_1: "1- Dữ liệu đẩy và dữ liệu tham gia của người dùng có thể được truy cập vào quản lý trực tiếp mô-đun đẩy phòng trực tiếp;",
  hint_2: "2-Push Rewards Range： Phần thưởng cho các lệnh đẩy neo được tạo ngẫu nhiên trong phạm vi đó",
  hint_3: "3 - đỉnh thưởng hàng ngày của người dùng： vượt quá đỉnh này sẽ tiếp tục tham gia vào các tweet mà không nhận được phần thưởng",
  hint_889: "Push-order reward range (bằng tiếng Anh).",
  Maximum_reward: "Phần thưởng lớn nhất.",
  Minimum_reward: "Phần thưởng tối thiểu.",
  Users_get_peak_daily: "User gets peak per day (Người dùng đạt đỉnh mỗi ngày).",
  hint_622: "Vui lòng nhập giá trị tối đa hàng ngày của người dùng",
  Please_enter_the_maximum_reward: "Vui lòng nhập phần thưởng tối đa.",
  Please_enter_the_minimum_reward: "Vui lòng nhập phần thưởng tối thiểu.",
  Please_enter_the_user_daily_peak_gain: "Please enter user to get peak (Vui lòng nhập user để nhận peak mỗi ngày).",
  Viewing_duration: "Thời gian quan sát dài",
  hint_107: "Vui lòng nhập thời gian xem，cần số nguyên dương",
  Watch_rewards: "Xem phần thưởng",
  Please_enter_the_viewing_reward: "Vui lòng nhập phần thưởng xem.",
  Reward_channels: "Kênh khen thưởng",
  hint_106: "Được thưởng khi xem trực tiếp trên kênh này sau khi mở，chỉ được thưởng một lần khi xem trên các kênh khác nhau",
  Please_enter_the_viewing_duration: "Vui lòng nhập thời gian xem.",
  Please_select_a_reward_channel: "Vui lòng nhập phần thưởng xem.",
  First_line_copy: "Dòng đầu tiên của copywriting",
  hint_108: "Vui lòng nhập bản sao，hỗ trợ tối đa 10 ký tự",
  Second_line_copy: "Dòng văn bản thứ hai.",
  Please_enter_the_first_line_of_text: "Vui lòng nhập dòng đầu tiên của bản sao.",
  Please_enter_the_second_line_of_text: "Vui lòng nhập dòng văn bản thứ hai.",
  Effective_date: "Ngày hợp lệ",
  Display_copy: "Hiển thị copywriting",
  Gift_channels: "Kênh tặng quà",
  Login_rewards: "Phần thưởng đổ bộ.",
  Accumulated_Gifts: "Tặng tích lũy.",
  Additional_rewards_for_app_login: "Phần thưởng bổ sung cho việc đăng nhập APP",
  hint_109: "Mời nhập phần thưởng đăng nhập，là 0 thì không thưởng.",
  Please_enter_the_reward_amount: "Vui lòng nhập số tiền thưởng.",
  Login_Activity_Rewards: "Phần thưởng chiến dịch đăng nhập",
  Please_enter_display_copy: "Vui lòng nhập bản sao hiển thị",
  Please_enter_the_login_reward: "Mời nhập phần thưởng đăng nhập.",
  Please_enter_a_valid_date: "Vui lòng nhập ngày hợp lệ",
  Please_enter_to_select_the_gift_channel: "Vui lòng nhập để chọn kênh tặng quà.",
  Login_reward_activity: "Chiến dịch tiền thưởng hạ cánh",
  Download_copy_configuration: "Tải xuống cấu hình sao chép",
  Lottery_time: "Thời gian rút thưởng.",
  Activity_ID: "ID hoạt động",
  Accessing_Users: "Truy cập người dùng",
  Participating_users: "Người dùng tham gia",
  Data_details: "Dữ liệu chi tiết.",
  Channel_sources: "Nguồn Channel Source",
  Click_on_the_number_of_lucky_draws: "Nhấp vào số lần rút thăm trúng thưởng",
  Page_visits: "Số lượt truy cập trang",
  Number_of_times_participating_in_the_lottery: "Số lần tham gia rút thăm trúng thưởng.",
  Winning_users: "Người chiến thắng",
  Users_who_win_physical_prizes: "Người dùng chiến thắng vật lý",
  Click_to_go_to_the_assistant_user: "Click vào nút Small Assistant User",
  Click_to_download_app_users: "Click vào Download APP User",
  Award_pool_type: "Loại pool giải thưởng",
  Lucky_Wheel: "Bàn xoay may mắn",
  Super_Wheel: "Siêu bàn xoay.",
  Lottery_Record_Daily_Turntable: "Hồ sơ rút thăm trúng thưởng - Vòng quay hàng ngày",
  Physical_rewards: "Phần thưởng bằng hiện vật",
  Draw_lottery_tickets: "Rút vé thưởng.",
  Processing_personnel: "Xử lý người.",
  Not_issued: "Không phát hành.",
  Issued: "Đã phát hành.",
  Virtual: "Hư cấu.",
  Operation_turntable: "Bàn xoay vận hành",
  Activity_Management: "Quản lý sự kiện",
  Lottery_record: "Kỷ lục rút thăm trúng thưởng.",
  Operation_turntable_activity_data: "Dữ liệu hoạt động của bàn xoay vận hành",
  Activity_type: "Các loại hoạt động",
  Activity_carousel: "Bàn xoay hoạt động",
  Daily_turntable: "Bàn xoay hàng ngày",
  Activity_statistics: "Thống kê hoạt động",
  Number_of_super_lottery_tickets_distributed: "Số lượng vé trúng thưởng siêu cấp.",
  Lucky_Wheel_Lucky_Draw_Times: "Số lần rút thăm may mắn.",
  Super_Wheel_Lottery_Times: "Số lần rút thăm trúng thưởng siêu vòng quay.",
  Basic_information: "Thông tin cơ bản",
  Activity_Rules: "Quy tắc hoạt động",
  Please_enter_activity_rules: "Vui lòng nhập quy tắc hoạt động",
  PC_banner_image: "Biểu đồ ngân hàng máy tính",
  H5_Jump_Page: "h5 trang nhảy",
  In_use: "Đang sử dụng.",
  Total_inventory: "Tổng hàng tồn kho",
  Prizes: "Giải thưởng.",
  Single_day_upper_limit: "Giới hạn một ngày",
  Individual_users_can_receive_upper_limits: "Single user get cap (Người dùng cá nhân được giới hạn)",
  Lucky_draw_probability_in_tens_of_thousands_: "Xác suất rút thăm trúng thưởng (vạn phần)",
  Is_it_a_running_horse_lamp_: "Có phải đèn chạy ngựa hay không?",
  Is_it_a_bottom_line: "Có bao che hay không?",
  Add_prize_pool: "Thêm tiền thưởng",
  Cloning: "Nhân bản.",
  Single_consumption: "Tiêu thụ một lần.",
  Daily_limit: "Giới hạn hàng ngày",
  Activity_channels: "Kênh hoạt động",
  H5_banner_diagram: "Biểu đồ h5 banner",
  How_to_earn_cat_coins: "Làm thế nào để kiếm tiền mèo?",
  Please_select_the_activity_time_range: "Vui lòng chọn khung thời gian hoạt động",
  Edit_anchor_recommendation_group: "Biên tập viên Anchor Recommendation Group",
  Add_a_new_anchor_recommendation_group: "Thêm nhóm giới thiệu neo mới",
  Please_upload_the_PC_banner_image: "Vui lòng tải lên biểu đồ pc banner",
  Please_upload_the_h5_banner_image: "Vui lòng tải lên biểu đồ h5 banner.",
  Please_enter_PC_Jump_Page: "Vui lòng nhập trang nhảy pc",
  Please_enter_h5_to_jump_to_the_page: "Vui lòng nhập trang nhảy h5",
  Please_enter_a_single_consumption: "Vui lòng nhập một lần tiêu thụ.",
  Please_enter_the_daily_limit: "Vui lòng nhập giới hạn hàng ngày.",
  Please_select_the_activity_channel: "Vui lòng chọn kênh hoạt động",
  Prize_Pool: "Hồ thưởng.",
  hint_110: "Thời gian mở hồ thưởng không được sớm hơn thời gian mở hoạt động.",
  hint_111: "Thời gian kết thúc giải thưởng không được chậm hơn thời gian kết thúc hoạt động.",
  The_total_probability_does_not_equal_10000: "Tổng xác suất không bằng 10.000",
  Sum_of_probabilities: "Tổng số xác suất",
  Please_select_the_prize_for_prize_: "Vui lòng chọn giải thưởng cho {số}",
  Please_enter_the_total_inventory_of_prize_: "Vui lòng nhập tổng số cổ phiếu của giải thưởng {số}",
  _The_daily_limit_is_greater_than_the_total_inventory: "{num} giới hạn một ngày lớn hơn tổng số hàng tồn kho.",
  hint_112: "Vui lòng nhập xác suất rút thăm trúng thưởng (10.000)",
  hint_115: "Vui lòng chọn giải thưởng {num} có đèn chạy bộ hay không.",
  hint_116: "Mỗi phần thưởng phải chọn một phần thưởng!",
  hint_114: "Vui lòng điền vào thời gian có hiệu lực của hồ thưởng.",
  hint_113: "Có xác nhận xóa bỏ phần thưởng này không?",
  Data_Overview: "Tổng quan về dữ liệu",
  Quantity_of_currency: "Số lượng tiền tệ",
  Please_enter_the_quantity_of_currency: "Vui lòng nhập số tiền",
  Number_of_lottery_tickets: "Số lượng vé thưởng",
  Please_enter_the_number_of_lottery_tickets: "Vui lòng nhập số lượng vé thưởng.",
  Select_icon: "Chọn icon",
  Please_select_the_type_of_prize: "Vui lòng chọn loại giải thưởng",
  Please_upload_the_icon: "Xin vui lòng tải lên icon.",
  Editing_prizes: "Giải thưởng biên tập",
  Please_enter_a_positive_integer: "Vui lòng nhập số nguyên dương",
  single_1: "Một",
  submit_results_1: "Gửi kết quả",
  rules_options: "Cấu hình quy tắc",
  rules_options_1: "Cấu hình quy tắc không được để trống",
  edit_activity_cai: "Biên tập viên thi đoán",
  created_topic_1: "Tạo chủ đề",
  // 新增
  created_topic_2: "Chủ đề biên tập",
  briefing_topic_1: "Giới thiệu về chủ đề",
  briefing_topic_2: "Hình thu nhỏ của chủ đề",
  hint_899: "Chỉ có thể tải lên tệp jpg/png, giới hạn 1M, kích thước 790 * 215px",
  hint_907: "Chỉ có thể tải lên tệp jpg/png, giới hạn 1M, kích thước 150 * 58px",
  hint_917: "Chỉ có thể tải lên tệp jpg/png, giới hạn 1M, kích thước 355 * 148px",
  hint_918: "The recommended image size is 256x256, and only jpg/png files can be uploaded",

  lookDetail: "xem",
  text_1: "Bạn có chắc chắn về việc hạ bệ không?",
  text_2: "Bạn có chắc chắn về việc lên kệ không?",
  // 新增2
  matchPlan_1: 'Tiến độ trận đấu',
  group_1: 'nhóm nhỏ',
  shopEdit: 'Chỉnh sửa sản phẩm',
  viShopEditName: 'Tên sản phẩm bằng tiếng Việt',
  viShopEditName1: 'Vui lòng nhập tên sản phẩm bằng tiếng Việt',
  hint_1001: "Mua mèo không được để trống",
  hint_1002: "Mua kim cương không được để trống",
  hint_1003: 'Vui lòng nhập tiền mèo',
  // 新增3
  remark_edit: 'Ghi chú biên tập',
  currency_cat: 'đồng xu mèo',
  diamond_1: 'kim cương',
  Invitee_UID_1: 'UID mời',
  Invitee_edit_1: "Mời cấu hình",
  minute_1: 'phút',
  establish_1: 'tạo ra',
  hint_1005: "Thống kê chiến thắng bóng đá",
  hint_1006: "Thống kê chiến thắng bóng rổ",
  hint_1007: "Chi tiết trúng thưởng",
  hint_1008: 'Bắt đầu',
  hint_1009: 'dừng lại',
  hint_1011: "Chưa điền",
  hint_1012: "Chọn biểu đồ banner",
  hint_1013: "trang nhảy pc",
  hint_1014: "Sử dụng",
  hint_1015: 'ID giải thưởng',
  hint_1016: "Vé trúng thưởng",
  hint_1017: "Liên kết",
  hint_1018: "ID thông tin",
  hint_1019: "Tải lên hình thu nhỏ nhạc",
  hint_1021: "Giới hạn xếp hạng chữ cái tiếng Anh",
  hint_1022: "Live interroom Push tự động bật lên",
  advertising_title: "Tiêu đề quảng cáo",
  Author_name_1: "Tác giả",
  // 新增4
  text_7: "Người dùng tin nhắn riêng",
  text_8: "Người dùng tin nhắn riêng",
  text_9: 'Quản lý thư riêng của người dùng',
  text_10: "Cấp bậc người dẫn chương trình trò chuyện riêng",
  text_11: 'Logo trên cùng của trang tải về',
  text_12: 'Trạng thái trang album APP/H5',
  text_13: 'Biểu đồ 3x iOS',
  text_14: 'Bài thứ nhất',
  text_15: 'Phần 2',
  text_16: 'Bài thứ ba',
  text_17: 'Phần 4',
  text_18: 'Nội dung của ${num} không thể để trống',
  text_19: "Hình ảnh thứ ${num} không thể để trống",
  text_20: 'Kích thước ảnh được đề xuất là 40x40 và chỉ có thể tải lên tệp jpg/png/gif',
  text_21: 'Kích thước ảnh được đề xuất là 40x40 và chỉ có thể tải lên tệp jpg/png/gif',
  text_22: 'Tên trang album PC',
  text_23: 'Vui lòng nhập URL tương ứng với src, để đếm nhiều tên miền, hãy phân biệt bằng ||',
  text_24: 'Giới thiệu về cấu hình sao chép trang',
  text_25: "Vui lòng điền nội dung bản sao",
  text_26: "Vui lòng nhập tài khoản nhà phát triển",
  text_27: "Trang khởi động mới",
  text_28: "Không cần cập nhật",
  text_29: 'Cập nhật tùy chọn',
  text_30: 'Buộc cập nhật',
  text_31: 'Khóa công khai',
  text_32: 'Khóa riêng',
  text_33: 'Mô tả chức năng',
  text_34: 'Cấu hình Android',
  text_35: "Phòng truyền hình trực tiếp gửi lì xì",
  text_36: "Tặng quà trực tiếp",
  text_37: 'Khởi động báo cáo điểm chôn APP',
  text_38: 'Loại tên miền',
  text_39: 'Sửa tên miền',
  text_40: 'Thêm tên miền',
  have_1: 'có',
  have_2: 'không có',
  updateTime_1: 'Thời gian cập nhật',
  text_41: 'Bạn chắc chắn muốn xóa tên miền?',
  text_42: "Sau khi bỏ lệnh cấm, từ này có thể tiếp tục được gửi trên nền tảng để xác nhận bỏ lệnh cấm không?",
  text_43: 'Địa chỉ banner URL',
  text_44: 'H5banner',
  text_45: 'Vui lòng nhập description',
  text_46: 'Vui lòng nhập tiêu đề trang đầu',
  text_47: 'Vui lòng nhập tiêu đề trang trực tiếp',
  text_48: "Chỉ có thể upload file jpg/jpeg/png, hỗ trợ upload tối đa 9 file",
  text_49: 'Tải lên tệp video',
  text_50: 'Vui lòng nhập ID vòng tròn',
  text_51: 'Hỗ trợ định dạng: JPG, PNG, GIF, MP4, Kích thước: Trong vòng 3M:',
  text_52: 'Chưa được công bố',
  text_53: 'Vui lòng nhập giới thiệu',
  text_54: 'Chỉ có thể tải lên tệp jpg/png/gif, giới hạn 1M, kích thước 790 * 215px',
  text_55: 'Đặt giới hạn số người',
  text_56: 'Thời gian đăng bài',
  text_57: 'Vui lòng đặt giới hạn số người',
  text_58: "Số lượng tối đa không được nhỏ hơn 50 người",
  text_59: 'Tên thật',
  text_60: 'Đồng ý',

  text_61: 'Tạo số vòng tròn',
  text_62: 'Số lượng người dùng',
  text_63: 'Thời gian gia nhập',
  text_64: 'Số chứng minh thư:',
  text_65: 'Vui lòng nhập số điện thoại',
  text_66: 'Tác giả',
  text_67: 'Thể thao',
  text_68: 'Giải trí',
  text_69: 'Khác',
  text_70: 'Chỉ có thể tải lên tệp jpg/png, kích thước ảnh cần là 120X120',
  text_71: 'Thêm kênh',
  text_72: 'Tất cả các kênh',
  channel_1: 'kênh',
  text_73: 'chỉ định phòng',
  text_74: 'Thành công',
  text_75: 'Địa điểm',
  text_76: 'Hình nền PC',
  text_77: 'Biểu đồ tiêu đề PC',
  text_78: 'Giải thích cách chơi',
  text_79: 'Thời gian xem trực tiếp (phút)',
  text_80: 'Xác suất',
  text_81: "0 là không thể rút trúng bao lì xì, đây là xác suất người dùng rút trúng bao lì xì có thưởng",
  text_82: 'Thời gian đăng ký',
  text_84: 'Trạng thái thưởng',
  text_85: 'Phần thưởng',
  text_86: "Bạn có chắc chắn sẽ nhận được phần thưởng không?",
  text_87: 'Kiểu chữ ký',
  text_88: 'Gửi người dùng',
  text_89: 'Vui lòng nhập người gửi userid',
  text_90: 'ID lì xì',
  text_91: 'Gửi biệt danh',
  text_92: 'Số phòng',
  text_93: 'Vui lòng nhập số phòng đã gửi',
  text_94: "Tiền lì xì",
  text_95: 'Số lượng',
  text_96: 'Số không hợp lệ',
  text_97: ' Thời gian gửi',
  text_98: 'Đã kích hoạt',
  text_99: 'Chủ đề ID',
  text_101: 'Thắng hay thua',
  text_102: 'ID mưa tiền lì xì',
  text_103: 'Tổng số bao lì xì',
  text_104: 'Hiệu lực',
  text_105: 'Vô hiệu hóa',
  text_106: 'Giải đấu bị ràng buộc',
  text_107: 'Số lượng bao lì xì',
  text_108: 'Số người nhận',
  text_110: 'Hoạt động',
  text_111: 'Nhận',
  text_112: 'Căn phòng',
  text_113: 'Chia sẻ nền tảng',
  text_114: 'Thời gian đăng nhập',
  text_115: 'Đăng ký IP',
  text_116: 'Thời gian online',
  text_117: 'Trạng thái gửi:',
  text_118: 'Thay đổi thời gian',
  text_119: 'Số lần hiển thị',
  text_120: 'ID trận đấu trực tiếp',
  text_121: 'Cách chơi',
  text_122: 'Nội dung đẩy',
  text_123: 'Cuộc thi',
  text_124: 'Hỗ trợ',
  text_125: 'Kết quả',
  text_126: 'phản đối',
  text_127: 'Sửa kết quả',
  text_128: 'Số tiền thưởng',
  text_129: 'Tổng phát hành',
  text_130: 'Người tố cáo',
  text_131: 'Mô tả',
  text_132: 'Trả lời',
  text_133: 'Bối cảnh APP',
  // 新增5
  live_time: 'Thời gian phát sóng trực tiếp (phút)',
  Please_select_the_results: 'Vui lòng chọn kết quả',
  red_1: 'đỏ',
  black_1: 'đen',
  go_1: 'đi',
  corner: 'Góc',
  win_or_lose: 'thắng hay thua',
  Size_division: 'Kích thước',
  take_care_1: 'Lưu ý: Kích thước 572x420',
  take_care_2: 'Lưu ý: Kích thước 926x126',
  discount_1: 'giảm giá',
  discount_name_1: 'Tên giảm giá',
  text_135: 'Vui lòng nhập nickname kênh',
  characters_1: 'văn bản',
  picture_1: 'Hình ảnh',
  Please_fill_in_the_link_1: 'Vui lòng điền vào liên kết',
  Please_fill_in_the_link_2: ' Vui lòng điền vào liên kết chính xác',
  publish_this_announcement: 'Bạn có chắc chắn về việc công bố thông báo này không?',
  send_state: 'Gửi trạng thái',
  Create_marketing_messages: 'Tạo tin nhắn tiếp thị',
  SMS_content: 'Nội dung tin nhắn',
  View_receiving_users: 'Xem người dùng đã nhận',
  Upload_list: 'Danh sách tải lên',
  Are_you_sure_to_cancel: 'Xác nhận hủy bỏ',
  User_Import_Template: 'Người dùng nhập mẫu',
  channel_2: 'kênh',
  autograph: 'chữ ký',
  SMS_type: 'Loại tin nhắn',
  SMS_template_editing: 'Sửa mẫu SMS',
  SMS_template_add: 'Thêm mẫu tin nhắn SMS',
  Editing_time: 'Thời gian chỉnh sửa',
  Instant_push: 'tức thì push',
  Timed_push: 'thời gian đẩy',
  Page_H5: 'Trang H5',
  Menu_Type: 'Loại menu',
  Role_authorization: 'ủy quyền vai trò',
  name_111: ' Tên',
  role_111: 'nhân vật',
  Modifying_Merchant_Basic_Information: 'Sửa đổi thông tin cơ bản của người bán',
  Gift_ID: 'ID quà tặng',
  Number_of_deliveries: 'Gửi số',
  Delivery_value_1: "Giá trị cho đi (tiền vàng/vạn)",
  broadcast_room_1: 'Gửi phòng trực tiếp',
  broadcast_room_2: 'Vui lòng nhập phòng phát sóng trực tiếp',
  broadcast_room_3: 'Cho đi giá trị',
  Gift_giving_time: 'Thời gian tặng quà',
  Online_1: 'Đã online',
  Gift_unit_price: 'Đơn giá quà tặng',
  hint_1110: 'Chỉ có thể tải lên tệp jpg/png, kích thước không quá 1MB!',
  hint_1112: 'Hiệu ứng xem trước',
  hint_1113: 'Chỉ có thể tải lên tệp gif, kích thước không quá 1MB!',
  hint_1114: 'Hiệu ứng chơi',
  hint_1115: 'Chỉ có thể tải lên tệp svga, kích thước không vượt quá 10MB!',
  Full_platform: 'Nền tảng đầy đủ',
  This_room: ' phòng này',
  hint_1116: 'Chỉ tải lên chỉ tải lên tệp jpg/png!',
  Type_of_issuance: 'Kiểu tóc dưới',
  Designated_category: 'Chỉ định lớp',
  hint_1117: 'Vui lòng nhập số phòng được chỉ định, cách nhau bằng dấu phẩy bằng tiếng Anh giữa nhiều phòng',
  hint_1118: 'Vui lòng nhập ID quà tặng',
  hint_1119: 'Nhập tối đa 99 ID quà tặng',
  hint_1120: 'Không thể nhập khoảng trắng',
  hint_1121: 'Không thể nhập dấu phẩy Trung Quốc',
  hint_1122: 'Chỉ có thể nhập tiếng Trung, tiếng Anh, số',
  hint_1123: 'Vui lòng chọn kiểu tiếp theo',
  hint_1124: 'Vui lòng chọn phạm vi tiếp theo',
  hint_1125: 'Vui lòng chọn loại',
  hint_1126: 'Vui lòng chỉ định phòng',
  Publishable_status: 'Trạng thái có thể xuất bản',
  Expert_nickname: 'Biệt danh chuyên gia',
  Expert_brief: 'Hồ sơ chuyên gia',
  Expert_detail_1: 'Chi tiết túi gấm',
  article_1: "Á Miên 15 vòng thi điểm tích lũy 13 điểm, xếp hạng 18, ở khu phụ gia xuống cấp; Lượt đấu trước sân khách hoàn toàn bại, gặp phải hai lần liên tiếp bại, trạng thái thấp; Mà sân nhà bọn họ 3 thắng 4 thua, xếp hạng 12, tuy rằng biểu hiện không xuất sắc, nhưng vẫn dựa vào sân nhà đoạt điểm; Về mặt đội hình, đội bóng có 1 người bị thương ở giữa sân, 2 người còn nghi ngờ khi cầu thủ xuất chiến. Đội khách xếp thứ hai trong bảng xếp hạng Pháp Giáp mùa giải trước, mùa giải này trượt dốc nghiêm trọng, hiện nay điểm tích lũy 15 lượt đấu là 10 điểm, xếp hạng 19, nằm trong khu vực giảm cấp. Trận đấu trước sân khách thua, đội bóng cũng gặp phải hai trận thua liên tiếp, trạng thái kém; Mùa giải này sân khách 2 thắng 1 hòa 4 thua, xếp hạng 13, tuy biểu hiện bình thường, nhưng toàn bộ thắng lợi giành được trên sân khách, có năng lực khách chiến nhất định; Về mặt đội hình, đội bóng bị lãnh đạo bị thương, hiện có 14 cầu thủ bị thương. Chỉ số ban đầu của châu Á mở ra bàn bình tay và bình nửa bàn chủ yếu cho trận đấu này, dành sự ủng hộ cẩn thận cho đội khách; Một số cơ quan trong chỉ số mới nhất điều chỉnh nhiều thành bàn ngang tay, niềm tin của cơ quan đối với đội khách có phần suy yếu; Chỉ số trung bình mới nhất của Âu Bồi đè thấp thắng thua bồi thường, nhiều cơ quan đồng thời đè thấp bồi thường, nâng bồi thường lên, bất lợi cho đội khách",
  user_1111: 'Người dùng',
  Import_1111: 'Nhập khẩu',
  hint_1127: 'Đã đạt đến giới hạn bổ sung',
  Closed_111: 'Đóng cửa',
  Opened_111: 'Mở',
  Select_111: 'Chọn',
  less_than: 'nhỏ hơn',
  Publisher_11: 'Nhà xuất bản',
  Let_the_ball: 'Hãy để quả bóng',
  analysis: 'Phân tích',
  Please_enter_the_parsing_content: 'Vui lòng nhập nội dung phân tích',
  price_11: 'giá bán',
  free_11: 'Miễn phí',
  release_1: 'Xuất bản',
  hint_1200: 'Tiêu đề cần nhiều hơn 4 từ',
  hint_1201: "Lý do đề xuất cần hơn 20 từ",
  Ball_selection: 'Lựa chọn bóng',
  Top_failure_time: 'Thời gian hết hạn',
  refund: 'hoàn tiền',
  Topping_time: 'Đặt đỉnh thời gian',
  System_messages: 'Thông điệp hệ thống',
  Please_enter_the_message_content: 'Vui lòng nhập nội dung tin nhắn',
  abnormal: 'bất thường',
  Let_the_ball_win: 'để bóng thắng',
  Please_set_the_time: 'Vui lòng đặt thời gian',
  Please_message_content: 'Vui lòng điền nội dung tin nhắn',
  hint_20001: "Thời gian đặt đỉnh không thể sớm hơn thời gian hiện tại",
  hint_20002: 'Bạn có chắc chắn rằng bạn đã hủy bỏ đỉnh không?',
  hint_20003: 'Thời gian xây dựng kho',
  Purchase_records: 'Hồ sơ mua hàng',
  // 新增8
  Edit_Resource_Bits: 'Chỉnh sửa các bit tài nguyên',
  ADD_Resource_Bits: 'Thêm bit tài nguyên',
  hint_20091: 'Di chuyển lên',
  hint_20092: 'Di chuyển xuống',
  hint_20093: 'hot mới',
  hint_20094: 'Video',
  hint_20095: 'Trang',
  hint_20096: 'Thêm nền',
  hint_20097: 'Bạn có chắc chắn muốn gỡ bỏ hình nền này không?',
  hint_20098: 'Chỉ có thể tải lên tệp jpg/jpeg/png, tỷ lệ khung hình 60 * 60',
  //新增9
  hint_20111: 'Vui lòng chọn thư viện bắn súng',
  hint_20112: 'Vui lòng nhập kiểu gửi tiếp',
  hint_20113: 'Vui lòng nhập phạm vi tiếp theo',
  hint_20114: 'Bạn có chắc muốn {status} cấu hình này không?',
  hint_20115: 'Đã {status}',
  hint_20116: 'Nội dung màn bom không thể để trống',
  hint_20117: 'Thêm tối đa 1000 mục một lần.',
  hint_20118: 'Thêm thời gian',
  hint_20119: "Sau khi bật, người dẫn chương trình sẽ quay trở lại robot, bạn có chắc chắn không?",
  hint_20121: 'Loại phát thanh viên',
  hint_20122: 'Vui lòng nhập ID',
  hint_20123: "Quản lý bất động sản",
  hint_20124: 'Vui lòng nhập uid',
  hint_20125: 'Người dùng userId',
  hint_20126: 'Vui lòng nhập userId',
  hint_20127: 'Vui lòng nhập tiêu đề phát sóng trực tiếp',
  hint_20128: 'Hợp nhất tài khoản',
  hint_20129: 'Phạm vi thông điệp',
  hint_20130: 'Tin nhắn này',
  hint_20888: 'Tin tức trong phòng truyền hình trực tiếp',
  hint_20131: 'Tất cả các tin tức trực tiếp',
  hint_20132: 'Xóa lý do',
  hint_20133: 'Cấm ngôn người dùng',
  hint_20134: 'Người dùng cấm ngôn',
  hint_20135: 'UID không thể để trống',
  hint_20136: "Chu kỳ cấm ngôn không thể để trống",
  hint_20137: "Nguyên nhân cấm ngôn không thể để trống",
  hint_20138: 'Số phòng không được để trống',
  hint_20139: 'Vui lòng nhập tên người dùng',
  hint_20400: 'Bắt đầu đánh giá',
  hint_20401: 'Đánh giá thất bại',
  hint_20402: 'Xuất Excel',
  hint_20403: 'Chu kỳ thanh toán',
  hint_20404: 'Số tiền điều chỉnh:',
  hint_20405: 'Nguyên',
  hint_20406: 'Không bắt đầu',
  hint_20407: 'Thanh toán hóa đơn',
  hint_20408: 'Trước khi biên tập',
  hint_20410: 'Sau khi chỉnh sửa',
  hint_20411: 'Đang chờ xét xử',
  hint_20412: 'Thêm bang hội',
  hint_20413: 'Vui lòng nhập tên tài khoản nhận tiền',
  hint_20414: 'Vui lòng nhập dòng mở tài khoản',
  hint_20415: 'Vui lòng nhập số thẻ ngân hàng',
  hint_20416: 'Vui lòng nhập địa chỉ mở tài khoản',
  hint_20417: 'Vui lòng nhập trong vòng 100 từ',
  hint_20418: 'Hiệp hội biên tập',
  hint_20419: 'guild này sẽ không thể được gán cho neo sau khi bị vô hiệu hóa, bạn có chắc chắn rằng guild này sẽ bị vô hiệu hóa không?',
  hint_20420: 'Chỉ có thể tải lên tệp jpg/png, kích thước đề xuất là',
  hint_20421: 'Tài khoản cấu hình',
  hint_20422: 'Tìm nội dung',
  hint_20423: 'Nội dung thay thế',
  hint_20424: 'Địa chỉ nhảy nguồn tín hiệu',
  hint_20425: 'Vui lòng nhập địa chỉ nguồn tín hiệu',
  hint_20426: 'Vui lòng nhập địa chỉ nhảy nguồn tín hiệu',
  hint_20427: 'Vui lòng điền vào sơ đồ ios',
  hint_20428: 'Vui lòng điền thông tin',
  hint_20429: 'Số phiên bản giao diện',
  hint_20430: 'Người cấu hình',
  hint_20431: 'Không có video ngắn nào được chọn, vui lòng chọn video ngắn trước khi đăng!',
  hint_20432: 'Phát hành thành công',
  hint_20433: 'Đăng thành công, video ngắn này vào thư viện phát hành',
  hint_20434: 'Thời gian leo lên',
  hint_20435: 'Vui lòng chọn khoảng thời gian',
  hint_20436: 'Vô hiệu hóa',
  hint_20437: 'Thiết lập thành công',
  hint_20438: 'Có nên đặt đỉnh hay không',
  hint_20439: 'sắp xếp vị trí',
  hint_20440: 'Bình luận',
  hint_20441: 'bìa video ngắn',
  hint_20442: 'Chỉnh sửa đỉnh',
  hint_20443: 'Vui lòng nhập vị trí sắp xếp',
  hint_20444: 'Không thể sửa đổi sau khi phát hành, bạn có xác nhận phát hành không?',
  hint_20445: 'Truy cập kênh',
  hint_20446: 'Tên miền nguồn',
  hint_20447: 'Trợ lý nhỏ UV',
  notice_111: 'Thông báo UV',
  index_111: 'chỉ số',
  test_111: "Bạn có chắc chắn muốn {status} chủ đề này không?",
  test_112: 'Đăng chủ đề',
  test_113: 'Bạn có chắc chắn muốn xóa?',
  Batch_approval: 'phê duyệt hàng loạt',
  Reviewer_111: 'Người đánh giá',
  you_want_to_pass: 'Bạn có chắc chắn muốn vượt qua không?',
  Download_failed: 'Tải xuống không thành công',
  Individual_currency: 'Mẫu phát hành hàng loạt tiền tệ cá nhân',
  pass_all_of_them: 'Bạn có muốn vượt qua tất cả?',
  Fill_in_at_least: 'Điền vào ít nhất một trong số các loại tiền tệ bổ sung, điểm kinh nghiệm bổ sung',
  quantity_of_additional_currency: 'Vui lòng nhập số tiền bổ sung',
  money_111: 'số tiền',
  Input_cannot_be_greater: 'Đầu vào không thể lớn hơn 99999 hoặc nhỏ hơn 1',
  Recharge_status: 'Trạng thái nạp tiền',
  Gold_coins: 'đồng tiền vàng',
  Alipay_payment: 'thanh toán bằng Alipay',
  WeChat_payment: 'thanh toán qua WeChat',
  ApplePay: 'thanh toán bằng apple',
  Unpaid_111: 'Chưa thanh toán',
  Paid_111: 'Đã thanh toán',
  Balance_ranking: 'Số dư',
  ranking_111: 'Xếp hạng',
  // 新增==
  Member_Change_Record: 'Bản ghi thay đổi thành viên',
  phone_area_code: 'Vui lòng đặt mã vùng điện thoại',
  your_phone_number: 'Vui lòng đặt số điện thoại',
  Successfully_lifted: 'Giải trừ cấm ngôn thành công',
  // 新增
  Risk_categories: 'Loại rủi ro',
  No_type_selected: 'Không chọn loại',
  // 新增12
  hint2222: 'Sau khi loại bỏ quyền hạn, phòng phát sóng trực tiếp này sẽ không còn vào robot nữa, có chắc là loại bỏ không',
  hint2223: 'Sau khi vô hiệu hóa hoạt động này không được hiển thị trong ngân hàng mưa phong bì màu đỏ trong chính sách, bạn có chắc chắn vô hiệu hóa hoạt động này?',
  hint2224: 'Sau khi kích hoạt hoạt động này sẽ chỉ hiển thị trong thư viện mưa phong bì màu đỏ trong chính sách, bạn có chắc chắn để kích hoạt hoạt động này?',
  //新增
  Uploader: 'Tải lên',
  hint2225: 'Không lớn hơn 99999',
  hint2226: 'Trứng vàng 1',
  hint2227: '15 phút',
  Vest_UID: 'Áo vest',
  Operator_ID: 'ID toán tử',
  Operator_account: 'Tài khoản nhà điều hành',
  Operator_nickname: 'Biệt hiệu của nhà điều hành',
  Explanation_111: 'Giải thích: Tài khoản vest tương ứng với một tài khoản người dùng bên C thực sự ở giao diện người dùng. Hiện tại, mỗi tài khoản được giới hạn ở 10 tài khoản vest;',
  Failed_to_obtain_user_information: 'Không lấy được thông tin người dùng',
  Failed_to_obtain_token: 'Không lấy được token',
  Customer_service: 'Dịch vụ khách hàng (không khả dụng)',
  Switching_operal_accounts: 'Chuyển đổi tài khoản hoạt động',
  Quick_reply_script: 'trả lời nhanh',
  mark_27: '{num} bit của người dùng đã được trích xuất tự động',
  Update: 'Cập nhật',
  Clear: 'rõ ràng',
  Extract_users: 'Trích xuất người dùng',


  // 
  
    Edit: "Biên tập viên",
    User_Configuration: "Cấu hình người dùng",
    Please_enter_a_numerical_level: "Vui lòng nhập số cấp độ.",
    hint_4: "Dưới cấp độ này không cho phép cài đặt bao gồm biệt danh chữ cái tiếng Anh",
    Nickname_English_letter_level_limit: "Biệt danh tiếng Anh hạn chế cấp bậc chữ cái.",
    Live_broadcast_room_configuration: "Cấu hình phòng phát sóng trực tiếp.",
    hint_6: "Thấp hơn cấp bậc này thì không cho phép nói chuyện trực tiếp.",
    hint_5: "Dưới cấp độ này phòng trực tiếp trò chuyện chữ cái tiếng Anh bị thay thế *",
    Open: "Mở ra.",
    Close: "Đóng cửa.",
    Circle_Speech_Configuration: "Cấu hình phát biểu vòng tròn",
    hint_7: "Thấp hơn cấp bậc này thì không cho phép người dẫn chương trình tin nhắn riêng.",
    Speech_level_limit: "Giới hạn cấp độ phát biểu.",
    hint_8_: "Thấp hơn cấp bậc này thì không cho phép nói chuyện phiếm trong giới.",
    Posting_updates_submission_levels: "Xuất bản năng động/đóng góp xếp hạng",
    hint_9: "Dưới cấp độ này thì không được phép đăng động/đóng góp.",
    hint_911: "Phát hành động/đóng góp",
    Cancel_1: "Hủy bỏ.",
    Save_1: "Tiết kiệm.",
    Please_enter_level: "Vui lòng nhập cấp bậc.",
    APP_bottom_label: "Nhãn dưới cùng của APP",
    Initial_icon: "icon ban đầu",
    Activity_icon: "Biểu tượng hoạt động",
    World_Cup_icon: "Icon World Cup bằng tiếng Anh",
    The_bottom_marketing_position_of_the_APP: "Vị trí tiếp thị dưới cùng của APP",
    Please_enter_the_redirect_address: "Vui lòng nhập địa chỉ nhảy",
    Event_start_time: "Thời gian bắt đầu sự kiện",
    APP_homepage_my_background: "Trang đầu của APP，bối cảnh của tôi.",
    hint_10: "Kích thước： 1125 * 624，chỉ có thể tải lên tệp jpg/png",
    Operation: "Hoạt động.",
    Upload: "Tải lên.",
    Ordinary_user_currency_icon: "Đồng tiền người dùng thông thường icon",
    Size_1: "Kích thước.",
    Type: "Loại",
    Preview_image: "Biểu đồ xem trước",
    Live_settlement_currency_icon: "Phát sóng trực tiếp thanh toán tiền tệ icon",
    Brocade_Bag_Settlement_Currency_icon: "Tiền tệ thanh toán túi gấm.",
    hint_11: "Hỗ trợ định dạng： PNG，Kích thước： Trong vòng 500K",
    Android_Triple_Image: "Bản đồ Android Triple Chart",
    Top_of_homepage_top_of_activity_page: "Đầu trang，đầu trang sự kiện",
    At_the_top_of_the_competition_page_my: "Trên trang thi đấu，của tôi.",
    Signal_source_top: "Đỉnh nguồn tín hiệu",
    Please_select: "Vui lòng chọn",
    Please_upload: "Xin hãy tải lên.",
    Operation_successful: "Chiến dịch thành công",
    Can_only_upload_can_only_upload_PNG_files: "Chỉ có thể tải lên chỉ có thể tải lên tệp png",
    hint_800: "Kích thước tải lên không thể vượt quá 500K!",
    Login_address_configuration: "Cấu hình địa chỉ đăng nhập",
    Please_enter_your_login_address: "Vui lòng nhập địa chỉ đăng nhập",
    hint_12: "Cấu hình này chỉ có hiệu lực đối với đầu đẩy sử dụng hệ thống người dùng bên thương gia，sau khi cấu hình，đầu đẩy hỗ trợ nhận dạng tên người dùng và mật khẩu riêng của bên thương gia",
    Recommended_by_the_editorial_circle: "Giới thiệu vòng tròn biên tập",
    Please_enter_the_resource_name: "Vui lòng nhập tên tài nguyên",
    Circle_List_Recommendation: "Giới thiệu danh sách vòng tròn",
    Recommended_by_Square_Circle: "Giới thiệu vòng tròn quảng trường.",
    Please_enter_userid: "Vui lòng nhập userid.",
    Please_enter_the_IP_address: "Vui lòng nhập IP",
    Search: "Tìm kiếm.",
    Refresh_IP_blacklist_cache: "Làm mới bộ nhớ cache danh sách đen IP",
    Reset: "Đặt lại.",
    Loading: "Đang tải...",
    Merchant_ID_1: "Số thương gia.",
    Prohibition_time_1: "Thời gian phong cấm.",
    Remove: "Loại bỏ.",
    hint_743: "Bạn có chắc chắn muốn xóa IP này không?",
    APP_brand_name: "Tên thương hiệu APP",
    Default_username_prefix: "Tiền tố tên người dùng mặc định",
    Common_user_currency_name: "Tên tiền tệ của người dùng thông thường",
    Live_settlement_currency_name: "Live Settlement Money Name(bằng tiếng Anh)",
    The_name_of_the_settlement_currency_for_the_brocade_bag: "Tên tiền tệ thanh toán túi gấm.",
    Contact_customer_service: "Liên hệ với dịch vụ khách hàng.",
    Assistant_default_icon: "Biểu tượng mặc định của trợ lý nhỏ",
    Click_to_select_resources: "Click vào Select Resources",
    hint_13: "Chỉ có thể tải lên tệp svga，kích thước hình ảnh không thể vượt quá 800k",
    Assistant_unread_message_icon: "Biểu tượng tin nhắn chưa đọc cho trợ lý nhỏ",
    Assistant_displays_icon_size: "Trợ lý nhỏ hiển thị kích thước biểu tượng",
    Small: "Tiểu nhân.",
    Medium: "Trung.",
    Big: "Đại.",
    Assistant_display_time: "Tiểu trợ lý trình diễn thời gian.",
    Online_customer_service_address: "Địa chỉ dịch vụ khách hàng trực tuyến",
    Initialize_account: "Khởi tạo tài khoản",
    Anchor_tool_download_address: "Anchor Tool Download Address (bằng tiếng Anh)",
    ICP_Filing_Information: "Thông tin nộp hồ sơ ICP",
    Live_broadcast_room_default_message: "Tin tức mặc định trong phòng phát sóng trực tiếp.",
    Conversation_background: "Bối cảnh trò chuyện.",
    Default_official_anchor_profile_picture: "Avatar chính thức mặc định",
    Default_official_message_avatar: "Avatar thông điệp chính thức mặc định",
    Login_registration_page_background: "Đăng nhập vào trang đăng ký nền",
    hint_14: "Hỗ trợ định dạng： JPG，PNG，GIF，MP4，Kích thước： Trong vòng 3M",
    Delete: "Xóa.",
    Triple_plot: "Bản đồ gấp ba lần.",
    Double_plot: "Bản đồ gấp đôi.",
    Please_fill_in_the_brand_name: "Vui lòng điền tên thương hiệu.",
    Please_fill_in_the_prefix: "Vui lòng điền tiền tố.",
    Please_fill_in_the_currency_name: "Vui lòng điền tên tiền.",
    Please_fill_in_customer_service_WeChat: "Xin vui lòng điền vào wechat của dịch vụ khách hàng.",
    Please_fill_in_the_download_address_for_the_anchor_tool: "Vui lòng điền địa chỉ tải xuống công cụ neo",
    Please_fill_in_the_ICP_information: "Vui lòng điền thông tin ICP",
    Please_fill_in_the_default_message_for_the_live_broadcast_room: "Xin hãy điền vào thông điệp mặc định của phòng phát sóng trực tiếp.",
    Please_fill_in_the_assistant_display_time: "Xin hãy điền vào thời gian trình diễn của trợ lý nhỏ.",
    hint_15: "Tải lên kích thước sai，xin vui lòng tải lên theo yêu cầu định dạng!",
    hint_16: "Chỉ có thể upload，chỉ có thể upload file png!",
    The_upload_size_cannot_exceed: "Kích thước tải lên không thể vượt quá.",
    hint_17: "Kích thước truyền không thể vượt quá 800K!",
    Select: "Chọn.",
    Please_upload_the_edited_Excel_template_file: "Vui lòng tải lên tập tin mẫu Excel đã chỉnh sửa",
    Confirm: "Xác nhận.",
    Importing: "Nhập khẩu",
    Please_upload_the_file: "Vui lòng tải tập tin lên.",
    Only_xlsx_format_files_can_be_uploaded: "Chỉ có thể tải lên các tập tin định dạng.xlsx!",
    hint_742: "Kích thước ảnh tải lên không được vượt quá 500MB!",
    Successfully_added: "Thêm thành công",
    Import_member_failure_list: "Nhập danh sách thành viên thất bại",
    OK: "Xác định.",
    Select_file: "Chọn tập tin",
    Please_enter_the_ID: "Vui lòng nhập ID.",
    User_nickname: "Biệt danh người dùng",
    hint_740: "Vui lòng nhập nickname người dùng",
    User_phone_number: "Số điện thoại người dùng",
    hint_739: "Vui lòng nhập số điện thoại người dùng",
    User_level: "Cấp độ người dùng",
    Please_enter_user_level: "Vui lòng nhập cấp độ người dùng",
    Membership_level: "Đẳng cấp hội viên.",
    Game_users: "Người dùng trò chơi",
    Status: "Trạng thái.",
    Registration_time: "Thời gian đăng ký",
    Identity: "Thân phận.",
    User_type: "Loại người dùng",
    Inviter_userid: "Mời người dùng",
    hint_738: "Vui lòng nhập người mời userid",
    Registration_Platform: "Nền tảng đăng ký",
    hint_741: "Vui lòng nhập địa chỉ web nền tảng đăng ký",
    Batch_import_of_members: "Thành viên nhập khẩu hàng loạt",
    Download_batch_import_templates: "Tải xuống mẫu nhập khẩu hàng loạt",
    Export_data: "Xuất dữ liệu",
    Total_number_of_registered_users: "Tổng số người dùng đã đăng ký",
    Number_of_registered_users_today: "Số lượng người dùng đăng ký hôm nay",
  
    // 错误开始
    nickname: "Biệt danh.",
    Avatar: "Avatar.",
    Mobile_area_code: "Mã vùng di động.",
    Mobile_phone_number: "Số điện thoại di động.",
    Third_party_binding: "Liên kết của bên thứ ba",
    Recent_login_time: "Thời gian đăng nhập gần đây",
    Recently_logged_in_IP: "Đăng nhập IP gần đây",
    Last_visited_time: "Thời gian thăm viếng gần đây",
    Anchor: "Người dẫn chương trình.",
    Experts: "Chuyên gia.",
    Anchor_level: "Đẳng cấp dẫn chương trình.",
    Ban: "Phong cấm.",
    Enable: "Bật.",
    Detailed: "Chi tiết.",
    Prohibition: "Cấm ngôn.",
    Reset_password: "Đặt lại mật khẩu",
    Personal_information_1: "Tư liệu cá nhân.",
    Mobile_phone_number_and_area_code: "Mã vùng di động.",
    Settings: "Cài đặt.",
    Phone_number: "Số điện thoại di động.",
    Please_enter_your_phone_number: "Vui lòng nhập số điện thoại di động.",
    User_status: "Trạng thái người dùng",
    Normal_1: "Bình thường.",
    hint_737: "Có phải là người dẫn chương trình hay không?",
    hint_736: "Có phải chuyên gia túi gấm không?",
    Recommendation_code: "Mã giới thiệu.",
    Personal_signature: "Chữ ký cá nhân",
    User_UID: "UID người dùng",
    Prohibition_period_: "Chu kỳ cấm ngôn",
    Release_the_ban: "Giải trừ cấm ngôn.",
    Reasons_for_Prohibition: "Nguyên nhân cấm ngôn.",
    Please_select_the_reason_for_the_ban: "Xin mời lựa chọn nguyên nhân cấm ngôn.",
    Delete_chat_messages: "Xóa tin nhắn trò chuyện",
    Delete_all_chat_messages_in_the_live_broadcast_room: "Xóa tất cả các tin nhắn trò chuyện trực tiếp.",
    hint_735: "Có chắc là bạn muốn khóa tài khoản này không?",
    Is_IP_banned_: "Có cấm IP không?",
    Current_level: "Đẳng cấp hiện tại.",
    Operation_time: "Thời gian hoạt động",
    Operator: "Người điều hành",
    No: "Không.",
    Yes: "Vâng.",
    C_end_user_data: "Dữ liệu người dùng phía c",
    Batch_import_of_member_templates: "Nhập khẩu hàng loạt các mẫu thành viên",
    Account_has_been_banned: "Tài khoản bị khóa",
    hint_734: "Bạn có chắc chắn muốn kích hoạt tài khoản này không?",
    Prompt: "Gợi ý.",
    Account_enabled: "Tài khoản đã được kích hoạt",
    hint_733: "Xác nhận đặt lại mật khẩu người dùng? Mật khẩu ban đầu là： Aa123456",
    Password_reset_successful: "Đặt lại mật khẩu thành công",
    Password_reset_failed: "Đặt lại mật khẩu không thành công",
    Download_the_homepage_logo_: "Tải xuống logo màn hình đầu trang：",
    Click_to_select_image: "Click để chọn hình ảnh",
    hint_20: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 344X120，tối đa 1 ảnh",
    hint_21: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 288X100，tối đa 1 ảnh",
    Download_the_top_screen_background_image: "Tải xuống ảnh nền màn hình đầu trang",
    hint_22: "Bạn chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần là 375X732，tối đa 1 ảnh",
    Download_the_top_screen_copy_image: "Tải xuống bản sao màn hình đầu trang",
    hint_23: "Bạn chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 1980x1650，tối đa 1 ảnh",
    Download_page_application_introduction_image: "Tải xuống sơ đồ giới thiệu ứng dụng trang",
    hint_24: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 360X842，tối đa 4 ảnh",
    Download_page_application_introduction: "Giới thiệu ứng dụng Download Page",
    Download_page_features: "Download page features features features (Tải về trang tính năng nổi bật)",
    PWA_configuration: "Cấu hình pwa",
    Only_json_files_can_be_uploaded: "Chỉ có thể tải lên tệp.json",
    Download_floating_bar_description: "Tải xuống mô tả thanh treo",
    New_H5_logo: "Logo H5 mới",
    hint_732: "Kích thước hình ảnh được đề xuất là 40x40",
    New_H5_Download_floating_bar: "New H5 - Tải xuống thanh treo",
    hint_731: "Kích thước hình ảnh được đề xuất là 400x60",
    Youmeng_Statistics: "Thống kê Hữu Minh",
    Please_enter_the_URL_corresponding_to_src: "Vui lòng nhập URL tương ứng với src",
    hint_25: "Để đếm nhiều tên miền，hãy phân biệt bằng ||",
    Baidu_Statistics: "Baidu Statistics(bằng tiếng Anh)",
    Version_download_address: "Địa chỉ download phiên bản",
    Quick_Version: "Phiên bản nhanh",
    Please_enter_the_iOS_shortcut_address: "Vui lòng nhập địa chỉ phím tắt iOS của bạn",
    Please_enter_Android_Scheme: "Vui lòng nhập lịch Android",
    H5_Station_Download_Jump_Status: "Trạm H5 tải xuống trạng thái nhảy",
    APP_H5_Album_Page_Name: "APP/H5 album page name (bằng tiếng Anh)",
    Please_enter_a_name: "Vui lòng nhập tên",
    APP_H5_Album_Page_Link: "APP/H5 album page link (bằng tiếng Anh)",
    Please_upload_pictures: "Xin vui lòng tải lên hình ảnh.",
    Please_upload_the_PWA_configuration_file: "Vui lòng tải tập tin cấu hình pwa lên.",
    Circle_: "Vòng tròn (Circle)",
    Please_enter_the_circle_ID: "Vui lòng nhập ID vòng tròn.",
    Please_fill_in_the_Android_app_download_address: "Vui lòng điền địa chỉ tải xuống ứng dụng Android",
    Please_fill_in_the_iOS_app_download_address: "Vui lòng điền địa chỉ tải xuống ứng dụng iOS",
    Please_fill_in_the_application_introduction: "Vui lòng điền vào phần giới thiệu ứng dụng.",
    Please_fill_in_the_featured_features: "Vui lòng điền vào các tính năng đặc biệt.",
    Please_fill_in_the_description_1: "Vui lòng điền vào mô tả.",
    hint_730: "Vui lòng điền địa chỉ tải xuống ổn định cho iOS",
    Please_fill_in_the_Android_Scheme: "Vui lòng điền vào Android Scheme",
    Please_fill_in_the_iOS_Scheme: "Vui lòng điền vào lịch trình iOS",
    hint_18: "Chỉ tải lên chỉ có thể tải lên tệp jpg，jpeg hoặc png",
    Upload_up_to_1_image: "Tải lên tối đa 1 tấm.",
    hint_19: "Chỉ tải lên chỉ có thể tải lên tệp jpg，jpeg hoặc png!",
    The_upload_size_cannot_exceed_1_5M_: "Kích thước tải lên không thể vượt quá 1.5M!",
    Only_json_format_files_can_be_uploaded_: "Chỉ có thể tải lên tập tin định dạng.json!",
    The_size_cannot_exceed_1MB_: "Không được vượt quá 1MB!",
    IOS_double_plot: "Sơ đồ IOS gấp đôi",
    Protocol_identifier_ID: "ID nhận dạng giao thức",
    Protocol_identifier: "Nhận dạng giao thức",
    Protocol_Name: "Tên giao thức",
    Last_editing_date: "Ngày biên tập cuối cùng",
    Release_status: "Phát hành trạng thái",
    H5_download_page_address: "Địa chỉ trang tải xuống H5",
    Application_Introduction: "Giới thiệu ứng dụng",
    Image_and_Text_Configuration: "Cấu hình đồ họa.",
    Page_: "Tấm thứ nhất.",
    Only_jpg_png_files_can_be_uploaded: "Chỉ có thể tải lên tệp jpg/png",
    The_size_is_394_X798: "Kích thước 394X798",
    Please_enter_the_application_introduction: "Vui lòng nhập giới thiệu ứng dụng",
    Please_enter_the_address: "Vui lòng nhập địa chỉ.",
    The_title_of_the_th_page_cannot_be_empty: "Tiêu đề ${num} không thể để trống.",
    Logo_icon: "logo - biểu tượng",
    Logo_Title: "logo - tiêu đề",
    The_recommended_image_size_is_140_x70: "Kích thước hình ảnh được đề xuất là 140x70",
    Navigation_bar_activity_map: "Biểu đồ hoạt động của Navigation Bar",
    hint_307: "Kích thước： 312 * 126，chỉ có thể tải lên tệp jpg/png",
    PC_download_copy: "Bản sao tải xuống PC",
    hint_26: "Vui lòng nhập dòng đầu tiên của bản sao，không quá 10 ký tự",
    hint_27: "Vui lòng nhập dòng thứ hai của bản sao，không quá 10 ký tự",
    Page_title_: "Tiêu đề trang：",
    Please_enter_a_title: "Vui lòng nhập tiêu đề",
    Page_keywords: "Trang từ khóa",
    Page_Description: "Mô tả trang",
    Please_enter_a_description: "Vui lòng nhập mô tả",
    Please_enter_the_download_address_for_the_anchor_tool: "Vui lòng nhập địa chỉ tải xuống công cụ neo",
    H5_download_page_address_1: "Địa chỉ trang tải h5",
    Please_enter_the_h5_download_page_address: "Vui lòng nhập địa chỉ trang tải về h5",
    Anchor_Recruitment_Description: "Mô tả tuyển dụng của Anchor",
    Please_enter_the_anchor_recruitment_description: "Vui lòng nhập mô tả tuyển dụng neo.",
    Anchor_Recruitment_QR_Code: "Anchor Recruitment QR Code(bằng tiếng Anh).",
    The_recommended_image_size_is_256x256: "Kích thước hình ảnh được đề xuất là 256x256",
    Contact_email_1: "Liên lạc với hòm thư.",
    Please_enter_your_contact_email: "Vui lòng nhập hộp thư liên lạc.",
    Bottom_copyright_information: "Thông tin bản quyền dưới cùng",
    Please_enter_copyright_information: "Vui lòng nhập thông tin bản quyền",
    PC_Link_Configuration: "Cấu hình liên kết phía PC",
    Please_enter_the_PC_domain_name_link_configuration: "Vui lòng nhập cấu hình liên kết tên miền phía PC",
    Site_icon: "Biểu tượng trang web",
    Click_to_select: "Click để chọn",
    hint_28: "Chỉ có thể tải lên tập tin ico，kích thước hình ảnh cần là 32x32，kích thước không vượt quá 20kb.",
    Please_fill_in_the_statistics_link: "Vui lòng điền vào liên kết thống kê.",
    Customer_service_code_: "Mã dịch vụ khách hàng：",
    Please_fill_in_the_customer_service_code: "Vui lòng điền mã dịch vụ khách hàng.",
    PC_album_page_status: "Trạng thái trang album PC",
    PC_album_page_link: "Liên kết trang album PC",
    Please_fill_in_the_title: "Vui lòng điền vào tiêu đề.",
    Please_fill_in_the_description: "Vui lòng điền vào mô tả.",
    Please_fill_in_the_keywords: "Vui lòng điền vào các từ khóa.",
    Please_fill_in_the_record_number_document: "Xin điền vào số hồ sơ.",
    Please_fill_in_your_email_address: "Vui lòng điền vào hòm thư.",
    Please_fill_in_the_h5_download_page_address: "Vui lòng điền địa chỉ trang download h5",
    Please_select_the_Feixin_QR_code_image: "Vui lòng chọn hình ảnh mã QR của Flying Mail",
    Please_upload_the_logo_icon: "Vui lòng tải lên biểu tượng logo",
    Please_upload_the_logo_title: "Vui lòng tải lên tiêu đề logo.",
    Please_enter: "Vui lòng nhập vào.",
    Only_ICO_files_can_be_uploaded_: "Chỉ có thể tải lên tập tin ico!",
    hint_729: "Kích thước ảnh tải lên không được vượt quá 20KB!",
    Please_enter_the_theme_name: "Vui lòng nhập tên chủ đề",
    Add_a_new_theme: "Chủ đề mới",
    There_is_currently_no_content: "Tạm thời không có nội dung.",
    Last_modification_time: "Thời gian sửa đổi cuối cùng.",
    Please_enter_sorting: "Vui lòng nhập thứ tự.",
    Are_you_sure_you_want_to_delete_it_: "Có xác nhận xóa không?",
    Delete_successful: "Xóa thành công",
    Theme: "Chủ đề",
    Publish: "Phát hành.",
    Please_select_a_theme: "Vui lòng chọn chủ đề",
    Please_enter_the_content_of_the_agreement: "Vui lòng nhập nội dung thỏa thuận.",
    Please_enter_the_protocol_name: "Vui lòng nhập tên giao thức",
    hint_728: "Có xác nhận việc phát hành thông tin thỏa thuận hay không",
    hint_727: "Xác nhận việc lưu thông tin thỏa thuận",
    User_Registration_Agreement: "Thỏa thuận đăng ký người dùng",
    User_Privacy_Agreement: "Thỏa thuận bảo mật người dùng",
    Live_streaming_protocol: "Thỏa thuận phát sóng trực tiếp.",
    Payment_Agreement: "Thỏa thuận thanh toán",
    Settlement_rules: "Quy tắc thanh toán.",
    Recharge_Service_Agreement: "Thỏa thuận dịch vụ nạp tiền",
    About: "Về.",
    Please_select_the_protocol_type: "Vui lòng chọn loại giao thức",
    Login_Page_Logo: "Logo trang đích",
    hint_29: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 109X140，tối đa 1 ảnh",
    Main_interface_logo: "Logo giao diện chính",
    hint_30: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 69X67，tối đa 1 ảnh",
    About_Page_Logo: "Giới thiệu về trang Logo",
    hint_31: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 109X109，tối đa 1 ảnh",
    hint_32: "Tải lên hình ảnh kích thước sai，xin vui lòng tải lên theo định dạng yêu cầu!",
    Add_TF_package: "Thêm gói TF",
    Package_name: "Bao danh.",
    Download_link: "Liên kết tải xuống",
    Developer_account: "Tài khoản nhà phát triển.",
    Effective_date_1: "Ngày có hiệu lực",
    Expiration_date: "Ngày hết hạn.",
    Remaining_valid_days: "Số ngày còn lại có giá trị",
    Please_enter_valid_days: "Vui lòng nhập số ngày hợp lệ",
    Effective_days: "Số ngày có giá trị",
    Please_enter_the_package_name: "Vui lòng nhập tên gói",
    Edit_TF_package: "Chỉnh sửa gói TF",
    Please_fill_in_the_download_link: "Vui lòng điền vào link download.",
    Please_select_the_effective_date: "Vui lòng chọn ngày có hiệu lực",
    hint_726: "Số ngày hợp lệ phải lớn hơn hoặc bằng 90.",
    Created_successfully: "Tạo ra thành công",
    Update_method: "Cập nhật cách thức",
    System: "Hệ thống",
    Add_1: "Thêm vào",
    Update_type: "Cập nhật loại",
    Version_number: "Số phiên bản.",
    Prompt_package: "Tip Pack (bằng tiếng Anh).",
    Update_package: "Cập nhật gói",
    Reminder_date: "Nhắc nhở ngày tháng",
    Recently_edited_by: "Biên tập viên gần đây",
    Last_edited_time: "Thời gian biên tập gần đây",
    Version_update: "Cập nhật phiên bản",
    TF_Package_Update: "Cập nhật gói TF",
    Download_address: "Tải xuống địa chỉ",
    Select_prompt_package: "Chọn gói gợi ý",
    Please_fill_in_the_download_address: "Vui lòng điền địa chỉ download.",
    Select_Update_Package: "Chọn gói cập nhật",
    Remaining: "Còn lại.",
    Heaven: "Trời ạ.",
    Start_reminder_date: "Bắt đầu nhắc nhở ngày tháng.",
    Update_content: "Cập nhật nội dung",
    Please_enter_the_updated_content: "Vui lòng nhập nội dung cập nhật",
    hint_33: "Hỗ trợ định dạng： PNG，JPG，Yêu cầu kích thước： 750 * 1624",
    Edit_version_update: "Chỉnh sửa phiên bản cập nhật",
    Add_version_update: "Thêm cập nhật phiên bản",
    Please_set_an_updated_version: "Vui lòng cài đặt phiên bản mới hơn.",
    Please_set_the_prompt_version: "Vui lòng thiết lập phiên bản nhắc nhở",
    Please_select_the_system: "Vui lòng chọn hệ thống",
    Please_choose_a_method: "Vui lòng chọn phương thức.",
    Please_enter_the_content: "Vui lòng nhập nội dung",
    Please_set_the_reminder_date: "Vui lòng đặt ngày nhắc nhở.",
    hint_725: "Tải lên hình ảnh chỉ có thể ở định dạng JPG hoặc PNG!",
    hint_724: "Kích thước ảnh tải lên không được vượt quá 1MB!",
    Modified_successfully: "Sửa đổi thành công.",
    User_Management: "Quản lý người dùng",
    IP_blacklist: "Danh sách đen IP",
    Application_ID: "ID ứng dụng",
    Application_Name: "Tên ứng dụng",
    Last_modified_date: "Ngày sửa đổi cuối cùng",
    Create_an_application: "Tạo ứng dụng",
    View_Key: "Xem chìa khóa.",
    Module: "Mô-đun",
    Supplier: "Nhà cung cấp",
    Add_Module: "Thêm mô-đun",
    Reset_Key: "Đặt lại chìa khóa",
    New_Module: "Mô-đun mới",
    Please_enter_the_application_name: "Vui lòng nhập tên ứng dụng",
    Please_select_a_module: "Vui lòng chọn Module",
    Please_select_a_supplier: "Vui lòng chọn nhà cung cấp",
    Please_enter_supplier_configuration: "Vui lòng nhập cấu hình nhà cung cấp",
    Edit_application: "Ứng dụng biên tập",
    hint_34: "Nếu ứng dụng có khả năng sử dụng mô-đun này，việc xóa bản ghi có thể khiến chức năng không khả dụng. Xác nhận xem bạn có muốn xóa không?",
    Start_page: "Trang khởi động",
    Change: "Thay đổi",
    Only_JPG_or_PNG_format_images_are_allowed_to_be_uploaded: "Chỉ cho phép tải lên hình ảnh ở định dạng JPG hoặc PNG",
    Are_you_sure_you_want_to_save_the_changes_: "Xác nhận các thay đổi đã lưu",
    hint_320: "Thay đổi mật khẩu thành công，vui lòng đăng nhập lại.",
    Review_version: "Phiên bản kiểm duyệt",
    Please_enter_version: "Vui lòng nhập phiên bản.",
    Tag_number: "Đánh dấu số",
    List_Fields: "Trường danh sách",
    Form_Description: "Mô tả hình thức",
    Remarks: "Ghi chú.",
    Pre_explanation: "Ghi chú trước",
    hint_35: "1. chức năng ẩn của kiểm soát phiên bản đối với môi trường xem xét phía iOS; 2. cấp độ mã phá vỡ rõ ràng làm kiểm soát môi trường xem xét bình luận hoặc mã; 3. Android không cần kiểm soát",
    Version_control: "Kiểm soát phiên bản",
    hint_36: "1. Hiển thị số phiên bản hiện tại cần điều khiển，ví dụ： phiên bản hiện tại cần xem xét là V1.1，hộp nhập cần nhập 1.1，và đánh dấu bên dưới mô-đun tương ứng cần ẩn，sau đó nhập mô-đun được đánh dấu bên dưới trong phiên bản V1.1 được ẩn. 2. nhập số phiên bản không có phiên bản thực tế để lưu，không ảnh hưởng đến việc hiển thị bất kỳ mô-đun phiên bản nào",
    Control_module: "Mô-đun điều khiển",
    hint_317: "Hiển thị tên chức năng ẩn của môi trường review cần thiết，trạng thái đánh dấu mặc định，bỏ chọn，và sau khi lưu，mô-đun này không bị ẩn trong phiên bản này;",
    Please_fill_in_version_information: "Vui lòng điền thông tin phiên bản.",
    Select_language: "Chọn ngôn ngữ",
    General_configuration: "Cấu hình phổ quát",
    IOS_configuration: "Cấu hình IOS",
    H5_configuration: "Cấu hình H5",
    New_download_page_configuration: "Cấu hình trang tải xuống mới",
    Streaming_tool_configuration: "Cấu hình công cụ push-flow",
    PC_configuration: "Cấu hình PC",
    Mobile_streaming_configuration: "Cấu hình đẩy di động",
    Advertising_restriction_configuration: "Cấu hình giới hạn quảng cáo",
    Virtual_Number_Restrictions: "Giới hạn số ảo",
    Reporting_restrictions_for_third_party_data_apps: "Giới hạn báo cáo APP dữ liệu ba bên",
    Report_domain_name_configuration: "Báo cáo cấu hình tên miền",
    Please_enter_the_domain_name: "Vui lòng nhập tên miền",
    hint_723: "Tải xuống APP lần đầu tiên để báo cáo.",
    Channel_code_parameter_acquisition: "Thu thập thông số mã kênh",
    PC_data_reporting: "PC Data Report (bằng tiếng Anh).",
    H5_data_reporting: "Báo cáo dữ liệu H5",
    Domain_Name_Address: "Địa chỉ tên miền",
    Please_enter_the_domain_name_address: "Vui lòng nhập địa chỉ tên miền",
    Deactivate: "Ngừng hoạt động.",
    Please_select_the_type: "Vui lòng chọn loại",
    Are_you_sure_to_enable_the_domain_name_: "Xác nhận kích hoạt tên miền?",
    hint_722: "Xác nhận hủy kích hoạt tên miền?",
    Update_successful: "Update thành công",
    Recommended: "Đang tiến cử.",
    Paused: "Tạm dừng.",
    Picture_matching: "Phối đồ.",
    Source: "Nguồn gốc",
    Feedback_type: "Các loại phản hồi",
    Number: "Số thứ tự.",
    Feedback_details: "Thông tin phản hồi chi tiết",
    Contact_information: "Thông tin liên lạc.",
    Feedback_time: "Thời gian phản hồi",
    Navigation_Station_Switch: "Công tắc trạm dẫn đường",
    Page_Configuration: "Cấu hình trang",
    Weight: "Trọng lượng.",
    hint_37: "Con số càng lớn，xếp hạng càng cao.",
    Button_Name: "Tên nút",
    Domain_name: "Tên miền",
    Please_enter_the_weight: "Vui lòng nhập trọng lượng.",
    Please_enter_the_button_name: "Vui lòng nhập tên nút",
    Mobile_related: "Điện thoại di động có liên quan",
    PC_related: "Kết quả tìm kiếm cho PC-side relevant",
    Personal_information: "Thông tin cá nhân",
    Login_account: "Đăng nhập tài khoản.",
    User_Name: "Tên người dùng",
    Security_Settings: "Cài đặt an ninh",
    Change_password: "Thay đổi mật khẩu",
    Original_password: "Mật mã gốc.",
    New_password: "Mật khẩu mới",
    Confirm_new_password: "Xác nhận mật khẩu mới",
    hint_721: "Mật khẩu không thể dài ít hơn 6 chữ số",
    The_passwords_entered_twice_do_not_match: "Mật khẩu nhập hai lần không khớp.",
    Please_enter_your_password_again: "Vui lòng nhập lại mật khẩu.",
    Merchant_Basic_Information: "Thông tin cơ bản về Merchant",
    Merchant_ID: "Merchant ID (bằng tiếng Anh)",
    Enterprise_Name: "Tên doanh nghiệp",
    Contact_person: "Người liên lạc",
    Contact_email: "Hộp thư liên lạc",
    Contact_phone_number: "Điện thoại liên lạc.",
    SMS_verification_code: "Mã xác minh SMS",
    Please_enter_the_merchant_ID: "Vui lòng nhập ID người bán",
    Please_enter_the_company_name: "Vui lòng nhập tên doanh nghiệp",
    Please_enter_the_contact_person: "Vui lòng nhập liên hệ",
    Please_enter_the_contact_person_phone_number: "Vui lòng nhập số điện thoại liên lạc",
    Please_enter_the_contact_email: "Vui lòng nhập hộp thư liên lạc",
    Please_enter_the_verification_code: "Vui lòng nhập CAPTCHA",
    Get_verification_code: "Lấy CAPTCHA.",
    Please_enter_sensitive_words: "Vui lòng nhập từ nhạy cảm.",
    Batch_Add: "Thêm hàng loạt",
    Query_results: "Kết quả truy vấn",
    hint_720: "Xin lỗi，không tìm được từ nhạy cảm.",
    Activity_Name: "Tên chiến dịch",
  
    // 错误结束====
  
    hint_718: "Vui lòng nhập từ nhạy cảm và không được dài ít hơn hai ký tự.",
    hint_308: "Có chắc là xóa từ khóa đó không?",
    continue_to_be_sent_on_the_platform: "Sau khi bỏ lệnh cấm，từ này có thể tiếp tục được gửi trên nền tảng.",
    Are_you_sure_to_lift_the_ban_: "Xác nhận bỏ lệnh cấm sao?",
    The_image_size_needs_to_be_40_x40: "Hình ảnh cần có kích thước 40x40",
    Brand_name: "Tên thương hiệu",
    Main_site_name: "Tên trạm chính",
    Please_enter_the_name_of_the_main_site: "Vui lòng nhập tên trạm chính.",
    Main_site_redirect_URL: "Trang web chính nhảy url",
    Please_enter_the_main_site_redirect_URL: "Vui lòng nhập URL nhảy của trạm chính",
    H5_Main_Station_Jump_URL: "H5 Main Station nhảy url",
    Please_enter_the_H5_main_site_redirect_URL: "Vui lòng nhập URL nhảy của trạm chính H5",
    Homepage_banner: "Trang chủ banner",
    hint_717: "Kích thước hình ảnh cần là 1200x150",
    Homepage_banner_URL: "Trang chủ banner url",
    Please_enter_the_banner_URL_on_the_homepage: "Vui lòng nhập bannerUrl trang đầu tiên",
    Live_room_banner: "Kênh truyền hình trực tiếp.",
    hint_716: "Vui lòng nhập bannerUrl phòng phát sóng trực tiếp.",
    Homepage_title: "Trang đầu - title",
    Please_enter_the_title: "Vui lòng nhập title",
    Home_page_description_1: "Trang chủ - description",
    Homepage_keywords_1: "Trang chủ - keywords",
    Please_enter_keywords_1: "Vui lòng nhập keywords",
    Live_room_title: "Live Room – Tiêu đề",
    Live_broadcast_room_keywords_1: "Lời bài hát： Keywords",
    Bottom_copyright_text: "Văn bản bản quyền dưới cùng",
    Please_enter_the_copyright_text_at_the_bottom: "Vui lòng nhập văn bản bản quyền dưới cùng",
    APP_download_URL: "URL tải xuống APP",
    Please_enter_the_APP_download_URL: "Vui lòng nhập URL tải xuống APP",
    hint_715: "Hình ảnh cần có kích thước 750x94",
    H5_Live_Banner: "H5 Live Banner(bằng tiếng Anh)",
    Please_enter_h5RoomBannerRedirectUrl: "Vui lòng nhập h5RoomBannerRedirectUrl",
    Please_enter_the_brand_name: "Vui lòng nhập tên thương hiệu",
    Please_enter_the_main_site_redirect: "Vui lòng nhập trạm chính để nhảy.",
    Please_enter_H5_main_site_redirect: "Vui lòng nhập H5 Main Station Jump",
    Please_enter_the_homepage: "Vui lòng nhập trang đầu tiên.",
    Please_enter_the_homepage_banner_to_jump_to: "Vui lòng nhập banner đầu tiên để nhảy",
    Please_enter_the_homepage_description: "Vui lòng nhập mô tả trang đầu tiên",
    Please_enter_homepage_keywords: "Vui lòng nhập từ khóa trang nhất",
    Please_enter_the_live_streaming_page: "Vui lòng nhập trang phát sóng trực tiếp.",
    hint_714: "Vui lòng nhập mô tả trang trực tiếp",
    Please_enter_keywords_for_the_live_streaming_page: "Vui lòng nhập từ khóa trang phát sóng trực tiếp",
    Please_enter_the_website_copyright_statement: "Vui lòng nhập thông báo bản quyền trang web",
    Please_enter_the_app_download_URL: "Vui lòng nhập URL tải xuống ứng dụng",
    Please_enter_the_site_h5BannerRedirectUrl: "Vui lòng nhập trang web h5BannerRedirectUrl",
    Add_Site: "Thêm một trang web",
    Edit_Site: "Trang web biên tập",
    Upload_up_to_1_photo_: "Tối đa tải lên một tấm!",
    Home_page_description: "Mô tả trang đầu tiên",
    Homepage_keywords: "Trang chủ Keywords",
    Live_broadcast_room_title: "Tiêu đề phòng phát sóng trực tiếp",
    Live_broadcast_room_description: "Mô tả phòng trực tiếp",
    Live_broadcast_room_keywords: "Phòng phát sóng trực tiếp keywords",
    App_download_URL: "URL tải xuống ứng dụng",
    H5_homepage_banner_URL: "h5 trang đầu bannerurl",
    H5_homepage_banner_redirect_URL: "h5 trang đầu banner nhảy url",
    H5_live_broadcast_room_banner_URL: "h5 phòng trực tiếp bannerurl",
    H5_Live_Room_Banner_Jump_URL: "h5 phòng trực tiếp banner nhảy url",
    Live_streaming_services_1: "Dịch vụ phát sóng trực tiếp",
    SMS_service: "Dịch vụ SMS",
    Certificate_file_name: "Tên tập tin chứng nhận",
    Certificate_type: "Loại giấy chứng nhận",
    General_Supplier_Configuration: "Cấu hình nhà cung cấp chung",
    Whether_to_configure_TF_package_name: "Cấu hình tên gói TF hay không",
    Package_Name_Configuration: "Cấu hình tên gói",
    Supplier_Configuration: "Cấu hình nhà cung cấp",
    Edit_Supplier_Configuration: "Chỉnh sửa cấu hình nhà cung cấp",
    Add_supplier_configuration: "Thêm cấu hình nhà cung cấp",
    Please_fill_in_the_configuration_information: "Vui lòng điền thông tin cấu hình",
    hint_713: "Bạn có chắc chắn về việc xóa cấu hình không?",
    Please_configure_TF_configuration_correctly: "Vui lòng cấu hình cấu hình TF chính xác",
    Version_update_reminder: "Cập nhật phiên bản nhắc nhở",
    TF_Package_Management: "Quản lý gói TF",
    Batch_upload: "Tải lên hàng loạt.",
    Posting_updates: "Phát hành Dynamic",
    Account: "Tài khoản.",
    Content: "Nội dung.",
    Upload_images: "Upload hình ảnh",
    Only_jpg_jpeg_png_files_can_be_uploaded: "Chỉ có thể tải lên tệp jpg/jpeg/png",
    Up_to_9_images_can_be_uploaded: "Hỗ trợ upload tối đa 9 tấm.",
    Click_to_select_file: "Click để chọn file",
    Only_supports_video_format_files: "Chỉ hỗ trợ các tập tin định dạng video",
    Related_circles: "Vòng tròn kết nối",
    Please_select_a_circle: "Vui lòng chọn vòng tròn.",
    Related_topics: "Chủ đề liên quan.",
    Please_select_a_topic: "Vui lòng chọn chủ đề.",
    Please_upload_the_video_file: "Xin mời lên tập tin video.",
    Please_select_an_account: "Vui lòng chọn số tài khoản",
    hint_712: "Tải lên hình ảnh chỉ có thể ở định dạng JPG hoặc PNG!",
    hint_707: "Kích thước ảnh tải lên không được vượt quá 10MB!",
    hint_710: "Chỉ có thể tải lên các tập tin định dạng mp4!",
    hint_711: "Không được vượt quá 200MB!",
    Please_enter_your_nickname: "Vui lòng nhập nick name.",
    Joined_the_circle: "Đã gia nhập vòng tròn.",
    Import_time: "Nhập thời gian",
    Disabled: "Cấm dùng.",
    Add_Circle: "Thêm vòng tròn",
    Cannot_be_greater_than_80: "Không thể lớn hơn 80.",
    hint_38: "Xác nhận vô hiệu hóa người dùng? Sau khi vô hiệu hóa sẽ không thể sử dụng tài khoản để đăng động?",
    hint_39: "Xác nhận kích hoạt người dùng? Sau khi kích hoạt có thể sử dụng lại tài khoản để đăng động không?",
    Dynamic_configuration: "Cấu hình động (Dynamic Configuration)",
    Publisher_nickname: "Biệt danh của người xuất bản.",
    Accumulated_likes: "Tổng số lượt like.",
    Accumulated_number_of_comments: "Số lượng bình luận tích lũy",
    Topic: "Đề tài.",
    Circle: "Vòng tròn.",
    Release_time: "Thời gian phát hành",
    Remove_this_comment: "Hạ giá bình luận này.",
    Dynamic_permission_configuration: "Cấu hình quyền động (Dynamic Permission Configuration)",
    Publish_dynamic_user_level_configuration: "Phát hành cấu hình cấp người dùng động",
    Name: "Tên.",
    Level: "Đẳng cấp.",
    hint_703: "Mục này được cấu hình để người dùng thông thường phát hành mức độ cần thiết để đạt được động lực",
    Cannot_be_less_than_1: "Không thể nhỏ hơn 1.",
    Please_fill_in_the_user_level: "Vui lòng điền vào User Level",
    There_are_currently_no_related_posts: "Tạm thời không có bài viết liên quan.",
    hint_702: "Xác nhận muốn hạ giá bình luận bài post này sao?",
    Comment_removed_successfully: "Bình luận về việc hạ cánh thành công.",
    hint_704: "Có chắc là muốn hạ đề tài này không?",
    hint_705: "Có chắc là muốn lên đề tài này không?",
    Return: "Trở về.",
    hint_706: "Bạn không có quyền truy cập vào trang này.",
    hint_309: "Nếu có bất mãn xin liên lạc với lãnh đạo của anh.",
    Or_you_can_go: "Hoặc là anh có thể đi.",
    Go_back_to_the_homepage: "Quay lại trang đầu.",
    hint_40: "Vui lòng kiểm tra xem địa chỉ web bạn đã nhập có chính xác không，vui lòng nhấp vào nút sau để quay lại trang chủ hoặc gửi báo cáo lỗi.",
    Return_to_homepage: "Trở lại trang đầu tiên.",
    hint_41: "Gợi ý： 1 - trọng lượng là số nguyên dương，giá trị càng lớn càng cao; 2 - hiển thị front-end mặc định，ẩn thì front-end không hiển thị",
    Anchor_nickname: "Biệt danh của người dẫn chương trình.",
    Please_enter_sorting_weight: "Vui lòng nhập trọng số sắp xếp",
    Display: "Hiển thị",
    Hidden: "Ẩn núp.",
    Associated_channels_categories: "Kênh liên kết/phân loại",
    Anchor_Name_1: "Tên người dẫn chương trình.",
    Please_enter_the_anchor_name_1: "Vui lòng nhập tên neo.",
    Room_number: "Số phòng.",
    Please_enter_the_room_number: "Vui lòng nhập số phòng.",
    Source_of_flow: "Nguồn gốc của stream",
    Anchor_profile_picture: "Avatar của người dẫn chương trình.",
    Source_room_number: "Số phòng nguồn.",
    Live_streaming_categories: "Phát sóng trực tiếp.",
    Live_streaming_title: "Tiêu đề trực tiếp",
    Stream_address: "Địa chỉ Stream",
    Forbidden_broadcasting: "Cấm phát sóng.",
    Live_streaming_1: "Đang phát sóng trực tiếp.",
    Ended: "Đã kết thúc.",
    Unlocking: "Bỏ lệnh cấm.",
    All: "Toàn bộ.",
    Douyu: "Cá chọi.",
    Tiger_Fang: "Hổ Nha.",
    hint_701: "Có xác nhận bỏ lệnh cấm không?",
    hint_700: "Có xác nhận cấm phát sóng không?",
    Competition_time: "Thời gian thi đấu.",
    Competition_status: "Trạng thái thi đấu.",
    Please_enter_keywords_2: "Vui lòng nhập từ khóa",
    League_Cup_Tournament: "&quot;League/Cup&quot; (bằng tiếng Anh).",
    The_home_team: "Đội chủ nhà.",
    The_visiting_team: "Đội khách.",
    Event_ID: "ID trận đấu",
    Anchor_Qingliu_Live_Room: "Phòng phát sóng trực tiếp Thanh Lưu.",
    Associated_Channel: "Các kênh liên kết",
    Unknown_status: "Trạng thái không rõ",
    Not_started: "Chưa bắt đầu.",
    In_progress: "Đang tiến hành.",
    The_competition_has_been_postponed: "Trận đấu bị hoãn lại",
    Competition_cancelled: "Trận đấu bị hủy bỏ.",
    Direct_room_number: "Đẩy thẳng số phòng.",
    Progress_time: "Thời gian tiến hành.",
    Live_streaming_status: "Trạng thái phát sóng trực tiếp.",
    Next_broadcast: "Phát sóng.",
    Binding_successful: "Liên kết thành công",
    hint_699: "Có chắc là sẽ phát sóng trực tiếp không?",
    Anchor_sorting: "Người dẫn chương trình.",
    hint_42: "Gợi ý： 1 - trọng lượng là số nguyên dương，giá trị càng lớn càng cao; 2 - hiển thị front-end mặc định，ẩn thì front-end không hiển thị;",
    Update: "Cập nhật",
    Channel_sorting: "Sắp xếp kênh",
    Channel_nickname: "Biệt danh kênh.",
    Secondary_classification: "Phân loại cấp hai.",
    Live_streaming_type: "Thể loại live stream",
    hint_698: "Liên quan đến nhiều thứ，xin hãy sử dụng dấu phẩy tiếng Anh để phân chia.",
    Streaming_settings: "Cài đặt Push Flow",
    End_all_live_broadcasts: "Kết thúc toàn bộ truyền hình trực tiếp.",
    Signal_source: "Nguồn tín hiệu",
    Push_flow_room: "Lời bài hát： Push The Flow Room",
    Streaming_Title: "Push stream title (ấn bản 1)",
    Push_Streaming_Channel: "Push stream channel(bằng tiếng Anh)",
    There_are_currently_no_channels_available: "Hiện tại tạm thời không có kênh.",
    Streaming_address: "Địa chỉ Pushstream",
    Streaming_address_server_address_: "Push stream address (địa chỉ máy chủ)",
    Copy: "Sao chép.",
    Push_stream_code_stream_key_: "Push stream code (Chìa khóa phát trực tuyến)",
    End_Live: "Kết thúc phát sóng trực tiếp.",
    hint_321: "Nếu bạn không có kênh nào để sử dụng，vui lòng：",
    Create_channel: "Tạo một kênh",
    You_are_about_to_use_it: "Ngài sắp dùng rồi.",
    Start_broadcasting: "Tiến hành phát sóng.",
    Start_live_streaming: "Bắt đầu phát sóng trực tiếp.",
    Copy_successful: "Sao chép thành công.",
    hint_697: "Vui lòng điền đầy đủ thông tin trước khi gửi.",
    hint_696: "Có chắc là muốn kết thúc phát sóng trực tiếp không?",
    hint_695: "Có chắc là muốn kết thúc toàn bộ chương trình phát sóng trực tiếp không?",
    Choose_a_live_broadcast_room: "Chọn phòng phát sóng trực tiếp.",
    Live_streaming_room: "Phòng truyền hình trực tiếp.",
    League_name: "Tên giải đấu",
    Please_enter_the_league_name: "Vui lòng nhập tên giải đấu.",
    Popular: "Hot nhất.",
    Normal: "Bình thường.",
    The_continent_where_it_is_located: "Châu sở tại.",
    Football: "Bóng đá.",
    Basketball: "Bóng rổ.",
    Date: "Hẹn hò.",
    Start_date: "Ngày bắt đầu.",
    To: "Tới.",
    End_date: "Ngày kết thúc",
    In_the_competition: "Trong trận đấu.",
    Delay: "Trì hoãn.",
    Popular_events: "Những trận đấu nóng bỏng",
    Keywords_1: "Từ khóa.",
    Please_enter_keywords: "Vui lòng nhập từ khóa",
    Batch_editing_of_popular_content: "Batch Editing Hot (bằng tiếng Anh).",
    Batch_creation_of_reserved_events: "Tạo ra các cuộc thi có lịch hẹn với số lượng lớn",
    Country_Region: "quốc gia/khu vực",
    Current_round: "Vòng quay hiện tại",
    Edit_Appointment_Anchor: "Biên tập viên đã hẹn người dẫn chương trình.",
    Please_choose_to_create_a_schedule: "Vui lòng chọn lịch thi đấu",
    Please_select_the_associated_channel: "Vui lòng chọn kênh liên kết",
    Basketball_schedule_data: "Dữ liệu lịch thi đấu bóng rổ.",
    No_selected_items: "Không chọn giữa mục",
    Main_Station_Event_ID: "ID trận đấu tổng đài.",
    Please_enter_the_main_event_ID: "Vui lòng nhập ID trận đấu tổng đài.",
    Third_party_in_game_event_ID: "ID trận đấu của bên thứ ba",
    hint_694: "Vui lòng nhập ID trận đấu của bên thứ ba.",
    There_are_streaming_anchors: "Có một người dẫn chương trình đẩy.",
    There_is_a_push_flow_room: "Có một căn phòng đẩy.",
    Click_on_the_nickname_modification_announcement: "REFERENCES [Tên bảng tham chiếu]",
    Please_select_a_popular_event: "Vui lòng chọn giải đấu nóng",
    Edit_successful: "Biên tập viên thành công",
    E_sports_streaming: "Esports Stream (bằng tiếng Anh)",
    Entertainment_stream: "Entertainment stream (bằng tiếng Anh)",
    Create_Popular: "Tạo ra một hit",
    Official_Clearflow: "Thanh Lưu chính thức.",
    Anchor_Qingliu: "Người dẫn chương trình Thanh Lưu.",
    Score: "Điểm số.",
    The_competition_team: "Đội thi đấu.",
    Create_circles: "Tạo vòng tròn",
    Limit_1M: "Giới hạn 1M",
    hint_900: "Kích thước 790 * 215px",
    Associate_Creator: "Người sáng tạo liên kết",
    Operation_label: "Nhãn hiệu hoạt động",
    Add_tags: "Thêm thẻ mới",
    Confirm_creation: "Xác định việc tạo ra",
    Label_: "Nhãn hiệu {num}",
    Please_select_an_image: "Vui lòng chọn hình ảnh",
    Please_select_the_associated_creator: "Vui lòng chọn người tạo liên kết",
    Publisher_userid: "Nhà xuất bản userid",
    Message_content: "Nội dung tin nhắn.",
    Chat_Room: "Phòng chat.",
    hint_45: "Tin nhắn sẽ không hiển thị trong vòng kết nối đó ở cuối ứng dụng sau khi xóa，xác nhận xóa chứ?",
    Circle_Introduction: "Giới thiệu vắn tắt về vòng tròn.",
    Circle_name: "Tên của vòng tròn",
    Circle_avatar: "Avatar vòng tròn.",
    Creator_1: "Người sáng tạo",
    Number_of_people_in_the_circle: "Số vòng tròn.",
    The_maximum_number_of_people_in_the_circle: "Giới hạn số người trong vòng tròn.",
    Circle_Cover: "Lời bài hát： Circle Cover",
    Circle_dynamics_1: "Circle Dynamics (bằng tiếng Anh).",
    Circle_Announcement: "Thông báo vòng tròn.",
    Creation_time: "Thời gian tạo ra",
    Dissolve_the_circle: "Giải tán vòng tròn.",
    Dissolved: "Giải tán.",
    Submission_dynamics: "Gửi bản thảo.",
    User_userid: "Người dùng userid",
    Number_of_comments: "Số lượng bình luận",
    Like_count: "Số lượt like.",
    Preview: "Xem trước",
    Setting_up_circle_announcements: "Thiết lập thông báo vòng tròn.",
    Add_Announcement: "Thêm thông báo",
    Please_enter_the_link_name: "Vui lòng nhập tên liên kết",
    Display_link: "Hiển thị liên kết",
    Jump_link: "Nhảy liên kết",
    Please_enter_the_jump_link: "Vui lòng nhập liên kết nhảy",
    To_be_reviewed: "Chờ xét duyệt.",
    Listed: "Đã lên kệ.",
    Offline_1: "Đã hạ giá.",
    Rejected: "Đã cự tuyệt.",
    Deleted: "Đã xóa",
    hint_692: "Vui lòng điền đầy đủ thông tin liên kết thông báo trước khi gửi.",
    The_circle_has_disbanded: "Vòng tròn đã giải tán.",
    Are_you_sure_you_want_to_dissolve_this_circle_: "Có xác nhận muốn giải tán vòng tròn này không?",
    Dissolution: "Giải tán.",
    hint_44: "Bạn có xác nhận rằng bạn muốn {status} động thái của thanh không?",
    hint_693: "Có xác nhận muốn từ chối động thái này không?",
    Successfully: "Đã thành công.",
    Remove_from_shelves: "Hạ giá.",
    Listing: "Lên giá.",
    Circle_dynamics: "Số động vòng tròn",
    Creator_nickname: "Biệt danh của người sáng tạo.",
    Creator_userid: "Người tạo userid",
    In_operation: "Đang hoạt động.",
    View_details: "Xem chi tiết.",
    Chat_messages: "Tin nhắn chat.",
    Edit_anchor_information: "Chỉnh sửa thông tin neo",
    Review: "Thẩm định.",
    Anchor_Name: "Tên người dẫn chương trình.",
    Guild: "Công hội sở tại.",
    Please_select_a_guild: "Xin mời chọn công hội.",
    Split_ratio: "Phân chia tỷ lệ.",
    Please_select_the_sharing_ratio: "Vui lòng chọn chia tỷ lệ.",
    Basic_salary: "Lương cơ bản.",
    Please_enter_the_base_salary: "Vui lòng nhập mức lương cơ bản.",
    Review_details: "Xem xét chi tiết.",
    Review_details_1: "Chi tiết",
    Please_enter_the_ID_number: "Vui lòng nhập số ID.",
    Please_enter_the_host_nickname: "Xin hãy nhập nick name của người dẫn chương trình.",
    hint_43: "Bạn có chắc chắn xóa người dùng khỏi danh sách ủy quyền của nhóm không?",
    Add_channel: "Tăng kênh",
    Default_sorting: "Sắp xếp mặc định",
    Channel_ID: "ID kênh",
    Icon: "Biểu tượng.",
    Page_template: "Mẫu trang",
    Tab_name: "Tên tab",
    Channel_Type: "Loại kênh",
    hint_46: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần là 120X120",
    Please_enter_a_valid_tab_name_: "Vui lòng nhập tên tab hợp lệ!",
    Enter_tab_name: "Nhập tên tab",
    Content_ID: "Content ID (ID nội dung)",
    Please_enter_ID: "Vui lòng nhập ID.",
    Content_type: "Loại nội dung",
    Topped: "Đã đặt đỉnh.",
    Top: "Đặt đỉnh.",
    Cancel_Topping: "Hủy bỏ việc lên đỉnh.",
    Restore_Display: "Hiển thị phục hồi",
    Expiration_time: "Thời gian mất hiệu lực.",
    Select_date_and_time: "Chọn ngày giờ",
    Sealing_the_picture: "Phong Đồ.",
    real_time_info: "Thông tin.",
    Short_video: "Đoạn video ngắn",
    Dynamic: "Năng động.",
    Brocade_Bag: "Túi gấm.",
    The_expiration_time_has_not_been_set_yet: "Chưa đặt thời gian hết hiệu lực.",
    Please_select_a_future_time: "Vui lòng chọn thời gian tương lai.",
    Homepage_Content_Management: "Quản lý nội dung trang nhất",
    Top_management_on_homepage: "Quản lý trang đầu.",
    Please_enter_information_keywords: "Vui lòng nhập từ khóa thông tin.",
    Keywords: "Từ khóa.",
    Batch_publishing: "Phát hành hàng loạt",
    Batch_scheduled_release: "Batch Timed Release(bằng tiếng Anh).",
    Thumbnails: "Hình thu nhỏ",
    Title_1: "Tiêu đề.",
    Crawling_time: "Trèo lên thời gian.",
    hint_47: "Bạn có thể chọn đăng bài theo lịch trình bất cứ lúc nào trong vòng 5 phút trong tuần tới，chỉnh sửa lại và hủy bỏ trước khi đặt thời gian.",
    Timed_release: "Timed Release (bằng tiếng Anh).",
    hint_48: "Không thể sửa đổi và hủy bỏ sau khi đăng，bạn có xác nhận đăng không?",
    hint_691: "Không có thông tin nào được chọn，vui lòng chọn thông tin trước khi đăng!",
    Please_choose_a_reasonable_publishing_time: "Vui lòng chọn thời gian hợp lý để đăng bài.",
    Edit_information: "Thông tin biên tập",
    New_information: "Thông tin mới",
    Please_fill_in_the_necessary_title_and_author: "Vui lòng điền vào các tiêu đề và tác giả cần thiết.",
    Information_Title: "Tiêu đề thông tin.",
    Comment_content: "Nội dung bình luận",
    hint_49: "Sau khi xóa bình luận này sẽ không hiển thị trong ứng dụng，PC，có chắc là xóa không?",
    hint_50: "Có chắc là hủy đăng không? Sau khi hủy，thông tin sẽ được đưa vào hộp nháp.",
    hint_51: "Xác định hạ giá chứ? Di chuyển phía sau hạ giá sẽ không xuất hiện tin nhắn này!",
    Cancel_Top_Time: "Hủy bỏ thời gian đặt hàng.",
    Publishing_Library: "Thư viện phát hành",
    Top_Library: "Đặt đỉnh kho.",
    Crawling_website_titles: "Thu thập tiêu đề trang web",
    Crawling_website_links: "Thu thập liên kết đến các trang web",
    Crawling_time_period: "Thời gian leo núi.",
    Set_crawling_time_period: "Thiết lập thời gian thu thập thông tin",
    End_time: "Thời gian kết thúc.",
    Select_a_time_range: "Chọn khung thời gian",
    hint_52: "Sau khi vô hiệu hóa thì không thu thập dữ liệu của trang web này，có xác nhận vô hiệu hóa không?",
    hint_690: "Bạn có chắc muốn kích hoạt cấu hình này không?",
    hint_689: "Bạn có chắc chắn muốn xóa thông tin này không?",
    Prize_Name: "Tên giải thưởng",
    Please_enter_the_name_of_the_prize: "Vui lòng nhập tên giải thưởng.",
    Prize_type: "Các loại giải thưởng",
    Query: "Truy vấn.",
    Add_prizes_1: "Giải thưởng mới",
    Prize_icon: "Giải thưởng icon",
    Please_enter_the_number_of_prizes: "Vui lòng nhập số lượng giải thưởng.",
    quantity: "Số lượng.",
    Only_jpg_png_gif_files_can_be_uploaded: "Chỉ có thể tải lên tệp jpg/png/gif",
    Modify: "Sửa đổi.",
    New: "Mới thêm",
    Virtual_currency: "Tiền ảo",
    Experience_value: "Điểm kinh nghiệm.",
    hint_53: "Tải lên hình ảnh chỉ có thể ở định dạng JPG hoặc PNG hoặc GIF!",
    Winning_number: "Số trúng thưởng.",
    Please_enter_the_winning_number: "Vui lòng nhập số trúng thưởng.",
    UID: "UID",
    Please_enter_UID: "Vui lòng nhập UID.",
    Obtain_time: "Lấy thời gian",
    Export: "Xuất khẩu",
    hint_688: "Phòng phát sóng trực tiếp đập trứng vàng - kỷ lục giải thưởng",
    Basic_Settings: "Cài đặt cơ bản",
    Red_envelope_rain_name: "Tên của mưa hồng bao.",
    Edit_Red_Envelope_Rain_Library: "Biên tập viên ngân hàng mưa phong bì đỏ",
    New_Red_Envelope_Rain_Warehouse: "Kho mưa tiền lì xì mới xây",
    Please_enter_the_name_of_the_red_envelope_rain: "Vui lòng nhập tên mưa hồng bao",
    Trigger_type: "Loại kích hoạt",
    Goal_scoring_events: "Sự kiện ghi bàn",
    Event_timeline: "Thời gian thi đấu.",
    Specify_a_time_point: "Xác định thời điểm",
    Maximum_number_of_red_envelope_rain_wheels: "Giới hạn số vòng mưa hồng bao.",
    Please_enter_the_number_of_rounds: "Vui lòng nhập số vòng.",
    hint_54: "0 là không có mưa tiền lì xì，đây là giới hạn số vòng mưa tiền lì xì nhiều nhất trong một cuộc thi.",
    hint_687: "Số lượng bao lì xì trung bình tối đa (một vòng người dùng duy nhất)",
    hint_55: "0 vì không cướp được tiền lì xì，sau khi đếm đến tiền lì xì sẽ không cướp được tiền lì xì nữa.",
    Fixed_point_in_time: "Một thời điểm cố định.",
    Select_date: "Chọn ngày",
    Probability_of_winning_a_prize_red_envelope: "Xác suất rút trúng bao lì xì có thưởng.",
    Please_enter_the_probability: "Vui lòng nhập xác suất.",
    hint_56: "0 là không thể rút trúng bao lì xì，đây là xác suất người dùng rút trúng bao lì xì có thưởng.",
    hint_686: "Vui lòng nhập giới hạn số vòng mưa trong bao lì xì.",
    Award_Pool_Settings: "Thiết lập hồ bơi giải thưởng",
    Add_prizes: "Thêm giải thưởng",
    Number_of_individual_red_envelope_rewards: "Thưởng một bao lì xì.",
    Please_enter_the_number: "Vui lòng nhập số.",
    Number_of_red_envelopes: "Số lượng phong bì màu đỏ",
    Please_enter_the_quantity: "Vui lòng nhập số lượng.",
    Prize_weight: "Trọng lượng giải thưởng.",
    Please_fill_in_the_fixed_time_point: "Vui lòng điền vào thời gian cố định.",
    Please_select_a_time_point_for_the_competition: "Vui lòng chọn thời điểm thi đấu.",
    hint_685: "Chỉ có thể cấu hình các số nguyên dương từ 0 đến 100",
    Please_add_at_least_one_prize: "Vui lòng thêm ít nhất một phần thưởng.",
    hint_684: "Vui lòng điền đầy đủ thông tin giải thưởng trước khi gửi.",
    hint_57: "Nhập sai，một bao lì xì thưởng số lượng，số lượng bao lì xì chỉ có thể cấu hình 1 - 99999 số nguyên dương",
    Add_has_reached_the_limit: "Việc bổ sung đã đạt đến giới hạn trên",
    Golden_Egg_Switch: "Công tắc trứng vàng.",
    Basic_Information_Configuration: "Cấu hình thông tin cơ bản",
    APP_background_image: "Hình nền ứng dụng",
    APP_Title_Image: "Biểu đồ tiêu đề APP",
    The_total_probability_must_be_10000: "Tổng xác suất phải là 10.000.",
    Red_envelope_rain_storage_details: "Chi tiết kho mưa phong bì đỏ",
    hint_58: "0 là không có mưa tiền lì xì，đây là giới hạn số vòng mưa tiền lì xì nhiều nhất trong một cuộc thi.",
    Wheel: "Bánh xe.",
    Pieces: "Cái.",
    Task_type: "Loại nhiệm vụ",
    Reward_Name: "Tên phần thưởng",
    Task_Title: "Tiêu đề nhiệm vụ.",
    Task_icon: "Biểu tượng nhiệm vụ",
    Task_count: "Số nhiệm vụ.",
    Application_channels: "Kênh ứng dụng",
    Edit_task: "Nhiệm vụ biên tập",
    Reward_type: "Các loại phần thưởng",
    Reward_quantity: "Số lượng phần thưởng",
    Please_enter_the_number_of_rewards: "Vui lòng nhập số tiền thưởng.",
    Please_select_the_reward_type_icon: "Vui lòng chọn loại hình thưởng icon",
    Please_select_the_application_channel: "Vui lòng chọn kênh ứng dụng",
    Task_title_cannot_be_empty: "Tiêu đề nhiệm vụ không được để trống.",
    Task_count_cannot_be_empty: "Số lượng nhiệm vụ không thể để trống.",
    To_be_effective: "Đợi có hiệu lực.",
    In_effect: "Đang có hiệu lực.",
    Daily_tasks: "Nhiệm vụ hàng ngày",
    Novice_Task: "Nhiệm vụ tân thủ.",
    Sign_in_task: "Ký nhận nhiệm vụ.",
    Attendance_Record: "Ký vào hồ sơ.",
    Please_enter_the_task_title: "Vui lòng nhập tiêu đề nhiệm vụ.",
    Check_in_switch: "Check-in-the-switch (bằng tiếng Anh).",
    No_1: "Thứ nhất.",
    Number_of_check_in_days: "Ký tên vào số ngày.",
    Reward_details: "Phần thưởng chi tiết.",
    Reward_icon: "Biểu tượng khen thưởng",
    Edit_check_in: "Biên tập viên đã ký.",
    Lottery: "Tiền màu.",
    Physical_object: "Vật chất.",
    Please_upload_the_reward_type_icon: "Vui lòng tải lên biểu tượng phần thưởng",
    Sign_in_has_been_closed: "Đã đóng cửa.",
    Check_in_has_been_opened: "Đã mở，đã ký.",
    Please_fill_in_the_quantity: "Vui lòng điền vào số lượng.",
    Please_enter_the_room_number_for_sending: "Vui lòng nhập số phòng đã gửi.",
    Send_room_number: "Gửi số phòng đi.",
    Sending_time: "Gửi thời gian",
    Details_of_receiving_red_envelopes: "Phong bì đỏ nhận chi tiết.",
    hint_683: "Tạm thời không có tiền lì xì để nhận chi tiết.",
    Anchor_room_number: "Số phòng dẫn chương trình.",
    Please_enter_the_host_room_number: "Vui lòng nhập số phòng phát thanh viên.",
    Remove_permissions: "Xóa quyền truy cập",
    Add_anchor: "Thêm một neo.",
    Time: "Thời gian.",
    Anchor_userID: "Người dẫn chương trình userID",
    Please_enter_the_anchor_userID: "Vui lòng nhập mã người dùng neo",
    Betting_amount: "Số tiền đặt cược",
    Prophecy_betting_list: "Danh sách cá cược tiên tri",
    Please_enter_the_name_of_the_main_team: "Vui lòng nhập tên đội chủ nhà.",
    Please_enter_the_name_of_the_visiting_team: "Vui lòng nhập tên đội khách.",
    Please_enter_the_status: "Vui lòng nhập trạng thái",
    Team_PK_ID: "Đội PK ID.",
    The_winner: "Bên thắng.",
    Number_of_gift_givers_1: "Số người tặng quà.",
    Total_value_in_gold_coins: "Tổng giá trị (tiền vàng)",
    The_losing_side: "Bên bại trận.",
    Binding_events: "Giải đấu bị ràng buộc",
    Please_enter_the_binding_event: "Vui lòng nhập giải đấu ràng buộc.",
    Bind_anchor: "Người dẫn chương trình bị trói.",
    Please_enter_the_bound_anchor: "Vui lòng nhập neo liên kết.",
    Activity_Strategy_Name: "Tên chiến dịch chính sách",
    hint_682: "Vui lòng nhập tên chính sách hoạt động",
    Red_envelope_rain_claim_details: "Hồng bao vũ lĩnh chi tiết.",
    Effective_anchor_room_details: "Chi tiết về phòng phát thanh viên có hiệu lực.",
    To_be_published: "Được xuất bản",
    Published: "Đã xuất bản",
    Canceled: " Đã hủy bỏ.",
    hint_681: "Xác nhận hủy đăng bài hay không",
    Main_switch: " Tổng công tắc.",
    Policy_Name: "Tên chính sách",
    Please_enter_a_policy_name: "Vui lòng nhập tên chính sách",
    Add_Policy: "Thêm chính sách",
    Red_envelope_rain_storage: "Hồng bao vũ khố.",
    Red_envelope_rain_type: "Phong bì màu đỏ loại mưa",
    Event_red_envelope_rain: "Cuộc thi Mưa hồng bao",
    Live_broadcast_room_chat_room: "Phòng phát sóng trực tiếp.",
    Event_type: "Thể loại sự kiện",
    Distribute_users: "Người dùng gửi xuống.",
    hint_60: "Vui lòng nhập UID người dùng，nhiều UID được ngăn cách bằng dấu phẩy tiếng Anh",
    Please_select_the_type_of_red_envelope_rain: "Vui lòng chọn loại mưa phong bì màu đỏ",
    Please_select_an_event: "Vui lòng chọn giải đấu",
    Please_select_the_red_envelope_rain_storage: "Xin mời chọn kho mưa hồng bao.",
    Edit_Policy: "Chiến lược biên tập",
    Strategy_details: "Chi tiết chiến lược",
    hint_61: "Offline chiến lược chiến dịch này sẽ dẫn đến chiến dịch offline từ đầu phía trước，hãy chắc chắn rằng chiến dịch offline.",
    Offline_2: "logout.",
    Live_room_room_number: "Số phòng truyền hình trực tiếp.",
    hint_680: "Xin vui lòng nhập số phòng trực tiếp.",
    Configuration_Management: "Quản lý cấu hình",
    Prize_configuration: "Cấu hình giải thưởng",
    Prize_Record: "Kỷ lục giải thưởng",
    Anchor_cumulative_share: "Người dẫn chương trình chia đều.",
    Option_A_cumulative_betting: "Lựa chọn A đặt cược tích lũy",
    Option_A: "Lựa chọn A",
    Option_B: "Lựa chọn B",
    Option_B_cumulative_betting: "Cược tích lũy tùy chọn B",
    Results: "Kết quả.",
    Number_of_bets: "Số người đặt cược",
    Anchor_sharing_3: "Phát thanh viên (3%)",
    Platform_share_2: "Phân chia nền tảng (2%)",
    The_anchor_prophecy: "Lời tiên tri của người dẫn chương trình.",
    The_prophecy_of_the_whole_nation: "Lời tiên tri toàn dân",
    Login_time: "Thời gian đăng nhập",
    Online_duration: "Thời gian online dài",
    Inviters_nickname: "Biệt danh của người mời：",
    Invitee_UID_: "UID được mời：",
    Invitation_time: "Thời gian mời",
    Inviter_payment_method: "Cách mời người nhận tiền",
    Alipay: "Alipay.",
    WeChat_account: "Vi tín hiệu.",
    QQ_account: "Tài khoản QQ.",
    Inviting_person_rewards: "Mời người khen thưởng.",
    Distributed: "Đã gửi đi.",
    Not_passed: "Không thông qua.",
    Accumulated: "Đang tích lũy.",
    Invitee_nickname: "Biệt danh của người được mời.",
    Invitee_rewards: "Thưởng cho người được mời",
    Select_distribution_type: "Chọn loại phân phối",
    Please_enter_a_rule_description: "Vui lòng nhập mô tả quy tắc.",
    Are_you_sure_to_cancel_the_distribution_: "Xác nhận hủy bỏ giao hàng sao?",
    APP_user_login_statistics: "Thống kê đăng nhập người dùng APP",
    hint_676: "Phần thưởng của người mời không được nhỏ hơn 0.",
    hint_677: "Phần thưởng của người mời không được lớn hơn 99999",
    hint_675: "Phần thưởng của người được mời không được nhỏ hơn 0.",
    hint_678: "Phần thưởng của người được mời không được lớn hơn 99999.",
    Room_type: "Loại phòng",
    The_ban_was_successful: "Cấm ngôn thành công.",
    hint_62: "Sau khi xóa，tin nhắn này sẽ không hiển thị trong phòng phát trực tiếp/phòng chat này ở phía ứng dụng，phía PC，phía H5，có chắc là nó bị ẩn không?",
    Please_enter_the_user_UID: "Vui lòng nhập UID người dùng",
    Prohibition_time: "Thời gian cấm ngôn.",
    Forbidden_live_broadcast_room: "Phòng phát sóng trực tiếp cấm ngôn.",
    Prohibition_type: "Thể loại cấm ngôn",
    Prohibition_reasons: "Lý do cấm ngôn.",
    Operator_UID: "Người vận hành UID",
    Successfully_unblocked: "Bỏ lệnh cấm thành công.",
    Opening_duration: "Thời gian phát sóng dài",
    The_duration_of_the_current_live_broadcast: "Thời gian phát sóng trực tiếp hiện tại",
    Current_number_of_views: "Số lượt xem hiện tại",
    hint_674: "Lời bài hát： Current Live Views (De Heavy)",
    Current_number_of_online_users: "Số người online hiện tại",
    hint_673: "Số người xem trực tuyến hiện đang phát sóng trực tiếp (giảm cân)",
    The_highest_number_of_online_users: "Số người online cao nhất",
    hint_672: "Số người trực tuyến cao nhất hiện đang phát sóng trực tiếp (giảm cân)",
    hint_63: "Số liệu thống kê bao gồm người dùng chưa đăng nhập，người dùng chưa đăng nhập không giảm trọng lượng.",
    Field_Description: "Field Description(bằng tiếng Anh)",
    Anchor_UID: "Người dẫn chương trình UID.",
    Live_Channel: "Kênh trực tiếp.",
    Start_time_1: "Thời gian phát sóng.",
    Downtime: "Thời gian phát sóng.",
    Opening_duration_minutes_: "Thời gian phát sóng (phút)",
    Accumulated_views: "Tích lũy lượt xem",
    Accumulated_number_of_online_users: "Số lượng người online tích lũy",
    Average_viewing_time_minutes_: "Thời gian xem trung bình (phút)",
    The_entire_live_broadcast_data: "Toàn bộ dữ liệu trực tiếp.",
    Edit_data: "Biên tập tài liệu",
    Add_announcement_link: "Thêm liên kết thông báo",
    Announcement_link: "Liên kết thông báo",
    Please_enter_the_display_link: "Vui lòng nhập liên kết hiển thị",
    Recommended_display: "Đề nghị trưng bày",
    Browser_Open: "Mở trình duyệt",
    Display_end: "Hiển thị kết thúc",
    Mobile_end: "Kết thúc di động",
    PC_end: "Kết thúc PC",
    Application_time: "Thời gian nộp đơn.",
    Global_announcement_adjustment: "Điều chỉnh thông báo toàn cầu",
    Sports_type: "Thể thao thể thao",
    On_the_front_of_the_ID_card: "Mặt trước của thẻ căn cước.",
    On_the_back_of_the_ID_card: "Trái ngược với thẻ căn cước.",
    Holding_an_ID_card: "Cầm chứng minh thư trong tay.",
    Reason_for_rejection: "Lý do từ chối",
    Lifting_the_ban: "Giải trừ phong cấm",
    Refused_to_pass: "Từ chối thông qua.",
    Please_enter_the_anchor_name: "Vui lòng nhập tên người dẫn chương trình.",
    ID_number: "Số chứng minh thư.",
    Front_photo_of_ID_card: "Ảnh chính diện chứng minh thư.",
    Back_photo_of_ID_card: "Ảnh trái của thẻ căn cước.",
    Housing_Management_Uid: "Quản lý bất động sản.",
    Please_enter_the_housing_management_Uid: "Vui lòng nhập UID của quản lý bất động sản.",
    Housing_management_nickname: "Biệt danh quản lý bất động sản.",
    hint_671: "Xin hãy nhập nick name của quản lý nhà đất.",
    Add_property_management: "Thêm bất động sản.",
    Cancel_housing_management: "Hủy bỏ quản lý nhà đất.",
    Please_enter_your_name: "Vui lòng nhập tên",
    hint_670: "Tên，xin hãy nhập chữ Hán.",
    Approved: "Thông qua xét duyệt.",
    Rejected_for_review: "Xét duyệt cự tuyệt.",
    Prohibited: "Đã phong cấm.",
    The_ID_photo_is_blurry: "Giấy tờ mơ hồ.",
    The_ID_number_does_not_match_the_ID_photo: "Số chứng minh thư không khớp với ảnh chứng minh thư.",
    My_ID_card_does_not_match_me: "Chứng minh thư không khớp với chính mình.",
    ID_card_number_is_blocked: "Số chứng minh thư bị chặn.",
    Facial_occlusion: "Che mặt người.",
    Room: "Phòng.",
    Please_select_the_reason: "Vui lòng chọn lý do.",
    hint_669: "Vui lòng nhập tên hoặc liên kết đến thông báo mà bạn muốn thay thế",
    Are_you_sure_you_want_to_pass_the_review_: "Xác định muốn xét duyệt thông qua sao?",
    hint_301: "Xác định khôi phục quyền phát sóng trực tiếp của người dẫn chương trình chứ?",
    Are_you_sure_you_want_to_ban_this_anchor_: "Xác định phong cấm người dẫn chương trình này sao?",
    Main_account_UID: "Tài khoản chính UID",
    Please_enter_the_main_account_UID: "Vui lòng nhập UID tài khoản chính.",
    Merge_account_UID: "Hợp nhất tài khoản UID",
    Please_enter_the_merged_account_UID: "Vui lòng nhập UID tài khoản hợp nhất.",
    Add_Merge: "Hợp nhất mới",
    Master_account_nickname: "Biệt danh tài khoản chính.",
    Merge_account_nicknames: "Mật danh tài khoản hợp nhất.",
    Merge_time: "Thời gian hợp nhất.",
    hint_64: "Sau khi hợp nhất tài khoản，được hợp nhất phát sóng，sẽ sử dụng tài khoản chính để phát sóng.",
    Merge_accounts: "Hợp nhất tài khoản.",
    Refresh: "Làm mới lại.",
    hint_668: "Lưu ý： Dữ liệu trực tuyến bị chậm trễ một chút",
    Note: "Đặt cược.",
    Basic_information_1: "Thông tin cơ bản",
    Anchor_Information: "Thông tin neo",
    Real_time_live_streaming_data: "Live streaming data (bằng tiếng Anh).",
    hint_667: "Dòng thông tin hiện tại không có sẵn!",
    Anchor_Management: "Quản lý neo.",
    Merge_anchor_accounts: "Hợp nhất tài khoản chủ trì.",
    Identify_the_main_switch: "Phân biệt tổng công tắc.",
    The_current_status_is: "Trạng thái hiện tại là",
    There_is_currently_no_data_available: "Tạm thời không có số liệu.",
    Jian_Huang: "Giám Hoàng.",
    Fear_prevention: "Giám định khủng bố.",
    Jianzheng: "Giám chính.",
    hint_303: "Có chắc là muốn cắt đứt đoạn phát sóng trực tiếp này không?",
    Thermal_value_coefficient: "Hệ số giá trị nhiệt.",
    Heat_value_base: "Cơ sở giá trị nhiệt",
    Modified_by: " Sửa đổi người.",
    Latest_modification_time: "Thời gian sửa đổi mới nhất.",
    Heat_value_setting: "Thiết lập giá trị nhiệt",
    Role_Name: "Tên nhân vật",
    Whether_to_enable: "Bật hay không bật",
    Live_broadcast_room_type: "Loại phòng trực tiếp",
    Initial_heat_value: "Giá trị nhiệt ban đầu",
    Last_editing_time: "Thời gian biên tập cuối cùng",
    Edit_heat_value: "Chỉnh sửa giá trị nhiệt",
    Initial_heating_value_: "Giá trị nhiệt ban đầu：",
    Please_enter_the_heat_value: "Vui lòng nhập giá trị nhiệt.",
    Please_fill_in_the_heat_value: "Vui lòng điền vào giá trị nhiệt.",
    Please_enter_the_anchor_UID: "Vui lòng nhập giao diện người dẫn chương trình",
    Live_streaming_services: "Live streaming service (bằng tiếng Anh).",
    Batch_editing: "Chỉnh sửa hàng loạt",
    All_anchors: "Toàn bộ người dẫn chương trình.",
    Platform_anchor_stream: "Platform Anchor Stream (bằng tiếng Anh).",
    Event_clearance: "Giải đấu Thanh Lưu.",
    Anchor_event_flow: "Dẫn chương trình phát sóng sự kiện",
    Esports_Entertainment_Stream: "Esports Entertainment Stream (bằng tiếng Anh).",
    Live_streaming_2: "Đang phát sóng trực tiếp.",
    Field: "Trường.",
    hint_665: "Có chắc là muốn phát sóng trực tiếp không?",
    hint_664: "Có chắc là muốn cấm phát sóng trực tiếp không?",
    This_PV: "PV này.",
    UV_in_this_field: "UV ở đây.",
    Peak_value: "Đỉnh núi.",
    Accumulated_viewing_time_minutes_: "Thời gian xem tích lũy (phút)",
    Total_number_of_followers: "Tổng số chú ý.",
    Number_of_followers_in_this_session: "Số lượng người quan tâm.",
    The_number_of_levels_taken_in_this_game: "Trận này lấy số cửa ải.",
    Net_increase_in_levels_in_this_game: "Trận này tịnh tăng quan số",
    Live_broadcast_session_data_of_anchors: "Dữ liệu phát sóng trực tiếp.",
    Forbidden_List: "Danh sách cấm ngôn.",
    Team: "Đội bóng.",
    Please_enter_the_team: "Vui lòng nhập đội.",
    Push_order_status: "Trạng thái Push",
    hint_306: "Xác nhận rằng bạn muốn ẩn đẩy đó? Ẩn mặt trước phía sau là vô hình",
    hint_305: "Xác nhận rằng bạn muốn hiển thị đẩy đó? Hiển thị mặt trước phía sau có thể nhìn thấy",
    Please_select_the_correction_result: "Vui lòng chọn kết quả sửa chữa",
    Reporting_time: "Thời gian tố cáo.",
    Whistleblower_userid: "Người tố giác người dùng",
    Enter_the_whistleblower_userid: "Nhập người tố cáo người dùng",
    Enter_room_number: "Nhập số phòng",
    Reply_or_not: "Có trả lời hay không.",
    Reason_for_reporting: " Nguyên nhân tố cáo.",
    Reply_content: "Trả lời nội dung",
    hint_663: "Vui lòng nhập nội dung trả lời (trong vòng 100 từ)",
    The_reply_content_cannot_be_empty: "Nội dung trả lời không thể để trống",
    Homepage_Configuration: "Cấu hình trang đầu",
    Level_animation_switch: "Công tắc hoạt hình cấp bậc",
    Cat_coin_animation_switch: "Công tắc hoạt hình Cat Coin",
    Enter_animation_switch_: "Công tắc hoạt hình tiếp cận：",
    APP_membership_background: "Bối cảnh thành viên APP",
    Membership_Benefits_Background: "Bối cảnh quyền lợi thành viên.",
    APP_H5_Membership_Benefits_Background: "Bối cảnh quyền lợi của thành viên APP/H5",
    Background_of_PC_Membership_Benefits: "Nền tảng về quyền lợi của thành viên PC",
    hint_409: "Lưu ý： Kích thước là 926x126",
    VIP_discount_configuration: "Cấu hình giảm giá VIP",
    Please_enter_VIP: "Vui lòng nhập VIP.",
    hint_402: "Vui lòng nhập giảm giá vip (ví dụ： 0.9)",
    hint_403: "Vui lòng nhập tên giảm giá vip (ví dụ： giảm giá 9%)",
    Edit_Channel: "Kênh biên tập",
    Add_a_new_channel: "Thêm kênh mới",
    Channel_avatar: "Avatar kênh.",
    hint_401: "Kích thước： 114 * 114 chỉ có thể tải lên tệp jpg/png",
    Channel_Cover: "Bìa kênh",
    hint_405: "Kích thước： 522 * 294 chỉ có thể tải lên tệp jpg/png",
    hint_406: "Gợi ý： Tự động chặn màn hình luồng và sử dụng bìa mặc định sau khi thất bại",
    hint_407: " Xác định khôi phục quyền phát sóng trực tiếp kênh này chứ?",
    hint_408: "Anh có chắc là mình sẽ chặn kênh này không?",
    Schedule_data: "Dữ liệu lịch thi đấu.",
    Edit_Announcement: "Thông báo biên tập",
    Create_Announcement: "Tạo một thông báo",
    Announcement_type: "Các loại thông báo",
    Image_and_text_links: "Liên kết text",
    Picture_and_text_messages: "Tin tức hình ảnh.",
    Activity_link: "Liên kết hoạt động",
    Announcement_Title: "Tiêu đề thông báo.",
    Cover_image: "Ảnh bìa.",
    Whether_to_push: "Có đẩy hay không.",
    Select_content: "Chọn nội dung",
    Activity_Title: "Tiêu đề chiến dịch",
    Event_Introduction: "Giới thiệu sự kiện",
    Please_select_the_announcement_type: "Vui lòng chọn loại thông báo",
    Please_select_the_publishing_time: "Vui lòng chọn thời gian phát hành.",
    Please_enter_the_announcement_title: "Vui lòng nhập tiêu đề thông báo",
    Please_enter_the_activity_title: "Vui lòng nhập tiêu đề chiến dịch",
    Please_enter_the_announcement_content: "Vui lòng nhập nội dung thông báo.",
    Please_fill_in_the_activity_introduction: " Vui lòng điền vào hồ sơ hoạt động.",
    Please_enter_the_text_message_content: "Vui lòng nhập nội dung tin nhắn SMS",
    Send_to: "Gửi đối tượng",
    Upload_data: "Tải lên dữ liệu",
    Download_List_Excel_Template: "Tải xuống danh sách mẫu Excel",
    Receive_user_list: "Nhận danh sách người dùng",
    All_platform_users: "Người dùng toàn nền tảng",
    Not_sent: "Không gửi đi",
    Sent: "Đã gửi",
    Cancel_sending: "Hủy gửi",
    Specify_user: "Chỉ định người dùng",
    Please_enter_the_theme: "Vui lòng nhập chủ đề",
    Multiple_separated_by_commas: "Nhiều ngăn cách bằng dấu phẩy",
    Template_ID: "Mẫu ID",
    Instant_sending: "Gửi ngay lập tức",
    Please_select_template_ID: "Vui lòng chọn mẫu ID",
    Are_you_sure_to_send_to_all_users_: "Đã xác nhận gửi cho tất cả người dùng chưa?",
    Sending_successful: "Gửi thành công",
    Channel_template_ID: "Mã mẫu bên kênh",
    Please_enter_the_channel_template_ID: "Vui lòng nhập ID mẫu bên kênh",
    Template_content: "Nội dung mẫu",
    Please_enter_template_content: "Vui lòng nhập nội dung mẫu",
    Please_select_a_channel: "Vui lòng chọn kênh",
    Please_enter_your_signature: "Vui lòng nhập chữ ký.",
    Alibaba_Cloud: "A Lý Vân.",
    Notification: "Thông báo.",
    Marketing: "Tiếp thị.",
    Verification_code: "CAPTCHA (bằng tiếng Anh).",
    First_select_the_SMS_type: "Chọn loại tin nhắn SMS đầu tiên",
    Please_fill_in_the_channel_template_ID: "Vui lòng điền vào mẫu ID của bên kênh",
    Message_type: "Loại tin nhắn",
    New_announcement: "Thông báo mới",
    Official_announcement_title: "Tiêu đề thông báo chính thức",
    Unpublish: "Hủy xuất bản",
    Jump_type: "Loại nhảy",
    Push_time: "Đẩy thời gian.",
    Please_enter_the_bag_ID: "Vui lòng nhập ID túi gấm.",
    Decimal_cannot_be_entered: "Không thể nhập số thập phân.",
    Please_enter_the_H5_link: "Vui lòng nhập liên kết H5",
    Push_details: "Gửi thông tin chi tiết.",
    Edit_push: "Biên tập viên Push",
    Add_push: "Push mới",
    Push_order_page: "Push the Single Page (bằng tiếng Anh).",
    Please_select_the_push_time: "Vui lòng chọn thời gian đẩy",
    Please_select_the_effective_time: "Vui lòng chọn thời gian có hiệu lực.",
    hint_660: "Xác định hủy bỏ nhiệm vụ đẩy đi lần này sao?",
    Add_menu: "Thực đơn mới",
    catalogue: "Thư mục.",
    Menu: "Thực đơn",
    Menu_icons: "Biểu tượng menu",
    Menu_Title: "Tiêu đề menu",
    Routing_address: "Địa chỉ định tuyến",
    Component_Name: "Tên thành phần",
    Component_path: "Đường dẫn thành phần",
    Whether_to_hide: "Có giấu hay không.",
    Superior_categories: "Danh mục cấp trên",
    Select_superior_category: "Chọn mục cấp cao hơn",
    Menu_Name: "Tên thực đơn",
    Permission_identification: "Xác định quyền hạn",
    Edit_menu: "Chỉnh sửa menu",
    Please_enter_the_menu_title: "Vui lòng nhập tiêu đề menu",
    Please_enter_the_routing_address: "Vui lòng nhập địa chỉ định tuyến",
    Please_enter_the_component_name: "Vui lòng nhập tên thành phần",
    Please_enter_the_component_path: "Vui lòng nhập đường dẫn thành phần",
    Please_select_the_superior_category: "Vui lòng chọn mục cấp trên",
    Top_level_categories: "Top-level category (bằng tiếng Anh).",
    hint_661: "Bạn có chắc chắn muốn xóa menu này không?",
    Please_enter_the_role_name: "Vui lòng nhập tên nhân vật",
    Add_new_role: "Thêm nhân vật mới",
    Role_ID: "ID nhân vật",
    Creator: "Người sáng tạo",
    Authorization: "ủy quyền",
    Edit_Role: "Vai trò biên tập viên",
    Audit_scenario: "Thẩm định cảnh tượng.",
    Please_enter_the_review_scenario: "Vui lòng nhập cảnh đánh giá",
    Review_page: "Trang đánh giá",
    Edit_audit_scenario_account: "Biên tập viên xem xét tài khoản cảnh quay.",
    Operations_Manager_Account: "Số tài khoản của người phụ trách hoạt động.",
    Please_enter_the_review_account: "Vui lòng nhập tài khoản xét duyệt.",
    Please_enter_your_account: "Vui lòng nhập số tài khoản.",
    New_users: "Thêm người dùng",
    Bind_characters: "Những nhân vật bị trói buộc",
    Latest_reset_time: "Thời gian reset mới nhất",
    Please_select_a_role: "Vui lòng chọn nhân vật.",
    Input_cannot_be_empty: "Input không thể để trống",
    hint_6601: "Không thể nhập ký tự và số đặc biệt",
    Edit_Users: "Người dùng biên tập",
    hint_659: "Bạn có chắc chắn muốn vô hiệu hóa tài khoản này không?",
    Account_deactivated: "Tài khoản đã bị vô hiệu hóa",
    hint_658: "Có chắc là bạn muốn đặt lại mật khẩu tài khoản này không?",
    Reset_successful: "Reset thành công",
    The_new_password_is: "Mật khẩu mới là",
    Gift_Name: "Tên của món quà",
    Please_enter_the_gift_name: "Vui lòng nhập tên món quà.",
    Gift_category: "Danh mục quà tặng",
    VIP_gift: "Quà VIP.",
    Add_gift: "Thêm một món quà.",
    Gift_unit_price_in_gold_coins_: "Đơn giá quà tặng (tiền vàng)",
    Banner_gift: "banner quà tặng",
    Full_screen_gift: "Quà tặng toàn màn hình",
    Gift_icon: "Biểu tượng quà tặng",
    Size_does_not_exceed_1MB_: "Kích thước không quá 1MB!",
    Enable_floating_screen: "Bật màn hình theo chiều gió",
    Floating_screen_category: "Thể loại màn hình bay.",
    hint_657: "Vui lòng chọn thể loại màn hình theo chiều gió",
    Please_select_VIP_level: "Vui lòng chọn cấp VIP",
    hint_656: "Có sẵn cho người dùng VIP ở cấp độ này trở lên",
    hint_601: "Bạn có thể nhập tối đa 4 ký tự Trung Quốc hoặc 8 chữ cái tiếng Anh hoặc 8 số.",
    Please_enter_the_price: "Vui lòng nhập giá.",
    hint_602: "Đơn giá quà tặng không được lớn hơn 999999 hoặc nhỏ hơn 0.",
    Please_upload_the_gift_icon: "Vui lòng tải lên biểu tượng quà tặng.",
    Please_upload_the_playback_effect: "Xin vui lòng tải lên hiệu ứng phát sóng.",
    Please_upload_the_preview_effect: "Vui lòng tải lên hiệu ứng xem trước",
    Edit_Gift: "Quà tặng biên tập",
    Please_choose_a_gift_type: "Vui lòng chọn loại quà tặng",
    Please_select_the_floating_screen_type: "Vui lòng chọn loại màn hình theo chiều gió",
    Please_select_whether_combo_is_possible: "Vui lòng chọn nếu bạn có thể kết hợp.",
    Only_SVGA_files_can_be_uploaded_: "Chỉ có thể tải lên tập tin svga!",
    Only_GIF_files_can_be_uploaded: "Chỉ có thể upload file gif",
    hint_66: "Tuyến dưới món quà này thì món quà này tuyến dưới kho quà tặng，tức là trong chiến lược cấu hình quà tặng không xuất hiện món quà này，có chắc chắn tuyến dưới không?",
    Successfully_taken_offline: "logout thành công.",
    hint_67: "Món quà này online thì món quà này online lại đến kho quà，tức là trong chiến lược cấu hình quà tặng lại xuất hiện món quà này，xác định login không?",
    Gift_data_statistics: "Gift Data Statistics (bằng tiếng Anh).",
    User_gift_details: "Chi tiết quà tặng của người dùng",
    Gift_List: "Danh sách quà tặng",
    Distribution_scope: "Phạm vi hạ phát.",
    Effective_time: "Thời gian có hiệu lực.",
    Last_modified_time: "Thời gian sửa đổi gần đây.",
    Last_Editor: "Người biên tập cuối cùng",
    hint_68: "Vui lòng nhập ID quà tặng，cách nhau giữa nhiều món quà bằng dấu phẩy tiếng Anh，thứ tự nhập là thứ tự hiển thị quà tặng phía trước",
    Sending_gifts: "Gửi quà đi.",
    hint_72: "Xóa chính sách quà tặng này sẽ dẫn đến xóa chính sách này，bạn có chắc chắn xóa không?",
    hint_70: "Offline chiến lược quà tặng này sẽ làm cho quà tặng của phòng chiến lược đó offline từ đầu phía trước，chắc chắn rằng offline?",
    hint_71: "Sách lược tặng quà online thì sách lược tặng quà online từ đầu，xác định login không?",
    Expert_type: "Loại chuyên gia",
    Import_experts: "Chuyên gia nhập khẩu",
    Introduction: "Giới thiệu vắn tắt.",
    Hit_rate: "Tỷ lệ trúng mục tiêu.",
    Recent_status: "Trạng thái gần đây.",
    The_highest_consecutive_red: "Cao nhất là Liên Hồng.",
    hint_604: "Gần đây Liên Hồng.",
    Number_of_proposals: "Số lượng chương trình",
    Number_of_buyers: "Số lượng người mua",
    Accumulated_total_revenue: "Tổng lợi nhuận tích lũy",
    Settlable_income: "Thu nhập có thể thanh toán.",
    Add_Expert: "Thêm chuyên gia",
    User_userid_cannot_be_empty: "User userid không thể để trống",
    Please_enter_the_league_and_team_name: "Vui lòng nhập tên đội",
    Home_team_vs_away_team: "Đội chủ nhà vs đội khách.",
    League: "Giải đấu.",
    Choose_a_competition: "Chọn giải đấu",
    Event_information: "Thông tin giải đấu",
    Select_recommendation: "Chọn một đề xuất",
    Yazhi: "Á chỉ.",
    The_main_winner: "Chủ thắng.",
    Customer_win: "Khách thắng.",
    Small_and_large_balls: "Quả bóng lớn nhỏ.",
    Greater_than: "Lớn hơn.",
    ball: "Quả bóng.",
    European_Compensation: "Âu Bồi.",
    A_draw: "Hòa.",
    Wanbo: "Vạn Bác.",
    Le_Dong: "Nhạc Động.",
    Macau: "Ma Cao.",
    crown: "Vương miện",
    hint_655: "Xin hãy chọn cách chơi trước.",
    Add_recommendation_group: "Thêm nhóm giới thiệu",
    Recommended_group_name: "Tên nhóm giới thiệu",
    Circle_details: "Vòng tròn chi tiết.",
    Number_of_recommended_circles: "Số lượng vòng tròn giới thiệu",
    Resource_bit_name: "Tên bit tài nguyên",
    Expert_details: "Chuyên gia chi tiết.",
    Number_of_recommended_experts: "Số lượng chuyên gia được đề xuất",
    Pause: "Tạm dừng.",
    Add_anchors: "Phát triển Anchor",
    X_connected_red: "X Liên Hồng.",
    Near_X_in_X: "Gần X trong X",
    Return_rate: "Tỷ lệ hoàn vốn.",
    hint_653: "Vui lòng điền vào ít nhất một thông tin neo đầy đủ.",
    hint_654: "Chắc chắn đề cử nhóm này chứ?",
    hint_652: "Xác định tạm dừng nhóm đề cử này sao?",
    Up_to_20_can_be_configured: "Có thể cấu hình tối đa 20 cái.",
    Edit_selling_price: "Biên tập giá bán",
    First_gear: "Số một",
    Second_gear: "Số hai.",
    Third_gear: "Số 3.",
    Release_Brocade_Bag: "Phát hành túi gấm.",
    Brocade_Bag_Type_: "Loại túi gấm：",
    Football_recommendations: "Giới thiệu bóng đá",
    Basketball_recommendations: "Lời khuyên bóng rổ.",
    hint_651: "Vui lòng chọn tùy chọn đề xuất cài đặt giải đấu",
    Please_enter_the_publisher_account: "Vui lòng nhập số tài khoản nhà xuất bản.",
    Please_enter_the_recommendation_reason: "Vui lòng nhập lý do đề cử.",
    Please_set_the_publishing_time: "Vui lòng đặt thời gian phát hành.",
    hint_650: "Trò chơi đã kết thúc cần chọn thời gian phát hành.",
    Expert_management: "Chuyên gia quản lý",
    Popular_experts: "Chuyên gia nóng bỏng",
    Hit: "Trúng mục tiêu.",
    Returned: "Đã trả lại.",
    To_be_returned: "Đợi trả lại.",
    On_sale: "Đang bán.",
    Cut_off_orders: "Cắt đơn.",
    Order_status: "Trạng thái lệnh",
    Purchase_time: "Thời gian mua hàng",
    Please_enter_the_competition: "Vui lòng nhập cuộc thi.",
    Competition: "Cuộc thi.",
    Publisher: "Nhà xuất bản",
    Please_enter_the_publisher: "Vui lòng nhập người xuất bản",
    Purchase_successful: "Mua thành công",
    hint_73: "Xác định hạ giá không? Đoạn video ngắn này sẽ không xuất hiện ở đầu di chuyển phía sau hạ giá?",
    Bullet_Screen_Library: "Kho chứa súng",
    Add_bullet_library: "Thêm thư viện bắn súng",
    Batch_Import: "Nhập khẩu hàng loạt",
    Add_bullet_comments: "Thêm màn hình bom.",
    Bullet_screen_content: "Nội dung màn bom.",
    Recent_Editors: "Biên tập viên gần đây nhất",
    Import_data: "Nhập dữ liệu",
    Edit_bullet_screen: "Chỉnh sửa màn bắn",
    hint_649: "Hỗ trợ thêm nhiều màn hình，nhiều màn hình bằng tiếng Anh,Tách dấu phẩy，thêm tối đa 1000 thanh một lần.",
    Label_One: "Nhãn hiệu một.",
    Label_2: "Nhãn hiệu 2.",
    Label_Three: "Nhãn hiệu số 3.",
    Label_Four: "Nhãn hiệu 4.",
    Label_Five: "Nhãn hiệu 5.",
    Please_enter_the_bullet_content: "Vui lòng nhập nội dung màn bom.",
    hint_648: "Kích thước tải lên không thể vượt quá 10MB!",
    Successfully_added_barrage: "Thêm màn bắn thành công",
    Update_barrage_successful: "Cập nhật màn bom thành công.",
    hint_647: "Tập tin tải lên chỉ có thể ở định dạng xls!",
    Import_failed: "Nhập thất bại",
    Import_successful: "Import thành công",
    hint_646: "Bạn có chắc chắn muốn xóa màn hình này không?",
    Number_of_fans: "Số lượng fan hâm mộ.",
    Live_broadcast_time: "Thời gian phát sóng trực tiếp.",
    Duration_minutes_: "Thời gian (phút)",
    Number_of_gift_givers: "Số người tặng quà.",
    Number_of_gifts_given: "Số lượng quà tặng.",
    Live_room_revenue_feather_: "Thu nhập phòng trực tiếp (lông vũ)",
    Guilds: "Công hội.",
    Settlable_income_yuan_: "Thu nhập có thể thanh toán được.",
    Reward_and_punishment_income_yuan: "Tiền thưởng và phạt.",
    Total_revenue_yuan_: "Tổng lợi nhuận (Yuan)",
    Adjustment_type_: "Loại điều chỉnh：",
    hint_645: "Lý do thưởng và phạt：",
    Settlement_bill_review: "Kiểm toán hóa đơn thanh toán",
    Originating_Reviewer: "Người khởi xướng cuộc kiểm toán",
    Operations_Manager_: "Giám đốc điều hành",
    Reissue: "Khởi động lại.",
    Through: "Thông qua.",
    Please_enter_the_amount: "Vui lòng nhập số tiền",
    Please_enter_the_reason: "Vui lòng nhập lý do.",
    Guild_name: "Tên của guild",
    Please_enter_the_guild_name: "Vui lòng nhập tên guild.",
    Guild_ID: "Guild ID (bằng tiếng Anh).",
    Receiving_account_name: "Tên tài khoản nhận tiền",
    Opening_Bank: "Mở tài khoản.",
    Bank_card_number: "Số thẻ ngân hàng.",
    Account_opening_address: "Địa chỉ mở tài khoản",
    Number_of_anchors: "Số người dẫn chương trình.",
    Please_fill_in_your_phone_number: "Vui lòng điền số điện thoại di động.",
    Please_fill_in_your_bank_card_number: "Vui lòng điền số thẻ ngân hàng.",
    hint_75: "Guild này sẽ có thể được gán cho Anchor khi được kích hoạt，bạn có chắc chắn rằng guild này sẽ được kích hoạt không?",
    Resource_location: "Vị trí tài nguyên",
    Is_it_effective_: "Có hiệu lực hay không.",
    Jump_position: "Vị trí nhảy",
    Please_enter_the_jump_location: "Vui lòng nhập vị trí nhảy",
    Please_select_a_cover: "Vui lòng chọn bìa.",
    Please_select_a_jump_type: "Vui lòng chọn kiểu nhảy",
    Please_select_a_resource_location: "Vui lòng chọn vị trí tài nguyên",
    hint_76: "Lưu ý： Quyền lực càng lớn thì vị trí quảng cáo hiện tại càng cao.",
    Is_the_browser_open_: " Cho dù trình duyệt đang mở",
    hint_644: "Xác nhận bạn muốn kích hoạt quảng cáo?",
    hint_643: "Xác nhận bạn muốn vô hiệu hóa quảng cáo?",
    hint_642: "Xác nhận rằng bạn muốn xóa quảng cáo?",
    Add_an_open_screen_advertisement: "Thêm quảng cáo mở màn hình mới",
    hint_641: "Vui lòng đảm bảo định dạng video là MP4 và không vượt quá 5M",
    hint_77: "Chỉ có thể tải lên tệp jpg/png/gif，kích thước ảnh cần 750X1624",
    Please_enter_the_link_address: "Vui lòng nhập địa chỉ liên kết",
    Please_enter_the_live_broadcast_room_ID: "Vui lòng nhập ID phòng phát sóng trực tiếp.",
    Please_enter_the_information_ID: "Vui lòng nhập ID thông tin.",
    Please_enter_the_chat_room_ID: "Vui lòng nhập ID phòng chat",
    The_input_information_format_is_incorrect: "Nhập thông tin sai định dạng",
    Chat_room_ID: "ID phòng chat",
    Brocade_Bag_ID: "ID túi gấm.",
    hint_640: "Chỉ có thể tải lên tệp jpg/png/gif，kích thước đề xuất là",
    Please_enter_the_sorting_number: "Vui lòng nhập số sắp xếp",
    Sort: "Sắp xếp.",
    The_length_cannot_exceed_20: "Chiều dài không thể vượt qua 20.",
    hint_78: "Sau khi hạ cánh，cửa sổ này có chắc chắn hạ xuống không?",
    hint_639: "Kích thước ảnh tải lên không được vượt quá 1MB",
    Page_Name: "Tên trang",
    Please_enter_the_page_name: "Vui lòng nhập tên trang",
    Parameters: "Tham số.",
    PC_Link: "Liên kết PC",
    H5_Link: "Liên kết H5",
    PC_Preview: "Xem trước PC",
    H5_Preview: "Xem trước H5",
    PC_Resource_Map: "Bản đồ tài nguyên PC",
    H5_Resource_Map: "Bản đồ tài nguyên H5",
    Please_enter_parameters: "Vui lòng nhập các thông số",
    Background_name: "Tên nền",
    Background_image: "Hình nền",
    Set_background_name: "Đặt tên nền",
    Set_sorting: "Thiết lập sắp xếp",
    Modify_background_image: "Thay đổi hình nền",
    Add_background_image: "Thêm hình nền mới",
    Please_fill_in_the_background_name: "Vui lòng điền tên nền.",
    Please_fill_in_the_sorting: "Xin hãy điền vào thứ tự sắp xếp",
    Successfully_taken_down: "Hạ giá thành công.",
    Successfully_listed: "Lên kệ thành công.",
    Effective: "Hiệu quả.",
    Invalid: "Không có hiệu quả.",
    hint_606: "Số lượng kênh phát sóng (10 phút thống kê một lần)",
    Go_live: "Đang online.",
    hint_605: "Vui lòng đặt số thứ tự (1-999)",
    Please_enter_the_tab_name: "Vui lòng nhập tên tab",
    hint_79: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần 670 * 376",
    Please_set_the_status: "Vui lòng thiết lập trạng thái",
    Are_you_sure_you_want_to_go_online_: "Có chắc là muốn online không?",
    Are_you_sure_you_want_to_go_offline_: "Có chắc là muốn logout không?",
    Anchor_userid: "Người dẫn chương trình.",
    Chat_room_title: "Tiêu đề phòng chat",
    Cut_off_flow: "Đoạn lưu.",
    hint_638: "Có chắc là phòng chat này bị đứt không?",
    Upload_time: "Thời gian tải lên",
    Cover_type: "Loại bìa",
    Batch_pass: "Thông qua hàng loạt.",
    Batch_rejection: "Từ chối hàng loạt.",
    Voice_chat_room: "Phòng chat bằng giọng nói.",
    Reject: "Từ chối.",
    Operated: "Hoạt động",
    Passed: "Đã thông qua.",
    Live_streaming_3: "Phát sóng trực tiếp.",
    hint_637: "Bạn có chắc chắn muốn xóa bìa không?",
    Music_name: "Tên nhạc",
    Please_enter_the_music_name: "Vui lòng nhập tên nhạc.",
    Author_name: "Tên tác giả",
    Please_enter_the_author_name: "Vui lòng nhập tên tác giả",
    Size: "Kích thước.",
    Playback_count: "Số lần chơi",
    Trial_listening: "Thử nghe xem.",
    Please_enter_the_song_name: "Vui lòng nhập tên bài hát.",
    Song_title: "Tên bài hát",
    Upload_music_files: "Upload file nhạc lên",
    Only_supports_MP3_format: "Chỉ hỗ trợ định dạng MP3",
    hint_80: "Tỷ lệ khung hình 60 * 60",
    Please_upload_music_files: "Xin mời vào tập tin nhạc.",
    Edit_Music: "Biên tập âm nhạc",
    Add_music: "Âm nhạc mới",
    Only_MP3_format_files_can_be_uploaded: "Chỉ có thể tải lên các tập tin định dạng mp3",
    The_size_cannot_exceed_20MB_: "Không được vượt quá 20MB!",
    hint_635: "Có chắc là anh muốn lên đĩa nhạc không?",
    hint_636: "Có chắc là anh muốn bỏ nhạc không?",
    Add_Recommendation: "Khuyến nghị mới",
    Please_set_the_sorting_number_1_999_: "Vui lòng đặt số thứ tự (1-999)",
    Editor_recommendation: "Giới thiệu biên tập viên",
    UserId_cannot_be_empty: "UserId không thể để trống",
    Shutdown_time: "Tắt thời gian phát sóng.",
    Peak_heat_value: "Đỉnh điểm của giá trị nhiệt",
    Edit_Page: "Trang biên tập",
    Add_Page: "Thêm một trang",
    Upload_at_least_one_resource_image: "Tải lên ít nhất một hình ảnh tài nguyên.",
    hint_81: "Liên kết sẽ được đăng trực tuyến sau khi đăng，bạn có chắc chắn rằng trang sẽ được đăng không?",
    hint_82: "Liên kết này sẽ không thể truy cập trực tuyến sau khi hạ giá，bạn có chắc chắn hạ giá trang này không?",
    Select_Cover: "Chọn bìa",
    hint_633: "Chú ý： Thời gian có hiệu lực không thể chồng chéo lên nhau!!!",
    hint_83: "Có thông tin không đầy đủ，xin điền đầy đủ rồi đệ trình!",
    hint_634: "Kích thước ảnh tải lên không được vượt quá 500K!",
    Recommended_image: "Bản đồ đề cử.",
    Display_sorting: "Hiển thị sắp xếp",
    Edit_Secondary_Classification: "Phân loại biên tập cấp độ 2",
    Classification: "Phân loại.",
    Add_Category: "Thêm phân loại",
    Please_enter_the_activity_name: "Vui lòng nhập tên chiến dịch",
    Activity_status: "Trạng thái hoạt động",
    Image: "Hình ảnh.",
    Configuration_date: "Cấu hình ngày",
    Please_enter_the_button_link: "Vui lòng nhập liên kết nút",
    Button_link: "Liên kết nút",
    Button_Text: "nút copywriting",
    hint_607: "Vui lòng nhập bản sao nút，tối đa 4 từ",
    Button_color: "Nút màu",
    hint_608: "Vui lòng nhập màu nút，chẳng hạn như #CCCCCC",
    Edit_Configuration: "Chỉnh sửa cấu hình",
    New_configuration: "Thêm cấu hình",
    hint_609: "Vui lòng nhập nút sao chép",
    Please_enter_the_button_color: "Vui lòng nhập màu nút",
    hint_84: "Bạn có chắc chắn muốn tải lên mẫu này không，mẫu trang sẽ có hiệu lực sau khi tải lên",
    hint_85: "Có chắc là bạn muốn gỡ bỏ mẫu này không，nút Page sẽ không hoạt động sau khi gỡ bỏ mẫu này.",
    APP_end: "Kết thúc APP.",
    H5_end: "Kết thúc H5.",
    AK_APP_end: "Kết thúc AK-APP",
    AK_PC_end: "Kết thúc AK-PC",
    AK_H5_end: "Kết thúc AK-H5",
    Anchor_details: "Người dẫn chương trình chi tiết.",
    Recommended_number_of_anchors: "Số lượng Anchors được đề xuất",
    Anchor_: "Người dẫn chương trình",
    Please_enter_the_anchor_ID: "Vui lòng nhập ID neo.",
    Please_fill_in_the_resource_slot_name: "Vui lòng điền vào tên tài nguyên bit.",
    Please_fill_in_at_least_one_anchor_information: "Vui lòng điền vào ít nhất một thông tin neo.",
    Secondary_classification_valid_all: "Phân loại thứ cấp (hợp lệ/tất cả)",
    SM_static_resources: "Tài nguyên tĩnh SM",
    AK_static_resources: "Tài nguyên tĩnh AK",
    More_Jumps: "Nhảy nhiều hơn",
    Editorial_Expert_Recommendation_Group: "Nhóm giới thiệu chuyên gia biên tập",
    Add_expert_recommendation_group: "Thêm nhóm giới thiệu chuyên gia",
    Near_0_in_0: "Gần 0 trong 0.",
    Highest: "Cao nhất.",
    Lian_Hong: "Liên Hồng.",
    Recently: "Gần đây.",
    Permissions: "Quyền hạn.",
    Are_you_sure_you_want_to_turn_it_on_: "Có chắc là mở không?",
    Are_you_sure_you_want_to_close_it_: "Có chắc là đóng cửa không?",
    Live_broadcast_room_name: "Tên phòng phát sóng trực tiếp.",
    Please_select_sorting: "Vui lòng chọn Sort",
    Edit_popular_recommendations: "Biên tập viên Hot Recommendations",
    Add_popular_recommendations: "Các khuyến nghị nóng mới được thêm vào",
    Advertising_name: "Tên quảng cáo",
    Resource_type: "Loại tài nguyên",
    Video_upload: "Tải lên video",
    Your_browser_does_not_support_video_playback: "Trình duyệt của bạn không hỗ trợ phát lại video",
    Re_upload: "Tải lên lại.",
    Please_upload_the_video: "Vui lòng tải video lên.",
    Offline: "Đã logout.",
    Square_banner: "Quảng trường banner",
    Page: "Trang này.",
    Information_details: "Thông tin chi tiết",
    Edit_open_screen_advertisement: "Biên tập quảng cáo mở màn hình",
    Please_select_a_resource_type: "Vui lòng chọn loại tài nguyên",
    Please_select_an_open_screen_advertisement: "Vui lòng chọn quảng cáo mở màn hình",
    The_uploaded_video_size_cannot_exceed_5MB_: "Kích thước video tải lên không được vượt quá 5 MB.",
    Please_upload_the_correct_video_format: "Vui lòng tải lên đúng định dạng video",
    Add_patches: "Thêm miếng dán mới",
    Display_location: "Hiển thị vị trí",
    Please_enter_the_title_of_the_patch_advertisement: "Vui lòng nhập tiêu đề quảng cáo bản vá",
    hint_86: "Chỉ có thể tải lên tệp jpg/png，kích thước ảnh cần là 100X100",
    Edit_patch_ads: "Biên tập quảng cáo vá",
    Add_patch_ads: "Thêm quảng cáo vá mới",
    Please_enter_the_advertisement_title: "Vui lòng nhập tiêu đề quảng cáo",
    Please_select_a_display_location: "Vui lòng chọn vị trí hiển thị",
    Recommended_by_experts_on_the_homepage: "Trang chủ chuyên gia giới thiệu",
    Live_streaming_page_anchor_recommendation: "Phát sóng trực tiếp dẫn chương trình giới thiệu",
    hint_87: "Tải lên hình ảnh chỉ có thể ở định dạng JPG，PNG hoặc gif!",
    Pop_up_name: "Tên cửa sổ đạn",
    APP_Advertising: "quảng cáo app",
    Open_screen_advertising: "Mở màn hình quảng cáo.",
    Patch_advertising: "Quảng cáo Patch",
    Activity_pop_up: "Cửa sổ bắn hoạt động.",
    hint_611: "Lưu ý： Tất cả các chức năng của hoạt động trên kệ đã được mở.",
    Assistant: "Tiểu trợ lý.",
    Homepage_Live: "Phát sóng trực tiếp trên trang nhất",
    Live_broadcast_room_announcement: "Thông báo trực tiếp.",
    Advertising_resource_allocation: "Cấu hình bit tài nguyên quảng cáo",
    hint_610: "Mở ra thì yêu cầu tài nguyên quảng cáo cũ.",
    hint_88: "Lưu ý： Một số chức năng không được mở trong quá trình đánh giá chính thức",
    APP_Brand_Name_: "Tên thương hiệu APP：",
    Please_enter_the_APP_brand_name: "Vui lòng nhập tên thương hiệu APP",
    Please_enter_contact_customer_service: "Xin vui lòng nhập liên hệ với dịch vụ khách hàng",
    hint_89: "Tải lên hình ảnh kích thước sai，xin vui lòng tải lên theo định dạng yêu cầu",
    Switch_initialization: "Khởi tạo công tắc",
    Version_configuration: "Cấu hình phiên bản",
    Review_status: "Trạng thái xét duyệt",
    Operation_status: "Tình trạng hoạt động",
    AppStore_version_number: "Số phiên bản AppStore",
    Please_enter_the_version_number: "Vui lòng nhập số phiên bản",
    Version_status: "Trạng thái phiên bản",
    Update_instructions: "Cập nhật hướng dẫn",
    Configure_time: "Cấu hình thời gian",
    Please_enter_the_interface_version_number: "Vui lòng nhập số phiên bản giao diện",
    hint_90: "Số phiên bản giao diện do kỹ thuật cung cấp，không thể tùy chỉnh riêng!",
    Please_enter_update_instructions: "Vui lòng nhập hướng dẫn cập nhật",
    Please_select_version_status: "Vui lòng chọn trạng thái phiên bản",
    Please_enter_the_AppStore_version_number: "Vui lòng nhập số phiên bản AppStore",
    Please_enter_the_short_video_title: "Vui lòng nhập tiêu đề video ngắn",
    Edit_Title: "Chỉnh sửa tiêu đề",
    Short_video_title_: "Tiêu đề video ngắn：",
    hint_91: "Phát hành đoạn video ngắn này đến đầu di động，có xác nhận phát hành không?",
    Crawling_website_sites: "Thu thập thông tin trang web",
    Statistical_time: "Thống kê thời gian",
    UV_data: "Dữ liệu UV",
    Platform: "Nền tảng.",
    Add_circle_recommendations: "Giới thiệu vòng tròn mới.",
    Are_you_sure_you_want_to_pause_: "Có xác định tạm dừng không?",
    Access_date: "Ngày truy cập",
    Posting_time: "Thời gian đăng bài.",
    Poster_nickname: "Biệt danh của người gửi.",
    Interactive_red_envelopes: "Phong bì đỏ tương tác",
    Topic_Name: "Tên chủ đề",
    Please_enter_the_topic_name: "Vui lòng nhập tên chủ đề",
    hint_632: "Nhiệt độ chủ đề (10 phút thống kê một lần)",
    Accumulated_number_of_related_posts: "Tích lũy số lượng bài viết liên quan",
    Save_and_publish: "Lưu và xuất bản",
    Recommendation: "Đề cử.",
    Add_recommended_topics: "Thêm đề xuất chủ đề",
    Number_of_participants_in_the_topic: "Số lượng người tham gia chủ đề",
    Number_of_topic_views: "Số lượt xem chủ đề",
    Topic_release_time: "Thời gian đăng chủ đề",
    Topic_Top_Time: "Đề tài được đặt lên hàng đầu.",
    Choose_a_topic: "Chọn chủ đề",
    Enter_the_topic_name_you_want_to_recommend: "Nhập tên chủ đề mà bạn cần đề xuất",
    Editor_recommended_topic: "Biên tập viên đề xuất chủ đề",
    The_topic_cannot_be_empty: "Đề tài không thể để trống.",
    hint_631: "Vui lòng nhập tên chủ đề (trong vòng 15 từ)",
    hint_630: "Vui lòng nhập giới thiệu về chủ đề (trong vòng 100 từ)",
    Successfully_created_topic: "Tạo chủ đề thành công",
    Recharge: "Nạp tiền.",
    Consumption: "Tiêu thụ.",
    Revenue: "Lợi nhuận.",
    Order_number_1: "Số thứ tự",
    Recharge_amount: "Số tiền nạp.",
    Consumption_type: "Loại người tiêu dùng",
    Consumption_amount: "Số tiền tiêu dùng",
    Revenue_type: "Các loại lợi nhuận",
    Revenue_amount: "Số tiền thu được",
    Additional_issuance: "Tăng phát.",
    Uploading: "Đang tải lên......",
    Batch_issuance: "Tăng trưởng hàng loạt.",
    Download_batch_issuance_templates: "Tải xuống mẫu tăng trưởng hàng loạt",
    Additional_currency_issuance: "Phát hành thêm tiền",
    Increase_experience_value_of_issuance: "Tăng điểm kinh nghiệm.",
    Please_enter_user_userid: "Vui lòng nhập user userid",
    Please_enter_the_quantity_of_coins: "Xin hãy nhập số lượng kim tệ.",
    Please_enter_a_note: "Vui lòng nhập ghi chú.",
    VIP_level: "Đẳng cấp VIP.",
    Additional_issuance_time: "Thời gian tăng trưởng.",
    Are_you_sure_you_want_to_refuse_: "Có chắc là muốn từ chối không?",
    Personal_currency_issuance: "Tăng trưởng tiền tệ cá nhân",
    VIP_currency_issuance: "Phát hành thêm tiền VIP",
    Total_consumption_in_gold_coins: "Total Consumption (đồng tiền vàng)",
    Total_rechargeyuan: "Tổng giá trị gia tăng.",
    Number_of_Rechargers: "Số người nạp tiền.",
    Recharge_switch: "Công tắc nạp tiền.",
    RMB_amount_yuan_: "Số tiền CNY (Yuan)",
    Number_of_gold_coins: "Số lượng kim tệ.",
    Edit_recharge_configuration: "Chỉnh sửa cấu hình nạp tiền",
    Add_recharge_configuration: "Thêm cấu hình nạp tiền",
    Recharge_time: "Thời gian nạp tiền.",
    Payment_channels: "Các kênh thanh toán",
    Please_enter_the_order_number_1: "Vui lòng nhập số đơn đặt hàng",
    Payment_time: "Thời gian thanh toán",
    Order_time: "Thời gian đặt hàng",
    Payment_amount_yuan: "Số tiền thanh toán (Yuan)",
    Balance_inquiry: "Truy vấn số dư.",
    Ordinary_user_currency_balance: "Số dư tiền tệ của người dùng thông thường",
    Details_1: "Chi tiết.",
    Football_quiz_activity: "Hoạt động trả lời câu hỏi bóng đá.",
    Football_winning_statistics: "Football Winning Statistics (bằng tiếng Anh)",
    Basketball_quiz_activity: "Hoạt động trả lời câu hỏi bóng rổ.",
    Basketball_winning_statistics: "Basketball Winning Statistics (bằng tiếng Anh).",
    Guessing_question_name: "Tên câu trả lời phỏng đoán.",
    Please_enter_the_name_of_the_quiz_question: "Xin vui lòng nhập tên câu trả lời.",
    Start_time: "Thời gian bắt đầu.",
    Add_a_question: "Câu trả lời mới.",
    Rule_configuration: "Cấu hình quy tắc",
    Style_Configuration: "Cấu hình kiểu dáng",
    Number_of_periods: "Số kỳ.",
    Reward_base: "Cơ sở khen thưởng.",
    Stop: "Dừng lại.",
    End: "Kết thúc.",
    Title: "Đề mục.",
    The_problem_name_cannot_be_empty: "Tên câu hỏi không thể để trống",
    Please_enter_the_question_name: "Vui lòng nhập tên câu hỏi",
    Please_enter_question_options: "Vui lòng nhập tùy chọn câu hỏi và câu trả lời",
    question_options_1: "Lựa chọn trả lời",
    Add_Options: "Thêm tùy chọn",
    Please_enter_the_number_of_currencies_for_the_reward_base: "Vui lòng nhập số tiền của cơ sở phần thưởng",
    Save: "Tiết kiệm.",
    Cancel: "Hủy bỏ.",
    Please_submit_results_with_caution: "Xin hãy cẩn thận trình bày kết quả.",
    The_answer_to_the_question_cannot_be_empty: "Câu trả lời cho câu hỏi không thể để trống",
    Flow: "Lưu cục trưởng.",
    Activity_Rule_Configuration: "Cấu hình quy tắc hoạt động",
    Rule_configuration_cannot_be_empty: "Cấu hình quy tắc không thể để trống",
    Limited_to_200_words_in_length: "Giới hạn 200 chữ.",
    Guessing_Style_Configuration: "Đoán kiểu dáng cấu hình.",
    PC_Banner_diagram: "Biểu đồ PC Banner",
    PC_Banner_image_cannot_be_empty: "Biểu đồ PC Banner không thể để trống",
    hint_612: "Kích thước： 1920x624 chỉ có thể tải lên tệp jpg/png",
    H5_APP_Banner_image: "Biểu đồ Banner H5/APP",
    H5_APP_Banner_image_cannot_be_empty: "Biểu đồ H5/APP Banner không thể để trống.",
    hint_613: "Kích thước： 750x1012 chỉ có thể tải lên tệp jpg/png",
    Theme_color: "Màu sắc chủ đề",
    The_theme_color_cannot_be_empty: "Màu chủ đề không thể để trống",
    hint_619: "Cấu hình màu ở cuối trang",
    Event_details: "Chi tiết sự kiện",
    Correct_answer: "Đáp án chính xác.",
    Stopped: "Đã dừng lại.",
    Add_a_guessing_activity: "Hoạt động cạnh tranh mới.",
    hint_618: "Mời điền tên đáp án cạnh tranh.",
    Please_select_the_effective_time_range: "Vui lòng chọn khung thời gian có hiệu lực",
    Please_fill_in_the_reward_base: "Xin hãy điền vào số tiền thưởng.",
    hint_92: "Sau khi hủy thì không công bố hoạt động trả lời kỳ này，có xác định hủy bỏ hoạt động trả lời phỏng đoán kỳ này không?",
    Success: "Thành công.",
    Cancel_successful: "Hủy bỏ thành công.",
    hint_93: "Sau khi dừng，người dùng sẽ không thể tham gia vào các câu trả lời và có chắc chắn ngừng hoạt động không?",
    hint_614: "Vui lòng nhập cơ sở phần thưởng cho số nguyên dương 1-99999",
    Cannot_select_past_time: "Không thể lựa chọn thời gian đã qua.",
    hint_615: "Có xác định công bố đáp án không?",
    Save_successful: "Tiết kiệm thành công",
    hint_616: "Câu trả lời cho câu hỏi {số} được đặt tối thiểu 2 lựa chọn",
    Number_of_participants: "Số người tham gia",
    Number_of_winners: "Số người trúng thưởng",
    hint_617: "Số tiền thưởng (tiền vàng)",
    Question_Name: "Tên câu hỏi",
    Number_of_correct_answers: "Số người trả lời đúng.",
    Activity_List: "Danh sách các hoạt động",
    Top_of_the_event: "Hoạt động lên đỉnh.",
    PC_end_carousel: "PC-side-round (bằng tiếng Anh).",
    AK_Activity_List: "AK - Danh sách các hoạt động",
    AK_Activity_Topping: "AK – Sự kiện đỉnh cao",
    AK_PC_end_carousel: "AK-PC-End Roundup (bằng tiếng Anh).",
    Create_activity_records: "Tạo một bản ghi hoạt động",
    Event_main_title: "Tiêu đề chính của chiến dịch",
    Activity_subtitle: "Phụ đề chiến dịch",
    PC_thumbnail: "Hình thu nhỏ phía PC",
    App_thumbnail: "Hình thu nhỏ ứng dụng",
    Activity_time: "Thời gian hoạt động",
    Long_term_activities: "Hoạt động lâu dài",
    Please_enter_the_main_title_of_the_activity: "Vui lòng nhập tiêu đề chính của chiến dịch",
    Please_enter_the_activity_subtitle: "Vui lòng nhập phụ đề hoạt động",
    PC_activity_redirect_address: "Địa chỉ chuyển hoạt động PC",
    Activity_jump_button_background: "Nền nút nhảy hoạt động",
    App_side_thumbnail: "Hình thu nhỏ phía ứng dụng",
    hint_901: "Kích thước 355 * 148px",
    App_activity_redirect_address: "Địa chỉ nhảy hoạt động ứng dụng",
    Time_type: "Loại thời gian",
    There_is_a_time_limit: "Có giới hạn thời gian.",
    Edit_activity_records: "Biên tập hồ sơ hoạt động",
    hint_629: "Có chắc là ghi lại hoạt động này không?",
    hint_620: "Xác định hủy bỏ ghi chép hoạt động này không?",
    Please_set_the_activity_time: "Vui lòng đặt thời gian hoạt động.",
    Jump_URL: "Nhảy URL",
    Queue: "Hàng đợi.",
    Cover: "Trang bìa.",
    Mobile_download_page: "Trang tải xuống di động",
    PC_Download_Page: "Trang tải xuống phía PC",
    Android_copywriting: "Copywriting cho Android",
    hint_94: "Vui lòng nhập bản sao，hỗ trợ tối đa 10 ký tự",
    IOS_Web_App_Copywriting: "iOS - Web App Copywriting (bằng tiếng Anh).",
    IOS_copywriting: "Copywriting cho iOS",
    PC_background_configuration: "Cấu hình nền PC",
    Group_stage_competition: "Vòng bảng.",
    "1_8_elimination_round": "Vòng loại 1/8.",
    "1_4_elimination_round": "Vòng loại 1/4.",
    Semi_finals: "Bán kết.",
    Finals: "Trận chung kết.",
    Location: "Địa điểm.",
    Facing_each_other: "Trận đấu.",
    hint_95: "Vui lòng nhập số phòng neo và ngăn cách bằng dấu phẩy，tối đa bốn chữ số",
    Fen: "Phân.",
    hint_628: "Tối đa chỉ có thể chọn 4 số phòng.",
    SM_redemption_page: "Trang trao đổi SM",
    AK_redemption_page: "Trang trao đổi AK",
    H5_end_thumbnail: "Hình thu nhỏ cuối H5",
    hint_96: "Chỉ có thể tải lên tệp jpg/png/gif，kích thước 1440 * 315",
    Category_ID: "Danh mục ID",
    Classification_name: "Tên phân loại",
    Please_enter_the_category_name: "Vui lòng nhập tên phân loại",
    Thai_tag_name: "Tên nhãn mác Thái Lan",
    Please_enter_the_Thai_tag_name: "Vui lòng nhập tên thẻ Thái Lan.",
    English_tag_name: "Tên nhãn tiếng Anh",
    Please_enter_the_English_tag_name: "Vui lòng nhập tên nhãn tiếng Anh",
    Vietnamese_label_name: "Tên nhãn mác tiếng Việt",
    Please_enter_the_Vietnamese_label_name: "Vui lòng nhập tên nhãn tiếng Việt",
    Korean_tag_name: "Tên nhãn mác Hàn Quốc",
    Please_enter_the_Korean_tag_name: "Vui lòng nhập tên nhãn tiếng Hàn",
    Brazilian_label_name: "Tên nhãn mác trong tiếng Brazil",
    Please_enter_the_Brazilian_label_name: "Vui lòng nhập tên nhãn mác tiếng Brazil",
    Add_a_new_category: "Phân loại mới",
    hint_888: "Tinh phẩm tốt.",
    Please_select_the_status: "Vui lòng chọn trạng thái",
    hint_627: "Có xác định hàng hóa này đang online không?",
    hint_626: "Có xác định được phân loại hàng hóa này không?",
    Warning: "Cảnh báo.",
    hint_97: "Phân loại có chứa hàng hóa thuộc sở hữu，không thể logout.",
    Edit_Classification: "Phân loại biên tập",
    Redemption_page_BANNER: "Đổi trang BANNER",
    hint_625: "Kích thước ảnh tải lên không được vượt quá 5M!",
    Delisted_products: "Hàng hóa hạ giá.",
    Listing_products: "Hàng hóa lên kệ.",
    Classification_management: "Quản lý phân loại.",
    Label_management: "Quản lý nhãn mác",
    Order_List: "Danh sách các đơn đặt hàng",
    Product_ID: "Mã sản phẩm",
    Please_enter_the_product_ID: "Vui lòng nhập ID sản phẩm",
    Product_name: "Tên sản phẩm",
    Please_enter_the_product_name: "Vui lòng nhập tên sản phẩm",
    Do_we_need_delivery_: "Có cần giao hàng hay không?",
    Switch: "Công tắc.",
    Unit_price: "Đơn giá.",
    Discounted_price: "Giảm giá.",
    Current_inventory: "Hàng tồn kho hiện tại",
    Redeemed: "Đã đổi.",
    Inventory_changes: "Thay đổi hàng tồn kho",
    Select_thumbnail: "Chọn hình thu nhỏ",
    Details_page_image: "Sơ đồ trang chi tiết",
    Select_detailed_image: "Chọn biểu đồ chi tiết",
    Details: "Mô tả chi tiết",
    Please_enter_the_unit_price: "Vui lòng nhập đơn giá.",
    Discounts: "Giảm giá đi.",
    Please_enter_the_discounted_price_of_the_product: "Vui lòng nhập hàng hóa để giảm giá.",
    Please_select_a_category: "Vui lòng chọn loại",
    Label: "Nhãn hiệu.",
    Product_purchase_restriction_period: "Chu kỳ hạn chế mua hàng hóa.",
    No_purchase_restrictions: "Không hạn chế mua.",
    Day: "Ngày.",
    Week: "Chu.",
    Cumulative: "Tích lũy.",
    Product_purchase_limit: "Số lượng hạn chế mua hàng.",
    hint_98: "Vui lòng nhập giới hạn mua hàng trong chu kỳ.",
    Inventory: "Hàng tồn kho.",
    Add: "Tăng lên.",
    Reduce: "Giảm.",
    The_product_name_cannot_be_empty: "Tên sản phẩm không được để trống",
    Boutique: "Tinh phẩm.",
    Life: "Cuộc sống.",
    Product_addition: "Hàng hóa mới.",
    Please_upload_thumbnail: "Vui lòng tải lên hình thu nhỏ.",
    Please_upload_detailed_images: "Xin vui lòng tải lên bản đồ chi tiết.",
    Please_select_whether_delivery_is_required: "Vui lòng chọn nếu bạn muốn giao hàng",
    Purchase_unit_price_cannot_be_empty: "Đơn giá mua không được để trống",
    Classification_cannot_be_empty: "Phân loại không được để trống.",
    hint_99: "Số lượng hạn chế mua hàng không được để trống.",
    Details_cannot_be_empty: "Thông tin chi tiết không thể để trống",
    Please_select_increase_decrease: "Vui lòng chọn tăng giảm",
    Are_you_sure_you_want_to_save_: "Có xác định bảo tồn hay không?",
    Cat_coin_price: "Giá mèo.",
    Diamond_price: "Giá kim cương.",
    Thai_product_name: "Tên thương mại Thái Lan",
    Please_enter_the_Thai_product_name: "Vui lòng nhập tên hàng hóa bằng tiếng Thái.",
    English_product_name: "Tên thương mại bằng tiếng Anh",
    Please_enter_the_English_product_name: "Vui lòng nhập tên sản phẩm bằng tiếng Anh",
    Korean_product_name: "Tên thương mại Hàn Quốc",
    Please_enter_the_Korean_product_name: "Vui lòng nhập tên hàng hóa bằng tiếng Hàn.",
    Brazilian_product_name: "Tên thương mại Brazil",
    Please_enter_the_Brazilian_product_name: "Vui lòng nhập tên thương mại bằng tiếng Brazil.",
    hint_902: "Kích thước đề nghị 174 * 174",
    hint_903: "Kích thước đề xuất 375 * 375",
    Thai_language_details: "Mô tả chi tiết bằng tiếng Thái",
    English_detailed_explanation: "Thông tin chi tiết bằng tiếng Anh",
    Vietnamese_language_details: "Thông tin chi tiết bằng tiếng Việt",
    Korean_language_details: "Mô tả chi tiết bằng tiếng Hàn",
    Brazilian_language_details: "Mô tả chi tiết tiếng Brazil",
    Please_enter_the_diamond_price_of_the_product: "Vui lòng nhập giá kim cương hàng hóa.",
    Are_you_sure_you_want_to_delist_this_product_: "Có xác định hạ giá sản phẩm này không?",
    Label_ID: "Thẻ ID",
    Please_enter_the_label_name: "Vui lòng nhập tên nhãn mác",
    Label_Name: "Tên nhãn mác",
    hint_624: "Có xác định đăng nhập nhãn hàng không?",
    hint_101: "Bạn có chắc chắn rằng bạn đã tắt nhãn sản phẩm này không?",
    Edit_label: "Nhãn hiệu biên tập",
    Order_number: "Số đơn đặt hàng",
    Please_enter_the_order_number: "Vui lòng nhập số đơn đặt hàng",
    Transaction_time: "Thời gian giao dịch",
    Receiving_address: "Địa chỉ nhận hàng.",
    Signee: "Người ký nhận.",
    Transaction_volume: "Khối lượng giao dịch",
    Total_transaction_amount: "Tổng số giao dịch",
    Transaction_status: "Trạng thái giao dịch",
    Unsuccessful: "Không thành công.",
    Limited_to_50_words_in_length: "Giới hạn 50 chữ.",
    Inviter_UID: "UID người mời",
    Inviting_person_nickname: "Biệt danh của người mời.",
    Invitee_UID: "UID được mời",
    Invitee_registration_time: "Thời gian đăng ký người được mời",
    Please_enter_the_inviter_reward: "Mời nhập phần thưởng người mời.",
    Please_enter_the_invitee_reward: "Xin mời nhập phần thưởng cho người được mời.",
    Rule_Description: "Quy tắc giải thích.",
    APP_landing_page_background_image: "Bản đồ nền trang hạ cánh của APP",
    hint_621: "Kích thước： 710X1086 chỉ có thể tải lên tệp jpg/png",
    Watch_the_live_broadcast: "Xem trực tiếp.",
    Live_room_speech: "Phát biểu trực tiếp.",
    Push_order_configuration: "Cấu hình đẩy",
    hint_102: "Gợi ý thân thiện： 1 - cấu hình phát biểu Vui lòng cấu hình theo thứ tự. Nếu cấu hình đến câu 2，chỉ 1，2 phát biểu cấu hình nội dung sẽ được thực hiện.",
    hint_905: "Cấu hình phát biểu lần thứ nhất.",
    Reward_amount: "Số tiền thưởng.",
    Please_enter_the_speech_reward_amount: "Vui lòng nhập số tiền thưởng phát biểu.",
    Valid_characters: "Ký tự hợp lệ",
    hint_623: "Vui lòng nhập ký tự hợp lệ để phát biểu",
    hint_103: "Số tiền thưởng cấu hình phát biểu đầu tiên không thể để trống",
    hint_104: "Số tiền thưởng cấu hình phát biểu thứ hai không thể để trống.",
    hint_105: "Số tiền thưởng cấu hình phát biểu lần thứ ba không thể để trống.",
    Friendly_reminder: "Gợi ý hữu nghị.",
    hint_1: "1- Dữ liệu đẩy và dữ liệu tham gia của người dùng có thể được truy cập vào quản lý trực tiếp mô-đun đẩy phòng trực tiếp;",
    hint_2: "2-Push Rewards Range： Phần thưởng cho các lệnh đẩy neo được tạo ngẫu nhiên trong phạm vi đó",
    hint_3: "3 - đỉnh thưởng hàng ngày của người dùng： vượt quá đỉnh này sẽ tiếp tục tham gia vào các tweet mà không nhận được phần thưởng",
    hint_889: "Push-order reward range (bằng tiếng Anh).",
    Maximum_reward: "Phần thưởng lớn nhất.",
    Minimum_reward: "Phần thưởng tối thiểu.",
    Users_get_peak_daily: "User gets peak per day (Người dùng đạt đỉnh mỗi ngày).",
    hint_622: "Vui lòng nhập giá trị tối đa hàng ngày của người dùng",
    Please_enter_the_maximum_reward: "Vui lòng nhập phần thưởng tối đa.",
    Please_enter_the_minimum_reward: "Vui lòng nhập phần thưởng tối thiểu.",
    Please_enter_the_user_daily_peak_gain: "Please enter user to get peak (Vui lòng nhập user để nhận peak mỗi ngày).",
    Viewing_duration: "Thời gian quan sát dài",
    hint_107: "Vui lòng nhập thời gian xem，cần số nguyên dương",
    Watch_rewards: "Xem phần thưởng",
    Please_enter_the_viewing_reward: "Vui lòng nhập phần thưởng xem.",
    Reward_channels: "Kênh khen thưởng",
    hint_106: "Được thưởng khi xem trực tiếp trên kênh này sau khi mở，chỉ được thưởng một lần khi xem trên các kênh khác nhau",
    Please_enter_the_viewing_duration: "Vui lòng nhập thời gian xem.",
    Please_select_a_reward_channel: "Vui lòng nhập phần thưởng xem.",
    First_line_copy: "Dòng đầu tiên của copywriting",
    hint_108: "Vui lòng nhập bản sao，hỗ trợ tối đa 10 ký tự",
    Second_line_copy: "Dòng văn bản thứ hai.",
    Please_enter_the_first_line_of_text: "Vui lòng nhập dòng đầu tiên của bản sao.",
    Please_enter_the_second_line_of_text: "Vui lòng nhập dòng văn bản thứ hai.",
    Effective_date: "Ngày hợp lệ",
    Display_copy: "Hiển thị copywriting",
    Gift_channels: "Kênh tặng quà",
    Login_rewards: "Phần thưởng đổ bộ.",
    Accumulated_Gifts: "Tặng tích lũy.",
    Additional_rewards_for_app_login: "Phần thưởng bổ sung cho việc đăng nhập APP",
    hint_109: "Mời nhập phần thưởng đăng nhập，là 0 thì không thưởng.",
    Please_enter_the_reward_amount: "Vui lòng nhập số tiền thưởng.",
    Login_Activity_Rewards: "Phần thưởng chiến dịch đăng nhập",
    Please_enter_display_copy: "Vui lòng nhập bản sao hiển thị",
    Please_enter_the_login_reward: "Mời nhập phần thưởng đăng nhập.",
    Please_enter_a_valid_date: "Vui lòng nhập ngày hợp lệ",
    Please_enter_to_select_the_gift_channel: "Vui lòng nhập để chọn kênh tặng quà.",
    Login_reward_activity: "Chiến dịch tiền thưởng hạ cánh",
    Download_copy_configuration: "Tải xuống cấu hình sao chép",
    Lottery_time: "Thời gian rút thưởng.",
    Activity_ID: "ID hoạt động",
    Accessing_Users: "Truy cập người dùng",
    Participating_users: "Người dùng tham gia",
    Data_details: "Dữ liệu chi tiết.",
    Channel_sources: "Nguồn Channel Source",
    Click_on_the_number_of_lucky_draws: "Nhấp vào số lần rút thăm trúng thưởng",
    Page_visits: "Số lượt truy cập trang",
    Number_of_times_participating_in_the_lottery: "Số lần tham gia rút thăm trúng thưởng.",
    Winning_users: "Người chiến thắng",
    Users_who_win_physical_prizes: "Người dùng chiến thắng vật lý",
    Click_to_go_to_the_assistant_user: "Click vào nút Small Assistant User",
    Click_to_download_app_users: "Click vào Download APP User",
    Award_pool_type: "Loại pool giải thưởng",
    Lucky_Wheel: "Bàn xoay may mắn",
    Super_Wheel: "Siêu bàn xoay.",
    Lottery_Record_Daily_Turntable: "Hồ sơ rút thăm trúng thưởng - Vòng quay hàng ngày",
    Physical_rewards: "Phần thưởng bằng hiện vật",
    Draw_lottery_tickets: "Rút vé thưởng.",
    Processing_personnel: "Xử lý người.",
    Not_issued: "Không phát hành.",
    Issued: "Đã phát hành.",
    Virtual: "Hư cấu.",
    Operation_turntable: "Bàn xoay vận hành",
    Activity_Management: "Quản lý sự kiện",
    Lottery_record: "Kỷ lục rút thăm trúng thưởng.",
    Operation_turntable_activity_data: "Dữ liệu hoạt động của bàn xoay vận hành",
    Activity_type: "Các loại hoạt động",
    Activity_carousel: "Bàn xoay hoạt động",
    Daily_turntable: "Bàn xoay hàng ngày",
    Activity_statistics: "Thống kê hoạt động",
    Number_of_super_lottery_tickets_distributed: "Số lượng vé trúng thưởng siêu cấp.",
    Lucky_Wheel_Lucky_Draw_Times: "Số lần rút thăm may mắn.",
    Super_Wheel_Lottery_Times: "Số lần rút thăm trúng thưởng siêu vòng quay.",
    Basic_information: "Thông tin cơ bản",
    Activity_Rules: "Quy tắc hoạt động",
    Please_enter_activity_rules: "Vui lòng nhập quy tắc hoạt động",
    PC_banner_image: "Biểu đồ ngân hàng máy tính",
    H5_Jump_Page: "h5 trang nhảy",
    In_use: "Đang sử dụng.",
    Total_inventory: "Tổng hàng tồn kho",
    Prizes: "Giải thưởng.",
    Single_day_upper_limit: "Giới hạn một ngày",
    Individual_users_can_receive_upper_limits: "Single user get cap (Người dùng cá nhân được giới hạn)",
    Lucky_draw_probability_in_tens_of_thousands_: "Xác suất rút thăm trúng thưởng (vạn phần)",
    Is_it_a_running_horse_lamp_: "Có phải đèn chạy ngựa hay không?",
    Is_it_a_bottom_line: "Có bao che hay không?",
    Add_prize_pool: "Thêm tiền thưởng",
    Cloning: "Nhân bản.",
    Single_consumption: "Tiêu thụ một lần.",
    Daily_limit: "Giới hạn hàng ngày",
    Activity_channels: "Kênh hoạt động",
    H5_banner_diagram: "Biểu đồ h5 banner",
    How_to_earn_cat_coins: "Làm thế nào để kiếm tiền mèo?",
    Please_select_the_activity_time_range: "Vui lòng chọn khung thời gian hoạt động",
    Edit_anchor_recommendation_group: "Biên tập viên Anchor Recommendation Group",
    Add_a_new_anchor_recommendation_group: "Thêm nhóm giới thiệu neo mới",
    Please_upload_the_PC_banner_image: "Vui lòng tải lên biểu đồ pc banner",
    Please_upload_the_h5_banner_image: "Vui lòng tải lên biểu đồ h5 banner.",
    Please_enter_PC_Jump_Page: "Vui lòng nhập trang nhảy pc",
    Please_enter_h5_to_jump_to_the_page: "Vui lòng nhập trang nhảy h5",
    Please_enter_a_single_consumption: "Vui lòng nhập một lần tiêu thụ.",
    Please_enter_the_daily_limit: "Vui lòng nhập giới hạn hàng ngày.",
    Please_select_the_activity_channel: "Vui lòng chọn kênh hoạt động",
    Prize_Pool: "Hồ thưởng.",
    hint_110: "Thời gian mở hồ thưởng không được sớm hơn thời gian mở hoạt động.",
    hint_111: "Thời gian kết thúc giải thưởng không được chậm hơn thời gian kết thúc hoạt động.",
    The_total_probability_does_not_equal_10000: "Tổng xác suất không bằng 10.000",
    Sum_of_probabilities: "Tổng số xác suất",
    Please_select_the_prize_for_prize_: "Vui lòng chọn giải thưởng cho {số}",
    Please_enter_the_total_inventory_of_prize_: "Vui lòng nhập tổng số cổ phiếu của giải thưởng {số}",
    _The_daily_limit_is_greater_than_the_total_inventory: "{num} giới hạn một ngày lớn hơn tổng số hàng tồn kho.",
    hint_112: "Vui lòng nhập xác suất rút thăm trúng thưởng (10.000)",
    hint_115: "Vui lòng chọn giải thưởng {num} có đèn chạy bộ hay không.",
    hint_116: "Mỗi phần thưởng phải chọn một phần thưởng!",
    hint_114: "Vui lòng điền vào thời gian có hiệu lực của hồ thưởng.",
    hint_113: "Có xác nhận xóa bỏ phần thưởng này không?",
    Data_Overview: "Tổng quan về dữ liệu",
    Quantity_of_currency: "Số lượng tiền tệ",
    Please_enter_the_quantity_of_currency: "Vui lòng nhập số tiền",
    Number_of_lottery_tickets: "Số lượng vé thưởng",
    Please_enter_the_number_of_lottery_tickets: "Vui lòng nhập số lượng vé thưởng.",
    Select_icon: "Chọn icon",
    Please_select_the_type_of_prize: "Vui lòng chọn loại giải thưởng",
    Please_upload_the_icon: "Xin vui lòng tải lên icon.",
    Editing_prizes: "Giải thưởng biên tập",
    Please_enter_a_positive_integer: "Vui lòng nhập số nguyên dương",
    single_1: "Một",
    submit_results_1: "Gửi kết quả",
    rules_options: "Cấu hình quy tắc",
    rules_options_1: "Cấu hình quy tắc không được để trống",
    edit_activity_cai: "Biên tập viên thi đoán",
    created_topic_1: "Tạo chủ đề",
    // 新增
    created_topic_2: "Chủ đề biên tập",
    briefing_topic_1: "Giới thiệu về chủ đề",
    briefing_topic_2: "Hình thu nhỏ của chủ đề",
    hint_899: "Chỉ có thể tải lên tệp jpg/png, giới hạn 1M, kích thước 790 * 215px",
    hint_907: "Chỉ có thể tải lên tệp jpg/png, giới hạn 1M, kích thước 150 * 58px",
    hint_917: "Chỉ có thể tải lên tệp jpg/png, giới hạn 1M, kích thước 355 * 148px",
    hint_918: "The recommended image size is 256x256, and only jpg/png files can be uploaded",
  
    lookDetail: "xem",
    text_1: "Bạn có chắc chắn về việc hạ bệ không?",
    text_2: "Bạn có chắc chắn về việc lên kệ không?",
    // 新增2
    matchPlan_1: 'Tiến độ trận đấu',
    group_1: 'nhóm nhỏ',
    shopEdit: 'Chỉnh sửa sản phẩm',
    viShopEditName: 'Tên sản phẩm bằng tiếng Việt',
    viShopEditName1: 'Vui lòng nhập tên sản phẩm bằng tiếng Việt',
    hint_1001: "Mua mèo không được để trống",
    hint_1002: "Mua kim cương không được để trống",
    hint_1003: 'Vui lòng nhập tiền mèo',
    // 新增3
    remark_edit: 'Ghi chú biên tập',
    currency_cat: 'đồng xu mèo',
    diamond_1: 'kim cương',
    Invitee_UID_1: 'UID mời',
    Invitee_edit_1: "Mời cấu hình",
    minute_1: 'phút',
    establish_1: 'tạo ra',
    hint_1005: "Thống kê chiến thắng bóng đá",
    hint_1006: "Thống kê chiến thắng bóng rổ",
    hint_1007: "Chi tiết trúng thưởng",
    hint_1008: 'Bắt đầu',
    hint_1009: 'dừng lại',
    hint_1011: "Chưa điền",
    hint_1012: "Chọn biểu đồ banner",
    hint_1013: "trang nhảy pc",
    hint_1014: "Sử dụng",
    hint_1015: 'ID giải thưởng',
    hint_1016: "Vé trúng thưởng",
    hint_1017: "Liên kết",
    hint_1018: "ID thông tin",
    hint_1019: "Tải lên hình thu nhỏ nhạc",
    hint_1021: "Giới hạn xếp hạng chữ cái tiếng Anh",
    hint_1022: "Live interroom Push tự động bật lên",
    advertising_title: "Tiêu đề quảng cáo",
    Author_name_1: "Tác giả",
    // 新增4
    text_7: "Người dùng tin nhắn riêng",
    text_8: "Người dùng tin nhắn riêng",
    text_9: 'Quản lý thư riêng của người dùng',
    text_10: "Cấp bậc người dẫn chương trình trò chuyện riêng",
    text_11: 'Logo trên cùng của trang tải về',
    text_12: 'Trạng thái trang album APP/H5',
    text_13: 'Biểu đồ 3x iOS',
    text_14: 'Bài thứ nhất',
    text_15: 'Phần 2',
    text_16: 'Bài thứ ba',
    text_17: 'Phần 4',
    text_18: 'Nội dung của ${num} không thể để trống',
    text_19: "Hình ảnh thứ ${num} không thể để trống",
    text_20: 'Kích thước ảnh được đề xuất là 40x40 và chỉ có thể tải lên tệp jpg/png/gif',
    text_21: 'Kích thước ảnh được đề xuất là 40x40 và chỉ có thể tải lên tệp jpg/png/gif',
    text_22: 'Tên trang album PC',
    text_23: 'Vui lòng nhập URL tương ứng với src, để đếm nhiều tên miền, hãy phân biệt bằng ||',
    text_24: 'Giới thiệu về cấu hình sao chép trang',
    text_25: "Vui lòng điền nội dung bản sao",
    text_26: "Vui lòng nhập tài khoản nhà phát triển",
    text_27: "Trang khởi động mới",
    text_28: "Không cần cập nhật",
    text_29: 'Cập nhật tùy chọn',
    text_30: 'Buộc cập nhật',
    text_31: 'Khóa công khai',
    text_32: 'Khóa riêng',
    text_33: 'Mô tả chức năng',
    text_34: 'Cấu hình Android',
    text_35: "Phòng truyền hình trực tiếp gửi lì xì",
    text_36: "Tặng quà trực tiếp",
    text_37: 'Khởi động báo cáo điểm chôn APP',
    text_38: 'Loại tên miền',
    text_39: 'Sửa tên miền',
    text_40: 'Thêm tên miền',
    have_1: 'có',
    have_2: 'không có',
    updateTime_1: 'Thời gian cập nhật',
    text_41: 'Bạn chắc chắn muốn xóa tên miền?',
    text_42: "Sau khi bỏ lệnh cấm, từ này có thể tiếp tục được gửi trên nền tảng để xác nhận bỏ lệnh cấm không?",
    text_43: 'Địa chỉ banner URL',
    text_44: 'H5banner',
    text_45: 'Vui lòng nhập description',
    text_46: 'Vui lòng nhập tiêu đề trang đầu',
    text_47: 'Vui lòng nhập tiêu đề trang trực tiếp',
    text_48: "Chỉ có thể upload file jpg/jpeg/png, hỗ trợ upload tối đa 9 file",
    text_49: 'Tải lên tệp video',
    text_50: 'Vui lòng nhập ID vòng tròn',
    text_51: 'Hỗ trợ định dạng: JPG, PNG, GIF, MP4, Kích thước: Trong vòng 3M:',
    text_52: 'Chưa được công bố',
    text_53: 'Vui lòng nhập giới thiệu',
    text_54: 'Chỉ có thể tải lên tệp jpg/png/gif, giới hạn 1M, kích thước 790 * 215px',
    text_55: 'Đặt giới hạn số người',
    text_56: 'Thời gian đăng bài',
    text_57: 'Vui lòng đặt giới hạn số người',
    text_58: "Số lượng tối đa không được nhỏ hơn 50 người",
    text_59: 'Tên thật',
    text_60: 'Đồng ý',
  
    text_61: 'Tạo số vòng tròn',
    text_62: 'Số lượng người dùng',
    text_63: 'Thời gian gia nhập',
    text_64: 'Số chứng minh thư:',
    text_65: 'Vui lòng nhập số điện thoại',
    text_66: 'Tác giả',
    text_67: 'Thể thao',
    text_68: 'Giải trí',
    text_69: 'Khác',
    text_70: 'Chỉ có thể tải lên tệp jpg/png, kích thước ảnh cần là 120X120',
    text_71: 'Thêm kênh',
    text_72: 'Tất cả các kênh',
    channel_1: 'kênh',
    text_73: 'chỉ định phòng',
    text_74: 'Thành công',
    text_75: 'Địa điểm',
    text_76: 'Hình nền PC',
    text_77: 'Biểu đồ tiêu đề PC',
    text_78: 'Giải thích cách chơi',
    text_79: 'Thời gian xem trực tiếp (phút)',
    text_80: 'Xác suất',
    text_81: "0 là không thể rút trúng bao lì xì, đây là xác suất người dùng rút trúng bao lì xì có thưởng",
    text_82: 'Thời gian đăng ký',
    text_84: 'Trạng thái thưởng',
    text_85: 'Phần thưởng',
    text_86: "Bạn có chắc chắn sẽ nhận được phần thưởng không?",
    text_87: 'Kiểu chữ ký',
    text_88: 'Gửi người dùng',
    text_89: 'Vui lòng nhập người gửi userid',
    text_90: 'ID lì xì',
    text_91: 'Gửi biệt danh',
    text_92: 'Số phòng',
    text_93: 'Vui lòng nhập số phòng đã gửi',
    text_94: "Tiền lì xì",
    text_95: 'Số lượng',
    text_96: 'Số không hợp lệ',
    text_97: ' Thời gian gửi',
    text_98: 'Đã kích hoạt',
    text_99: 'Chủ đề ID',
    text_101: 'Thắng hay thua',
    text_102: 'ID mưa tiền lì xì',
    text_103: 'Tổng số bao lì xì',
    text_104: 'Hiệu lực',
    text_105: 'Vô hiệu hóa',
    text_106: 'Giải đấu bị ràng buộc',
    text_107: 'Số lượng bao lì xì',
    text_108: 'Số người nhận',
    text_110: 'Hoạt động',
    text_111: 'Nhận',
    text_112: 'Căn phòng',
    text_113: 'Chia sẻ nền tảng',
    text_114: 'Thời gian đăng nhập',
    text_115: 'Đăng ký IP',
    text_116: 'Thời gian online',
    text_117: 'Trạng thái gửi:',
    text_118: 'Thay đổi thời gian',
    text_119: 'Số lần hiển thị',
    text_120: 'ID trận đấu trực tiếp',
    text_121: 'Cách chơi',
    text_122: 'Nội dung đẩy',
    text_123: 'Cuộc thi',
    text_124: 'Hỗ trợ',
    text_125: 'Kết quả',
    text_126: 'phản đối',
    text_127: 'Sửa kết quả',
    text_128: 'Số tiền thưởng',
    text_129: 'Tổng phát hành',
    text_130: 'Người tố cáo',
    text_131: 'Mô tả',
    text_132: 'Trả lời',
    text_133: 'Bối cảnh APP',
    // 新增5
    live_time: 'Thời gian phát sóng trực tiếp (phút)',
    Please_select_the_results: 'Vui lòng chọn kết quả',
    red_1: 'đỏ',
    black_1: 'đen',
    go_1: 'đi',
    corner: 'Góc',
    win_or_lose: 'thắng hay thua',
    Size_division: 'Kích thước',
    take_care_1: 'Lưu ý: Kích thước 572x420',
    take_care_2: 'Lưu ý: Kích thước 926x126',
    discount_1: 'giảm giá',
    discount_name_1: 'Tên giảm giá',
    text_135: 'Vui lòng nhập nickname kênh',
    characters_1: 'văn bản',
    picture_1: 'Hình ảnh',
    Please_fill_in_the_link_1: 'Vui lòng điền vào liên kết',
    Please_fill_in_the_link_2: ' Vui lòng điền vào liên kết chính xác',
    publish_this_announcement: 'Bạn có chắc chắn về việc công bố thông báo này không?',
    send_state: 'Gửi trạng thái',
    Create_marketing_messages: 'Tạo tin nhắn tiếp thị',
    SMS_content: 'Nội dung tin nhắn',
    View_receiving_users: 'Xem người dùng đã nhận',
    Upload_list: 'Danh sách tải lên',
    Are_you_sure_to_cancel: 'Xác nhận hủy bỏ',
    User_Import_Template: 'Người dùng nhập mẫu',
    channel_2: 'kênh',
    autograph: 'chữ ký',
    SMS_type: 'Loại tin nhắn',
    SMS_template_editing: 'Sửa mẫu SMS',
    SMS_template_add: 'Thêm mẫu tin nhắn SMS',
    Editing_time: 'Thời gian chỉnh sửa',
    instant_push: 'tức thì push',
    timed_push: 'thời gian đẩy',
    Trang_H5: 'Trang H5',
    Menu_Type: 'Loại menu',
    Role_authorization: 'ủy quyền vai trò',
    name_111: ' Tên',
    role_111: 'nhân vật',
    Modifying_Merchant_Basic_Information: 'Sửa đổi thông tin cơ bản của người bán',
    Gift_ID: 'ID quà tặng',
    Number_of_deliveries: 'Gửi số',
    Delivery_value_1: "Giá trị cho đi (tiền vàng/vạn)",
    broadcast_room_1: 'Gửi phòng trực tiếp',
    broadcast_room_2: 'Vui lòng nhập phòng phát sóng trực tiếp',
    broadcast_room_3: 'Cho đi giá trị',
    Gift_giving_time: 'Thời gian tặng quà',
    Online_1: 'Đã online',
    Gift_unit_price: 'Đơn giá quà tặng',
    hint_1110: 'Chỉ có thể tải lên tệp jpg/png, kích thước không quá 1MB!',
    hint_1112: 'Hiệu ứng xem trước',
    hint_1113: 'Chỉ có thể tải lên tệp gif, kích thước không quá 1MB!',
    hint_1114: 'Hiệu ứng chơi',
    hint_1115: 'Chỉ có thể tải lên tệp svga, kích thước không vượt quá 10MB!',
    Full_platform: 'Nền tảng đầy đủ',
    This_room: ' phòng này',
    hint_1116: 'Chỉ tải lên chỉ tải lên tệp jpg/png!',
    Type_of_issuance: 'Kiểu tóc dưới',
    Designated_category: 'Chỉ định lớp',
    hint_1117: 'Vui lòng nhập số phòng được chỉ định, cách nhau bằng dấu phẩy bằng tiếng Anh giữa nhiều phòng',
    hint_1118: 'Vui lòng nhập ID quà tặng',
    hint_1119: 'Nhập tối đa 99 ID quà tặng',
    hint_1120: 'Không thể nhập khoảng trắng',
    hint_1121: 'Không thể nhập dấu phẩy Trung Quốc',
    hint_1122: 'Chỉ có thể nhập tiếng Trung, tiếng Anh, số',
    hint_1123: 'Vui lòng chọn kiểu tiếp theo',
    hint_1124: 'Vui lòng chọn phạm vi tiếp theo',
    hint_1125: 'Vui lòng chọn loại',
    hint_1126: 'Vui lòng chỉ định phòng',
    Publishable_status: 'Trạng thái có thể xuất bản',
    Expert_nickname: 'Biệt danh chuyên gia',
    Expert_brief: 'Hồ sơ chuyên gia',
    Expert_detail_1: 'Chi tiết túi gấm',
    article_1: "Á Miên 15 vòng thi điểm tích lũy 13 điểm, xếp hạng 18, ở khu phụ gia xuống cấp; Lượt đấu trước sân khách hoàn toàn bại, gặp phải hai lần liên tiếp bại, trạng thái thấp; Mà sân nhà bọn họ 3 thắng 4 thua, xếp hạng 12, tuy rằng biểu hiện không xuất sắc, nhưng vẫn dựa vào sân nhà đoạt điểm; Về mặt đội hình, đội bóng có 1 người bị thương ở giữa sân, 2 người còn nghi ngờ khi cầu thủ xuất chiến. Đội khách xếp thứ hai trong bảng xếp hạng Pháp Giáp mùa giải trước, mùa giải này trượt dốc nghiêm trọng, hiện nay điểm tích lũy 15 lượt đấu là 10 điểm, xếp hạng 19, nằm trong khu vực giảm cấp. Trận đấu trước sân khách thua, đội bóng cũng gặp phải hai trận thua liên tiếp, trạng thái kém; Mùa giải này sân khách 2 thắng 1 hòa 4 thua, xếp hạng 13, tuy biểu hiện bình thường, nhưng toàn bộ thắng lợi giành được trên sân khách, có năng lực khách chiến nhất định; Về mặt đội hình, đội bóng bị lãnh đạo bị thương, hiện có 14 cầu thủ bị thương. Chỉ số ban đầu của châu Á mở ra bàn bình tay và bình nửa bàn chủ yếu cho trận đấu này, dành sự ủng hộ cẩn thận cho đội khách; Một số cơ quan trong chỉ số mới nhất điều chỉnh nhiều thành bàn ngang tay, niềm tin của cơ quan đối với đội khách có phần suy yếu; Chỉ số trung bình mới nhất của Âu Bồi đè thấp thắng thua bồi thường, nhiều cơ quan đồng thời đè thấp bồi thường, nâng bồi thường lên, bất lợi cho đội khách",
    user_1111: 'Người dùng',
    Import_1111: 'Nhập khẩu',
    hint_1127: 'Đã đạt đến giới hạn bổ sung',
    Closed_111: 'Đóng cửa',
    Opened_111: 'Mở',
    Select_111: 'Chọn',
    less_than: 'nhỏ hơn',
    Publisher_11: 'Nhà xuất bản',
    Let_the_ball: 'Hãy để quả bóng',
    analysis: 'Phân tích',
    Please_enter_the_parsing_content: 'Vui lòng nhập nội dung phân tích',
    price_11: 'giá bán',
    free_11: 'Miễn phí',
    release_1: 'Xuất bản',
    hint_1200: 'Tiêu đề cần nhiều hơn 4 từ',
    hint_1201: "Lý do đề xuất cần hơn 20 từ",
    Ball_selection: 'Lựa chọn bóng',
    Top_failure_time: 'Thời gian hết hạn',
    refund: 'hoàn tiền',
    Topping_time: 'Đặt đỉnh thời gian',
    System_messages: 'Thông điệp hệ thống',
    Please_enter_the_message_content: 'Vui lòng nhập nội dung tin nhắn',
    abnormal: 'bất thường',
    Let_the_ball_win: 'để bóng thắng',
    Please_set_the_time: 'Vui lòng đặt thời gian',
    Please_message_content: 'Vui lòng điền nội dung tin nhắn',
    hint_20001: "Thời gian đặt đỉnh không thể sớm hơn thời gian hiện tại",
    hint_20002: 'Bạn có chắc chắn rằng bạn đã hủy bỏ đỉnh không?',
    hint_20003: 'Thời gian xây dựng kho',
    Purchase_records: 'Hồ sơ mua hàng',
    // 新增8
    Edit_Resource_Bits: 'Chỉnh sửa các bit tài nguyên',
    ADD_Resource_Bits: 'Thêm bit tài nguyên',
    hint_20091: 'Di chuyển lên',
    hint_20092: 'Di chuyển xuống',
    hint_20093: 'hot mới',
    hint_20094: 'Video',
    hint_20095: 'Trang',
    hint_20096: 'Thêm nền',
    hint_20097: 'Bạn có chắc chắn muốn gỡ bỏ hình nền này không?',
    hint_20098: 'Chỉ có thể tải lên tệp jpg/jpeg/png, tỷ lệ khung hình 60 * 60',
    //新增9
    hint_20111: 'Vui lòng chọn thư viện bắn súng',
    hint_20112: 'Vui lòng nhập kiểu gửi tiếp',
    hint_20113: 'Vui lòng nhập phạm vi tiếp theo',
    hint_20114: 'Bạn có chắc muốn {status} cấu hình này không?',
    hint_20115: 'Đã {status}',
    hint_20116: 'Nội dung màn bom không thể để trống',
    hint_20117: 'Thêm tối đa 1000 mục một lần.',
    hint_20118: 'Thêm thời gian',
    hint_20119: "Sau khi bật, người dẫn chương trình sẽ quay trở lại robot, bạn có chắc chắn không?",
    hint_20121: 'Loại phát thanh viên',
    hint_20122: 'Vui lòng nhập ID',
    hint_20123: "Quản lý bất động sản",
    hint_20124: 'Vui lòng nhập uid',
    hint_20125: 'Người dùng userId',
    hint_20126: 'Vui lòng nhập userId',
    hint_20127: 'Vui lòng nhập tiêu đề phát sóng trực tiếp',
    hint_20128: 'Hợp nhất tài khoản',
    hint_20129: 'Phạm vi thông điệp',
    hint_20130: 'Tin nhắn này',
    hint_20888: 'Tin tức trong phòng truyền hình trực tiếp',
    hint_20131: 'Tất cả các tin tức trực tiếp',
    hint_20132: 'Xóa lý do',
    hint_20133: 'Cấm ngôn người dùng',
    hint_20134: 'Người dùng cấm ngôn',
    hint_20135: 'UID không thể để trống',
    hint_20136: "Chu kỳ cấm ngôn không thể để trống",
    hint_20137: "Nguyên nhân cấm ngôn không thể để trống",
    hint_20138: 'Số phòng không được để trống',
    hint_20139: 'Vui lòng nhập tên người dùng',
    hint_20400: 'Bắt đầu đánh giá',
    hint_20401: 'Đánh giá thất bại',
    hint_20402: 'Xuất Excel',
    hint_20403: 'Chu kỳ thanh toán',
    hint_20404: 'Số tiền điều chỉnh:',
    hint_20405: 'Nguyên',
    hint_20406: 'Không bắt đầu',
    hint_20407: 'Thanh toán hóa đơn',
    hint_20408: 'Trước khi biên tập',
    hint_20410: 'Sau khi chỉnh sửa',
    hint_20411: 'Đang chờ xét xử',
    hint_20412: 'Thêm bang hội',
    hint_20413: 'Vui lòng nhập tên tài khoản nhận tiền',
    hint_20414: 'Vui lòng nhập dòng mở tài khoản',
    hint_20415: 'Vui lòng nhập số thẻ ngân hàng',
    hint_20416: 'Vui lòng nhập địa chỉ mở tài khoản',
    hint_20417: 'Vui lòng nhập trong vòng 100 từ',
    hint_20418: 'Hiệp hội biên tập',
    hint_20419: 'guild này sẽ không thể được gán cho neo sau khi bị vô hiệu hóa, bạn có chắc chắn rằng guild này sẽ bị vô hiệu hóa không?',
    hint_20420: 'Chỉ có thể tải lên tệp jpg/png, kích thước đề xuất là',
    hint_20421: 'Tài khoản cấu hình',
    hint_20422: 'Tìm nội dung',
    hint_20423: 'Nội dung thay thế',
    hint_20424: 'Địa chỉ nhảy nguồn tín hiệu',
    hint_20425: 'Vui lòng nhập địa chỉ nguồn tín hiệu',
    hint_20426: 'Vui lòng nhập địa chỉ nhảy nguồn tín hiệu',
    hint_20427: 'Vui lòng điền vào sơ đồ ios',
    hint_20428: 'Vui lòng điền thông tin',
    hint_20429: 'Số phiên bản giao diện',
    hint_20430: 'Người cấu hình',
    hint_20431: 'Không có video ngắn nào được chọn, vui lòng chọn video ngắn trước khi đăng!',
    hint_20432: 'Phát hành thành công',
    hint_20433: 'Đăng thành công, video ngắn này vào thư viện phát hành',
    hint_20434: 'Thời gian leo lên',
    hint_20435: 'Vui lòng chọn khoảng thời gian',
    hint_20436: 'Vô hiệu hóa',
    hint_20437: 'Thiết lập thành công',
    hint_20438: 'Có nên đặt đỉnh hay không',
    hint_20439: 'sắp xếp vị trí',
    hint_20440: 'Bình luận',
    hint_20441: 'bìa video ngắn',
    hint_20442: 'Chỉnh sửa đỉnh',
    hint_20443: 'Vui lòng nhập vị trí sắp xếp',
    hint_20444: 'Không thể sửa đổi sau khi phát hành, bạn có xác nhận phát hành không?',
    hint_20445: 'Truy cập kênh',
    hint_20446: 'Tên miền nguồn',
    hint_20447: 'Trợ lý nhỏ UV',
    notice_111: 'Thông báo UV',
    index_111: 'chỉ số',
    test_111: "Bạn có chắc chắn muốn {status} chủ đề này không?",
    test_112: 'Đăng chủ đề',
    test_113: 'Bạn có chắc chắn muốn xóa?',
    Batch_approval: 'phê duyệt hàng loạt',
    Reviewer_111: 'Người đánh giá',
    you_want_to_pass: 'Bạn có chắc chắn muốn vượt qua không?',
    Download_failed: 'Tải xuống không thành công',
    Individual_currency: 'Mẫu phát hành hàng loạt tiền tệ cá nhân',
    pass_all_of_them: 'Bạn có muốn vượt qua tất cả?',
    Fill_in_at_least: 'Điền vào ít nhất một trong số các loại tiền tệ bổ sung, điểm kinh nghiệm bổ sung',
    quantity_of_additional_currency: 'Vui lòng nhập số tiền bổ sung',
    money_111: 'số tiền',
    Input_cannot_be_greater: 'Đầu vào không thể lớn hơn 99999 hoặc nhỏ hơn 1',
    Recharge_status: 'Trạng thái nạp tiền',
    Gold_coins: 'đồng tiền vàng',
    Alipay_payment: 'thanh toán bằng Alipay',
    WeChat_payment: 'thanh toán qua WeChat',
    ApplePay: 'thanh toán bằng apple',
    Unpaid_111: 'Chưa thanh toán',
    Paid_111: 'Đã thanh toán',
    Balance_ranking: 'Số dư',
    ranking_111: 'Xếp hạng',
    // 新增==
    Member_Change_Record: 'Bản ghi thay đổi thành viên',
    phone_area_code: 'Vui lòng đặt mã vùng điện thoại',
    your_phone_number: 'Vui lòng đặt số điện thoại',
    Successfully_lifted: 'Giải trừ cấm ngôn thành công',
    // 新增
    Risk_categories: 'Loại rủi ro',
    No_type_selected: 'Không chọn loại',
  
    // 小助手新增
    Click_here_to_retry: 'Thử lại lần nữa',
    Loading_failed: 'tải không thành công',
    Invalid_data: 'Dữ liệu không hợp lệ',
    Insert_Image: 'Chèn ảnh',
    download_111: 'Tải về',
    not_support_preview: 'Tập tin này không hỗ trợ xem trước',
    hint_801: 'Tập tin quá lớn, vui lòng tải lại',
    hint_802: 'Định dạng tập tin sai, vui lòng tải lên lại',
    hint_803: 'Tải lên tệp không thành công',
    hint_804: 'Tập tin vượt quá giới hạn',
    Member_Information: 'Thông tin thành viên',
    Personnel_changes: 'Thay đổi nhân sự',
    Anchor_Circle: 'Vòng tròn dẫn đường',
    Total_number_of_people: 'Tổng số người',
    New_additions_today: 'mới hôm nay',
    Today_decrease: "Giảm hôm nay",
    Today_cumulative_changes: 'Thay đổi tích lũy ngày hôm nay',
    person_111: 'người',
    // 新增222
    Edit_anchor_circle: 'Chỉnh sửa vòng tròn',
    Administrator: 'Quản trị viên',
    Please_select_an_administrator: 'Vui lòng chọn quản trị viên',
    Anchor_Circle_Name: 'Tên vòng tròn dẫn',
    Anchor_Circle_ID: 'Số vòng lặp',
    Anchor_Circle_Avatar: 'Hình đại diện vòng tròn',
    Number_of_members: 'Số lượng thành viên',
    Free_speech: 'tự do ngôn luận',
    All_staff_are_prohibited_from_speaking_1: 'Cấm tất cả',
    Group_entry_time: 'thời gian nhóm',
    Restricted_person: 'Giới hạn người',
    Is_it_in_the_group: 'có trong nhóm hay không',
    Remove: 'di chuyển',
    Enter: "Nhập",
    Reason: "Lý do",
    Operator: 'Nhà điều hành',
    Edit_Community_Circle: 'Cộng đồng biên tập',
    Add_social_circles: 'Thêm cộng đồng mới',
    Community_nickname: 'nickname cộng đồng',
    Community_circle_avatar: 'hình đại diện cộng đồng',
    Group_Leader: 'Chủ nhóm',
    All_staff_are_prohibited_from_speaking: 'Tất cả đều bị cấm',
    Please_enter_the_social_circle_nickname: 'Vui lòng nhập nickname',
    Please_select_the_community_circle_avatar: 'Vui lòng chọn hình đại diện cộng đồng',
    Please_select_the_group_leader: 'Vui lòng chọn chủ nhóm',
    Please_select_the_speaking_status: 'Vui lòng chọn trạng thái phát biểu',
    Community_Circle_ID: 'ID cộng đồng',
    Community_Circle_Name: 'Tên cộng đồng',
    Are_you_sure_you_want_to_disable_this_circle: 'Bạn có chắc là bạn muốn vô hiệu hóa vòng tròn này không?',
    Are_you_sure_you_want_to_enable_this_circle: 'Xác nhận bạn muốn kích hoạt vòng tròn?',
    User_cancels_operation: 'Hành động hủy bỏ của người dùng',
    Speech_status: 'trạng thái phát biểu',
    Reason_for_group_membership: 'Lý do kéo nhóm',
    Group_pull_date: 'ngày nhóm kéo',
    Move_into_a_new_circle: 'Di chuyển vào vòng tròn mới',
    New_Circle_Name: 'Tên vòng tròn mới',
    Circle_Announcement: 'Thông báo vòng tròn',
    marked_6: 'Vui lòng điền đầy đủ thông báo liên kết thông tin trước khi gửi',
    Community_circle: 'cộng đồng',
    Community_Circle_Management: 'Quản lý cộng đồng',
    Anchor_Circle_Management: 'Quản lý vòng tròn',
    Please_enter_the_name_of_the_anchor_circle: 'Vui lòng nhập tên vòng tròn',
    Community_Circle_View: 'Xem cộng đồng',
    Anchor_Circle_View: 'xem vòng tròn',
    Edit_customer_service_group_classification: 'Chỉnh sửa phân loại nhóm dịch vụ khách hàng',
    Add_customer_service_group_classification: 'Phân loại nhóm khách hàng mới',
    Operation_failed: 'Hoạt động không thành công',
    Edit_traffic_entry: 'Chỉnh sửa lối vào giao thông',
    Add_traffic_entry: 'Thêm cổng giao thông',
    Entry_Name: 'Nhập tên',
    Entrance_ID: 'Nhập ID',
    Sort_Number: 'Số sắp xếp',
    Entry_Description: 'Mô tả lối vào',
    Entrance_background_image: 'Hình nền nhập cảnh',
    Classification_ID: 'ID phân loại',
    Policy_application_update: 'Cập nhật ứng dụng chính sách',
    Set_up_customer_service_group: 'Thiết lập nhóm dịch vụ khách hàng',
    Strategy_number: 'Số chiến lược',
    Tourists: 'Khách du lịch',
    Members: 'Thành viên',
    Reception_customer_service_team: 'Nhóm tiếp tân',
    Are_you_sure_you_want_to_deactivate_this_account: 'Bạn có chắc muốn vô hiệu hóa tài khoản này không?',
    Are_you_sure_you_want_to_enable_this_account: 'Bạn có muốn kích hoạt tài khoản này không?',
    Traffic_entrance: 'Lối vào giao thông',
    Traffic_Entry_Name: 'Tên cổng giao thông',
    Traffic_Entry_Description: 'Mô tả lối vào giao thông',
    Customer_service_group_classification: 'Phân loại nhóm dịch vụ khách hàng',
    Are_you_sure_you_want_to_disable_this_entry: 'Bạn có chắc muốn vô hiệu hóa lối vào này không?',
    Are_you_sure_you_want_to_enable_this_entry: 'Xác nhận bạn muốn kích hoạt lối vào này?',
    Entering_delay_time_in_milliseconds: 'Nhập thời gian trễ mili giây',
    marked_8: 'Vui lòng nhập thời gian trễ mili giây',
    Entering_display_time_in_milliseconds: 'Nhập thời gian hiển thị mili giây',
    marked_9: 'Vui lòng nhập thời gian hiển thị mili giây',
    marked_10: 'Khoảng thời gian người dùng hiển thị tin nhắn giả',
    marked_11: 'Vui lòng nhập khoảng thời gian vài phút khi người dùng hiển thị tin nhắn giả mạo',
    Entering_Chinese_script_content: 'Nhập nội dung ngôn ngữ',
    Global_configuration: 'Cấu hình toàn cầu',
    Edit_account: 'Tài khoản biên tập',
    Add_account: 'Thêm tài khoản',
    A_vest: "Áo gi - lê",
    Customer_service_1: 'Dịch vụ khách hàng',
    Account_type: 'Loại tài khoản',
    Account_avatar: 'Hình đại diện tài khoản',
    Account_nickname: 'Tên tài khoản',
    Please_enter_your_account_nickname: 'Vui lòng nhập nick name',
    Please_select_account_type: 'Vui lòng chọn loại tài khoản',
    Please_upload_your_account_avatar: 'Vui lòng tải ảnh đại diện',
    Please_select_account_status: 'Vui lòng chọn trạng thái tài khoản',
    Log_content: 'Nội dung nhật ký',
    marked_50: 'Số lượng',
    System_logs: 'Nhật ký hệ thống',
    Exporting: ' xuất khẩu...',
    marked_12: 'Thiếu các đối số cần thiết để xuất dữ liệu',
    Last_7_days: '7 ngày gần đây',
    In_the_past_30_days: '30 ngày qua',
    In_the_past_60_days: '60 ngày qua',
    In_the_past_90_days: '90 ngày qua',
    time_range: 'khoảng thời gian',
    System_account: 'Tài khoản hệ thống',
    marked_13: "Số lượng người dùng chuyển đổi mới trong ngày",
    marked_14: "Số lượng người dùng hủy chuyển đổi mới trong ngày",
    Total_number_of_converted_users: 'Tổng số người dùng đã chuyển đổi',
    Edit_private_script: 'Chỉnh sửa ngôn ngữ riêng tư',
    Add_private_script: 'Thêm ngôn ngữ riêng tư',
    Script_content: 'Nội dung ngôn ngữ',
    Script_state: 'Trạng thái ngôn ngữ',
    Please_enter_the_script: 'Vui lòng nhập từ',
    Please_select_the_script_status: 'Vui lòng chọn trạng thái ngôn ngữ',
    Are_you_sure_you_want_to_deactivate_this_script: 'Bạn có chắc muốn vô hiệu hóa thuật ngữ này không?',
    Are_you_sure_you_want_to_enable_this_script: 'Xác nhận bạn muốn kích hoạt từ này?',
    Edit_public_automatic_scripts: 'Chỉnh sửa tự động công cộng',
    Add_public_automatic_scripts: 'Thêm ngôn ngữ tự động công cộng',
    Script_Title: 'Tiêu đề thuật ngữ',
    Associated_traffic_entry: 'Cổng giao thông liên kết',
    Associate_with_customer_service_group: 'Nhóm dịch vụ khách hàng liên kết',
    Please_enter_the_script_title: 'Vui lòng nhập tiêu đề',
    Please_enter_the_sorting_number: 'Vui lòng nhập số sắp xếp',
    Please_select_the_associated_traffic_entry: 'Vui lòng chọn cổng giao thông liên kết',
    marked_15: 'Vui lòng chọn nhóm dịch vụ liên kết',
    Editing_public_language: 'Chỉnh sửa ngôn ngữ công cộng',
    Add_public_language_skills: 'Thêm ngôn ngữ công cộng',
    Script_number: 'Số từ',
    Public_speaking: 'Ngôn ngữ công cộng',
    Public_automatic_language: ' Ngôn ngữ tự động công cộng',
    marked_17: 'Bạn chắc chắn muốn vô hiệu hóa nhãn?',
    marked_16: 'Xác nhận bạn muốn kích hoạt thẻ?',
    Edit_custom_user_groups: 'Chỉnh sửa nhóm người dùng tùy chỉnh',
    Add_custom_user_groups: 'Thêm nhóm người dùng tùy chỉnh mới',
    Custom_user_group_name: 'Tên nhóm người dùng tùy chỉnh:',
    Custom_user_group_description: 'Mô tả nhóm người dùng tùy chỉnh',
    Master_Administrator: 'Giám đốc điều hành',
    Speech_settings: 'thiết lập speech',
    marked_18: 'Vui lòng nhập tên nhóm người dùng tùy chỉnh',
    Please_select_an_icon: 'Vui lòng chọn biểu tượng',
    Please_select_the_cover_image: 'Vui lòng chọn ảnh bìa',
    Please_select_the_primary_administrator: 'Vui lòng chọn người quản lý',
    Please_select_speech_settings: 'Vui lòng chọn cài đặt phát biểu',
    Circle: 'Vòng tròn.',
    Add_internal_members: 'Thêm thành viên mới',
    Operator_Name: 'Tên người điều hành',
    Vest_account_nickname: 'Tên tài khoản',
    Third_party_ID: 'ID của bên thứ ba',
    Business_ID: 'ID doanh nghiệp',
    Please_select_a_member: 'Vui lòng chọn thành viên',
    User_group_number: 'Số nhóm người dùng',
    User_group_name: 'Tên nhóm người dùng',
    User_group_label: 'Thẻ nhóm người dùng',
    Updated_by: 'Đã cập nhật',
    Member_management: 'Quản lý thành viên',
    Cancel_administrator: 'Hủy quản trị viên',
    Remove_user_group: 'Xóa nhóm người dùng',
    Set_as_administrator: 'Đặt làm quản trị viên',
    Business_UID: 'UID kinh doanh',
    Are_you_sure_you_want_to_proceed: 'Bạn có chắc chắn về hành động không?',
    User_ID: 'Số người dùng',
    User_group_user_records: 'Bản ghi người dùng nhóm người dùng',
    Previous_user_group: 'Nhóm người dùng trước',
    Current_user_group: 'Nhóm người dùng hiện tại',
    Explanation: 'Mô tả',
    Cancel_conversion: 'Hủy chuyển đổi',
    Initial_reporting_time: 'Thời gian báo cáo ban đầu',
    In_the_anchor_circle: 'trong vòng tròn',
    Community_Circle: 'cộng đồng',
    Are_you_sure_you_want: 'Bạn có chắc chắn muốn {num} người dùng không?',
    User_Information: 'Thông tin người dùng',
    Information_line: 'đường dây thông tin',
    Domain_Name_Source: 'Nguồn gốc tên miền',
    Please_enter_the_domain_name_source: 'Vui lòng nhập nguồn tên miền',
    Please_enter_ID: 'Vui lòng nhập ID',
    Access_time: 'Thời gian truy cập',
    Assistant_data: 'Dữ liệu trợ lý nhỏ',
    Customer_service_seat_details: 'Chi tiết chỗ ngồi',
    Force_offline: 'Buộc off line',
    Signal_status: 'Trạng thái tín hiệu',
    Seat_status: 'Trạng thái ngồi',
    Current_reception: 'Tiếp nhận hiện tại',
    Recently_offline: 'Đã offline',
    Recently_launched: 'Đã hoạt động gần đây',
    Currently_receiving_users: 'Người dùng hiện tại',
    There_are_currently_no_customers: 'Không có khách hàng',
    There_is_no_more: ' Không còn nữa',
    Seat_status_log: 'Nhật ký trạng thái ngồi',
    Signal_status_log: 'Nhật ký trạng thái tín hiệu',
  
    // 新增3333
    Offline: 'ngoại tuyến',
    Online: "Trực tuyến",
    Busy: "Bận rộn",
    Historical_status: 'Trạng thái lịch sử',
    Reception_traffic_entrance: 'Lối vào giao thông tiếp tân',
    Sender_User_ID: 'Gửi ID người dùng',
    User_type_of_sender: 'Gửi kiểu người dùng',
    Message_ID: 'ID tin nhắn',
    Message_type: 'Loại tin nhắn',
    Message_content: 'Nội dung tin nhắn',
    Private_conversation_ID_1: 'ID phiên trò chuyện riêng tư',
    Recipient_User_ID: 'ID người dùng của người nhận',
    Recipient_user_type: 'Loại người dùng người nhận',
    Sending_time: 'Gửi thời gian',
    Number_of_reception_sessions: 'Số phiên tiếp tân',
    View_current_chat_history: 'Xem lịch sử trò chuyện hiện tại',
    View_all_chat_records: 'Xem tất cả các cuộc trò chuyện',
    Number_of_receptionists: 'Số lượng tiếp tân',
    Private_conversation_ID: 'Cuộc trò chuyện riêng tư ID:',
    Please_enter_the_private_chat_session_ID: 'Vui lòng nhập ID phiên riêng',
    Session_start_time: 'Thời gian bắt đầu phiên',
    Customer_service_account_ID: ' ID tài khoản khách hàng',
    Customer_service_nickname: 'Biệt danh dịch vụ khách hàng',
    marked_19: "Phòng truyền hình trực tiếp",
    marked_20: "Người dùng kích hoạt thay đổi lối vào lưu lượng",
    marked_21: "Giải phóng khách hàng",
    Customer_service_switch_offline_release: 'Chuyển đổi offline',
    marked_1: "Tin nhắn trò chuyện riêng của người dùng gửi tín hiệu dịch vụ khách hàng offline",
    marked_2: "Gửi tin nhắn riêng tư cho khách hàng",
    marked_3: "Thông tin trò chuyện riêng của người dùng gửi đi trạng thái ngồi của dịch vụ khách hàng không rõ",
    marked_4: "Thông tin trò chuyện riêng tư của người dùng gửi đi thông tin thiếu sót về dịch vụ khách hàng",
    marked_5: "Thông tin trò chuyện riêng của người dùng gửi thông tin thay đổi lối vào lưu lượng",
    Unknown: 'Không rõ',
    Add_menu: 'Thêm menu',
    catalogue: 'thư mục',
    Menu: 'Thực đơn',
    Menu_icon: 'Biểu tượng menu',
    Menu_Title: 'Tiêu đề menu',
    Routing_address: 'Địa chỉ định tuyến',
    Whether_to_hide_or_not: 'ẩn hoặc không ẩn',
    Menu_Name: 'Tên menu',
    Permission_identifier: 'Nhận dạng quyền',
    Component_path: 'Đường dẫn thành phần',
    Please_enter_the_menu_title: 'Vui lòng nhập tiêu đề menu',
    Please_enter_the_routing_address: 'Vui lòng nhập địa chỉ định tuyến',
    Please_select_the_superior_category: 'Vui lòng chọn lớp cao hơn',
    Are_you_sure_you_want_to_delete: 'Bạn có chắc muốn xóa menu {num} không?',
    Please_enter_your_account_number: 'Vui lòng nhập số tài khoản',
    Please_enter_your_name: 'Vui lòng nhập tên',
    Please_select_a_role: 'Vui lòng chọn vai trò',
    Please_enter_password: 'Vui lòng nhập mật khẩu',
    marked_22: "Vui lòng chọn ít nhất một nhóm dịch vụ khách hàng",
    Reset_password: 'Đặt lại mật khẩu',
    Customer_Service_Group: 'Nhóm dịch vụ khách hàng',
    Role: "Vai trò",
    marked_23: 'Bạn chắc chắn muốn đăng xuất khỏi tài khoản?',
    marked_24: 'Bạn chắc chắn muốn vô hiệu hóa tài khoản?',
    marked_25: 'Bạn chắc chắn muốn đặt lại mật khẩu tài khoản?',
    Modify_profile_picture: 'Sửa ảnh đại diện',
    new_avatar: 'Avatar mới',
    Please_select_a_profile_picture: 'Vui lòng chọn hình đại diện',
    marked_26: 'Sửa đổi thành công, vui lòng đăng nhập lại',
    Change_nickname: 'Thay đổi nickname',
    Business_System_Api: 'Hệ thống kinh doanh Api',
    Conversion_System_Api: 'Chuyển đổi hệ thống API',
    Please_enter_the_business_system_Api: 'Vui lòng nhập API hệ thống kinh doanh',
    Please_enter_the_conversion_system_Api: 'Vui lòng nhập hệ thống chuyển đổi Api',
    official_avatar: 'Hình đại diện chính thức',
    Official_nickname: 'Biệt danh chính thức',
    Please_enter_the_official_nickname: 'Vui lòng nhập nickname chính thức',
    Page_Title: 'Tiêu đề trang',
    Please_enter_the_page_title: 'Vui lòng nhập tiêu đề trang',
    Red_series: 'màu đỏ',
    Green_series: 'màu xanh lá cây',
    Black_series: 'màu đen',
    Please_upload_your_profile_picture: 'Vui lòng tải ảnh đại diện lên',
    Please_select_a_style: 'Vui lòng chọn phong cách',
    Configuration_query_failed: 'Cấu hình truy vấn không thành công',
    Preview_Configuration: 'Cấu hình xem trước',
    Please_complete_the_form: 'Vui lòng hoàn thiện form',
    Provide_the_necessary_parameters_for_preview: 'Cung cấp các tham số cần thiết để xem trước',
    Vest_UID: 'Áo khoác UID',
    Operator_ID: 'ID của nhà điều hành',
    Operator_account: 'Số tài khoản người vận hành',
    Operator_nickname: 'Biệt danh của nhà điều hành',
    Explanation_111: 'Giải thích: Tài khoản clone tương ứng với một tài khoản người dùng C-side thực sự ở phía trước, hiện tại mỗi tài khoản chỉ giới hạn 10 tài khoản clone;',
    Failed_to_obtain_user_information: 'Không thể lấy được thông tin người dùng',
    Failed_to_obtain_token: 'Nhận token không thành công',
    Customer_service: 'Dịch vụ khách hàng (không khả dụng)',
    Switching_operational_accounts: 'Chuyển đổi tài khoản',
    Quick_reply_script: 'Trả lời nhanh',
    marked_27: 'bit người dùng {num} đã được trích xuất tự động',
    Update: 'Cập nhật',
    Clear: "Trống rỗng",
    Extract_users: 'trích xuất người dùng',
    block: "Kéo đen",
    Locked: 'Bị khóa.',
    marked_28: 'Bạn chắc chắn muốn làm trống người dùng trích xuất?',
    Successfully_blacklisted: 'thành công',
    User_group_comments: 'Phát biểu trong nhóm người dùng',
    marked_29: 'Phát biểu truy cập tự động trích xuất người dùng',
    marked_30: 'Chỉ có thể nhập tiếng Trung và tiếng Anh',
    Successfully_deleted_keyword: 'Xóa từ khóa thành công',
    User_Group: 'Nhóm người dùng',
    Registration_days: 'Số ngày đăng ký',
    Release: 'Giải phóng',
    Label: 'Nhãn hiệu',
    Tagged: "đánh dấu",
    Conversion_Description: 'Mô tả chuyển đổi',
    Application_Name: 'Tên ứng dụng',
    Installation_time: 'Thời gian cài đặt',
    User_nickname: 'Biệt danh người dùng',
    Chat_content: 'Nội dung trò chuyện',
    Time_of_occurrence: 'thời gian xảy ra',
    Private_Chat: 'Trò chuyện riêng tư',
    Business_System_UID: 'UID hệ thống kinh doanh',
    speak111: 'Phát biểu',
    marked_35: 'Vui lòng nhập tên cộng đồng',
    Name1111: 'Tên',
    password111: 'mật khẩu',
    enter_the_entrance_ID: 'Vui lòng nhập ID nhập cảnh',
    enter_the_entrance_111: 'Vui lòng nhập tên nhập cảnh',
    enter_the_entrance_112: 'Vui lòng nhập mô tả nhập cảnh',
    enter_the_entrance_113: 'Vui lòng chọn hình nền lối vào',
  
    // 新增444
    marked_66: 'ID doanh nghiệp',
    marked_67: 'Đang xuất...',
    marked_68: 'Trạng thái thẻ',
    marked_69: 'Vui lòng chọn trạng thái nhãn',
    marked_70: 'Thêm ít nhất một thẻ nữa',
    marked_71: 'Số/Biệt danh',
    marked_72: 'Ngày đăng ký',
    marked_73: 'Thông tin người dùng',
    marked_74: 'Hình đại diện người dùng',
    marked_75: 'Thời gian thi đấu',
    marked_76: 'Chúa tể',
    marked_77: 'Lịch sử tiếp nhận người dùng',
    marked_78: 'Xác nhận đã buộc off-line?',
    marked_79: 'Người dùng APP',
    marked_80: 'Nhóm khách hàng',
    marked_81: 'Nguồn gốc',
    marked_82: 'Người dùng bắt đầu',
    marked_83: "Nâng phòng truyền hình trực tiếp",
    marked_84: 'ID người dùng',
    marked_85: 'Loại đóng',
    marked_86: 'Thời gian kết thúc phiên',
    marked_87: 'thành công',
    marked_88: 'Mật khẩu đăng nhập',
    marked_89: 'Xóa nhóm dịch vụ khách hàng',
    marked_90: 'Xác nhận bạn muốn kích hoạt tài khoản?',
    marked_91: 'Vui lòng chọn trạng thái vai trò',
    marked_92: 'ủy quyền thành công',
    marked_93: 'Biệt danh mới',
    marked_94: 'Thông tin khách hàng',
    marked_95: 'Vui lòng hoàn thiện biểu mẫu để cung cấp các thông số cần thiết để xem trước',
    marked_96: 'Vui lòng nhập nickname mới',
    marked_97: 'Số tài khoản',
    marked_98: 'Kích hoạt như áo khoác của tôi',
    marked_99: '(không có sẵn)',
    marked_100: 'Đã gửi',
    marked_101: 'phát biểu',
    marked_102: 'Hoạt động khóa thành công',
    marked_103: "Từ khóa đã đạt giới hạn, không thể thêm nữa",
    marked_104: 'Tạm thời không nói chuyện nhóm',
    marked_105: 'Cài đặt ứng dụng',
    marked_106: 'chuyển đổi',
    marked_107: 'Chuyển đổi người dùng',
    style_select: 'Chọn kiểu',
    button111: 'nút bấm',
  

};
