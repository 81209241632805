import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../layout/index'
import { getLanguage } from '@/locale/index'
import i18n from "@/locale";
console.log(getLanguage());
Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/login',
    meta: { title: i18n.messages[getLanguage()].login.logIn, noCache: true },
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/features/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/features/401'),
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/features/redirect')
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    hidden: true,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard'),
        name: 'Dashboard',
        meta: { title: i18n.messages[getLanguage()].navbar.dashboard, icon: 'index', affix: true, noCache: true }
      }
    ]
  },
  {
    path: '/profile',
    component: Layout,
    hidden: true,
    redirect: '/profile/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/profile/index'),
        name: '个人中心',
        meta: { title: i18n.messages[getLanguage()].navbar.profile, noCache: true }
      }
    ]
  },
  {
    path: '/merchants',
    component: Layout,
    hidden: true,
    redirect: '/merchants/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/profile/merchants'),
        name: '商户基本信息',
        meta: { title: '商户基本信息', noCache: true }
      }
    ]
  }
  // {
  //   path: '/activity-management',
  //   component: Layout,
  //   redirect: '/turntable',
  //   meta: {
  //     title: '活动管理',
  //     icon: 'index',
  //     affix: true,
  //     noCache: true
  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/activity-management/turntable/index'),
  //       name: '转盘活动管理',
  //       meta: { title: '转盘活动管理', noCache: true }
  //     },
  //     {
  //       path: 'good-management',
  //       component: () => import('@/views/activity-management/gold-coin/index'),
  //       name: '商品库管理',
  //       meta: { title: '商品库管理', noCache: true }
  //     },
  //     {
  //       path: 'exchange-setting',
  //       component: () => import('@/views/activity-management/gold-coin/exchange-setting'),
  //       name: '兑换页设置',
  //       meta: { title: '兑换页设置', noCache: true }
  //     },
  //     {
  //       path: 'transaction-details',
  //       component: () => import('@/views/activity-management/gold-coin/transaction-details'),
  //       name: '商品交易明细',
  //       meta: { title: '商品交易明细', noCache: true }
  //     }
  //   ]
  // }
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
