export default function(el) {
  var input = el
  input.onkeyup = function(e) {
    if (e.target.value.length === 1) {
      e.target.value = e.target.value.replace(/[^0-9]/g, '')
    } else {
      e.target.value = e.target.value.replace(/[^\d]/g, '')
    }
    if (parseInt(e.target.value) > 999) {
      e.target.value = 999
    }
  }
}
