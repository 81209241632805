export default {
   Edit: "編輯",
   User_Configuration: "使用者配置",
   Please_enter_a_numerical_level: "請輸入數位等級",
   hint_4: "低於該等級不允許設置包含英文字母昵稱",
   Nickname_English_letter_level_limit: "昵稱英文字母等級限制",
   Live_broadcast_room_configuration: "直播間配置",
   hint_6: "低於該等級則不允許直播間聊天",
   hint_5: "低於該等級直播間聊天英文字母被替換*",
   Open: "開啟",
   Close: "關閉",
   Circle_Speech_Configuration: "圈子發言配置",
   hint_7: "低於該等級則不允許圈子內私信主播",
   Speech_level_limit: "發言等級限制",
   hint_8_: "低於該等級則不允許圈子內聊天發言",
   Posting_updates_submission_levels: "發佈動態/投稿等級",
   hint_9: "低於該等級則不允許發佈動態/投稿",
   hint_911: "發佈動態/投稿",
   Cancel_1: "取消",
   Save_1: "保存",
   Please_enter_level: "請輸入等級",
   APP_bottom_label: "APP底部標籤",
   Initial_icon: "初始icon",
   Activity_icon: "活動icon",
   World_Cup_icon: "世界盃icon",
   The_bottom_marketing_position_of_the_APP: "APP底部行銷位",
   Please_enter_the_redirect_address: "請輸入跳轉位址",
   Event_start_time: "活動開始時間",
   APP_homepage_my_background: "APP首頁、我的背景",
   hint_10: "尺寸： 1125*624，只能上傳jpg/png檔",
   Operation: "操作",
   Upload: "上傳",
   Ordinary_user_currency_icon: "普通用戶貨幣icon",
   Size_1: "尺寸",
   Type: "類型",
   Preview_image: "預覽圖",
   Live_settlement_currency_icon: "直播結算貨幣icon",
   Brocade_Bag_Settlement_Currency_icon: "錦囊結算貨幣icon",
   hint_11: "支援格式：PNG，大小：500K以內",
   Android_Triple_Image: "Android三倍圖",
   Top_of_homepage_top_of_activity_page: "首頁頂部、活動頁頂部",
   At_the_top_of_the_competition_page_my: "賽事頁頂部、我的",
   Signal_source_top: "信號源頂部",
   Please_select: "請選擇",
   Please_upload: "請上傳",
   Operation_successful: "操作成功",
   Can_only_upload_can_only_upload_PNG_files: "只能上傳只能上傳png檔",
   hint_800: "上傳大小不能超過 500K!",
   Login_address_configuration: "登錄位址配置",
   Please_enter_your_login_address: "請輸入登錄位址",
   hint_12:
     "此配置僅針對推流端使用商戶側使用者系統生效，配置後，推流端支援識別商戶側自有用戶名及密碼",
   Recommended_by_the_editorial_circle: "編輯圈子推薦",
   Please_enter_the_resource_name: "請輸入資源名稱",
   Circle_List_Recommendation: "圈子列表推薦",
   Recommended_by_Square_Circle: "廣場圈子推薦",
   Please_enter_userid: "請輸入userid",
   Please_enter_the_IP_address: "請輸入IP",
   Search: "搜索",
   Refresh_IP_blacklist_cache: "刷新IP黑名單緩存",
   Reset: "重置",
   Loading: "載入中...",
   Merchant_ID_1: "商戶編號",
   Prohibition_time_1: "封禁時間",
   Remove: "移除",
   hint_743: "確定要移除此IP嗎？",
   APP_brand_name: "APP品牌名稱",
   Default_username_prefix: "預設使用者名首碼",
   Common_user_currency_name: "普通用戶貨幣名稱",
   Live_settlement_currency_name: "直播結算貨幣名稱",
   The_name_of_the_settlement_currency_for_the_brocade_bag: "錦囊結算貨幣名稱",
   Contact_customer_service: "聯繫客服",
   Assistant_default_icon: "小助手預設圖示",
   Click_to_select_resources: "點擊選取資源",
   hint_13: "只能上傳svga檔，圖片大小不能超過800k",
   Assistant_unread_message_icon: "小助手未讀消息圖示",
   Assistant_displays_icon_size: "小助手顯示圖示尺寸",
   Small: "小",
   Medium: "中",
   Big: "大",
   Assistant_display_time: "小助手展示時間",
   Online_customer_service_address: "線上客服地址",
   Initialize_account: "初始化帳號",
   Anchor_tool_download_address: "主播工具下載地址",
   ICP_Filing_Information: "ICP備案資訊",
   Live_broadcast_room_default_message: "直播間預設消息",
   Conversation_background: "語聊背景",
   Default_official_anchor_profile_picture: "默認官方主播頭像",
   Default_official_message_avatar: "預設官方消息頭像",
   Login_registration_page_background: "登錄註冊頁面背景",
   hint_14: "支援格式：JPG、PNG、GIF、MP4，大小：3M以內",
   Delete: "刪除",
   Triple_plot: "三倍圖",
   Double_plot: "二倍圖",
   Please_fill_in_the_brand_name: "請填寫品牌名稱",
   Please_fill_in_the_prefix: "請填寫首碼",
   Please_fill_in_the_currency_name: "請填寫貨幣名稱",
   Please_fill_in_customer_service_WeChat: "請填寫客服微信",
   Please_fill_in_the_download_address_for_the_anchor_tool:
     "請填寫主播工具下載地址",
   Please_fill_in_the_ICP_information: "請填寫ICP資訊",
   Please_fill_in_the_default_message_for_the_live_broadcast_room:
     "請填寫直播間預設消息",
   Please_fill_in_the_assistant_display_time: "請填寫小助手展示時間",
   hint_15: "上傳尺寸有誤，請按格式要求上傳!",
   hint_16: "只能上傳只能上傳png檔!",
   The_upload_size_cannot_exceed: "上傳大小不能超過",
   hint_17: "傳大小不能超過 800K!",
   Select: "選取",
   Please_upload_the_edited_Excel_template_file: "請上傳編輯好的excel範本檔",
   Confirm: "確認",
   Importing: "導入中",
   Please_upload_the_file: "請上傳文件",
   Only_xlsx_format_files_can_be_uploaded: "只能上傳.xlsx格式檔!",
   hint_742: "上傳圖片大小不能超過 500MB!",
   Successfully_added: "添加成功",
   Import_member_failure_list: "導入會員失敗名單",
   OK: "確定",
   Select_file: "選擇檔",
   Please_enter_the_ID: "請輸入id",
   User_nickname: "用戶昵稱",
   hint_740: "請輸入用戶昵稱",
   User_phone_number: "用戶手機號",
   hint_739: "請輸入用戶手機號",
   User_level: "使用者等級",
   Please_enter_user_level: "請輸入使用者等級",
   Membership_level: "會員等級",
   Game_users: "遊戲用戶",
   Status: "狀態",
   Registration_time: "註冊時間",
   Identity: "身份",
   User_type: "用戶類型",
   Inviter_userid: "邀請人userid",
   hint_738: "請輸入邀請人userid",
   Registration_Platform: "註冊平臺",
   hint_741: "請輸入註冊平臺網址",
   Batch_import_of_members: "批量導入會員",
   Download_batch_import_templates: "下載批量導入範本",
   Export_data: "匯出數據",
   Total_number_of_registered_users: "總註冊用戶數",
   Number_of_registered_users_today: "今日註冊用戶數",
 
   // 錯誤開始
   nickname: "昵稱",
   Avatar: "頭像",
   Mobile_area_code: "手機區號",
   Mobile_phone_number: "手機號碼",
   Third_party_binding: "協力廠商綁定",
   Recent_login_time: "最近登錄時間",
   Recently_logged_in_IP: "最近登錄IP",
   Last_visited_time: "最近存取時間",
   Anchor: "主播",
   Experts: "專家",
   Anchor_level: "主播等級",
   Ban: "封禁",
   Enable: "啟用",
   Detailed: "詳細",
   Prohibition: "禁言",
   Reset_password: "重置密碼",
   Personal_information_1: "的個人資料",
   Mobile_phone_number_and_area_code: "手機號區號",
   Settings: "設置",
   Phone_number: "手機號",
   Please_enter_your_phone_number: "請輸入手機號",
   User_status: "使用者狀態",
   Normal_1: "正常",
   hint_737: "是否主播",
   hint_736: "是否錦囊專家",
   Recommendation_code: "推薦碼",
   Personal_signature: "個人簽名",
   User_UID: "用戶UID",
   Prohibition_period_: "禁言週期：",
   Release_the_ban: "解除禁言",
   Reasons_for_Prohibition: "禁言原因",
   Please_select_the_reason_for_the_ban: "請選擇禁言原因",
   Delete_chat_messages: "刪除聊天消息",
   Delete_all_chat_messages_in_the_live_broadcast_room: "刪除所有直播間聊天消息",
   hint_735: "確定要封禁此帳戶嗎？",
   Is_IP_banned_: "是否封禁IP",
   Current_level: "當前等級",
   Operation_time: "操作時間",
   Operator: "操作人",
   No: "否",
   Yes: "是",
   C_end_user_data: "c端使用者資料",
   Batch_import_of_member_templates: "批量導入會員範本",
   Account_has_been_banned: "帳戶已封禁",
   hint_734: "確定要啟用此帳戶嗎？",
   Prompt: "提示",
   Account_enabled: "帳戶已啟用",
   hint_733: "確認重置該使用者密碼嗎？初始密碼為：Aa123456",
   Password_reset_successful: "重置密碼成功",
   Password_reset_failed: "重置密碼失敗",
   Download_the_homepage_logo_: "下載頁首屏Logo：",
   Click_to_select_image: "點擊選取圖片",
   hint_20: "只能上傳jpg/png檔，圖片尺寸需為344X120，最多1張",
   hint_21: "只能上傳jpg/png檔，圖片尺寸需為288X100，最多1張",
   Download_the_top_screen_background_image: "下載頁首屏背景圖",
   hint_22: "只能上傳jpg/png檔，圖片尺寸需為375X732，最多1張",
   Download_the_top_screen_copy_image: "下載頁首屏文案圖",
   hint_23: "只能上傳jpg/png檔，圖片尺寸需為1980x1650，最多1張",
   Download_page_application_introduction_image: "下載頁應用介紹圖",
   hint_24: "只能上傳jpg/png檔，圖片尺寸需為360X842，最多4張",
   Download_page_application_introduction: "下載頁應用介紹",
   Download_page_features: "下載頁特色功能",
   PWA_configuration: "pwa配置",
   Only_json_files_can_be_uploaded: "只能上傳.json文件",
   Download_floating_bar_description: "下載懸浮條描述",
   New_H5_logo: "新H5-logo",
   hint_732: "圖片尺寸建議為40x40",
   New_H5_Download_floating_bar: "新H5-下載懸浮條",
   hint_731: "圖片尺寸建議為400x60",
   Youmeng_Statistics: "友盟統計",
   Please_enter_the_URL_corresponding_to_src: "請輸入src對應的url",
   hint_25: "如需統計多個功能變數名稱，請用||區分",
   Baidu_Statistics: "百度統計",
   Version_download_address: "版本下載地址",
   Quick_Version: "快捷版",
   Please_enter_the_iOS_shortcut_address: "請輸入iOS快捷版位址",
   Please_enter_Android_Scheme: "請輸入Android Scheme",
   H5_Station_Download_Jump_Status: "H5站下載跳轉狀態",
   APP_H5_Album_Page_Name: "APP/H5專輯頁名稱",
   Please_enter_a_name: "請輸入名稱",
   APP_H5_Album_Page_Link: "APP/H5專輯頁連結",
   Please_upload_pictures: "請上傳圖片",
   Please_upload_the_PWA_configuration_file: "請上pwa設定檔",
   Circle_: "圈子{num}",
   Please_enter_the_circle_ID: "請輸入圈子ID",
   Please_fill_in_the_Android_app_download_address: "請填Android APP下載地址",
   Please_fill_in_the_iOS_app_download_address: "請填iOS APP下載地址",
   Please_fill_in_the_application_introduction: "請填寫應用介紹",
   Please_fill_in_the_featured_features: "請填寫特色功能",
   Please_fill_in_the_description_1: "請填寫描述內容",
   hint_730: "請填寫iOS穩定版下載地址",
   Please_fill_in_the_Android_Scheme: "請填寫Android Scheme",
   Please_fill_in_the_iOS_Scheme: "請填寫iOS Scheme",
   hint_18: "只能上傳只能上傳jpg、jpeg或png檔",
   Upload_up_to_1_image: "最多上傳1張",
   hint_19: "只能上傳只能上傳jpg、jpeg或png檔!",
   The_upload_size_cannot_exceed_1_5M_: "上傳大小不能超過 1.5M!",
   Only_json_format_files_can_be_uploaded_: "只能上傳.json格式檔!",
   The_size_cannot_exceed_1MB_: "大小不能超過 1MB!",
   IOS_double_plot: "IOS二倍圖",
   Protocol_identifier_ID: "協定標識ID",
   Protocol_identifier: "協定標識",
   Protocol_Name: "協定名稱",
   Last_editing_date: "最後編輯日期",
   Release_status: "發佈狀態",
   H5_download_page_address: "H5下載頁面位址",
   Application_Introduction: "應用介紹",
   Image_and_Text_Configuration: "圖文配置",
   Page_: "第{num}張",
   Only_jpg_png_files_can_be_uploaded: "只能上傳jpg/png檔",
   The_size_is_394_X798: "尺寸為394X798",
   Please_enter_the_application_introduction: "請輸入應用介紹",
   Please_enter_the_address: "請輸入下位址",
   The_title_of_the_th_page_cannot_be_empty: "第${num}張標題不能為空",
   Logo_icon: "logo-圖示",
   Logo_Title: "logo-標題",
   The_recommended_image_size_is_140_x70: "圖片尺寸建議為140x70",
   Navigation_bar_activity_map: "巡覽列活動圖",
   hint_307: "尺寸： 312*126，只能上傳jpg/png檔",
   PC_download_copy: "PC下載文案",
   hint_26: "請輸入第一行文案，不超過10個字元",
   hint_27: "請輸入第二行文案，不超過10個字元",
   Page_title_: "頁面title：",
   Please_enter_a_title: "請輸入標題",
   Page_keywords: "頁面關鍵字",
   Page_Description: "頁面描述",
   Please_enter_a_description: "請輸入描述",
   Please_enter_the_download_address_for_the_anchor_tool:
     "請輸入主播工具下載位址",
   H5_download_page_address_1: "h5下載頁地址",
   Please_enter_the_h5_download_page_address: "請輸入h5下載頁位址",
   Anchor_Recruitment_Description: "主播招聘描述",
   Please_enter_the_anchor_recruitment_description: "請輸入主播招聘描述",
   Anchor_Recruitment_QR_Code: "主播招聘二維碼",
   The_recommended_image_size_is_256x256: "圖片尺寸建議為256x256",
   Contact_email_1: "聯繫郵箱",
   Please_enter_your_contact_email: "請輸入聯繫郵箱",
   Bottom_copyright_information: "底部版權資訊",
   Please_enter_copyright_information: "請輸入版權資訊",
   PC_Link_Configuration: "PC端連結配置",
   Please_enter_the_PC_domain_name_link_configuration: "請輸入PC端功能變數名稱連結配置",
   Site_icon: "網站icon",
   Click_to_select: "點擊選取",
   hint_28: "只能上傳ico檔，圖片尺寸需為32x32，大小不超過20kb",
   Please_fill_in_the_statistics_link: "請填寫統計連結",
   Customer_service_code_: "客服代碼",
   Please_fill_in_the_customer_service_code: "請填寫客服代碼",
   PC_album_page_status: "PC專輯頁狀態",
   PC_album_page_link: "PC專輯頁連結",
   Please_fill_in_the_title: "請填寫標題",
   Please_fill_in_the_description: "請填寫描述",
   Please_fill_in_the_keywords: "請填寫關鍵字",
   Please_fill_in_the_record_number_document: "請填寫備案號文案",
   Please_fill_in_your_email_address: "請填寫郵箱",
   Please_fill_in_the_h5_download_page_address: "請填寫h5下載頁地址",
   Please_select_the_Feixin_QR_code_image: "請選擇飛信二維碼圖片",
   Please_upload_the_logo_icon: "請上傳logo圖示",
   Please_upload_the_logo_title: "請上傳logo標題",
   Please_enter: "請輸入",
   Only_ICO_files_can_be_uploaded_: "只能上傳ico檔!",
   hint_729: "上傳圖片大小不能超過 20KB!",
   Please_enter_the_theme_name: "請輸入主題名稱",
   Add_a_new_theme: "新增主題",
   There_is_currently_no_content: "暫無內容",
   Last_modification_time: "最後修改時間",
   Please_enter_sorting: "請輸入排序",
   Are_you_sure_you_want_to_delete_it_: "是否確認刪除？",
   Delete_successful: "刪除成功",
   Theme: "主題",
   Publish: "發佈",
   Please_select_a_theme: "請選擇主題",
   Please_enter_the_content_of_the_agreement: "請輸入協定內容",
   Please_enter_the_protocol_name: "請輸入協定名稱",
   hint_728: "是否確認發佈協定資訊",
   hint_727: "是否確認保存協定資訊",
   User_Registration_Agreement: "使用者註冊協定",
   User_Privacy_Agreement: "使用者隱私協定",
   Live_streaming_protocol: "直播協議",
   Payment_Agreement: "支付協議",
   Settlement_rules: "結算規則",
   Recharge_Service_Agreement: "充值服務協定",
   About: "關於",
   Please_select_the_protocol_type: "請選擇協議類型",
   Login_Page_Logo: "登錄頁Logo",
   hint_29: "只能上傳jpg/png檔，圖片尺寸需為109X140，最多1張",
   Main_interface_logo: "主介面Logo",
   hint_30: "只能上傳jpg/png檔，圖片尺寸需為69X67，最多1張",
   About_Page_Logo: "關於頁面Logo",
   hint_31: "只能上傳jpg/png檔，圖片尺寸需為109X109，最多1張",
   hint_32: "上傳圖片尺寸有誤，請按格式要求上傳!",
   Add_TF_package: "添加TF包",
   Package_name: "包名",
   Download_link: "下載連結",
   Developer_account: "開發者帳號",
   Effective_date_1: "生效日期",
   Expiration_date: "失效日期",
   Remaining_valid_days: "剩餘有效天數",
   Please_enter_valid_days: "請輸入有效天數",
   Effective_days: "有效天數",
   Please_enter_the_package_name: "請輸入包名",
   Edit_TF_package: "編輯TF包",
   Please_fill_in_the_download_link: "請填寫下載連結",
   Please_select_the_effective_date: "請選擇生效日期",
   hint_726: "有效天數必須大於等於90",
   Created_successfully: "創建成功",
   Update_method: "更新方式",
   System: "系統",
   Add_1: "添加",
   Update_type: "更新類型",
   Version_number: "版本號",
   Prompt_package: "提示包",
   Update_package: "更新包",
   Reminder_date: "提醒日期",
   Recently_edited_by: "最近編輯人",
   Last_edited_time: "最近編輯時間",
   Version_update: "版本更新",
   TF_Package_Update: "TF包更新",
   Download_address: "下載地址",
   Select_prompt_package: "選擇提示包",
   Please_fill_in_the_download_address: "請填寫下載地址",
   Select_Update_Package: "選擇更新包",
   Remaining: "剩餘",
   Heaven: "天",
   Start_reminder_date: "開始提醒日期",
   Update_content: "更新內容",
   Please_enter_the_updated_content: "請輸入更新內容",
   hint_33: "支援格式：PNG、JPG，尺寸要求：750*1624",
   Edit_version_update: "編輯版本更新",
   Add_version_update: "添加版本更新",
   Please_set_an_updated_version: "請設置更新版本",
   Please_set_the_prompt_version: "請設置提示版本",
   Please_select_the_system: "請選擇系統",
   Please_choose_a_method: "請選擇方式",
   Please_enter_the_content: "請輸入內容",
   Please_set_the_reminder_date: "請設置提醒日期",
   hint_725: "上傳圖片只能是 JPG 或 PNG 格式!",
   hint_724: "上傳圖片大小不能超過 1MB!",
   Modified_successfully: "修改成功",
   User_Management: "用戶管理",
   IP_blacklist: "IP黑名單",
   Application_ID: "應用ID",
   Application_Name: "應用名稱",
   Last_modified_date: "最後修改日期",
   Create_an_application: "創建應用",
   View_Key: "查看金鑰",
   Module: "模組",
   Supplier: "供應商",
   Add_Module: "添加模組",
   Reset_Key: "重置金鑰",
   New_Module: "新建模組",
   Please_enter_the_application_name: "請輸入應用名稱",
   Please_select_a_module: "請選擇模組",
   Please_select_a_supplier: "請選擇供應商",
   Please_enter_supplier_configuration: "請輸入供應商配置",
   Edit_application: "編輯應用",
   hint_34:
     "若該應用有使用此模組的功能，刪除記錄可能會導致功能不可用。確認是否要刪除？",
   Start_page: "啟動頁",
   Change: "變更",
   Only_JPG_or_PNG_format_images_are_allowed_to_be_uploaded:
     "只允許上傳JPG格式或PNG格式圖片",
   Are_you_sure_you_want_to_save_the_changes_: "是否確認保存變更",
   hint_320: "密碼修改成功，請重新登錄",
   Review_version: "審核版本",
   Please_enter_version: "請輸入版本",
   Tag_number: "標記號",
   List_Fields: "列表欄位",
   Form_Description: "表單說明",
   Remarks: "備註",
   Pre_explanation: "前置說明",
   hint_35:
     "1.針對ios端提審環境版本控制隱藏功能；2.代碼層面規避明顯做審核環境控制注釋或代碼；3.Android端無需控制",
   Version_control: "版本控制",
   hint_36:
     "1.顯示當前需要控制的版本號，例如：當前需要提審的版本為V1.1，則輸入框需輸入1.1，且下方勾選對應需要隱藏的模組，則在V1.1版本裡以下勾選的模組入口均隱藏。2.輸入無實際版本的版本號保存，不影響顯示任意版本模組；",
   Control_module: "控制模組",
   hint_317:
     "顯示所需要再提審環境隱藏的功能名稱，預設勾選狀態，取掉勾選，且保存後，則在此版本中不隱藏此模組；",
   Please_fill_in_version_information: "請填寫版本資訊",
   Select_language: "選擇語言",
   General_configuration: "通用配置",
   IOS_configuration: "IOS配置",
   H5_configuration: "H5配置",
   New_download_page_configuration: "新下載頁配置",
   Streaming_tool_configuration: "推流工具配置",
   PC_configuration: "PC配置",
   Mobile_streaming_configuration: "移動推流配置",
   Advertising_restriction_configuration: "廣告限制配置",
   Virtual_Number_Restrictions: "虛擬號碼限制",
   Reporting_restrictions_for_third_party_data_apps: "三方資料APP上報限制",
   Report_domain_name_configuration: "上報功能變數名稱配置",
   Please_enter_the_domain_name: "請輸入功能變數名稱",
   hint_723: "下載APP首次埋點上報",
   Channel_code_parameter_acquisition: "管道碼參數獲取",
   PC_data_reporting: "PC資料上報",
   H5_data_reporting: "H5資料上報",
   Domain_Name_Address: "功能變數名稱地址",
   Please_enter_the_domain_name_address: "請輸入功能變數名稱位址",
   Deactivate: "停用",
   Please_select_the_type: "請選擇類型",
   Are_you_sure_to_enable_the_domain_name_: "確認啟用功能變數名稱嗎？",
   hint_722: "確認停用功能變數名稱嗎？",
   Update_successful: "更新成功",
   Recommended: "推薦中",
   Paused: "已暫停",
   Picture_matching: "配圖",
   Source: "來源",
   Feedback_type: "回饋類型",
   Number: "序號",
   Feedback_details: "回饋詳情",
   Contact_information: "聯繫方式",
   Feedback_time: "回饋時間",
   Navigation_Station_Switch: "導航站開關",
   Page_Configuration: "頁面配置",
   Weight: "權重",
   hint_37: "數字越大排名越靠前",
   Button_Name: "按鈕名稱",
   Domain_name: "功能變數名稱",
   Please_enter_the_weight: "請輸入權重",
   Please_enter_the_button_name: "請輸入按鈕名稱",
   Mobile_related: "移動端相關",
   PC_related: "PC端相關",
   Personal_information: "個人資訊",
   Login_account: "登錄帳號",
   User_Name: "用戶姓名",
   Security_Settings: "安全設置",
   Change_password: "修改密碼",
   Original_password: "原密碼",
   New_password: "新密碼",
   Confirm_new_password: "確認新密碼",
   hint_721: "密碼長度不能少於6位元",
   The_passwords_entered_twice_do_not_match: "兩次輸入的密碼不一致",
   Please_enter_your_password_again: "請再次輸入密碼",
   Merchant_Basic_Information: "商戶基本資訊",
   Merchant_ID: "商戶ID",
   Enterprise_Name: "企業名稱",
   Contact_person: "連絡人",
   Contact_email: "連絡人郵箱",
   Contact_phone_number: "連絡人手機",
   SMS_verification_code: "短信驗證碼",
   Please_enter_the_merchant_ID: "請輸入商戶ID",
   Please_enter_the_company_name: "請輸入企業名稱",
   Please_enter_the_contact_person: "請輸入連絡人",
   Please_enter_the_contact_person_phone_number: "請輸入連絡人手機",
   Please_enter_the_contact_email: "請輸入連絡人郵箱",
   Please_enter_the_verification_code: "請輸入驗證碼",
   Get_verification_code: "獲取驗證碼",
   Please_enter_sensitive_words: "請輸入敏感詞",
   Batch_Add: "批量添加",
   Query_results: "查詢結果",
   hint_720: "抱歉，沒有找到該敏感詞",
   Activity_Name: "活動名稱",
 
   // 錯誤end====
 
   hint_718: "請輸入敏感詞且長度不能少於兩個字元",
   hint_308: "確定刪除該關鍵字？",
   continue_to_be_sent_on_the_platform: "解禁後則該詞可繼續在平臺發送",
   Are_you_sure_to_lift_the_ban_: "確認解禁嗎？",
   The_image_size_needs_to_be_40_x40: "圖片尺寸需為40x40",
   Brand_name: "品牌名稱",
   Main_site_name: "主站名稱",
   Please_enter_the_name_of_the_main_site: "請輸入主站名稱",
   Main_site_redirect_URL: "主站跳轉url",
   Please_enter_the_main_site_redirect_URL: "請輸入主站跳轉url",
   H5_Main_Station_Jump_URL: "H5主站跳轉url",
   Please_enter_the_H5_main_site_redirect_URL: "請輸入H5主站跳轉url",
   Homepage_banner: "首頁banner",
   hint_717: "圖片尺寸需為1200x150",
   Homepage_banner_URL: "首頁banner url",
   Please_enter_the_banner_URL_on_the_homepage: "請輸入首頁bannerUrl",
   Live_room_banner: "直播間banner",
   hint_716: "請輸入直播間bannerUrl",
   Homepage_title: "首頁-title",
   Please_enter_the_title: "請輸入title",
   Home_page_description_1: "首頁-description",
   Homepage_keywords_1: "首頁-keywords",
   Please_enter_keywords_1: "請輸入keywords",
   Live_room_title: "直播間-title",
   Live_broadcast_room_keywords_1: "直播間-keywords",
   Bottom_copyright_text: "底部版權文字",
   Please_enter_the_copyright_text_at_the_bottom: "請輸入底部版權文字",
   APP_download_URL: "APP下載URL",
   Please_enter_the_APP_download_URL: "請輸入APP下載URL",
   hint_715: "圖片尺寸需為750x94",
   H5_Live_Banner: "H5直播banner",
   Please_enter_h5RoomBannerRedirectUrl: "請輸入h5RoomBannerRedirectUrl",
   Please_enter_the_brand_name: "請輸入品牌名稱",
   Please_enter_the_main_site_redirect: "請輸入主站跳轉",
   Please_enter_H5_main_site_redirect: "請輸入H5主站跳轉",
   Please_enter_the_homepage: "請輸入首頁",
   Please_enter_the_homepage_banner_to_jump_to: "請輸入首頁 banner 跳轉",
   Please_enter_the_homepage_description: "請輸入首頁描述",
   Please_enter_homepage_keywords: "請輸入首頁關鍵字",
   Please_enter_the_live_streaming_page: "請輸入直播頁",
   hint_714: "請輸入直播頁描述",
   Please_enter_keywords_for_the_live_streaming_page: "請輸入直播頁關鍵字",
   Please_enter_the_website_copyright_statement: "請輸入網站版權申明",
   Please_enter_the_app_download_URL: "請輸入app 下載 url",
   Please_enter_the_site_h5BannerRedirectUrl: "請輸入網站h5BannerRedirectUrl",
   Add_Site: "添加網站",
   Edit_Site: "編輯網站",
   Upload_up_to_1_photo_: "最多上傳1張!",
   Home_page_description: "首頁description",
   Homepage_keywords: "首頁keywords",
   Live_broadcast_room_title: "直播間title",
   Live_broadcast_room_description: "直播間-description",
   Live_broadcast_room_keywords: "直播間-keywords",
   App_download_URL: "App下載URL",
   H5_homepage_banner_URL: "h5首頁bannerurl",
   H5_homepage_banner_redirect_URL: "h5首頁banner跳轉url",
   H5_live_broadcast_room_banner_URL: "h5直播間bannerurl",
   H5_Live_Room_Banner_Jump_URL: "h5直播間banner跳轉url",
   Live_streaming_services_1: "直播服務",
   SMS_service: "短信服務",
   Certificate_file_name: "證書檔案名",
   Certificate_type: "證書類型",
   General_Supplier_Configuration: "通用供應商配置",
   Whether_to_configure_TF_package_name: "是否配置TF包名",
   Package_Name_Configuration: "包名配置",
   Supplier_Configuration: "供應商配置",
   Edit_Supplier_Configuration: "編輯供應商配置",
   Add_supplier_configuration: "新增供應商配置",
   Please_fill_in_the_configuration_information: "請填寫配置資訊",
   hint_713: "是否確定刪除配置？",
   Please_configure_TF_configuration_correctly: "請正確配置TF配置",
   Version_update_reminder: "版本更新提醒",
   TF_Package_Management: "TF包管理",
   Batch_upload: "批量上傳",
   Posting_updates: "發佈動態",
   Account: "帳號",
   Content: "內容",
   Upload_images: "上傳圖片",
   Only_jpg_jpeg_png_files_can_be_uploaded: "只能上傳jpg/jpeg/png檔",
   Up_to_9_images_can_be_uploaded: "最多支援上傳9張",
   Click_to_select_file: "點擊選取檔",
   Only_supports_video_format_files: "僅支援視頻格式檔",
   Related_circles: "關聯圈子",
   Please_select_a_circle: "請選擇圈子",
   Related_topics: "關聯話題",
   Please_select_a_topic: "請選擇話題",
   Please_upload_the_video_file: "請上視頻檔",
   Please_select_an_account: "請選擇帳號",
   hint_712: "上傳圖片只能是 JPG 或 PNG格式!",
   hint_707: "上傳圖片大小不能超過 10MB!",
   hint_710: "只能上傳mp4格式檔!",
   hint_711: "大小不能超過 200MB!",
   Please_enter_your_nickname: "請輸入昵稱",
   Joined_the_circle: "已加入圈子",
   Import_time: "導入時間",
   Disabled: "禁用",
   Add_Circle: "添加圈子",
   Cannot_be_greater_than_80: "不能大於80",
   hint_38: "確認禁用該用戶嗎？禁用後將無法使用該帳號發佈動態？",
   hint_39: "確認啟用該用戶嗎？啟用後將可重新使用該帳號發佈動態？",
   Dynamic_configuration: "動態配置",
   Publisher_nickname: "發佈人昵稱",
   Accumulated_likes: "累計點贊數",
   Accumulated_number_of_comments: "累計評論數",
   Topic: "話題",
   Circle: "圈子",
   Release_time: "發佈時間",
   Remove_this_comment: "下架此評論",
   Dynamic_permission_configuration: "動態許可權配置",
   Publish_dynamic_user_level_configuration: "發佈動態使用者等級配置",
   Name: "名稱",
   Level: "等級",
   hint_703: "此項配置為普通使用者發佈動態需達到的等級",
   Cannot_be_less_than_1: "不能小於1",
   Please_fill_in_the_user_level: "請填寫使用者等級",
   There_are_currently_no_related_posts: "暫無相關帖子",
   hint_702: "確認要下架此條帖子評論嗎？",
   Comment_removed_successfully: "評論下架成功",
   hint_704: "確定要下架嗎？",
   hint_705: "確定要上架嗎？",
   Return: "返回",
   hint_706: "你沒有許可權去該頁面",
   hint_309: "如有不滿請聯繫你領導",
   Or_you_can_go: "或者你可以去",
   Go_back_to_the_homepage: "回首頁",
   hint_40: "請檢查您輸入的網址是否正確，請點擊以下按鈕返回主頁或者發送錯誤報告",
   Return_to_homepage: "返回首頁",
   hint_41:
     "提示：1-權重為正整數，數值越大越靠前；2-默認前端展示，隱藏則前端不展示",
   Anchor_nickname: "主播昵稱",
   Please_enter_sorting_weight: "請輸入排序權重",
   Display: "顯示",
   Hidden: "隱藏",
   Associated_channels_categories: "關聯頻道/分類",
   Anchor_Name_1: "主播名稱",
   Please_enter_the_anchor_name_1: "請輸入主播名稱",
   Room_number: "房間號",
   Please_enter_the_room_number: "請輸入房間號",
   Source_of_flow: "流來源",
   Anchor_profile_picture: "主播頭像",
   Source_room_number: "來源房間號",
   Live_streaming_categories: "直播品類",
   Live_streaming_title: "直播標題",
   Stream_address: "流地址",
   Forbidden_broadcasting: "禁播",
   Live_streaming_1: "直播中",
   Ended: "已結束",
   Unlocking: "解禁",
   All: "全部",
   Douyu: "鬥魚",
   Tiger_Fang: "虎牙",
   hint_701: "是否確認解禁該主播？",
   hint_700: "是否確認禁播該主播？",
   Competition_time: "比賽時間",
   Competition_status: "比賽狀態",
   Please_enter_keywords_2: "請輸入關鍵字",
   League_Cup_Tournament: "聯賽/杯賽",
   The_home_team: "主隊",
   The_visiting_team: "客隊",
   Event_ID: "賽事ID",
   Anchor_Qingliu_Live_Room: "主播清流直播間",
   Associated_Channel: "關聯頻道",
   Unknown_status: "狀態未知",
   Not_started: "未開始",
   In_progress: "進行中",
   The_competition_has_been_postponed: "比賽推遲",
   Competition_cancelled: "比賽取消",
   Direct_room_number: "直推房間號",
   Progress_time: "進行時間",
   Live_streaming_status: "直播狀態",
   Next_broadcast: "下播",
   Binding_successful: "綁定成功",
   hint_699: "確定將該直播下播嗎？",
   Anchor_sorting: "主播排序",
   hint_42:
     "提示：1-權重為正整數，數值越大越靠前；2-預設前端顯示，隱藏則前端不展示；",
   Update: "更新",
   Channel_sorting: "頻道排序",
   Channel_nickname: "頻道昵稱",
   Secondary_classification: "二級分類",
   Live_streaming_type: "直播類型",
   hint_698: "關聯多項，請使用英文逗號分割",
   Streaming_settings: "推流設置",
   End_all_live_broadcasts: "結束全部直播",
   Signal_source: "信號源",
   Push_flow_room: "推流房間",
   Streaming_Title: "推流標題",
   Push_Streaming_Channel: "推流頻道",
   There_are_currently_no_channels_available: "當前暫無頻道",
   Streaming_address: "推流地址",
   Streaming_address_server_address_: "推流地址（伺服器地址）",
   Copy: "複製",
   Push_stream_code_stream_key_: "推流碼（串流秘鑰)",
   End_Live: "結束直播",
   hint_321: "如沒有可使用的頻道，請先：",
   Create_channel: "創建頻道",
   You_are_about_to_use_it: "您即將用",
   Start_broadcasting: "進行開播",
   Start_live_streaming: "開始直播",
   Copy_successful: "複製成功",
   hint_697: "請填寫完整資訊再提交",
   hint_696: "確定要結束直播嗎？",
   hint_695: "確定要結束全部直播嗎？",
   Choose_a_live_broadcast_room: "選擇直播間",
   Live_streaming_room: "直播間",
   League_name: "聯賽名稱",
   Please_enter_the_league_name: "請輸入聯賽名稱",
   Popular: "熱門",
   Normal: "普通",
   The_continent_where_it_is_located: "所在洲",
   Football: "足球",
   Basketball: "籃球",
   Date: "日期",
   Start_date: "開始日期",
   To: "至",
   End_date: "結束日期",
   In_the_competition: "比賽中",
   Delay: "推遲",
   Popular_events: "熱門賽事",
   Keywords_1: "關鍵字",
   Please_enter_keywords: "請輸入關鍵字",
   Batch_editing_of_popular_content: "批量編輯熱門",
   Batch_creation_of_reserved_events: "批量創建預約賽事",
   Country_Region: "國家/地區",
   Current_round: "當前輪次",
   Edit_Appointment_Anchor: "編輯預約主播",
   Please_choose_to_create_a_schedule: "請選擇創建賽程",
   Please_select_the_associated_channel: "請選擇關聯頻道",
   Basketball_schedule_data: "籃球賽程數據",
   No_selected_items: "無選中項",
   Main_Station_Event_ID: "總台賽事ID",
   Please_enter_the_main_event_ID: "請輸入總台賽事ID",
   Third_party_in_game_event_ID: "協力廠商賽中賽事ID",
   hint_694: "請輸入協力廠商賽中賽事ID",
   There_are_streaming_anchors: "存在推流主播",
   There_is_a_push_flow_room: "存在推流房間",
   Click_on_the_nickname_modification_announcement: "（點擊昵稱修改公告）",
   Please_select_a_popular_event: "請選擇熱門賽事",
   Edit_successful: "編輯成功",
   E_sports_streaming: "電競流",
   Entertainment_stream: "娛樂流",
   Create_Popular: "創建熱門",
   Official_Clearflow: "官方清流",
   Anchor_Qingliu: "主播清流",
   Score: "比分",
   The_competition_team: "比賽球隊",
   Create_circles: "創建圈子",
   Limit_1M: "限制1M",
   hint_900: "大小790*215px",
   Associate_Creator: "關聯創建者",
   Operation_label: "運營標籤",
   Add_tags: "新增標籤",
   Confirm_creation: "確定創建",
   Label_: "標籤{num}",
   Please_select_an_image: "請選擇圖片",
   Please_select_the_associated_creator: "請選擇關聯創建者",
   Publisher_userid: "發佈人userid",
   Message_content: "消息內容",
   Chat_Room: "聊天室",
   hint_45: "刪除後該消息將不在App端的該圈子內展示，確認刪除嗎？",
   Circle_Introduction: "圈子簡介",
   Circle_name: "圈子名稱",
   Circle_avatar: "圈子頭像",
   Creator_1: "創建者",
   Number_of_people_in_the_circle: "圈子人數",
   The_maximum_number_of_people_in_the_circle: "圈子人數上限",
   Circle_Cover: "圈子封面",
   Circle_dynamics_1: "圈子動態",
   Circle_Announcement: "圈子公告",
   Creation_time: "創建時間",
   Dissolve_the_circle: "解散圈子",
   Dissolved: "已解散",
   Submission_dynamics: "投稿動態",
   User_userid: "用戶userid",
   Number_of_comments: "評論數",
   Like_count: "點贊數",
   Preview: "預覽",
   Setting_up_circle_announcements: "設置圈子公告",
   Add_Announcement: "添加公告",
   Please_enter_the_link_name: "請輸入連結名稱",
   Display_link: "展示連結",
   Jump_link: "跳轉連結",
   Please_enter_the_jump_link: "請輸入跳轉連結",
   To_be_reviewed: "待審核",
   Listed: "已上架",
   Offline_1: "已下架",
   Rejected: "已拒絕",
   Deleted: "已刪除",
   hint_692: "請填寫完整公告連結資訊再提交",
   The_circle_has_disbanded: "圈子已解散",
   Are_you_sure_you_want_to_dissolve_this_circle_: "是否確認要解散該圈子？",
   Dissolution: "解散",
   hint_44: "是否確認要{status}該條動態？",
   hint_693: "是否確認要拒絕該條動態？",
   Successfully: "已成功",
   Remove_from_shelves: "下架",
   Listing: "上架",
   Circle_dynamics: "圈子動態數",
   Creator_nickname: "創建者昵稱",
   Creator_userid: "創建者userid",
   In_operation: "運營中",
   View_details: "查看詳情",
   Chat_messages: "聊天消息",
   Edit_anchor_information: "編輯主播資訊",
   Review: "審核",
   Anchor_Name: "主播姓名",
   Guild: "所在公會",
   Please_select_a_guild: "請選擇公會",
   Split_ratio: "分成比例",
   Please_select_the_sharing_ratio: "請選擇分成比例",
   Basic_salary: "底薪",
   Please_enter_the_base_salary: "請輸入底薪",
   Review_details: "審核詳情",
   Review_details_1: "詳情",
   Please_enter_the_ID_number: "請輸入身份證號碼",
   Please_enter_the_host_nickname: "請輸入主播昵稱",
   hint_43: "確定把該用戶從群主授權列表中刪除嗎？",
   Add_channel: "增加頻道",
   Default_sorting: "默認排序",
   Channel_ID: "頻道ID",
   Icon: "圖示",
   Page_template: "頁面範本",
   Tab_name: "tab名稱",
   Channel_Type: "頻道類型",
   hint_46: "只能上傳jpg/png檔，圖片尺寸需為120X120",
   Please_enter_a_valid_tab_name_: "請輸入有效的tab名稱！",
   Enter_tab_name: "輸入tab名稱",
   Content_ID: "內容ID",
   Please_enter_ID: "請輸入ID",
   Topped: "已置頂",
   Top: "置頂",
   Cancel_Topping: "取消置頂",
   Restore_Display: "恢復顯示",
   Expiration_time: "失效時間",
   Select_date_and_time: "選擇日期時間",
   Sealing_the_picture: "封圖",
   real_time_info: "資訊",
   Short_video: "短視頻",
   Dynamic: "動態",
   Brocade_Bag: "錦囊",
   The_expiration_time_has_not_been_set_yet: "還未設置失效時間",
   Please_select_a_future_time: "請選擇未來時間",
   Homepage_Content_Management: "首頁內容管理",
   Top_management_on_homepage: "首頁置頂管理",
   Please_enter_information_keywords: "請輸入資訊關鍵字",
   Keywords: "關鍵字",
   Batch_publishing: "批量發佈",
   Batch_scheduled_release: "批量定時發佈",
   Thumbnails: "縮略圖",
   Title_1: "標題",
   Crawling_time: "爬取時間",
   hint_47:
     "你可以選擇5分鐘後，今後一個星期內的任意時間定時發佈，在設定時間之前可以重新編輯和取消",
   Timed_release: "定時發佈",
   hint_48: "發佈後無法進行修改和撤銷，是否確認發佈？",
   hint_691: "未選擇任何資訊，請選擇資訊後再發佈！",
   Please_choose_a_reasonable_publishing_time: "請選擇合理的發佈時間",
   Edit_information: "編輯資訊",
   New_information: "新增資訊",
   Please_fill_in_the_necessary_title_and_author: "請填寫必要的標題和作者",
   Information_Title: "資訊標題",
   Comment_content: "評論內容",
   hint_49: "刪除後該評論將不在APP端、PC端內展示，確定刪除嗎？",
   hint_50: "確定取消發佈嗎？取消後該條資訊將進入草稿箱",
   hint_51: "確定下架嗎？下架後移動端將不出現此條資訊！",
   Cancel_Top_Time: "取消置頂時間",
   Publishing_Library: "發佈庫",
   Top_Library: "置頂庫",
   Crawling_website_titles: "爬取網站標題",
   Crawling_website_links: "爬取網站連結",
   Crawling_time_period: "爬取時段",
   Set_crawling_time_period: "設置爬取時間段",
   End_time: "結束時間",
   Select_a_time_range: "選擇時間範圍",
   hint_52: "禁用後則不爬取該網站資料，是否確認禁用？",
   hint_690: "確定要啟用此配置嗎？",
   hint_689: "確定要刪除此條資訊嗎？",
   Prize_Name: "獎品名稱",
   Please_enter_the_name_of_the_prize: "請輸入獎品名稱",
   Prize_type: "獎品類型",
   Query: "查詢",
   Add_prizes_1: "新增獎品",
   Prize_icon: "獎品icon",
   Please_enter_the_number_of_prizes: "請輸入獎品數量",
   quantity: "數量",
   Only_jpg_png_gif_files_can_be_uploaded: "只能上傳jpg/png/gif檔",
   Modify: "修改",
   New: "新增",
   Virtual_currency: "虛擬貨幣",
   Experience_value: "經驗值",
   hint_53: "上傳圖片只能是 JPG 或 PNG 或 GIF格式!",
   Winning_number: "中獎編號",
   Please_enter_the_winning_number: "請輸入中獎編號",
   UID: "UID",
   Please_enter_UID: "請輸入UID",
   Obtain_time: "獲得時間",
   Export: "匯出",
   hint_688: "直播間砸金蛋-獎品記錄",
   Basic_Settings: "基本設置",
   Red_envelope_rain_name: "紅包雨名稱",
   Edit_Red_Envelope_Rain_Library: "編輯紅包雨庫",
   New_Red_Envelope_Rain_Warehouse: "新建紅包雨庫",
   Please_enter_the_name_of_the_red_envelope_rain: "請輸入紅包雨名稱",
   Trigger_type: "觸發類型",
   Goal_scoring_events: "進球事件",
   Event_timeline: "賽事時間點",
   Specify_a_time_point: "指定時間點",
   Maximum_number_of_red_envelope_rain_wheels: "紅包雨輪數上限",
   Please_enter_the_number_of_rounds: "請輸入輪數",
   hint_54: "0為不下紅包雨，此為一場比賽最多紅包雨輪數的上限",
   hint_687: "最多中紅包個數(單個用戶單輪)",
   hint_55: "0為搶不到紅包，達到紅包個數數後則不會再搶到紅包",
   Fixed_point_in_time: "固定時間點",
   Select_date: "選擇日期",
   Probability_of_winning_a_prize_red_envelope: "抽中有獎紅包概率",
   Please_enter_the_probability: "請輸入概率",
   hint_56: "0為不可能抽中紅包，此為用戶抽中有獎紅包的概率",
   hint_686: "請輸入紅包雨輪數上限",
   Award_Pool_Settings: "獎池設置",
   Add_prizes: "添加獎品",
   Number_of_individual_red_envelope_rewards: "單個紅包獎勵個數",
   Please_enter_the_number: "請輸入個數",
   Number_of_red_envelopes: "紅包數量",
   Please_enter_the_quantity: "請輸入數量",
   Prize_weight: "獎品權重",
   Please_fill_in_the_fixed_time_point: "請填寫固定時間點",
   Please_select_a_time_point_for_the_competition: "請選擇賽事時間點",
   hint_685: "僅可配置0 - 100的正整數數位",
   Please_add_at_least_one_prize: "請至少添加一條獎品",
   hint_684: "請填寫完整獎品資訊再提交",
   hint_57: "輸入有誤，單個紅包獎勵個數、紅包數量僅可配置1 - 99999的正整數數位",
   Add_has_reached_the_limit: "添加已達到上限",
   Golden_Egg_Switch: "金蛋開關",
   Basic_Information_Configuration: "基本資訊配置",
   APP_background_image: "APP背景圖",
   APP_Title_Image: "APP標題圖",
   The_total_probability_must_be_10000: "概率總和必需為10000",
   Red_envelope_rain_storage_details: "紅包雨庫詳情",
   hint_58: " 0為不下紅包雨，此為一場比賽最多紅包雨輪數的上限",
   Wheel: "輪",
   Pieces: "個",
   Task_type: "任務類型",
   Reward_Name: "獎勵名稱",
   Task_Title: "任務標題",
   Task_icon: "任務icon",
   Task_count: "任務次數",
   Application_channels: "應用管道",
   Edit_task: "編輯任務",
   Reward_type: "獎勵類型",
   Reward_quantity: "獎勵數量",
   Please_enter_the_number_of_rewards: "請輸入獎勵數量",
   Please_select_the_reward_type_icon: "請選擇獎勵類型icon",
   Please_select_the_application_channel: "請選擇應用管道",
   Task_title_cannot_be_empty: "任務標題不能為空",
   Task_count_cannot_be_empty: "任務次數不能為空",
   To_be_effective: "待生效",
   In_effect: "生效中",
   Daily_tasks: "每日任務",
   Novice_Task: "新手任務",
   Sign_in_task: "簽到任務",
   Attendance_Record: "簽到記錄",
   Please_enter_the_task_title: "請輸入任務標題",
   Check_in_switch: "簽到開關",
   No_1: "第",
   Number_of_check_in_days: "簽到天數",
   Reward_details: "獎勵明細",
   Reward_icon: "獎勵icon",
   Edit_check_in: "編輯簽到",
   Lottery: "彩金",
   Physical_object: "實物",
   Please_upload_the_reward_type_icon: "請上傳獎勵類型icon",
   Sign_in_has_been_closed: "已關閉簽到",
   Check_in_has_been_opened: "已打開簽到",
   Please_fill_in_the_quantity: "請填寫數量",
   Please_enter_the_room_number_for_sending: "請輸入發送房間號",
   Send_room_number: "發送房間號",
   Sending_time: "發送時間",
   Details_of_receiving_red_envelopes: "紅包領取詳情",
   hint_683: "暫無紅包領取詳情",
   Anchor_room_number: "主播房間號",
   Please_enter_the_host_room_number: "請輸入主播房間號",
   Remove_permissions: "移除許可權",
   Add_anchor: "添加主播",
   Time: "時間",
   Anchor_userID: "主播userID",
   Please_enter_the_anchor_userID: "請輸入主播userID",
   Betting_amount: "投注金額",
   Prophecy_betting_list: "預言投注列表",
   Please_enter_the_name_of_the_main_team: "請輸入主隊名稱",
   Please_enter_the_name_of_the_visiting_team: "請輸入客隊名稱",
   Please_enter_the_status: "請輸入狀態",
   Team_PK_ID: "球隊PK ID",
   The_winner: "勝方",
   Number_of_gift_givers_1: "送禮人數",
   Total_value_in_gold_coins: "總價值(金幣)",
   The_losing_side: "敗方",
   Binding_events: "綁定賽事",
   Please_enter_the_binding_event: "請輸入綁定賽事",
   Bind_anchor: "綁定主播",
   Please_enter_the_bound_anchor: "請輸入綁定主播",
   Activity_Strategy_Name: "活動策略名稱",
   hint_682: "請輸入活動策略名稱",
   Red_envelope_rain_claim_details: "紅包雨領取詳情",
   Effective_anchor_room_details: "生效主播房間詳情",
   To_be_published: "待發佈",
   Published: "已發佈",
   Canceled: "已取消",
   hint_681: "是否確認取消發佈",
   Main_switch: "總開關",
   Policy_Name: "策略名稱",
   Please_enter_a_policy_name: "請輸入策略名稱",
   Add_Policy: "添加策略",
   Red_envelope_rain_storage: "紅包雨庫",
   Red_envelope_rain_type: "紅包雨類型",
   Event_red_envelope_rain: "賽事紅包雨",
   Live_broadcast_room_chat_room: "直播間/語聊室",
   Event_type: "賽事類型",
   Distribute_users: "下發用戶",
   hint_60: "請輸入用戶UID，多個UID使用英文逗號隔開",
   Please_select_the_type_of_red_envelope_rain: "請選擇紅包雨類型",
   Please_select_an_event: "請選擇賽事",
   Please_select_the_red_envelope_rain_storage: "請選擇紅包雨庫",
   Edit_Policy: "編輯策略",
   Strategy_details: "策略詳情",
   hint_61: "下線該活動策略將導致該活動從前端下線，確定下線嗎",
   Offline_2: "下線",
   Live_room_room_number: "直播間房間號",
   hint_680: "請輸入直播間房間號",
   Configuration_Management: "配置管理",
   Prize_configuration: "獎品配置",
   Prize_Record: "獎品記錄",
   Anchor_cumulative_share: "主播累計分成",
   Option_A_cumulative_betting: "選項A累計投注",
   Option_A: "選項A",
   Option_B: "選項B",
   Option_B_cumulative_betting: "選項B累計投注",
   Results: "結果",
   Number_of_bets: "投注人數",
   Anchor_sharing_3: "主播分成（3%）",
   Platform_share_2: "平臺分成（2%）",
   The_anchor_prophecy: "主播預言",
   The_prophecy_of_the_whole_nation: "全民預言",
   Login_time: "登錄時間",
   Online_duration: "線上時長",
   Inviters_nickname: "邀請人昵稱",
   Invitee_UID_: "被邀請人UID：",
   Invitation_time: "邀請時間",
   Inviter_payment_method: "邀請人收款方式",
   Alipay: "支付寶",
   WeChat_account: "微信號",
   QQ_account: "QQ帳號",
   Inviting_person_rewards: "邀請人獎勵",
   Distributed: "已派發",
   Not_passed: "未通過",
   Accumulated: "累計中",
   Invitee_nickname: "被邀請人昵稱",
   Invitee_rewards: "被邀請人獎勵",
   Select_distribution_type: "選擇派發類型",
   Please_enter_a_rule_description: "請輸入規則說明",
   Are_you_sure_to_cancel_the_distribution_: "確認取消派發嗎？",
   APP_user_login_statistics: "APP用戶登錄統計",
   hint_676: "邀請人獎勵不能小於0",
   hint_677: "邀請人獎勵不能大於99999",
   hint_675: "被邀請人獎勵不能小於0",
   hint_678: "被邀請人獎勵不能大於99999",
   Room_type: "房間類型",
   The_ban_was_successful: "禁言成功",
   hint_62:
     "刪除後該消息將不在App端、PC端、H5端的該直播間/聊天室內展示，確定隱藏嗎？",
   Please_enter_the_user_UID: "請輸入用戶UID",
   Prohibition_time: "禁言時間",
   Forbidden_live_broadcast_room: "禁言直播間",
   Prohibition_type: "禁言類型",
   Prohibition_reasons: "禁言理由",
   Operator_UID: "操作人UID",
   Successfully_unblocked: "解禁成功",
   Opening_duration: "開播時長",
   The_duration_of_the_current_live_broadcast: "當前直播的直播時長",
   Current_number_of_views: "當前流覽次數",
   hint_674: "當前直播的流覽次數（去重）",
   Current_number_of_online_users: "當前線上人數",
   hint_673: "當前直播的線上觀看人數（去重）",
   The_highest_number_of_online_users: "最高線上人數",
   hint_672: "當前直播的最高線上人數（去重）",
   hint_63: "統計包含未登錄用戶，未登錄用戶未去重。",
   Field_Description: "欄位說明",
   Anchor_UID: "主播UID",
   Live_Channel: "直播頻道",
   Start_time_1: "開播時間",
   Downtime: "下播時間",
   Opening_duration_minutes_: "開播時長(分鐘)",
   Accumulated_views: "累計流覽次數",
   Accumulated_number_of_online_users: "累計線上人數",
   Average_viewing_time_minutes_: "平均觀看時長(分鐘)",
   The_entire_live_broadcast_data: "整場直播數據",
   Edit_data: "編輯資料",
   Add_announcement_link: "添加公告連結",
   Announcement_link: "公告連結",
   Please_enter_the_display_link: "請輸入展示連結",
   Recommended_display: "推薦展示",
   Browser_Open: "流覽器打開",
   Display_end: "顯示端",
   Mobile_end: "移動端",
   PC_end: "PC端",
   Application_time: "申請時間",
   Global_announcement_adjustment: "全域公告調整",
   Sports_type: "體育類型",
   On_the_front_of_the_ID_card: "身份證正面",
   On_the_back_of_the_ID_card: "身份證反面",
   Holding_an_ID_card: "手持身份證",
   Reason_for_rejection: "拒絕原因",
   Lifting_the_ban: "解除封禁",
   Refused_to_pass: "拒絕通過",
   Please_enter_the_anchor_name: "請輸入主播姓名",
   ID_number: "身份證號",
   Front_photo_of_ID_card: "身份證正面照",
   Back_photo_of_ID_card: "身份證反面照",
   Housing_Management_Uid: "房管Uid",
   Please_enter_the_housing_management_Uid: "請輸入房管Uid",
   Housing_management_nickname: "房管昵稱",
   hint_671: "請輸入房管昵稱",
   Add_property_management: "添加房管",
   Cancel_housing_management: "取消房管",
   Please_enter_your_name: "請輸入姓名",
   hint_670: "姓名請輸入漢字",
   Approved: "審核通過",
   Rejected_for_review: "審核拒絕",
   Prohibited: "已封禁",
   The_ID_photo_is_blurry: "證件照模糊",
   The_ID_number_does_not_match_the_ID_photo: "身份證號碼與證件照不匹配",
   My_ID_card_does_not_match_me: "身份證與本人不匹配",
   ID_card_number_is_blocked: "身份證號碼遮擋",
   Facial_occlusion: "人臉遮擋",
   Room: "房間",
   Please_select_the_reason: "請選擇原因",
   hint_669: "請輸入要替換的公告名稱或連結",
   Are_you_sure_you_want_to_pass_the_review_: "確定要審核通過嗎？",
   hint_301: "確定恢復該主播直播許可權嗎？",
   Are_you_sure_you_want_to_ban_this_anchor_: "確定將該主播封禁嗎？",
   Main_account_UID: "主帳號UID",
   Please_enter_the_main_account_UID: "請輸入主帳號UID",
   Merge_account_UID: "合併帳號UID",
   Please_enter_the_merged_account_UID: "請輸入合併帳號UID",
   Add_Merge: "新增合併",
   Master_account_nickname: "主帳號昵稱",
   Merge_account_nicknames: "合併帳號昵稱",
   Merge_time: "合併時間",
   hint_64: "合併帳號後，被合併主播帳號開播，將使用主帳號進行上播展示",
   Merge_accounts: "合併帳號",
   Refresh: "刷新",
   hint_668: "注：線上資料略有延遲",
   Note: "注",
   Basic_information_1: "基礎資訊",
   Anchor_Information: "主播信息",
   Real_time_live_streaming_data: "即時直播資料",
   hint_667: "當前流資訊不可用！",
   Anchor_Management: "主播管理",
   Merge_anchor_accounts: "主播帳號合併",
   Identify_the_main_switch: "鑒別總開關",
   The_current_status_is: "當前狀態為",
   There_is_currently_no_data_available: "暫無數據",
   Jian_Huang: "鑒黃",
   Fear_prevention: "鑒恐",
   Jianzheng: "鑒政",
   hint_303: "確定要將此直播間斷流嗎？",
   Thermal_value_coefficient: "熱力值係數",
   Heat_value_base: "熱力值基數",
   Modified_by: "修改人",
   Latest_modification_time: "最新修改時間",
   Heat_value_setting: "熱力值設置",
   Role_Name: "角色名稱",
   Whether_to_enable: "是否啟用",
   Live_broadcast_room_type: "直播間類型",
   Initial_heat_value: "初始熱力值",
   Last_editing_time: "最後編輯時間",
   Edit_heat_value: "編輯熱力值",
   Initial_heating_value_: "初始熱力值：",
   Please_enter_the_heat_value: "請輸入熱力值",
   Please_fill_in_the_heat_value: "請填寫熱力值",
   Please_enter_the_anchor_UID: "請輸入主播UID",
   Live_streaming_services: "直播流服務",
   Batch_editing: "批量編輯",
   All_anchors: "全部主播",
   Platform_anchor_stream: "平臺主播流",
   Event_clearance: "賽事清流",
   Anchor_event_flow: "主播賽事流",
   Esports_Entertainment_Stream: "電競娛樂流",
   Live_streaming_2: "正在直播",
   Field: "場",
   hint_665: "確定要將此直播間下播嗎？",
   hint_664: "確定要將此直播間禁播嗎？",
   This_PV: "本場PV",
   UV_in_this_field: "本場UV",
   Peak_value: "峰值",
   Accumulated_viewing_time_minutes_: "累計觀看時長(分鐘)",
   Total_number_of_followers: "總關注數",
   Number_of_followers_in_this_session: "本場關注數",
   The_number_of_levels_taken_in_this_game: "本場取關數",
   Net_increase_in_levels_in_this_game: "本場淨增關數",
   Live_broadcast_session_data_of_anchors: "主播直播場次數據",
   Forbidden_List: "禁言列表",
   Team: "球隊",
   Please_enter_the_team: "請輸入球隊",
   Push_order_status: "推單狀態",
   hint_306: "確認要隱藏該推單嗎？隱藏後前端不可見",
   hint_305: "確認要顯示該推單嗎？顯示後前端可見",
   Please_select_the_correction_result: "請選擇修正結果",
   Reporting_time: "舉報時間",
   Whistleblower_userid: "舉報人userid",
   Enter_the_whistleblower_userid: "輸入舉報人userid",
   Enter_room_number: "輸入房間號",
   Reply_or_not: "是否回復",
   Reason_for_reporting: "舉報原因",
   Reply_content: "回復內容",
   hint_663: "請輸入回復內容(100字以內)",
   The_reply_content_cannot_be_empty: "回復內容不能為空",
   Homepage_Configuration: "首頁配置",
   Level_animation_switch: "等級動畫開關",
   Cat_coin_animation_switch: "貓幣動畫開關",
   Enter_animation_switch_: "進場動畫開關：",
   APP_membership_background: "APP會員背景",
   Membership_Benefits_Background: "會員權益背景",
   APP_H5_Membership_Benefits_Background: "APP/H5會員權益背景",
   Background_of_PC_Membership_Benefits: "PC會員權益背景",
   hint_409: "注：尺寸為926x126",
   VIP_discount_configuration: "vip折扣配置",
   Please_enter_VIP: "請輸入VIP",
   hint_402: "請輸入vip折扣（例如：0.9）",
   hint_403: "請輸入vip折扣名稱（例如：9折）",
   Edit_Channel: "編輯頻道",
   Add_a_new_channel: "新增頻道",
   Channel_avatar: "頻道頭像",
   hint_401: "尺寸：114*114 只能上傳jpg/png檔",
   Channel_Cover: "頻道封面",
   hint_405: "尺寸：522*294 只能上傳jpg/png檔",
   hint_406: "提示：自動截取流畫面，失敗後則使用默認封面",
   hint_407: "確定恢復該頻道直播許可權嗎？",
   hint_408: "確定將該頻道封禁嗎？",
   Schedule_data: "賽程數據",
   Edit_Announcement: "編輯公告",
   Create_Announcement: "創建公告",
   Announcement_type: "公告類型",
   Image_and_text_links: "圖文連結",
   Picture_and_text_messages: "圖文消息",
   Activity_link: "活動連結",
   Announcement_Title: "公告標題",
   Cover_image: "封面圖",
   Whether_to_push: "是否推送",
   Select_content: "選擇內容",
   Activity_Title: "活動標題",
   Event_Introduction: "活動簡介",
   Please_select_the_announcement_type: "請選擇公告類型",
   Please_select_the_publishing_time: "請選擇發佈時間",
   Please_enter_the_announcement_title: "請輸入公告標題",
   Please_enter_the_activity_title: "請輸入活動標題",
   Please_enter_the_announcement_content: "請輸入公告內容",
   Please_fill_in_the_activity_introduction: "請填寫活動簡介",
   Please_enter_the_text_message_content: "請輸入短信內容",
   Send_to: "發送對象",
   Upload_data: "上傳數據",
   Download_List_Excel_Template: "下載名單Excel範本",
   Receive_user_list: "接收用戶列表",
   All_platform_users: "全平臺用戶",
   Not_sent: "未發送",
   Sent: "已發送",
   Cancel_sending: "取消發送",
   Specify_user: "指定用戶",
   Please_enter_the_theme: "請輸入主題",
   Multiple_separated_by_commas: "多個以逗號隔開",
   Template_ID: "範本ID",
   Instant_sending: "即時發送",
   Please_select_template_ID: "請選擇範本ID",
   Are_you_sure_to_send_to_all_users_: "是否確認向所有用戶發送？",
   Sending_successful: "發送成功",
   Channel_template_ID: "管道方範本ID",
   Please_enter_the_channel_template_ID: "請輸入管道方範本ID",
   Template_content: "範本內容",
   Please_enter_template_content: "請輸入範本內容",
   Please_select_a_channel: "請選擇管道",
   Please_enter_your_signature: "請輸入簽名",
   Alibaba_Cloud: "阿裡雲",
   Notification: "通知",
   Marketing: "行銷",
   Verification_code: "驗證碼",
   First_select_the_SMS_type: "先選擇短信類型",
   Please_fill_in_the_channel_template_ID: "請填寫管道方範本ID",
   Message_type: "消息類型",
   New_announcement: "新增公告",
   Official_announcement_title: "官方公告標題",
   Unpublish: "取消發佈",
   Jump_type: "跳轉類型",
   Push_time: "推送時間",
   Please_enter_the_bag_ID: "請輸入錦囊ID",
   Decimal_cannot_be_entered: "不能輸入小數",
   Please_enter_the_H5_link: "請輸入H5連結",
   Push_details: "推送詳情",
   Edit_push: "編輯推送",
   Add_push: "新增推送",
   Push_order_page: "推單頁",
   Please_select_the_push_time: "請選擇推送時間",
   Please_select_the_effective_time: "請選擇生效時間",
   hint_660: "確定撤銷本次推送任務嗎？",
   Add_menu: "新增菜單",
   catalogue: "目錄",
   Menu: "菜單",
   Menu_icons: "功能表圖示",
   Menu_Title: "菜單標題",
   Routing_address: "路由地址",
   Component_Name: "組件名稱",
   Component_path: "元件路徑",
   Whether_to_hide: "是否隱藏",
   Superior_categories: "上級類目",
   Select_superior_category: "選擇上級類目",
   Menu_Name: "菜單名稱",
   Permission_identification: "許可權標識",
   Edit_menu: "編輯功能表",
   Please_enter_the_menu_title: "請輸入功能表標題",
   Please_enter_the_routing_address: "請輸入路由位址",
   Please_enter_the_component_name: "請輸入元件名稱",
   Please_enter_the_component_path: "請輸入元件路徑",
   Please_select_the_superior_category: "請選擇上級類目",
   Top_level_categories: "頂級類目",
   hint_661: "確定要刪除此菜單嗎？",
   Please_enter_the_role_name: "請輸入角色名稱",
   Add_new_role: "新增角色",
   Role_ID: "角色ID",
   Creator: "創建人",
   Authorization: "授權",
   Edit_Role: "編輯角色",
   Audit_scenario: "審核場景",
   Please_enter_the_review_scenario: "請輸入審核場景",
   Review_page: "審核頁面",
   Edit_audit_scenario_account: "編輯審核場景帳號",
   Operations_Manager_Account: "運營負責人帳號",
   Please_enter_the_review_account: "請輸入審核帳號",
   Please_enter_your_account: "請輸入帳號",
   New_users: "新增用戶",
   Bind_characters: "綁定角色",
   Latest_reset_time: "最新重置時間",
   Please_select_a_role: "請選擇角色",
   Input_cannot_be_empty: "輸入不能為空",
   Edit_Users: "編輯用戶",
   hint_659: "確定要停用此帳戶嗎？",
   Account_deactivated: "帳戶已停用",
   hint_658: "確定要重置此帳號密碼嗎？",
   Reset_successful: "重置成功",
   The_new_password_is: "新密碼為",
   Gift_Name: "禮物名稱",
   Please_enter_the_gift_name: "請輸入禮物名稱",
   Gift_category: "禮物類別",
   VIP_gift: "VIP禮物",
   Add_gift: "添加禮物",
   Gift_unit_price_in_gold_coins_: "禮物單價(金幣)",
   Banner_gift: "橫幅禮物",
   Full_screen_gift: "全屏禮物",
   Gift_icon: "禮物圖示",
   Size_does_not_exceed_1MB_: "大小不超過1MB！",
   Enable_floating_screen: "啟用飄屏",
   Floating_screen_category: "飄屏類別",
   hint_657: "請選擇飄屏類別",
   Please_select_VIP_level: "請選擇VIP等級",
   hint_656: "該等級及以上VIP使用者可使用",
   hint_601: "最多可輸入4個中文漢字或者8個英文字母或者8個數位",
   Please_enter_the_price: "請輸入價格",
   hint_602: "禮物單價不能大於9999999或小於0",
   Please_upload_the_gift_icon: "請上傳禮物圖示",
   Please_upload_the_playback_effect: "請上傳播放效果",
   Please_upload_the_preview_effect: "請上傳預覽效果",
   Edit_Gift: "編輯禮物",
   Please_choose_a_gift_type: "請選擇禮物類型",
   Please_select_the_floating_screen_type: "請選擇飄屏類型",
   Please_select_whether_combo_is_possible: "請選擇是否可連擊",
   Only_SVGA_files_can_be_uploaded_: "只能上傳svga檔!",
   Only_GIF_files_can_be_uploaded: "只能上傳gif檔",
   hint_66:
     "下線該禮物則該禮物從禮物倉庫中下線，即配置禮物策略中不出現該禮物，確定下線嗎？",
   Successfully_taken_offline: "下線成功",
   hint_67:
     "上線該禮物則該禮物重新上線至禮物倉庫，即配置禮物策略中重新出現該禮物，確定上線嗎",
   Gift_data_statistics: "禮物資料統計",
   User_gift_details: "用戶送禮明細",
   Gift_List: "禮物列表",
   Distribution_scope: "下發範圍",
   Effective_time: "生效時間",
   Last_modified_time: "最近修改時間",
   Last_Editor: "最後編輯人",
   hint_68:
     "請輸入禮物ID，多個禮物之間使用英文逗號隔開，輸入順序則為前端禮物展示順序",
   Sending_gifts: "下發禮物",
   hint_72: "刪除該禮物策略將導致該策略刪除，確定刪除嗎？？",
   hint_70: "下線該禮物策略將導致該策略房間的禮物從前端下線，確定下線嗎？",
   hint_71: "上線該禮物策略則該策略房間的禮物從前端上線，確定上線嗎？",
   Expert_type: "專家類型",
   Import_experts: "導入專家",
   Introduction: "簡介",
   Hit_rate: "命中率",
   Recent_status: "近期狀態",
   The_highest_consecutive_red: "最高連紅",
   hint_604: "近期連紅",
   Number_of_proposals: "方案數量",
   Number_of_buyers: "購買人數",
   Accumulated_total_revenue: "累計總收益",
   Settlable_income: "可結算收益",
   Add_Expert: "添加專家",
   User_userid_cannot_be_empty: "用戶userid不能為空",
   Please_enter_the_league_and_team_name: "請輸入聯賽、球隊名稱",
   Home_team_vs_away_team: "主隊VS客隊",
   League: "聯賽",
   Choose_a_competition: "選擇賽事",
   Event_information: "賽事信息",
   Select_recommendation: "選擇推薦",
   Yazhi: "亞指",
   The_main_winner: "主勝",
   Customer_win: "客勝",
   Small_and_large_balls: "大小球",
   Greater_than: "大於",
   ball: "球",
   European_Compensation: "歐賠",
   A_draw: "平局",
   Wanbo: "萬博",
   Le_Dong: "樂動",
   Macau: "澳門",
   crown: "皇冠",
   hint_655: "請先選擇玩法",
   Add_recommendation_group: "添加推薦組",
   Recommended_group_name: "推薦組名稱",
   Circle_details: "圈子明細",
   Number_of_recommended_circles: "推薦圈子數量",
   Resource_bit_name: "資源位名稱",
   Expert_details: "專家明細",
   Number_of_recommended_experts: "推薦專家數量",
   Pause: "暫停",
   Add_anchors: "增加主播",
   X_connected_red: "X連紅",
   Near_X_in_X: "近X中X",
   Return_rate: "回報率",
   hint_653: "請填寫至少一條完整主播資訊",
   hint_654: "確定推薦該組嗎？",
   hint_652: "確定暫停該推薦組嗎？",
   Up_to_20_can_be_configured: "最多可以配置加20個",
   Edit_selling_price: "編輯售價",
   First_gear: "第一檔",
   Second_gear: "第二檔",
   Third_gear: "第三檔",
   Release_Brocade_Bag: "發佈錦囊",
   Brocade_Bag_Type_: "錦囊類型：",
   Football_recommendations: "足球推薦",
   Basketball_recommendations: "籃球推薦",
   hint_651: "請選擇賽事設置推薦選項",
   Please_enter_the_publisher_account: "請輸入發佈者帳號",
   Please_enter_the_recommendation_reason: "請輸入推薦理由",
   Please_set_the_publishing_time: "請設置發佈時間",
   hint_650: "已結束比賽需要選擇發佈時間",
   Expert_management: "專家管理",
   Popular_experts: "熱門專家",
   Hit: "命中",
   Returned: "已返還",
   To_be_returned: "待返還",
   On_sale: "售賣中",
   Cut_off_orders: "截單",
   Order_status: "訂單狀態",
   Purchase_time: "購買時間",
   Please_enter_the_competition: "請輸入賽事",
   Competition: "賽事",
   Publisher: "發佈人",
   Please_enter_the_publisher: "請輸入發佈人",
   Purchase_successful: "購買成功",
   hint_73: "確定下架嗎？下架後移動端將不出現此條短視頻？",
   Bullet_Screen_Library: "彈幕庫",
   Add_bullet_library: "添加彈幕庫",
   Batch_Import: "批量導入",
   Add_bullet_comments: "添加彈幕",
   Bullet_screen_content: "彈幕內容",
   Recent_Editors: "最近的編輯人",
   Import_data: "導入數據",
   Edit_bullet_screen: "編輯彈幕",
   hint_649: "支持添加多條彈幕，多個彈幕用英文逗號隔開，單次最多添加1000條。",
   Label_One: "標籤一",
   Label_2: "標籤二",
   Label_Three: "標籤三",
   Label_Four: "標籤四",
   Label_Five: "標籤五",
   Please_enter_the_bullet_content: "請輸入彈幕內容",
   hint_648: "上傳大小不能超過 10MB!",
   Successfully_added_barrage: "添加彈幕成功",
   Update_barrage_successful: "更新彈幕成功",
   hint_647: "上傳檔只能是 xls格式!",
   Import_failed: "導入失敗",
   Import_successful: "導入成功",
   hint_646: "確定要刪除此條彈幕嗎？",
   Number_of_fans: "粉絲數",
   Live_broadcast_time: "直播時間",
   Duration_minutes_: "時長(分鐘)",
   Number_of_gift_givers: "送禮人數(個)",
   Number_of_gifts_given: "送禮個數(個)",
   Live_room_revenue_feather_: "直播間收益(羽毛)",
   Guilds: "公會",
   Settlable_income_yuan_: "可結算收益(元)",
   Reward_and_punishment_income_yuan: "獎懲收益(元)",
   Total_revenue_yuan_: "總收益(元)",
   Adjustment_type_: "調整類型：",
   hint_645: "獎懲原因：",
   Settlement_bill_review: "結算帳單審核",
   Originating_Reviewer: "發起審核人",
   Operations_Manager_: "運營負責人",
   Reissue: "重新發起",
   Through: "通過",
   Please_enter_the_amount: "請輸入金額",
   Please_enter_the_reason: "請輸入原因",
   Guild_name: "公會名稱",
   Please_enter_the_guild_name: "請輸入公會名稱",
   Guild_ID: "公會ID",
   Receiving_account_name: "收款帳戶名稱",
   Opening_Bank: "開戶行",
   Bank_card_number: "銀行卡號",
   Account_opening_address: "開戶地址",
   Number_of_anchors: "主播個數",
   Please_fill_in_your_phone_number: "請填寫手機號碼",
   Please_fill_in_your_bank_card_number: "請填寫銀行卡號",
   hint_75: "啟用後將可為主播分配該公會，是否確定啟用此公會",
   Resource_location: "資源位置",
   Is_it_effective_: "是否生效",
   Jump_position: "跳轉位置",
   Please_enter_the_jump_location: "請輸入跳轉位置",
   Please_select_a_cover: "請選擇封面",
   Please_select_a_jump_type: "請選擇跳轉類型",
   Please_select_a_resource_location: "請選擇資源位置",
   hint_76: "注：權重越大當前廣告位越靠前",
   Is_the_browser_open_: "是否流覽器打開",
   hint_644: "確認要啟用該廣告嗎？",
   hint_643: "確認要停用該廣告嗎？",
   hint_642: "確認要移除該廣告位嗎？",
   Add_an_open_screen_advertisement: "新增開屏廣告",
   hint_641: "請保證視頻格式為MP4格式，且不超過5M",
   hint_77: "只能上傳jpg/png/gif檔，圖片尺寸需為750X1624",
   Please_enter_the_link_address: "請輸入連結位址",
   Please_enter_the_live_broadcast_room_ID: "請輸入直播間ID",
   Please_enter_the_information_ID: "請輸入資訊ID",
   Please_enter_the_chat_room_ID: "請輸入聊天室ID",
   The_input_information_format_is_incorrect: "輸入資訊格式有誤",
   Chat_room_ID: "聊天室ID",
   Brocade_Bag_ID: "錦囊ID",
   hint_640: "只能上傳jpg/png/gif檔，建議尺寸為",
   Please_enter_the_sorting_number: "請輸入排序號",
   Sort: "排序",
   The_length_cannot_exceed_20: "長度不能走過20",
   hint_78: "下線後該彈窗從移動端下架，是否確定下線此彈窗？",
   hint_639: "上傳圖片大小不能超過 1MB",
   Page_Name: "頁面名稱",
   Please_enter_the_page_name: "請輸入頁面名稱",
   Parameters: "參數",
   PC_Link: "PC連結",
   H5_Link: "H5連結",
   PC_Preview: "PC預覽",
   H5_Preview: "H5預覽",
   PC_Resource_Map: "PC資源圖",
   H5_Resource_Map: "H5資源圖",
   Please_enter_parameters: "請輸入參數",
   Background_name: "背景名",
   Background_image: "背景圖",
   Set_background_name: "設置背景名",
   Set_sorting: "設置排序",
   Modify_background_image: "修改背景圖",
   Add_background_image: "新增背景圖",
   Please_fill_in_the_background_name: "請填寫背景名稱",
   Please_fill_in_the_sorting: "請填寫排序",
   Successfully_taken_down: "下架成功",
   Successfully_listed: "上架成功",
   Effective: "有效",
   Invalid: "無效",
   hint_606: "頻道開播數量（10分鐘統計一次）",
   Go_live: "上線",
   hint_605: "請設置排序號（1-999)",
   Please_enter_the_tab_name: "請輸入tab名稱",
   hint_79: "只能上傳jpg/png檔，圖片尺寸需為670*376",
   Please_set_the_status: "請設置狀態",
   Are_you_sure_you_want_to_go_online_: "確定要上線嗎",
   Are_you_sure_you_want_to_go_offline_: "確定要下線嗎",
   Anchor_userid: "主播userid",
   Chat_room_title: "聊天室標題",
   Cut_off_flow: "斷流",
   hint_638: "確定對此聊天室斷流嗎？",
   Upload_time: "上傳時間",
   Cover_type: "封面類型",
   Batch_pass: "批量通過",
   Batch_rejection: "批量拒絕",
   Voice_chat_room: "語音聊天室",
   Reject: "拒絕",
   Operated: "已操作",
   Passed: "已通過",
   Live_streaming_3: "直播",
   hint_637: "確定要刪除封面嗎？",
   Music_name: "音樂名",
   Please_enter_the_music_name: "請輸入音樂名稱",
   Author_name: "作者名",
   Please_enter_the_author_name: "請輸入作者名稱",
   Size: "大小",
   Playback_count: "播放次數",
   Trial_listening: "試聽",
   Please_enter_the_song_name: "請輸入歌曲名",
   Song_title: "歌曲名",
   Upload_music_files: "上傳音樂檔",
   Only_supports_MP3_format: "僅支援MP3格式",
   hint_80: "寬高比 60*60",
   Please_upload_music_files: "請上音樂檔",
   Edit_Music: "編輯音樂",
   Add_music: "新增音樂",
   Only_MP3_format_files_can_be_uploaded: "只能上傳mp3格式檔",
   The_size_cannot_exceed_20MB_: "大小不能超過 20MB!",
   hint_635: "確定要上架該音樂嗎？",
   hint_636: "確定要下架該音樂嗎？",
   Add_Recommendation: "新增推薦",
   Please_set_the_sorting_number_1_999_: "請設置排序號（1-999）",
   Editor_recommendation: "編輯推薦",
   UserId_cannot_be_empty: "UserId不能為空",
   Shutdown_time: "關播時間",
   Peak_heat_value: "熱力值峰值",
   Edit_Page: "編輯頁面",
   Add_Page: "添加頁面",
   Upload_at_least_one_resource_image: "至少上傳一端資源圖片",
   hint_81: "發佈後該連結將發佈至線上，是否確定發佈該頁面？",
   hint_82: "下架後該連結將不可線上上訪問，是否確定下架該頁面？",
   Select_Cover: "選擇封面",
   hint_633: "注意：生效時間不可重疊！！！",
   hint_83: "存在不完整資訊，請填寫完整再提交！！",
   hint_634: "上傳圖片大小不能超過 500K!",
   Recommended_image: "推薦圖",
   Display_sorting: "顯示排序",
   Edit_Secondary_Classification: "編輯二級分類",
   Classification: "分類",
   Add_Category: "添加分類",
   Please_enter_the_activity_name: "請輸入活動名稱",
   Activity_status: "活動狀態",
   Image: "圖片",
   Configuration_date: "配置日期",
   Please_enter_the_button_link: "請輸入按鈕連結",
   Button_link: "按鈕連結",
   Button_Text: "按鈕文案",
   hint_607: "請輸入按鈕文案，最大4個字",
   Button_color: "按鈕顏色",
   hint_608: "請輸入按鈕顏色，如#CCCCCC",
   Edit_Configuration: "編輯配置",
   New_configuration: "新增配置",
   hint_609: "請輸入按鈕文案",
   Please_enter_the_button_color: "請輸入按鈕顏色",
   hint_84: "確定要上架此範本嗎，上架後頁面範本會生效",
   hint_85: "確定要下架此範本嗎，下架後頁面按鈕會失效",
   APP_end: "APP端",
   H5_end: "H5端",
   AK_APP_end: "AK-APP端",
   AK_PC_end: "AK-PC端",
   AK_H5_end: "AK-H5端",
   Anchor_details: "主播明細",
   Recommended_number_of_anchors: "推薦主播數量",
   Anchor_: "主播{num}",
   Please_enter_the_anchor_ID: "請輸入主播ID",
   Please_fill_in_the_resource_slot_name: "請填寫資源位名稱",
   Please_fill_in_at_least_one_anchor_information: "請填寫至少一條主播資訊",
   Secondary_classification_valid_all: "二級分類(有效/全部)",
   SM_static_resources: "SM靜態資源",
   AK_static_resources: "AK靜態資源",
   More_Jumps: "更多跳轉",
   Editorial_Expert_Recommendation_Group: "編輯專家推薦組",
   Add_expert_recommendation_group: "新增專家推薦組",
   Near_0_in_0: "近0中0",
   Highest: "最高",
   Lian_Hong: "連紅",
   Recently: "近期",
   Permissions: "許可權",
   Are_you_sure_you_want_to_turn_it_on_: "確定開啟嗎？",
   Are_you_sure_you_want_to_close_it_: "確定關閉嗎？",
   Live_broadcast_room_name: "直播間名稱",
   Please_select_sorting: "請選擇排序",
   Edit_popular_recommendations: "編輯熱門推薦",
   Add_popular_recommendations: "新增熱門推薦",
   Advertising_name: "廣告名稱",
   Resource_type: "資源類型",
   Video_upload: "視頻上傳",
   Your_browser_does_not_support_video_playback: "您的流覽器不支援視頻播放",
   Re_upload: "重新上傳",
   Please_upload_the_video: "請上傳視頻",
   Offline: "已下線",
   Square_banner: "廣場banner",
   Page: "頁面",
   Information_details: "資訊詳情",
   Edit_open_screen_advertisement: "編輯開屏廣告",
   Please_select_a_resource_type: "請選擇資源類型",
   Please_select_an_open_screen_advertisement: "請選擇開屏廣告",
   The_uploaded_video_size_cannot_exceed_5MB_: "上傳視頻大小不能超過5MB哦",
   Please_upload_the_correct_video_format: "請上傳正確的視頻格式",
   Add_patches: "新增貼片",
   Display_location: "展示位置",
   Please_enter_the_title_of_the_patch_advertisement: "請輸入彈跳式廣告標題",
   hint_86: "只能上傳jpg/png檔，圖片尺寸需為100X100",
   Edit_patch_ads: "編輯彈跳式廣告",
   Add_patch_ads: "新增彈跳式廣告",
   Please_enter_the_advertisement_title: "請輸入廣告標題",
   Please_select_a_display_location: "請選擇展示位置",
   Recommended_by_experts_on_the_homepage: "首頁專家推薦",
   Live_streaming_page_anchor_recommendation: "直播頁主播推薦",
   hint_87: "上傳圖片只能是 JPG、PNG或gif格式!",
   Pop_up_name: "彈窗名稱",
   APP_Advertising: "APP廣告",
   Open_screen_advertising: "開屏廣告",
   Patch_advertising: "彈跳式廣告",
   Activity_pop_up: "活動彈窗",
   hint_611: "注：上架運營全部功能都已開放",
   Assistant: "小助手",
   Homepage_Live: "首頁直播",
   Live_broadcast_room_announcement: "直播間公告",
   Advertising_resource_allocation: "廣告資源位元配置",
   hint_610: "開啟則請求舊的廣告資源位素材",
   hint_88: "注：官方審核期間，部分功能未開放",
   APP_Brand_Name_: "APP品牌名稱：",
   Please_enter_the_APP_brand_name: "請輸入APP品牌名稱",
   Please_enter_contact_customer_service: "請輸入聯繫客服",
   hint_89: "上傳圖片尺寸有誤，請按格式要求上傳",
   Switch_initialization: "開關初始化",
   Version_configuration: "版本配置",
   Review_status: "審核狀態",
   Operation_status: "運營狀態",
   AppStore_version_number: "AppStore版本號",
   Please_enter_the_version_number: "請輸入版本號",
   Version_status: "版本狀態",
   Update_instructions: "更新說明",
   Configure_time: "配置時間",
   Please_enter_the_interface_version_number: "請輸入介面版本號",
   hint_90: "介面版本號由技術提供，不可私自訂！",
   Please_enter_update_instructions: "請輸入更新說明",
   Please_select_version_status: "請選擇版本狀態",
   Please_enter_the_AppStore_version_number: "請輸入AppStore版本號",
   Please_enter_the_short_video_title: "請輸入短視頻標題",
   Edit_Title: "編輯標題",
   Short_video_title_: "短視頻標題",
   hint_91: "發佈該條短視頻至移動端，是否確認發佈？",
   Crawling_website_sites: "爬取網站網站",
   Statistical_time: "統計時間",
   UV_data: "uv數據",
   Platform: "平臺",
   Add_circle_recommendations: "新增圈子推薦",
   Are_you_sure_you_want_to_pause_: "是否確定暫停？",
   Access_date: "訪問日期",
   Posting_time: "發帖時間",
   Poster_nickname: "發帖人昵稱",
   Interactive_red_envelopes: "互動紅包",
   Topic_Name: "話題名稱",
   Please_enter_the_topic_name: "請輸入話題名稱",
   hint_632: "話題熱度(10分鐘統計一次)",
   Accumulated_number_of_related_posts: "累計相關帖子數",
   Save_and_publish: "保存並發佈",
   Recommendation: "推薦",
   Add_recommended_topics: "新增推薦話題",
   Number_of_participants_in_the_topic: "話題參與人數",
   Number_of_topic_views: "話題流覽次數",
   Topic_release_time: "話題發佈時間",
   Topic_Top_Time: "話題置頂時間",
   Choose_a_topic: "選擇話題",
   Enter_the_topic_name_you_want_to_recommend: "輸入需要推薦的話題名稱",
   Editor_recommended_topic: "編輯推薦話題",
   The_topic_cannot_be_empty: "話題不能為空",
   hint_631: "請輸入話題名稱（15字以內）",
   hint_630: "請輸入話題簡介（100字以內）",
   Successfully_created_topic: "創建話題成功",
   Recharge: "充值",
   Consumption: "消費",
   Revenue: "收益",
   Order_number_1: "訂單編號",
   Recharge_amount: "充值金額",
   consumption_type: "消費類型",
   consumption_amount: "消費金額",
   revenue_type: "收益類型",
   revenue_amount: "收益金額",
   Additional_issuance: "增發",
   Uploading: "上傳中。。。",
   Batch_issuance: "批量增發",
   Download_batch_issuance_templates: "下載批量增發範本",
   Additional_currency_issuance: "增發貨幣",
   Increase_experience_value_of_issuance: "增發經驗值",
   Please_enter_user_userid: "請輸入用戶userid",
   Please_enter_the_quantity_of_coins: "請輸入金幣數量",
   Please_enter_a_note: "請輸入備註",
   VIP_level: "VIP等級",
   Additional_issuance_time: "增發時間",
   Are_you_sure_you_want_to_refuse_: "確定要拒絕嗎？",
   Personal_currency_issuance: "個人貨幣增發",
   VIP_currency_issuance: "VIP貨幣增發",
   Total_consumption_in_gold_coins: "總消耗（金幣）",
   Total_rechargeyuan: "總充值（元）",
   Number_of_Rechargers: "充值人數",
   Recharge_switch: "充值開關",
   RMB_amount_yuan_: "人民幣金額（元）",
   Number_of_gold_coins: "金幣數量",
   Edit_recharge_configuration: "編輯充值配置",
   Add_recharge_configuration: "新增充值配置",
   Recharge_time: "充值時間",
   Payment_channels: "支付管道",
   Please_enter_the_order_number_1: "請輸入訂單編號",
   Payment_time: "支付時間",
   Order_time: "訂單時間",
   Payment_amount_yuan: "支付金額（元）",
   Balance_inquiry: "餘額查詢",
   Ordinary_user_currency_balance: "普通用戶貨幣餘額",
   Details_1: "明細",
   Football_quiz_activity: "足球答題活動",
   Football_winning_statistics: "足球中獎統計",
   Basketball_quiz_activity: "籃球答題活動",
   Basketball_winning_statistics: "籃球中獎統計",
   Guessing_question_name: "競猜答題名稱",
   Please_enter_the_name_of_the_quiz_question: "請輸入競猜答題名稱",
   Start_time: "開始時間",
   Add_a_question: "新增答題",
   Rule_configuration: "規則配置",
   Style_Configuration: "樣式配置",
   Number_of_periods: "期數",
   Reward_base: "獎勵基數",
   Stop: "停止",
   End: "結束",
   Title: "題目",
   The_problem_name_cannot_be_empty: "問題名稱不能為空",
   Please_enter_the_question_name: "請輸入問題名稱",
   Please_enter_question_options: "請輸入問答題選項",
   question_options_1: "答題選項",
   Add_Options: "添加選項",
   Please_enter_the_number_of_currencies_for_the_reward_base:
     "請輸入獎勵基數的貨幣個數",
   Save: "保 存",
   Cancel: "取 消",
   Please_submit_results_with_caution: "請謹慎提交結果",
   The_answer_to_the_question_cannot_be_empty: "問題答案不能為空",
   Flow: "流局",
   Activity_Rule_Configuration: "活動規則配置",
   Rule_configuration_cannot_be_empty: "規則配置不能為空",
   Limited_to_200_words_in_length: "限長200個字",
   Guessing_Style_Configuration: "竟猜樣式配置",
   PC_Banner_diagram: "PC Banner圖",
   PC_Banner_image_cannot_be_empty: "PC Banner圖不能為空",
   hint_612: "尺寸：1920x624 只能上傳jpg/png檔",
   H5_APP_Banner_image: "H5/APP Banner圖",
   H5_APP_Banner_image_cannot_be_empty: "H5/APP Banner圖不能為空",
   hint_613: "尺寸：750x1012 只能上傳jpg/png檔",
   Theme_color: "主題色",
   The_theme_color_cannot_be_empty: "主題色不能為空",
   hint_619: "頁面底部的顏色配置",
   Event_details: "活動詳情",
   Correct_answer: "正確答案",
   Stopped: "已停止",
   Add_a_guessing_activity: "新增競猜活動",
   hint_618: "請填寫競猜答題名稱",
   Please_select_the_effective_time_range: "請選擇生效時間範圍",
   Please_fill_in_the_reward_base: "請填寫獎勵基數",
   hint_92: "取消後則不發佈該期答題活動，是否確定取消該期競猜答題活動?",
   Success: "成功",
   Cancel_successful: "取消成功",
   hint_93: "停止後用戶將無法參與競猜答題，是否確定停止競猜答題活動",
   hint_614: "請輸入1-99999正整數的獎勵基數",
   Cannot_select_past_time: "不可選擇過去時間",
   hint_615: "是否確定公佈答案？",
   Save_successful: "保存成功",
   hint_616: "題目{num} 的答案選項最少設置2個選項",
   Number_of_participants: "參與人數",
   Number_of_winners: "中獎人數",
   hint_617: "獎勵個數（金幣）",
   Question_Name: "問題名稱",
   Number_of_correct_answers: "答對人數",
   Activity_List: "活動列表",
   Top_of_the_event: "活動置頂",
   PC_end_carousel: "PC端輪播",
   AK_Activity_List: "AK-活動列表",
   AK_Activity_Topping: "AK-活動置頂",
   AK_PC_end_carousel: "AK-PC端輪播",
   Create_activity_records: "創建活動記錄",
   Event_main_title: "活動主標題",
   Activity_subtitle: "活動副標題",
   PC_thumbnail: "PC端縮略圖",
   App_thumbnail: "App縮略圖",
   Activity_time: "活動時間",
   Long_term_activities: "長期活動",
   Please_enter_the_main_title_of_the_activity: "請輸入活動主標題",
   Please_enter_the_activity_subtitle: "請輸入活動副標題",
   PC_activity_redirect_address: "PC活動跳轉地址",
   Activity_jump_button_background: "活動跳轉按鈕背景",
   App_side_thumbnail: "App端縮略圖",
   hint_901: "大小355*148px",
   App_activity_redirect_address: "App 活動跳轉地址",
   Time_type: "時間類型",
   There_is_a_time_limit: "有時間限制",
   Edit_activity_records: "編輯活動記錄",
   hint_629: "確定置頂此條活動記錄嗎？",
   hint_620: "確定取消置頂此條活動記錄嗎？",
   Please_set_the_activity_time: "請設置活動時間",
   Jump_URL: "跳轉URL",
   Queue: "佇列",
   Cover: "封面",
   Mobile_download_page: "移動端下載頁",
   PC_Download_Page: "PC端下載頁",
   Android_copywriting: "安卓文案",
   hint_94: "請輸入文案，最大支援10個字元",
   IOS_Web_App_Copywriting: "iOS-網頁版APP文案",
   IOS_copywriting: "iOS文案",
   PC_background_configuration: "PC背景配置",
   Group_stage_competition: "小組賽",
   "1_8_elimination_round": "1/8淘汰賽",
   "1_4_elimination_round": "1/4淘汰賽",
   Semi_finals: "半決賽",
   Finals: "決賽",
   Location: "地點",
   Facing_each_other: "對陣",
   hint_95: "請輸入主播房間號並用逗號隔開，最多四位",
   Fen: "分",
   hint_628: "最多只能選擇4個主播房間號",
   SM_redemption_page: "SM兌換頁面",
   AK_redemption_page: "AK兌換頁面",
   H5_end_thumbnail: "H5端縮略圖",
   hint_96: "只能上傳jpg/png/gif檔，尺寸1440*315",
   Category_ID: "類別ID",
   Classification_name: "分類名稱",
   Please_enter_the_category_name: "請輸入分類名稱",
   Thai_tag_name: "泰語標籤名稱",
   Please_enter_the_Thai_tag_name: "請輸入泰語標籤名稱",
   English_tag_name: "英語標籤名稱",
   Please_enter_the_English_tag_name: "請輸入英語標籤名稱",
   Vietnamese_label_name: "越南語標籤名稱",
   Please_enter_the_Vietnamese_label_name: "請輸入越南語標籤名稱",
   Korean_tag_name: "韓語標籤名稱",
   Please_enter_the_Korean_tag_name: "請輸入韓語標籤名稱",
   Brazilian_label_name: "巴西語標籤名稱",
   Please_enter_the_Brazilian_label_name: "請輸入巴西語標籤名稱",
   Add_a_new_category: "新增分類",
   hint_888: "精品好物",
   Please_select_the_status: "請選擇狀態",
   hint_627: "是否確定上線該商品？",
   hint_626: "是否確定下線該商品分類？",
   Warning: "警告",
   hint_97: "分類下含有所屬商品，不可下線",
   Edit_Classification: "編輯分類",
   Redemption_page_BANNER: "兌換頁BANNER",
   hint_625: "上傳圖片大小不能超過 5M!",
   Delisted_products: "下架商品",
   Listing_products: "上架商品",
   Classification_management: "分類管理",
   Label_management: "標籤管理",
   Order_List: "訂單清單",
   Product_ID: "商品ID",
   Please_enter_the_product_ID: "請輸入商品ID",
   Product_name: "商品名稱",
   Please_enter_the_product_name: "請輸入商品名稱",
   Do_we_need_delivery_: "是否需配送",
   Switch: "開關",
   Unit_price: "單價",
   Discounted_price: "折後價",
   Current_inventory: "當前庫存",
   Redeemed: "已兌換",
   Inventory_changes: "庫存變動",
   Select_thumbnail: "選擇縮略圖",
   Details_page_image: "詳情頁圖",
   Select_detailed_image: "選擇詳情圖",
   Details: "詳情說明",
   Please_enter_the_unit_price: "請輸入單價",
   Discounts: "打折價",
   Please_enter_the_discounted_price_of_the_product: "請輸入商品打折價",
   Please_select_a_category: "請選擇類別",
   Label: "標籤",
   Product_purchase_restriction_period: "商品限購週期",
   No_purchase_restrictions: "不限購",
   Day: "日",
   Week: "周",
   Cumulative: "累計",
   Product_purchase_limit: "商品限購數",
   hint_98: "請輸入週期內的商品限購數",
   Inventory: "庫存",
   Add: "增加",
   Reduce: "減少",
   The_product_name_cannot_be_empty: "商品名稱不可為空",
   Boutique: "精品",
   Life: "生活",
   Product_addition: "商品新增",
   Please_upload_thumbnail: "請上傳縮略圖",
   Please_upload_detailed_images: "請上傳詳情圖",
   Please_select_whether_delivery_is_required: "請選擇是否需配送",
   Purchase_unit_price_cannot_be_empty: "購買單價不可為空",
   Classification_cannot_be_empty: "分類不可為空",
   hint_99: "商品限購數不能為空",
   Details_cannot_be_empty: "詳情說明不能為空",
   Please_select_increase_decrease: "請選擇增加減少",
   Are_you_sure_you_want_to_save_: "是否確定保存？",
   Cat_coin_price: "貓幣價",
   Diamond_price: "鑽石價",
   Thai_product_name: "泰語商品名稱",
   Please_enter_the_Thai_product_name: "請輸入泰語商品名稱",
   English_product_name: "英語商品名稱",
   Please_enter_the_English_product_name: "請輸入英語商品名稱",
   Korean_product_name: "韓語商品名稱",
   Please_enter_the_Korean_product_name: "請輸入韓語商品名稱",
   Brazilian_product_name: "巴西語商品名稱",
   Please_enter_the_Brazilian_product_name: "請輸入巴西語商品名稱",
   hint_902: "尺寸建議174*174",
   hint_903: "尺寸建議375*375",
   Thai_language_details: "泰語詳情說明",
   English_detailed_explanation: "英語詳情說明",
   Vietnamese_language_details: "越南語詳情說明",
   Korean_language_details: "韓語詳情說明",
   Brazilian_language_details: "巴西語詳情說明",
   Please_enter_the_diamond_price_of_the_product: "請輸入商品鑽石價",
   Are_you_sure_you_want_to_delist_this_product_: "是否確定下架該商品？",
   Label_ID: "標籤ID",
   Please_enter_the_label_name: "請輸入標籤名稱",
   Label_Name: "標籤名稱",
   hint_624: "是否確定上線該商品標籤？",
   hint_101: "是否確定下線該商品標籤？",
   Edit_label: "編輯標籤",
   Order_number: "訂單號",
   Please_enter_the_order_number: "請輸入訂單號",
   Transaction_time: "交易時間",
   Receiving_address: "收貨地址",
   Signee: "簽收人",
   Transaction_volume: "交易量",
   Total_transaction_amount: "交易總額",
   Transaction_status: "交易狀態",
   Unsuccessful: "不成功",
   Limited_to_50_words_in_length: "限長50個字",
   Inviter_UID: "邀請人UID",
   Inviting_person_nickname: "邀請人昵稱",
   Invitee_UID: "被邀請人UID",
   Invitee_registration_time: "被邀請人註冊時間",
   Please_enter_the_inviter_reward: "請輸入邀請人獎勵",
   Please_enter_the_invitee_reward: "請輸入被邀請人獎勵",
   Rule_Description: "規則說明",
   APP_landing_page_background_image: "APP落地頁背景圖",
   hint_621: "尺寸：710X1086 只能上傳jpg/png檔",
   Watch_the_live_broadcast: "觀看直播",
   Live_room_speech: "直播間發言",
   Push_order_configuration: "推單配置",
   hint_102:
     "友情提示：1-發言配置請按照順序依次進行配置。如配置到第2句，則僅會執行1，2發言配置內容。",
   hint_905: "第{num}次發言配置",
   Reward_amount: "獎勵金額",
   Please_enter_the_speech_reward_amount: "請輸入發言獎勵金額",
   Valid_characters: "有效字元",
   hint_623: "請輸入發言有效字元",
   hint_103: "第一次發言配置獎勵金額不能為空",
   hint_104: "第二次發言配置獎勵金額不能為空",
   hint_105: "第三次發言配置獎勵金額不能為空",
   Friendly_reminder: "友情提示",
   hint_1: "1-推單數據及使用者參與資料可前往 直播管理》直播間推單 模組查看；",
   hint_2: "2-推單獎勵區間：主播推單的獎勵會隨機在該區間生成；",
   hint_3: "3-用戶每日獎勵峰值：超過該峰值則繼續參與推單，不可獲得獎勵；",
   hint_889: "推單獎勵區間",
   Maximum_reward: "最大獎勵",
   Minimum_reward: "最小獎勵",
   Users_get_peak_daily: "用戶每日獲得峰值",
   hint_622: "請輸入用戶每日最大獲取值",
   Please_enter_the_maximum_reward: "請輸入最大獎勵",
   Please_enter_the_minimum_reward: "請輸入最小獎勵",
   Please_enter_the_user_daily_peak_gain: "請輸入用戶每日獲得峰值",
   Viewing_duration: "觀看時長",
   hint_107: "請輸入觀看時長，需為正整數",
   Watch_rewards: "觀看獎勵",
   Please_enter_the_viewing_reward: "請輸入觀看獎勵",
   Reward_channels: "獎勵管道",
   hint_106: "開通後在該管道觀看直播會獲得獎勵，不同管道觀看僅獎勵一次；",
   Please_enter_the_viewing_duration: "請輸入觀看時長",
   Please_select_a_reward_channel: "請選擇獎勵管道",
   First_line_copy: "第一行文案",
   hint_108: "請輸入文案，最大支援10字元",
   Second_line_copy: "第二行文案",
   Please_enter_the_first_line_of_text: "請輸入第一行文案",
   Please_enter_the_second_line_of_text: "請輸入第二行文案",
   Effective_date: "有效日期",
   Display_copy: "展示文案",
   Gift_channels: "贈送管道",
   Login_rewards: "登陸獎勵",
   Accumulated_Gifts: "累計贈送",
   Additional_rewards_for_app_login: "APP登陸額外獎勵",
   hint_109: "請輸入登陸獎勵，為0則不獎勵",
   Please_enter_the_reward_amount: "請輸入獎勵金額",
   Login_Activity_Rewards: "登錄活動獎勵",
   Please_enter_display_copy: "請輸入展示文案",
   Please_enter_the_login_reward: "請輸入登陸獎勵",
   Please_enter_a_valid_date: "請輸入有效日期",
   Please_enter_to_select_the_gift_channel: "請輸入選擇贈送管道",
   Login_reward_activity: "登陸獎勵活動",
   Download_copy_configuration: "下載文案配置",
   Lottery_time: "抽獎時間",
   Activity_ID: "活動id",
   Accessing_Users: "訪問用戶",
   Participating_users: "參與用戶",
   Data_details: "數據明細",
   Channel_sources: "管道來源",
   Click_on_the_number_of_lucky_draws: "點擊抽獎次數",
   Page_visits: "頁面訪問次數",
   Number_of_times_participating_in_the_lottery: "參與抽獎次數",
   Winning_users: "中獎用戶",
   Users_who_win_physical_prizes: "實物中獎用戶",
   Click_to_go_to_the_assistant_user: "點擊去小助手用戶",
   Click_to_download_app_users: "點擊下載APP用戶",
   Award_pool_type: "獎池類型",
   Lucky_Wheel: "幸運轉盤",
   Super_Wheel: "超級轉盤",
   Lottery_Record_Daily_Turntable: "抽獎記錄-日常轉盤",
   Physical_rewards: "實物獎勵",
   Draw_lottery_tickets: "抽獎券",
   Processing_personnel: "處理人",
   Not_issued: "未發放",
   Issued: "已發放",
   Virtual: "虛擬",
   Operation_turntable: "運營轉盤",
   Activity_Management: "活動管理",
   Lottery_record: "抽獎紀錄",
   Operation_turntable_activity_data: "運營轉盤活動資料",
   Activity_type: "活動類型",
   Activity_carousel: "活動轉盤",
   Daily_turntable: "日常轉盤",
   Activity_statistics: "活動統計",
   Number_of_super_lottery_tickets_distributed: "超級抽獎券發放數量",
   Lucky_Wheel_Lucky_Draw_Times: "幸運轉盤抽獎次數",
   Super_Wheel_Lottery_Times: "超級轉盤抽獎次數",
   Basic_information: "基本資訊",
   Activity_Rules: "活動規則",
   Please_enter_activity_rules: "請輸入活動規則",
   PC_banner_image: "pc banner圖",
   H5_Jump_Page: "h5跳越網頁面",
   In_use: "使用中",
   Total_inventory: "總庫存",
   Prizes: "獎品",
   Single_day_upper_limit: "單日上限",
   Individual_users_can_receive_upper_limits: "單個用戶獲得上限",
   Lucky_draw_probability_in_tens_of_thousands_: "抽獎概率(萬分）",
   Is_it_a_running_horse_lamp_: "是否跑馬燈",
   Is_it_a_bottom_line: "是否兜底",
   Add_prize_pool: "添加獎池",
   Cloning: "克隆",
   Single_consumption: "單次消耗",
   Daily_limit: "每日上限",
   Activity_channels: "活動管道",
   H5_banner_diagram: "h5 banner圖",
   How_to_earn_cat_coins: "如何賺貓幣",
   Please_select_the_activity_time_range: "請選擇活動時間範圍",
   Edit_anchor_recommendation_group: "編輯主播推薦組",
   Add_a_new_anchor_recommendation_group: "新增主播推薦組",
   Please_upload_the_PC_banner_image: "請上傳pc banner圖",
   Please_upload_the_h5_banner_image: "請上傳h5 banner圖",
   Please_enter_PC_Jump_Page: "請輸入pc跳越網頁面",
   Please_enter_h5_to_jump_to_the_page: "請輸入h5跳越網頁面",
   Please_enter_a_single_consumption: "請輸入單次消耗",
   Please_enter_the_daily_limit: "請輸入每日上限",
   Please_select_the_activity_channel: "請選擇活動管道",
   Prize_Pool: "獎池",
   hint_110: "獎池開啟時間不可早于活動開啟時間",
   hint_111: "獎池結束時間不可晚於活動結束時間",
   The_total_probability_does_not_equal_10000: "概率總和不等於10000",
   Sum_of_probabilities: "概率總和",
   Please_select_the_prize_for_prize_: "請選擇獎品{num}的獎品",
   Please_enter_the_total_inventory_of_prize_: "請輸入獎品{num}的總庫存",
   _The_daily_limit_is_greater_than_the_total_inventory:
     "{num}單日上限大於總庫存",
   hint_112: "請輸入獎品{num}的抽獎概率(萬分）",
   hint_115: "請選擇獎品{num}的是否跑馬燈",
   hint_116: "每個獎池必須選擇一個兜底獎品!",
   hint_114: "請填寫獎池的生效時間",
   hint_113: "是否確認刪除該獎池?",
   Data_Overview: "數據概覽",
   Quantity_of_currency: "貨幣數量",
   Please_enter_the_quantity_of_currency: "請輸入貨幣數量",
   Number_of_lottery_tickets: "獎券數量",
   Please_enter_the_number_of_lottery_tickets: "請輸入獎券數量",
   Select_icon: "選擇icon",
   Please_select_the_type_of_prize: "請選擇獎品類型",
   Please_upload_the_icon: "請上傳icon",
   Editing_prizes: "編輯獎品",
   Please_enter_a_positive_integer: "請輸入正整數",
   single_1: "個",
   submit_results_1: "提交結果",
   rules_options: "規則配置",
   rules_options_1: "規則配置不能為空",
   edit_activity_cai: "編輯競猜活動",
   created_topic_1: "創建話題",
   created_topic_2: "編輯話題",
   briefing_topic_1: "話題簡介",
   briefing_topic_2: "話題縮略圖",
   hint_899: "只能上傳jpg/png檔,限制1M,大小790*215px",
   hint_907: "只能上傳jpg/png檔,限制1M,大小150*58px",
   hint_917: "只能上傳jpg/png檔,限制1M,大小355*148px",
   hint_918: "圖片尺寸建議為256x256,只能上傳jpg/png檔",
   lookDetail: "查看",
   text_1: "是否確定下架？",
   text_2: "是否確定上架？",
   matchPlan_1: "比賽進度",
   group_1: "小組",
   shopEdit: "商品編輯",
   viShopEditName: "越南語商品名稱",
   viShopEditName1: "請輸入越南語商品名稱",
   hint_1001: "購買貓幣不可為空",
   hint_1002: "購買鑽石不可為空",
   hint_1003: "請輸入貓幣",
   remark_edit: "備註編輯",
   currency_cat: "貓幣",
   diamond_1: "鑽石",
   Invitee_UID_1: "邀請人UID",
   Invitee_edit_1: "邀請配置",
   minute_1: "分鐘",
   establish_1: "創建",
   hint_1005: "足球中獎統計資料",
   hint_1006: "籃球中獎統計資料",
   hint_1007: "中獎詳情",
   hint_1008: "始",
   hint_1009: "止",
   hint_1011: "未填寫",
   hint_1012: "選擇banner圖",
   hint_1013: "pc跳越網頁面",
   hint_1014: "使用",
   hint_1015: "獎品ID",
   hint_1016: "抽獎券",
   hint_1017: "連結",
   hint_1018: "資訊ID",
   hint_1019: "上傳音樂縮略圖",
   hint_1021: "英文字母等級限制",
   hint_1022: "直播間推單自動彈出",
   advertising_title: "廣告標題",
   Author_name_1: "作者",
   text_7: "用戶私信主播",
   text_8: "主播私信用戶",
   text_9: "用戶私信管理員",
   text_10: "私聊主播等級",
   text_11: "下載頁頂部Logo",
   text_12: "APP/H5專輯頁狀態",
   text_13: "IOS三倍圖",
   text_14: "第一張",
   text_15: "第二張",
   text_16: "第三張",
   text_17: "第四張",
   text_18: "第${num}張內容不能為空",
   text_19: "第${num}張圖片不能為空",
   text_20: "圖片尺寸建議為40x40,只能上傳jpg/png/gif檔",
   text_21: "圖片尺寸建議為40x40,只能上傳jpg/png/gif檔",
   text_22: "PC專輯頁名稱",
   text_23: "請輸入src對應的url,如需統計多個功能變數名稱，請用||區分",
   text_24: "關於頁面文案配置",
   text_25: "請填寫文案內容",
   text_26: "請輸入開發者帳號",
   text_27: "新手引導頁",
   text_28: "不用更新",
   text_29: "可選更新",
   text_30: "強制更新",
   text_31: "公開金鑰",
   text_32: "私密金鑰",
   text_33: "功能說明",
   text_34: "Android配置",
   text_35: "直播間發送紅包",
   text_36: "直播間送禮",
   text_37: "啟動APP埋點上報",
   text_38: "功能變數名稱類型",
   text_39: "編輯功能變數名稱",
   text_40: "添加功能變數名稱",
   have_1: "有",
   have_2: "無",
   updateTime_1: "更新時間",
   text_41: "確定要刪除該功能變數名稱嗎？",
   text_42: "解禁後則該詞可繼續在平臺發送,確認解禁嗎？",
   text_43: "直播間banner url",
   text_44: "H5banner",
   text_45: "請輸入description",
   text_46: "請輸入首頁 title",
   text_47: "請輸入直播頁 title",
   text_48: "只能上傳jpg/jpeg/png檔,最多支援上傳9張",
   text_49: "上傳視頻檔",
   text_50: "請輸入圈子id",
   text_51: "支援格式：JPG、PNG、GIF、MP4，大小：3M以內:",
   text_52: "未發佈",
   text_53: "請輸入簡介",
   text_54: "只能上傳jpg/png/gif檔,限制1M,大小790*215px",
   text_55: "設置人數上限",
   text_56: "投稿時間",
   text_57: "請設置人數上限",
   text_58: "人數上限不得小於50人",
   text_59: "真實姓名",
   text_60: "已同意",
   text_61: "創建圈子數",
   text_62: "圈子用戶數",
   text_63: "加入時間",
   text_64: "身份證號碼：",
   text_65: "請輸入手機號碼",
   text_66: "提交人",
   text_67: "體育類",
   text_68: "娛樂類",
   text_69: "其他",
   text_70: "只能上傳jpg/png檔，圖片尺寸需為120X120",
   text_71: "添加頻道",
   text_72: "全部頻道",
   channel_1: "頻道",
   text_73: "指定房間",
   text_74: "上線成功",
   text_75: "位置",
   text_76: "PC背景圖",
   text_77: "PC標題圖",
   text_78: "玩法說明",
   text_79: "觀看直播時長(分鐘)",
   text_80: "概率",
   text_81: "0為不不可能抽中紅包，此為用戶抽中有獎紅包的概率",
   text_82: "簽到時間",
   text_84: "獎勵狀態",
   text_85: "派發獎勵",
   text_86: "確定派發獎勵嗎？",
   text_87: "簽到類型",
   text_88: "發送人userid",
   text_89: "請輸入發送人userid",
   text_90: "紅包ID",
   text_91: "發送人昵稱",
   text_92: "發起房間號",
   text_93: "請輸入發送房間號",
   text_94: "紅包檔位",
   text_95: "領取個數",
   text_96: "失效個數",
   text_97: "發送時間",
   text_98: "已啟用",
   text_99: "主題ID",
   text_101: "輸贏",
   text_102: "紅包雨ID",
   text_103: "紅包總數",
   text_104: "生效",
   text_105: "失效",
   text_106: "綁定賽事/主播",
   text_107: "已領紅包個數",
   text_108: "領取人數",
   text_110: "活動",
   text_111: "領取",
   text_112: "房間",
   text_113: "平臺累計分成",
   text_114: "登出時間",
   text_115: "註冊IP",
   text_116: "線上時長",
   text_117: "派發狀態：",
   text_118: "更改時間",
   text_119: "場次ID",
   text_120: "直播比賽ID",
   text_121: "玩法",
   text_122: "推單內容",
   text_123: "賽段",
   text_124: "支持",
   text_125: "主播結果",
   text_126: "反對",
   text_127: "修正結果",
   text_128: "獎勵數額",
   text_129: "總發放",
   text_130: "舉報人",
   text_131: "描述",
   text_132: "回復",
   text_133: "APP背景",
   // 新增5
   live_time: "直播時長(分鐘)",
   Please_select_the_results: "請選擇本單的結果",
   red_1: "紅",
   black_1: "黑",
   go_1: "走",
   corner: "角球",
   win_or_lose: "讓分勝負",
   Size_division: "大小分",
   take_care_1: "注：尺寸為572x420",
   take_care_2: "注：尺寸為926x126",
   discount_1: "折扣",
   discount_name_1: "折扣名稱",
   text_135: "請輸入頻道昵稱",
   characters_1: "文字",
   picture_1: "圖片",
   Please_fill_in_the_link_1: "請填寫連結",
   Please_fill_in_the_link_2: "請正確填寫連結",
   publish_this_announcement: "是否確定發佈該公告？",
   send_state: "發送狀態",
   Create_marketing_messages: "創建行銷短信",
   SMS_content: "短信內容",
   View_receiving_users: "查看接收用戶",
   Upload_list: "上傳名單",
   Are_you_sure_to_cancel: "是否確認取消",
   User_Import_Template: "使用者導入範本",
   channel_2: "管道",
   autograph: "簽名",
   SMS_type: "短信類型",
   SMS_template_editing: "短信範本編輯",
   SMS_template_add: "短信範本新增",
   Editing_time: "編輯時間",
   Instant_push: "即時推送",
   Timed_push: "定時推送",
   Page_H5: "H5頁",
   Menu_Type: "菜單類型",
   Role_authorization: "角色授權",
   name_111: "姓名",
   role_111: "角色",
   Modifying_Merchant_Basic_Information: "修改商戶基本資訊",
   Gift_ID: "禮物ID",
   Number_of_deliveries: "送出個數",
   Delivery_value_1: "送出價值(金幣/萬個)",
   broadcast_room_1: "送出直播間",
   broadcast_room_2: "請輸入送出直播間",
   broadcast_room_3: "送出價值",
   Gift_giving_time: "送禮時間",
   Online_1: "已上線",
   Gift_unit_price: "禮物單價",
   hint_1110: "只能上傳jpg/png檔,大小不超過1MB！",
   hint_1112: "預覽效果",
   hint_1113: " 只能上傳gif檔,大小不超過1MB！",
   hint_1114: "播放效果",
   hint_1115: "只能上傳svga檔,大小不超過10MB！",
   Full_platform: "全平臺",
   This_room: "本房間",
   hint_1116: "只能上傳只能上傳jpg/png檔!",
   Type_of_issuance: "下發類型",
   Designated_category: "指定品類",
   hint_1117: "請輸入指定房間號，多個房間之間使用英文的逗號隔開",
   hint_1118: "請輸入禮物ID",
   hint_1119: "最多可輸入99個禮物ID",
   hint_1120: "不可輸入空格",
   hint_1121: "不可輸入中文逗號",
   hint_1122: "只可輸入中文、英文、數位",
   hint_1123: "請選擇下發類型",
   hint_1124: "請選擇下發範圍",
   hint_1125: "請選擇品類",
   hint_1126: "請指定房間",
   Publishable_status: "可發佈狀態",
   Expert_nickname: "專家昵稱",
   Expert_brief: "專家簡介",
   Expert_detail_1: "錦囊詳情",
   article_1:
     "亞眠15輪比賽積分13分，排名第18位，居降級附加區；上輪比賽客場完敗,，遭遇兩連敗,，狀態低迷；而他們主場3勝4負，排名第12位，雖然表現不出色，但還是依靠主場搶分；陣容方面，球隊有1名中場傷停，2名於球員出戰存疑。客隊上賽季排名法甲第二，本賽季下滑嚴重，目前15輪比賽積分10分，排名第19位，居降級區內；上輪比賽客場告負，球隊同樣遭遇兩連敗，狀態較差；本賽季客場2勝1平4負，排名第13位，雖然表現一般，但所獲勝利全為客場所得，有一定客戰能力；陣容方面，球隊飽受傷病領導困擾，目前有多達14名球員傷停。亞洲初始指數為本場比賽開出平手盤和主受平半盤，給予客隊謹慎支持；最新指數中部分機構多調整為平手盤，機構對客隊信心有所減弱；歐賠最新平均指數壓低勝賠，多機構同時壓低平賠，抬升負賠，不利客隊。",
   user_1111: "用戶",
   Import_1111: "導入",
   hint_1127: "已經達到添加上限了",
   Closed_111: "已關閉",
   Opened_111: "已開啟",
   Select_111: "選擇",
   less_than: "小於",
   Publisher_11: "發佈者",
   Let_the_ball: "讓球",
   analysis: "解析",
   Please_enter_the_parsing_content: "請輸入解析內容",
   price_11: "售價",
   free_11: "免費",
   release_1: "發佈",
   hint_1200: "標題需要4字以上",
   hint_1201: "推薦理由需要20字以上",
   Ball_selection: "球類選擇",
   Top_failure_time: "置頂失效時間",
   refund: "退款",
   Topping_time: "置頂時間",
   System_messages: "系統消息",
   Please_enter_the_message_content: "請輸入消息內容",
   abnormal: "異常",
   Let_the_ball_win: "讓球勝平負",
   Please_set_the_time: "請設置時間",
   Please_message_content: "請填寫消息內容",
   hint_20001: "置頂時間不能早於當前時間",
   hint_20002: "確定取消置頂嗎？",
   hint_20003: "建倉時間",
   Purchase_records: "購買記錄",
   // 新增8
   Edit_Resource_Bits: "編輯資源位",
   ADD_Resource_Bits: "新增資源位",
   hint_20091: "上移",
   hint_20092: "下移",
   hint_20093: "新增熱門",
   hint_20094: "視頻",
   hint_20095: "頁面",
   hint_20096: "添加背景",
   hint_20097: "確定要下架此背景圖嗎？",
   hint_20098: "只能上傳jpg/jpeg/png檔,寬高比 60*60",
   //新增9
   hint_20111: "請選擇彈幕庫",
   hint_20112: "請輸入下發類型",
   hint_20113: "請輸入下發範圍",
   hint_20114: "確定要{status}此配置嗎？",
   hint_20115: "已{status}",
   hint_20116: "彈幕內容不能為空",
   hint_20117: "單次最多添加1000條。",
   hint_20118: "添加時間",
   hint_20119: "開啟後，該主播直播間將重新進入機器人，確定開啟嗎？",
   hint_20121: "主播類型",
   hint_20122: "請輸入身份證",
   hint_20123: "房管",
   hint_20124: "請輸入uid",
   hint_20125: "用戶userId",
   hint_20126: "請輸入用戶userId",
   hint_20127: "請輸入直播標題",
   hint_20128: "帳號合併",
   hint_20129: "消息範圍",
   hint_20130: "本條消息",
   hint_20888: "本直播間內消息",
   hint_20131: "所有直播間消息",
   hint_20132: "刪除原因",
   hint_20133: "用戶禁言",
   hint_20134: "禁言用戶",
   hint_20135: "UID不能為空",
   hint_20136: "禁言週期不能為空",
   hint_20137: "禁言原因不能為空",
   hint_20138: "房間號不能為空",
   hint_20139: "請輸入主播userid",
   hint_20400: "發起審核",
   hint_20401: "審核失敗",
   hint_20402: "匯出excel",
   hint_20403: "結算週期",
   hint_20404: "調整金額：",
   hint_20405: "元",
   hint_20406: "未發起",
   hint_20407: "結算帳單",
   hint_20408: "編輯前",
   hint_20410: "編輯後",
   hint_20411: "待提審",
   hint_20412: "添加公會",
   hint_20413: "請輸入收款帳戶名稱",
   hint_20414: "請輸入開戶行",
   hint_20415: "請輸入銀行卡號",
   hint_20416: "請輸入開戶位址",
   hint_20417: "請輸入100字以內",
   hint_20418: "編輯公會",
   hint_20419: "停用後將不可為主播分配該公會，是否確定停用此公會？",
   hint_20420: "只能上傳jpg/png檔，建議尺寸為",
   hint_20421: "配置帳號",
   hint_20422: "查找內容",
   hint_20423: "替換內容",
   hint_20424: "信號源跳轉位址",
   hint_20425: "請輸入信號源位址",
   hint_20426: "請輸入信號源跳轉位址",
   hint_20427: "請填寫ios Scheme",
   hint_20428: "請填寫關於內容",
   hint_20429: "介面版本號",
   hint_20430: "配置人",
   hint_20431: "未選擇任何短視頻，請選擇短視頻後再發佈！",
   hint_20432: "發佈成功",
   hint_20433: "發佈成功，此條短視頻進入發佈庫",
   hint_20434: "爬取時間段",
   hint_20435: "請選擇時間段",
   hint_20436: "已禁用",
   hint_20437: "設置成功",
   hint_20438: "是否置頂",
   hint_20439: "排序位置",
   hint_20440: "評論",
   hint_20441: "短視頻封面",
   hint_20442: "編輯置頂",
   hint_20443: "請輸入排序位置",
   hint_20444: "發佈後無法進行修改，是否確認發佈？",
   hint_20445: "訪問管道",
   hint_20446: "來源功能變數名稱",
   hint_20447: "小助手UV",
   notice_111: "公告UV",
   index_111: "指數",
   test_111: "確定要{status}該話題嗎？",
   test_112: "發佈話題",
   test_113: "確定要刪除嗎？",
   Batch_approval: "批量審批",
   Reviewer_111: "審核人",
   you_want_to_pass: "確定要通過嗎？",
   Download_failed: "下載失敗",
   Individual_currency: "個人貨幣批量增發範本",
   pass_all_of_them: "確認要全部通過嗎？",
   Fill_in_at_least: "增發貨幣、增發經驗值至少填寫其中一項",
   quantity_of_additional_currency: "請輸入增發貨幣數量",
   money_111: "金額",
   Input_cannot_be_greater: "輸入不能大於99999或小於1",
   Recharge_status: "充值狀態",
   Gold_coins: "金幣",
   Alipay_payment: "支付寶支付",
   WeChat_payment: "微信支付",
   ApplePay: "蘋果支付",
   Unpaid_111: "未支付",
   Paid_111: "已支付",
   Balance_ranking: "餘額排行",
   ranking_111: "排名",
   Member_Change_Record: "會員變更記錄",
   phone_area_code: "請設置手機區號",
   your_phone_number: "請設置手機號",
   Successfully_lifted: "解除禁言成功",
   Risk_categories: "風險類別",
   No_type_selected: "未選擇類型",
   hint2222: "移除許可權後，該主播直播間將不再進入機器人，確定移除嗎",
   hint2223: "禁用後該活動不在策略中的紅包雨庫中展示，是否確定禁用此活動？",
   hint2224: "啟用後該活動僅將在策略中的紅包雨庫中展示，是否確定啟用此活動？",
   Uploader: "上傳人",
   hint2225: "不能大於99999",
   hint2226: "金蛋1",
   hint2227: "15分鐘",
   member_limit: "會員限定",
   member_limit_info: "請選擇會員限定",
   hint2228: "競足推薦",
   hint2229: "內容分配",
   hint2230: "商戶名稱",
   hint2231: "是否分配錦囊",
   hint2232: "分配內容形式",
   hint2233: "是否代運營內容",
   hint2234: "是否分配全部內容",
   hint2235: "原創內容分配範圍",
   hint2236: "是否分配無標籤內容",
   hint2237: "是否分配全部有標籤內容",
   hint2238: "一級標籤分配範圍",
   hint2239: "當前標籤",
   hint2240: "標籤庫",
   hint2241: "當前欄全選",
   hint2242: "全部內容形式",
   hint2243: "不分配內容",
   hint2244: "僅圖文",
   hint2245: "僅視頻",
   hint2246: "全部原創",
   hint2247: "不分配原創內容",
   hint2248: "圖文，視頻",
   hint2249: "是否分配全部無標籤內容",
   hint2250: "是否分配全部有標籤內容",
   hint2251: "一級標籤",
   hint2252: "請輸入標籤搜索",
   hint2253: "內容形式",
   hint2254: "圖文",
   hint2255: "視頻",
   hint2256: "標籤編輯",
   hint2257: "二級標籤",
 
   hint2258: "創建標籤",
   hint2259: "編輯標籤",
   hint2260: "標籤級別",
   hint2261: "一級",
   hint2262: "二級",
   hint2263: "對應一級標籤",
   hint2264: "展開",
   hint2265: "標籤狀態",
   hint2266: "批量代發佈",
   hint2267: "批量代下架",
   hint2268: "標題關鍵字",
   hint2269: "獲取時間",
   hint2270: "爬取",
   hint2271: "賽事中心",
   hint2272: "原創",
   hint2273: "源發佈時間",
   hint2274: "搜索有標籤內容",
   hint2275: "代發佈狀態",
   hint2276: "發佈狀態變更時間",
   hint2277: "請輸入標題關鍵字",
   hint2278: "審核退回",
   hint2279: "請輸入一級標籤搜索",
   hint2281: "請輸入二級標籤搜索",
   hint2282: "是否確認要退回該條內容資料？",
   hint2283:
     "退回後，商戶將暫時無法查詢到本條圖文內容，可在圖文採集庫再次審核通過。",
   hint2284: "是否確認要代商戶發佈本組內容資料？",
   hint2285: "是否確認要代商戶下架本條內容資料？",
   hint2286: "退回",
   hint2287: "發佈",
   hint2288: "下架",
   hint2289: "新增原創",
   hint2299: "請選擇分類",
   hint2290: "審核時間",
   hint2291: "內容編輯",
   hint2292: "視頻編輯",
   hint2293: "前瞻",
   hint2294: "精華",
   hint2295: "娛樂",
   hint2296: "上傳視頻",
   hint2297: "格式限制為mp4或flv檔，檔大小以小為佳，限傳一個",
   original_content_img: "原內容中的圖片",
   Custom_Image: "自訂圖片",
   insert_the_cover: "請插入封面圖",
   Please_improve_the_title: "請完善標題",
   upload_the_video: "請上傳視頻",
   upload_the_cover_image: "請上傳封面圖",
   result_for_this_content: "請選擇本條內容的審核結果",
   to_the_distribution_repository: "審核通過的，該條內容資料才會提交至分發庫",
   reviewed_again: "審核拒絕的，可再次進行審核",
   Set_crawling_time: "設置爬取時間",
   What_crawling_task: "用該爬取任務？",
   Is_it_true: "是否",
   Please_refine_the_author: "請完善作者",
 
 
   // 小助手
   Edit: "編輯",
   User_Configuration: "使用者配置",
   Please_enter_a_numerical_level: "請輸入數位等級",
   hint_4: "低於該等級不允許設置包含英文字母昵稱",
   Nickname_English_letter_level_limit: "昵稱英文字母等級限制",
   Live_broadcast_room_configuration: "直播間配置",
   hint_6: "低於該等級則不允許直播間聊天",
   hint_5: "低於該等級直播間聊天英文字母被替換*",
   Open: "開啟",
   Close: "關閉",
 
 
   Circle_Speech_Configuration: "圈子發言配置",
   hint_7: "低於該等級則不允許圈子內私信主播",
   Speech_level_limit: "發言等級限制",
   hint_8_: "低於該等級則不允許圈子內聊天發言",
   Posting_updates_submission_levels: "發佈動態/投稿等級",
   hint_9: "低於該等級則不允許發佈動態/投稿",
   hint_911: "發佈動態/投稿",
   Cancel_1: "取消",
   Save_1: "保存",
   APP_bottom_label: "APP底部標籤",
   Initial_icon: "初始icon",
   Activity_icon: "活動icon",
   World_Cup_icon: "世界盃icon",
   The_bottom_marketing_position_of_the_APP: "APP底部行銷位",
   Please_enter_the_redirect_address: "請輸入跳轉位址",
   Event_start_time: "活動開始時間",
   APP_homepage_my_background: "APP首頁、我的背景",
   hint_10: "尺寸： 1125*624，只能上傳jpg/png檔",
   Operation: "操作",
   Upload: "上傳",
   Ordinary_user_currency_icon: "普通用戶貨幣icon",
   Size_1: "尺寸",
   Type: "類型",
   Preview_image: "預覽圖",
   Live_settlement_currency_icon: "直播結算貨幣icon",
   Brocade_Bag_Settlement_Currency_icon: "錦囊結算貨幣icon",
   hint_11: "支援格式：PNG，大小：500K以內",
   Android_Triple_Image: "Android三倍圖",
   Top_of_homepage_top_of_activity_page: "首頁頂部、活動頁頂部",
   At_the_top_of_the_competition_page_my: "賽事頁頂部、我的",
   Signal_source_top: "信號源頂部",
   Please_select: "請選擇",
   Please_upload: "請上傳",
   Operation_successful: "操作成功",
   Can_only_upload_can_only_upload_PNG_files: "只能上傳只能上傳png檔",
   hint_800: "上傳大小不能超過 500K!",
   Login_address_configuration: "登錄位址配置",
   Please_enter_your_login_address: "請輸入登錄位址",
   hint_12: "此配置僅針對推流端使用商戶側使用者系統生效，配置後，推流端支援識別商戶側自有用戶名及密碼",
   Recommended_by_the_editorial_circle: "編輯圈子推薦",
   Please_enter_the_resource_name: "請輸入資源名稱",
   Circle_List_Recommendation: "圈子列表推薦",
   Recommended_by_Square_Circle: "廣場圈子推薦",
   Please_enter_userid: "請輸入userid",
   Please_enter_the_IP_address: "請輸入IP",
   Search: "搜索",
   Refresh_IP_blacklist_cache: "刷新IP黑名單緩存",
   Reset: "重置",
   Loading: "載入中...",
   Merchant_ID_1: "商戶編號",
   Prohibition_time_1: "封禁時間",
   Remove: "移除",
   hint_743: "確定要移除此IP嗎？",
   APP_brand_name: "APP品牌名稱",
   Default_username_prefix: "預設使用者名首碼",
   Common_user_currency_name: "普通用戶貨幣名稱",
   Live_settlement_currency_name: "直播結算貨幣名稱",
   The_name_of_the_settlement_currency_for_the_brocade_bag: "錦囊結算貨幣名稱",
   Contact_customer_service: "聯繫客服",
   Assistant_default_icon: "小助手預設圖示",
   Click_to_select_resources: "點擊選取資源",
   hint_13: "只能上傳svga檔，圖片大小不能超過800k",
   Assistant_unread_message_icon: "小助手未讀消息圖示",
   Assistant_displays_icon_size: "小助手顯示圖示尺寸",
   Small: "小",
   Medium: "中",
   Big: "大",
   Assistant_display_time: "小助手展示時間",
   Online_customer_service_address: "線上客服地址",
   Initialize_account: "初始化帳號",
   Anchor_tool_download_address: "主播工具下載地址",
   ICP_Filing_Information: "ICP備案資訊",
   Live_broadcast_room_default_message: "直播間預設消息",
   Conversation_background: "語聊背景",
   Default_official_anchor_profile_picture: "默認官方主播頭像",
   Default_official_message_avatar: "預設官方消息頭像",
   Login_registration_page_background: "登錄註冊頁面背景",
   hint_14: "支援格式：JPG、PNG、GIF、MP4，大小：3M以內",
   Delete: "刪除",
   Triple_plot: "三倍圖",
   Double_plot: "二倍圖",
   Please_fill_in_the_brand_name: "請填寫品牌名稱",
   Please_fill_in_the_prefix: "請填寫首碼",
   Please_fill_in_the_currency_name: "請填寫貨幣名稱",
   Please_fill_in_customer_service_WeChat: "請填寫客服微信",
   Please_fill_in_the_download_address_for_the_anchor_tool: "請填寫主播工具下載地址",
   Please_fill_in_the_ICP_information: "請填寫ICP資訊",
   Please_fill_in_the_default_message_for_the_live_broadcast_room: "請填寫直播間預設消息",
   Please_fill_in_the_assistant_display_time: "請填寫小助手展示時間",
   hint_15: "上傳尺寸有誤，請按格式要求上傳!",
   hint_16: "只能上傳只能上傳png檔!",
   The_upload_size_cannot_exceed: "上傳大小不能超過",
   hint_17: "傳大小不能超過 800K!",
   Select: "選取",
   Please_upload_the_edited_Excel_template_file: "請上傳編輯好的excel範本檔",
   Confirm: "確認",
   Importing: "導入中",
   Please_upload_the_file: "請上傳文件",
   Only_xlsx_format_files_can_be_uploaded: "只能上傳.xlsx格式檔!",
   hint_742: "上傳圖片大小不能超過 500MB!",
   Successfully_added: "添加成功",
   Import_member_failure_list: "導入會員失敗名單",
   OK: "確定",
   Select_file: "選擇檔",
   Please_enter_the_ID: "請輸入id",
   User_nickname: "用戶昵稱",
   hint_740: "請輸入用戶昵稱",
   User_phone_number: "用戶手機號",
   hint_739: "請輸入用戶手機號",
   User_level: "使用者等級",
   Please_enter_user_level: "請輸入使用者等級",
   Membership_level: "會員等級",
   Game_users: "遊戲用戶",
   Status: "狀態",
   Registration_time: "註冊時間",
   Identity: "身份",
   User_type: "用戶類型",
   Inviter_userid: "邀請人userid",
   hint_738: "請輸入邀請人userid",
   Registration_Platform: "註冊平臺",
   hint_741: "請輸入註冊平臺網址",
   Batch_import_of_members: "批量導入會員",
   Download_batch_import_templates: "下載批量導入範本",
   Export_data: "匯出數據",
   Total_number_of_registered_users: "總註冊用戶數",
   Number_of_registered_users_today: "今日註冊用戶數",
 
   // 錯誤開始
   nickname: "昵稱",
   Avatar: "頭像",
   Mobile_area_code: "手機區號",
   Mobile_phone_number: "手機號碼",
   Third_party_binding: "協力廠商綁定",
   Recent_login_time: "最近登錄時間",
   Recently_logged_in_IP: "最近登錄IP",
   Last_visited_time: "最近存取時間",
   Anchor: "主播",
   Experts: "專家",
   Anchor_level: "主播等級",
   Ban: "封禁",
   Enable: "啟用",
   Detailed: "詳細",
   Prohibition: "禁言",
   Reset_password: "重置密碼",
   Personal_information_1: "的個人資料",
   Mobile_phone_number_and_area_code: "手機號區號",
   Settings: "設置",
   Phone_number: "手機號",
   Please_enter_your_phone_number: "請輸入手機號",
   User_status: "使用者狀態",
   Normal_1: "正常",
   hint_737: "是否主播",
   hint_736: "是否錦囊專家",
   Recommendation_code: "推薦碼",
   Personal_signature: "個人簽名",
   User_UID: "用戶UID",
   Prohibition_period_: "禁言週期",
   Release_the_ban: "解除禁言",
   Reasons_for_Prohibition: "禁言原因",
   Please_select_the_reason_for_the_ban: "請選擇禁言原因",
   Delete_chat_messages: "刪除聊天消息",
   Delete_all_chat_messages_in_the_live_broadcast_room: "刪除所有直播間聊天消息",
   hint_735: "確定要封禁此帳戶嗎？",
   Is_IP_banned_: "是否封禁IP",
   Current_level: "當前等級",
   Operation_time: "操作時間",
   Operator: "操作人",
   No: "否",
   Yes: "是",
   C_end_user_data: "c端使用者資料",
   Batch_import_of_member_templates: "批量導入會員範本",
   Account_has_been_banned: "帳戶已封禁",
   hint_734: "確定要啟用此帳戶嗎？",
   Prompt: "提示",
   Account_enabled: "帳戶已啟用",
   hint_733: "確認重置該使用者密碼嗎？初始密碼為：Aa123456",
   Password_reset_successful: "重置密碼成功",
   Password_reset_failed: "重置密碼失敗",
   Download_the_homepage_logo_: "下載頁首屏Logo：",
   Click_to_select_image: "點擊選取圖片",
   hint_20: "只能上傳jpg/png檔，圖片尺寸需為344X120，最多1張",
   hint_21: "只能上傳jpg/png檔，圖片尺寸需為288X100，最多1張",
   Download_the_top_screen_background_image: "下載頁首屏背景圖",
   hint_22: "只能上傳jpg/png檔，圖片尺寸需為375X732，最多1張",
   Download_the_top_screen_copy_image: "下載頁首屏文案圖",
   hint_23: "只能上傳jpg/png檔，圖片尺寸需為1980x1650，最多1張",
   Download_page_application_introduction_image: "下載頁應用介紹圖",
   hint_24: "只能上傳jpg/png檔，圖片尺寸需為360X842，最多4張",
   Download_page_application_introduction: "下載頁應用介紹",
   Download_page_features: "下載頁特色功能",
   PWA_configuration: "pwa配置",
   Only_json_files_can_be_uploaded: "只能上傳.json文件",
   Download_floating_bar_description: "下載懸浮條描述",
   New_H5_logo: "新H5-logo",
   hint_732: "圖片尺寸建議為40x40",
   New_H5_Download_floating_bar: "新H5-下載懸浮條",
   hint_731: "圖片尺寸建議為400x60",
   Youmeng_Statistics: "友盟統計",
   Please_enter_the_URL_corresponding_to_src: "請輸入src對應的url",
   hint_25: "如需統計多個功能變數名稱，請用||區分",
   Baidu_Statistics: "百度統計",
   Version_download_address: "版本下載地址",
   Quick_Version: "快捷版",
   Please_enter_the_iOS_shortcut_address: "請輸入iOS快捷版位址",
   Please_enter_Android_Scheme: "請輸入Android Scheme",
   H5_Station_Download_Jump_Status: "H5站下載跳轉狀態",
   APP_H5_Album_Page_Name: "APP/H5專輯頁名稱",
   Please_enter_a_name: "請輸入名稱",
   APP_H5_Album_Page_Link: "APP/H5專輯頁連結",
   Please_upload_pictures: "請上傳圖片",
   Please_upload_the_PWA_configuration_file: "請上pwa設定檔",
   Circle_: "圈子{num}",
   Please_enter_the_circle_ID: "請輸入圈子ID",
   Please_fill_in_the_Android_app_download_address: "請填Android APP下載地址",
   Please_fill_in_the_iOS_app_download_address: "請填iOS APP下載地址",
   Please_fill_in_the_application_introduction: "請填寫應用介紹",
   Please_fill_in_the_featured_features: "請填寫特色功能",
   Please_fill_in_the_description_1: "請填寫描述內容",
   hint_730: "請填寫iOS穩定版下載地址",
   Please_fill_in_the_Android_Scheme: "請填寫Android Scheme",
   Please_fill_in_the_iOS_Scheme: "請填寫iOS Scheme",
   hint_18: "只能上傳只能上傳jpg、jpeg或png檔",
   Upload_up_to_1_image: "最多上傳1張",
   hint_19: "只能上傳只能上傳jpg、jpeg或png檔!",
   The_upload_size_cannot_exceed_1_5M_: "上傳大小不能超過 1.5M!",
   Only_json_format_files_can_be_uploaded_: "只能上傳.json格式檔!",
   The_size_cannot_exceed_1MB_: "大小不能超過 1MB!",
   IOS_double_plot: "IOS二倍圖",
   Protocol_identifier_ID: "協定標識ID",
   Protocol_identifier: "協定標識",
   Protocol_Name: "協定名稱",
   Last_editing_date: "最後編輯日期",
   Release_status: "發佈狀態",
   H5_download_page_address: "H5下載頁面位址",
   Application_Introduction: "應用介紹",
   Image_and_Text_Configuration: "圖文配置",
   Page_: "第{num}張",
   Only_jpg_png_files_can_be_uploaded: "只能上傳jpg/png檔",
   The_size_is_394_X798: "尺寸為394X798",
   Please_enter_the_application_introduction: "請輸入應用介紹",
   Please_enter_the_address: "請輸入下位址",
   The_title_of_the_th_page_cannot_be_empty: "第${num}張標題不能為空",
   Logo_icon: "logo-圖示",
   Logo_Title: "logo-標題",
   The_recommended_image_size_is_140_x70: "圖片尺寸建議為140x70",
   Navigation_bar_activity_map: "巡覽列活動圖",
   hint_307: "尺寸： 312*126，只能上傳jpg/png檔",
   PC_download_copy: "PC下載文案",
   hint_26: "請輸入第一行文案，不超過10個字元",
   hint_27: "請輸入第二行文案，不超過10個字元",
   Page_title_: "頁面title：",
   Please_enter_a_title: "請輸入標題",
   Page_keywords: "頁面關鍵字",
   Page_Description: "頁面描述",
   Please_enter_a_description: "請輸入描述",
   Please_enter_the_download_address_for_the_anchor_tool: "請輸入主播工具下載位址",
   H5_download_page_address_1: "h5下載頁地址",
   Please_enter_the_h5_download_page_address: "請輸入h5下載頁位址",
   Anchor_Recruitment_Description: "主播招聘描述",
   Please_enter_the_anchor_recruitment_description: "請輸入主播招聘描述",
   Anchor_Recruitment_QR_Code: "主播招聘二維碼",
   The_recommended_image_size_is_256x256: "圖片尺寸建議為256x256",
   Contact_email_1: "聯繫郵箱",
   Please_enter_your_contact_email: "請輸入聯繫郵箱",
   Bottom_copyright_information: "底部版權資訊",
   Please_enter_copyright_information: "請輸入版權資訊",
   PC_Link_Configuration: "PC端連結配置",
   Please_enter_the_PC_domain_name_link_configuration: "請輸入PC端功能變數名稱連結配置",
   Site_icon: "網站icon",
   Click_to_select: "點擊選取",
   hint_28: "只能上傳ico檔，圖片尺寸需為32x32，大小不超過20kb",
   Please_fill_in_the_statistics_link: "請填寫統計連結",
   Customer_service_code_: "客服代碼",
   Please_fill_in_the_customer_service_code: "請填寫客服代碼",
   PC_album_page_status: "PC專輯頁狀態",
   PC_album_page_link: "PC專輯頁連結",
   Please_fill_in_the_title: "請填寫標題",
   Please_fill_in_the_description: "請填寫描述",
   Please_fill_in_the_keywords: "請填寫關鍵字",
   Please_fill_in_the_record_number_document: "請填寫備案號文案",
   Please_fill_in_your_email_address: "請填寫郵箱",
   Please_fill_in_the_h5_download_page_address: "請填寫h5下載頁地址",
   Please_select_the_Feixin_QR_code_image: "請選擇飛信二維碼圖片",
   Please_upload_the_logo_icon: "請上傳logo圖示",
   Please_upload_the_logo_title: "請上傳logo標題",
   Please_enter: "請輸入",
   Only_ICO_files_can_be_uploaded_: "只能上傳ico檔!",
   hint_729: "上傳圖片大小不能超過 20KB!",
   Please_enter_the_theme_name: "請輸入主題名稱",
   Add_a_new_theme: "新增主題",
   There_is_currently_no_content: "暫無內容",
   Last_modification_time: "最後修改時間",
   Please_enter_sorting: "請輸入排序",
   Are_you_sure_you_want_to_delete_it_: "是否確認刪除？",
   Delete_successful: "刪除成功",
   Theme: "主題",
   Publish: "發佈",
   Please_select_a_theme: "請選擇主題",
   Please_enter_the_content_of_the_agreement: "請輸入協定內容",
   Please_enter_the_protocol_name: "請輸入協定名稱",
   hint_728: "是否確認發佈協定資訊",
   hint_727: "是否確認保存協定資訊",
   User_Registration_Agreement: "使用者註冊協定",
   User_Privacy_Agreement: "使用者隱私協定",
   Live_streaming_protocol: "直播協議",
   Payment_Agreement: "支付協議",
   Settlement_rules: "結算規則",
   Recharge_Service_Agreement: "充值服務協定",
   About: "關於",
   Please_select_the_protocol_type: "請選擇協議類型",
   Login_Page_Logo: "登錄頁Logo",
   hint_29: "只能上傳jpg/png檔，圖片尺寸需為109X140，最多1張",
   Main_interface_logo: "主介面Logo",
   hint_30: "只能上傳jpg/png檔，圖片尺寸需為69X67，最多1張",
   About_Page_Logo: "關於頁面Logo",
   hint_31: "只能上傳jpg/png檔，圖片尺寸需為109X109，最多1張",
   hint_32: "上傳圖片尺寸有誤，請按格式要求上傳!",
   Add_TF_package: "添加TF包",
   Package_name: "包名",
   Download_link: "下載連結",
   Developer_account: "開發者帳號",
   Effective_date_1: "生效日期",
   Expiration_date: "失效日期",
   Remaining_valid_days: "剩餘有效天數",
   Please_enter_valid_days: "請輸入有效天數",
   Effective_days: "有效天數",
   Please_enter_the_package_name: "請輸入包名",
   Edit_TF_package: "編輯TF包",
   Please_fill_in_the_download_link: "請填寫下載連結",
   Please_select_the_effective_date: "請選擇生效日期",
   hint_726: "有效天數必須大於等於90",
   Created_successfully: "創建成功",
   Update_method: "更新方式",
   System: "系統",
   Add_1: "添加",
   Update_type: "更新類型",
   Version_number: "版本號",
   Prompt_package: "提示包",
   Update_package: "更新包",
   Reminder_date: "提醒日期",
   Recently_edited_by: "最近編輯人",
   Last_edited_time: "最近編輯時間",
   Version_update: "版本更新",
   TF_Package_Update: "TF包更新",
   Download_address: "下載地址",
   Select_prompt_package: "選擇提示包",
   Please_fill_in_the_download_address: "請填寫下載地址",
   Select_Update_Package: "選擇更新包",
   Remaining: "剩餘",
   Heaven: "天",
   Start_reminder_date: "開始提醒日期",
   Update_content: "更新內容",
   Please_enter_the_updated_content: "請輸入更新內容",
   hint_33: "支援格式：PNG、JPG，尺寸要求：750*1624",
   Edit_version_update: "編輯版本更新",
   Add_version_update: "添加版本更新",
   Please_set_an_updated_version: "請設置更新版本",
   Please_set_the_prompt_version: "請設置提示版本",
   Please_select_the_system: "請選擇系統",
   Please_choose_a_method: "請選擇方式",
   Please_enter_the_content: "請輸入內容",
   Please_set_the_reminder_date: "請設置提醒日期",
   hint_725: "上傳圖片只能是 JPG 或 PNG 格式!",
   hint_724: "上傳圖片大小不能超過 1MB!",
   Modified_successfully: "修改成功",
   User_Management: "用戶管理",
   IP_blacklist: "IP黑名單",
   Application_ID: "應用ID",
   Application_Name: "應用名稱",
   Last_modified_date: "最後修改日期",
   Create_an_application: "創建應用",
   View_Key: "查看金鑰",
   Module: "模組",
   Supplier: "供應商",
   Add_Module: "添加模組",
   Reset_Key: "重置金鑰",
   New_Module: "新建模組",
   Please_enter_the_application_name: "請輸入應用名稱",
   Please_select_a_module: "請選擇模組",
   Please_select_a_supplier: "請選擇供應商",
   Please_enter_supplier_configuration: "請輸入供應商配置",
   Edit_application: "編輯應用",
   hint_34: "若該應用有使用此模組的功能，刪除記錄可能會導致功能不可用。確認是否要刪除？",
   Start_page: "啟動頁",
   Change: "變更",
   Only_JPG_or_PNG_format_images_are_allowed_to_be_uploaded: "只允許上傳JPG格式或PNG格式圖片",
   Are_you_sure_you_want_to_save_the_changes_: "是否確認保存變更",
   hint_320: "密碼修改成功，請重新登錄",
   Review_version: "審核版本",
   Please_enter_version: "請輸入版本",
   Tag_number: "標記號",
   List_Fields: "列表欄位",
   Form_Description: "表單說明",
   Remarks: "備註",
   Pre_explanation: "前置說明",
   hint_35: "1.針對ios端提審環境版本控制隱藏功能；2.代碼層面規避明顯做審核環境控制注釋或代碼；3.Android端無需控制",
   Version_control: "版本控制",
   hint_36: "1.顯示當前需要控制的版本號，例如：當前需要提審的版本為V1.1，則輸入框需輸入1.1，且下方勾選對應需要隱藏的模組，則在V1.1版本裡以下勾選的模組入口均隱藏。2.輸入無實際版本的版本號保存，不影響顯示任意版本模組；",
   Control_module: "控制模組",
   hint_317: "顯示所需要再提審環境隱藏的功能名稱，預設勾選狀態，取掉勾選，且保存後，則在此版本中不隱藏此模組；",
   Please_fill_in_version_information: "請填寫版本資訊",
   Select_language: "選擇語言",
   General_configuration: "通用配置",
   IOS_configuration: "IOS配置",
   H5_configuration: "H5配置",
   New_download_page_configuration: "新下載頁配置",
   Streaming_tool_configuration: "推流工具配置",
   PC_configuration: "PC配置",
   Mobile_streaming_configuration: "移動推流配置",
   Advertising_restriction_configuration: "廣告限制配置",
   Virtual_Number_Restrictions: "虛擬號碼限制",
   Reporting_restrictions_for_third_party_data_apps: "三方資料APP上報限制",
   Report_domain_name_configuration: "上報功能變數名稱配置",
   Please_enter_the_domain_name: "請輸入功能變數名稱",
   hint_723: "下載APP首次埋點上報",
   Channel_code_parameter_acquisition: "管道碼參數獲取",
   PC_data_reporting: "PC資料上報",
   H5_data_reporting: "H5資料上報",
   Domain_Name_Address: "功能變數名稱地址",
   Please_enter_the_domain_name_address: "請輸入功能變數名稱位址",
   Deactivate: "停用",
   Please_select_the_type: "請選擇類型",
   Are_you_sure_to_enable_the_domain_name_: "確認啟用功能變數名稱嗎？",
   hint_722: "確認停用功能變數名稱嗎？",
   Update_successful: "更新成功",
   Recommended: "推薦中",
   Paused: "已暫停",
   Picture_matching: "配圖",
   Source: "來源",
   Feedback_type: "回饋類型",
   Number: "序號",
   Feedback_details: "回饋詳情",
   Contact_information: "聯繫方式",
   Feedback_time: "回饋時間",
   Navigation_Station_Switch: "導航站開關",
   Page_Configuration: "頁面配置",
   Weight: "權重",
   hint_37: "數字越大排名越靠前",
   Button_Name: "按鈕名稱",
   Domain_name: "功能變數名稱",
   Please_enter_the_weight: "請輸入權重",
   Please_enter_the_button_name: "請輸入按鈕名稱",
   Mobile_related: "移動端相關",
   PC_related: "PC端相關",
   Personal_information: "個人資訊",
   Login_account: "登錄帳號",
   User_Name: "用戶姓名",
   Security_Settings: "安全設置",
   Change_password: "修改密碼",
   Original_password: "原密碼",
   New_password: "新密碼",
   Confirm_new_password: "確認新密碼",
   hint_721: "密碼長度不能少於6位元",
   The_passwords_entered_twice_do_not_match: "兩次輸入的密碼不一致",
   Please_enter_your_password_again: "請再次輸入密碼",
   Merchant_Basic_Information: "商戶基本資訊",
   Merchant_ID: "商戶ID",
   Enterprise_Name: "企業名稱",
   Contact_person: "連絡人",
   Contact_email: "連絡人郵箱",
   Contact_phone_number: "連絡人手機",
   SMS_verification_code: "短信驗證碼",
   Please_enter_the_merchant_ID: "請輸入商戶ID",
   Please_enter_the_company_name: "請輸入企業名稱",
   Please_enter_the_contact_person: "請輸入連絡人",
   Please_enter_the_contact_person_phone_number: "請輸入連絡人手機",
   Please_enter_the_contact_email: "請輸入連絡人郵箱",
   Please_enter_the_verification_code: "請輸入驗證碼",
   Get_verification_code: "獲取驗證碼",
   Please_enter_sensitive_words: "請輸入敏感詞",
   Batch_Add: "批量添加",
   Query_results: "查詢結果",
   hint_720: "抱歉，沒有找到該敏感詞",
   Activity_Name: "活動名稱",
 
   // 錯誤end====
 
   hint_718: "請輸入敏感詞且長度不能少於兩個字元",
   hint_308: "確定刪除該關鍵字？",
   continue_to_be_sent_on_the_platform: "解禁後則該詞可繼續在平臺發送",
   Are_you_sure_to_lift_the_ban_: "確認解禁嗎？",
   The_image_size_needs_to_be_40_x40: "圖片尺寸需為40x40",
   Brand_name: "品牌名稱",
   Main_site_name: "主站名稱",
   Please_enter_the_name_of_the_main_site: "請輸入主站名稱",
   Main_site_redirect_URL: "主站跳轉url",
   Please_enter_the_main_site_redirect_URL: "請輸入主站跳轉url",
   H5_Main_Station_Jump_URL: "H5主站跳轉url",
   Please_enter_the_H5_main_site_redirect_URL: "請輸入H5主站跳轉url",
   Homepage_banner: "首頁banner",
   hint_717: "圖片尺寸需為1200x150",
   Homepage_banner_URL: "首頁banner url",
   Please_enter_the_banner_URL_on_the_homepage: "請輸入首頁bannerUrl",
   Live_room_banner: "直播間banner",
   hint_716: "請輸入直播間bannerUrl",
   Homepage_title: "首頁-title",
   Please_enter_the_title: "請輸入title",
   Home_page_description_1: "首頁-description",
   Homepage_keywords_1: "首頁-keywords",
   Please_enter_keywords_1: "請輸入keywords",
   Live_room_title: "直播間-title",
   Live_broadcast_room_keywords_1: "直播間-keywords",
   Bottom_copyright_text: "底部版權文字",
   Please_enter_the_copyright_text_at_the_bottom: "請輸入底部版權文字",
   APP_download_URL: "APP下載URL",
   Please_enter_the_APP_download_URL: "請輸入APP下載URL",
   hint_715: "圖片尺寸需為750x94",
   H5_Live_Banner: "H5直播banner",
   Please_enter_h5RoomBannerRedirectUrl: "請輸入h5RoomBannerRedirectUrl",
   Please_enter_the_brand_name: "請輸入品牌名稱",
   Please_enter_the_main_site_redirect: "請輸入主站跳轉",
   Please_enter_H5_main_site_redirect: "請輸入H5主站跳轉",
   Please_enter_the_homepage: "請輸入首頁",
   Please_enter_the_homepage_banner_to_jump_to: "請輸入首頁 banner 跳轉",
   Please_enter_the_homepage_description: "請輸入首頁描述",
   Please_enter_homepage_keywords: "請輸入首頁關鍵字",
   Please_enter_the_live_streaming_page: "請輸入直播頁",
   hint_714: "請輸入直播頁描述",
   Please_enter_keywords_for_the_live_streaming_page: "請輸入直播頁關鍵字",
   Please_enter_the_website_copyright_statement: "請輸入網站版權申明",
   Please_enter_the_app_download_URL: "請輸入app 下載 url",
   Please_enter_the_site_h5BannerRedirectUrl: "請輸入網站h5BannerRedirectUrl",
   Add_Site: "添加網站",
   Edit_Site: "編輯網站",
   Upload_up_to_1_photo_: "最多上傳1張!",
   Home_page_description: "首頁description",
   Homepage_keywords: "首頁keywords",
   Live_broadcast_room_title: "直播間title",
   Live_broadcast_room_description: "直播間-description",
   Live_broadcast_room_keywords: "直播間-keywords",
   App_download_URL: "App下載URL",
   H5_homepage_banner_URL: "h5首頁bannerurl",
   H5_homepage_banner_redirect_URL: "h5首頁banner跳轉url",
   H5_live_broadcast_room_banner_URL: "h5直播間bannerurl",
   H5_Live_Room_Banner_Jump_URL: "h5直播間banner跳轉url",
   Live_streaming_services_1: "直播服務",
   SMS_service: "短信服務",
   Certificate_file_name: "證書檔案名",
   Certificate_type: "證書類型",
   General_Supplier_Configuration: "通用供應商配置",
   Whether_to_configure_TF_package_name: "是否配置TF包名",
   Package_Name_Configuration: "包名配置",
   Supplier_Configuration: "供應商配置",
   Edit_Supplier_Configuration: "編輯供應商配置",
   Add_supplier_configuration: "新增供應商配置",
   Please_fill_in_the_configuration_information: "請填寫配置資訊",
   hint_713: "是否確定刪除配置？",
   Please_configure_TF_configuration_correctly: "請正確配置TF配置",
   Version_update_reminder: "版本更新提醒",
   TF_Package_Management: "TF包管理",
   Batch_upload: "批量上傳",
   Posting_updates: "發佈動態",
   Account: "帳號",
   Content: "內容",
   Upload_images: "上傳圖片",
   Only_jpg_jpeg_png_files_can_be_uploaded: "只能上傳jpg/jpeg/png檔",
   Up_to_9_images_can_be_uploaded: "最多支援上傳9張",
   Click_to_select_file: "點擊選取檔",
   Only_supports_video_format_files: "僅支援視頻格式檔",
   Related_circles: "關聯圈子",
   Please_select_a_circle: "請選擇圈子",
   Related_topics: "關聯話題",
   Please_select_a_topic: "請選擇話題",
   Please_upload_the_video_file: "請上視頻檔",
   Please_select_an_account: "請選擇帳號",
   hint_712: "上傳圖片只能是 JPG 或 PNG格式!",
   hint_707: "上傳圖片大小不能超過 10MB!",
   hint_710: "只能上傳mp4格式檔!",
   hint_711: "大小不能超過 200MB!",
   Please_enter_your_nickname: "請輸入昵稱",
   Joined_the_circle: "已加入圈子",
   Import_time: "導入時間",
   Disabled: "禁用",
   Add_Circle: "添加圈子",
   Cannot_be_greater_than_80: "不能大於80",
   hint_38: "確認禁用該用戶嗎？禁用後將無法使用該帳號發佈動態？",
   hint_39: "確認啟用該用戶嗎？啟用後將可重新使用該帳號發佈動態？",
   Dynamic_configuration: "動態配置",
   Publisher_nickname: "發佈人昵稱",
   Accumulated_likes: "累計點贊數",
   Accumulated_number_of_comments: "累計評論數",
   Topic: "話題",
   Circle: "圈子",
   Release_time: "發佈時間",
   Remove_this_comment: "下架此評論",
   Dynamic_permission_configuration: "動態許可權配置",
   Publish_dynamic_user_level_configuration: "發佈動態使用者等級配置",
   Name: "名稱",
   Level: "等級",
   hint_703: "此項配置為普通使用者發佈動態需達到的等級",
   Cannot_be_less_than_1: "不能小於1",
   Please_fill_in_the_user_level: "請填寫使用者等級",
   There_are_currently_no_related_posts: "暫無相關帖子",
   hint_702: "確認要下架此條帖子評論嗎？",
   Comment_removed_successfully: "評論下架成功",
   hint_704: "確定要下架該話題嗎？",
   hint_705: "確定要上架該話題嗎？",
   Return: "返回",
   hint_706: "你沒有許可權去該頁面",
   hint_309: "如有不滿請聯繫你領導",
   Or_you_can_go: "或者你可以去",
   Go_back_to_the_homepage: "回首頁",
   hint_40: "請檢查您輸入的網址是否正確，請點擊以下按鈕返回主頁或者發送錯誤報告",
   Return_to_homepage: "返回首頁",
   hint_41: "提示：1-權重為正整數，數值越大越靠前；2-默認前端展示，隱藏則前端不展示",
   Anchor_nickname: "主播昵稱",
   Please_enter_sorting_weight: "請輸入排序權重",
   Display: "顯示",
   Hidden: "隱藏",
   Associated_channels_categories: "關聯頻道/分類",
   Anchor_Name_1: "主播名稱",
   Please_enter_the_anchor_name_1: "請輸入主播名稱",
   Room_number: "房間號",
   Please_enter_the_room_number: "請輸入房間號",
   Source_of_flow: "流來源",
   Anchor_profile_picture: "主播頭像",
   Source_room_number: "來源房間號",
   Live_streaming_categories: "直播品類",
   Live_streaming_title: "直播標題",
   Stream_address: "流地址",
   Forbidden_broadcasting: "禁播",
   Live_streaming_1: "直播中",
   Ended: "已結束",
   Unlocking: "解禁",
   All: "全部",
   Douyu: "鬥魚",
   Tiger_Fang: "虎牙",
   hint_701: "是否確認解禁該主播？",
   hint_700: "是否確認禁播該主播？",
   Competition_time: "比賽時間",
   Competition_status: "比賽狀態",
   Please_enter_keywords_2: "請輸入關鍵字",
   League_Cup_Tournament: "聯賽/杯賽",
   The_home_team: "主隊",
   The_visiting_team: "客隊",
   Event_ID: "賽事ID",
   Anchor_Qingliu_Live_Room: "主播清流直播間",
   Associated_Channel: "關聯頻道",
   Unknown_status: "狀態未知",
   Not_started: "未開始",
   In_progress: "進行中",
   The_competition_has_been_postponed: "比賽推遲",
   Competition_cancelled: "比賽取消",
   Direct_room_number: "直推房間號",
   Progress_time: "進行時間",
   Live_streaming_status: "直播狀態",
   Next_broadcast: "下播",
   Binding_successful: "綁定成功",
   hint_699: "確定將該直播下播嗎？",
   Anchor_sorting: "主播排序",
   hint_42: "提示：1-權重為正整數，數值越大越靠前；2-預設前端顯示，隱藏則前端不展示；",
   Update: "更新",
   Channel_sorting: "頻道排序",
   Channel_nickname: "頻道昵稱",
   Secondary_classification: "二級分類",
   Live_streaming_type: "直播類型",
   hint_698: "關聯多項，請使用英文逗號分割",
   Streaming_settings: "推流設置",
   End_all_live_broadcasts: "結束全部直播",
   Signal_source: "信號源",
   Push_flow_room: "推流房間",
   Streaming_Title: "推流標題",
   Push_Streaming_Channel: "推流頻道",
   There_are_currently_no_channels_available: "當前暫無頻道",
   Streaming_address: "推流地址",
   Streaming_address_server_address_: "推流地址（伺服器地址）",
   Copy: "複製",
   Push_stream_code_stream_key_: "推流碼（串流秘鑰)",
   End_Live: "結束直播",
   hint_321: "如沒有可使用的頻道，請先：",
   Create_channel: "創建頻道",
   You_are_about_to_use_it: "您即將用",
   Start_broadcasting: "進行開播",
   Start_live_streaming: "開始直播",
   Copy_successful: "複製成功",
   hint_697: "請填寫完整資訊再提交",
   hint_696: "確定要結束直播嗎？",
   hint_695: "確定要結束全部直播嗎？",
   Choose_a_live_broadcast_room: "選擇直播間",
   Live_streaming_room: "直播間",
   League_name: "聯賽名稱",
   Please_enter_the_league_name: "請輸入聯賽名稱",
   Popular: "熱門",
   Normal: "普通",
   The_continent_where_it_is_located: "所在洲",
   Football: "足球",
   Basketball: "籃球",
   Date: "日期",
   Start_date: "開始日期",
   To: "至",
   End_date: "結束日期",
   In_the_competition: "比賽中",
   Delay: "推遲",
   Popular_events: "熱門賽事",
   Keywords_1: "關鍵字",
   Please_enter_keywords: "請輸入關鍵字",
   Batch_editing_of_popular_content: "批量編輯熱門",
   Batch_creation_of_reserved_events: "批量創建預約賽事",
   Country_Region: "國家/地區",
   Current_round: "當前輪次",
   Edit_Appointment_Anchor: "編輯預約主播",
   Please_choose_to_create_a_schedule: "請選擇創建賽程",
   Please_select_the_associated_channel: "請選擇關聯頻道",
   Basketball_schedule_data: "籃球賽程數據",
   No_selected_items: "無選中項",
   Main_Station_Event_ID: "總台賽事ID",
   Please_enter_the_main_event_ID: "請輸入總台賽事ID",
   Third_party_in_game_event_ID: "協力廠商賽中賽事ID",
   hint_694: "請輸入協力廠商賽中賽事ID",
   There_are_streaming_anchors: "存在推流主播",
   There_is_a_push_flow_room: "存在推流房間",
   Click_on_the_nickname_modification_announcement: "（點擊昵稱修改公告）",
   Please_select_a_popular_event: "請選擇熱門賽事",
   Edit_successful: "編輯成功",
   E_sports_streaming: "電競流",
   Entertainment_stream: "娛樂流",
   Create_Popular: "創建熱門",
   Official_Clearflow: "官方清流",
   Anchor_Qingliu: "主播清流",
   Score: "比分",
   The_competition_team: "比賽球隊",
   Create_circles: "創建圈子",
   Limit_1M: "限制1M",
   hint_900: "大小790*215px",
   Associate_Creator: "關聯創建者",
   Operation_label: "運營標籤",
   Add_tags: "新增標籤",
   Confirm_creation: "確定創建",
   Label_: "標籤{num}",
   Please_select_an_image: "請選擇圖片",
   Please_select_the_associated_creator: "請選擇關聯創建者",
   Publisher_userid: "發佈人userid",
   Message_content: "消息內容",
   Chat_Room: "聊天室",
   hint_45: "刪除後該消息將不在App端的該圈子內展示，確認刪除嗎？",
   Circle_Introduction: "圈子簡介",
   Circle_name: "圈子名稱",
   Circle_avatar: "圈子頭像",
   Creator_1: "創建者",
   Number_of_people_in_the_circle: "圈子人數",
   The_maximum_number_of_people_in_the_circle: "圈子人數上限",
   Circle_Cover: "圈子封面",
   Circle_dynamics_1: "圈子動態",
   Circle_Announcement: "圈子公告",
   Creation_time: "創建時間",
   Dissolve_the_circle: "解散圈子",
   Dissolved: "已解散",
   Submission_dynamics: "投稿動態",
   User_userid: "用戶userid",
   Number_of_comments: "評論數",
   Like_count: "點贊數",
   Preview: "預覽",
   Setting_up_circle_announcements: "設置圈子公告",
   Add_Announcement: "添加公告",
   Please_enter_the_link_name: "請輸入連結名稱",
   Display_link: "展示連結",
   Jump_link: "跳轉連結",
   Please_enter_the_jump_link: "請輸入跳轉連結",
   To_be_reviewed: "待審核",
   Listed: "已上架",
   Offline_1: "已下架",
   Rejected: "已拒絕",
   Deleted: "已刪除",
   hint_692: "請填寫完整公告連結資訊再提交",
   The_circle_has_disbanded: "圈子已解散",
   Are_you_sure_you_want_to_dissolve_this_circle_: "是否確認要解散該圈子？",
   Dissolution: "解散",
   hint_44: "是否確認要{status}該條動態？",
   hint_693: "是否確認要拒絕該條動態？",
   Successfully: "已成功",
   Remove_from_shelves: "下架",
   Listing: "上架",
   Circle_dynamics: "圈子動態數",
   Creator_nickname: "創建者昵稱",
   Creator_userid: "創建者userid",
   In_operation: "運營中",
   View_details: "查看詳情",
   Chat_messages: "聊天消息",
   Edit_anchor_information: "編輯主播資訊",
   Review: "審核",
   Anchor_Name: "主播姓名",
   Guild: "所在公會",
   Please_select_a_guild: "請選擇公會",
   Split_ratio: "分成比例",
   Please_select_the_sharing_ratio: "請選擇分成比例",
   Basic_salary: "底薪",
   Please_enter_the_base_salary: "請輸入底薪",
   Review_details: "審核詳情",
   Review_details_1: "詳情",
   Please_enter_the_ID_number: "請輸入身份證號碼",
   Please_enter_the_host_nickname: "請輸入主播昵稱",
   hint_43: "確定把該用戶從群主授權列表中刪除嗎？",
   Default_sorting: "默認排序",
   Channel_ID: "頻道ID",
   Icon: "圖示",
   Page_template: "頁面範本",
   Tab_name: "tab名稱",
   Channel_Type: "頻道類型",
   hint_46: "只能上傳jpg/png檔，圖片尺寸需為120X120",
   Please_enter_a_valid_tab_name_: "請輸入有效的tab名稱！",
   Enter_tab_name: "輸入tab名稱",
   Content_ID: "內容ID",
   Please_enter_ID: "請輸入ID",
   Content_type: "內容類別型",
   Topped: "已置頂",
   Top: "置頂",
   Cancel_Topping: "取消置頂",
   Restore_Display: "恢復顯示",
   Expiration_time: "失效時間",
   Select_date_and_time: "選擇日期時間",
   Sealing_the_picture: "封圖",
   real_time_info: "資訊",
   Short_video: "短視頻",
   Dynamic: "動態",
   Brocade_Bag: "錦囊",
   The_expiration_time_has_not_been_set_yet: "還未設置失效時間",
   Please_select_a_future_time: "請選擇未來時間",
   Top_management_on_homepage: "首頁置頂管理",
   Please_enter_information_keywords: "請輸入資訊關鍵字",
   Keywords: "關鍵字",
   Batch_publishing: "批量發佈",
   Batch_scheduled_release: "批量定時發佈",
   Thumbnails: "縮略圖",
   Title_1: "標題",
   Crawling_time: "爬取時間",
   hint_47: "你可以選擇5分鐘後，今後一個星期內的任意時間定時發佈，在設定時間之前可以重新編輯和取消",
   Timed_release: "定時發佈",
   hint_48: "發佈後無法進行修改和撤銷，是否確認發佈？",
   hint_691: "未選擇任何資訊，請選擇資訊後再發佈！",
   Please_choose_a_reasonable_publishing_time: "請選擇合理的發佈時間",
   Edit_information: "編輯資訊",
   New_information: "新增資訊",
   Please_fill_in_the_necessary_title_and_author: "請填寫必要的標題和作者",
   Information_Title: "資訊標題",
   Comment_content: "評論內容",
   hint_49: "刪除後該評論將不在APP端、PC端內展示，確定刪除嗎？",
   hint_50: "確定取消發佈嗎？取消後該條資訊將進入草稿箱",
   hint_51: "確定下架嗎？下架後移動端將不出現此條資訊！",
   Cancel_Top_Time: "取消置頂時間",
   Publishing_Library: "發佈庫",
   Top_Library: "置頂庫",
   Crawling_website_titles: "爬取網站標題",
   Crawling_website_links: "爬取網站連結",
   Crawling_time_period: "爬取時段",
   Set_crawling_time_period: "設置爬取時間段",
   End_time: "結束時間",
   Select_a_time_range: "選擇時間範圍",
   hint_52: "禁用後則不爬取該網站資料，是否確認禁用？",
   hint_690: "確定要啟用此配置嗎？",
   hint_689: "確定要刪除此條資訊嗎？",
   Prize_Name: "獎品名稱",
   Please_enter_the_name_of_the_prize: "請輸入獎品名稱",
   Prize_type: "獎品類型",
   Query: "查詢",
   Add_prizes_1: "新增獎品",
   Prize_icon: "獎品icon",
   Please_enter_the_number_of_prizes: "請輸入獎品數量",
   quantity: "數量",
   Only_jpg_png_gif_files_can_be_uploaded: "只能上傳jpg/png/gif檔",
   Modify: "修改",
   New: "新增",
   Virtual_currency: "虛擬貨幣",
   Experience_value: "經驗值",
   hint_53: "上傳圖片只能是 JPG 或 PNG 或 GIF格式!",
   Winning_number: "中獎編號",
   Please_enter_the_winning_number: "請輸入中獎編號",
   UID: "UID",
   Please_enter_UID: "請輸入UID",
   Obtain_time: "獲得時間",
   Export: "匯出",
   hint_688: "直播間砸金蛋-獎品記錄",
   Basic_Settings: "基本設置",
   Red_envelope_rain_name: "紅包雨名稱",
   Edit_Red_Envelope_Rain_Library: "編輯紅包雨庫",
   New_Red_Envelope_Rain_Warehouse: "新建紅包雨庫",
   Please_enter_the_name_of_the_red_envelope_rain: "請輸入紅包雨名稱",
   Trigger_type: "觸發類型",
   Goal_scoring_events: "進球事件",
   Event_timeline: "賽事時間點",
   Maximum_number_of_red_envelope_rain_wheels: "紅包雨輪數上限",
   Please_enter_the_number_of_rounds: "請輸入輪數",
   hint_54: "0為不下紅包雨，此為一場比賽最多紅包雨輪數的上限",
   hint_687: "最多中紅包個數(單個用戶單輪)",
   hint_55: "0為搶不到紅包，達到紅包個數數後則不會再搶到紅包",
   Fixed_point_in_time: "固定時間點",
   Select_date: "選擇日期",
   Probability_of_winning_a_prize_red_envelope: "抽中有獎紅包概率",
   Please_enter_the_probability: "請輸入概率",
   hint_56: "0為不可能抽中紅包，此為用戶抽中有獎紅包的概率",
   hint_686: "請輸入紅包雨輪數上限",
   Award_Pool_Settings: "獎池設置",
   Add_prizes: "添加獎品",
   Number_of_individual_red_envelope_rewards: "單個紅包獎勵個數",
   Please_enter_the_number: "請輸入個數",
   Number_of_red_envelopes: "紅包數量",
   Please_enter_the_quantity: "請輸入數量",
   Prize_weight: "獎品權重",
   Please_fill_in_the_fixed_time_point: "請填寫固定時間點",
   Please_select_a_time_point_for_the_competition: "請選擇賽事時間點",
   hint_685: "僅可配置0 - 100的正整數數位",
   Please_add_at_least_one_prize: "請至少添加一條獎品",
   hint_684: "請填寫完整獎品資訊再提交",
   hint_57: "輸入有誤，單個紅包獎勵個數、紅包數量僅可配置1 - 99999的正整數數位",
   Add_has_reached_the_limit: "添加已達到上限",
   Golden_Egg_Switch: "金蛋開關",
   Basic_Information_Configuration: "基本資訊配置",
   APP_background_image: "APP背景圖",
   APP_Title_Image: "APP標題圖",
   The_total_probability_must_be_10000: "概率總和必需為10000",
   Red_envelope_rain_storage_details: "紅包雨庫詳情",
   hint_58: " 0為不下紅包雨，此為一場比賽最多紅包雨輪數的上限",
   Wheel: "輪",
   Pieces: "個",
   Task_type: "任務類型",
   Reward_Name: "獎勵名稱",
   Task_Title: "任務標題",
   Task_icon: "任務icon",
   Task_count: "任務次數",
   Application_channels: "應用管道",
   Edit_task: "編輯任務",
   Reward_type: "獎勵類型",
   Reward_quantity: "獎勵數量",
   Please_enter_the_number_of_rewards: "請輸入獎勵數量",
   Please_select_the_reward_type_icon: "請選擇獎勵類型icon",
   Please_select_the_application_channel: "請選擇應用管道",
   Task_title_cannot_be_empty: "任務標題不能為空",
   Task_count_cannot_be_empty: "任務次數不能為空",
   To_be_effective: "待生效",
   In_effect: "生效中",
   Daily_tasks: "每日任務",
   Novice_Task: "新手任務",
   Sign_in_task: "簽到任務",
   Attendance_Record: "簽到記錄",
   Please_enter_the_task_title: "請輸入任務標題",
   Check_in_switch: "簽到開關",
   No_1: "第",
   Number_of_check_in_days: "簽到天數",
   Reward_details: "獎勵明細",
   Reward_icon: "獎勵icon",
   Edit_check_in: "編輯簽到",
   Lottery: "彩金",
   Physical_object: "實物",
   Please_upload_the_reward_type_icon: "請上傳獎勵類型icon",
   Sign_in_has_been_closed: "已關閉簽到",
   Check_in_has_been_opened: "已打開簽到",
   Please_fill_in_the_quantity: "請填寫數量",
   Please_enter_the_room_number_for_sending: "請輸入發送房間號",
   Send_room_number: "發送房間號",
   Sending_time: "發送時間",
   Details_of_receiving_red_envelopes: "紅包領取詳情",
   hint_683: "暫無紅包領取詳情",
   Anchor_room_number: "主播房間號",
   Please_enter_the_host_room_number: "請輸入主播房間號",
   Remove_permissions: "移除許可權",
   Add_anchor: "添加主播",
   Time: "時間",
   Anchor_userID: "主播userID",
   Please_enter_the_anchor_userID: "請輸入主播userID",
   Betting_amount: "投注金額",
   Prophecy_betting_list: "預言投注列表",
   Please_enter_the_name_of_the_main_team: "請輸入主隊名稱",
   Please_enter_the_name_of_the_visiting_team: "請輸入客隊名稱",
   Please_enter_the_status: "請輸入狀態",
   Team_PK_ID: "球隊PK ID",
   The_winner: "勝方",
   Number_of_gift_givers_1: "送禮人數",
   Total_value_in_gold_coins: "總價值(金幣)",
   The_losing_side: "敗方",
   Binding_events: "綁定賽事",
   Please_enter_the_binding_event: "請輸入綁定賽事",
   Bind_anchor: "綁定主播",
   Please_enter_the_bound_anchor: "請輸入綁定主播",
   Activity_Strategy_Name: "活動策略名稱",
   hint_682: "請輸入活動策略名稱",
   Red_envelope_rain_claim_details: "紅包雨領取詳情",
   Effective_anchor_room_details: "生效主播房間詳情",
   To_be_published: "待發佈",
   Published: "已發佈",
   Canceled: "已取消",
   hint_681: "是否確認取消發佈",
   Main_switch: "總開關",
   Policy_Name: "策略名稱",
   Please_enter_a_policy_name: "請輸入策略名稱",
   Add_Policy: "添加策略",
   Red_envelope_rain_storage: "紅包雨庫",
   Red_envelope_rain_type: "紅包雨類型",
   Event_red_envelope_rain: "賽事紅包雨",
   Live_broadcast_room_chat_room: "直播間/語聊室",
   Event_type: "賽事類型",
   Distribute_users: "下發用戶",
   hint_60: "請輸入用戶UID，多個UID使用英文逗號隔開",
   Please_select_the_type_of_red_envelope_rain: "請選擇紅包雨類型",
   Please_select_an_event: "請選擇賽事",
   Please_select_the_red_envelope_rain_storage: "請選擇紅包雨庫",
   Edit_Policy: "編輯策略",
   Strategy_details: "策略詳情",
   hint_61: "下線該活動策略將導致該活動從前端下線，確定下線嗎",
   Offline_2: "下線",
   Live_room_room_number: "直播間房間號",
   hint_680: "請輸入直播間房間號",
   Configuration_Management: "配置管理",
   Prize_configuration: "獎品配置",
   Prize_Record: "獎品記錄",
   Anchor_cumulative_share: "主播累計分成",
   Option_A_cumulative_betting: "選項A累計投注",
   Option_A: "選項A",
   Option_B: "選項B",
   Option_B_cumulative_betting: "選項B累計投注",
   Results: "結果",
   Number_of_bets: "投注人數",
   Anchor_sharing_3: "主播分成（3%）",
   Platform_share_2: "平臺分成（2%）",
   The_anchor_prophecy: "主播預言",
   The_prophecy_of_the_whole_nation: "全民預言",
   Login_time: "登錄時間",
   Online_duration: "線上時長",
   Inviters_nickname: "邀請人昵稱",
   Invitee_UID_: "被邀請人UID：",
   Invitation_time: "邀請時間",
   Inviter_payment_method: "邀請人收款方式",
   Alipay: "支付寶",
   WeChat_account: "微信號",
   QQ_account: "QQ帳號",
   Inviting_person_rewards: "邀請人獎勵",
   Distributed: "已派發",
   Not_passed: "未通過",
   Accumulated: "累計中",
   Invitee_nickname: "被邀請人昵稱",
   Invitee_rewards: "被邀請人獎勵",
   Select_distribution_type: "選擇派發類型",
   Please_enter_a_rule_description: "請輸入規則說明",
   Are_you_sure_to_cancel_the_distribution_: "確認取消派發嗎？",
   APP_user_login_statistics: "APP用戶登錄統計",
   hint_676: "邀請人獎勵不能小於0",
   hint_677: "邀請人獎勵不能大於99999",
   hint_675: "被邀請人獎勵不能小於0",
   hint_678: "被邀請人獎勵不能大於99999",
   Room_type: "房間類型",
   The_ban_was_successful: "禁言成功",
   hint_62: "刪除後該消息將不在App端、PC端、H5端的該直播間/聊天室內展示，確定隱藏嗎？",
   Please_enter_the_user_UID: "請輸入用戶UID",
   Prohibition_time: "禁言時間",
   Forbidden_live_broadcast_room: "禁言直播間",
   Prohibition_type: "禁言類型",
   Prohibition_reasons: "禁言理由",
   Operator_UID: "操作人UID",
   Successfully_unblocked: "解禁成功",
   Opening_duration: "開播時長",
   The_duration_of_the_current_live_broadcast: "當前直播的直播時長",
   Current_number_of_views: "當前流覽次數",
   hint_674: "當前直播的流覽次數（去重）",
   Current_number_of_online_users: "當前線上人數",
   hint_673: "當前直播的線上觀看人數（去重）",
   The_highest_number_of_online_users: "最高線上人數",
   hint_672: "當前直播的最高線上人數（去重）",
   hint_63: "統計包含未登錄用戶，未登錄用戶未去重。",
   Field_Description: "欄位說明",
   Anchor_UID: "主播UID",
   Live_Channel: "直播頻道",
   Start_time_1: "開播時間",
   Downtime: "下播時間",
   Opening_duration_minutes_: "開播時長(分鐘)",
   Accumulated_views: "累計流覽次數",
   Accumulated_number_of_online_users: "累計線上人數",
   Average_viewing_time_minutes_: "平均觀看時長(分鐘)",
   The_entire_live_broadcast_data: "整場直播數據",
   Edit_data: "編輯資料",
   Add_announcement_link: "添加公告連結",
   Announcement_link: "公告連結",
   Please_enter_the_display_link: "請輸入展示連結",
   Recommended_display: "推薦展示",
   Browser_Open: "流覽器打開",
   Display_end: "顯示端",
   Mobile_end: "移動端",
   PC_end: "PC端",
   Application_time: "申請時間",
   Global_announcement_adjustment: "全域公告調整",
   Sports_type: "體育類型",
   On_the_front_of_the_ID_card: "身份證正面",
   On_the_back_of_the_ID_card: "身份證反面",
   Holding_an_ID_card: "手持身份證",
   Reason_for_rejection: "拒絕原因",
   Lifting_the_ban: "解除封禁",
   Refused_to_pass: "拒絕通過",
   Please_enter_the_anchor_name: "請輸入主播姓名",
   ID_number: "身份證號",
   Front_photo_of_ID_card: "身份證正面照",
   Back_photo_of_ID_card: "身份證反面照",
   Housing_Management_Uid: "房管Uid",
   Please_enter_the_housing_management_Uid: "請輸入房管Uid",
   Housing_management_nickname: "房管昵稱",
   hint_671: "請輸入房管昵稱",
   Add_property_management: "添加房管",
   Cancel_housing_management: "取消房管",
   Please_enter_your_name: "請輸入姓名",
   hint_670: "姓名請輸入漢字",
   Approved: "審核通過",
   Rejected_for_review: "審核拒絕",
   Prohibited: "已封禁",
   The_ID_photo_is_blurry: "證件照模糊",
   The_ID_number_does_not_match_the_ID_photo: "身份證號碼與證件照不匹配",
   My_ID_card_does_not_match_me: "身份證與本人不匹配",
   ID_card_number_is_blocked: "身份證號碼遮擋",
   Facial_occlusion: "人臉遮擋",
   Room: "房間",
   Please_select_the_reason: "請選擇原因",
   hint_669: "請輸入要替換的公告名稱或連結",
   Are_you_sure_you_want_to_pass_the_review_: "確定要審核通過嗎？",
   hint_301: "確定恢復該主播直播許可權嗎？",
   Are_you_sure_you_want_to_ban_this_anchor_: "確定將該主播封禁嗎？",
   Main_account_UID: "主帳號UID",
   Please_enter_the_main_account_UID: "請輸入主帳號UID",
   Merge_account_UID: "合併帳號UID",
   Please_enter_the_merged_account_UID: "請輸入合併帳號UID",
   Add_Merge: "新增合併",
   Master_account_nickname: "主帳號昵稱",
   Merge_account_nicknames: "合併帳號昵稱",
   Merge_time: "合併時間",
   hint_64: "合併帳號後，被合併主播帳號開播，將使用主帳號進行上播展示",
   Merge_accounts: "合併帳號",
   Refresh: "刷新",
   hint_668: "注：線上資料略有延遲",
   Note: "注",
   Basic_information_1: "基礎資訊",
   Anchor_Information: "主播信息",
   Real_time_live_streaming_data: "即時直播資料",
   hint_667: "當前流資訊不可用！",
   Anchor_Management: "主播管理",
   Merge_anchor_accounts: "主播帳號合併",
   Identify_the_main_switch: "鑒別總開關",
   The_current_status_is: "當前狀態為",
   There_is_currently_no_data_available: "暫無數據",
   Jian_Huang: "鑒黃",
   Fear_prevention: "鑒恐",
   Jianzheng: "鑒政",
   hint_303: "確定要將此直播間斷流嗎？",
   Thermal_value_coefficient: "熱力值係數",
   Heat_value_base: "熱力值基數",
   Modified_by: "修改人",
   Latest_modification_time: "最新修改時間",
   Heat_value_setting: "熱力值設置",
   Role_Name: "角色名稱",
   Whether_to_enable: "是否啟用",
   Live_broadcast_room_type: "直播間類型",
   Initial_heat_value: "初始熱力值",
   Last_editing_time: "最後編輯時間",
   Edit_heat_value: "編輯熱力值",
   Initial_heating_value_: "初始熱力值：",
   Please_enter_the_heat_value: "請輸入熱力值",
   Please_fill_in_the_heat_value: "請填寫熱力值",
   Please_enter_the_anchor_UID: "請輸入主播UID",
   Live_streaming_services: "直播流服務",
   Batch_editing: "批量編輯",
   All_anchors: "全部主播",
   Platform_anchor_stream: "平臺主播流",
   Event_clearance: "賽事清流",
   Anchor_event_flow: "主播賽事流",
   Esports_Entertainment_Stream: "電競娛樂流",
   Live_streaming_2: "正在直播",
   Field: "場",
   hint_665: "確定要將此直播間下播嗎？",
   hint_664: "確定要將此直播間禁播嗎？",
   This_PV: "本場PV",
   UV_in_this_field: "本場UV",
   Peak_value: "峰值",
   Accumulated_viewing_time_minutes_: "累計觀看時長(分鐘)",
   Total_number_of_followers: "總關注數",
   Number_of_followers_in_this_session: "本場關注數",
   The_number_of_levels_taken_in_this_game: "本場取關數",
   Net_increase_in_levels_in_this_game: "本場淨增關數",
   Live_broadcast_session_data_of_anchors: "主播直播場次數據",
   Forbidden_List: "禁言列表",
   Team: "球隊",
   Please_enter_the_team: "請輸入球隊",
   Push_order_status: "推單狀態",
   hint_306: "確認要隱藏該推單嗎？隱藏後前端不可見",
   hint_305: "確認要顯示該推單嗎？顯示後前端可見",
   Please_select_the_correction_result: "請選擇修正結果",
   Reporting_time: "舉報時間",
   Whistleblower_userid: "舉報人userid",
   Enter_the_whistleblower_userid: "輸入舉報人userid",
   Enter_room_number: "輸入房間號",
   Reply_or_not: "是否回復",
   Reason_for_reporting: "舉報原因",
   Reply_content: "回復內容",
   hint_663: "請輸入回復內容(100字以內)",
   The_reply_content_cannot_be_empty: "回復內容不能為空",
   Homepage_Configuration: "首頁配置",
   Level_animation_switch: "等級動畫開關",
   Cat_coin_animation_switch: "貓幣動畫開關",
   Enter_animation_switch_: "進場動畫開關：",
   APP_membership_background: "APP會員背景",
   Membership_Benefits_Background: "會員權益背景",
   APP_H5_Membership_Benefits_Background: "APP/H5會員權益背景",
   Background_of_PC_Membership_Benefits: "PC會員權益背景",
   hint_409: "注：尺寸為926x126",
   VIP_discount_configuration: "vip折扣配置",
   Please_enter_VIP: "請輸入VIP",
   hint_402: "請輸入vip折扣（例如：0.9）",
   hint_403: "請輸入vip折扣名稱（例如：9折）",
   Edit_Channel: "編輯頻道",
   Add_a_new_channel: "新增頻道",
   Channel_avatar: "頻道頭像",
   hint_401: "尺寸：114*114 只能上傳jpg/png檔",
   Channel_Cover: "頻道封面",
   hint_405: "尺寸：522*294 只能上傳jpg/png檔",
   hint_406: "提示：自動截取流畫面，失敗後則使用默認封面",
   hint_407: "確定恢復該頻道直播許可權嗎？",
   hint_408: "確定將該頻道封禁嗎？",
   Schedule_data: "賽程數據",
   Edit_Announcement: "編輯公告",
   Create_Announcement: "創建公告",
   Announcement_type: "公告類型",
   Image_and_text_links: "圖文連結",
   Picture_and_text_messages: "圖文消息",
   Activity_link: "活動連結",
   Announcement_Title: "公告標題",
   Cover_image: "封面圖",
   Whether_to_push: "是否推送",
   Select_content: "選擇內容",
   Activity_Title: "活動標題",
   Event_Introduction: "活動簡介",
   Please_select_the_announcement_type: "請選擇公告類型",
   Please_select_the_publishing_time: "請選擇發佈時間",
   Please_enter_the_announcement_title: "請輸入公告標題",
   Please_enter_the_activity_title: "請輸入活動標題",
   Please_enter_the_announcement_content: "請輸入公告內容",
   Please_fill_in_the_activity_introduction: "請填寫活動簡介",
   Please_enter_the_text_message_content: "請輸入短信內容",
   Send_to: "發送對象",
   Upload_data: "上傳數據",
   Download_List_Excel_Template: "下載名單Excel範本",
   Receive_user_list: "接收用戶列表",
   All_platform_users: "全平臺用戶",
   Not_sent: "未發送",
   Sent: "已發送",
   Cancel_sending: "取消發送",
   Specify_user: "指定用戶",
   Please_enter_the_theme: "請輸入主題",
   Multiple_separated_by_commas: "多個以逗號隔開",
   Template_ID: "範本ID",
   Instant_sending: "即時發送",
   Please_select_template_ID: "請選擇範本ID",
   Are_you_sure_to_send_to_all_users_: "是否確認向所有用戶發送？",
   Sending_successful: "發送成功",
   Channel_template_ID: "管道方範本ID",
   Please_enter_the_channel_template_ID: "請輸入管道方範本ID",
   Template_content: "範本內容",
   Please_enter_template_content: "請輸入範本內容",
   Please_select_a_channel: "請選擇管道",
   Please_enter_your_signature: "請輸入簽名",
   Alibaba_Cloud: "阿裡雲",
   Notification: "通知",
   Marketing: "行銷",
   Verification_code: "驗證碼",
   First_select_the_SMS_type: "先選擇短信類型",
   Please_fill_in_the_channel_template_ID: "請填寫管道方範本ID",
   Message_type: "消息類型",
   New_announcement: "新增公告",
   Official_announcement_title: "官方公告標題",
   Unpublish: "取消發佈",
   Jump_type: "跳轉類型",
   Push_time: "推送時間",
   Please_enter_the_bag_ID: "請輸入錦囊ID",
   Decimal_cannot_be_entered: "不能輸入小數",
   Please_enter_the_H5_link: "請輸入H5連結",
   Push_details: "推送詳情",
   Edit_push: "編輯推送",
   Add_push: "新增推送",
   Push_order_page: "推單頁",
   Please_select_the_push_time: "請選擇推送時間",
   Please_select_the_effective_time: "請選擇生效時間",
   hint_660: "確定撤銷本次推送任務嗎？",
   Add_menu: "新增菜單",
   catalogue: "目錄",
   Menu: "菜單",
   Menu_icons: "功能表圖示",
   Menu_Title: "菜單標題",
   Routing_address: "路由地址",
   Component_Name: "組件名稱",
   Component_path: "元件路徑",
   Whether_to_hide: "是否隱藏",
   Superior_categories: "上級類目",
   Select_superior_category: "選擇上級類目",
   Menu_Name: "菜單名稱",
   Permission_identification: "許可權標識",
   Edit_menu: "編輯功能表",
   Please_enter_the_menu_title: "請輸入功能表標題",
   Please_enter_the_routing_address: "請輸入路由位址",
   Please_enter_the_component_name: "請輸入元件名稱",
   Please_enter_the_component_path: "請輸入元件路徑",
   Please_select_the_superior_category: "請選擇上級類目",
   Top_level_categories: "頂級類目",
   hint_661: "確定要刪除此菜單嗎？",
   Please_enter_the_role_name: "請輸入角色名稱",
   Add_new_role: "新增角色",
   Role_ID: "角色ID",
   Creator: "創建人",
   Authorization: "授權",
   Edit_Role: "編輯角色",
   Audit_scenario: "審核場景",
   Please_enter_the_review_scenario: "請輸入審核場景",
   Review_page: "審核頁面",
   Edit_audit_scenario_account: "編輯審核場景帳號",
   Operations_Manager_Account: "運營負責人帳號",
   Please_enter_the_review_account: "請輸入審核帳號",
   Please_enter_your_account: "請輸入帳號",
   New_users: "新增用戶",
   Bind_characters: "綁定角色",
   Latest_reset_time: "最新重置時間",
   Please_select_a_role: "請選擇角色",
   Input_cannot_be_empty: "輸入不能為空",
   hint_6601: "不能輸入特殊字元及數位",
   Edit_Users: "編輯用戶",
   hint_659: "確定要停用此帳戶嗎？",
   Account_deactivated: "帳戶已停用",
   hint_658: "確定要重置此帳號密碼嗎？",
   Reset_successful: "重置成功",
   The_new_password_is: "新密碼為",
   Gift_Name: "禮物名稱",
   Please_enter_the_gift_name: "請輸入禮物名稱",
   Gift_category: "禮物類別",
   VIP_gift: "VIP禮物",
   Add_gift: "添加禮物",
   Gift_unit_price_in_gold_coins_: "禮物單價(金幣)",
   Banner_gift: "橫幅禮物",
   Full_screen_gift: "全屏禮物",
   Gift_icon: "禮物圖示",
   Size_does_not_exceed_1MB_: "大小不超過1MB！",
   Enable_floating_screen: "啟用飄屏",
   Floating_screen_category: "飄屏類別",
   hint_657: "請選擇飄屏類別",
   Please_select_VIP_level: "請選擇VIP等級",
   hint_656: "該等級及以上VIP使用者可使用",
   hint_601: "最多可輸入4個中文漢字或者8個英文字母或者8個數位",
   Please_enter_the_price: "請輸入價格",
   hint_602: "禮物單價不能大於9999999或小於0",
   Please_upload_the_gift_icon: "請上傳禮物圖示",
   Please_upload_the_playback_effect: "請上傳播放效果",
   Please_upload_the_preview_effect: "請上傳預覽效果",
   Edit_Gift: "編輯禮物",
   Please_choose_a_gift_type: "請選擇禮物類型",
   Please_select_the_floating_screen_type: "請選擇飄屏類型",
   Please_select_whether_combo_is_possible: "請選擇是否可連擊",
   Only_SVGA_files_can_be_uploaded_: "只能上傳svga檔!",
   Only_GIF_files_can_be_uploaded: "只能上傳gif檔",
   hint_66: "下線該禮物則該禮物從禮物倉庫中下線，即配置禮物策略中不出現該禮物，確定下線嗎？",
   Successfully_taken_offline: "下線成功",
   hint_67: "上線該禮物則該禮物重新上線至禮物倉庫，即配置禮物策略中重新出現該禮物，確定上線嗎",
   Gift_data_statistics: "禮物資料統計",
   User_gift_details: "用戶送禮明細",
   Gift_List: "禮物列表",
   Distribution_scope: "下發範圍",
   Effective_time: "生效時間",
   Last_modified_time: "最近修改時間",
   Last_Editor: "最後編輯人",
   hint_68: "請輸入禮物ID，多個禮物之間使用英文逗號隔開，輸入順序則為前端禮物展示順序",
   Sending_gifts: "下發禮物",
   hint_72: "刪除該禮物策略將導致該策略刪除，確定刪除嗎？？",
   hint_70: "下線該禮物策略將導致該策略房間的禮物從前端下線，確定下線嗎？",
   hint_71: "上線該禮物策略則該策略房間的禮物從前端上線，確定上線嗎？",
   Expert_type: "專家類型",
   Import_experts: "導入專家",
   Introduction: "簡介",
   Hit_rate: "命中率",
   Recent_status: "近期狀態",
   The_highest_consecutive_red: "最高連紅",
   hint_604: "近期連紅",
   Number_of_proposals: "方案數量",
   Number_of_buyers: "購買人數",
   Accumulated_total_revenue: "累計總收益",
   Settlable_income: "可結算收益",
   Add_Expert: "添加專家",
   User_userid_cannot_be_empty: "用戶userid不能為空",
   Please_enter_the_league_and_team_name: "請輸入聯賽、球隊名稱",
   Home_team_vs_away_team: "主隊VS客隊",
   League: "聯賽",
   Choose_a_competition: "選擇賽事",
   Event_information: "賽事信息",
   Select_recommendation: "選擇推薦",
   Yazhi: "亞指",
   The_main_winner: "主勝",
   Customer_win: "客勝",
   Small_and_large_balls: "大小球",
   Greater_than: "大於",
   ball: "球",
   European_Compensation: "歐賠",
   A_draw: "平局",
   Wanbo: "萬博",
   Le_Dong: "樂動",
   Macau: "澳門",
   crown: "皇冠",
   hint_655: "請先選擇玩法",
   Add_recommendation_group: "添加推薦組",
   Recommended_group_name: "推薦組名稱",
   Circle_details: "圈子明細",
   Number_of_recommended_circles: "推薦圈子數量",
   Resource_bit_name: "資源位名稱",
   Expert_details: "專家明細",
   Number_of_recommended_experts: "推薦專家數量",
   Pause: "暫停",
   Add_anchors: "增加主播",
   X_connected_red: "X連紅",
   Near_X_in_X: "近X中X",
   Return_rate: "回報率",
   hint_653: "請填寫至少一條完整主播資訊",
   hint_654: "確定推薦該組嗎？",
   hint_652: "確定暫停該推薦組嗎？",
   Up_to_20_can_be_configured: "最多可以配置加20個",
   Edit_selling_price: "編輯售價",
   First_gear: "第一檔",
   Second_gear: "第二檔",
   Third_gear: "第三檔",
   Release_Brocade_Bag: "發佈錦囊",
   Brocade_Bag_Type_: "錦囊類型：",
   Football_recommendations: "足球推薦",
   Basketball_recommendations: "籃球推薦",
   hint_651: "請選擇賽事設置推薦選項",
   Please_enter_the_publisher_account: "請輸入發佈者帳號",
   Please_enter_the_recommendation_reason: "請輸入推薦理由",
   Please_set_the_publishing_time: "請設置發佈時間",
   hint_650: "已結束比賽需要選擇發佈時間",
   Expert_management: "專家管理",
   Popular_experts: "熱門專家",
   Hit: "命中",
   Returned: "已返還",
   To_be_returned: "待返還",
   On_sale: "售賣中",
   Cut_off_orders: "截單",
   Order_status: "訂單狀態",
   Purchase_time: "購買時間",
   Please_enter_the_competition: "請輸入賽事",
   Competition: "賽事",
   Publisher: "發佈人",
   Please_enter_the_publisher: "請輸入發佈人",
   Purchase_successful: "購買成功",
   hint_73: "確定下架嗎？下架後移動端將不出現此條短視頻？",
   Bullet_Screen_Library: "彈幕庫",
   Add_bullet_library: "添加彈幕庫",
   Batch_Import: "批量導入",
   Add_bullet_comments: "添加彈幕",
   Bullet_screen_content: "彈幕內容",
   Recent_Editors: "最近的編輯人",
   Import_data: "導入數據",
   Edit_bullet_screen: "編輯彈幕",
   hint_649: "支持添加多條彈幕，多個彈幕用英文逗號隔開，單次最多添加1000條。",
   Label_One: "標籤一",
   Label_2: "標籤二",
   Label_Three: "標籤三",
   Label_Four: "標籤四",
   Label_Five: "標籤五",
   Please_enter_the_bullet_content: "請輸入彈幕內容",
   hint_648: "上傳大小不能超過 10MB!",
   Successfully_added_barrage: "添加彈幕成功",
   Update_barrage_successful: "更新彈幕成功",
   hint_647: "上傳檔只能是 xls格式!",
   Import_failed: "導入失敗",
   Import_successful: "導入成功",
   hint_646: "確定要刪除此條彈幕嗎？",
   Number_of_fans: "粉絲數",
   Live_broadcast_time: "直播時間",
   Duration_minutes_: "時長(分鐘)",
   Number_of_gift_givers: "送禮人數(個)",
   Number_of_gifts_given: "送禮個數(個)",
   Live_room_revenue_feather_: "直播間收益(羽毛)",
   Guilds: "公會",
   Settlable_income_yuan_: "可結算收益(元)",
   Reward_and_punishment_income_yuan: "獎懲收益(元)",
   Total_revenue_yuan_: "總收益(元)",
   Adjustment_type_: "調整類型：",
   hint_645: "獎懲原因：",
   Settlement_bill_review: "結算帳單審核",
   Originating_Reviewer: "發起審核人",
   Operations_Manager_: "運營負責人",
   Reissue: "重新發起",
   Through: "通過",
   Please_enter_the_amount: "請輸入金額",
   Please_enter_the_reason: "請輸入原因",
   Guild_name: "公會名稱",
   Please_enter_the_guild_name: "請輸入公會名稱",
   Guild_ID: "公會ID",
   Receiving_account_name: "收款帳戶名稱",
   Opening_Bank: "開戶行",
   Bank_card_number: "銀行卡號",
   Account_opening_address: "開戶地址",
   Number_of_anchors: "主播個數",
   Please_fill_in_your_phone_number: "請填寫手機號碼",
   Please_fill_in_your_bank_card_number: "請填寫銀行卡號",
   hint_75: "啟用後將可為主播分配該公會，是否確定啟用此公會",
   Resource_location: "資源位置",
   Is_it_effective_: "是否生效",
   Jump_position: "跳轉位置",
   Please_enter_the_jump_location: "請輸入跳轉位置",
   Please_select_a_cover: "請選擇封面",
   Please_select_a_jump_type: "請選擇跳轉類型",
   Please_select_a_resource_location: "請選擇資源位置",
   hint_76: "注：權重越大當前廣告位越靠前",
   Is_the_browser_open_: "是否流覽器打開",
   hint_644: "確認要啟用該廣告嗎？",
   hint_643: "確認要停用該廣告嗎？",
   hint_642: "確認要移除該廣告位嗎？",
   Add_an_open_screen_advertisement: "新增開屏廣告",
   hint_641: "請保證視頻格式為MP4格式，且不超過5M",
   hint_77: "只能上傳jpg/png/gif檔，圖片尺寸需為750X1624",
   Please_enter_the_link_address: "請輸入連結位址",
   Please_enter_the_live_broadcast_room_ID: "請輸入直播間ID",
   Please_enter_the_information_ID: "請輸入資訊ID",
   Please_enter_the_chat_room_ID: "請輸入聊天室ID",
   The_input_information_format_is_incorrect: "輸入資訊格式有誤",
   Chat_room_ID: "聊天室ID",
   Brocade_Bag_ID: "錦囊ID",
   hint_640: "只能上傳jpg/png/gif檔，建議尺寸為",
   Please_enter_the_sorting_number: "請輸入排序號",
   Sort: "排序",
   The_length_cannot_exceed_20: "長度不能走過20",
   hint_78: "下線後該彈窗從移動端下架，是否確定下線此彈窗？",
   hint_639: "上傳圖片大小不能超過 1MB",
   Page_Name: "頁面名稱",
   Please_enter_the_page_name: "請輸入頁面名稱",
   Parameters: "參數",
   PC_Link: "PC連結",
   H5_Link: "H5連結",
   PC_Preview: "PC預覽",
   H5_Preview: "H5預覽",
   PC_Resource_Map: "PC資源圖",
   H5_Resource_Map: "H5資源圖",
   Please_enter_parameters: "請輸入參數",
   Background_name: "背景名",
   Background_image: "背景圖",
   Set_background_name: "設置背景名",
   Set_sorting: "設置排序",
   Modify_background_image: "修改背景圖",
   Add_background_image: "新增背景圖",
   Please_fill_in_the_background_name: "請填寫背景名稱",
   Please_fill_in_the_sorting: "請填寫排序",
   Successfully_taken_down: "下架成功",
   Successfully_listed: "上架成功",
   Effective: "有效",
   Invalid: "無效",
   hint_606: "頻道開播數量（10分鐘統計一次）",
   Go_live: "上線",
   hint_605: "請設置排序號（1-999)",
   Please_enter_the_tab_name: "請輸入tab名稱",
   hint_79: "只能上傳jpg/png檔，圖片尺寸需為670*376",
   Please_set_the_status: "請設置狀態",
   Are_you_sure_you_want_to_go_online_: "確定要上線嗎",
   Are_you_sure_you_want_to_go_offline_: "確定要下線嗎",
   Anchor_userid: "主播userid",
   Chat_room_title: "聊天室標題",
   Cut_off_flow: "斷流",
   hint_638: "確定對此聊天室斷流嗎？",
   Upload_time: "上傳時間",
   Cover_type: "封面類型",
   Batch_pass: "批量通過",
   Batch_rejection: "批量拒絕",
   Voice_chat_room: "語音聊天室",
   Reject: "拒絕",
   Operated: "已操作",
   Passed: "已通過",
   Live_streaming_3: "直播",
   hint_637: "確定要刪除封面嗎？",
   Music_name: "音樂名",
   Please_enter_the_music_name: "請輸入音樂名稱",
   Author_name: "作者名",
   Please_enter_the_author_name: "請輸入作者名稱",
   Size: "大小",
   Playback_count: "播放次數",
   Trial_listening: "試聽",
   Please_enter_the_song_name: "請輸入歌曲名",
   Song_title: "歌曲名",
   Upload_music_files: "上傳音樂檔",
   Only_supports_MP3_format: "僅支援MP3格式",
   hint_80: "寬高比 60*60",
   Please_upload_music_files: "請上音樂檔",
   Edit_Music: "編輯音樂",
   Add_music: "新增音樂",
   Only_MP3_format_files_can_be_uploaded: "只能上傳mp3格式檔",
   The_size_cannot_exceed_20MB_: "大小不能超過 20MB!",
   hint_635: "確定要上架該音樂嗎？",
   hint_636: "確定要下架該音樂嗎？",
   Add_Recommendation: "新增推薦",
   Please_set_the_sorting_number_1_999_: "請設置排序號（1-999）",
   Editor_recommendation: "編輯推薦",
   UserId_cannot_be_empty: "UserId不能為空",
   Shutdown_time: "關播時間",
   Peak_heat_value: "熱力值峰值",
   Edit_Page: "編輯頁面",
   Add_Page: "添加頁面",
   Upload_at_least_one_resource_image: "至少上傳一端資源圖片",
   hint_81: "發佈後該連結將發佈至線上，是否確定發佈該頁面？",
   hint_82: "下架後該連結將不可線上上訪問，是否確定下架該頁面？",
   Select_Cover: "選擇封面",
   hint_633: "注意：生效時間不可重疊！！！",
   hint_83: "存在不完整資訊，請填寫完整再提交！！",
   hint_634: "上傳圖片大小不能超過 500K!",
   Recommended_image: "推薦圖",
   Display_sorting: "顯示排序",
   Edit_Secondary_Classification: "編輯二級分類",
   Classification: "分類",
   Add_Category: "添加分類",
   Please_enter_the_activity_name: "請輸入活動名稱",
   Activity_status: "活動狀態",
   Image: "圖片",
   Configuration_date: "配置日期",
   Please_enter_the_button_link: "請輸入按鈕連結",
   Button_link: "按鈕連結",
   Button_Text: "按鈕文案",
   hint_607: "請輸入按鈕文案，最大4個字",
   Button_color: "按鈕顏色",
   hint_608: "請輸入按鈕顏色，如#CCCCCC",
   Edit_Configuration: "編輯配置",
   New_configuration: "新增配置",
   hint_609: "請輸入按鈕文案",
   Please_enter_the_button_color: "請輸入按鈕顏色",
   hint_84: "確定要上架此範本嗎，上架後頁面範本會生效",
   hint_85: "確定要下架此範本嗎，下架後頁面按鈕會失效",
   APP_end: "APP端",
   H5_end: "H5端",
   AK_APP_end: "AK-APP端",
   AK_PC_end: "AK-PC端",
   AK_H5_end: "AK-H5端",
   Anchor_details: "主播明細",
   Recommended_number_of_anchors: "推薦主播數量",
   Anchor_: "主播{num}",
   Please_enter_the_anchor_ID: "請輸入主播ID",
   Please_fill_in_the_resource_slot_name: "請填寫資源位名稱",
   Please_fill_in_at_least_one_anchor_information: "請填寫至少一條主播資訊",
   Secondary_classification_valid_all: "二級分類(有效/全部)",
   SM_static_resources: "SM靜態資源",
   AK_static_resources: "AK靜態資源",
   More_Jumps: "更多跳轉",
   Editorial_Expert_Recommendation_Group: "編輯專家推薦組",
   Add_expert_recommendation_group: "新增專家推薦組",
   Near_0_in_0: "近0中0",
   Highest: "最高",
   Lian_Hong: "連紅",
   Recently: "近期",
   Permissions: "許可權",
   Are_you_sure_you_want_to_turn_it_on_: "確定開啟嗎？",
   Are_you_sure_you_want_to_close_it_: "確定關閉嗎？",
   Live_broadcast_room_name: "直播間名稱",
   Please_select_sorting: "請選擇排序",
   Edit_popular_recommendations: "編輯熱門推薦",
   Add_popular_recommendations: "新增熱門推薦",
   Advertising_name: "廣告名稱",
   Resource_type: "資源類型",
   Video_upload: "視頻上傳",
   Your_browser_does_not_support_video_playback: "您的流覽器不支援視頻播放",
   Re_upload: "重新上傳",
   Please_upload_the_video: "請上傳視頻",
   Offline: "已下線",
   Square_banner: "廣場banner",
   Page: "頁面",
   Information_details: "資訊詳情",
   Edit_open_screen_advertisement: "編輯開屏廣告",
   Please_select_a_resource_type: "請選擇資源類型",
   Please_select_an_open_screen_advertisement: "請選擇開屏廣告",
   The_uploaded_video_size_cannot_exceed_5MB_: "上傳視頻大小不能超過5MB哦",
   Please_upload_the_correct_video_format: "請上傳正確的視頻格式",
   Add_patches: "新增貼片",
   Display_location: "展示位置",
   Please_enter_the_title_of_the_patch_advertisement: "請輸入彈跳式廣告標題",
   hint_86: "只能上傳jpg/png檔，圖片尺寸需為100X100",
   Edit_patch_ads: "編輯彈跳式廣告",
   Add_patch_ads: "新增彈跳式廣告",
   Please_enter_the_advertisement_title: "請輸入廣告標題",
   Please_select_a_display_location: "請選擇展示位置",
   Recommended_by_experts_on_the_homepage: "首頁專家推薦",
   Live_streaming_page_anchor_recommendation: "直播頁主播推薦",
   hint_87: "上傳圖片只能是 JPG、PNG或gif格式!",
   Pop_up_name: "彈窗名稱",
   APP_Advertising: "APP廣告",
   Open_screen_advertising: "開屏廣告",
   Patch_advertising: "彈跳式廣告",
   Activity_pop_up: "活動彈窗",
   hint_611: "注：上架運營全部功能都已開放",
   Assistant: "小助手",
   Homepage_Live: "首頁直播",
   Live_broadcast_room_announcement: "直播間公告",
   Advertising_resource_allocation: "廣告資源位元配置",
   hint_610: "開啟則請求舊的廣告資源位素材",
   hint_88: "注：官方審核期間，部分功能未開放",
   APP_Brand_Name_: "APP品牌名稱：",
   Please_enter_the_APP_brand_name: "請輸入APP品牌名稱",
   Please_enter_contact_customer_service: "請輸入聯繫客服",
   hint_89: "上傳圖片尺寸有誤，請按格式要求上傳",
   Switch_initialization: "開關初始化",
   Version_configuration: "版本配置",
   Review_status: "審核狀態",
   Operation_status: "運營狀態",
   AppStore_version_number: "AppStore版本號",
   Please_enter_the_version_number: "請輸入版本號",
   Version_status: "版本狀態",
   Update_instructions: "更新說明",
   Configure_time: "配置時間",
   Please_enter_the_interface_version_number: "請輸入介面版本號",
   hint_90: "介面版本號由技術提供，不可私自訂！",
   Please_enter_update_instructions: "請輸入更新說明",
   Please_select_version_status: "請選擇版本狀態",
   Please_enter_the_AppStore_version_number: "請輸入AppStore版本號",
   Please_enter_the_short_video_title: "請輸入短視頻標題",
   Edit_Title: "編輯標題",
   Short_video_title_: "短視頻標題：",
   hint_91: "發佈該條短視頻至移動端，是否確認發佈？",
   Crawling_website_sites: "爬取網站網站",
   Statistical_time: "統計時間",
   UV_data: "uv數據",
   Platform: "平臺",
   Add_circle_recommendations: "新增圈子推薦",
   Are_you_sure_you_want_to_pause_: "是否確定暫停？",
   Access_date: "訪問日期",
   Posting_time: "發帖時間",
   Poster_nickname: "發帖人昵稱",
   Interactive_red_envelopes: "互動紅包",
   Topic_Name: "話題名稱",
   Please_enter_the_topic_name: "請輸入話題名稱",
   hint_632: "話題熱度(10分鐘統計一次)",
   Accumulated_number_of_related_posts: "累計相關帖子數",
   Save_and_publish: "保存並發佈",
   Recommendation: "推薦",
   Add_recommended_topics: "新增推薦話題",
   Number_of_participants_in_the_topic: "話題參與人數",
   Number_of_topic_views: "話題流覽次數",
   Topic_release_time: "話題發佈時間",
   Topic_Top_Time: "話題置頂時間",
   Choose_a_topic: "選擇話題",
   Enter_the_topic_name_you_want_to_recommend: "輸入需要推薦的話題名稱",
   Editor_recommended_topic: "編輯推薦話題",
   The_topic_cannot_be_empty: "話題不能為空",
   hint_631: "請輸入話題名稱（15字以內）",
   hint_630: "請輸入話題簡介（100字以內）",
   Successfully_created_topic: "創建話題成功",
   Recharge: "充值",
   Consumption: "消費",
   Revenue: "收益",
   Order_number_1: "訂單編號",
   Recharge_amount: "充值金額",
   Consumption_type: "消費類型",
   Consumption_amount: "消費金額",
   Revenue_type: "收益類型",
   Revenue_amount: "收益金額",
   Additional_issuance: "增發",
   Uploading: "上傳中。。。",
   Batch_issuance: "批量增發",
   Download_batch_issuance_templates: "下載批量增發範本",
   Additional_currency_issuance: "增發貨幣",
   Increase_experience_value_of_issuance: "增發經驗值",
   Please_enter_user_userid: "請輸入用戶userid",
   Please_enter_the_quantity_of_coins: "請輸入金幣數量",
   Please_enter_a_note: "請輸入備註",
   VIP_level: "VIP等級",
   Additional_issuance_time: "增發時間",
   Are_you_sure_you_want_to_refuse_: "確定要拒絕嗎？",
   Personal_currency_issuance: "個人貨幣增發",
   VIP_currency_issuance: "VIP貨幣增發",
   Total_consumption_in_gold_coins: "總消耗（金幣）",
   Total_rechargeyuan: "總充值（元）",
   Number_of_Rechargers: "充值人數",
   Recharge_switch: "充值開關",
   RMB_amount_yuan_: "人民幣金額（元）",
   Number_of_gold_coins: "金幣數量",
   Edit_recharge_configuration: "編輯充值配置",
   Add_recharge_configuration: "新增充值配置",
   Recharge_time: "充值時間",
   Payment_channels: "支付管道",
   Please_enter_the_order_number_1: "請輸入訂單編號",
   Payment_time: "支付時間",
   Order_time: "訂單時間",
   Payment_amount_yuan: "支付金額（元）",
   Balance_inquiry: "餘額查詢",
   Ordinary_user_currency_balance: "普通用戶貨幣餘額",
   Details_1: "明細",
   Football_quiz_activity: "足球答題活動",
   Football_winning_statistics: "足球中獎統計",
   Basketball_quiz_activity: "籃球答題活動",
   Basketball_winning_statistics: "籃球中獎統計",
   Guessing_question_name: "競猜答題名稱",
   Please_enter_the_name_of_the_quiz_question: "請輸入競猜答題名稱",
   Start_time: "開始時間",
   Add_a_question: "新增答題",
   Rule_configuration: "規則配置",
   Style_Configuration: "樣式配置",
   Number_of_periods: "期數",
   Reward_base: "獎勵基數",
   Stop: "停止",
   End: "結束",
   Title: "題目",
   The_problem_name_cannot_be_empty: "問題名稱不能為空",
   Please_enter_the_question_name: "請輸入問題名稱",
   Please_enter_question_options: "請輸入問答題選項",
   question_options_1: "答題選項",
   Add_Options: "添加選項",
   Please_enter_the_number_of_currencies_for_the_reward_base: "請輸入獎勵基數的貨幣個數",
   Save: "保 存",
   Cancel: "取 消",
   Please_submit_results_with_caution: "請謹慎提交結果",
   The_answer_to_the_question_cannot_be_empty: "問題答案不能為空",
   Flow: "流局",
   Activity_Rule_Configuration: "活動規則配置",
   Rule_configuration_cannot_be_empty: "規則配置不能為空",
   Limited_to_200_words_in_length: "限長200個字",
   Guessing_Style_Configuration: "竟猜樣式配置",
   PC_Banner_diagram: "PC Banner圖",
   PC_Banner_image_cannot_be_empty: "PC Banner圖不能為空",
   hint_612: "尺寸：1920x624 只能上傳jpg/png檔",
   H5_APP_Banner_image: "H5/APP Banner圖",
   H5_APP_Banner_image_cannot_be_empty: "H5/APP Banner圖不能為空",
   hint_613: "尺寸：750x1012 只能上傳jpg/png檔",
   Theme_color: "主題色",
   The_theme_color_cannot_be_empty: "主題色不能為空",
   hint_619: "頁面底部的顏色配置",
   Event_details: "活動詳情",
   Correct_answer: "正確答案",
   Stopped: "已停止",
   Add_a_guessing_activity: "新增競猜活動",
   hint_618: "請填寫競猜答題名稱",
   Please_select_the_effective_time_range: "請選擇生效時間範圍",
   Please_fill_in_the_reward_base: "請填寫獎勵基數",
   hint_92: "取消後則不發佈該期答題活動，是否確定取消該期競猜答題活動?",
   Success: "成功",
   Cancel_successful: "取消成功",
   hint_93: "停止後用戶將無法參與競猜答題，是否確定停止競猜答題活動",
   hint_614: "請輸入1-99999正整數的獎勵基數",
   Cannot_select_past_time: "不可選擇過去時間",
   hint_615: "是否確定公佈答案？",
   Save_successful: "保存成功",
   hint_616: "題目{num} 的答案選項最少設置2個選項",
   Number_of_participants: "參與人數",
   Number_of_winners: "中獎人數",
   hint_617: "獎勵個數（金幣）",
   Question_Name: "問題名稱",
   Number_of_correct_answers: "答對人數",
   Activity_List: "活動列表",
   Top_of_the_event: "活動置頂",
   PC_end_carousel: "PC端輪播",
   AK_Activity_List: "AK-活動列表",
   AK_Activity_Topping: "AK-活動置頂",
   AK_PC_end_carousel: "AK-PC端輪播",
   Create_activity_records: "創建活動記錄",
   Event_main_title: "活動主標題",
   Activity_subtitle: "活動副標題",
   PC_thumbnail: "PC端縮略圖",
   App_thumbnail: "App縮略圖",
   Activity_time: "活動時間",
   Long_term_activities: "長期活動",
   Please_enter_the_main_title_of_the_activity: "請輸入活動主標題",
   Please_enter_the_activity_subtitle: "請輸入活動副標題",
   PC_activity_redirect_address: "PC活動跳轉地址",
   Activity_jump_button_background: "活動跳轉按鈕背景",
   App_side_thumbnail: "App端縮略圖",
   hint_901: "大小355*148px",
   App_activity_redirect_address: "App 活動跳轉地址",
   Time_type: "時間類型",
   There_is_a_time_limit: "有時間限制",
   Edit_activity_records: "編輯活動記錄",
   hint_629: "確定置頂此條活動記錄嗎？",
   hint_620: "確定取消置頂此條活動記錄嗎？",
   Please_set_the_activity_time: "請設置活動時間",
   Jump_URL: "跳轉URL",
   Queue: "佇列",
   Cover: "封面",
   Mobile_download_page: "移動端下載頁",
   PC_Download_Page: "PC端下載頁",
   Android_copywriting: "安卓文案",
   hint_94: "請輸入文案，最大支援10個字元",
   IOS_Web_App_Copywriting: "iOS-網頁版APP文案",
   IOS_copywriting: "iOS文案",
   PC_background_configuration: "PC背景配置",
   Group_stage_competition: "小組賽",
   "1_8_elimination_round": "1/8淘汰賽",
   "1_4_elimination_round": "1/4淘汰賽",
   Semi_finals: "半決賽",
   Finals: "決賽",
   Location: "地點",
   Facing_each_other: "對陣",
   hint_95: "請輸入主播房間號並用逗號隔開，最多四位",
   Fen: "分",
   hint_628: "最多只能選擇4個主播房間號",
   SM_redemption_page: "SM兌換頁面",
   AK_redemption_page: "AK兌換頁面",
   H5_end_thumbnail: "H5端縮略圖",
   hint_96: "只能上傳jpg/png/gif檔，尺寸1440*315",
   Category_ID: "類別ID",
   Classification_name: "分類名稱",
   Please_enter_the_category_name: "請輸入分類名稱",
   Thai_tag_name: "泰語標籤名稱",
   Please_enter_the_Thai_tag_name: "請輸入泰語標籤名稱",
   English_tag_name: "英語標籤名稱",
   Please_enter_the_English_tag_name: "請輸入英語標籤名稱",
   Vietnamese_label_name: "越南語標籤名稱",
   Please_enter_the_Vietnamese_label_name: "請輸入越南語標籤名稱",
   Korean_tag_name: "韓語標籤名稱",
   Please_enter_the_Korean_tag_name: "請輸入韓語標籤名稱",
   Brazilian_label_name: "巴西語標籤名稱",
   Please_enter_the_Brazilian_label_name: "請輸入巴西語標籤名稱",
   Add_a_new_category: "新增分類",
   hint_888: "精品好物",
   Please_select_the_status: "請選擇狀態",
   hint_627: "是否確定上線該商品？",
   hint_626: "是否確定下線該商品分類？",
   Warning: "警告",
   hint_97: "分類下含有所屬商品，不可下線",
   Edit_Classification: "編輯分類",
   Redemption_page_BANNER: "兌換頁BANNER",
   hint_625: "上傳圖片大小不能超過 5M!",
   Delisted_products: "下架商品",
   Listing_products: "上架商品",
   Classification_management: "分類管理",
   Label_management: "標籤管理",
   Order_List: "訂單清單",
   Product_ID: "商品ID",
   Please_enter_the_product_ID: "請輸入商品ID",
   Product_name: "商品名稱",
   Please_enter_the_product_name: "請輸入商品名稱",
   Do_we_need_delivery_: "是否需配送",
   Switch: "開關",
   Unit_price: "單價",
   Discounted_price: "折後價",
   Current_inventory: "當前庫存",
   Redeemed: "已兌換",
   Inventory_changes: "庫存變動",
   Select_thumbnail: "選擇縮略圖",
   Details_page_image: "詳情頁圖",
   Select_detailed_image: "選擇詳情圖",
   Details: "詳情說明",
   Please_enter_the_unit_price: "請輸入單價",
   Discounts: "打折價",
   Please_enter_the_discounted_price_of_the_product: "請輸入商品打折價",
   Please_select_a_category: "請選擇類別",
   Label: "標籤",
   Product_purchase_restriction_period: "商品限購週期",
   No_purchase_restrictions: "不限購",
   Day: "日",
   Week: "周",
   Cumulative: "累計",
   Product_purchase_limit: "商品限購數",
   hint_98: "請輸入週期內的商品限購數",
   Inventory: "庫存",
   Add: "增加",
   Reduce: "減少",
   The_product_name_cannot_be_empty: "商品名稱不可為空",
   Boutique: "精品",
   Life: "生活",
   Product_addition: "商品新增",
   Please_upload_thumbnail: "請上傳縮略圖",
   Please_upload_detailed_images: "請上傳詳情圖",
   Please_select_whether_delivery_is_required: "請選擇是否需配送",
   Purchase_unit_price_cannot_be_empty: "購買單價不可為空",
   Classification_cannot_be_empty: "分類不可為空",
   hint_99: "商品限購數不能為空",
   Details_cannot_be_empty: "詳情說明不能為空",
   Please_select_increase_decrease: "請選擇增加減少",
   Are_you_sure_you_want_to_save_: "是否確定保存？",
   Cat_coin_price: "貓幣價",
   Diamond_price: "鑽石價",
   Thai_product_name: "泰語商品名稱",
   Please_enter_the_Thai_product_name: "請輸入泰語商品名稱",
   English_product_name: "英語商品名稱",
   Please_enter_the_English_product_name: "請輸入英語商品名稱",
   Korean_product_name: "韓語商品名稱",
   Please_enter_the_Korean_product_name: "請輸入韓語商品名稱",
   Brazilian_product_name: "巴西語商品名稱",
   Please_enter_the_Brazilian_product_name: "請輸入巴西語商品名稱",
   hint_902: "尺寸建議174*174",
   hint_903: "尺寸建議375*375",
   Thai_language_details: "泰語詳情說明",
   English_detailed_explanation: "英語詳情說明",
   Vietnamese_language_details: "越南語詳情說明",
   Korean_language_details: "韓語詳情說明",
   Brazilian_language_details: "巴西語詳情說明",
   Please_enter_the_diamond_price_of_the_product: "請輸入商品鑽石價",
   Are_you_sure_you_want_to_delist_this_product_: "是否確定下架該商品？",
   Label_ID: "標籤ID",
   Please_enter_the_label_name: "請輸入標籤名稱",
   Label_Name: "標籤名稱",
   hint_624: "是否確定上線該商品標籤？",
   hint_101: "是否確定下線該商品標籤？",
   Edit_label: "編輯標籤",
   Order_number: "訂單號",
   Please_enter_the_order_number: "請輸入訂單號",
   Transaction_time: "交易時間",
   Receiving_address: "收貨地址",
   Signee: "簽收人",
   Transaction_volume: "交易量",
   Total_transaction_amount: "交易總額",
   Transaction_status: "交易狀態",
   Unsuccessful: "不成功",
   Limited_to_50_words_in_length: "限長50個字",
   Inviter_UID: "邀請人UID",
   Inviting_person_nickname: "邀請人昵稱",
   Invitee_UID: "被邀請人UID",
   Invitee_registration_time: "被邀請人註冊時間",
   Please_enter_the_inviter_reward: "請輸入邀請人獎勵",
   Please_enter_the_invitee_reward: "請輸入被邀請人獎勵",
   Rule_Description: "規則說明",
   APP_landing_page_background_image: "APP落地頁背景圖",
   hint_621: "尺寸：710X1086 只能上傳jpg/png檔",
   Watch_the_live_broadcast: "觀看直播",
   Live_room_speech: "直播間發言",
   Push_order_configuration: "推單配置",
   hint_102: "友情提示：1-發言配置請按照順序依次進行配置。如配置到第2句，則僅會執行1，2發言配置內容。",
   hint_905: "第{num}次發言配置",
   Reward_amount: "獎勵金額",
   Please_enter_the_speech_reward_amount: "請輸入發言獎勵金額",
   Valid_characters: "有效字元",
   hint_623: "請輸入發言有效字元",
   hint_103: "第一次發言配置獎勵金額不能為空",
   hint_104: "第二次發言配置獎勵金額不能為空",
   hint_105: "第三次發言配置獎勵金額不能為空",
   Friendly_reminder: "友情提示",
   hint_1: "1-推單數據及使用者參與資料可前往 直播管理》直播間推單 模組查看；",
   hint_2: "2-推單獎勵區間：主播推單的獎勵會隨機在該區間生成；",
   hint_3: "3-用戶每日獎勵峰值：超過該峰值則繼續參與推單，不可獲得獎勵；",
   hint_889: "推單獎勵區間",
   Maximum_reward: "最大獎勵",
   Minimum_reward: "最小獎勵",
   Users_get_peak_daily: "用戶每日獲得峰值",
   hint_622: "請輸入用戶每日最大獲取值",
   Please_enter_the_maximum_reward: "請輸入最大獎勵",
   Please_enter_the_minimum_reward: "請輸入最小獎勵",
   Please_enter_the_user_daily_peak_gain: "請輸入用戶每日獲得峰值",
   Viewing_duration: "觀看時長",
   hint_107: "請輸入觀看時長，需為正整數",
   Watch_rewards: "觀看獎勵",
   Please_enter_the_viewing_reward: "請輸入觀看獎勵",
   Reward_channels: "獎勵管道",
   hint_106: "開通後在該管道觀看直播會獲得獎勵，不同管道觀看僅獎勵一次；",
   Please_enter_the_viewing_duration: "請輸入觀看時長",
   Please_select_a_reward_channel: "請選擇獎勵管道",
   First_line_copy: "第一行文案",
   hint_108: "請輸入文案，最大支援10字元",
   Second_line_copy: "第二行文案",
   Please_enter_the_first_line_of_text: "請輸入第一行文案",
   Please_enter_the_second_line_of_text: "請輸入第二行文案",
   Effective_date: "有效日期",
   Display_copy: "展示文案",
   Gift_channels: "贈送管道",
   Login_rewards: "登陸獎勵",
   Accumulated_Gifts: "累計贈送",
   Additional_rewards_for_app_login: "APP登陸額外獎勵",
   hint_109: "請輸入登陸獎勵，為0則不獎勵",
   Please_enter_the_reward_amount: "請輸入獎勵金額",
   Login_Activity_Rewards: "登錄活動獎勵",
   Please_enter_display_copy: "請輸入展示文案",
   Please_enter_the_login_reward: "請輸入登陸獎勵",
   Please_enter_a_valid_date: "請輸入有效日期",
   Please_enter_to_select_the_gift_channel: "請輸入選擇贈送管道",
   Login_reward_activity: "登陸獎勵活動",
   Download_copy_configuration: "下載文案配置",
   Lottery_time: "抽獎時間",
   Activity_ID: "活動id",
   Accessing_Users: "訪問用戶",
   Participating_users: "參與用戶",
   Data_details: "數據明細",
   Channel_sources: "管道來源",
   Click_on_the_number_of_lucky_draws: "點擊抽獎次數",
   Page_visits: "頁面訪問次數",
   Number_of_times_participating_in_the_lottery: "參與抽獎次數",
   Winning_users: "中獎用戶",
   Users_who_win_physical_prizes: "實物中獎用戶",
   Click_to_go_to_the_assistant_user: "點擊去小助手用戶",
   Click_to_download_app_users: "點擊下載APP用戶",
   Award_pool_type: "獎池類型",
   Lucky_Wheel: "幸運轉盤",
   Super_Wheel: "超級轉盤",
   Lottery_Record_Daily_Turntable: "抽獎記錄-日常轉盤",
   Physical_rewards: "實物獎勵",
   Draw_lottery_tickets: "抽獎券",
   Processing_personnel: "處理人",
   Not_issued: "未發放",
   Issued: "已發放",
   Virtual: "虛擬",
   Operation_turntable: "運營轉盤",
   Activity_Management: "活動管理",
   Lottery_record: "抽獎紀錄",
   Operation_turntable_activity_data: "運營轉盤活動資料",
   Activity_type: "活動類型",
   Activity_carousel: "活動轉盤",
   Daily_turntable: "日常轉盤",
   Activity_statistics: "活動統計",
   Number_of_super_lottery_tickets_distributed: "超級抽獎券發放數量",
   Lucky_Wheel_Lucky_Draw_Times: "幸運轉盤抽獎次數",
   Super_Wheel_Lottery_Times: "超級轉盤抽獎次數",
   Basic_information: "基本資訊",
   Activity_Rules: "活動規則",
   Please_enter_activity_rules: "請輸入活動規則",
   PC_banner_image: "pc banner圖",
   H5_Jump_Page: "h5跳越網頁面",
   In_use: "使用中",
   Total_inventory: "總庫存",
   Prizes: "獎品",
   Single_day_upper_limit: "單日上限",
   Individual_users_can_receive_upper_limits: "單個用戶獲得上限",
   Lucky_draw_probability_in_tens_of_thousands_: "抽獎概率(萬分）",
   Is_it_a_running_horse_lamp_: "是否跑馬燈",
   Is_it_a_bottom_line: "是否兜底",
   Add_prize_pool: "添加獎池",
   Cloning: "克隆",
   Single_consumption: "單次消耗",
   Daily_limit: "每日上限",
   Activity_channels: "活動管道",
   H5_banner_diagram: "h5 banner圖",
   How_to_earn_cat_coins: "如何賺貓幣",
   Please_select_the_activity_time_range: "請選擇活動時間範圍",
   Edit_anchor_recommendation_group: "編輯主播推薦組",
   Add_a_new_anchor_recommendation_group: "新增主播推薦組",
   Please_upload_the_PC_banner_image: "請上傳pc banner圖",
   Please_upload_the_h5_banner_image: "請上傳h5 banner圖",
   Please_enter_PC_Jump_Page: "請輸入pc跳越網頁面",
   Please_enter_h5_to_jump_to_the_page: "請輸入h5跳越網頁面",
   Please_enter_a_single_consumption: "請輸入單次消耗",
   Please_enter_the_daily_limit: "請輸入每日上限",
   Please_select_the_activity_channel: "請選擇活動管道",
   Prize_Pool: "獎池",
   hint_110: "獎池開啟時間不可早于活動開啟時間",
   hint_111: "獎池結束時間不可晚於活動結束時間",
   The_total_probability_does_not_equal_10000: "概率總和不等於10000",
   Sum_of_probabilities: "概率總和",
   Please_select_the_prize_for_prize_: "請選擇獎品{num}的獎品",
   Please_enter_the_total_inventory_of_prize_: "請輸入獎品{num}的總庫存",
   _The_daily_limit_is_greater_than_the_total_inventory: "{num}單日上限大於總庫存",
   hint_112: "請輸入獎品{num}的抽獎概率(萬分）",
   hint_115: "請選擇獎品{num}的是否跑馬燈",
   hint_116: "每個獎池必須選擇一個兜底獎品!",
   hint_114: "請填寫獎池的生效時間",
   hint_113: "是否確認刪除該獎池?",
   Data_Overview: "數據概覽",
   Quantity_of_currency: "貨幣數量",
   Please_enter_the_quantity_of_currency: "請輸入貨幣數量",
   Number_of_lottery_tickets: "獎券數量",
   Please_enter_the_number_of_lottery_tickets: "請輸入獎券數量",
   Select_icon: "選擇icon",
   Please_select_the_type_of_prize: "請選擇獎品類型",
   Please_upload_the_icon: "請上傳icon",
   Editing_prizes: "編輯獎品",
   Please_enter_a_positive_integer: "請輸入正整數",
   single_1: "個",
   submit_results_1: "提交結果",
   rules_options: "規則配置",
   rules_options_1: "規則配置不能為空",
   edit_activity_cai: "編輯競猜活動",
   created_topic_1: "創建話題",
   // 新增
   created_topic_2: "編輯話題",
   briefing_topic_1: "話題簡介",
   briefing_topic_2: "話題縮略圖",
   hint_899: "只能上傳jpg/png檔,限制1M,大小790*215px",
   hint_907: "只能上傳jpg/png檔,限制1M,大小150*58px",
   hint_917: "只能上傳jpg/png檔,限制1M,大小355*148px",
   hint_918: "圖片尺寸建議為256x256,只能上傳jpg/png檔",
   lookDetail: "查看",
   text_1: "是否確定下架？",
   text_2: "是否確定上架？",
   // 新增2
   matchPlan_1: '比賽進度',
   group_1: '小組',
   shopEdit: '商品編輯',
   viShopEditName: '越南語商品名稱',
   viShopEditName1: '請輸入越南語商品名稱',
   hint_1001: '購買貓幣不可為空',
   hint_1002: '購買鑽石不可為空',
   hint_1003: '請輸入貓幣',
   // 新增3
   remark_edit: '備註編輯',
   currency_cat: '貓幣',
   diamond_1: '鑽石',
   Invitee_UID_1: "邀請人UID",
   Invitee_edit_1: "邀請配置",
   minute_1: "分鐘",
   establish_1: "創建",
   hint_1005: "足球中獎統計資料",
   hint_1006: "籃球中獎統計資料",
   hint_1007: "中獎詳情",
   hint_1008: "始",
   hint_1009: "止",
   hint_1011: "未填寫",
   hint_1012: "選擇banner圖",
   hint_1013: "pc跳越網頁面",
   hint_1014: "使用",
   hint_1015: "獎品ID",
   hint_1016: "抽獎券",
   hint_1017: "連結",
   hint_1018: "資訊ID",
   hint_1019: "上傳音樂縮略圖",
   hint_1021: "英文字母等級限制",
   hint_1022: "直播間推單自動彈出",
   advertising_title: "廣告標題",
   Author_name_1: "作者",
   // 新增4
   text_7: '用戶私信主播',
   text_8: '主播私信用戶',
   text_9: '用戶私信管理員',
   text_10: '私聊主播等級',
   text_11: '下載頁頂部Logo',
   text_12: 'APP/H5專輯頁狀態',
   text_13: 'IOS三倍圖',
   text_14: '第一張',
   text_15: '第二張',
   text_16: '第三張',
   text_17: '第四張',
   text_18: '第${num}張內容不能為空',
   text_19: '第${num}張圖片不能為空',
   text_20: '圖片尺寸建議為40x40,只能上傳jpg/png/gif檔',
   text_21: '圖片尺寸建議為40x40,只能上傳jpg/png/gif檔',
   text_22: 'PC專輯頁名稱',
   text_23: '請輸入src對應的url,如需統計多個功能變數名稱，請用||區分',
   text_24: '關於頁面文案配置',
   text_25: '請填寫文案內容',
   text_26: '請輸入開發者帳號',
   text_27: '新手引導頁',
   text_28: '不用更新',
   text_29: '可選更新',
   text_30: '強制更新',
   text_31: '公開金鑰',
   text_32: '私密金鑰',
   text_33: '功能說明',
   text_34: 'Android配置',
   text_35: '直播間發送紅包',
   text_36: '直播間送禮',
   text_37: '啟動APP埋點上報',
   text_38: '功能變數名稱類型',
   text_39: '編輯功能變數名稱',
   text_40: '添加功能變數名稱',
   have_1: '有',
   have_2: '無',
   updateTime_1: '更新時間',
   text_41: '確定要刪除該功能變數名稱嗎？',
   text_42: '解禁後則該詞可繼續在平臺發送,確認解禁嗎？',
   text_43: '直播間banner url',
   text_44: 'H5banner',
   text_45: '請輸入description',
   text_46: '請輸入首頁 title',
   text_47: '請輸入直播頁 title',
   text_48: '只能上傳jpg/jpeg/png檔,最多支援上傳9張',
   text_49: '上傳視頻檔',
   text_50: '請輸入圈子id',
   text_51: '支援格式：JPG、PNG、GIF、MP4，大小：3M以內:',
   text_52: '未發佈',
   text_53: '請輸入簡介',
   text_54: '只能上傳jpg/png/gif檔,限制1M,大小790*215px',
   text_55: '設置人數上限',
   text_56: '投稿時間',
   text_57: '請設置人數上限',
   text_58: '人數上限不得小於50人',
   text_59: '真實姓名',
   text_60: '已同意',
   text_61: '創建圈子數',
   text_62: '圈子用戶數',
   text_63: '加入時間',
   text_64: '身份證號碼：',
   text_65: '請輸入手機號碼',
   text_66: '提交人',
   text_67: '體育類',
   text_68: '娛樂類',
   text_69: '其他',
   text_70: '只能上傳jpg/png檔，圖片尺寸需為120X120',
   text_71: '添加頻道',
   text_72: '全部頻道',
   channel_1: '頻道',
   text_73: '指定房間',
   text_74: '上線成功',
   text_75: '位置',
   text_76: 'PC背景圖',
   text_77: 'PC標題圖',
   text_78: '玩法說明',
   text_79: '觀看直播時長(分鐘)',
   text_80: '概率',
   text_81: '0為不不可能抽中紅包，此為用戶抽中有獎紅包的概率',
   text_82: '簽到時間',
   text_84: '獎勵狀態',
   text_85: '派發獎勵',
   text_86: '確定派發獎勵嗎？',
   text_87: '簽到類型',
   text_88: '發送人userid',
   text_89: '請輸入發送人userid',
   text_90: '紅包ID',
   text_91: '發送人昵稱',
   text_92: '發起房間號',
   text_93: '請輸入發送房間號',
   text_94: '紅包檔位',
   text_95: '領取個數',
   text_96: '失效個數',
   text_97: '發送時間',
   text_98: '已啟用',
   text_99: '主題ID',
   text_101: '輸贏',
   text_102: '紅包雨ID',
   text_103: '紅包總數',
   text_104: '生效',
   text_105: '失效',
   text_106: '綁定賽事/主播',
   text_107: '已領紅包個數',
   text_108: '領取人數',
   text_110: '活動',
   text_111: '領取',
   text_112: '房間',
   text_113: '平臺累計分成',
   text_114: '登出時間',
   text_115: '註冊IP',
   text_116: '線上時長',
   text_117: '派發狀態：',
   text_118: '更改時間',
   text_119: '場次ID',
   text_120: '直播比賽ID',
   text_121: '玩法',
   text_122: '推單內容',
   text_123: '賽段',
   text_124: '支持',
   text_125: '主播結果',
   text_126: '反對',
   text_127: '修正結果',
   text_128: '獎勵數額',
   text_129: '總發放',
   text_130: '舉報人',
   text_131: '描述',
   text_132: '回復',
   text_133: 'APP背景',
   // 新增5
   live_time: '直播時長(分鐘)',
   Please_select_the_results: '請選擇本單的結果',
   red_1: '紅',
   black_1: '黑',
   go_1: '走',
   corner: '角球',
   win_or_lose: '讓分勝負',
   Size_division: '大小分',
   take_care_1: '注：尺寸為572x420',
   take_care_2: '注：尺寸為926x126',
   discount_1: '折扣',
   discount_name_1: '折扣名稱',
   text_135: '請輸入頻道昵稱',
   characters_1: '文字',
   picture_1: '圖片',
   Please_fill_in_the_link_1: '請填寫連結',
   Please_fill_in_the_link_2: '請正確填寫連結',
   publish_this_announcement: '是否確定發佈該公告？',
   send_state: '發送狀態',
   Create_marketing_messages: '創建行銷短信',
   SMS_content: '短信內容',
   View_receiving_users: '查看接收用戶',
   Upload_list: '上傳名單',
   Are_you_sure_to_cancel: '是否確認取消',
   User_Import_Template: '使用者導入範本',
   channel_2: '管道',
   autograph: '簽名',
   SMS_type: '短信類型',
   SMS_template_editing: '短信範本編輯',
   SMS_template_add: '短信範本新增',
   Editing_time: '編輯時間',
   Instant_push: '即時推送',
   Timed_push: '定時推送',
   Page_H5: 'H5頁',
   Menu_Type: '菜單類型',
   Role_authorization: '角色授權',
   name_111: '姓名',
   role_111: '角色',
   Modifying_Merchant_Basic_Information: '修改商戶基本資訊',
   Gift_ID: '禮物ID',
   Number_of_deliveries: '送出個數',
   Delivery_value_1: '送出價值(金幣/萬個)',
   broadcast_room_1: '送出直播間',
   broadcast_room_2: '請輸入送出直播間',
   broadcast_room_3: '送出價值',
   Gift_giving_time: '送禮時間',
   Online_1: '已上線',
   Gift_unit_price: '禮物單價',
   hint_1110: '只能上傳jpg/png檔,大小不超過1MB！',
   hint_1112: '預覽效果',
   hint_1113: ' 只能上傳gif檔,大小不超過1MB！',
   hint_1114: '播放效果',
   hint_1115: '只能上傳svga檔,大小不超過10MB！',
   Full_platform: '全平臺',
   This_room: '本房間',
   hint_1116: '只能上傳只能上傳jpg/png檔!',
   Type_of_issuance: '下發類型',
   Designated_category: '指定品類',
   hint_1117: '請輸入指定房間號，多個房間之間使用英文的逗號隔開',
   hint_1118: '請輸入禮物ID',
   hint_1119: '最多可輸入99個禮物ID',
   hint_1120: '不可輸入空格',
   hint_1121: '不可輸入中文逗號',
   hint_1122: '只可輸入中文、英文、數位',
   hint_1123: '請選擇下發類型',
   hint_1124: '請選擇下發範圍',
   hint_1125: '請選擇品類',
   hint_1126: '請指定房間',
   Publishable_status: '可發佈狀態',
   Expert_nickname: '專家昵稱',
   Expert_brief: '專家簡介',
   Expert_detail_1: '錦囊詳情',
   article_1: '亞眠15輪比賽積分13分，排名第18位，居降級附加區；上輪比賽客場完敗,，遭遇兩連敗,，狀態低迷；而他們主場3勝4負，排名第12位，雖然表現不出色，但還是依靠主場搶分；陣容方面，球隊有1名中場傷停，2名於球員出戰存疑。客隊上賽季排名法甲第二，本賽季下滑嚴重，目前15輪比賽積分10分，排名第19位，居降級區內；上輪比賽客場告負，球隊同樣遭遇兩連敗，狀態較差；本賽季客場2勝1平4負，排名第13位，雖然表現一般，但所獲勝利全為客場所得，有一定客戰能力；陣容方面，球隊飽受傷病領導困擾，目前有多達14名球員傷停。亞洲初始指數為本場比賽開出平手盤和主受平半盤，給予客隊謹慎支持；最新指數中部分機構多調整為平手盤，機構對客隊信心有所減弱；歐賠最新平均指數壓低勝賠，多機構同時壓低平賠，抬升負賠，不利客隊。',
   user_1111: '用戶',
   Import_1111: '導入',
   hint_1127: '已經達到添加上限了',
   Closed_111: '已關閉',
   Opened_111: '已開啟',
   Select_111: '選擇',
   less_than: '小於',
   Publisher_11: '發佈者',
   Let_the_ball: '讓球',
   analysis: '解析',
   Please_enter_the_parsing_content: '請輸入解析內容',
   price_11: '售價',
   free_11: '免費',
   release_1: '發佈',
   hint_1200: '標題需要4字以上',
   hint_1201: '推薦理由需要20字以上',
   Ball_selection: '球類選擇',
   Top_failure_time: '置頂失效時間',
   refund: '退款',
   Topping_time: '置頂時間',
   System_messages: '系統消息',
   Please_enter_the_message_content: '請輸入消息內容',
   abnormal: '異常',
   Let_the_ball_win: '讓球勝平負',
   Please_set_the_time: '請設置時間',
   Please_message_content: '請填寫消息內容',
   hint_20001: '置頂時間不能早於當前時間',
   hint_20002: '確定取消置頂嗎？',
   hint_20003: '建倉時間',
   Purchase_records: '購買記錄',
   // 新增8
   Edit_Resource_Bits: '編輯資源位',
   ADD_Resource_Bits: '新增資源位',
   hint_20091: '上移',
   hint_20092: '下移',
   hint_20093: '新增熱門',
   hint_20094: '視頻',
   hint_20095: '頁面',
   hint_20096: '添加背景',
   hint_20097: '確定要下架此背景圖嗎？',
   hint_20098: '只能上傳jpg/jpeg/png檔,寬高比 60*60',
   //新增9
   hint_20111: '請選擇彈幕庫',
   hint_20112: '請輸入下發類型',
   hint_20113: '請輸入下發範圍',
   hint_20114: '確定要{status}此配置嗎？',
   hint_20115: '已{status}',
   hint_20116: '彈幕內容不能為空',
   hint_20117: '單次最多添加1000條。',
   hint_20118: '添加時間',
   hint_20119: '開啟後，該主播直播間將重新進入機器人，確定開啟嗎？',
   hint_20121: '主播類型',
   hint_20122: '請輸入身份證',
   hint_20123: '房管',
   hint_20124: '請輸入uid',
   hint_20125: '用戶userId',
   hint_20126: '請輸入用戶userId',
   hint_20127: '請輸入直播標題',
   hint_20128: '帳號合併',
   hint_20129: '消息範圍',
   hint_20130: '本條消息',
   hint_20888: '本直播間內消息',
   hint_20131: '所有直播間消息',
   hint_20132: '刪除原因',
   hint_20133: '用戶禁言',
   hint_20134: '禁言用戶',
   hint_20135: 'UID不能為空',
   hint_20136: '禁言週期不能為空',
   hint_20137: '禁言原因不能為空',
   hint_20138: '房間號不能為空',
   hint_20139: '請輸入主播userid',
   hint_20400: '發起審核',
   hint_20401: '審核失敗',
   hint_20402: '匯出excel',
   hint_20403: '結算週期',
   hint_20404: '調整金額：',
   hint_20405: '元',
   hint_20406: '未發起',
   hint_20407: '結算帳單',
   hint_20408: '編輯前',
   hint_20410: '編輯後',
   hint_20411: '待提審',
   hint_20412: '添加公會',
   hint_20413: '請輸入收款帳戶名稱',
   hint_20414: '請輸入開戶行',
   hint_20415: '請輸入銀行卡號',
   hint_20416: '請輸入開戶位址',
   hint_20417: '請輸入100字以內',
   hint_20418: '編輯公會',
   hint_20419: '停用後將不可為主播分配該公會，是否確定停用此公會？',
   hint_20420: '只能上傳jpg/png檔，建議尺寸為',
   hint_20421: '配置帳號',
   hint_20422: '查找內容',
   hint_20423: '替換內容',
   hint_20424: '信號源跳轉位址',
   hint_20425: '請輸入信號源位址',
   hint_20426: '請輸入信號源跳轉位址',
   hint_20427: '請填寫ios Scheme',
   hint_20428: '請填寫關於內容',
   hint_20429: '介面版本號',
   hint_20430: '配置人',
   hint_20431: '未選擇任何短視頻，請選擇短視頻後再發佈！',
   hint_20432: '發佈成功',
   hint_20433: '發佈成功，此條短視頻進入發佈庫',
   hint_20434: '爬取時間段',
   hint_20435: '請選擇時間段',
   hint_20436: '已禁用',
   hint_20437: '設置成功',
   hint_20438: '是否置頂',
   hint_20439: '排序位置',
   hint_20440: '評論',
   hint_20441: '短視頻封面',
   hint_20442: '編輯置頂',
   hint_20443: '請輸入排序位置',
   hint_20444: '發佈後無法進行修改，是否確認發佈？',
   hint_20445: '訪問管道',
   hint_20446: '來源功能變數名稱',
   hint_20447: '小助手UV',
   notice_111: '公告UV',
   index_111: '指數',
   test_111: '確定要{status}該話題嗎？',
   test_112: '發佈話題',
   test_113: '確定要刪除嗎？',
   Batch_approval: '批量審批',
   Reviewer_111: '審核人',
   you_want_to_pass: '確定要通過嗎？',
   Download_failed: '下載失敗',
   Individual_currency: '個人貨幣批量增發範本',
   pass_all_of_them: '確認要全部通過嗎？',
   Fill_in_at_least: '增發貨幣、增發經驗值至少填寫其中一項',
   quantity_of_additional_currency: '請輸入增發貨幣數量',
   money_111: '金額',
   Input_cannot_be_greater: '輸入不能大於99999或小於1',
   Recharge_status: '充值狀態',
   Gold_coins: '金幣',
   Alipay_payment: '支付寶支付',
   WeChat_payment: '微信支付',
   ApplePay: '蘋果支付',
   Unpaid_111: '未支付',
   Paid_111: '已支付',
   Balance_ranking: '餘額排行',
   ranking_111: '排名',
   // 新增==
   Member_Change_Record: '會員變更記錄',
   phone_area_code: '請設置手機區號',
   your_phone_number: '請設置手機號',
   Successfully_lifted: '解除禁言成功',
   // 新增
   Risk_categories: '風險類別',
   No_type_selected: '未選擇類型',
 
   // 小助手新增
   Click_here_to_retry: '點此重試',
   Loading_failed: '載入失敗',
   Invalid_data: '數據無效',
   Insert_Image: '插入圖片',
   download_111: '下載',
   not_support_preview: '該檔不支援預覽',
   hint_801: '檔過大，請重新上傳',
   hint_802: '檔案格式錯誤，請重新上傳',
   hint_803: '檔上傳失敗',
   hint_804: '檔超出限制',
   Member_Information: '成員資訊',
   Personnel_changes: '人員變化',
   Anchor_Circle: '主播圈',
   Total_number_of_people: '總人數',
   New_additions_today: '今日新增',
   Today_decrease: '今日減少',
   Today_cumulative_changes: '今日累計變換',
   person_111: '人',
   // 新增222
   Edit_anchor_circle: '編輯主播圈',
   Administrator: '管理員',
   Please_select_an_administrator: '請選擇管理員',
   Anchor_Circle_Name: '主播圈名稱',
   Anchor_Circle_ID: '主播圈ID',
   Anchor_Circle_Avatar: '主播圈頭像',
   Number_of_members: '成員數',
   Free_speech: '自由發言',
   All_staff_are_prohibited_from_speaking_1: '全員禁言',
   Group_entry_time: '進群時間',
   Restricted_person: '限制人',
   Is_it_in_the_group: '是否在群',
   Remove: '移出',
   Enter: '進入',
   Reason: '原因',
   Operator: '操作員',
   Edit_Community_Circle: '編輯社群圈',
   Add_social_circles: '新增社群圈',
   Community_nickname: '社群圈昵稱',
   Community_circle_avatar: '社群圈頭像',
   Group_Leader: '群主',
   All_staff_are_prohibited_from_speaking: '全員禁言（不包含管理員）',
   Please_enter_the_social_circle_nickname: '請輸入社群圈昵稱',
   Please_select_the_community_circle_avatar: '請選擇社群圈頭像',
   Please_select_the_group_leader: '請選擇群主',
   Please_select_the_speaking_status: '請選擇發言狀態',
   Community_Circle_ID: '社群圈ID',
   Community_Circle_Name: '社群圈名稱',
   Are_you_sure_you_want_to_disable_this_circle: '確定要禁用該圈子？',
   Are_you_sure_you_want_to_enable_this_circle: '確認要啟用該圈子？',
   User_cancels_operation: '用戶取消操作',
   Speech_status: '發言狀態',
   Reason_for_group_membership: '拉群原因',
   Group_pull_date: '拉群日期',
   Move_into_a_new_circle: '移入新圈',
   New_Circle_Name: '新圈名稱',
   Circle_Announcement: '圈子公告',
   marked_6: '請填寫完整公告連結資訊再提交',
   Community_circle: '社群圈',
   Community_Circle_Management: '社群圈管理',
   Anchor_Circle_Management: '主播圈管理',
   Please_enter_the_name_of_the_anchor_circle: '請輸入主播圈名稱',
   Community_Circle_View: '社群圈查看',
   Anchor_Circle_View: '主播圈查看',
   Edit_customer_service_group_classification: '編輯客服組分類',
   Add_customer_service_group_classification: '新增客服組分類',
   Operation_failed: '操作失敗',
   Edit_traffic_entry: '編輯流量入口',
   Add_traffic_entry: '新增流量入口',
   Entry_Name: '入口名稱',
   Entrance_ID: '入口ID',
   Sort_Number: '排序編號',
   Entry_Description: '入口描述',
   Entrance_background_image: '入口背景圖',
   Classification_ID: '分類ID',
   Policy_application_update: '策略應用更新',
   Set_up_customer_service_group: '設置客服組',
   Strategy_number: '策略編號',
   Tourists: '遊客',
   Members: '會員',
   Reception_customer_service_team: '接待客服組',
   Are_you_sure_you_want_to_deactivate_this_account: '確定要停用該帳號？',
   Are_you_sure_you_want_to_enable_this_account: '確認要啟用該帳號嗎？',
   Traffic_entrance: '流量入口',
   Traffic_Entry_Name: '流量入口名稱',
   Traffic_Entry_Description: '流量入口描述',
   Customer_service_group_classification: '客服組分類',
   Are_you_sure_you_want_to_disable_this_entry: '確定要禁用該入口？',
   Are_you_sure_you_want_to_enable_this_entry: '確認要啟用該入口？',
   Entering_delay_time_in_milliseconds: '正在輸入延遲時間毫秒',
   marked_8: '請輸入正在輸入延遲時間毫秒',
   Entering_display_time_in_milliseconds: '正在輸入顯示時間毫秒',
   marked_9: '請輸入正在輸入顯示時間毫秒',
   marked_10: '使用者顯示假消息的時間間隔分鐘',
   marked_11: '請輸入使用者顯示假消息的時間間隔分鐘',
   Entering_Chinese_script_content: '正在輸入中話術內容',
   Global_configuration: '全域配置',
   Edit_account: '編輯帳號',
   Add_account: '新增帳號',
   A_vest: '馬甲',
   Customer_service_1: '客服',
   Account_type: '帳號類型',
   Account_avatar: '帳號頭像',
   Account_nickname: '帳號昵稱',
   Please_enter_your_account_nickname: '請輸入帳號昵稱',
   Please_select_account_type: '請選擇帳號類型',
   Please_upload_your_account_avatar: '請上傳帳號頭像',
   Please_select_account_status: '請選擇帳號狀態',
   Log_content: '日誌內容',
   marked_50: '編號',
   System_logs: '系統日誌',
   Exporting: '匯出中...',
   marked_12: '缺少匯出資料必須的參數',
   Last_7_days: '最近7天',
   In_the_past_30_days: '最近30天',
   In_the_past_60_days: '最近60天',
   In_the_past_90_days: '最近90天',
   Time_range: '時間範圍',
   System_account: '系統帳號',
   marked_13: '當日新增轉化用戶數',
   marked_14: '當日新增取消轉化用戶數',
   Total_number_of_converted_users: '轉化用戶總數',
   Edit_private_script: '編輯私有話術',
   Add_private_script: '新增私有話術',
   Script_content: '話術內容',
   Script_state: '話術狀態',
   Please_enter_the_script: '請輸入話術',
   Please_select_the_script_status: '請選擇話術狀態',
   Are_you_sure_you_want_to_deactivate_this_script: '確定要停用該話術？',
   Are_you_sure_you_want_to_enable_this_script: '確認要啟用該話術？',
   Edit_public_automatic_scripts: '編輯公共自動話術',
   Add_public_automatic_scripts: '新增公共自動話術',
   Script_Title: '話術標題',
   Associated_traffic_entry: '關聯流量入口',
   Associate_with_customer_service_group: '關聯客服組',
   Please_enter_the_script_title: '請輸入話術標題',
   Please_enter_the_sorting_number: '請輸入排序編號',
   Please_select_the_associated_traffic_entry: '請選擇關聯流量入口',
   marked_15: '請選擇關聯客服組',
   Editing_public_language: '編輯公共話術',
   Add_public_language_skills: '新增公共話術',
   Script_number: '話術編號',
   Public_speaking: '公共話術',
   Public_automatic_language: '公共自動話術',
   marked_17: '確定要停用該標籤？',
   marked_16: '確認要啟用該標籤？',
   Edit_custom_user_groups: '編輯自訂用戶組',
   Add_custom_user_groups: '新增自訂用戶組',
   Custom_user_group_name: '自訂用戶組名稱：',
   Custom_user_group_description: '自訂用戶組描述',
   Master_Administrator: '主管理員',
   Speech_settings: '發言設置',
   marked_18: '請輸入自訂用戶組名稱',
   Please_select_an_icon: '請選擇圖示',
   Please_select_the_cover_image: '請選擇封面圖',
   Please_select_the_primary_administrator: '請選擇主管理員',
   Please_select_speech_settings: '請選擇發言設置',
   Circle: '圈子',
   Add_internal_members: '新增內部成員',
   Operator_Name: '操作員姓名',
   Vest_account_nickname: '馬甲帳號昵稱',
   Third_party_ID: '協力廠商ID',
 
   //小助手缺失翻譯
   Business_ID: '業務ID',
   Please_select_a_member: '請選擇成員',
   User_group_number: '用戶組編號',
   User_group_name: '用戶組名稱',
   User_group_label: '用戶組標籤',
   Updated_by: '更新人',
   Member_management: '成員管理',
   Cancel_administrator: '取消管理員',
   Remove_user_group: '移除用戶組',
   Set_as_administrator: '設為管理員',
   Business_UID: '業務UID',
   Are_you_sure_you_want_to_proceed: '確定操作嗎？',
   User_ID: '用戶編號',
   User_group_user_records: '使用者組使用者記錄',
   Previous_user_group: '上一用戶組',
   Current_user_group: '當前用戶組',
   Explanation: '說明',
   Cancel_conversion: '取消轉化',
   Initial_reporting_time: '初次上報時間',
   In_the_anchor_circle: '所在主播圈',
   Community_Circle: '所在社群圈',
   Are_you_sure_you_want: '確定要{num}該用戶嗎？',
   User_Information: '使用者資訊',
   Information_line: '信息線',
   Domain_Name_Source: '功能變數名稱來源',
   Please_enter_the_domain_name_source: '請輸入功能變數名稱來源',
   Please_enter_ID: '請輸入ID',
   Access_time: '存取時間',
   Assistant_data: '小助手數據',
   Customer_service_seat_details: '客服坐席詳情',
   Force_offline: '強制下線',
   Signal_status: '信號狀態',
   Seat_status: '坐席狀態',
   Current_reception: '當前接待',
   Recently_offline: '最近下線',
   Recently_launched: '最近上線',
   Currently_receiving_users: '當前接待用戶',
   There_are_currently_no_customers: '暫無客戶',
   There_is_no_more: '沒有更多了',
   Seat_status_log: '坐席狀態日誌',
   Signal_status_log: '信號狀態日誌',
 
   // 新增3333
   Offline: '離線',
   Online: '線上',
   Busy: '忙碌',
   Historical_status: '歷史狀態',
   Reception_traffic_entrance: '接待流量入口',
   Sender_User_ID: '發送人用戶ID',
   User_type_of_sender: '發送人用戶類型',
   Message_ID: '消息ID',
   Message_type: '消息類型',
   Message_content: '消息內容',
   Private_conversation_ID_1: '私聊會話ID',
   Recipient_User_ID: '接收人用戶ID',
   Recipient_user_type: '接收人用戶類型',
   Sending_time: '發送時間',
   Number_of_reception_sessions: '接待會話數',
   View_current_chat_history: '查看當前聊天記錄',
   View_all_chat_records: '查看所有聊天記錄',
   Number_of_receptionists: '接待人數',
   Private_conversation_ID: '私聊會話ID：',
   Please_enter_the_private_chat_session_ID: '請輸入私聊會話ID',
   Session_start_time: '會話開始時間',
   Customer_service_account_ID: '客服帳號ID',
   Customer_service_nickname: '客服昵稱',
   marked_19: '客服直播間撈起',
   marked_20: '用戶觸發流量入口變更釋放',
   marked_21: '客服主動釋放',
   Customer_service_switch_offline_release: '客服切換離線釋放',
   marked_1: '使用者私聊資訊發送客服信號離線釋放',
   marked_2: '使用者私聊資訊發送客服坐席離線釋放',
   marked_3: '使用者私聊資訊發送客服坐席狀態未知釋放',
   marked_4: '使用者私聊資訊發送客服資料缺失釋放',
   marked_5: '使用者私聊資訊發送流量入口變更釋放',
   Unknown: '未知',
   Add_menu: '新增菜單',
   catalogue: '目錄',
   Menu: '菜單',
   Menu_icon: '功能表圖示',
   Menu_Title: '菜單標題',
   Routing_address: '路由地址',
   Whether_to_hide_or_not: '是否隱藏',
   Menu_Name: '菜單名稱',
   Permission_identifier: '許可權標識',
   Component_path: '元件路徑',
   Please_enter_the_menu_title: '請輸入功能表標題',
   Please_enter_the_routing_address: '請輸入路由位址',
   Please_select_the_superior_category: '請選擇上級類目',
   Are_you_sure_you_want_to_delete: '確定要刪除{num}該項菜單嗎？',
   Please_enter_your_account_number: '請輸入帳號',
   Please_enter_your_name: '請輸入姓名',
   Please_select_a_role: '請選擇角色',
   Please_enter_password: '請輸入密碼',
   marked_22: '請至少選擇一個客服組',
   Reset_password: '重置密碼',
   Customer_Service_Group: '所屬客服組',
   Role: '角色',
   marked_23: '確定要下線該帳戶？',
   marked_24: '確定要停用該帳戶？',
   marked_25: '確定要重置該帳戶密碼？',
   Modify_profile_picture: '修改頭像',
   New_avatar: '新頭像',
   Please_select_a_profile_picture: '請選擇頭像',
   marked_26: '修改成功，請重新登錄',
   Change_nickname: '修改昵稱',
   Business_System_Api: '業務系統Api',
   Conversion_System_Api: '轉化系統Api',
   Please_enter_the_business_system_Api: '請輸入業務系統Api',
   Please_enter_the_conversion_system_Api: '請輸入轉化系統Api',
   Official_avatar: '官方頭像',
   Official_nickname: '官方昵稱',
   Please_enter_the_official_nickname: '請輸入官方昵稱',
   Page_Title: '頁面標題',
   Please_enter_the_page_title: '請輸入頁面標題',
   Red_series: '紅色系',
   Green_series: '綠色系',
   Black_series: '黑色系',
   Please_upload_your_profile_picture: '請上傳頭像',
   Please_select_a_style: '請選擇樣式',
   Configuration_query_failed: '配置查詢失敗',
   Preview_Configuration: '預覽配置',
   Please_complete_the_form: '請完善表單',
   Provide_the_necessary_parameters_for_preview: '提供預覽需要的參數',
   Vest_UID: '馬甲UID',
   Operator_ID: '操作員ID',
   Operator_account: '操作員帳號',
   Operator_nickname: '操作員昵稱',
   Explanation_111: '說明：馬甲帳號對應前端的一個真實C端用戶帳號，目前每個帳號僅限10個馬甲帳號；',
   Failed_to_obtain_user_information: '獲取使用者資訊失敗',
   Failed_to_obtain_token: '獲取token失敗',
   Customer_service: '客服(不可用)',
   Switching_operational_accounts: '切換運營帳號',
   Quick_reply_script: '快速回復話術',
   marked_27: '已自動提取用戶{num}位',
   Update: '更新',
   Clear: '清空',
   Extract_users: '提取用戶',
   block: '拉黑',
   Locked: '鎖定',
   marked_28: '確定要清空提取用戶嗎？',
   Successfully_blacklisted: '拉黑操作成功',
   User_group_comments: '用戶組內發言',
   marked_29: '發言命中自動提取用戶',
   marked_30: '只能輸入中英文',
   Successfully_deleted_keyword: '刪除關鍵字成功',
   User_Group: '用戶組',
   Registration_days: '註冊天數',
   Release: '釋放',
   Label: '標籤',
   Tagging: '打標籤',
   Conversion_Description: '轉化說明',
   Application_Name: '應用名稱',
   Installation_time: '安裝時間',
   User_nickname: '用戶昵稱',
   Chat_content: '聊天內容',
   Time_of_occurrence: '發生時間',
   Private_Chat: '私聊',
   Business_System_UID: '業務系統UID',
   speak111: '發言',
   marked_35: '請輸入社群圈名稱',
   Name1111: '名稱',
   password111: '密碼',
   enter_the_entrance_ID: '請輸入入口ID',
   enter_the_entrance_111: '請輸入入口名稱',
   enter_the_entrance_112: '請輸入入口描述',
   enter_the_entrance_113: '請選擇入口背景圖',
 
   // 新增444
   marked_66: '業務端ID',
   marked_67: '匯出中...',
   marked_68: '標籤狀態',
   marked_69: '請選擇標籤狀態',
   marked_70: '至少再添加一個標籤',
   marked_71: '編號/昵稱',
   marked_72: '註冊日期',
   marked_73: '用戶詳情',
   marked_74: '用戶頭像',
   marked_75: '賽事時間',
   marked_76: '主',
   marked_77: '歷史接待用戶',
   marked_78: '確認強制下線嗎？',
   marked_79: 'APP用戶',
   marked_80: '客服組',
   marked_81: '發起來源',
   marked_82: '用戶發起',
   marked_83: '客服直播間撈起',
   marked_84: '用戶ID',
   marked_85: '關閉類型',
   marked_86: '會話結束時間',
   marked_87: '成功',
   marked_88: '登錄密碼',
   marked_89: '移除客服組',
   marked_90: '確認要啟用該帳戶嗎？',
   marked_91: '請選擇角色狀態',
   marked_92: '授權成功',
   marked_93: '新昵稱',
   marked_94: '客服信息',
   marked_95: '請完善表單,提供預覽需要的參數',
   marked_96: '請輸入新的昵稱',
   marked_97: '帳號等級',
   marked_98: '啟用為我的馬甲',
   marked_99: '(不可用)',
   marked_100: '發送',
   marked_101: '發言命中',
   marked_102: '鎖定操作成功',
   marked_103: '關鍵字已達上限，無法再添加',
   marked_104: '暫無群聊',
   marked_105: '安裝應用',
   marked_106: '轉化',
   marked_107: '轉化用戶',
   style_select: '樣式選擇',
   button111: '按鈕',
   // 新增
   'Member_login_data': '會員登入數據',
   'Login_type': '登錄類型',
   // 新增
   'Such_as': '例如',
   'marked_108': '不能小於0',
   'marked_109': '不能輸入空格',
   'marked_110': '只能上傳jpg/png檔，圖片尺寸需為360X842，最多4張',
   'marked_111': '圖片尺寸建議為400x60,只能上傳jpg/png/gif檔',
   'marked_112': '最多上傳4張',
   'marked_113': '請輸入正文',
   'marked_114': '請設置封面',
   'Comment_details': '評論詳情',
   'marked_115': '備註：最多置頂20條資料',
   'marked_116': '話題背景圖',
   'marked_117': '啟用後則自動爬取該網站的資料，是否確認啟用？',
   'marked_118': "第{num}張",
   // 新增
   'marked_119': '請選擇標籤級別',
   'marked_120': '請選擇對應一級標籤',
   'marked_121': '批量變更所屬二級狀態',
   'marked_122': '創建的標籤，不支持刪除，變更名稱或自身標籤級別；只支援變更所屬的一級標籤，和標籤狀態。是否確認創建標籤？',
   'marked_123': '是否確認變更？',
   // 新增
   'marked_124': '分配數據量',
   'marked_125': '發佈量',
   'unaudited': '未審核',
   'Video_size': '視頻尺寸',
   'marked_126': '請填寫登錄地址',
   //新手通道
   'new_passage': '新手通道',
   'vip_passage': 'VIP通道',
   'zhanshang_passage': '招商通道',
   'click_num': '點擊數量',
   'small_help': '小助手點擊量統計資料',
   // 新增
   'So_save_it': '先保存',
   'if_save_to_web': '是否更新到前端',
   'match_name': '賽事名稱',
   'if_candle_hot': '是否取消熱門',
   'candle_hot': '取消熱門',
   'go_hot': '上熱門',
   'candle_hot_time': '取消熱門時間',
   'please_add_match': '請添加賽事',
   'hint3333': '確定刪除嗎',
   'hint3334': '取消熱門賽事',
   'hint3335': '確定更新嗎',
   'hint3336': '確定上熱門嗎',
   'hint3337': '隱藏?',
   'hint3338': '顯示?',
   'hint3339': '只能上傳jpg/png/jpeg/gif檔',
   'hint3340': '小助手回復速度',
   'hint3341': '小助手開啟速度',
   'hint3342': '小助手處理滿意度',
   'hint3343': '意見回饋',
   'hint3344':'小助手滿意度數據'
};
