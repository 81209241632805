export default {
  dashboard: 'Dashboard',
  directivePermission: 'Instruction permission',
  icons: 'Icons',
  components: 'Components',
  clipboardDemo: 'Clipboard',
  componentMixin: 'Mixin',
  backToTop: 'Back To Top',
  page401: '401',
  page404: '404',
  errorLog: 'Error Log',
  mergeHeader: 'Merge Header',
  i18n: 'I18n',
  profile: 'Profile'
}