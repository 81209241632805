exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#ccc",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#1f2124",
	"menuHover": "#5d85f4",
	"subMenuBg": "#000",
	"subMenuHover": "#5d85f4",
	"sideBarWidth": "210px"
};