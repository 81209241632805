export default {
  title: 'Login Form',
  merchant: 'Merchant ID',
  logIn: 'Login',
  username: 'Username',
  password: 'Password',
  merchantTips: 'The merchant can not be less than 1 digits',
  usernameTips: 'Please enter the correct user name',
  passwordTips: 'The password can not be less than 6 digits',
  merchantNameTips: 'Please enter the correct merchant ID',
  ForgotPasswordPrompt: 'If you forget your password, please contact the administrator to reset it.'
}