<template>
  <div class="app-container">
    <div class="bg-container">
      <img src="@/assets/images/dashboard_bg.png">
    </div>
  </div>
</template>
<script>
export default {
  name: 'Dashboard',
  components: {

  },
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.bg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  img {
    width: 557px;
    height: 566px;
  }
}
</style>
