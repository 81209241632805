import Cookies from 'js-cookie'

const TokenKey = 'X-ACCESS-TOKEN'
const AppId = 'appid'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getAppId() {
  return Cookies.get(AppId)
}

export function setAppId(appId) {
  return Cookies.set(AppId, appId)
}

export function removeAppId() {
  return Cookies.remove(AppId)
}

export function getMerchantId() {
  return localStorage.getItem('merchantId') || ''
}
// sessionStorage.setItem('merchantId', userInfo.merchantId)
