export default {
  dashboard: '首页',
  directivePermission: '指令权限',
  icons: '图标',
  components: '组件',
  componentMixin: '小组件',
  backToTop: '返回顶部',
  errorPages: '错误页面',
  page401: '401',
  page404: '404',
  errorLog: '错误日志',
  clipboardDemo: 'Clipboard',
  i18n: '国际化',
  profile: '个人中心'
}
