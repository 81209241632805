var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            autocomplete: "on",
            "label-position": "left",
          },
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("h3", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("login.title"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "merchantId" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                ref: "merchantId",
                attrs: {
                  placeholder: _vm.$t("login.merchantNameTips"),
                  name: "merchantId",
                  type: "text",
                  tabindex: "1",
                  autocomplete: "on",
                },
                model: {
                  value: _vm.loginForm.merchantId,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "merchantId", $$v)
                  },
                  expression: "loginForm.merchantId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                ref: "username",
                attrs: {
                  placeholder: _vm.$t("login.username"),
                  name: "username",
                  type: "text",
                  tabindex: "1",
                  autocomplete: "on",
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v)
                  },
                  expression: "loginForm.username",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "Caps lock is On",
                placement: "right",
                manual: "",
              },
              model: {
                value: _vm.capsTooltip,
                callback: function ($$v) {
                  _vm.capsTooltip = $$v
                },
                expression: "capsTooltip",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    key: _vm.passwordType,
                    ref: "password",
                    attrs: {
                      type: _vm.passwordType,
                      placeholder: _vm.$t("login.password"),
                      name: "password",
                      tabindex: "2",
                      autocomplete: "on",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.capsTooltip = false
                      },
                    },
                    nativeOn: {
                      keyup: [
                        function ($event) {
                          return _vm.checkCapslock($event)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleLogin($event)
                        },
                      ],
                    },
                    model: {
                      value: _vm.loginForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "password", $$v)
                      },
                      expression: "loginForm.password",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class":
                            _vm.passwordType === "password"
                              ? "eye"
                              : "eye-open",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-bottom": "15px" },
              attrs: { loading: _vm.loading, type: "primary" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleLogin($event)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("login.logIn")) + "\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { position: "relative" } }, [
            _c("div", { staticClass: "tips" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("login.ForgotPasswordPrompt"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "lang-select" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: _vm.languageKey === "zh" ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleLanguage("zh")
                    },
                  },
                },
                [_vm._v("\n        中文\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: _vm.languageKey === "en" ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleLanguage("en")
                    },
                  },
                },
                [_vm._v("\n        English\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: _vm.languageKey === "th" ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleLanguage("th")
                    },
                  },
                },
                [_vm._v("\n        ภาษาไทย\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: _vm.languageKey === "zht" ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleLanguage("zht")
                    },
                  },
                },
                [_vm._v("\n        繁体中文\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }