export default {
  dashboard: 'หน้าแรก',
  directivePermission: 'คำสั่งอนุญาต',
  icons: 'ไอคอน',
  components: 'คอมโพเนนต์',
  componentMixin: 'วิดเจ็ต',
  backToTop: 'กลับไปด้านบน',
  errorPages: 'หน้าข้อผิดพลาด',
  page401: '401',
  page404: '404',
  errorLog: 'บันทึกข้อผิดพลาด',
  clipboardDemo: 'Clipboard',
  i18n: 'ความเป็นสากล',
  profile: 'Personal Center'
}